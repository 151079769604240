import BaseModule from '@/vue/store/modules/base_module';

const state = {
  ...BaseModule.state,
  search_endpoint: '',
  sort_columns: [
    {column_name: 'xmlReceived', direction: 'DESC'},
  ],
  search_columns: [],
  quick_filters: {},
  data: []
};

const getters = { ...BaseModule.getters };
const actions = {
  ...BaseModule.actions,
  set_endpoint({state, commit, dispatch}, payload) {
    commit('set_search_endpoint', '/api/v1/import/' + payload.ais);
    commit('set_param', {name: 'pharmacyId', value: payload.id});
  },
};
const mutations = { ...BaseModule.mutations };

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
