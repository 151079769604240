<template>
  <select class="select2-filter-item"></select>
</template>
<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';

export default {
  props: ['value', 'options', 'item', 'ajax_route'],
  mounted: function () {
    this.id = '' + Math.floor(Math.random() * 1e10);
    this.initSelect2();
  },
  data: function () {
    return {
      id: ''
    };
  },
  mixins: [BaseComponentsMixin],
  watch: {
    value: function (value) {
      // update value
      $(this.$el).val(value).trigger('change');
    },
    options: function () {
      // update options
      $(this.$el).empty();
      this.initSelect2(); //.select2({ data: options });
    }
  },
  methods: {
    destroySelect2: function () {
      $(this.$el).off().select2('destroy');
    },
    initSelect2: function () {
      $(this.$el).select2(
        $.extend({placeholder: 'Maak een keuze'}, select2MultiWordMatchAjax, {
          data: this.options,
          ajax: {
            url: this.ajax_route,
            delay: 300,
            cache: true,
            dataType: "json",
            data: function (params) {
              return {
                search: params.term, // search term
                page: params.page
              };
            },
            processResults: function (data, params) {
              // Catch errors
              if (data instanceof Array) {
                // Let formajaxprocessor catch notification
                processAjaxUpdate(data);
                // Return empty result
                return {
                  results: [],
                  pagination: {
                    more: false
                  }
                };
                // No errors, so return data
              } else {
                // parse the results into the format expected by Select2
                // since we are using custom formatting functions we do not need to
                // alter the remote JSON data, except to indicate that infinite
                // scrolling can be used
                params.page = params.page || 1;

                return {
                  results: data.results,
                  pagination: {
                    more: (params.page * 30) < data.total_count
                  }
                };
              }
            }
          }
        })
      );

      $(this.$el).on("select2:select", (e) => {
        const data = e.params.data;
        this.item.value = data.id;
        this.item.text = data.text;
      });
      $(this.$el).on("select2:unselect", () => {
        this.item.value = '';
        this.item.text = '';
      });
    },
  },
  unmounted() {
    this.destroySelect2();
  }
};
</script>

<style>
.filter-item .select2 {
  width: 500px !important;
}
</style>
