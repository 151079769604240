<template>
  <medi-modal
      @closed="ignoreForNow"
      :hide_overlay="hide_overlay"
      :loading="loading"
      size="small"
  >
    <template v-slot:modal-header>
      <div class="modal-title col-auto">
        {{ action.name }}
      </div>
    </template>

    <span v-html="action.description"></span>

    <template v-slot:action-buttons >
      <medimo-button type="success" class="w-25" @click="submit('accept')">
        Ja
      </medimo-button>
      <medimo-button type="danger" class="w-25" @click="submit('decline')">
        Nee
      </medimo-button>
      <div class="d-inline mr-auto"></div>
      <medimo-button type="button" class="btn btn-primary ml-1" @click="ignoreForNow">
        Nu even niet
      </medimo-button>
    </template>
  </medi-modal>
</template>

<script>

import BaseComponentsMixin from '../../../mixins/BaseComponentsMixin';
import MediModal from '../../general/MediModal';
import NavigatesProgrammatically from '../../../mixins/navigation/NavigatesProgrammatically';
import InitialData from '../../../mixins/InitialData';

export default {
  components: {
    MediModal,
  },

  emits: ['closed','processed'],

  props: [
    'action',
    'hide_overlay',
    'patient',
    'refresh_patient_medication_data',
  ],

  mixins: [
    BaseComponentsMixin,
    NavigatesProgrammatically,
    InitialData,
  ],

  data: function () {
    return {
      agreed: false,
      loading: false,
      to: '/'
    };
  },

  computed: {
    language() {
      return this.$store.getters['current_user/language'];
    },
  },

  methods: {
    close() {
      this.$emit('closed');
    },
    ignoreForNow() {
      this.close();
    },
    submit(value) {
      this.loading = true;
      return this.$store.dispatch('patients/update_action_from_patient', {
        rejectErrors: true,
        action_id: this.action.id,
        patient_id: this.action.patientId,
        handler: 'opiumReturnPharmacy',
        refresh_patient_medication_data: this.refresh_patient_medication_data,
        data: {
          value: value,
        }
      }).then(() => {
        this.loading = false;
        this.$store.dispatch('notifications/addSuccessAlert', {message: 'Gegevens opgeslagen'});

        this.$emit('processed');
        this.close();
      }).catch(() => {
        this.loading = false;
      });
    },
  },
};
</script>
