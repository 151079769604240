import BaseModule from '@/vue/store/modules/base_module';

// initial state
// shape: [{ id, quantity }]
const state = {
  ...BaseModule.state,

  data: [],

  single: {},

  chat_messages: {},

  quick_filters: {
  },

  toggleQuickFilters: true,
};

// getters
const getters = {
  ...BaseModule.getters,
};

// actions
const actions = {
  ...BaseModule.actions,

  fetchActive({state, commit, dispatch, getters, rootState}, payload) {
    payload.endpoint = '/api/v1/patients/chats/'+ payload.patient_id +'/active';
    return dispatch('patient_chats/fetch', payload, {root:true});
  },
  fetchArchived({state, commit, dispatch, getters, rootState}, payload) {
    payload.endpoint = '/api/v1/patients/chats/'+ payload.patient_id +'/archived';
    return dispatch('patient_chats/fetch', payload, {root:true});
  },
  fetch({state, commit, dispatch, getters, rootState}, payload) {
    return dispatch('api/getEndpoint', {
      endpoint: payload.endpoint,
      data: {} // Empty, since it's a GET
    }, {root: true}).then(response => {
      commit('set_data', response.data.data);
    });
  },
  fetchSingle({state, commit, dispatch, getters, rootState}, payload) {
    return dispatch('api/getEndpoint', {
      endpoint: '/api/v1/patients/chats/'+ payload.patient_id +'/single/'+ payload.chat_id,
      data: {} // Empty, since it's a GET
    }, {root: true}).then(response => {
      commit('set_single', {chat: response.data.data});
    });
  },

  store({state, commit, dispatch, getters, rootState}, payload) {

    const endpoint = '/api/v1/patients/chats/' + payload.patient_id + '/store';

    return dispatch('api/postEndpoint', {
      endpoint: endpoint,
      rejectErrors: true,
      data: payload.data
    }, {root: true});
  },

  fetchChatMessages({state, commit, dispatch, getters, rootState}, payload) {

    const endpoint = '/api/v1/patients/chats/' + payload.patient_id + '/fetch-messages/' + payload.chat_id;

    return dispatch('api/getEndpoint', {
      endpoint: endpoint,
      data: {}
    }, {root: true}).then(response => {
      commit('add_to_chat_messages', {chat_id: payload.chat_id, chat_messages: response.data.data});
      return response;
    });
  },

  reply({state, commit, dispatch, getters, rootState}, payload) {

    const endpoint = '/api/v1/patients/chats/' + payload.patient_id + '/reply/' + payload.chat_id;

    return dispatch('api/postEndpoint', {
      endpoint: endpoint,
      rejectErrors: true,
      data: payload.data
    }, {root: true}).then(response => {
      commit('add_to_chat_messages', {chat_id: payload.chat_id, chat_messages: response.data.data});
      return response;
    });
  },

  close({state, commit, dispatch, getters, rootState}, payload) {

    const endpoint = '/api/v1/patients/chats/' + payload.patient_id + '/close/' + payload.chat_id;

    return dispatch('api/postEndpoint', {
      endpoint: endpoint,
      rejectErrors: true,
      data: payload.data
    }, {root: true}).then(response => {
      const data = response.data.data;
      data.patient_id = payload.patient_id;
      dispatch('patients/update_active_chat_count', data, { root: true });
      return response;
    });
  },

  read({state, commit, dispatch, getters, rootState}, payload) {

    const endpoint = '/api/v1/patients/chats/' + payload.patient_id + '/read/' + payload.chat_id;

    return dispatch('api/postEndpoint', {
      endpoint: endpoint,
      data: payload.data
    }, {root: true}).then(response => {
      return response;
    });
  },
};

// mutations
const mutations = {
  ...BaseModule.mutations,

  add_to_chat_messages(state, payload) {
    state.chat_messages[payload.chat_id] = payload.chat_messages;
  },
  set_single(state, payload) {
    state.single = payload.chat;
  }
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations,
};
