<template>
  <div :id="id" :class="'medimo-vue-select2 ' + validation_class + ' ' + value_class + ' ' + (help_text.length ? 'has-help-text' : '')" style="position: relative;">
    <!--
      Onderstaand gebruikt soms concatenated dynamic properties, als je dit niet doet pakt de VueSelect hem gek genoeg niet
    -->
    <vue-select
                :class="'vue-select2 ' + extra_classes + ' ' + stateClasses"
                :label="'' + optionsLabelProperty"
                :placeholder="'' + placeholder"
                :modelValue="modelValue"
                :options="optionsArray"
                :searchable="searchable"
                :noOptionsMessage="no_results_message"

                :disabled="disabled"

                @search="fetchOptions"

                :filter="filter"
                :filterable="filterable"
                :clearable="clearable"

                @open="isOpen = true"
                @close="isOpen = false"

                :multiple="multiple"
                :help_text="help_text"

                @update:modelValue="onInput($event)"
                @option:selected="onSelected($event)"
                @option:deselected="onDeselected($event)"

                :appendToBody="true"
                :dropdown-should-open="dropdownShouldOpen"
                :reduce="reduced"
                :resetWidth="resetWidth"

                :selectable="option => !option.hasOwnProperty('group')"
    >
      <template #option="option">
        <div v-if="option['group']" class="group disabled">
          {{ option['group'] }}
        </div>
        <div>
          {{ option[optionsLabelProperty] }}
        </div>
      </template>
      <template #selected-option="option">
        {{ option[optionsLabelProperty] }}
      </template>

      <template v-slot:no-options>
        {{ no_options_message }}
      </template>
      <template v-slot:list-footer v-if="api_url.length && min_input_length && this.query.length<min_input_length">
        <li class="vs__no-options medimo-75">
          <em>{{ type_to_search_message_shown }}</em>
        </li>
      </template>
    </vue-select>

    <medimo-tooltip v-if="help_text.length" :content="help_text" :class="(inline ? '' : 'not-inline')">
      <fa-icon icon="fa-solid fa-circle-question" class="text-primary" size="lg" />
    </medimo-tooltip>
    <div class="invalid-feedback" v-show="showInvalid">
      Ongeldige waarde ingevoerd
    </div>
    <medimo-loader-overlay :size="16" class="mt-1" :loading="loading" :showErrorMessageAfter="60000" message=""></medimo-loader-overlay>
  </div>
</template>

<script>

import MedimoInput from '@/vue/components/general/form/base/MedimoInput';
import MedimoLoaderOverlay from '@/vue/components/general/MedimoLoaderOverlay';
import MedimoTooltip from '@/vue/components/general/MedimoTooltip';
import ThrottlesRequests from '@/vue/mixins/ThrottlesRequests';
import Utility from '@/vue/utility/utility';
import VueSelect from '@/vue/components/general/form/base/vue-select2/VueSelect';
import WorksWithParentsSiblingsAndChildren from '@/vue/mixins/WorksWithParentsSiblingsAndChildren';
import {nextTick} from 'vue';

export default {
  components: {
    MedimoTooltip,
    MedimoInput,
    MedimoLoaderOverlay,
    VueSelect,
  },

  emits: ['update:modelValue','option:selected','option:deselected'],

  props: {
    id: {},

    'disabled': {default: false},

    // Deze kun je gebruiken om buiten de search() om een loader te tonen
    // bijv. als je data extern via een fetch ophaalt
    'loading': {default: false},

    // Equivalent to the placeholder attribute on an <input>.
    // https://vue-select.org/api/props.html#placeholder
    'placeholder': {default: ""},

    // Contains the currently selected value. Very similar to a value attribute on an <input>. You can listen for changes using 'change' event using v-on.
    // https://vue-select.org/api/props.html#value
    'modelValue': {default: '', type: [String, Number, Array]},

    // An array of strings or objects to be used as dropdown choices. If you are using an array of objects, vue-select will look for a label key (ex. [{text: 'Canada', value: 'CA'}]). A custom label key can be set with the label prop.
    // https://vue-select.org/api/props.html#options
    'options': {
      default: function () {
        return [];
      },
      type: Array,
    },

    'api_url': {default: '',},
    'api_request_type': {default: 'POST'},
    // Als je extra dingen mee wilt sturen zoals bijv. de quick_filters of sort_columns kan dat hier:
    // 'api_data': {
    //   default: {
    //     quick_filters: ['alleen paracetamol'],
    //   }
    // },
    'api_data': {
      default: () => {
        return {};
      }
    },
    // Deze gebruik je om een custom naam voor de query mee te geven, waar de backend naar luistert
    'api_query_parameter': {default: 'query',},
    // Soms heb je geen controle over de backend, en zit de resultaten array in een aparty property
    // zoals bijvoorbeeld 'result' - dan kun je die hier instellen om die array dan alsnog uit te lezen
    'api_result_property': {default: '',},

    // Als de options door een API query gevuld worden betekent dit in de praktijk dat
    // je start met een lege array. Als het model al wel een waarde heeft, kun je die
    // hier als object meegeven (met de juiste text/value pair) zodat hij al netjes gevuld wordt
    'api_prefilled_option': {
      default: () => {
        return {};
      },
    },
    'min_input_length': {default: 3},
    'fetch_on_load': {default: true},

    // Tells vue-select what key to use when generating option labels when each option is an object.
    // https://vue-select.org/api/props.html#label
    'optionsLabelProperty': {default: 'text'},
    'optionsValueProperty': {default: 'value'},
    // Als je extra properties in de optie wil behouden (een model bijv) kun je die hier definieren
    // zodat ze mee omhoog getrapt worden bij een select event
    'extraOptionDataProperties': {type: Array, default: () => []},

    // Equivalent to the multiple attribute on a <select> input.
    // https://vue-select.org/api/props.html#multiple
    'multiple': {default: false},

    /**
     * MEDIMO Specific
     */
    'extra_classes': {},
    'validation_class': {},
    'help_text': {default: ''},
    'inline': {default: true},
    'erasable': {default: false},
    'minimum_results_for_search': {default: 10},
    'no_results_message': {default: 'Geen resultaten gevonden'},
    'type_to_search_message': {default: 'Typ om te zoeken...'},
    'clearable': {default: false},
    'resetWidth': { type: Number, default: 0 },
  },

  mixins: [
    WorksWithParentsSiblingsAndChildren,
    ThrottlesRequests,
  ],

  data: function () {
    return {
      forceClose: false,
      showInvalid: false,
      isOpen: false,
      apiOptions: [],
      selectedOptions: [],

      query: '',
    };
  },

  computed: {
    /**
     * Deze worden in de CSS gebruikt om bepaalde dingen te overrulen / anders weer te geven
     */
    stateClasses() {
      return (this.isOpen ? 'open' : 'closed') + ' '
          + ((this.modelValue !== null && (this.modelValue.length || (this.modelValue && !Array.isArray(this.modelValue)))) ? 'has-selection' : '')
          + ' ' + (this.multiple ? 'multiple' : 'single')
          + ' ' + (this.erasable_value ? 'erasable' : '');
    },
    invalidValue() {
      if (this.modelValue === '' || this.modelValue === null) {
        return false;
      }
      if (this.isOpen) {
        return false;
      }

      // Deze logica houdt rekening met een Multiple select2 waar de value een array is
      if (this.multiple) {
        const matchingResults = [];
        this.modelValue.forEach(value => {
          if (Utility.find_index_of_matching_element(this.optionsArray, this.optionsValueProperty, value) > -1) {
            matchingResults.push(1);
          }
        });
        return matchingResults.length !== this.modelValue.length;
      }

      // Als de waarde niet gevonden wordt returned deze helper -1
      return Utility.find_index_of_matching_element(this.optionsArray, this.optionsValueProperty, this.modelValue) < 0;
    },
    searchable() {
      if (this.api_url.length) {
        return true;
      }
      return this.options.length > this.minimum_results_for_search;
    },
    reduced() {
      // Om de return goed te laten verlopen en het label te zien, maar de value te setten, hebben
      // we deze reducer nodig
      // Source: https://vue-select.org/api/props.html#rece
      return selection => selection[this.optionsValueProperty];
    },
    value_class() {
      if (this.modelValue) {
        return this.modelValue.length ? 'has-value' : '';
      }

      return '';
    },
    value_value() {
      return this.modelValue;
    },
    type_to_search_message_shown() {
      if (this.min_input_length) {
        return this.type_to_search_message + ' (minimaal ' + this.min_input_length + ' karakters)';
      }
    },
    /**
     * Deze is nodig om automatisch te kunnen switchen tussen user-provided options, of de
     * opties die komen van een API call
     */
    optionsArray() {
      if (this.api_url.length) {
        let concat = this.apiOptions;

        if (Array.isArray(this.api_prefilled_option)) {
          concat = this.apiOptions.concat(this.api_prefilled_option);
        } else if (this.api_prefilled_option !== null && Object.keys(this.api_prefilled_option).length) {
          concat = this.apiOptions.concat([this.api_prefilled_option]);
        }
        if (Array.isArray(this.selectedOptions)) {
          concat = concat.concat(this.selectedOptions);
        }

        // // En hier moeten we nog even filteren op de zoek tekst zodat vooraf ingevulde waarden
        // // niet zichtbaar zijn zolang er _geen_ zoekopdracht is, en
        // // niet zichtbaar zijn als er _geen_ match met de zoekopdracht is
        concat = concat.map(option => {
          const newOptionData = {};
          newOptionData[this.optionsLabelProperty] = option[this.optionsLabelProperty];
          newOptionData[this.optionsValueProperty] = option[this.optionsValueProperty];
          this.extraOptionDataProperties.forEach(extraOptionDataProperty => {
            if (option[extraOptionDataProperty] !== undefined) {
              newOptionData[extraOptionDataProperty] = option[extraOptionDataProperty];
            }
          });
          if (option.group !== undefined) {
            newOptionData.group = option.group;
            newOptionData[this.optionsValueProperty] = option.group; // Voorkomen van wegfilteren van meerdere groups
          }
          // // Zo maken we alles onzichtbaar als de gebruiker nog niet genoeg input heeft gegeven
          // let visible = false;
          // if(this.query.length >= this.min_input_length) {
          //   visible = Utility.string_contains_query(option[this.optionsLabelProperty], this.query)
          // }

          newOptionData.visible = this.query.length >= this.min_input_length;
          return newOptionData;
        });

        return Utility.remove_duplicates_from_array_by_key(concat, this.optionsValueProperty);

        // Op deze manier blijven vanuit de API geselecteerde opties persisted zolang de
        // gebruiker geen nieuwe kiest, maar de data wel overschreven wordt vanuit een nieuwe request
        // concat = concat.concat(Array.isArray(this.selectedOptions) && this.selectedOptions.length ? this.selectedOptions : [this.selectedOptions]);
        // De originele waarde kan in de API return waardes zitten en voor key errors zorgen en het component
        // stuk maken. Met deze voorkomen we dat.
        // return Utility.remove_duplicates_from_array_by_key(concat, 'id');
      }

      return this.options;
    },
    filterable() {
      return !this.api_url.length;
    },
    erasable_value() {
      // Als het een multi-select betreft wil je hem altijd erasable hebben
      if (this.multiple) {
        return true;
      }
      return this.erasable;
    },
    no_options_message() {
      if (this.api_url.length && this.min_input_length && this.query.length >= this.min_input_length) {
        return this.no_results_message;
      } else if (this.options.length === 0) {
        return this.no_results_message;
      }
      return '';
    },

    /**
     * Function Overrides
     *
     */
    // https://vue-select.org/api/props.html#filter
    filter() {
      return (options, search) => {

        // Als de options option-groups bevatten
        // dan willen we eerst de volledige array ombouwen
        // vervolge
        const categorizedOptions = {};

        // Als er geen categorien zijn, laten we deze hieronder weer compleet links liggen
        let lastGroup = 0;
        categorizedOptions[lastGroup] = [];
        options.forEach(option => {
          if (typeof option.group !== 'undefined') {
            lastGroup = option.group;
            categorizedOptions[lastGroup] = [];
          } else {
            categorizedOptions[lastGroup].push(option);
          }
        });

        // Als er opties waren
        if (lastGroup !== 0) {
          // Nu loopen we door alles heen en filteren we de non-matches
          Object.keys(categorizedOptions).forEach(category => {
            categorizedOptions[category] = categorizedOptions[category].filter(option => {
              const label = this.getOptionLabel(option);
              return Utility.matches_filter_query(label, search);
            });
          });

          const filteredOptionGroups = [];

          // En finally, bouwen we hem weer terug door er één lange array van te maken
          // met enkel de groups die nog options hebben, te laten
          Object.keys(categorizedOptions).forEach(category => {
            if (categorizedOptions[category].length > 0) {
              // Eerst de groep, met dynamische label / options
              const groupData = {group: category};
              groupData[this.optionsLabelProperty] = '';
              groupData[this.optionsValueProperty] = '';
              filteredOptionGroups.push(groupData);
              categorizedOptions[category].forEach(option => {
                filteredOptionGroups.push(option);
              });
            }
          });

          return filteredOptionGroups;
        }

        // Als er geen groups zijn, kunnen we de default filtering toepassen
        return options.filter(option => {
          let label = this.getOptionLabel(option);
          if (typeof label === "number") {
            label = label.toString();
          }
          return Utility.matches_filter_query(label, search);
        });
      };
    }
  },

  watch: {
    invalidValue: {
      immediate: true,
      handler(isInvalid) {
        if (isInvalid) {
          // Empty the value als hij niet bestaat zodat we geen invalide waardes vast kunnen houden
          this.$emit('update:modelValue', '');
          // En we laten 5 seconden de boodschap aan de gebruiker zien
          this.showInvalid = true;
          setTimeout(() => {
            this.showInvalid = false;
          }, 5000);
        }
      }
    },
    isOpen(value) {
      // Doordat de VueSelect2 "append to body" is, zal elke klik registratie _behalve_ het selecteren
      // van een option, MediModals sluiten. Door deze watcher kan een MediModal uitlezen of er iets open
      // staat, en die sluiting tegen gaan.
      this.$store.commit('settings/modals/set_open_select', value);
    }
  },

  created() {
    //
  },

  mounted() {
    // Deze moet hier om even de directe load te triggeren als er geen min length is
    if (this.fetch_on_load) {
      this.fetchOptions('', () => {
      }, true);
    }
  },

  beforeUnmount() {
    // Met deze resetten we de modal waarde (via de isOpen watcher),
    // anders kan hij per ongeluk op false blijven, en daarmee voorkomen
    // dat andere modals gesloten kunnen worden
    this.isOpen = false;
  },

  methods: {
    dropdownShouldOpen(VueSelect) {
      nextTick(() => {
        this.forceClose = false;
      });

      return VueSelect.open && this.forceClose === false;
    },
    onInput(value) {
      this.$emit('update:modelValue', value);
    },
    onSelected(event) {
      this.selectedOptions = event;
      // Event name is dan gelijk met de "oude" MedimoSelect2
      this.$emit('option:selected', event);
    },
    onDeselected(event) {
      if (Array.isArray(this.selectedOptions)) {
        this.selectedOptions = Utility.remove_object_from_array_by_key(this.selectedOptions, this.optionsValueProperty, event[this.optionsValueProperty]);
      }
      this.forceClose = true;

      // Event name is dan gelijk met de "oude" MedimoSelect2
      this.$emit('option:deselected', event);
    },
    getOptionLabel(option) {
      // https://vue-select.org/api/props.html#getoptionlabel
      if (typeof option === 'object') {
        if (!option.hasOwnProperty(this.optionsLabelProperty)) {
          return console.warn(
            `[vue-select warn]: Label key "option.${this.label}" does not` +
            ` exist in options object ${JSON.stringify(option)}.\n` +
            'https://vue-select.org/api/props.html#getoptionlabel'
          );
        }
        return option[this.optionsLabelProperty];
      }
      return option;
    },
    fetchOptions(search, loading, firstLoad = false) {
      this.query = search;
      if (search.length < this.min_input_length && firstLoad === false) {
        return;
      }
      if (this.api_url.length === 0) {
        return;
      }

      loading(true);

      let api_url = this.api_url;
      if (this.api_request_type.toLowerCase() === 'get') {
        // Als het een api url zit met een parameter er in gebakken, voegen we 'm toe
        if (this.api_url.includes('?')) {
          api_url += '&' + this.api_query_parameter + '=' + search;
        } else {
          api_url += '?' + this.api_query_parameter + '=' + search;
        }
      } else {
        this.api_data[this.api_query_parameter] = search;
      }

      // Bij een GET request moeten we de api data ook als url parameters meegeven
      if (this.api_request_type.toLowerCase() === 'get' && Object.keys(this.api_data).length) {
        Object.keys(this.api_data).forEach(data_key => {
          if (Array.isArray(this.api_data[data_key])) {
            // Uitzondering om de API goed aan te sturen voor sorting...
            // We gaan er hier gemakshalve van uit dat we in een select2 altijd ASC sorteren
            if (data_key === 'sort_columns') {
              this.api_data[data_key].forEach(arrayData => {
                api_url += '&sort_columns[' + arrayData + ']=ASC';
              });
            } else {
              this.api_data[data_key].forEach(arrayData => {
                api_url += '&' + data_key + '[]=' + arrayData;
              });
            }
          }
          // Nulls laten we achterwege want die komen als string binnen
          else if (this.api_data[data_key] !== null) {
            api_url += '&' + data_key + '=' + this.api_data[data_key];
          }
        });
      }

      this.dispatchThrottled('api/' + this.api_request_type.toLowerCase() + 'Endpoint', {
        endpoint: api_url,
        data: this.api_data,
      }).then(response => {
        // Dit is echt top code 😆 Helaas een viertal opties die dicht bij elkaar liggen
        if (typeof response.data.data !== 'undefined') {
          if (this.api_result_property.length) {
            this.apiOptions = response.data.data[this.api_result_property];
          } else {
            this.apiOptions = response.data.data;
          }
        } else {
          if (this.api_result_property.length) {
            this.apiOptions = response.data[this.api_result_property];
          } else {
            this.apiOptions = response.data;
          }
        }
        loading(false);
      }).catch(error => {
        if (error.message !== 'Throttled') {
          loading(false);
        }
      });
    },
  },

  unmounted() {
    //
  }
};
</script>
