<template>
    <medimo-form-row margin_classes="mb-0" class="schema-form-row">
        <medimo-form-column column_classes="col-3">
            <medimo-button-link icon="fa-solid fa-plus" padding_class="px-0 py-0" id="addNextDateLink" @click="addDate">
                Regel toevoegen
            </medimo-button-link>
        </medimo-form-column>
        <medimo-form-column column_classes="col">
          <medimo-button-link v-if="hasHistoricData" icon="fa-solid fa-up-right-from-square" padding_class="px-0 py-0" @click="showManualDosingHistory = true">
            Historie handmatig schema
          </medimo-button-link>
          <manual-dosing-history-modal
              v-if="showManualDosingHistory"
              @closed="showManualDosingHistory = false"
              :dosage_instruction="dosage_instruction">
          </manual-dosing-history-modal>

            <!-- Empty, needed to keep in sync with the DateRow -->
        </medimo-form-column>
        <medimo-form-column column_classes="col-auto">
            <medimo-button-link
                    icon_position="right"
                    padding_class="px-0 py-0" id="clearManualDosingSchema"
                    icon="fa-solid fa-trash-can" title="Reset"
                    @click="clear">
            </medimo-button-link>
        </medimo-form-column>
    </medimo-form-row>
</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import Utility from '@/vue/utility/utility';
import moment from 'moment-mini';
import MedimoFormRow from '@/vue/components/general/form/base/MedimoFormRow';
import MedimoFormColumn from '@/vue/components/general/form/base/MedimoFormColumn';
import MedimoButtonLink from '@/vue/components/general/MedimoButtonLink';
import MediModal from '@/vue/components/general/MediModal';
import ManualDosingHistoryModal from '@/vue/components/modals/ManualDosingHistoryModal';

export default {
  emits: ['add_date','clear'],
  components: {
    MediModal,
    MedimoFormRow,
    MedimoFormColumn,
    MedimoButtonLink,
    ManualDosingHistoryModal,
  },

  props: {
    dates: {required: true},
    times: {required: true},
    create_template: {required: true},
    use_fixed_date: {required: true},
    dosage_instruction: {required: true}
  },

  mixins: [BaseComponentsMixin],

  data: function () {
    return {
      showManualDosingHistory: false,
    };
  },

  computed: {
    nextDateTitle: function () {
      if (this.create_template === true || !this.use_fixed_date) {
        return 'Dag' + (this.dates.length + 1);
      }
      return this.nextDate().format('DD-MM-YYYY');
    },
    lastDate: function () {
      return this.dates[this.dates.length - 1];
    },
    hasHistoricData: function () {
      return this.dosage_instruction && this.dosage_instruction.mutation && moment(this.dosage_instruction.start_datetime) < moment() && this.dosage_instruction.isManualDosingSchema;
    }
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    nextDate: function () {
      const date = Utility.deep_clone(this.lastDate);
      return moment(date).add(1, 'days');
    },
    clear: function () {
      this.$emit('clear');
    },
    addDate() {
      this.$emit('add_date');
    }
  },

  watch: {
    //
  }
};
</script>
