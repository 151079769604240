<template>
  <div class="d-inline copy-print-status-table-buttons">
    <medimo-copy-table-button
        class="ml-2"
        tooltip_content_start="Kopiëer alle tabellen naar klembord"
        row_component_css_selector=".medimo-tr.tr"
        :cell_component_css_selector="['.medimo-td.td', '.medimo-th.th']"
        :container_component="container_component"
        :trigger="trigger"
        text_class="text-medimo"
        :extra-data="extraData"
    >
      <slot></slot>
    </medimo-copy-table-button>
  </div>
</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import MedimoCopyTableButton from '@/vue/components/general/MedimoCopyTableButton';

export default {
  components: {MedimoCopyTableButton},

  props: ['container_component', 'trigger'],

  mixins: [
    BaseComponentsMixin,
  ],

  data: function () {
    return {
      extraData: [],
    };
  },

  computed: {
    //
  },

  created() {
    //
  },

  mounted() {
    // Episodes, Indications, Intolerances
    const eiiDataColumns = this.container_component.$el.querySelectorAll('.eii-container .data-column');

    eiiDataColumns.forEach(column => {
      // Eerst de header
      this.extraData.push(column.querySelector('strong').innerText);
      // Dan de regels eronder
      column.querySelectorAll('.p-1.ml-1').forEach(row => {
        this.extraData.push(row.innerText);
      });

      this.extraData.push('‎'); // Lege row om af te sluiten
    });
  },

  methods: {
    //
  },

  watch: {
    //
  }
};
</script>
