<template>
    <medi-modal
        :loading="loading"
        title="Episodes historie"
        size="medium"
        :patient_id="patient_id"
        @closed="onClosed()"
    >
        <medimo-table
            :read_only="true"
            :enable_filter="false"
            :number_of_rows="episodes_history.length"
            :copyable="true"
        >
            <medimo-thead></medimo-thead>
            <medimo-tbody>
                <medimo-tr :disabled="true" :key="episodes_history.id" v-for="history in episodes_history">
                    <medimo-td col_width="12">
                        <span class="font-weight-bold">
                          {{ history.title }}
                        </span>
                        <br />
                        <span v-for="log in history.log.data">
                            <span v-html="log"></span><br />
                        </span>
                        <span v-html="history.log.by"></span>
                    </medimo-td>
                </medimo-tr>
            </medimo-tbody>
        </medimo-table>

    </medi-modal>
</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import moment from 'moment-mini';

export default {
  emits: ['closed'],
  props: [
    'trigger',
    'patient_id'
  ],

  mixins: [
    BaseComponentsMixin
  ],

  data: function () {
    return {
      loading: false,
      picked: '',
    };
  },

  computed: {
    episodes_history() {
      const episodes_history = this.$store.state.episodes.history[this.patient_id];

      if (typeof episodes_history === 'undefined') {
        return [];
      }

      return episodes_history;
    },
  },

  mounted() {
    this.fetchHistory();
  },

  methods: {
    fetchHistory() {
      // If don't already have some history, we show the loader
      if (this.episodes_history.length === 0) {
        this.loading = true;
      }
      this.$store.dispatch('episodes/fetchHistory', {
        patient_id: this.patient_id
      }).then(() => {
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },

    onClosed(event) {
      this.$emit('closed');
    },
  },

  watch: {
    //
  }
};
</script>
