import {GeneratedTimeObject} from '@/domain/models/generated/GeneratedTimeObject';

export class TimeObject extends GeneratedTimeObject { 
  public formatToStandardTime(): string {
    const formattedHour: string = String(this.hour).padStart(2, '0');
    const formattedMinutes: string = String(this.minutes).padStart(2, '0');
    return formattedHour + ':' + formattedMinutes;
  }

  public formatTimeWithLeadingZero(): string {
    return this.getTimeString(true);
  }

  public formatTimeWithOutLeadingZero(): string {
    return this.getTimeString(false);
  }

  public inMinutes(): number | null {
    if (this.hour === null || this.minutes === null) {
      return null;
    }

    return this.hour * 60 + this.minutes;
  }

  private getTimeString(leadingZero = false): string {
    const formattedHour: string = leadingZero ? String(this.hour).padStart(2, '0') : String(this.hour);
    const formattedMinutes: string = String(this.minutes).padStart(2, '0');
    return formattedHour + formattedMinutes;
  }
}
