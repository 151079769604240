<template v-if="typeof quick_filter_value.visible === 'undefined' || quick_filter_value.visible === true">
    <div :class="'quick-filter-group ' + (!hasActiveQuickFilters ? 'inactive' : '')">
      <!--
        De truc is hier om de store_name op null te zetten zodat de default
        behaviour niet getriggerd wordt, en we hier een andere actie aan kunnen roepen
      -->
      <medimo-quick-filter-button
          v-for="(quick_filter_value, quick_filter_name) in quick_filter.group"
          :key="quick_filter_name"
          :quick_filter_name="quick_filter_name"
          :quick_filter_value="quick_filter_value"
          :store_name="null"
          :is_active="isActive(quick_filter_name)"
          @toggled="toggled(quick_filter_name)"
      >
      </medimo-quick-filter-button>
    </div>
</template>

<script>

/**
 * Deze gebruiken we bij ButtonGroups zodat die set elkaar kan togglen, OR ipv AND
 */
import MedimoQuickFilterButton from './MedimoQuickFilterButton';

export default {
  components: {
    MedimoQuickFilterButton,
  },

  props: {
    quick_filter: {required: true},
    store_name: {required: true},
    quick_filter_group_name: {required: true},
  },

  mixins: [],

  data: function () {
    return {
      //
    };
  },

  computed: {
    hasActiveQuickFilters() {
      let hasActiveQuickFilters = false;

      Object.keys(this.quick_filter.group).forEach(quick_filter_name => {
        if (this.quick_filter.group[quick_filter_name] === true) {
          hasActiveQuickFilters = true;
        }
        if (typeof this.quick_filter.group[quick_filter_name].value !== 'undefined' && this.quick_filter.group[quick_filter_name].value) {
          hasActiveQuickFilters = true;
        }
      });

      return hasActiveQuickFilters;
    }
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    toggled(quick_filter_name) {
      this.$store.commit(this.store_name + '/toggle_grouped_quick_filter', {
        quick_filter_name: quick_filter_name,
        quick_filter_group_name: this.quick_filter_group_name,
      });
    },
    isActive(quick_filter_name) {
      // Hier haalt hij de status op in de groep, omdat de structuur anders is kan
      // dat niet in de MedimoQuickFilterButton, maar gebruiken we daarvoor de is_active property
      const quickFilter = this.$store.getters[this.store_name + '/quick_filters'][this.quick_filter_group_name]['group'][quick_filter_name];
      if (typeof quickFilter === 'object') {
        return quickFilter.value;
      }

      return quickFilter;
    }
  },

  watch: {
    //
  }
};
</script>
