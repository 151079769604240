import {DispenseRequestWarning} from '@/domain/models/DispenseRequestWarning';

export class GeneratedDispenseRequestWarning { 
  public text: string;
  public additionalText: string | null;
  public reimbursedConditionsWarning: boolean;

  constructor(text: string, additionalText: string | null = null, reimbursedConditionsWarning: boolean) {
    this.text = text;
    this.additionalText = additionalText;
    this.reimbursedConditionsWarning = reimbursedConditionsWarning;
  }

  public static fromJson(json: unknown): DispenseRequestWarning {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new DispenseRequestWarning(
      parsedJson['text'] as string,
      parsedJson['additionalText'] as string,
      parsedJson['reimbursedConditionsWarning'] as boolean,
    );
  }

  public static multipleFromJson(json: unknown): DispenseRequestWarning[] {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    const items: DispenseRequestWarning[] = [];
    for (const item of parsedJson) {
      items.push(this.fromJson(item));
    }
    return items;
  }
}
