'use strict';

class OptionHelper {
  static actionOption = 1;
  static filterOption = 2;
  static filterStatusOption = 3;
  static startOption = 4;
  static stopOption = 5;
  static changeOption = 6;
  static stopFoundStatusOption = 7;
  static customQuestionOption = 8;
  static customAnswerOption = 9;

  constructor(language) {
    this.language = language;
  }

  getTitle(item) {
    const type = item.type;

    switch (type) {
      case OptionHelper.filterOption:
        return {txt: '', html: item.title};
      case OptionHelper.actionOption:
        return this.getActionTitle(item.values);
      case OptionHelper.startOption:
        return {txt: this.getStartStatusTitle(item.values, true), html: ''};
      case OptionHelper.changeOption:
        return {txt: this.getStartStatusTitle(item.values, false), html: ''};
      case OptionHelper.stopOption:
        return {txt: this.getStopStatusTitle(item.values), html: ''};
      case OptionHelper.stopFoundStatusOption:
        return {txt: this.getStopStatusTitle(item.values), html: ''};
      case OptionHelper.customQuestionOption:
        return {txt: item.values.question, html: 'Stel vraag: <br />'};
      case OptionHelper.customAnswerOption:
        return {txt: item.values.answer, html: 'Antwoord optie: <br />'};
      default:
        return {txt: item.title, html: ''};
    }
  }

  getActionTitle(values) {
    let startAfterText = '';

    if (values['startAfterDays'] !== '0' && values['startAfterDays'] !== 0) {
      if (values['startAfterDays'] === '1') {
        startAfterText = ' (start na 1 dag)';
      } else {
        startAfterText = ' (start na ' + values['startAfterDays'] + ' dagen)';
      }
    }
    return {html: '<b>Voeg actie toe:</b><br />', txt: values['name'] + startAfterText};
  }

  getStartStatusTitle(values, start) {
    const drugName = values['drugName'];
    const usageType = values['usageType'];
    let prettyUsageType;
    if (usageType === 'T') {
      const daysDuration = values['daysDuration'];
      prettyUsageType = ' tijdelijk';
      if (typeof daysDuration !== 'undefined') prettyUsageType += this._getDaysDuration(daysDuration);
    } else if (usageType === 'S') prettyUsageType = ' handmatig schema';
    else if (usageType === 'C') prettyUsageType = ' continue';

    let title;
    if (start === true) {
      title = 'START ';
    } else {
      title = 'WIJZIG ';
    }
    title += drugName;

    let titleStartMoment = this._getStartMomentText(values);

    const startAfterDays = values['startAfterDays'];
    if (start) {
      if (startAfterDays === '1') titleStartMoment += ' (na minimaal ' + startAfterDays + ' dag)';
      else if (startAfterDays !== '0') titleStartMoment += ' (na minimaal ' + startAfterDays + ' dagen)';
    } else {
      if (startAfterDays === '1') titleStartMoment += ' (minimaal ' + startAfterDays + ' dag na voorganger)';
      else if (startAfterDays !== '0') titleStartMoment += ' (minimaal ' + startAfterDays + ' dagen na voorganger)';
    }


    title += ' ' + titleStartMoment;
    title += ', ' + prettyUsageType;

    return title;
  }

  getStopStatusTitle(values) {
    let drugName = values.drugName;

    if (typeof values.drugName === 'undefined') {
      drugName = 'gevonden medicatieregel(s)';
    }

    let title = 'STOP ';
    title += drugName;
    let titleStartMoment = this._getStartMomentText(values);
    const startAfterDays = values.startAfterDays;
    if (startAfterDays === '1') titleStartMoment += ' (minimaal ' + startAfterDays + ' dag na voorganger)';
    else if (startAfterDays !== '0') titleStartMoment += ' (minimaal ' + startAfterDays + ' dagen na voorganger)';
    title += ' ' + titleStartMoment;

    return title;
  }

  _getDaysDuration(daysDuration) {
    if (daysDuration === '1') {
      return ' voor ' + daysDuration + ' dag';
    } else {
      return ' voor ' + daysDuration + ' dagen';
    }

  }

  _getStartMomentText(values) {
    let titleStartMoment = '';
    const startMoment = values['startMoment'];
    if (startMoment === '4') titleStartMoment += 'per direct';
    else if (startMoment === '0') titleStartMoment += 'per vandaag';
    else if (startMoment === '1') titleStartMoment += 'per morgen';
    else if (startMoment === '2') titleStartMoment += 'per ' + this.language['weekdoos'];
    return titleStartMoment;
  }
}

module.exports = OptionHelper;
