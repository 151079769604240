window.axios = require('axios');
/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */
window.axios.defaults.headers.common = {
  'X-Requested-With': 'XMLHttpRequest',
  // 'X-CSRF-TOKEN': csrfToken
};

// Set the default timeout to 30 seconds, also the timeout of PHP for most requests
window.axios.defaults.timeout = 60000;
window.axios.defaults.maxContentLength = Infinity;
window.axios.defaults.maxBodyLength = Infinity;

/**
 * Because Vue converts all arrays and objects to observers, this can really screw up the logging when debugging
 * You'll never get a snapshot at that point, but the value at the time when you open it, at which point
 * it might have changed a few times already. To circumvent this, we auto-convert Objects and Arrays before logging
 * for the expected behaviour.
 */
const log = console.log;

console.logVue = function () {
  const args = Array.from(arguments);

  // Loop through all the arguments in search of arrays or objects (skipping nulls, which are for some reason considered an object as well)
  args.forEach((arg, index) => {
    if ((typeof arg === 'object' && arg !== null) || Array.isArray(arg)) {
      args[index] = JSON.parse(JSON.stringify(arg));
    }
  });

  // Add the prefix so it's easy to see the console.log has been edited
  args.unshift('MEDIMO.CONSOLE.LOG:');

  // These 4 keep the console from cluttering. By default a trace is folded out causing the console to fill up with code lines
  // by formatting it like this, it's a single line with 2 fold-outs, one are the variables in detail, the second the stacktrace so
  // you can easily see where the console.log was triggered
  console.groupCollapsed(args);
  log.apply(console, args);
  console.trace();
  console.groupEnd();
};
