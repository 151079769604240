import BaseModule from '@/vue/store/modules/base_module';
import moment from 'moment-mini';

// initial state
const state = {
  ...BaseModule.state,

  data: [],

  options: [],

  quick_filters: {

  },

  toggleQuickFilters: true,
};

// getters
const getters = {
  ...BaseModule.getters,

  filteredOptionsGroep(state) {
    return state.options.filter((option) => {
      return option.type === 'groep';
    });
  },
  filteredOptionsStof(state) {
    return state.options.filter((option) => {
      return option.type === 'SNK';
    });
  },
  filteredOptionsStofEnToedien(state) {
    return state.options.filter((option) => {
      return option.type === 'SSK';
    });
  },
  filteredOptionsPreparaat(state) {
    return state.options.filter((option) => {
      return option.type === 'HPK';
    });
  },

  checkedOptionsGroep(state) {
    return state.options.filter((option) => {
      return option.type === 'groep' && option.checked === true;
    });
  },
  checkedOptionsStof(state) {
    return state.options.filter((option) => {
      return option.type === 'SNK' && option.checked === true;
    });
  },
  checkedOptionsStofEnToedien(state) {
    return state.options.filter((option) => {
      return option.type === 'SSK' && option.checked === true;
    });
  },
  checkedOptionsPreparaat(state) {
    return state.options.filter((option) => {
      return option.type === 'HPK' && option.checked === true;
    });
  },

  allCheckedOptions(state) {
    return state.options.filter((option) => {
      return option.checked === true;
    });
  }
};

// actions
const actions = {
  ...BaseModule.actions,

  fetchOptions({state, commit, dispatch, getters, rootState}, payload) {

    const endpoint = '/api/v1/patients/' + payload.patient_id + '/intolerances/zindex-options/' + payload.type + '/' + payload.id;
    return dispatch('api/getEndpoint', {
      endpoint: endpoint,
      data: {} // Empty, since it's a GET
    }, {root: true}).then(response => {
      commit('set_options', {options: response.data.data});
    });
  },
};

// mutations
const mutations = {
  ...BaseModule.mutations,

  set_options(state, payload) {
    state.options = payload.options;
  },
  toggle_option(state, payload) {

    // find option by type and id
    let indexToChange = null;
    state.options.forEach((option, key) => {
      if (option.id === payload.option.id && option.type === payload.option.type) {
        indexToChange = key;
      }
    });

    // update option
    if (indexToChange !== null) {
      state.options[indexToChange] = payload.option;
    }
  }
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
