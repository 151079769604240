<script>

export default {
  components: {},

  props: {

  },

  mixins: [

  ],

  data: function () {
    return {
      //
    };
  },

  computed: {

  },

  created() {
    //
  },

  mounted() {
    document.addEventListener('keydown', this.printListener);
  },

  beforeUnmount() {
    document.removeEventListener('keydown', this.printListener);
  },

  methods: {
    printHandler() {
      if (typeof this.printButtonHandler === 'function') {
        this.printButtonHandler();
        return true;
      }
      console.error('this.printButtonHandler is undefined by a component that uses the PrintsHtml Mixin');
    },
    printListener(e) {
      if ((e.ctrlKey || e.metaKey) && (e.key === "p")) {
        this.printHandler();
        e.cancelBubble = true;
        e.preventDefault();
        e.stopImmediatePropagation();
      }
    },
    printHtml(html) {
      // open window om te printen content te renderen
      const WinPrint = window.open('', '', 'left=300,top=100,width=705,height=400,toolbar=0,scrollbars=0,status=0');

      WinPrint.document.write('<html><head><style>body { font-family: "Open Sans", Arial, Helvetica, "sans-serif"; font-size:12px; font-style:normal; font-weight:400; }</style>');
      WinPrint.document.write('<style>');

      // voeg open sans font toe
      WinPrint.document.write('@font-face {\n' +
        '  font-family: "Open Sans", Arial, Helvetica;\n' +
        '  font-style: normal;\n' +
        '  font-weight: 400;\n' +
        '  src: url(/fonts/open-sans-v17-latin-ext-regular.eot?6035727c9748b7f19151ed3946cb8882);\n' +
        '  /* IE9 Compat Modes */\n' +
        '  src: local("Open Sans Regular"), local("OpenSans-Regular"), url(/fonts/open-sans-v17-latin-ext-regular.eot?6035727c9748b7f19151ed3946cb8882) format("embedded-opentype"), url(/fonts/open-sans-v17-latin-ext-regular.woff2?be21e759f9abd15b8cef68d92cedc081) format("woff2"), url(/fonts/open-sans-v17-latin-ext-regular.woff?b7b7c77b83e9d67f6756aa2716f35eba) format("woff"), url(/fonts/open-sans-v17-latin-ext-regular.ttf?615065f99828a8f1423c48ce21ddfffb) format("truetype"), url(/fonts/open-sans-v17-latin-ext-regular.svg?7aab4c13671282c90669eb6a10357e41) format("svg");\n' +
        '  /* Legacy iOS */\n' +
        '}');

      // in window is alleen de melding 'Printen wordt voorbereid...' zichtbaar
      WinPrint.document.write('@media screen { #screen { display: block; font-size:16px; } #print { display: none; } }');
      WinPrint.document.write('@media print { #print { display: block; } #screen { display: none; } }');

      WinPrint.document.write('</style></head><body>');

      // loading melding
      WinPrint.document.write('<div id="screen">' +
        '<img class="medimo-icon-is-spinning" src="/dist/assets/loader.svg" style="float:left;" />' +
        '   <span style="float:left; margin-left:10px; margin-top:5px;">Printen wordt voorbereid...</span>' +
        '</div>');

      // te printen html
      WinPrint.document.write('<div id="print">' + html + '</div>');

      WinPrint.document.write('</body></html>');

      WinPrint.document.close(); // necessary for IE >= 10
      WinPrint.focus(); // necessary for IE >= 10*/
      WinPrint.print();
      WinPrint.close();
    },
    addMedimoTableCss(html) {
      // custom styling voor printen
      html += '<style>';
      html += '.medimo-table-content { float:left; clear:both; width:100%; margin-bottom:30px; font-size:9px; }';
      html += '.medimo-table-content.no-rows { margin-bottom:0px; }';
      html += '.col, .th  { min-height:10px; }';
      html += '.medimo-tr { width:100%; clear:both; }';
      html += '.medimo-th { float:left; width:30%; border-bottom:1px solid #999; font-weight:bold; }';
      html += '.col-sm-3  { float:left; width:25%; }';
      html += '.col-sm-4  { float:left; width:35%; }';
      html += '.col-sm-5  { float:left; width:40%; }';
      html += '.text-center  { margin-bottom:30px; }';
      html += '.col-sm-1  { float:left; width:10%; border:none; }';
      html += '</style>';

      return html;
    }
  },

  watch: {
    //
  }
};
</script>
