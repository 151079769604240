import BaseModule from '@/vue/store/modules/base_module';

// initial state
// shape: [{ id, quantity }]
const state = {
  store_namespace: 'patients',
  ...BaseModule.state,
  data: [],
  active_patient_id: false,
  search_endpoint: '/api/v1/patients/search',
  prescriberPresence: {}, // patientId => presenceCount
};

const getters = {
  ...BaseModule.getters,
  prescriberPresence(state) {
    return state.prescriberPresence;
  },
  get_active_actions(state, getters, rootState, rootGetters) {
    return (patient_id) => {
      return getters.find(patient_id).actions.filter(action => {
        return action.isActive == true;
      });
    };
  },
};

const mutations = {
  ...BaseModule.mutations,
  setPrescriberPresence(state, payload) {
    state.prescriberPresence[payload.patientId] = payload.presence;
  },
};

const actions = {
  ...BaseModule.actions,
  get_default_times({state, commit, dispatch, getters, rootState, rootGetters}, patientId) {
    const patient = getters['find'](patientId);
    return rootGetters['wards/default_times'](patient.ward.id); //TODO should be patient.ward_id?
  },
  add_to_data({state, commit, dispatch, getters, rootState}, payload) {
    commit('add_to_data', payload);

    // Add the episodes
    commit('episodes/clear_data', [], {root: true});
    payload.episodes.forEach(model => {
      commit('episodes/add_to_data', model, {root: true});
    });

    // Add the Intolerances
    commit('intolerances/clear_data', [], {root: true});
    if (payload.intolerances === null) {
      commit('intolerances/set_data', null, {root: true});
    } else {
      payload.intolerances.forEach(model => {
        commit('intolerances/add_to_data', model, {root: true});
      });
    }

    // Add the Contra Indicaties
    commit('indications/clear_data', [], {root: true});
    if (payload.indications === null) {
      commit('indications/set_data', null, {root: true});
    } else {
      payload.indications.forEach(model => {
        commit('indications/add_to_data', model, {root: true});
      });
    }

    // Add the ward
    commit('wards/add_to_data', payload.ward, {root: true});
  },
  fetch_single({state, commit, dispatch, getters, rootState}, payload) {
    return dispatch('api/getEndpoint', {
      endpoint: '/api/v1/patients/' + payload.id
    }, {root: true}).then(response => {
      dispatch('add_to_data', response.data.data);
    });
  },

  update_action_from_patient({state, commit, dispatch, getters, rootState}, payload) {
    return dispatch('actions/update', payload, {root: true})
      .then(response => {
        const action = response.data.data;
        // Vernieuw patient-data (o.a. aantal openstaande acties)
        // Haal automatisch alle patient data op en ververst daarmee ook direct zijn / haar acties
        dispatch('fetch_single', {id: action.patientId});

        if (payload.refresh_patient_medication_data) {
          dispatch('initial_data/trigger_refresh_medication', payload.patient_id, {root: true});
        }
      });
  },

  remove_action_from_patient({state, commit, dispatch, getters, rootState}, payload) {
    const patient = getters['find'](payload.patient_id);
    // Now we create a new array without the action we want to remove
    const newActions = patient.actions.filter(action => {
      return action.id !== payload.action_id;
    });
    // And then replace it:
    commit('change_property_of_model', {
      model_id: payload.patient_id,
      property_key: 'actions',
      value: newActions,
    });
  },

  update_active_chat_count({state, commit, dispatch, getters, rootState}, payload) {
    commit('change_property_of_model', {
      model_id: payload.patient_id,
      property_key: 'activeChats',
      value: {count: payload.count},
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
