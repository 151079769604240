<template>
  <!--
    De ConfirmButton werkt met een @confirmed event
    Na de eerste klik, tonen we een tweede label en moet de gebruiker
    nog een keer klikken om te bevestigen.
  -->
  <div :class="classes + ' ' + (isClicked ? 'w-100' : widthClass) + ' d-flex'">

    <medimo-notification :type="notificationType" class="mr-auto col-md-6 mr-2" v-if="isClicked">
      <span class="align-middle">
        <fa-icon icon="fa-solid fa-bell" class="bell" size="lg" /> {{ confirmLabel }}
      </span>
    </medimo-notification>

    <medimo-button :type="cancelType" v-if="isClicked" class="my-auto mr-2" @click="cancelled()">
      {{ cancelLabel }}
    </medimo-button>
    <medimo-button :class="'my-auto ' + (isClicked ? 'ml-0' : autoMargin) " :disabled="disabled" :type="type" @click.stop.prevent="clicked" v-bind="$props">
      <template v-if="!isClicked">
        <slot></slot>
      </template>
      <template v-if="isClicked">
        {{ confirmButtonLabel }}
      </template>
    </medimo-button>
  </div>
</template>

<script>

import MedimoButton from './MedimoButton';
import MedimoNotification from './MedimoNotification';

export default {
  /**
   * Zelfde als de MedimoButton maar dan moet de gebruiker 2x klikken voordat
   * de @click gefired wordt
   *
   * Accepteert alle properties die de MedimoButton ook accepteert
   */
  components: {
    MedimoNotification,
    MedimoButton
  },

  emits: ['clicked','confirmed','cancelled'],

  props: {
    // Met deze kun je de knop aanvankelijk links of rechts uitlijnen
    position: {default: 'right'},
    label: {default: 'Verwijder'},
    confirmLabel: {default: 'Weet u het zeker?'},
    confirmButtonLabel: {default: 'Bevestig'},
    cancelLabel: {default: 'Annuleer'},
    notificationType: {default: 'danger'},
    cancelType: {default: ' btn-outline-medimo'},
    type: {default: 'medimo'},
    classes: {default: ''},
    disabled: {default: false},
    resetAfter: {default: 3000},
    widthClass: {default: 'w-100'}
  },

  mixins: [],

  data: function () {
    return {
      isClicked: false,
    };
  },

  computed: {
    autoMargin() {
      if (this.position === 'right') {
        return 'ml-auto';
      }
      return 'mr-auto';
    }
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    clicked() {
      if (!this.isClicked) {
        this.isClicked = true;
        this.$emit('clicked');
        return;
      }
      // En we emitten het event voor verdere verwerking
      this.$emit('confirmed');
      // Als hij isClicked, resetten we 'm
      this.isClicked = false;
    },
    cancelled() {
      this.$emit('cancelled');
      this.isClicked = false;
    }
  },

  watch: {
    //
  }
};
</script>
