<template>
  <MediModal
      :loading="loading"
      title="Nieuwe actie"
      size="medium"
      @closed="close">
    <MedimoForm>

      <MedimoFormRow class="mb-2">
        <MedimoFormColumn column_classes="col-12">
          <MedimoValidatedSelect2
              :editable="true"
              label_column_classes="col-12 col-sm-3"
              label="Naam"
              placeholder="Naam actie"
              name="name"
              :options="templateOptions"
              v-model="templateId"
              @editedText="(event: string) => name = event"
              :editable_text="name"
          ></MedimoValidatedSelect2>
        </MedimoFormColumn>
      </MedimoFormRow>

      <MedimoFormRow class="mb-2">
        <MedimoFormColumn column_classes="col-12">
          <MedimoLabeledVueSelect2
              label_column_classes="col-12 col-sm-3"
              select2_column_classes="col-12 col-sm-9"
              label="Voorschrift"
              placeholder="Gerelateerde voorschrift (optioneel)"
              name="relatedStatusId"
              v-model="relatedStatusId"
              :options="relatedStatusIdOptions"
          ></MedimoLabeledVueSelect2>
        </MedimoFormColumn>
      </MedimoFormRow>

      <MedimoFormRow class="mb-2">
        <MedimoFormColumn column_classes="col-12">
          <MedimoLabeledTextarea
              label_column_classes="col-12 col-sm-3"
              label="Toelichting"
              placeholder="Toelichting (optioneel)"
              name="description"
              v-model="description"
              rows="3"
          ></MedimoLabeledTextarea>
        </MedimoFormColumn>
      </MedimoFormRow>

      <MedimoFormRow class="mb-2">
        <MedimoFormColumn column_classes="col-12 col-sm-3">
          <label>Verantwoordelijk</label>
        </MedimoFormColumn>

        <MedimoFormColumn column_classes="col-12 col-sm-6">

          <MedimoFilterButton @toggle="isAssignedToPhysician = !isAssignedToPhysician"
                              :active="isAssignedToPhysician"
                                extra_classes="mr-2">
            Arts
          </MedimoFilterButton>

          <MedimoFilterButton @toggle="isAssignedToCare = !isAssignedToCare"
                              :active="isAssignedToCare"
                                extra_classes="mr-2">
            Zorg
          </MedimoFilterButton>

          <MedimoFilterButton @toggle="isAssignedToPharmacy = !isAssignedToPharmacy"
                              :active="isAssignedToPharmacy"
                                extra_classes="mr-2">
            Apotheker
          </MedimoFilterButton>

        </MedimoFormColumn>
      </MedimoFormRow>

      <MedimoFormRow class="mb-2">
        <MedimoFormColumn column_classes="col-12">
          <MedimoLabeledDatePicker
              label_column_classes="col-12 col-sm-3"
              label="Te voltooien voor"
              datetime_format="DD-MM-YYYY HH:mm"
              :minDate="minDate"
              name="validUntil"
              v-model="validUntil"
          ></MedimoLabeledDatePicker>
        </MedimoFormColumn>
      </MedimoFormRow>

      <MedimoFormRow class="mb-2">
        <MedimoFormColumn column_classes="col-12 col-sm-3">
          <label>Zichtbaar voor</label>
        </MedimoFormColumn>

        <MedimoFormColumn column_classes="col-12 col-sm-9">

          <MedimoFilterButton
              @toggle="isVisibleForPhysician = !isVisibleForPhysician"
              :active="isPhysician || isVisibleForPhysician"
              :extra_classes="(isPhysician ? 'disabled ': '') + 'isVisibleToPhysician mr-2'"
              :help_text="visibleForHelpText(isPhysician)"
          >Arts
          </MedimoFilterButton>

          <MedimoFilterButton
              @toggle="isVisibleForCare = !isVisibleForCare"
              :active="isCare || isVisibleForCare"
              :extra_classes="(isCare ? 'disabled ' : '') + 'isVisibleToCare mr-2'"
              :help_text="visibleForHelpText(isCare)"
          >Zorg
          </MedimoFilterButton>

          <MedimoFilterButton
              @toggle="isVisibleForPharmacy = !isVisibleForPharmacy"
              :active="isPharmacy || isVisibleForPharmacy"
              :extra_classes="(isPharmacy ? 'disabled ' : '') + 'isVisibleToPharmacy mr-2'"
              :help_text="visibleForHelpText(isPharmacy)"
          >Apotheker
          </MedimoFilterButton>

        </MedimoFormColumn>

      </MedimoFormRow>

      <MedimoFormRow class="mb-2">
        <MedimoFormColumn column_classes="col-12 col-sm-3">
          <label>Opties</label>
        </MedimoFormColumn>
        <MedimoFormColumn column_classes="col-12 col-sm-9">
          <MedimoFilterButton
              @toggle="isPrivate = !isPrivate"
              :active="isPrivate"
              extra_classes="isPrivate mr-2"
              help_text="Markeer als privé actie">
            Prive
          </MedimoFilterButton>

          <MedimoFilterButton
              @toggle="informThem = !informThem"
              :active="informThem"
              extra_classes="informThem mr-2"
              help_text="Informeer betrokkenen dat de actie is aangemaakt">
            Informeer
          </MedimoFilterButton>

          <MedimoFilterButton
              @toggle="isRecurrent = !isRecurrent"
              :active="isRecurrent"
              extra_classes="isRecurrent mr-2"
              help_text="Actie keert terug na op te geven aantal dagen">
            Herhalen
          </MedimoFilterButton>

          <MedimoFilterButton
              @toggle="informByMail = !informByMail"
              :active="informByMail"
              extra_classes="remind mr-2"
              help_text="Stuur een extra herinneringsmail op de dag dat de actie voltooid moet worden">
            Herinner
          </MedimoFilterButton>
        </MedimoFormColumn>
      </MedimoFormRow>

      <MedimoFormRow class="mb-2" v-if="isRecurrent">
        <MedimoFormColumn column_classes="col-12 col-sm-3">
          <label>Herhalen na</label>
        </MedimoFormColumn>
        <MedimoFormColumn column_classes="col-12 col-sm-1">
          <MedimoInput
              name="repeatAfterDays"
              v-model="repeatAfterDays"
          ></MedimoInput>
        </MedimoFormColumn>
        <MedimoFormColumn column_classes="col-12 col-sm-2">
          <label>dagen</label>
        </MedimoFormColumn>
      </MedimoFormRow>

    </MedimoForm>

    <template v-slot:action-buttons>
      <MedimoButton @click="saveNewAction" class="mr-2">
        Opslaan
      </MedimoButton>
      <MedimoButton @click="close" type="outline-primary">
        Annuleren
      </MedimoButton>
    </template>

  </MediModal>
</template>

<script setup lang="ts">
import MediModal from "@/vue/components/general/MediModal.vue";
import MedimoForm from "@/vue/components/general/form/MedimoForm.vue";
import MedimoFormRow from "@/vue/components/general/form/base/MedimoFormRow.vue";
import MedimoFormColumn from "@/vue/components/general/form/base/MedimoFormColumn.vue";
import moment from 'moment-mini';
import Utility from '@/vue/utility/utility';
import MedimoFilterButton from '@/vue/components/general/form/base/MedimoFilterButton.vue';
import MedimoValidatedSelect2 from "@/vue/components/general/form/MedimoValidatedSelect2.vue";
import MedimoLabeledVueSelect2 from '@/vue/components/general/form/base/vue-select2/MedimoLabeledVueSelect2.vue';
import MedimoLabeledTextarea from "@/vue/components/general/form/base/MedimoLabeledTextarea.vue";
import MedimoLabeledDatePicker from "@/vue/components/general/form/base/MedimoLabeledDatePicker.vue";
import MedimoInput from "@/vue/components/general/form/base/MedimoInput.vue";
import MedimoButton from "@/vue/components/general/MedimoButton.vue";
import {AvailableAction} from "@/domain/models/AvailableAction";
import {Patient} from "@/domain/models/Patient";
import BasicOptions from "@/declarations/BasicOptions";
import {computed, nextTick, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import utility from "@/vue/utility/utility";

interface Props {
  patient: Patient,
  triggerModel?: string,
  triggerModelId?: string,
  relatedStatusId?: string | number,
  medications?: DosingInstruction[], // Nodig voor de
}

const props = withDefaults(defineProps<Props>(), {
  triggerModel: '',
  triggerModelId: '',
  relatedStatusId: '',
});

const store = useStore();

const emit = defineEmits(['closed', 'processed']);

const loading = ref<boolean>(false);
const templates = ref<string>('');
const name = ref<string>('');
const description = ref<string>('');
const relatedStatusId = ref<string | number>('');
const validUntil = ref<string>(moment(moment(), "DD-MM-YYYY").add(7, 'days').format('YYYY-MM-DD HH:mm'));
const minDate = ref<string>(moment().format('YYYY-MM-DD HH:mm')); // Mindate heeft ander format
const isAssignedToCare = ref<boolean>(true);
const isAssignedToPharmacy = ref<boolean>(true);
const isAssignedToPhysician = ref<boolean>(false);
const isVisibleForCare = ref<boolean>(false);
const isVisibleForPharmacy = ref<boolean>(false);
const isVisibleForPhysician = ref<boolean>(false);
const isCare = ref<boolean>(false);
const isPharmacy = ref<boolean>(false);
const isPhysician = ref<boolean>(false);
const isRecurrent = ref<boolean>(false);
const isPrivate = ref<boolean>(false);
const informThem = ref<boolean>(false);
const informByMail = ref<boolean>(false);
const repeatAfterDays = ref<number>(30);
const templateId = ref<string>('');

onMounted(() => {
  fetchTemplates();

  isAssignedToCare.value = userBaseEntityType.value === 'Care';
  isAssignedToPharmacy.value = userBaseEntityType.value === 'Pharmacy';
  isAssignedToPhysician.value = userBaseEntityType.value === 'Physician';

  isCare.value = userBaseEntityType.value === 'Care';
  isPharmacy.value = userBaseEntityType.value === 'Pharmacy';
  isPhysician.value = userBaseEntityType.value === 'Physician';

  nextTick(() => relatedStatusId.value = props.relatedStatusId);
});

const currentUser = computed(() => {
  return store.getters['current_user/data'];
});

const unfilteredTemplates = computed<AvailableAction[]>(() => {
  return store.getters['actions/state']('templates');
});

const templateOptions = computed<BasicOptions[]>(() => {
  if (!unfilteredTemplates.value.length) {
    return [{value: '', text: ''}];
  }

  const options: BasicOptions[] = [];
  unfilteredTemplates.value.forEach((item: AvailableAction) => {
    options.push({
      value: item.id.toString(),
      text: item.name
    });
  });

  return options;
});

const relatedStatusIdOptions = computed<BasicOptions[]>(() => {
  const result: DosingInstruction[] = store.getters['dosage_instructions/sorted_data'];
  const options: BasicOptions[] = [{value: '', text: ''}];

  // MED-4801
  // De AddActionModal wordt op meerdere plekken gebruikt en de dosage_instructions/sorted_data getter
  // is dan niet altijd gevuld. Dus nu is er een medications property waarmee je voorschriften ook mee kan geven zodat de voorschrift dropdown goed gevuld is. Dat was ook de bug: hij had die voorschriften niet.
  result.forEach((status: DosingInstruction) => {
    options.push({value: status.id, text: status.drug_name});
  });

  props.medications?.forEach((status: DosingInstruction) => {
    options.push({value: status.id, text: status.drug_name});
  });

  return utility.remove_duplicates_from_array_by_key(options, 'value');
});

const userBaseEntityType = computed<string>(() => {
  const entityType = currentUser.value.entityType;

  if (entityType === 'Ward' || entityType === 'Location') {
    return 'Care';
  } else if (entityType === 'Pharmacy') {
    return 'Pharmacy';
  } else if (entityType === 'Physician') {
    return 'Physician';
  } else {
    return 'Onbekend';
  }
});

function visibleForHelpText(visible: boolean): string {
  if (visible) {
    return 'Actie altijd zichtbaar voor maker';
  }
  return '';
}

function close(): void {
  emit('closed');
}

function fetchTemplates(): void {
  // only fetch when not already available
  if (templates.value.length > 0) {
    return;
  }

  loading.value = true;
  store.dispatch('actions/fetchTemplates', {}).then(() => {
    loading.value = false;
  }).catch(() => {
    loading.value = false;
  });
}

function loadTemplate(): void {
  const template = Utility.find_model_by_id(unfilteredTemplates.value, templateId.value);

  isAssignedToCare.value = template.isAssignedToCare;
  isAssignedToPharmacy.value = template.isAssignedToPharmacy;
  isAssignedToPhysician.value = template.isAssignedToPhysician;

  informThem.value = template.chatEnabled;
  repeatAfterDays.value = template.repeatAfterDays;
  validUntil.value = moment(moment(), "DD-MM-YYYY").add(template.startAfterDays, 'days').format('YYYY-MM-DD');
  isRecurrent.value = template.isRecurrent;

  if (!isCare.value) {
    isVisibleForCare.value = template.isParticipatingCare;
  }

  if (!isPharmacy.value) {
    isVisibleForPharmacy.value = template.isParticipatingPharmacy;
  }

  if (!isPhysician.value) {
    isVisibleForPhysician.value = template.isParticipatingPhysician;
  }
}

function saveNewAction(): void {
  loading.value = true;

  const data: { [key: string]: boolean | number | string } = {
    name: name.value,
    description: description.value,
    statusId: relatedStatusId.value,

    isAssignedToCare: isAssignedToCare.value,
    isAssignedToPharmacy: isAssignedToPharmacy.value,
    isAssignedToPhysician: isAssignedToPhysician.value,

    isParticipatingCare: isVisibleForCare.value,
    isParticipatingPharmacy: isVisibleForPharmacy.value,
    isParticipatingPhysician: isVisibleForPhysician.value,

    isRecurrent: isRecurrent.value,
    validUntil: validUntil.value,
    informByMail: informByMail.value,
    templateId: templateId.value,
    triggerModel: props.triggerModel,
    triggerModelId: props.triggerModelId,
    informThem: informThem.value,
    privacy: isPrivate.value,
  };

  if (isRecurrent.value && repeatAfterDays.value) {
    data.repeatAfterDays = repeatAfterDays.value;
  }

  store.dispatch('actions/store', {
    patient_id: props.patient.id,
    data: data
  }).then(() => {
    loading.value = false;
    emit('processed');
    close();
  }).catch(() => {
    loading.value = false;
  });
}

watch([templateId, isAssignedToCare, isAssignedToPharmacy, isAssignedToPhysician], (
  [newTemplateId, newIsAssignedToCare, newIsAssignedToPharmacy, newIsAssignedToPhysician],
  [prevTemplateId, prevIsAssignedToCare, prevIsAssignedToPharmacy, prevIsAssignedToPhysician]
) => {
  if (newTemplateId && newTemplateId !== prevTemplateId) {
    loadTemplate();
  }

  if (newIsAssignedToCare && newIsAssignedToCare != prevIsAssignedToCare) {
    isVisibleForCare.value = true;
  }

  if (newIsAssignedToPharmacy && newIsAssignedToPharmacy != prevIsAssignedToPharmacy) {
    isVisibleForPharmacy.value = true;
  }

  if (newIsAssignedToPhysician && newIsAssignedToPhysician != prevIsAssignedToPhysician) {
    isVisibleForPhysician.value = true;
  }
});
</script>
