import Utility from '@/vue/utility/utility';
import BaseModule from '@/vue/store/modules/base_module';

// initial state
// shape: [{ id, quantity }]
const state = {
  ...BaseModule.state,
  data: [],
};

// getters
const getters = {
  ...BaseModule.getters,
  sentry_id(state, getters, rootState, rootGetters) {
    if (rootGetters['settings/errors/sentry_id'] !== null) {
      return 'JS.LOGID:' + rootGetters['settings/errors/sentry_id'];
    }
    return '';
  },
  all() {
    // Deze getter is nodig voor het testen; zo zien we welke er in de localStorage
    // zitten en kunnen we daarop asserten
    const keys = Object.keys(localStorage);
    const sentries = [];
    keys.forEach(key => {
      sentries.push(localStorage.getItem(key));
    });
    return sentries;
  }
};

// actions
const actions = {
  ...BaseModule.actions,
  // Simple bridges to the commit
  add_to_data ({state, commit, dispatch, getters, rootState}, payload) {
    commit('add_to_data', payload);
  },
  // Simple bridges to the commit
  add_data ({state, commit, dispatch, getters, rootState}, payload) {
    commit('add_to_data', payload);
  },
  send({state, commit, dispatch, getters, rootState}, payload) {
    if (typeof payload.message === 'undefined') {
      if (typeof payload === 'string') {
        const message = payload;
        payload = {};
        payload.message = message;
      }
    }
    if (typeof payload.trace === 'undefined') {
      payload.trace = null;
    }
    if (typeof payload.setExtra === 'undefined') {
      payload.setExtra = {};
    }

    window.app.sendSentry(payload.message, payload.trace, payload.setExtra);
  }
};

// mutations
const mutations = {
  ...BaseModule.mutations,
  // We overwrite the default one, so there's no ID logic in there
  add_to_data (state, data) {
    state.data.push(data);
  },
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
