import BaseModule from '@/vue/store/modules/base_module';

// initial state
const state = {
  ...BaseModule.state,
  search_endpoint: '/api/v1/stock/wards',
  quick_filters: {},
  sort_columns: [{column_name: 'location.naam', direction: 'ASC'}],
};

// actions
const actions = {
  ...BaseModule.actions,
  fetch_single({state, commit, dispatch, getters, rootState}, payload) {
    return dispatch('api/getEndpoint', {
      endpoint: state.search_endpoint + '/' + payload.id
    }, {root: true}).then(response => {
      commit('add_to_data', response.data.data);
    });
  },
};

// getters
const getters = {
  ...BaseModule.getters,
};

// mutations
const mutations = {
  ...BaseModule.mutations
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
