import {DispenseRequest} from '@/domain/models/DispenseRequest';
import {CodeSystem} from '@/domain/models/CodeSystem';
import {TimeIntervalObject} from '@/domain/models/TimeIntervalObject';
import {PharmaceuticalProduct} from '@/domain/models/PharmaceuticalProduct';
import {CodeObject} from '@/domain/models/CodeObject';
import {HealthcareProvider} from '@/domain/models/HealthcareProvider';
import {QuantityObject} from '@/domain/models/QuantityObject';
import {AddressObject} from '@/domain/models/AddressObject';
import moment from 'moment';

export class GeneratedDispenseRequest { 
  public externalIdentifier: string;
  public externalIdentifierSystem: CodeSystem;
  public periodOfUse: TimeIntervalObject | null;
  public numberOfRefills: number | null;
  public comment: string | null;
  public medicationAgreementId: number;
  public pharmaceuticalTreatmentId: number;
  public pharmaceuticalProduct: PharmaceuticalProduct;
  public additionalWishes: CodeObject[];
  public financialIndicationCode: CodeObject | null;
  public intendedSupplier: HealthcareProvider | null;
  public amount: QuantityObject | null;
  public dateTime: moment.Moment;
  public dispenseLocation: AddressObject | null;

  constructor(externalIdentifier: string, externalIdentifierSystem: CodeSystem, periodOfUse: TimeIntervalObject | null = null, numberOfRefills: number | null = null, comment: string | null = null, medicationAgreementId: number, pharmaceuticalTreatmentId: number, pharmaceuticalProduct: PharmaceuticalProduct, additionalWishes: CodeObject[], financialIndicationCode: CodeObject | null = null, intendedSupplier: HealthcareProvider | null = null, amount: QuantityObject | null = null, dateTime: moment.Moment, dispenseLocation: AddressObject | null = null) {
    this.externalIdentifier = externalIdentifier;
    this.externalIdentifierSystem = externalIdentifierSystem;
    this.periodOfUse = periodOfUse;
    this.numberOfRefills = numberOfRefills;
    this.comment = comment;
    this.medicationAgreementId = medicationAgreementId;
    this.pharmaceuticalTreatmentId = pharmaceuticalTreatmentId;
    this.pharmaceuticalProduct = pharmaceuticalProduct;
    this.additionalWishes = additionalWishes;
    this.financialIndicationCode = financialIndicationCode;
    this.intendedSupplier = intendedSupplier;
    this.amount = amount;
    this.dateTime = dateTime;
    this.dispenseLocation = dispenseLocation;
  }

  public static fromJson(json: unknown): DispenseRequest {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new DispenseRequest(
      parsedJson['externalIdentifier'] as string,
      CodeSystem.fromJson(parsedJson['externalIdentifierSystem']),
      parsedJson['periodOfUse'] ? TimeIntervalObject.fromJson(parsedJson['periodOfUse']) : null,
      parsedJson['numberOfRefills'] as number,
      parsedJson['comment'] as string,
      parsedJson['medicationAgreementId'] as number,
      parsedJson['pharmaceuticalTreatmentId'] as number,
      PharmaceuticalProduct.fromJson(parsedJson['pharmaceuticalProduct']),
      parsedJson['additionalWishes'] ? parsedJson['additionalWishes'].map((e: unknown) => CodeObject.fromJson(e)) : null,
      parsedJson['financialIndicationCode'] ? CodeObject.fromJson(parsedJson['financialIndicationCode']) : null,
      parsedJson['intendedSupplier'] ? HealthcareProvider.fromJson(parsedJson['intendedSupplier']) : null,
      parsedJson['amount'] ? QuantityObject.fromJson(parsedJson['amount']) : null,
      moment.parseZone(parsedJson['dateTime']),
      parsedJson['dispenseLocation'] ? AddressObject.fromJson(parsedJson['dispenseLocation']) : null,
    );
  }

  public static multipleFromJson(json: unknown): DispenseRequest[] {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    const items: DispenseRequest[] = [];
    for (const item of parsedJson) {
      items.push(this.fromJson(item));
    }
    return items;
  }
}
