import BaseModule from '@/vue/store/modules/base_module';

const state = {
  ...BaseModule.state,
  optins: {},
  history: {},
};

const getters = { };

const actions = {

  fetch({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/patients/' + payload.patient_id + '/optin/index';

    return dispatch('api/getEndpoint', {
      endpoint: endpoint,
      data: {}
    }, {root: true}).then(response => {
      commit('add_to_optins', {patient_id: payload.patient_id, optins: response.data.data});
    });
  },

  update({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/patients/' + payload.patient_id + '/optin/update';

    return dispatch('api/postEndpoint', {
      endpoint: endpoint,
      data: payload.data
    }, {root: true}).then(response => {
      commit('patients/add_to_data', response.data.data, { root: true });
      commit('clear_optins', {patient_id: payload.patient_id });
    });
  },

  fetchHistory({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/patients/' + payload.patient_id + '/optin/history';

    return dispatch('api/getEndpoint', {
      endpoint: endpoint,
      data: {} // Empty, since it's a GET
    }, {root: true}).then(response => {
      commit('add_to_history', {patient_id: payload.patient_id, log_history: response.data.data});
    });
  },
};

const mutations = {
  ...BaseModule.mutations,

  add_to_optins(state, payload) {
    state.optins[payload.patient_id] = payload.optins;
  },
  clear_optins(state, payload) {
    delete state.optins[payload.patient_id];
  },
  add_to_history(state, payload) {
    state.history[payload.patient_id] = payload.log_history;
  },
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
