<script>
export default {
  components: {},

  props: [],

  mixins: [],

  data: function () {
    return {
      //
    };
  },

  computed: {
    //
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    /**
       * Copy all the content from
       *
       * @param child_components_in_kebab_case If you want to copy all the MedimoTds and MedimoThs you input ['medimo-td','medimo-td']
       */
    copyContent(child_components_in_kebab_case) {

    },

    generateVirtualTable() {

    }
  },

  watch: {
    //
  }
};
</script>
