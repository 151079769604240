<template>
    <medimo-labeled-date-time-picker
            v-bind="$attrs"

            :only_date="true"
            :close_on_date_change="true"
            :datetime_format="datetime_format"
    >
      <slot></slot>
      <template v-slot:pre-buttons>
        <slot name="pre-buttons"></slot>
      </template>
    </medimo-labeled-date-time-picker>
</template>

<script>

import MedimoLabeledDateTimePicker from './MedimoLabeledDateTimePicker';

export default {
  inheritAttrs: false,
  components: {
    MedimoLabeledDateTimePicker,
  },
  props: {
    datetime_format: {default: "DD-MM-YYYY"},
  }
};
</script>
