<template>
  <medimo-vue-select2
      class="ward-select2"
      label="Multiple"
      :minimum_results_for_search="5"
      label_column_classes="col-6"
      :placeholder="placeholder"
      :help_text="help_text"
      :multiple="multiple"
      :options="options"
      :loading="loading"
      api_url="/api/v1/wards/search"
      api_request_type="GET"
      optionsLabelProperty="name_and_location"
      optionsValueProperty="id"
      :api_data="{
        results_per_page: 'all',
        cluster_id: cluster_id,
        location_id: location_id,
        search_columns: ['afdeling.naam', 'location.naam'],
        sort_columns: ['location.naam', 'afdeling.naam'],
      }"
      :min_input_length="min_input_length"
      v-model="value">
  </medimo-vue-select2>
</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import MedimoVueSelect2 from '@/vue/components/general/form/base/vue-select2/MedimoVueSelect2';

export default {
  emits: ['changed'],
  components: {MedimoVueSelect2},

  props: {
    placeholder: {default: 'Selecteer een afdeling'},
    help_text: {default: ''},
    label: {default: ''},
    cluster_id: {default: null},
    location_id: {default: null},
    fetch_on_load: {default: false},
    multiple: {default: false},
    min_input_length: {default: 0},
  },

  mixins: [BaseComponentsMixin],

  data: function () {
    return {
      value: null,
      id: null,
      loading: false,
    };
  },

  computed: {
    options() {
      return this.models.map(model => {
        return {value: model.id, text: model.name};
      });
    },
    name() {
      if (this.id === null) {
        return '';
      }
      return this.options.filter(option => {
        return option.value === this.id;
      })[0];
    },
    models() {
      return this.$store.getters['wards/search_results'];
    }
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    selected(data) {
      this.id = parseInt(data.id);
    },
  },

  watch: {
    value(value) {
      this.$emit('changed', value);
    },
    id() {
      this.$emit('changed', {
        name: this.name,
        id: this.id,
      });
    },
    cluster_id() {
      this.id = null;
      this.$store.commit('wards/clear_search_results');
    },
    location_id() {
      this.id = null;
      this.$store.commit('wards/clear_search_results');
      this.fetchOptions();
    }
  }
};
</script>
