<template>
    <medimo-date-time-picker
            class="medimo-date-picker"
            v-bind="$attrs"

            :only_date="true"
            datetime_format="DD-MM-YYYY"
    ></medimo-date-time-picker>
</template>

<script>

import MedimoDateTimePicker from './MedimoDateTimePicker';

export default {
  components: {
    MedimoDateTimePicker
  },

  props: {
    /**
       *
       *
       *
       *  Check MedimoDateTimePicker for all the options
       *  This is just a wrapper that's easier to recognise as a "ah, this picks dates only"
       *  component in a form.
       *
       *  All the properties that the MedimoDateTimePicker accepts, this component accepts as well.
       *
       *
       *
       */
  },

  mixins: [],

  data: function () {
    return {
      //
    };
  },

  computed: {
    //
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    //
  },

  watch: {
    //
  }
};
</script>
