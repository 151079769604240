import {store} from '@/vue/store';
import {MedicationUse} from "@/domain/models/MedicationUse";
import {AbstractMedicationStore} from "@/controllers/AbstractMedicationStore";
import {MedicationVerification} from "@/domain/models/MedicationVerification";
import {MedicationUseVerification} from "@/domain/models/MedicationUseVerification";

export class MedicationUseVerificationController extends AbstractMedicationStore {
  protected apiEndpoint = 'medication-use-verification';

  public async list(patientId: number): Promise<MedicationVerification[]> {
    const response = await store.dispatch('api/getEndpoint', {
      endpoint: '/api/v1/mp9/patient/' + patientId + '/' + this.apiEndpoint,
    });

    return MedicationVerification.multipleFromJson(response.data.data);
  }

  public async get(patientId: number, medicationVerificationId: number): Promise<MedicationUseVerification> {
    const response = await store.dispatch('api/getEndpoint', {
      endpoint: '/api/v1/mp9/patient/' + patientId + '/' + this.apiEndpoint + '/' + medicationVerificationId,
    });

    return MedicationUseVerification.fromJson(response.data);
  }

  public async start(patientId: number): Promise<MedicationVerification | null> {
    const response = await store.dispatch('api/getEndpoint', {
      endpoint: '/api/v1/mp9/patient/' + patientId + '/' + this.apiEndpoint + '/start',
    });

    return MedicationVerification.fromJson(response.data.data);
  }

  public async updateVerification(patientId: number, id: number, verification: MedicationVerification): Promise<void> {
    await store.dispatch('api/putEndpoint', {
      endpoint: '/api/v1/mp9/patient/' + patientId + '/' + this.apiEndpoint + '/' + id + '/update',
      data: verification,
    });
  }

  public async confirmMedicationUse(patientId: number, pharmaceuticalTreatmentId: number, medicationVerificationId: number): Promise<MedicationUse> {
    const response = await store.dispatch('api/postEndpoint', {
      endpoint: '/api/v1/mp9/patient/' + patientId + '/' + this.apiEndpoint + '/' + medicationVerificationId + '/confirm-use/' + pharmaceuticalTreatmentId,
    });

    return MedicationUse.fromJson(response.data.data);
  }

  public async stopMedicationUse(patientId: number, endDateTime: string, pharmaceuticalTreatmentId: number, medicationVerificationId: number): Promise<MedicationUse> {
    const response = await store.dispatch('api/postEndpoint', {
      endpoint: '/api/v1/mp9/patient/' + patientId + '/' + this.apiEndpoint + '/' + medicationVerificationId + '/stop-use/' + pharmaceuticalTreatmentId,
      data: {endDateTime: endDateTime}
    });

    return MedicationUse.fromJson(response.data.data);
  }

  public async initiateUpdateMedicationUse(patientId: number, pharmaceuticalTreatmentId: number, medicationVerificationId: number): Promise<void> {
    const response = await store.dispatch('api/postEndpoint', {
      endpoint: '/api/v1/mp9/patient/' + patientId + '/' + this.apiEndpoint + '/' + medicationVerificationId + '/initiate-update-use/' + pharmaceuticalTreatmentId,
    });

    const pharmaceuticalTreatment = response.data.data;

    // todo mp9 this has overlap with the abstract pharmaceutical treatment class
    this.storeMedicationAgreements(pharmaceuticalTreatment);
    this.storeMedicationUses(pharmaceuticalTreatment);
    this.storeAdministrationAgreements(pharmaceuticalTreatment);
    this.storeVariableDosingRegimens(pharmaceuticalTreatment);
    this.storeDrug(pharmaceuticalTreatment);
  }

  public async confirmMedicationVerification(patientId: number, medicationVerificationId: number): Promise<void> {
    await store.dispatch('api/postEndpoint', {
      endpoint: '/api/v1/mp9/patient/' + patientId + '/' + this.apiEndpoint + '/' + medicationVerificationId + '/confirm-medication-verification/',
    });
  }
}
