import BaseModule from '@/vue/store/modules/base_module';
import moment from 'moment-mini';
import Utility from '@/vue/utility/utility';

const state = {
  ...BaseModule.state,
  quick_filters: {
    'Alleen actief': true,
  },
  toggleQuickFilters: true,
  signals: {},
};

// getters
const getters = {
  ...BaseModule.getters,
  active(state) {
    if (state.data === null) {
      return state.data;
    }

    return state.data.filter((indication) => {
      return indication.stop_date === null || moment(indication.stop_date).isAfter(moment());
    });
  },
  find_by_zindex_id(state, getters, rootState) {
    if (state.data === null) {
      return () => null;
    }

    return (zindex_id) => getters['find_by_prop']({property: 'zindex_id', value: zindex_id});
  },
};

// actions
const actions = {
  ...BaseModule.actions,
  fetchMedicationGuardSignals({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/patients/' + payload.patient_id + '/contra-indications/' + payload.bst_id + '/signals';
    return dispatch('api/getEndpoint', {
      endpoint: endpoint,
      data: {}
    }, {root: true});
  },
  updateNoIndicationsReason({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/patients/' + payload.patient_id + '/contra-indications/empty-reason';
    return dispatch('api/postEndpoint', {
      endpoint: endpoint,
      data: payload.data,
    }, {root: true}).then(response => {
      return response.data;
    });
  },
  store({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/patients/' + payload.patient_id + '/contra-indications/store';
    return dispatch('api/postEndpoint', {
      endpoint: endpoint,
      data: payload.data,
    }, {root: true}).then(data => {
      commit('add_to_data', data.data.data);
    });
  },
  update({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/patients/' + payload.patient_id + '/contra-indications/' + payload.indication_id + '/update';
    return dispatch('api/postEndpoint', {
      endpoint: endpoint,
      data: payload.data,
    }, {root: true}).then(data => {
      const indication = data.data.data;
      if (indication.active === false) {
        commit('remove_from_data', {indication_id: indication.id});
      } else {
        commit('add_to_data', indication);
      }
    });
  },
  delete({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/patients/' + payload.patient_id + '/contra-indications/' + payload.indication_id + '/delete';
    return dispatch('api/postEndpoint', {
      endpoint: endpoint
    }, {root: true}).then(data => {
      commit('remove_from_data', {indication_id: data.data.data.id});
    });
  },
};

// mutations
const mutations = {
  ...BaseModule.mutations,
  remove_from_data(state, payload) {
    Utility.delete_models_by_property(state.data, 'id', payload.indication_id);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
