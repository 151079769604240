<template>
  <medi-modal
      :loading="loading"
      :title="action.name"
      size="medium"
      @closed="ignoreForNow"
  >
    <medimo-notification type="danger" v-if="errorMessage.length > 0">
      {{ errorMessage }}
    </medimo-notification>
    <medimo-form v-else>
      <medimo-form-row>
        <medimo-form-column column_classes="mb-2">
          <medimo-link target="_blank" to="https://corona.steffie.nl/nl/">Klik hier voor toelichting voor {{ language.patient }}</medimo-link>
        </medimo-form-column>
      </medimo-form-row>

      <medimo-form-row>
        <medimo-form-column column_classes="mb-2">
          <medimo-link target="_blank" to="https://www.rivm.nl/covid19vaccinatieprofessionals">Klik hier voor toelichting voor professionals
          </medimo-link>
        </medimo-form-column>
      </medimo-form-row>

      <medimo-form-row>
        <medimo-form-column column_classes="mb-4">
          Mogelijke contra-indicatie of reden voor uitstel voor vaccinatie kunnen zijn:<br>
          Koorts, huidige of recent doorgemaakte Covid-19 infectie, ernstige immuunstoornissenen, hiv en ernstige bloedingsneiging.
          Ga na of {{ language.patient }} wel vaccinatie mag ontvangen of stel zo nodig uit.
        </medimo-form-column>
      </medimo-form-row>

      <medimo-validated-vue-select2
          label_column_classes="col-md-8"
          :help_text="'Maak een keuze'"
          :label="'Wil, kan en mag de ' + language.patient + ' vervolgvaccinatie ontvangen?'"
          :options="[
            {value: 'Y', text: 'Ja, wil vaccinatie'},
            {value: 'N', text: 'Nee, weigert vaccinatie'},
            {value: 'Uitstel', text: 'Uitstellen'},
            {value: 'Overig', text: 'Overig'},
          ]"
          v-model="wants_to_be_vaccinated"
          name="wants_to_be_vaccinated"
      ></medimo-validated-vue-select2>

      <medimo-validated-textarea
          v-if="wants_to_be_vaccinated !== 'Y'"
          class="mt-2"
          label_column_classes="col-12 col-sm-3"
          label="Toelichting"
          placeholder="Uw toelichting"
          name="explanation"
          v-model="explanation"
          rows="3"
      ></medimo-validated-textarea>

      <medimo-validated-date-picker
          v-if="wants_to_be_vaccinated === 'Uitstel'"
          class="mt-2"
          placeholder="Selecteer datum"
          label_column_classes="col-12 col-sm-3"
          :label="next_valid_until_label"
          :minDate="minDate"
          name="next_valid_until_date"
          v-model="next_valid_until_date"
      ></medimo-validated-date-picker>

      <template v-if="wants_to_be_vaccinated === 'Y'">

        <medimo-validated-input
            :disabled="true"
            label_column_classes="col-md-3"
            class="mt-2"
            :label="'Vaccin'"
            :modelValue="drug ? drug.fullnaam : 'Onbekende eerste vaccinatie'"
            name="vaccin"
        ></medimo-validated-input>

        <vaccine-batch-select2 v-if="drug" :type="action.type" :vaccine_id="drug.id" v-model="batch_number"></vaccine-batch-select2>
      </template>
    </medimo-form>

    <template v-slot:action-buttons>
      <medimo-button type="success" @click="submit" :disabled="!readyToSubmit">Afhandelen</medimo-button>
      <medimo-button type="medimo" class="ml-auto" @click="ignoreForNow">Nu even niet</medimo-button>
    </template>
  </medi-modal>
</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import VaccineBatchSelect2 from '@/vue/components/patients/action-dialogs/forms/VaccineBatchSelect2';
import MedimoValidatedVueSelect2 from '@/vue/components/general/form/base/vue-select2/MedimoValidatedVueSelect2';

export default {
  components: {
    VaccineBatchSelect2,
    MedimoValidatedVueSelect2
  },
  emits: ['closed','processed'],
  props: [
    'action',
    'patient',
    'refresh_patient_medication_data',
  ],

  mixins: [
    BaseComponentsMixin
  ],

  data: function () {
    return {
      loading: false,
      wants_to_be_vaccinated: '',
      rivm_optin: '',
      batch_number: '',
      next_valid_until_date: '',
      explanation: '',
      drug: null,
      errorMessage: '',
      button_save_text: 'Opslaan',
      next_valid_until_label: 'Stel uit tot',
    };
  },

  computed: {
    current_user() {
      return this.$store.getters['current_user/data'];
    },
    language() {
      return this.$store.getters['current_user/language'];
    },
    readyToSubmit() {
      if ((this.wants_to_be_vaccinated === 'N' || this.wants_to_be_vaccinated === 'Overig') && this.explanation.length) {
        return true;
      }
      if (this.wants_to_be_vaccinated === 'Uitstel' && this.explanation.length && this.next_valid_until_date.length) {
        return true;
      }
      if (this.batch_number.length) {
        return true;
      }
      return false;
    },
    minDate() {
      return this.action.validUntil;
    }
  },

  created() {},
  mounted() {
    this.fetchDrugId();
  },

  methods: {
    close() {
      this.$emit('closed');
    },
    ignoreForNow() {
      this.close();
    },
    fetchDrugId() {
      this.loading = true;
      this.$store.dispatch('api/getEndpoint', {
        endpoint: '/api/v1/clusters/' + this.current_user.cluster_id + '/vaccinations/previous/' + this.patient.id,
        rejectErrors: true,
      }).then(response => {
        this.loading = false;
        // We krijgen hier de VaccinDrugResource terug
        this.drug = response.data.data;
      }).catch((error) => {
        // this.errorMessage = 'Er is iets mis gegaan waardoor de actie niet verwerkt kan worden. Neem aub contact op met support.'
        this.loading = false;
      });
    },
    submit() {
      this.loading = true;
      return this.$store.dispatch('patients/update_action_from_patient', {
        rejectErrors: true,
        action_id: this.action.id,
        patient_id: this.action.patientId,
        handler: 'vaccinate',
        refresh_patient_medication_data: this.refresh_patient_medication_data,
        data: {
          wants_to_be_vaccinated: this.wants_to_be_vaccinated,
          vaccin: this.drug ? this.drug.id : '',
          rivm_optin: 'nvt',
          batch_number: this.batch_number,
          explanation: this.explanation,
          next_valid_until_date: this.next_valid_until_date,
        }
      }).then(() => {
        // Afronden en melding tonen
        this.loading = false;
        this.$store.dispatch('notifications/addSuccessAlert', {message: 'Gegevens opgeslagen'});

        this.$emit('processed');
        this.close();
      }).catch(() => {
        // Needed for IE11, there's no .finally()
        this.loading = false;
      });
    },
  },

  watch: {}
};
</script>
