import Utility from '@/vue/utility/utility';

// initial state
// shape: [{ id, quantity }]
const state = {
  data: []
};

// getters
const getters = {
  all(state, getters, rootState) {
    return state.data;
  },
  find(state, getters, rootState) {
    return (dosageUnit) => {
      return Utility.find_model_by_property(state.data, 'value', dosageUnit);
    };
  }

};

const actions = {};


// mutations
const mutations = {
  set_data(state, new_data) {
    state.data = new_data;
  },
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
