<template>
  <div v-if="unprocessed_action_dialogs.length"
       id="unprocessed-action-dialogs"
  >
    <template v-for="(action, index) in unprocessed_action_dialogs" :key="action.id">

      <transition name="fade">
        <base-action-modal
            v-if="current_action.id === action.id"
            :patient="patient"
            :hide_overlay="true"
            :action="action"
        ></base-action-modal>
      </transition>

    </template>
  </div>
</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import BaseActionModal from './_BaseActionModal';
import {nextTick} from 'vue';

export default {
  components: {
    BaseActionModal,
  },

  props: [
    'patient'
  ],

  mixins: [BaseComponentsMixin],

  data: function () {
    return {
      total_dialogs: 0,
    };
  },

  computed: {
    patient_actions() {
      // Return an empty array when there are no actions / it's null. Otherwise it'll error out.
      return this.patient !== null ? this.patient.actions : [];
    },
    unprocessed_action_dialogs() {
      const vm = this;

      // Als we op deze url de popups willen onderdrukken
      if (typeof this.$route.query.noActionPopUp !== 'undefined') {
        // Remove the suppress
        const query = Object.assign({}, this.$route.query);
        delete query.noActionPopUp;
        this.$router.replace({ query });
        // return false: no action-popups
        return false;
      }

      // Here we filter the active__action_dialogs (actions that have a show_dialog value of "Y" in the Database
      // (TRUE, due to the Resource)
      return this.patient_actions.filter(action => {
        // When we should show a dialog for this action
        if (action.showPopup) {
          // And the current_user hasn't interacted with the Action before. If he has, there will be a timestamp
          // in the current_user.processed_action_dialogs object that we check
          return !vm.$store.getters['current_user/has_processed_action_dialog'](action.id);
        }
        return false;
      });
    },
    number_of_unprocessed_action_dialogs() {
      return this.unprocessed_action_dialogs.length;
    },
    current_action() {
      if (this.unprocessed_action_dialogs.length) {
        return this.unprocessed_action_dialogs[0];
      }
      return {};
    }
  },

  created() {
    //
  },

  mounted() {
    // When all is done, we update the local value.
    nextTick(() => {
      this.total_dialogs = this.number_of_unprocessed_action_dialogs;
    });
  },

  methods: {
    //
  },

  watch: {
    number_of_unprocessed_action_dialogs(value) {
      // Only when the number goes up, do we update the local value. Otherwise it will update constantly
      // as the user processes the dialogs :-)
      if (value > this.number_of_unprocessed_action_dialogs) {
        this.total_dialogs = value;
      }
    }
  }
};
</script>
<style>
#unprocessed-action-dialogs {
  background-color: rgba(0, 0, 0, 0.35);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  outline: 0;
}
</style>
