import Utility from '@/vue/utility/utility';
import BaseModule from '@/vue/store/modules/base_module';
import moment from 'moment-mini';

// initial state
const state = {
  ...BaseModule.state,
  quick_filters: {
    'Alleen actief': true,
  },
  toggleQuickFilters: true,
  signals: {},
};

// getters
const getters = {
  ...BaseModule.getters,

  active(state) {
    if (state.data === null) {
      return state.data;
    }

    return state.data.filter((intolerance) => {
      return intolerance.stop_date === null || moment(intolerance.stop_date).isAfter(moment());
    });
  },

  find_by_zindex(state) {
    if (state.data === null) {
      return () => null;
    }

    return (bst_type, bst_id) => state.data.filter((intolerance) => {
      if (intolerance.bst_type === bst_type && intolerance.bst_id === bst_id) {
        return intolerance;
      }
    });
  },
};

// actions
const actions = {
  ...BaseModule.actions,
  fetchMedicationGuardSignals({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/patients/' + payload.patient_id + '/intolerances/signals';
    return dispatch('api/postEndpoint', {
      endpoint: endpoint,
      data: {
        intolerancesToBeAdded: payload.intolerances_to_be_added,
        intoleranceType: payload.intolerance_type,
      }
    }, {root: true});
  },

  updateNoIntolerancesReason({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/patients/' + payload.patient_id + '/intolerances/empty-reason';
    return dispatch('api/postEndpoint', {
      endpoint: endpoint,
      data: payload.data,
    }, {root: true}).then(response => {
      return response.data;
    });
  },

  store({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/patients/' + payload.patient_id + '/intolerances/store';
    return dispatch('api/postEndpoint', {
      rejectErrors: true,
      endpoint: endpoint,
      data: payload.data,
    }, {root: true}).then(data => {
      data.data.data.forEach(model => {
        commit('add_to_data', model);
      });
    });
  },

  update({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/patients/' + payload.patient_id + '/intolerances/' + payload.intolerance_id + '/update';
    return dispatch('api/postEndpoint', {
      rejectErrors: true,
      endpoint: endpoint,
      data: payload.data,
    }, {root: true}).then(data => {
      commit('add_to_data', data.data.data);
    });
  },

  delete({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/patients/' + payload.patient_id + '/intolerances/' + payload.intolerance_id + '/delete';
    return dispatch('api/postEndpoint', {
      endpoint: endpoint
    }, {root: true}).then(data => {
      commit('remove_from_data', {intolerance_id: data.data.data.id});
    });
  },
};

// mutations
const mutations = {
  ...BaseModule.mutations,
  remove_from_data(state, payload) {
    Utility.delete_models_by_property(state.data, 'id', payload.intolerance_id);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
