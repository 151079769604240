import {GeneratedDrugObject} from '@/domain/models/generated/GeneratedDrugObject';

export class DrugObject extends GeneratedDrugObject {
  static readonly parenteral = 10;
  static readonly inhalation = 62;

  public isInfusionDrug(): boolean {
    return this.isParenteral() || this.isInhalation();
  }

  public isParenteral(): boolean {
    return this.administrationRoute === DrugObject.parenteral;
  }

  public isInhalation(): boolean {
    return this.administrationRoute === DrugObject.inhalation;
  }

  public isCoumarin(): boolean {
    // todo mp9 make variable
    return [50007323, 10052477, 50001783].includes(this.id);
  }
}
