<template>
    <div @click="$emit('click')" :class="'medimo-td td ' + column_classes" ref="medimo-td" :copy="copy ? null : copy">
        <slot></slot>
    </div>
</template>

<script>
export default {
  components: {},
  emits: ['click'],

  props: {
    // Use this to set the width
    'column_classes': {default: 'col'},

    // Set to FALSE to disable copying data from this cell to the clipboard when clicking the COPY icon
    copy: {default: true},
  },

  mixins: [],

  data: function () {
    return {
      //
    };
  },

  computed: {
    uid() {
      return this._.uid;
    },
    medimoTdUid() {
      return this.uid;
    }
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    //
  },

  watch: {
    //
  }
};
</script>
