import {DisplayInfoObject} from '@/domain/models/DisplayInfoObject';

export class GeneratedDisplayInfoObject { 
  public dosageInfo: string;
  public stopping: boolean;
  public title: string;
  public subTitle: string | null;

  constructor(dosageInfo: string, stopping: boolean, title: string, subTitle: string | null = null) {
    this.dosageInfo = dosageInfo;
    this.stopping = stopping;
    this.title = title;
    this.subTitle = subTitle;
  }

  public static fromJson(json: unknown): DisplayInfoObject {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new DisplayInfoObject(
      parsedJson['dosageInfo'] as string,
      parsedJson['stopping'] as boolean,
      parsedJson['title'] as string,
      parsedJson['subTitle'] as string,
    );
  }

  public static multipleFromJson(json: unknown): DisplayInfoObject[] {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    const items: DisplayInfoObject[] = [];
    for (const item of parsedJson) {
      items.push(this.fromJson(item));
    }
    return items;
  }
}
