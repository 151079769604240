import Vue from 'vue';
import Utility from '@/vue/utility/utility';
import BaseModule from '@/vue/store/modules/base_module';

// initial state
// shape: [{ id, quantity }]
const state = {
  ...BaseModule.state,
};

// getters
const getters = {
  ...BaseModule.getters,
  /**
   * Om de administration_agreement te getten kan het zijn dat er _en_ een agreement _en_ een treatment
   * is, of alleen een treatment, of alleen een agreement. In alle gevallen is er een patient_id
   * Die laatste gebruiken we dan ook als dubbelcheck dat we nooit het verkeerde ophalen
   *
   * @param state
   * @param getters
   * @param rootState
   * @returns array
   */
  get(state, getters, rootState) {
    return (medication_treatment_id, medication_agreement_id, patient_id) => {
      return state.data.filter(administrationAgreement => {
        const match1 = administrationAgreement.patient_id == patient_id && administrationAgreement.medication_treatment_id == medication_treatment_id;
        const match2 = administrationAgreement.patient_id == patient_id && administrationAgreement.medication_agreement_id == medication_agreement_id;
        return match1 || match2;
      });
    };
  },
};

// actions
const actions = {
  ...BaseModule.actions,
  // dummy_action ({state, commit, dispatch, getters, rootState}, payload) {
  //     //
  // }
};

// mutations
const mutations = {
  ...BaseModule.mutations,
  // dummy_mutation (state, id) {
  //     state.id = id;
  // },
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
