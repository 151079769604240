const appendToBody = {
  mounted(el, {instance}) {
    appendToBody.updatePosition(el, instance);

    // Dit moet zo omdat anders de removeEventListener niet goed z'n werk doet
    appendToBody.onScroll = function () {
      appendToBody.updatePosition(el, instance);
    };
    window.addEventListener("scroll", appendToBody.onScroll, false);
  },
  updated(el, {instance}) {
    // Context is de VueSelect
    // Wat we nu willen is dat de positie na initialisatie alleen update
    // als de options veranderd zijn.
    if (instance.shouldRecalculatePosition) {
      appendToBody.updatePosition(el, instance);
      instance.shouldRecalculatePosition = false;
    }
  },
  beforeUnmount(el, {instance}) {
    window.removeEventListener("scroll", appendToBody.onScroll, false);

    if (instance.appendToBody) {
      if (el.unbindPosition && typeof el.unbindPosition === 'function') {
        el.unbindPosition();
      }
      if (el.parentNode) {
        el.parentNode.removeChild(el);
      }
    }
  },
  updatePosition(el, instance) {
    if (instance.appendToBody) {
      const { height, top, left, width } = instance.$refs.toggle.getBoundingClientRect();
      const scrollX = window.scrollX || window.pageXOffset;
      const scrollY = window.scrollY || window.pageYOffset;
      el.unbindPosition = instance.calculatePosition(el, instance, {
        width: width + 'px',
        left: (scrollX + left) + 'px',
        top: (scrollY + top + height) + 'px',
      });

      document.body.appendChild(el);
    }
  }
};

export default appendToBody;
