<template>
    <div class="row d-flex mb-2">
        <div class="col-12">
            <label :class="type" :for="name" v-if="label.length">
                {{ label }}
            </label>
        </div>
        <div v-bind:class="{'col-11': hasHelpText, 'col-12': !hasHelpText}">
            <select :class="extra_classes"
                    :type="type"
                    @input="$emit('input', $event.target.value)"
                    :name="name"
                    :disabled="disabled">
                <option v-for="option in options" :value="option.value" :selected="value === option.value">
                    {{ option.text }}
                </option>
            </select>
        </div>
        <div class="col-1 align-self-center" v-if="help_text.length">
            <medimo-tooltip :content="help_text">
                <i class="fa fa-info-circle form-info fa-2x text-primary"></i>
            </medimo-tooltip>
        </div>
    </div>
</template>

<script>

import MedimoTooltip from '@/vue/components/general/MedimoTooltip';

export default {

  components: {
    MedimoTooltip
  },
  emits: ['input'],
  props: {
    'options': {default: []}, // Should contain a value and text field
    'name': {default: ''},
    'label': {default: ''},
    'placeholder': {default: ''},
    'extra_classes': {default: ''},
    'type': {default: 'text'},
    'help_text': {default: ''},
    'value': {default: ''},
    'disabled': {default: false}
  },

  mixins: [],

  data: function () {
    return {
      //
    };
  },

  computed: {
    hasHelpText() {
      return this.help_text.length > 0;
    }
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    // This one pushes the value upstream to the Form
    onInput(event) {
      this.$emit('input', event.target.value);
    }
  },

  watch: {
    //
  }
};
</script>
