export default {
  beforeMount(el) {
    let elementStartX, elementStartY, initialMousePositionX, initialMousePositionY;
    let dragIconEl = el;

    // If it's a modal that we want to drag we need to add the draggable class to the header only
    if (isModal()) {
      dragIconEl = el.getElementsByClassName('modal-header')[0];
    }
    dragIconEl.classList.add("is-draggable");

    function onMouseMove(event) {
      const dragX = event.clientX - initialMousePositionX;
      const dragY = event.clientY - initialMousePositionY;
      el.style.left = elementStartX + dragX + 'px';
      el.style.top = elementStartY + dragY + 'px';
      return false;
    }

    function onMouseUp() {
      // When the user is done, return to the normal hover icon
      dragIconEl.classList.remove("is-dragging");
      dragIconEl.classList.add("is-draggable");

      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    }

    el.addEventListener('mousedown', function (event) {
      // If it's a modal that we want to drag but we're not clicking on the header
      // ignore the event
      if (isModal() && !clickedOnModalHeader(event)) {
        return false;
      }
      // Als het een modal is, maar niet de bovenste modal, doen we ook niets
      if (isModal() && !isTopModal()) {
        return false;
      }

      // Close the hand
      dragIconEl.classList.remove("is-draggable");
      dragIconEl.classList.add("is-dragging");

      // Set the initial position values so the DIV doesn't jump left or right
      elementStartX = el.offsetLeft;
      elementStartY = el.offsetTop;
      initialMousePositionX = event.clientX;
      initialMousePositionY = event.clientY;

      // Only now do we change the position to absolute, so we get control over the element
      el.style.position = 'absolute';
      el.style.top = elementStartY + 'px';
      el.style.left = elementStartX + 'px';

      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);

      return false;
    });

    function isModal() {
      return el.className.includes('modal-dialog');
    }

    function isTopModal() {
      return el.className.includes('modal-dialog') && el.className.includes('top-modal');
    }

    function clickedOnModalHeader(event) {

      const edgeCases = [
        'svg',
        'path'
      ];

      // edge case
      if (edgeCases.includes(event.target.tagName)) {
        return false;
      }

      return event.target.className.includes('modal-header')
        || event.target.className.includes('modal-title')
        || event.target.className.includes('modal-subtitle');
    }
  }
};
