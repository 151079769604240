<template>
  <medi-modal @closed="$emit('closed')"
              size="small"
              :title="title"
              class="authorisation-list-date-pick-modal"
  >
    <medimo-date-picker
      name="authorisation-end-date"
       v-model="datePickModalDate"
      :close_on_date_change="true"
    ></medimo-date-picker>
    <template v-slot:action-buttons>
      <medimo-button type="button" class="btn btn-primary" @click="goToList()">Verstuur</medimo-button>
      <medimo-button type="button" class="btn btn-outline-medimo" @click="$emit('closed')">Sluiten</medimo-button>
    </template>
  </medi-modal>
</template>

<script>
import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import moment from "moment-mini";

export default {
  components: {},

  emits: ['closed'],

  props: ['route'],

  mixins: [BaseComponentsMixin],

  data: function () {
    return {
      datePickModalDate: null
    };
  },

  computed: {
    title() {
      return this.routeData.title;
    },
    routeData() {
      return this.$store.getters['routes/routes'](this.route);
    },
  },

  created() {
    //
  },

  mounted() {
    this.datePickModalDate = moment().add(3, 'months').format('YYYY-MM-DD');
  },

  methods: {
    goToList() {
      window.location.href = this.route + '&endDate=' + this.datePickModalDate;
    },
  },

  watch: {
    //
  }
};
</script>
