<template>
    <li class="breadcrumb-item text-breadcrumb" :id="'breadcrumb-' + breadcrumb_index" v-if="breadcrumb.title">

        <!-- We trigger this one programmatically so it works in tandem with the breadcrumbs -->
        <medimo-link :to="breadcrumb.path" :trigger="navigation_trigger"
                     @click.left.exact.prevent="navigate">
            <span class="hidden-sm-down">{{ breadcrumb.title }}</span>
            <span class="hidden-md-up">
                <!-- Show "Terug" on the mobile version -->
                Terug
            </span>
        </medimo-link>

    </li>
</template>

<script>
import MedimoLink from '@/vue/components/general/MedimoLink';

import ChecksIfVuePage from '@/vue/mixins/navigation/ChecksIfVuePage';

export default {
  components: {
    MedimoLink
  },

  props: [
    //
    'is_back_breadcrumb',

    // The breadcrumb from the store
    'breadcrumb',

    // The index of this breadcrumb in the data array of the store
    'breadcrumb_index',
  ],

  mixins: [ChecksIfVuePage],

  data: function () {
    return {
      navigation_trigger: false,
    };
  },

  computed: {
    isVueRoute() {
      return this.pathIsVueRoute(this.breadcrumb.path);
    },
    showClusterSelector: {
      get() {
        return this.$store.state.settings.navigation.showClusterSelector;
      },
      set(value) {
        this.$store.commit('settings/navigation/setShowClusterSelector', value);
      }
    },
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    hideClusterSelector() {
      if (this.showClusterSelector) {
        this.showClusterSelector = false;
      }
    },
    navigate() {
      this.hideClusterSelector();

      this.navigation_trigger = !this.navigation_trigger;

      // Here we go back to the url of the breadcrumb, and make sure to delete all the breadcrumbs
      // after it as well, to remove our trail
      this.$store.commit('settings/breadcrumbs/remove_breadcrumbs_after', this.breadcrumb_index);
    }
  },

  watch: {
    //
  }
};
</script>
