import {GeneratedMedicationAgreement} from '@/domain/models/generated/GeneratedMedicationAgreement';
import moment from "moment";
import SnomedCt from "@/enums/SnomedCt";

export class MedicationAgreement extends GeneratedMedicationAgreement {
  public isActive() {
    const now = moment();

    if (this.startDateTime?.isBefore(now) && this.endDateTime === null) {
      return true;
    }

    if (this.startDateTime?.isBefore(now) && this.endDateTime?.isAfter(now)) {
      return true;
    }

    return false;
  }

  public hasBeenCancelled(): boolean {
    if (this.stopType === null) {
      return false;
    }

    return this.stopType?.code === SnomedCt.Cancelled;
  }

  public hasStopped(): boolean {
    if (this.periodOfUse.endDateTimeOrDuration() === null) {
      return false;
    }

    return this.stopType?.code === SnomedCt.Discontinued;
  }

  public hasBeenTemporarilyStopped(): boolean {
    if (this.periodOfUse.endDateTimeOrDuration() === null) {
      return false;
    }

    return this.stopType?.code === SnomedCt.Suspended;
  }

  public hasToBeChanged(): boolean {
    return this.copyIndicator || this.distributedIndicator;
  }

  public hasToBeStopped(): boolean {
    return this.copyIndicator || this.distributedIndicator;
  }
}
