import {useRouter} from "vue-router";
import {useStore} from 'vuex';

export function useChecksIfVuePage() {

  const store = useStore(); // store, geen $store meer
  const router = useRouter();

  function currentPageIsVuePage():boolean {
    if (store.state.settings.navigation.isVueErrorPage) {
      return true;
    }

    // Als het een v2 pagina is, is het sowieso geen Vue page
    const body = document.querySelector('body.v2');
    if (body !== null) {
      return false; // Hij is gevonden, dus geen vue page
    }

    return pathIsVueRoute(window.location.pathname);
  }

  // function pathIsVueRoute(path: string) {
  //   // TMP disabled, levert onvoorziene issues op op pagina's met _id en stores doordat de navigatie
  //   if (path !== '/') {
  //     path = path.replace(/\/+$/g, ''); // Weghalen van de laatste slash als hij er is, anders gaat de check fout
  //   }
  //
  //   // Checks if the current page is a Vue page, with a <router-view> component
  //   // We compare each element of the path (split on slash) and ignore the variables (starts with : in the vue route)
  //   path = removeUrlParameters(path);
  //
  //   // Sommige pagina's zijn v2 maar omdat er in Vue een wildcard
  //   // route bestaat denkt vue dan dat deze bestaan. Bijvoorbeeld:
  //   // /reports/_report_id
  //   // die matched met:
  //   // /reports/medimomonitor
  //   // Voor nu kunnnen we die zo snel excluden. Beter is dat deze simpelweg nooit matchen
  //   const v2Urls = [
  //     '/reports/medimomonitor',
  //     '/reports/scores',
  //     '/reports/scores/scoreOverview'
  //   ];
  //
  //   if (v2Urls.includes(path)) {
  //     return false;
  //   }
  //
  //   const actualPathArray = path.split('/');
  //
  //   // Checks if the current link is a Vue route that's generated by our Folder structure
  //   for (const route of router.options.routes) {
  //     // Ignore the catch-all for 404's
  //     if (route.path.startsWith('/:pathMatch')) {
  //       continue;
  //     }
  //
  //     const checkingPathArray = route.path.split('/');
  //     // We don't have to check this route if the length of the elements is not the same
  //     if (actualPathArray.length !== checkingPathArray.length) {
  //       continue;
  //     }
  //
  //     let foundMatch = true;
  //     for (const pathIndex in checkingPathArray) {
  //       // Ignore element if it starts with :
  //       if (checkingPathArray[pathIndex]?.startsWith(':')) {
  //         continue;
  //       }
  //       // If element is not the same, this is not the same route.
  //       if (checkingPathArray[pathIndex] !== actualPathArray[pathIndex]) {
  //         foundMatch = false;
  //         break;
  //       }
  //     }
  //     // If we are here and the foundMatch is still true, all elements are the same, therefore this must be the route
  //     if (foundMatch) {
  //       return true;
  //     }
  //   }
  //
  //   return false;
  // }
  function removeUrlParameters(path: string): string {
    if (!path.includes('?')) {
      return path;
    }
    return path.split('?')[0]!;
  }

  // TMP OLD START - Kan later weer weg en dan pathIsVueRouteNew() renamen naar pathIsVueRoute()
  function pathIsVueRoute(path: string) {
    if (path !== '/') {
      path = path.replace(/\/+$/g, ''); // Weghalen van de laatste slash als hij er is, anders gaat de check fout
    }

    // Werkt niet met strings, enkel met IDs
    path = removeUrlParameters(path);

    // Sommige pagina's zijn v2 maar omdat er in Vue een wildcard
    // route bestaat denkt vue dan dat deze bestaan. Bijvoorbeeld:
    // /reports/_report_id
    // die matched met:
    // /reports/medimomonitor
    // Voor nu kunnnen we die zo snel excluden. Beter is dat deze simpelweg nooit matchen
    const v2Urls = [
      '/reports/medimomonitor',
      '/reports/scores',
      '/reports/scores/scoreOverview',
    ];

    if (v2Urls.includes(path)) {
      return false;
    }

    // Checks if the current link is a Vue route that's generated by our
    // Folder structure
    const routerMatches = router.options.routes.filter((route) => {
      // Here we compare the normalized link; /status/12345/episodes -> /patient/:id/episodes
      // To the normalized route; /status/:patient_id/episodes -> /patient/:id/episodes
      let normalizePath = normalizeNewIdPath(path);
      normalizePath = normalizeIdPath(normalizePath);

      return normalizePath === normalizeRoutePath(route.path);
    });

    return routerMatches.length > 0;
  }
  function normalizeRoutePath(path: string) {
    return path.replace(/(:[a-z])\w+/g, ":id");
  }
  function normalizeIdPath(path: string) {
    path = path.replace(/\/([0-9])+/g, "/:id");

    // If the last character is /, and the path is not the homepage
    if (path[path.length - 1] === '/' && path.length > 1) {
      path = path.slice(0, -1);
    }
    return path;
  }
  function normalizeNewIdPath(path: string) {
    return path.replace(/(new-[0-9]*)+/g, ":id");
  }
  // TMP OLD END

  return {
    currentPageIsVuePage,
    pathIsVueRoute,
  };
}
