import {AsNeededObject} from '@/domain/models/AsNeededObject';
import {CodeValue} from '@/domain/models/CodeValue';
import {RangeObject} from '@/domain/models/RangeObject';

export class GeneratedAsNeededObject { 
  public condition: CodeValue | null;
  public maximumDose: RangeObject | null;

  constructor(condition: CodeValue | null = null, maximumDose: RangeObject | null = null) {
    this.condition = condition;
    this.maximumDose = maximumDose;
  }

  public static fromJson(json: unknown): AsNeededObject {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new AsNeededObject(
      parsedJson['condition'] ? CodeValue.fromJson(parsedJson['condition']) : null,
      parsedJson['maximumDose'] ? RangeObject.fromJson(parsedJson['maximumDose']) : null,
    );
  }

  public static multipleFromJson(json: unknown): AsNeededObject[] {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    const items: AsNeededObject[] = [];
    for (const item of parsedJson) {
      items.push(this.fromJson(item));
    }
    return items;
  }
}
