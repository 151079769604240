const prefix = "fak";
const faCapsule = {
  prefix: 'fak',
  iconName: 'capsule',
  icon: [256, 256, ['capsule'], "&#xEA07", "M147.2 230.4 L230.4 147.2 A1 1 0 0 0 108.8 25.6 L25.6 108.8 A1 1 0 0 0 147.2 230.4Z M177.6 155.2 L100.8 78.4 L131.2 48 A1 1 0 0 1 208 124.8Z M78.4 100.8 L155.2 177.6 L124.8 208 A1 1 0 0 1 48 131.2Z"]
};
const faCapsuleDuo = {
  prefix: 'fak',
  iconName: 'capsule-duo',
  icon: [256, 256, ['capsule-duo'], "&#xEA07", ["M188.8 188.8 L230.4 147.2 A1 1 0 0 0 108.8 25.6 L67.2 67.2 Z", "M147.2 230.4 L188.8 188.8 L67.2 67.2 L25.6 108.8 A1 1 0 0 0 147.2 230.4 Z"]]
  // icon: [256, 256, [], "&#xEA07", ["M200 177.6 L230.4 147.2 A1 1 0 0 0 108.8 25.6 L78.4 56Z M177.6 155.2 L100.8 78.4 L131.2 48 A1 1 0 0 1 208 124.8Z", "M147.2 230.4 L200 177.6 L78.4 56 L25.6 108.8 A1 1 0 0 0 147.2 230.4Z M78.4 100.8 L155.2 177.6 L124.8 208 A1 1 0 0 1 48 131.2Z"]]
};
const icons = {
  faCapsule: faCapsule,
  faCapsuleDuo: faCapsuleDuo
};

export { icons as fak, prefix, faCapsule, faCapsuleDuo };
