import Utility from '@/vue/utility/utility';

// initial state
// shape: [{ id, quantity }]
const state = {
  show_error_overlay: false,

  // Formatted like an array of objects that look like: {message: '', about: ''}, where about signals what the error is about, like patient, or whatever
  // This name can then be used for filtering purposes.
  error_messages: [],

  // An array with the Status Codes that should trigger the Error overlay on pages.
  page_error_status_codes: [
    '404', // Missing
    '401', // Unauthorized
    '500', // Render / Application error
  ],

  sentry_event_id: null,
};

// getters
const getters = {
  page_errors(state, getters, rootState) {
    return state.error_messages.filter(error_message => {
      return state.page_error_status_codes.includes(error_message.status.toString());
    });
  },
  sentry_id(state, getters, rootState) {
    return state.sentry_event_id;
  }
};

// actions
const actions = {
  // dummy_action ({state, commit, dispatch, getters, rootState}, payload) {
  //     //
  // }
};

// mutations
const mutations = {
  set_show_error_overlay(state, value) {
    state.show_error_overlay = value;
  },
  clear(state) {
    state.error_messages = [];
    state.show_error_overlay = false;
  },
  add_to_page_errors(state, data) {

    Object.keys(data.errors).forEach((key) => {
      const error_bag = {};
      error_bag['message'] = data.errors[key];
      error_bag['status'] = data.status;

      state.error_messages.push(error_bag);
    });

  },
  add_page_error(state, data) {
    const error_messages = Utility.clone(state.error_messages);
    error_messages.push(data);
    state.error_messages = error_messages;
  },
  set_sentry_event_id(state, sentry_event_id) {
    state.sentry_event_id = sentry_event_id;
  },
  clear_sentry_event_id(state) {
    state.sentry_event_id = null;
  }
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
