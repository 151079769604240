<template>
    <div class="col-12 medication-agreement">
        <template v-for="(dosage_instruction, index) in dosage_instructions">
            <mp9-instruction-for-use-row
                :key="dosage_instruction.id"
                v-if="dosage_instruction.mp9"
                :medication_agreement="medication_agreement"
                :instruction_for_use="dosage_instruction"
            ></mp9-instruction-for-use-row>
            <dosage-instruction-row v-else
                :key="dosage_instruction.id"
                :medication_treatment="medication_treatment"
                :medication_agreement="medication_agreement"
                :all_dosage_instruction_ids="all_dosage_instruction_ids"
                :has_future_instructions="has_future_instructions"
                :dosage_instruction="dosage_instruction"
                :dosage_instruction_index="index"
                :number_of_dosage_instructions="dosage_instructions.length"
                :show_all="show_all"
                :read_only="read_only"
                @toggle_show_all="toggleShowAll"
                :show_ingang_per="show_ingang_per"
        ></dosage-instruction-row>
      </template>
    </div>
</template>

<script>
import DosageInstructionRow from '@/vue/components/status/DosageInstructionRow';
import Mp9InstructionForUseRow from '@/vue/components/status/Mp9InstructionForUseRow.vue';

export default {
  emits: ['toggle_show_all'],
  components: {
    Mp9InstructionForUseRow,
    DosageInstructionRow
  },

  props: {
    'show_all': {required: true},
    'all_dosage_instruction_ids': {required: true},
    'has_future_instructions': {required: true},
    'medication_treatment': {required: true},
    'medication_agreement': {required: true},
    'read_only': {required: false, default: null},
    'show_ingang_per': {default: true}
  },

  mixins: [],

  data: function () {
    return {
      //
    };
  },

  computed: {
    dosage_instructions() {
      return this.$store.getters['dosage_instructions/for_db_medication_agreement_id'](this.medication_agreement.id);
    }
  },

  created() {
    //
  },

  mounted() {
    // setTimeout(() => {
    //   if (this.dosage_instructions.length === 0) {
    //     // Dit moet niet kunnen, betekent foutieve database data
    //     this.$root.sendSentry('MedicationAgreement zonder dosageInstructions gevonden voor MedicationAgreement met id: ' + this.medication_agreement.id);
    //   }
    // }, 1000)
  },

  methods: {
    toggleShowAll() {
      this.$emit('toggle_show_all');
    },
  },

  watch: {
    //
  }
};
</script>

<style lang="scss" scoped>
    .medication-agreement {
        /*border-left: 2px black solid;*/
    }
</style>
