import Utility from '@/vue/utility/utility';
import BaseModule from '@/vue/store/modules/base_module';

// initial state
// shape: [{ id, quantity }]
const state = {
  ...BaseModule.state,

  search_endpoint: '/api/v1/clusters/search',

  filter_columns: [
    'name',
    'city'
  ],

  sort_columns: [{column_name: 'name', direction: 'ASC'}],
  default_sort_columns: [{column_name: 'name', direction: 'ASC'}],
};

// getters
const getters = {
  ...BaseModule.getters
};

// actions
const actions = {
  ...BaseModule.actions,

  switch_to_cluster({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/clusterselect/' + payload.cluster_id;

    return dispatch('api/getEndpoint', {
      endpoint: endpoint,
      data: {} // Empty, since it's a GET
    }, {root: true}).then(response => {
      // If all went well, we get the updated CurrentUser back
      commit('current_user/set_data', response.data.data, {root: true});
    });
  },
  fetch({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/clusters';

    return dispatch('api/getEndpoint', {
      endpoint: endpoint,
      data: payload.data // Can be extended with {params: {urlParameter: value}}
    }, {root: true}).then(response => {

      commit('set_search_results', response.data.data);
      commit('set_search_meta', response.data.meta);

    });
  },
  updateClusterParameters({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/clusters/parameters/' + payload.clusterId ;

    return dispatch('api/postEndpoint', {
      endpoint: endpoint,
      data: payload.data // Can be extended with {params: {urlParameter: value}}
    }, {root: true}).then(response => {

      commit('update_selected_search_result', response.data.data);
      commit('add_to_data', response.data.data);

    });
  },
  updateAllClusterParameters({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/clusters/parameters';

    return dispatch('api/postEndpoint', {
      endpoint: endpoint,
      data: payload.data // Can be extended with {params: {urlParameter: value}}
    }, {root: true}).then(response => {

      commit('update_selected_search_result', response.data.data);
      commit('add_to_data', response.data.data);

    });
  },
};

// mutations
const mutations = {
  // dummy_mutation (state, id) {
  //     state.id = id;
  // },
  ...BaseModule.mutations
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
