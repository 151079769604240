<template>
  <medimo-tooltip :showOnClick="false" content="Exporteer naar CSV">
    <medimo-button-link :to="''"
                        @click="exportContent"
                        icon="fa-solid fa-download">Export CSV
    </medimo-button-link>
  </medimo-tooltip>
</template>

<script>
import MedimoButtonLink from './../MedimoButtonLink';
import MedimoTooltip from './../MedimoTooltip';
import CopiesContent from '@/vue/mixins/CopiesContent';
import WorksWithParentsSiblingsAndChildren from '@/vue/mixins/WorksWithParentsSiblingsAndChildren';
import ThrottlesRequests from '@/vue/mixins/ThrottlesRequests';

export default {
  components: {
    MedimoButtonLink,
    MedimoTooltip,
  },

  props: {
    /**
     * The default value the user sees when they hover over the clipboard icon
     */
    tooltip_content_start: {
      default: 'Exporteer alle resultaten'
    },
    // We exporteren altijd de data uit de store, of gebruiken de store dispatch om de
    // export te triggeren in de controller
    store_name: {
      required: true
    },
  },

  mixins: [
    CopiesContent,
    ThrottlesRequests,
    WorksWithParentsSiblingsAndChildren,
  ],

  data: function () {
    return {
      tooltip_content: '',
      active: true,
    };
  },

  computed: {
    is_local_data_table() {
      return typeof this.find_matching_parent('medimoLocalDataTableUid') !== 'undefined';
    },
    is_external_data_table() {
      return typeof this.find_matching_parent('medimoExternalDataTableUid') !== 'undefined';
    }
  },

  created() {
    //
  },

  mounted() {
    // To have a single point of truth, we set an initial value based on the data, only to overwrite that later
    // if the user clicks the button
    this.tooltip_content = this.tooltip_content_start;
  },

  methods: {
    toggleTooltipMessage() {
      this.tooltip_content = 'Exporteren gelukt!';
      setTimeout(() => {
        this.tooltip_content = this.tooltip_content_start;
      }, 5000);
    },
    exportContentsToExternalFile() {

    },
    exportContent() {
      // if ExternalDataTable, export through the API
      if (this.is_external_data_table) {
        this.dispatchThrottled(this.store_name + '/export', {}, 300).catch(error => {
          if (error.message !== 'Throttled') {
            // nothing we can do
            this.$root.sendSentry(error);
          }
        });
      } else if (this.is_local_data_table) {
        // if LocalDataTable, export locally
        this.dispatchThrottled(this.store_name + '/export_local_data', {}, 300).catch(error => {
          if (error.message !== 'Throttled') {
            // nothing we can do
            this.$root.sendSentry(error);
          }
        });
      } else {
        console.error('Deze MedimoTable is noch external, noch local');
      }
    }
  },

  watch: {
    //
  }
};
</script>
