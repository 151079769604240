<template>
    <div style="display:inline-block;">

        <medimo-link
                id="bsn-link"
                :class="'status badge badge-sm ml-1 ' + (patient.hasBsn ? 'badge-medimo-50' : 'badge-warning')"
                @click="show_bsn_modal = true"
                :to="''">
            <medimo-tooltip :content="(patient.hasBsn ? '' : 'Geen ') + 'BSN Bekend'" class="small">BSN</medimo-tooltip>
        </medimo-link>

        <edit-bsn-modal v-if="show_bsn_modal" :patient="patient" @closed="show_bsn_modal = false" />
    </div>
</template>

<script>
import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import EditBsnModal from '@/vue/components/patients/bsn/EditBsnModal';

export default {
  components: { EditBsnModal },
  mixins: [ BaseComponentsMixin ],
  props: [ 'patient' ],
  data: function() {
    return {
      show_bsn_modal: false,
    };
  }
};
</script>
