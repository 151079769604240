<template>
  <span class="avatar thumbnail image d-flex align-items-center" :style="'left: ' + left + 'px;'">
    <img :style="'width: ' + size + '; height: ' + size +'px;'" :src="patient.profile_picture" alt="">
  </span>
</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';

export default {
  components: {},

  props: {
    'patient': {required: true},
    'size': {default: 32},
    'left': {default: 0},
  },

  mixins: [BaseComponentsMixin],

  data: function () {
    return {
      //
    };
  },

  computed: {
    //
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    //
  },

  watch: {
    //
  }
};
</script>
<style lang="scss">
.thumbnail {
  left: -4px;
  position: relative;
  top: 3px;

  img {
    border-radius: 50px;
    object-fit: cover;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.50);
  }
}
</style>
