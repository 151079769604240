export default {

  /**
   * Exporteert een array met objecten naar CSV waarbij de eerste row de kolommen zijn
   *
   * @param array
   * @param delimiter
   */
  exportToCsv(array, delimiter = ';') {

    const column_names = Object.keys(array[0]);

    const csvFileName = "MEDIMO-DATA-" + Date.now() + '.csv';
    // let csvContent = "data:text/csv;charset=utf-8,"
    let csvContent = column_names.join(delimiter) + "\r\n";
    array.forEach(row => {
      csvContent += Object.values(row).join(delimiter) + "\r\n";
    });

    if (window.navigator.msSaveBlob) {
      //Internet Explorer 11
      window.navigator.msSaveBlob(new Blob([csvContent]), csvFileName);
    } else if (window.webkitURL != null) {
      // Chrome, Firefox
      const a = document.createElement("a");
      csvContent = encodeURIComponent(csvContent);
      a.href = "data:application/csv;charset=UTF-8," + csvContent;
      a.download = csvFileName;
      a.click();
    } else {
      //Everything Else
      window.open(csvContent);
    }
  },

};
