import BaseModule from '@/vue/store/modules/base_module';

const state = {
  ...BaseModule.state,

  search_endpoint: '/api/v1/lsp-query',

  filter_columns: [
    'interaction',
  ],

  quick_filters: {
    'Niet verwerkt': true,
    'Fouten': false,
  },
  toggleQuickFilters: true,

  sort_columns: [
    {column_name: 'created_at', direction: 'DESC'}
  ],
};

// getters
const getters = {
  ...BaseModule.getters
};

// actions
const actions = {
  ...BaseModule.actions,

  fetch({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/lsp-query';

    return dispatch('api/getEndpoint', {
      endpoint: endpoint,
      data: {} // Empty, since it's a GET
    }, {root: true}).then(response => {

      commit('set_search_results', response.data.data);
      commit('set_search_meta', response.data.meta);

    });
  },
};

// mutations
const mutations = {
  ...BaseModule.mutations
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
