import BaseModule from '@/vue/store/modules/base_module';

// initial state
const state = {
  ...BaseModule.state,

  data: [],

  quick_filters: {
    'chat': {
      'mustBeActive': true,
      'group': {
        'inkomend': true,
        'uitgaand': false,
      }
    },
    'patients': {
      'mustBeActive': true,
      'visible': true,
      'group': {
        'eigen patient_plural': true,
        'waarneming': false,
      }
    }
  },
  toggleQuickFilters: true,

  sort_columns: [{column_name: 'updatedAt', direction: 'DESC'}],
  default_sort_columns: [{column_name: 'updatedAt', direction: 'DESC'}],
};

// getters
const getters = {
  ...BaseModule.getters,
  // Quick filter functions to prevent error
  inkomend_quick_filter() {
    return (data) => {
      return data;
    };
  },
  uitgaand_quick_filter() {
    return (data) => {
      return data;
    };
  },
  eigen_patient_plural_quick_filter() {
    return (data) => {
      return data;
    };
  },
  waarneming_quick_filter() {
    return (data) => {
      return data;
    };
  },
};

// actions
const actions = {
  ...BaseModule.actions,

  fetchIncoming({state, commit, dispatch, getters, rootState}, payload) {
    payload = {};
    payload.endpoint = '/api/v1/chats/incoming';
    return dispatch('chats/fetch', payload, {root:true});
  },
  fetchIncomingActing({state, commit, dispatch, getters, rootState}, payload) {
    payload = {};
    payload.endpoint = '/api/v1/chats/incoming-acting';
    return dispatch('chats/fetch', payload, {root:true});
  },
  fetchOutgoing({state, commit, dispatch, getters, rootState}, payload) {
    payload = {};
    payload.endpoint = '/api/v1/chats/outgoing';
    return dispatch('chats/fetch', payload, {root:true});
  },
  fetch({state, commit, dispatch, getters, rootState}, payload) {
    return dispatch('api/getEndpoint', {
      endpoint: payload.endpoint,
      data: {} // Empty, since it's a GET
    }, {root: true}).then(response => {
      commit('set_data', response.data.data);
    });
  },
  store({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/patients/chats/' + payload.patient_id + '/store';
    return dispatch('api/postEndpoint', {
      endpoint: endpoint,
      data: payload.data
    }, {root: true});
  },
  fetchChatMessages({state, commit, dispatch, getters, rootState}, payload) {
    return dispatch('patient_chats/fetchChatMessages', payload, {root:true});
  },
  reply({state, commit, dispatch, getters, rootState}, payload) {
    return dispatch('patient_chats/reply', payload, {root:true});
  },
  read({state, commit, dispatch, getters, rootState}, payload) {
    return dispatch('patient_chats/read', payload, {root:true});
  },
  close({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/patients/chats/' + payload.patient_id + '/close/' + payload.chat_id;
    return dispatch('api/postEndpoint', {
      endpoint: endpoint,
      data: payload.data
    }, {root: true}).then(response => {
      const data = response.data.data;
      data.patient_id = payload.patient_id;
      return response;
    });
  },
};

// mutations
const mutations = {
  ...BaseModule.mutations,
  quick_filters_visibility(state, visible) {
    // Change visibility patients quick filter
    state.quick_filters.patients.visible = visible;
  }
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations,
};
