<script>

import ChecksIfVuePage from '@/vue/mixins/navigation/ChecksIfVuePage';
import MedimoLink from '@/vue/components/general/MedimoLink';
import MedimoButton from '@/vue/components/general/MedimoButton';

export default {
  // Nodig voor Vue3 "inline" template
  template: `
    <div v-bind="$attrs">
      <slot v-bind="self" />
    </div>`,

  components: {
    MedimoLink,
    MedimoButton,
  },

  props: ['class', 'style', 'key'],

  mixins: [
    ChecksIfVuePage
  ],

  data: function () {
    return {
      //
    };
  },

  computed: {
    // Nodig voor Vue3 "inline" template
    self() {
      return this;
    },
    isVuePage() {
      return this.currentPageIsVuePage();
    },
    showClusterSelector: {
      get() {
        return this.$store.state.settings.navigation.showClusterSelector;
      },
      set(value) {
        this.$store.commit('settings/navigation/setShowClusterSelector', value);
      }
    },
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    hideClusterSelector() {
      if (this.showClusterSelector) {
        this.showClusterSelector = false;
      }
    }
  },

  watch: {
    //
  }
};
</script>
