<template>
  <div
      :class="{'is-dark': dark}"
      class="week-days flex"
      style="margin: 0 10px;"
  >
    <div
        v-for="(weekDay, index) in weekDays"
        :key="index"
        class="flex-1 text-muted lm-fs-12 flex justify-content-right align-center week-days-container"
    >
            <span class="mr-2">
                {{ weekDay.replace('.', '') }}
            </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WeekDays',
  props: {
    weekDays: {type: Array, default: () => ([]), required: true},
    dark: {type: Boolean, default: null}
  }
};
</script>
