<template>
  <div>
    <medimo-tabs :min_height="false"
                 padding_classes_override="pt-1"
                 margin_classes_override="mt-2 mx-0 mb-0"
                 :white-tab-background="false"
                 :tab_names="tabNames" :tab_open_by_default="signalMessage.length ? 3 : 1" @clickedTab="clickedTab">
      <template v-slot:content-1>
        <medimo-form>
          <medimo-textarea
              id="signal-note"
              placeholder="Uw notitie / opmerking..."
              @update:modelValue="updateNote($event)"
              :rows="3"
              :modelValue="signal.note"></medimo-textarea>
        </medimo-form>
      </template>
      <template v-slot:content-2>
        <div style="text-align:center;">
          <medimo-button class="mt-4" @click="show_chat_modal = true">
            Open Berichten Venster
          </medimo-button>
        </div>
      </template>
      <template v-slot:content-3>
        <span v-if="signalMessage" v-html="signalMessage"></span>
        <span v-else>
                    Geen informatie bekend.
                </span>
        <protocol-viewer-modal
            :signal="signal"
            :trace="trace"
        ></protocol-viewer-modal>
      </template>
    </medimo-tabs>

    <add-chat-modal
        :patient_id="patientId"
        :chat_subject="signal.title"
        :trigger_model="'MedicationGuardSignal'"
        v-if="show_chat_modal"
        @closed="show_chat_modal = false"
        @stored="show_chat_modal = false">
    </add-chat-modal>

  </div>
</template>

<script>
import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import AddChatModal from '@/vue/components/patients/chats/AddChatModal';
import MfbProtocolViewer from '@/vue/components/mfb/MfbProtocolViewer';
import ProtocolViewerModal from './ProtocolViewerModal';

export default {
  components: {
    ProtocolViewerModal,
    MfbProtocolViewer,
    AddChatModal,
  },
  computed: {
    tabNames() {
      const tabNames = {'1': 'Notities', '2': this.message};

      if (this.signalMessage.length) {
        tabNames['3'] = 'Info';
      }

      return tabNames;
    },
    trace() {
      if (this.signal && this.signal.protocolId) {
        return this.signal.trace;
      }
      return false;
    },
    signalMessage() {
      // The first signal we get through is actually a really slim version with only a title.
      // That's why we need to check for the existence of the message property first.
      if (typeof this.signal.message === 'undefined') {
        return '';
      }
      return this.signal.message;
    },
    patientId() {
      return this.$route.params.patient_id;
    },
  },

  props: {
    'signal': {required: true},
    'schema_view': {default: false}
  },

  mixins: [BaseComponentsMixin],

  data() {
    return {
      show_chat_modal: false,
      message: 'Bericht',
    };
  },

  methods: {
    clickedTab(tab) {
      if (tab === '2') {
        this.show_chat_modal = true;
      }
    },
    updateNote: function (value) {
      this.$store.commit('medication_guard_signals/add_shadow_note', {
        'signal': this.signal,
        'note': value,
        'schema_view': this.schema_view,
      });
    },
  },

  watch: {
    //
  }
};
</script>
