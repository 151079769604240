<template>
  <div>
    <medimo-labeled-date-time-picker
        :class="extra_classes"
        :label_column_classes="label_column_classes"
        :no_margins="no_margins"

        v-bind:modelValue="modelValue"
        v-on:update:modelValue="onInput"

        :disabled="disabled"

        :name="name"
        :is_invalid="is_invalid"
        :datetime_format="datetime_format"
        :label="label"
        :minDate="minDate"
        :maxDate="maxDate"
        :deadline_today="deadline_today"
        :deadline_tomorrow="deadline_tomorrow"
        :deadline_weekbox="deadline_weekbox"
        :shortcuts="shortcuts"
        :input_container_styles="input_container_styles"
        :time_disabled="time_disabled"
        :only_date="only_date"
        :only_time="only_time"
        :show_icon="show_icon"
        :placeholder="placeholder"

        :time_reset="time_reset"
        :close_on_date_change="close_on_date_change"
        :close_on_time_change="close_on_time_change"

        :validation_class="validation_class"
        :help_text="help_text"
    >
      <slot v-if="hasSlot" name="custom"></slot>
    </medimo-labeled-date-time-picker>
  </div>
</template>

<script>

import MedimoLabeledDateTimePicker from './base/MedimoLabeledDateTimePicker';
import IsValidatedFormComponentMixin from '@/vue/mixins/form/IsValidatedFormComponentMixin';

export default {

  components: {
    MedimoLabeledDateTimePicker
  },

  emits: ['update:modelValue'],

  props: {
    'no_margins': {default: true},
    'inline': {default: true},
    'extra_classes': {default: ''},
    'label_column_classes': {default: 'col-auto'},
    'help_text': {default: ''},

    disabled: {default: false},

    // When set to TRUE, will set the time to 00:00 when selecting a date
    time_reset: {default: true},
    // Set to TRUE to close the DTP when a user clicks on a date button
    close_on_date_change: {default: false},
    // Set to TRUE to close the DTP when a user clicks on a time button
    close_on_time_change: {default: true},

    // Check MedimoDateTimePicker for comments on these properties
    name: {default: ''},
    is_invalid: {default: false},
    datetime_format: {default: 'DD-MM-YYYY HH:mm'},
    label: {default: ''},
    minDate: {default: '-'},
    maxDate: {default: '-'},
    deadline_today: {required: false},
    deadline_tomorrow: {required: false},
    deadline_weekbox: {required: false},
    only_time: {default: false},
    modelValue: {default: false},
    shortcuts: {default: false},
    input_container_styles: {default: ''},
    time_disabled: {default: false},
    only_date: {default: false},
    show_icon: {default: true},
    placeholder: {default: 'Selecteer datum/tijd'},
  },

  mixins: [
    IsValidatedFormComponentMixin
  ],

  data: function () {
    return {
      //
    };
  },

  computed: {
    hasSlot() {
      return !!this.$slots.custom;
    }
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    onInput(value) {
      this.$emit('update:modelValue', value);
    }
  },

  watch: {
    //
  }
};
</script>
