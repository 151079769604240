import Utility from '@/vue/utility/utility';
import BaseModule from '@/vue/store/modules/base_module';

// Deze is puur om de MedimoLocalDatatable te populaten
const state = {
  ...BaseModule.state,
  data: [],

  search_columns: [
    //
  ],
};

// getters
const getters = {
  ...BaseModule.getters,
};

// actions
const actions = {
  ...BaseModule.actions,
  fetch({state, commit, dispatch, getters, rootState}, payload) {
    // target_id
    // target_type
    const endpoint = '/api/v1/permissions/' + payload.target_type + '/' + payload.target_id + '/cooperations/';

    return dispatch('api/getEndpoint', {
      endpoint: endpoint
    }, {root: true}).then(response => {
      return response.data;
    });

  }
};

// mutations
const mutations = {
  ...BaseModule.mutations,
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
