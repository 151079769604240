import {AbstractMedication} from "@/controllers/AbstractMedication";
import {MedicationUse} from "@/domain/models/MedicationUse";
import {AbstractMedicationData} from "@/controllers/AbstractMedicationData";

export class MedicationUseController extends AbstractMedication<MedicationUse> {
  protected storeName = 'mp9/medication_uses';
  protected apiEndpoint = 'medication-use';

  protected fromJson(data: unknown): MedicationUse {
    return MedicationUse.fromJson(data);
  }
}

export type MedicationUseData = AbstractMedicationData<MedicationUse>
