<template>
  <span class="medimo-copy-button text-medimo-50 clickable ml-2 d-inline" @click.stop="$emit('printClicked')">
    <medimo-tooltip :content="tooltip_content">
      <fa-icon icon="fa-solid fa-print" size="lg" />
    </medimo-tooltip>
  </span>
</template>

<script>
import MedimoTooltip from './MedimoTooltip';

export default {
  components: {
    MedimoTooltip,
  },

  emits: ['printClicked'],

  props: {

  },

  mixins: [

  ],

  data: function () {
    return {
      tooltip_content: 'Print',
    };
  },

  computed: {
    //
  },

  created() {
    //
  },

  mounted() {

  },

  methods: {

  },

  watch: {
    //
  }
};
</script>
