import {DosingInstructionObject} from '@/domain/models/DosingInstructionObject';
import {QuantityObject} from '@/domain/models/QuantityObject';
import {RangeObject} from '@/domain/models/RangeObject';
import {AdministeringScheduleObject} from '@/domain/models/AdministeringScheduleObject';
import {AsNeededObject} from '@/domain/models/AsNeededObject';
import {TimeIntervalObject} from '@/domain/models/TimeIntervalObject';

export class GeneratedDosingInstructionObject { 
  public doseDuration: QuantityObject | null;
  public sequenceNumber: number | null;
  public dose: RangeObject | null;
  public administeringSchedule: AdministeringScheduleObject | null;
  public asNeeded: AsNeededObject | null;
  public administeringSpeed: RangeObject | null;
  public durationOfAdministration: TimeIntervalObject | null;

  constructor(doseDuration: QuantityObject | null = null, sequenceNumber: number | null = null, dose: RangeObject | null = null, administeringSchedule: AdministeringScheduleObject | null = null, asNeeded: AsNeededObject | null = null, administeringSpeed: RangeObject | null = null, durationOfAdministration: TimeIntervalObject | null = null) {
    this.doseDuration = doseDuration;
    this.sequenceNumber = sequenceNumber;
    this.dose = dose;
    this.administeringSchedule = administeringSchedule;
    this.asNeeded = asNeeded;
    this.administeringSpeed = administeringSpeed;
    this.durationOfAdministration = durationOfAdministration;
  }

  public static fromJson(json: unknown): DosingInstructionObject {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new DosingInstructionObject(
      parsedJson['doseDuration'] ? QuantityObject.fromJson(parsedJson['doseDuration']) : null,
      parsedJson['sequenceNumber'] as number,
      parsedJson['dose'] ? RangeObject.fromJson(parsedJson['dose']) : null,
      parsedJson['administeringSchedule'] ? AdministeringScheduleObject.fromJson(parsedJson['administeringSchedule']) : null,
      parsedJson['asNeeded'] ? AsNeededObject.fromJson(parsedJson['asNeeded']) : null,
      parsedJson['administeringSpeed'] ? RangeObject.fromJson(parsedJson['administeringSpeed']) : null,
      parsedJson['durationOfAdministration'] ? TimeIntervalObject.fromJson(parsedJson['durationOfAdministration']) : null,
    );
  }

  public static multipleFromJson(json: unknown): DosingInstructionObject[] {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    const items: DosingInstructionObject[] = [];
    for (const item of parsedJson) {
      items.push(this.fromJson(item));
    }
    return items;
  }
}
