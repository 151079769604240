import Utility from '@/vue/utility/utility';
import BaseModule from '@/vue/store/modules/base_module';

// initial state
// shape: [{ id, quantity }]
const state = {
  data: []
};

// getters
const getters = {
  all(state, getters, rootState) {
    return state.data;
  },
  is_infusion_interval(state, getters, rootState) {
    return (id) => {
      const value = Utility.find_model_by_property(state.data, 'value', Utility.parse_id(id));
      if (value === null) {
        return false;
      }
      return value.attributes.infusionInterval;
    };
  },
  ...BaseModule.getters
};

// actions
const actions = {
  fetch_all({state, commit, dispatch, getters, rootState}, payload) {
    // This method is not used. Data is loaded through initial_data.js, which calls set_data()
  },
};

// mutations
const mutations = {
  set_data(state, new_data) {
    state.data = new_data.data;
  },
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
