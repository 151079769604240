<template>
  <div>
    <medimo-labeled-textarea :class="(full_height ? 'h-100' : '') + ' ' + extra_classes"
                             :validation_class="validation_class"
                             :cols="cols"
                             :rows="rows"
                             :type="type"
                             :no_margins="no_margins"
                             :inline="inline"
                             :disabled="disabled"
                             :max_characters="max_characters"

                             v-bind:modelValue="modelValue"
                             @update:modelValue="onInput"

                             :label_column_classes="label_column_classes"
                             :name="name"
                             :label="label"
                             :help_text="help_text"
                             :placeholder="placeholder">
    </medimo-labeled-textarea>
  </div>
</template>

<script>

import MedimoLabeledTextarea from './base/MedimoLabeledTextarea';
import IsValidatedFormComponentMixin from '@/vue/mixins/form/IsValidatedFormComponentMixin';

export default {

  components: {
    MedimoLabeledTextarea
  },

  emits: ['update:modelValue'],

  props: {
    'no_margins': {default: true},
    'inline': {default: true},
    'cols': {default: 20},
    'rows': {default: 5},
    'disabled': {default: false},
    'max_characters': {default: null},
    'modelValue': {default: ''},
    'name': {default: ''},
    'label': {default: ''},
    'placeholder': {default: ''},
    'extra_classes': {default: ''},
    'help_text': {default: ''},
    'type': {default: 'text'},
    'label_column_classes': {default: 'col-auto'},
    'full_height': {default: true},
  },

  mixins: [
    IsValidatedFormComponentMixin
  ],

  data: function () {
    return {
      //
    };
  },

  computed: {
    //
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    onInput(value) {
      this.$emit('update:modelValue', value);
    }
  },

  watch: {
    //
  }
};
</script>
