<template>
  <div class="d-inline">
    <div :class="'d-inline mr-4 ' + cursor_class" v-if="inline" @click="inputTrigger">
      <fa-icon v-if="label_position === 'right'" :icon="icon" size="lg" :class="cursor_class + ' mr-2'" />
      <label :for="selected_value" :class="cursor_class + ' mr-2'">
        {{ label }}
      </label>
      <fa-icon v-if="label_position === 'left'" :icon="icon" size="lg" :class="cursor_class + ' mr-2'" />
    </div>
    <medimo-form-row v-else :no_margins="no_margins" @click="inputTrigger"
                     :class="'medimo-radio mr-3 '  + (disabled ? 'opacity-50' : '')">
        <medimo-form-column :column_classes="label_column_classes" :class="cursor_class" :no_margin="no_margins" :order_classes="label_order_class" v-if="label.length">
            <label :for="selected_value" :class="cursor_class">
                {{ label }}
            </label>
        </medimo-form-column>
        <medimo-form-column :column_classes="radio_button_column_classes" :class="cursor_class" :no_margin="no_margins">
            <fa-icon :icon="icon" size="lg" class="mr-0" />
        </medimo-form-column>
    </medimo-form-row>
  </div>
</template>

<script>

import MedimoFormRow from './MedimoFormRow';
import MedimoFormColumn from './MedimoFormColumn';

export default {

  components: {
    MedimoFormRow,
    MedimoFormColumn,
  },

  emits: ['update:modelValue'],

  props: {
    'no_margins': {default: true},
    // This is what v-model will define; usually the data object you
    'modelValue': {default: '', type: [String, Number]},

    // This is the value you want v-model to become if the user clicks on the radio button
    'selected_value': {default: '', type: [String, Number]},
    'label': {default: ''},
    'extra_classes': {default: ''},
    'type': {default: 'text'},

    'radio_button_column_classes': {default: 'col-auto'},
    'label_column_classes': {default: 'col-auto'},

    // Switch the label position from left to right and vice versa
    'label_position': {default: 'right'},

    // Set toe TRUE to disable the radio button and any click events
    'disabled': {default: false},

    'inline': {default: false},
  },

  mixins: [],

  data: function () {
    return {
      //
    };
  },

  computed: {
    is_selected() {
      return this.modelValue == this.selected_value;
    },
    icon() {
      return this.is_selected ? 'fa-regular fa-circle-dot' : 'fa-regular fa-circle';
    },
    cursor_class() {
      return this.disabled ? 'disabled' : 'clickable';
    },
    // Sets the column's order based on the label_position property.
    label_order_class() {
      if (this.label_position === 'left') {
        return 'order-first';
      }
      if (this.label_position === 'right') {
        return 'order-last';
      }
    }
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    inputTrigger() {
      // Disable the output / interactivity when it's disabled
      if (!this.disabled) {
        this.$emit('update:modelValue', this.selected_value);
      }
    }
  },

  watch: {
    //
  }
};
</script>

<style scoped lang="scss">
  @import "/variables";
  svg.svg-inline--fa {
    font-size: 18px;
    vertical-align: -0.6em;
    margin-top: 0.3em;
    color: $medimo;
  }
  .disabled {
    svg.svg-inline--fa {
      color: $gray;
    }
  }
</style>
