import Utility from '@/vue/utility/utility';
import BaseModule from '@/vue/store/modules/base_module';

// initial state
// shape: [{ id, quantity }]
const state = {
  ...BaseModule.state,

  // Report preferences. Omdat we constant nieuwe reports genereren in de backend
  // slaan we dingen als welke view een gebruiker prefereert hier op. Dan kan die bij
  // ieder report van eenzelfde type hergebruikt worden

  // report_name: {view: 'view_name'}

  views: {},
};

// getters
const getters = {
  ...BaseModule.getters,
  view(state, getters, rootState, rootGetters) {
    return (report_name) => {
      if (typeof state.views[report_name] !== 'undefined') {
        return state.views[report_name];
      }
      return null;
    };
  }
};

// actions
const actions = {
  ...BaseModule.actions,
  // dummy_action ({state, commit, dispatch, getters, rootState}, payload) {
  //     //
  // }
};

// mutations
const mutations = {
  ...BaseModule.mutations,
  set_view(state, payload) {
    state.views[payload.report_name] = payload.view_name;
  },
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
