<template>
  <medi-modal title="Wilt u de pagina verlaten?" size="small" @closed="cancel" :initial_z_index="2030" :position="'relative'" id="unsaved-changes-modal">

    <div>
      Er zijn nog onopgeslagen wijzigingen op deze pagina. Wanneer u de pagina verlaat worden deze NIET opgeslagen!
    </div>

    <template v-slot:action-buttons>
      <medimo-button type="button" class="btn btn-primary" @click="proceed">
        Pagina verlaten
      </medimo-button>
      <medimo-button type="button" class="btn btn-outline-medimo" @click="cancel">
        Op pagina blijven
      </medimo-button>
    </template>
  </medi-modal>
</template>

<script>
import MediModal from '@/vue/components/general/MediModal';
import MedimoButton from '@/vue/components/general/MedimoButton';
import NavigatesProgrammatically from '@/vue/mixins/navigation/NavigatesProgrammatically';

export default {
  components: {MedimoButton, MediModal},

  props: [],

  mixins: [
    NavigatesProgrammatically,
  ],

  data: function () {
    return {
      //
    };
  },

  computed: {
    to() {
      return this.$store.getters['settings/navigation/unsavedChangesModalToLink'];
    }
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    proceed() {
      this.$store.commit('settings/navigation/setHasUnsavedChanges', false);
      this.$store.commit('settings/navigation/setShowUnsavedChangesModal', false);
      this.$store.commit('settings/navigation/setUnsavedChangesUrlWhitelist', []);
      this.navigateProgrammatically();
    },
    cancel() {
      this.$store.commit('settings/navigation/setShowUnsavedChangesModal', false);
    },
  },

  watch: {
    //
  }
};
</script>
