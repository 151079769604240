<template>
  <medi-modal
      :loading="loading"
      :title="'Nieuwe intolerantie: '+zindex_intolerance.text"
      size="large"
      @closed="closed">

    <medimo-form v-if="!view_confirm">

      <checkboxes-by-type v-if="options_groep.length > 0"
        label="Allergie/Intolerantie groepen"
        type="groep"
        :options="options_groep">
      </checkboxes-by-type>

      <checkboxes-by-type v-if="options_stof.length > 0"
        col_class="col-12 col-md-4"
        label="Allergie/Intolerantie stof"
        type="stof"
        :options="options_stof">
      </checkboxes-by-type>

      <checkboxes-by-type v-if="options_stof_toedien.length > 0"
        col_class="col-12 col-md-4"
        label="Allergie/Intolerantie stof incl. toedienwijze"
        type="stof_toedien"
        :options="options_stof_toedien">
      </checkboxes-by-type>

      <checkboxes-by-type v-if="options_preparaat.length > 0"
        label="Allergie/Intolerantie handelspreparaat"
        type="handelspreparaat"
        :options="options_preparaat">
      </checkboxes-by-type>

      <medimo-form-row>
        <medimo-form-column column_classes="col-6">
          <medimo-validated-select2
              label_column_classes="col-12 col-sm-4"
              label="Allergie/intolerantie"
              name="intolerance_type"
              :options="intolerance_options"
              v-model="intolerance_type"
          ></medimo-validated-select2>
        </medimo-form-column>
      </medimo-form-row>

      <medimo-form-row>
        <medimo-form-column column_classes="col-6">
          <medimo-validated-input
              label_column_classes="col-12 col-sm-4"
              label="Bijzonderheden"
              name="info"
              v-model="info"
          ></medimo-validated-input>
        </medimo-form-column>
      </medimo-form-row>

      <medimo-form-row>
        <medimo-form-column column_classes="col-6">
          <medimo-validated-date-picker
              label_column_classes="col-12 col-sm-4"
              label="Vaststellingsmoment"
              placeholder="(Optioneel)"
              name="register_date"
              v-model="register_date">
          </medimo-validated-date-picker>
        </medimo-form-column>
      </medimo-form-row>

    </medimo-form>

    <add-intolerance-confirm v-if="view_confirm"
      :patient="patient"
      :info="info"
      :intolerance_type="intolerance_type"
      :intolerance_id="zindex_intolerance.id"
      :register_date="register_date">
    </add-intolerance-confirm>

    <template v-slot:action-buttons>
      <medimo-button :disabled="button_opslaan_disabled" @click="view_confirm = true" v-if="!view_confirm">
        Opslaan
      </medimo-button>
      <medimo-button :disabled="button_bevestig_disabled" @click="confirm" v-if="view_confirm">
        Bevestig
      </medimo-button>
      <medimo-button @click="close(false)" type="outline-primary">
        Annuleren
      </medimo-button>
    </template>

  </medi-modal>
</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import PatientInfoMediModal from '@/vue/components/modals/PatientInfoMediModal';
import AddIntoleranceConfirm from './AddIntoleranceConfirm';
import CheckboxesByType from './form/CheckboxesByType';

export default {
  emits: ['closed'],
  components: {
    CheckboxesByType,
    AddIntoleranceConfirm,
    PatientInfoMediModal,
  },

  props: {
    'zindex_intolerance': {required: true},
    'patient': {required: true},
  },

  mixins: [
    BaseComponentsMixin
  ],

  data: function () {
    return {
      loading: false,
      intolerance_type: 'A',
      info: '',
      register_date: '',
      view_confirm: false
    };
  },

  computed: {
    intolerances_to_be_added() {
      return this.$store.getters['intolerances_zindex/allCheckedOptions'];
    },
    medication_guard_signals_to_be_checked() {
      return this.signals.filter((signal) => {
        return (signal.requiresApproval === 1 || signal.requiresApproval === true) && !signal.approved;
      });
    },
    options_groep() {
      return this.$store.getters['intolerances_zindex/filteredOptionsGroep'];
    },
    options_stof() {
      return this.$store.getters['intolerances_zindex/filteredOptionsStof'];
    },
    options_stof_toedien() {
      return this.$store.getters['intolerances_zindex/filteredOptionsStofEnToedien'];
    },
    options_preparaat() {
      return this.$store.getters['intolerances_zindex/filteredOptionsPreparaat'];
    },
    intolerance_options() {
      return [
        { value: 'A', text: 'Allergie' },
        { value: 'I', text: 'Intolerantie' }
      ];
    },
    button_opslaan_disabled() {
      // nog geen intolerance_type gekozen
      if (this.intolerance_type === '') {
        return true;
      }

      // nog geen option aangevinkt
      if (this.intolerances_to_be_added.length === 0) {
        return true;
      }

      // all set!
      return false;
    },
    button_bevestig_disabled() {

      // voer eerst zelfde checks als bij opslaan uit
      if (this.button_opslaan_disabled === false) {

        // niet alle signalen afgevinkt
        if (this.medication_guard_signals_to_be_checked.length > 0) {
          return true;
        }
      }

      // all set!
      return false;
    },
    signals() {
      return this.$store.getters['medication_guard_signals/for_added_intolerance'](this.zindex_intolerance.id);
    },
  },

  created() {
  },

  mounted() {
  },

  methods: {
    clearSignalsAndTraces() {
      this.$store.commit('medication_guard_signals/clear');
    },
    close(refresh) {
      this.clearSignalsAndTraces();
      this.$emit('closed', refresh);
    },
    closed() {
      this.clearSignalsAndTraces();
      this.$emit('closed');
    },
    confirm() {
      // check if there are any unapproved signals that should be approved
      if (this.medication_guard_signals_to_be_checked.length > 0) {
        this.$store.dispatch('notifications/addDangerAlert', {
          title: 'Medicatiebewakingssignalen met een * dienen geautoriseerd te worden'
        });
        return;
      }
      // everything validated! store
      this.store();
    },
    store() {
      this.loading = true;
      this.$store.dispatch('intolerances/store', {
        patient_id: this.patient.id,
        data: {
          intolerance_type: this.intolerance_type,
          info: this.info,
          register_date: this.register_date,
          intolerances_to_be_added: this.intolerances_to_be_added,
          signals: this.signals,
        }
      }).then(() => {
        this.loading = false;
        this.close(true);
      }).catch((err) => {
        this.loading = false;
      });
    },
  },

  watch: {

  }
};
</script>
