<template>
    <div class="nav-search-authorisation-list-link w-100 p-0 m-0">
      <NavSearchLink
        class="px-0"
        :indexGroup="indexGroup"
        :navSearchItem="navSearchItem"
        :target="target"
        :select_first_link="select_first_link"
        :first_link_index="first_link_index"
        :navigateOnClicked="false"
        :closeOnClicked="false"
        @clicked="clicked()"
      />
      <AuthorisationListDatePickModal
          v-if="showModal"
          @closed="showModal = false"
          :route="route"
      />
    </div>
</template>
<script>
import NavSearchLink from "@/vue/components/nav/NavSearchLink.vue";
import AuthorisationListDatePickModal from "@/vue/components/modals/AuthorisationListDatePickModal.vue";
import moment from "moment-mini";
import NavigatesProgrammatically from "@/vue/mixins/navigation/NavigatesProgrammatically.vue";

export default {
  components: {AuthorisationListDatePickModal, NavSearchLink},

  props: {
    'triggerNavigation': {required: true}, // @enter

    // To make sure the Links are grouped properly, we prepend the index with a number
    // Otherwise the keyoard navigation might start in the middle
    'indexGroup': {default: 0},

    // Contains a title, path, visible (boolean), and optionally, right_title property
    'navSearchItem': {},

    // Set to '_blank' to open in a new window
    'target': {default: '_self'},

    // Use these to auto-select the first link
    'select_first_link': {default: false},
    'first_link_index': {default: -1},
  },

  mixins: [
    NavigatesProgrammatically,
  ],

  data: function () {
    return {
      showModal: false,
    };
  },

  computed: {
    uid() {
      return this._.uid;
    },
    selectedNavSearchLinkUid: {
      get() {
        return this.$store.state.settings.navigation.selectedNavSearchLinkUid;
      },
      set(value) {
        this.$store.commit('settings/navigation/setSelectedNavSearchLinkUid', value);
      }
    },
    isSelected() {
      // We moeten hier -1 doen omdat het de parent is van de selectedNavSearchLink
      return this.uid === (this.selectedNavSearchLinkUid - 1);
    },
    route() {
      return this.navSearchItem.path;
    },
    routeData() {
      return this.$store.getters['routes/routes'](this.route);
    },
    months() {
      if (this.route.includes('months=3')) {
        return 3;
      }
      if (this.route.includes('months=12')) {
        return 12;
      }
      return 0;
    },
    date() {
      return moment().add(this.months, 'months').format('YYYY-MM-DD');
    },
    url() {
      return this.route.split('?months=')[0];
    }
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    clicked() {
      if (this.months === 0) {
        this.showModal = true;
        return;
      }

      // Bij de months=3 en months=12 variant willen we in 1x door navigeren, anders modal tonen
      this.to = this.url + '?endDate=' + this.date;
      this.navigateProgrammatically();
    }
  },

  watch: {
    triggerNavigation() {
      if (this.isSelected) {
        this.clicked();
      }
    }
  }
};
</script>
<style lang="scss">
/**
  Nodig om de styles te counteren voor de modal, aangezien deze hier nested is in NavSearch
  Weer net ff anders dan de ReportLink dus voor flexibiliteit een volledige definitie
 **/
#top-nav li.nav-item .nav-search-authorisation-list-link .medimodal .btn {
  padding: 0.3rem 0.75rem;
}
#nav-search .list-group .nav-search-authorisation-list-link .medimodal {
  .btn-primary, .btn:hover, .btn-medimo {
    color: white;
  }
}

#nav-search .nav-search-authorisation-list-link .medimodal {
  input {
    border-radius: 3px;
    width: 100%;
    padding-left: 10px;
    min-height: 0;
  }

  svg.svg-inline--fa {
    opacity: 1;
  }
}
</style>
