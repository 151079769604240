import BaseModule from '@/vue/store/modules/base_module';

const state = {
  ...BaseModule.state,
  quick_filters: {}, // {'Technische logs': false},
  sort_columns: [
    {column_name: 'created_at', direction: 'DESC'}
  ],
  history: {},
};

const getters = { ...BaseModule.getters };
const actions = { ...BaseModule.actions };
const mutations = { ...BaseModule.mutations };

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
