import Utility from '@/vue/utility/utility';
import BaseModule from '@/vue/store/modules/base_module';

// getters
const getters = {
  ...BaseModule.getters,
  base_for(state, getters, rootState) {
    return (for_params) => {
      const stateKey = for_params.state_key; //db_data, data or shadow_data
      const propertyName = for_params.property_name; //eg medication_treatment_id, medication_agreement_id, schema_id
      const propertyValue = for_params.property_value;

      const dosingInstructions = Utility.find_models_by_property(state[stateKey], propertyName, propertyValue);

      if (for_params.sort) {
        dosingInstructions.sort(function (a, b) {
          if (a.start_moment_datetime > b.start_moment_datetime) {
            return 1;
          } else return -1;
        });
      }

      return dosingInstructions;
    };
  },
  for_medication_agreements(state, getters, rootState, rootGetters) {
    return (medicationAgreements) => {
      let allDosageInstructions = [];
      for (let i = 0; i < medicationAgreements.length; i++) {
        const dosageInstructions = getters['for_medication_agreement_id'](medicationAgreements[i].id);
        allDosageInstructions = [].concat(allDosageInstructions, dosageInstructions);
      }
      return allDosageInstructions;
    };
  },
  for_medication_agreement_id(state, getters, rootState, rootGetters) {
    return (medicationAgreementId) => {
      const params = {state_key: 'data', property_name: 'medication_agreement_id', property_value: medicationAgreementId, sort: true};
      return getters['base_for'](params);
    };
  },
  for_local_medication_treatment_id(state, getters, rootState, rootGetters) {
    return (medicationTreatmentId) => {
      const params = {state_key: 'data', property_name: 'medication_treatment_id', property_value: medicationTreatmentId, sort: true};
      return getters['base_for'](params);
    };
  },
  for_db_medication_treatment_id(state, getters, rootState, rootGetters) {
    return (medicationTreatmentId) => {
      const params = {state_key: 'db_data', property_name: 'medication_treatment_id', property_value: medicationTreatmentId, sort: true};
      return getters['base_for'](params);
    };
  },
  for_db_medication_agreement_id(state, getters, rootState, rootGetters) {
    return (medicationAgreementId) => {
      const params = {state_key: 'db_data', property_name: 'medication_agreement_id', property_value: medicationAgreementId, sort: true};
      return getters['base_for'](params);
    };
  },
  for_shadow_medication_agreement_id(state, getters, rootState, rootGetters) {
    return (medicationAgreementId) => {
      const params = {state_key: 'shadow_data', property_name: 'medication_agreement_id', property_value: medicationAgreementId, sort: false};
      return getters['base_for'](params);
    };
  },
  for_shadow_medication_agreement_sorted(state, getters, rootState, rootGetters) {
    return (medicationAgreementId) => {
      const params = {state_key: 'shadow_data', property_name: 'medication_agreement_id', property_value: medicationAgreementId, sort: true};
      return getters['base_for'](params);
    };
  },
  for_shadow_medication_agreement(state, getters, rootState, rootGetters) {
    return (medicationAgreementId) => {
      const params = {state_key: 'shadow_data', property_name: 'medication_agreement_id', property_value: medicationAgreementId, sort: false};
      return getters['base_for'](params);
    };
  },
  for_medication_agreement(state, getters, rootState) {
    return (medicationAgreementId) => {
      const params = {state_key: 'data', property_name: 'medication_agreement_id', property_value: medicationAgreementId, sort: false};
      return getters['base_for'](params);
    };
  },
  for_schema(state, getters, rootState) {
    return (first_dosing_instruction_id) => {
      const params = {state_key: 'data', property_name: 'first_dosing_instruction_id', property_value: first_dosing_instruction_id, sort: true};
      return getters['base_for'](params);
    };
  },
  for_shadow_schema(state, getters, rootState) {
    return (first_dosing_instruction_id) => {
      const params = {state_key: 'shadow_data', property_name: 'first_dosing_instruction_id', property_value: first_dosing_instruction_id, sort: true};
      return getters['base_for'](params);
    };
  },
};

export default {
  namespaced: true,
  getters,
};
