import {GeneratedMedicationUse} from '@/domain/models/generated/GeneratedMedicationUse';
import moment from "moment";

export class MedicationUse extends GeneratedMedicationUse {
  public isActive() {
    const now = moment();

    if (this.startDateTime?.isBefore(now) && this.endDateTime === null) {
      return true;
    }

    if (this.startDateTime?.isBefore(now) && this.endDateTime?.isAfter(now)) {
      return true;
    }

    return false;
  }

  public author(): string {
    if (this.authorProfessional) {
      return this.authorProfessional.professionalObject.fullName();
    }

    if (this.authorProvider) {
      return this.authorProvider.providerSpecification.organizationName;
    }

    if (this.authorIsPatient) {
      return 'Patient';
    }

    return '-';
  }

  public hasToBeChanged(): boolean {
    return this.copyIndicator || this.distributedIndicator;
  }

  public hasToBeStopped(): boolean {
    return this.copyIndicator || this.distributedIndicator;
  }
}
