<template>
  <div class="root">
    <xml-tree-branch
        :element="element"
        :layer="1"
        :query-value="query"
        :onlyShowMatches="onlyShowMatches"
        :showMatchContent="showMatchContent"
        :fadeMatchContent="fadeMatchContent"
        :autoCollapseAfter="autoCollapseAfter"
        :properties-to-show="propertiesToShow"
    ></xml-tree-branch>
  </div>
</template>

<script>
import XmlTreeBranch from './XmlTreeBranch';

export default {
  name: "XmlTree",
  components: {XmlTreeBranch},
  props: {
    rawData: String,
    propertiesToShow: {default: []},
    autoCollapseAfter: {required: true},
    query: {default: ''},
    onlyShowMatches: {default: true},
    fadeMatchContent: {default: true},
    showMatchContent: {default: true},
  },
  computed: {
    element() {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(this.rawData, "text/xml");
      return xmlDoc.documentElement;
    },
    // Children zijn hier de child "DOM" nodes die vanuit de parser komen
    // Intern maakt hij op basis van de XML een soort tree aan die dezelfde eigenschappen heeft als een element uit de querySelector
    children() {
      const children = [];
      let node = this.element.firstChild;
      while (node) {
        children.push(node);
        node = node.nextSibling;
      }
      return children;
    },

    isText() {
      if (this.children.length === 1) {
        if (this.children[0].nodeType === 3) {
          return true;
        }
      }

      return false;
    }
  }
};
</script>
