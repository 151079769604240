import {Router, RouteLocation} from 'vue-router';

class NavigationController {
  private _router: Router;
  private _exitCallbacks: Map<string, _ExitCallback>;

  constructor(router: Router) {
    this._router = router;
    this._exitCallbacks = new Map();
    this._router.afterEach(this._handleNavigation.bind(this));
  }

  /**
   * Fire entryCallback when:
   * 1) entering a url which matches urlRegexes and
   * 2) entryCallback has not fired yet on 1 of these urls.
   * Then fire exitCallback once only when we navigate to a page that does not match the urlRegexes.
   * Use overwrite to overwrite an existing key.
   */
  public onEntry(key: string, urlRegexes: RegExp[], entryCallback: () => void, exitCallback: () => void, overwrite = false): void {
    if (!this._exitCallbacks.has(key) || overwrite) {
      entryCallback();
      this._exitCallbacks.set(key, new _ExitCallback(urlRegexes, exitCallback));
    }
  }

  private _handleNavigation(to: RouteLocation) {
    // Check exit callbacks.
    this._exitCallbacks.forEach((exitCallback, key) => {
      if (exitCallback.shouldExecuteOn(to.path)) {
        exitCallback.execute();
        this._exitCallbacks.delete(key);
      }
    });
  }
}

class _ExitCallback {
  readonly execute: () => void;
  _urlRegexes: RegExp[];

  constructor(routes: RegExp[], exitCallback: () => void) {
    this._urlRegexes = routes;
    this.execute = exitCallback;
  }

  public shouldExecuteOn(url: string): boolean {
    for (const routeRegex of this._urlRegexes) {
      if (url.match(routeRegex)) {
        return false;
      }
    }
    return true;
  }
}

export default NavigationController;
