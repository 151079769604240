<template>
  <div class="tr row">
    <div class="td col-12 p-0 m-0">
      <MedimoTr @triggered="editAction = true">
        <slot></slot>
      </MedimoTr>
    </div>
    <BaseActionModal
        :action="props.action"
        :patient="props.patient"

        :refresh_patient_medication_data="props.refresh_patient_medication_data"

        @closed="closedModal"
        @processed="processedModal"

        v-if="editAction"
    ></BaseActionModal>
  </div>
</template>

<script setup lang="ts">
import MedimoTr from '@/vue/components/general/table/MedimoTr.vue';
import BaseActionModal from '@/vue/components/patients/action-dialogs/_BaseActionModal.vue';
import {Action} from "@/domain/models/Action";
import {Patient} from "@/domain/models/Patient";
import {ref} from "vue";

const emit = defineEmits(['processed', 'refresh_table']);

interface Props {
  action: Action,
  patient: Patient,
  refresh_patient_medication_data?: boolean,
}

const props = withDefaults(defineProps<Props>(), {
  refresh_patient_medication_data: true,
});

const editAction = ref<boolean>(false);

function closedModal(): void {
  editAction.value = false;
}

function processedModal(): void {
  emit('processed');
  emit('refresh_table');
  editAction.value = false;
}
</script>
