import Utility from '@/vue/utility/utility';
import BaseModule from '@/vue/store/modules/base_module';

// initial state
// shape: [{ id, quantity }]
const state = {
  ...BaseModule.state,

  // Generated met https://www.json-generator.com/#
  // Super handig :)
  data: [],

  quick_filters: {
    // Zie base_module voor de meest recente omschrijving qua mogelijkheden
    'Alleen Tabletten': true,
    'Milliliter': false,
    'Medicijnen': {
      'group': {
        'Alleen Tabletten': false,
        'Alleen Zalf': false,
        'Alleen Druppels': false,
      }
    },
  },
  filter_columns: [
    // 'example'
  ],
};

// getters
const getters = {
  ...BaseModule.getters,
  milliliter_quick_filter() {
    return (data) => {
      return data;
    }
  },
  alleen_tabletten_quick_filter(state, getters, rootState, rootGetters) {
    // Om deze filter te triggeren moet er een quick_filter met de naam "Example" zijn.
    return (data, filter_state) => {
      if (!filter_state) return data;

      return data.filter((model) => {
        return model.fullnaam.toLowerCase().includes('tablet');
      });
    };
  },
  alleen_zalf_quick_filter(state, getters, rootState, rootGetters) {
    // Om deze filter te triggeren moet er een quick_filter met de naam "Example" zijn.
    return (data, filter_state) => {
      if (!filter_state) return data;

      return data.filter((model) => {
        return model.fullnaam.toLowerCase().includes('zalf');
      });
    };
  },
  alleen_druppels_quick_filter(state, getters, rootState, rootGetters) {
    // Om deze filter te triggeren moet er een quick_filter met de naam "Example" zijn.
    return (data, filter_state) => {
      if (!filter_state) return data;

      return data.filter((model) => {
        return model.fullnaam.toLowerCase().includes('druppels');
      });
    };
  },
};

// actions
const actions = {
  ...BaseModule.actions,
  fetch ({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/example-data/random';

    return dispatch('api/getEndpoint', {
      endpoint: endpoint,
      data: {} // Empty, since it's a GET
    }, {root: true}).then(response => {
      commit('set_data', response.data.data);
    });
  }
};

// mutations
const mutations = {
  ...BaseModule.mutations,
  // dummy_mutation (state, id) {
  //     state.id = id;
  // },
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
