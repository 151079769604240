import moment from "moment";
import {Options} from "@/controllers/InitialDataController";
import {MedicationVerification} from "@/domain/models/MedicationVerification";
import {MedicationVerificationItems} from "@/domain/models/MedicationVerificationItems";

export class MedicationUseVerification {
  public data: MedicationVerificationItems[];
  lastMedicationVerificationAt: moment.Moment | null;
  verification: MedicationVerification | null;
  informantOptions: Options[];

  constructor(data: MedicationVerificationItems[], lastMedicationVerificationAt: moment.Moment | null, verification: MedicationVerification, informantOptions: Options[]) {
    this.data = data;
    this.lastMedicationVerificationAt = lastMedicationVerificationAt;
    this.verification = verification;
    this.informantOptions = informantOptions;
  }

  public static fromJson(json: unknown): MedicationUseVerification {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new MedicationUseVerification(
      parsedJson['data'].map((e: unknown) => MedicationVerificationItems.fromJson(e)),
      parsedJson['lastMedicationVerificationAt'] ? moment(parsedJson['lastMedicationVerificationAt']) : null,
      MedicationVerification.fromJson(parsedJson['verification']),
      parsedJson['informantOptions'] as Options[],
    );
  }
}
