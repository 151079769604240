<template>
    <medimo-tr>
      <medimo-td column_classes="col-sm-3">
        <span class="badge badge-warning p-1 mr-1">MP9</span>
        {{ medication_agreement.pharmaceutical_product.description }}
      </medimo-td>
      <medimo-td column_classes="col-sm-5">
<!--        {{ instruction_for_use.description }}-->

        <span v-for="instruction in instruction_for_use.dosing_instructions">
          {{ instruction.display }}<br>
        </span>
      </medimo-td>
      <medimo-td>
        {{ medication_agreement.start_datetime }}
        {{ medication_agreement.stop_datetime }}
      </medimo-td>
    </medimo-tr>
</template>

<script>

import BaseComponentsMixin from '/vue/mixins/BaseComponentsMixin';

export default {
  components: {},

  props: {
    'medication_agreement': {required: true},
    'instruction_for_use': {required: true},
  },

  mixins: [BaseComponentsMixin],

  data: function () {
    return {
      //
    };
  },

  computed: {
    //
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    //
  },

  watch: {
    //
  }
};
</script>
