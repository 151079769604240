<template>
  <medimo-validated-vue-select2
      label_column_classes="col-md-3 pl-2"
      class="mt-2"
      :help_text="'Maak een keuze'"
      label="Vaccin"
      placeholder="Selecteer het vaccin"
      :minimum_results_for_search="2"
      :loading="loading"
      :options="options"
      v-model="localValue"
      name="vaccin">
  </medimo-validated-vue-select2>
</template>

<script>

import MedimoValidatedVueSelect2 from '@/vue/components/general/form/base/vue-select2/MedimoValidatedVueSelect2';

export default {
  components: {MedimoValidatedVueSelect2},
  emits: ['input'],
  props: [
    'value',
    'type'
  ],
  mixins: [],
  data: function () {
    return {
      localValue: null,
      options: [],
      loading: false,
    };
  },
  computed: {
    cluster_id() {
      return this.$store.state.current_user.data.cluster_id;
    }
  },
  created() {},
  mounted() {
    this.fetchVaccines();
  },
  methods: {
    fetchVaccines() {
      this.loading = true;
      this.$store.dispatch('api/getEndpoint', {
        endpoint: '/api/v1/clusters/' + this.cluster_id + '/vaccinations/' + this.type + '/vaccines',
      }).then(response => {
        this.options = response.data.data.map(vaccine => {
          return {value: vaccine.id.toString(), text: vaccine.name};
        });

        if (this.options.length === 0) {
          this.$store.dispatch('notifications/addDangerAlert', {title: 'Er zijn geen vaccins geconfigureerd voor dit cluster', message: 'Neem contact op met functioneel applicatiebeheer van uw organisatie.'});
        }
        if (this.options.length === 1) {
          this.localValue = this.options[0].value;
        }
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    }
  },
  watch: {
    value(value) {
      this.localValue = value;
    },
    localValue(value) {
      this.$emit('input', value);
    }
  }
};
</script>
