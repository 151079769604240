import BaseModule from '@/vue/store/modules/base_module';
import moment from 'moment-mini';
import Utility from '@/vue/utility/utility';

// initial state
const state = {
  ...BaseModule.state,

  data: [],

  quick_filters: {
    'Alleen actief': true,
  },

  toggleQuickFilters: true,

  history: {},
};

// getters
const getters = {
  ...BaseModule.getters,

  active(state) {
    return state.data.filter((episode) => {
      return episode.end_date === null || moment(episode.end_date).isAfter(moment());
    });
  },

  find_by_code(state, getters, rootState) {
    return (code) => getters['find_by_prop']({property: 'code', value: code});
  },
};

// actions
const actions = {
  ...BaseModule.actions,

  store({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/patients/' + payload.patient_id + '/episodes/store';
    return dispatch('api/postEndpoint', {
      endpoint: endpoint,
      data: payload.data,
    }, {root: true}).then(data => {
      commit('add_to_data', data.data.data);
    });
  },

  update({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/patients/' + payload.patient_id + '/episodes/' + payload.episode_id + '/update';
    return dispatch('api/postEndpoint', {
      endpoint: endpoint,
      data: payload.data,
    }, {root: true}).then(data => {
      commit('add_to_data', data.data.data);
    });
  },

  delete({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/patients/' + payload.patient_id + '/episodes/' + payload.episode_id + '/delete';
    return dispatch('api/postEndpoint', {
      endpoint: endpoint
    }, {root: true}).then(data => {
      commit('remove_from_data', {episode_id: data.data.data.id});
    });
  },

  fetchHistory({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/patients/' + payload.patient_id + '/episodes/history';
    return dispatch('api/getEndpoint', {
      endpoint: endpoint,
      data: {} // Empty, since it's a GET
    }, {root: true}).then(response => {
      commit('add_to_history', {patient_id: payload.patient_id, episodes_history: response.data.data});
    });
  },

};

// mutations
const mutations = {
  ...BaseModule.mutations,

  add_to_history(state, payload) {
    state.history[payload.patient_id] = payload.episodes_history;
  },
  clear_history(state, payload) {
    delete state.history[payload.patient_id];
  },
  remove_from_data(state, payload) {
    Utility.delete_models_by_property(state.data, 'id', payload.episode_id);
  }
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
