<template>
  <div class="tr row">
    <div class="td col-12 p-0 m-0">
      <medimo-tr @triggered="edit_intolerance = true">

        <medimo-td column_classes="col col-sm-3">
          <span>{{ intolerance.title }}</span>
          <span v-if="intolerance.attributes" class="text-danger">
            <template v-for="attribute in intolerance.attributes">
              {{ attribute }}
            </template>
          </span>
        </medimo-td>

        <medimo-td column_classes="col col-sm-3">
          <span>{{ parsedRegisteredDate }}</span>
        </medimo-td>

        <medimo-td column_classes="col col-sm-3">
          <span>{{ intolerance.info }}</span>
        </medimo-td>

        <medimo-td column_classes="col col-sm-3">
          <span>{{ parsedEndDate }}</span>
        </medimo-td>

      </medimo-tr>
    </div>
    <edit-intolerance-modal
        :intolerance="intolerance"
        :patient="patient"
        @closed="closedModal"
        @processed="processedModal"
        v-if="edit_intolerance"
    ></edit-intolerance-modal>
  </div>
</template>

<script>
import Dates from '@/vue/utility/dates';
import MedimoTd from '@/vue/components/general/table/MedimoTd';
import MedimoTr from '@/vue/components/general/table/MedimoTr';
import EditIntoleranceModal from './EditIntoleranceModal';

export default {
  emits: ['refresh_table'],
  components: {
    EditIntoleranceModal,
    MedimoTd,
    MedimoTr,
  },

  props: [
    'intolerance',
    'patient',
  ],

  mixins: [],

  data: function () {
    return {
      edit_intolerance: false,
    };
  },

  computed: {
    parsedRegisteredDate() {
      if (this.intolerance.register_date === null) {
        return 'Onbekend';
      }
      return Dates.setDate(this.intolerance.register_date).dutchDate();
    },
    parsedEndDate() {
      return Dates.setDate(this.intolerance.stop_date).dutchDateTime();
    }
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    closedModal() {
      this.edit_intolerance = false;
    },
    processedModal() {
      this.$emit('refresh_table');
      this.edit_intolerance = false;
    },
  },

  watch: {
    //
  }
};
</script>
