<template>
  <medi-modal
      :loading="loading"
      title="Wijzig BEM"
      size="medium"
      @closed="$emit('closed')"
  >
    <medimo-form>
      <medimo-radio
          :id="'bem_option_'+option.id"
          :index="index"
          :key="option.id"
          :label="option.bemcode + ' - ' + option.naam + ''"
          :selected_value="option.id"
          :label_column_classes="'w-75'"
          v-for="(option, index) in bem_options"
          v-model="picked"
      ></medimo-radio>
    </medimo-form>

        <template v-slot:action-buttons>
            <medimo-button class="mr-md-auto" type="outline-primary" @click="show_bem_history_modal = true">
                Historie
            </medimo-button>
            <medimo-button @click="updatePatientBem" class="mr-2">
                Opslaan
            </medimo-button>
            <medimo-button type="outline-medimo" @click="$emit('closed')">
                Annuleren
            </medimo-button>
        </template>

    <bem-history-modal
        :patient_id="patient.id"
        v-if="show_bem_history_modal"
        @closed="show_bem_history_modal = false"
    ></bem-history-modal>

  </medi-modal>
</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import BemHistoryModal from './BemHistoryModal';

export default {

  components: {
    BemHistoryModal,
  },

  emits: ['closed'],

  props: [
    'trigger',
    'patient'
  ],

  mixins: [
    BaseComponentsMixin
  ],

  data: function () {
    return {
      loading: false,
      show_bem_history_modal: false,
      picked: '',
    };
  },

  computed: {
    bem_options() {

      const bem_options = this.$store.state.bem.options[this.patient.id];

      if (typeof bem_options === 'undefined') {
        return [];
      }

      return bem_options;
    },
    selected_bem_id() {
      return this.patient.independenceClassificationId;
    }
  },

  created() {
    this.fetchOptions();
  },

  mounted() {
    // initial selected value
    this.picked = this.selected_bem_id;
  },

  unmounted() {
    //
  },

  methods: {
    fetchOptions() {

      // only fetch when not already available
      if (this.bem_options.length > 0) {
        return;
      }

      this.loading = true;

      this.$store.dispatch('bem/fetchOptions', {
        patient_id: this.patient.id
      }).then(() => {
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },

    updatePatientBem() {

      this.loading = true;

      this.$store.dispatch('bem/update', {
        patient_id: this.patient.id,
        data: {id: this.picked}
      }).then(() => {
        this.loading = false;
        this.close();
      }).catch(() => {
        this.loading = false;
      });
    },
  },

  watch: {
    //
  }
};
</script>
