<script>
/**
 * Use this mixin to enable quick access to true/false usages on the width of the page
 * Acts as a Vue friendly replacement of the d-block d-lg-none, etc. classes
 * And keeps the DOM clean.
 */
export default {
  components: {},

  props: [],

  mixins: [],

  inject: ["mq"],

  data: function () {
    return {
      //
    };
  },

  computed: {
    sm() {
      return this.mq.sm;
    },
    md() {
      return this.mq.md;
    },
    lg() {
      return this.mq.lg;
    },
    xl() {
      return this.mq.xl;
    },
    smPlus() {
      return this.mq.smPlus;
    },
    mdPlus() {
      return this.mq.mdPlus;
    },
    lgPlus() {
      return this.mq.lgPlus;
    },
    xlPlus() {
      return this.mq.xlPlus;
    },
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    /**
     * Checks the current MediaQuery and returns a boolean
     * Handy for v-if'ing html elements and not having
     * to rely on the bootstrap classes
     *
     * @param breakpoint
     * @returns {boolean}
     */
    currentlyOn(breakpoint) {
      switch (breakpoint) {
        case 'sm':
          return this.sm;
        case 'md':
          return this.md;
        case 'lg':
          return this.lg;
        case 'xl':
          return this.xl;
        case 'sm+':
          return this.smPlus;
        case 'md+':
          return this.mdPlus;
        case 'lg+':
          return this.lgPlus;
        case 'xl+':
          return this.xlPlus;
      }
    }
  },

  watch: {
    //
  }
};
</script>
