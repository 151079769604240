// initial state
// shape: [{ id, quantity }]
const state = {
  statuses: [
    { value: '1', text: 'Blocker' },
    { value: '2', text: 'Critical' },
    { value: '3', text: 'Major' },
    { value: '4', text: 'Minor' },
    { value: '5', text: 'Trivial' }
  ]
};

// getters
const getters = {
  // dummy_getter(state, getters, rootState) {
  //
  // }
};

// actions
const actions = {
  // dummy_action ({state, commit, dispatch, getters, rootState}, payload) {
  //     //
  // }
};

// mutations
const mutations = {
  // dummy_mutation (state, id) {
  //     state.id = id;
  // },
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
