<template>
  <base-dosage-input
      class="manual-dosing-administration-input w-100"
      :disabled="isDisabled"
      autocomplete="off"
      ref="entry"
      @focus="focus($event)"
      @blur="updateValue"
      v-bind:id="'md-' + id"
      v-bind:name="'md-'+(time+date).replace(/[:-]/g,'')"
      type="text"
      v-model="value"
      @keyup_up="navigate(-1,0, $event, 'keyup_up')"
      @keyup_down="navigate(1,0, $event, 'keyup_down')"
      @keyup_left="navigate(0,-1, $event, 'keyup_left')"
      @keyup_right="navigate(0,1, $event, 'keyup_right')"
      @keydown="userHasBeenActive = true"
      @keydown_tab="navigate(0,1,$event, 'keydown_tab')"
      :focus="active"
  ></base-dosage-input>
</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import Utility from '@/vue/utility/utility';
import Times from '@/vue/utility/times';
import moment from 'moment-mini';
import BaseDosageInput from '@/vue/components/status/dosage-instructions/components/BaseDosageInput';

export default {
  emits: ['update_selected_field','navigate','input', 'keyup_up', 'keyup_down', 'keyup_left', 'keyup_right', 'keydown_tab'],
  components: {BaseDosageInput},

  props: {
    "read_only": {default: false},
    "values": {required: true},
    "time": {required: true},
    "date": {required: true},
    "active": {required: true, default: false},
    "is_disabled": {required: false, default: null},
    "dosage_instruction": {required: true},
  },

  mixins: [BaseComponentsMixin],

  data: function () {
    return {
      userHasBeenActive: false,
    };
  },

  computed: {
    id() {
      return (this.time + this.date).replace(/[:-]/g, '');
    },
    isDisabled() {
      if (this.read_only || this.is_disabled) {
        return true;
      }

      const inputDateTime = moment(this.date + ' ' + Times.decodeTime(this.time), 'YYYY-MM-DD HH:mm');
      const start_datetime = moment(this.dosage_instruction.start_moment_datetime);
      return start_datetime > inputDateTime;
    },
    value: {
      get() {
        if (this.isDisabled) {
          return '';
        }

        const filterOnDate = Utility.find_models_by_property(this.values, 'date', this.date);
        if (!filterOnDate) {
          return 0;
        }

        const valueEntry = Utility.find_model_by_property(filterOnDate, 'time', this.time);
        if (!valueEntry) {
          return 0;
        }

        return valueEntry.value;
      },
      set(value) {
        this.updateValue(value);
      }
    }
  },
  methods: {
    focus: function () {
      this.$emit('update_selected_field', {time: this.time, date: this.date});
    },
    navigate: function (offsetY, offsetX, event, keyType) {
      this.$emit(keyType, event);
    },
    updateValue: function (value) {
      // window.medimo?.debugPrescribe.logInput(`Handmatig: ${this.date} om ${this.time} naar ${value} (debug 22)`); // no window.medimo in Vue test
      this.$emit('input', {time: this.time, date: this.date, value: value});
    }
  },
  watch: {
    isDisabled() {
      this.value = '0';
    }
  }
};
</script>
