<template>
  <div class="reading-tr scrollable-data-table-row ref-unit">
    <div class="d-inline-block cell" style="width:45%;">
      {{ referentie }}
    </div><div class="d-inline-block cell" style="width:55%;">
      {{ eenheid }}
    </div>
  </div>
</template>

<script>

export default {
  emits: ['updated','selected'],
  components: {
  },

  props: [
    'bepaling',
    'readings',
    'dates',
    'patient',
  ],

  mixins: [],

  data: function () {
    return {
    };
  },

  created() {},

  mounted() {
    this.$emit('updated');
  },

  computed: {
    firstReadingWithReference () {
      for (const reading in this.readings) {
        if (this.readings[reading].ondergrens || this.readings[reading].bovengrens) {
          return this.readings[reading];
        }
      }
    },
    firstReadingWithUnit () {
      for (const reading in this.readings) {
        if (this.readings[reading].eenheid) {
          return this.readings[reading];
        }
      }
    },
    referentie() {
      if (!this.firstReadingWithReference) {
        return '-';
      }
      return this.firstReadingWithReference.ondergrens + this.parsedStreepje + this.firstReadingWithReference.bovengrens;
    },
    parsedStreepje() {
      if (this.firstReadingWithReference.ondergrens && this.firstReadingWithReference.bovengrens) {
        return ' - ';
      } else {
        return '';
      }
    },
    eenheid() {
      if (!this.firstReadingWithUnit) {
        return '-';
      }
      return this.firstReadingWithUnit.eenheid;
    },
  },

  methods: {
    uitslagOnDate(date) {
      if (typeof this.readings[date] === 'undefined') {
        return '-';
      }
      return this.readings[date].uitslag;
    },

    showEditReadingModal(date) {
      if (typeof this.readings[date] === 'undefined') {
        return;
      }
      const model = this.readings[date];
      this.$emit('selected', model);
    }
  },

  watch: {
    readings() {
      this.$emit('updated');
    }
  }
};
</script>
