<template>
    <patient-info-medi-modal
        :id="'episoded-data-table-modal'"
        :patient_id="patient.id"
        :loading="loading"
        title="Episodes"
        size="large"
        :min_height="0"
        @closed="close"
    >
        <medimo-external-data-table
            :name="'patient-episodes-' + patient_id"
            :store_name="store_name"
            :number_of_rows="models.length"
            :minimum_height="true"
            :refresh_table="refresh_table"
            :search_on_load="false"
            :pagination_enabled="false"

            privacy_filter_property="patient_id"
            :privacy_filter_value="patient_id"
        >
            <medimo-thead>
                <medimo-tr>
                    <medimo-th column_classes="col col-sm-1" :store_name="store_name" sort_column_1="code">Code</medimo-th>
                    <medimo-th column_classes="col col-sm-7" :store_name="store_name" sort_column_1="title">Titel</medimo-th>
                    <medimo-th column_classes="col col-sm-2" :store_name="store_name" sort_column_1="episodeStart">Start</medimo-th>
                    <medimo-th column_classes="col col-sm-2" :store_name="store_name" sort_column_1="episodeEnd">Einde</medimo-th>
                </medimo-tr>
            </medimo-thead>
            <medimo-tbody>
                <template v-for="model in models">
                    <episodes-data-table-row
                        :episode="model"
                        :patient="patient"
                        @selected="openEditEpisodeModal(model)"
                    ></episodes-data-table-row>
                </template>
            </medimo-tbody>
        </medimo-external-data-table>

        <template v-slot:action-buttons>
          <medimo-button @click="openFindZindexEpisodeModal" class="mr-2">
            Nieuwe toevoegen
          </medimo-button>
          <medimo-button class="mr-md-auto" type="outline-primary" @click="openHistoryModal">
            Historie
          </medimo-button>
          <medimo-button @click="close">
            Sluiten
          </medimo-button>
        </template>

        <episode-history-modal
            :patient_id="patient.id"
            v-if="show_history_modal"
            @closed="show_history_modal = false"
        ></episode-history-modal>

        <add-episode-modal
            :patient="patient"
            v-if="show_add_episode_modal"
            :zindex_episode="selected_zindex_episode"
            @closed="closeAddEpisodeModal"
        ></add-episode-modal>

        <edit-episode-modal
            v-if="show_edit_episode_modal"
            :episode="selected_episode"
            :loading="loading"
            :patient="patient"
            @closed="closeEditEpisodeModal"
        ></edit-episode-modal>

        <find-zindex-episode-data-table-modal
            v-if="show_find_zindex_modal"
            :patient="patient"
            @zindexEpisodeSelected="zindexEpisodeSelected"
            @closed="show_find_zindex_modal = false"
        >
        </find-zindex-episode-data-table-modal>

    </patient-info-medi-modal>
</template>

<script>
import Utility from '@/vue/utility/utility';
import EpisodeHistoryModal from './EpisodeHistoryModal';
import EpisodesDataTableRow from './EpisodesDataTableRow';
import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import PatientInfoMediModal from '@/vue/components/modals/PatientInfoMediModal';
import EditEpisodeModal from './EditEpisodeModal';
import AddEpisodeModal from './AddEpisodeModal';
import FindZindexEpisodeDataTableModal from './FindZindexEpisodeDataTableModal';

export default {
  components: {
    FindZindexEpisodeDataTableModal,
    AddEpisodeModal,
    EditEpisodeModal,
    EpisodeHistoryModal,
    EpisodesDataTableRow,
    PatientInfoMediModal
  },
  emits: ['closed'],
  props: {
    'patient': {required: true},
  },

  mixins: [
    BaseComponentsMixin
  ],

  data: function () {
    return {
      store_name: 'episodes',
      loading: false,
      show_history_modal: false,
      show_add_episode_modal: false,
      show_edit_episode_modal: false,
      show_find_zindex_modal: false,
      refresh_table: 0,
      selected_episode: false,
      selected_zindex_episode: false,
    };
  },

  computed: {
    patient_id() {
      return this.patient.id;
    },
    // This is the computed property that would return the models / data that would generate the rows
    // usually an array / object of models from the Vuex store
    models() {
      return this.$store.getters[this.store_name + '/search_results'].filter((model) => {
        return model.patient_id === this.patient_id;
      });
    }
  },

  created() {
    const data = Utility.deep_clone(this.$store.state.episodes.data);
    this.$store.commit(this.store_name + '/set_search_results', data.reverse());
  },

  mounted() {
    // When ready, we set the search endpoint
    this.$store.commit(this.store_name + '/set_search_endpoint', '/api/v1/patients/' + this.patient.id + '/episodes');
  },

  methods: {
    close() {
      this.$emit('closed');
    },
    openEditEpisodeModal(episode) {
      this.selected_episode = episode;
      this.show_edit_episode_modal = true;
    },
    closeEditEpisodeModal(refresh) {
      this.show_edit_episode_modal = false;
      if (refresh) {
        this.refresh_table++;
      }
    },
    openFindZindexEpisodeModal() {
      this.show_find_zindex_modal = true;
    },
    zindexEpisodeSelected(model) {
      this.selected_zindex_episode = model;
      this.show_find_zindex_modal = false;      // close find modal
      this.show_add_episode_modal = true;     // open add modal
    },
    openAddEpisodeModal() {
      this.show_add_episode_modal = true;
    },
    closeAddEpisodeModal(refresh) {
      this.show_add_episode_modal = false;
      if (refresh) {
        this.refresh_table++;
      }
    },
    openHistoryModal() {
      this.show_history_modal = true;
    }
  },

  watch: {
    //
  }
};
</script>
