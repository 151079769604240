import {AbstractMedication} from "@/controllers/AbstractMedication";
import {VariableDosingRegimen} from "@/domain/models/VariableDosingRegimen";
import {store} from "@/vue/store";
import {AbstractMedicationData} from "@/controllers/AbstractMedicationData";

export class VariableDosingRegimenController extends AbstractMedication<VariableDosingRegimen> {
  protected storeName = 'mp9/variable_dosing_regimens';
  protected apiEndpoint = 'variable-dosing-regimen';

  public async createDefault(patientId: number, medicationAgreementId: number): Promise<void> {
    const response = await store.dispatch('api/getEndpoint', {
      endpoint: '/api/v1/mp9/patient/' + patientId + '/' + this.apiEndpoint + '/create-default/' + medicationAgreementId,
    });

    const drug = response.data['drug'];
    store.commit('drugs/add_to_data', drug, {root: true});

    this.setConceptOnStore(response.data['variableDosingRegimen'], drug);
  }

  public async create(patientId: number, medicationAgreementId: number): Promise<void> {
    const response = await store.dispatch('api/postEndpoint', {
      endpoint: '/api/v1/mp9/patient/' + patientId + '/' + this.apiEndpoint + '/create/' + medicationAgreementId,
    });

    const drug = response.data['drug'];

    this.pushToStore(response.data['variableDosingRegimen'], drug);
  }

  protected fromJson(data: unknown): VariableDosingRegimen {
    return VariableDosingRegimen.fromJson(data);
  }
}

export type VariableDosingRegimenData = AbstractMedicationData<VariableDosingRegimen>
