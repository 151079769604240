<template>
    <medimo-validated-date-time-picker
            v-bind="$attrs"

            :only_date="true"
            :close_on_date_change="true"
            :datetime_format="datetime_format"
    ></medimo-validated-date-time-picker>
</template>

<script>

import MedimoValidatedDateTimePicker from './MedimoValidatedDateTimePicker';

export default {
  inheritAttrs: false,
  components: {
    MedimoValidatedDateTimePicker,
  },
  props: {
    datetime_format: {default: "DD-MM-YYYY"}
  },
};
</script>
