import {store} from '@/vue/store';
import {UnprocessedMedicationAgreement} from "@/domain/models/UnprocessedMedicationAgreement";
import {AbstractMedicationStore} from "@/controllers/AbstractMedicationStore";

export class UnprocessedMedicationAgreementController extends AbstractMedicationStore {
  protected apiEndpoint = 'unprocessed-medication-agreement';

  public async list(patientId: number): Promise<UnprocessedMedicationAgreement[]> {
    const response = await store.dispatch('api/getEndpoint', {
      endpoint: '/api/v1/mp9/patient/' + patientId + '/' + this.apiEndpoint,
    });

    return UnprocessedMedicationAgreement.multipleFromJson(response.data);
  }

  public async confirm(patientId: number, medicationAgreementId: number): Promise<any> {
    const response = await store.dispatch('api/postEndpoint', {
      endpoint: '/api/v1/mp9/patient/' + patientId + '/' + this.apiEndpoint + '/' + medicationAgreementId,
    });

    return response.data.data;
  }

  public async correct(patientId: number, medicationAgreementId: number): Promise<void> {
    const response = await store.dispatch('api/postEndpoint', {
      endpoint: '/api/v1/mp9/patient/' + patientId + '/' + this.apiEndpoint + '/' + medicationAgreementId + '/correct',
    });

    const pharmaceuticalTreatment = response.data.data;

    this.storeMedicationAgreements(pharmaceuticalTreatment);
    this.storeMedicationUses(pharmaceuticalTreatment);
    this.storeAdministrationAgreements(pharmaceuticalTreatment);
    this.storeVariableDosingRegimens(pharmaceuticalTreatment);
    this.storeDrug(pharmaceuticalTreatment);
  }
}
