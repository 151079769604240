<template>
    <div class="medication-treatment row no-gutters tr">
        <medication-agreement-row
                v-for="medication_agreement in medication_agreements"
                :key="medication_agreement.id"
                :medication_treatment="medication_treatment"
                :medication_agreement="medication_agreement"
                :all_dosage_instruction_ids="all_dosage_instruction_ids"
                :has_future_instructions="has_future_instructions"
                :read_only="read_only"
                :show_all="show_all"
                @toggle_show_all="toggleShowAll"
                :show_ingang_per="show_ingang_per"
        ></medication-agreement-row>
    </div>
</template>

<script>

import MedicationAgreementRow from '@/vue/components/status/MedicationAgreementRow';
import moment from 'moment-mini';
export default {
  components: {
    MedicationAgreementRow
  },

  props: {
    'medication_treatment': {},
    'read_only': {required: false, default: null},
    'show_all_page': {default: true},
    'show_ingang_per': {default: true},
  },

  mixins: [],

  data: function () {
    return {
      show_all: true,
      all_dosage_instruction_ids: [],
      has_future_instructions: false,
    };
  },

  computed: {
    medication_agreements() {
      return this.$store.getters['medication_agreements/for_db_medication_treatment_id'](this.medication_treatment.id);
    },
    all_dosing_instructions() {
      return this.$store.getters['dosage_instructions/for_db_medication_treatment_id'](this.medication_treatment.id);
    }
  },

  created() {
  },

  mounted() {
    this.setDosageInstructionIds();

    setTimeout(() => {
      if (this.medication_agreements.length === 0) {
        // Dit moet niet kunnen, betekent foutieve database data
        this.$root.sendSentry('MedicationTreatment zonder agreements gevonden voor MedicationTreatment met id: ' + this.medication_treatment.id);
      }
    }, 500);
  },

  methods: {
    toggleShowAll() {
      this.show_all = !this.show_all;
    },
    setDosageInstructionIds() {
      const ids = [];

      this.all_dosing_instructions.forEach((dosage_instruction) => {

        if (moment(dosage_instruction.start_moment_datetime, 'YYYY-MM-DD HH:mm:ss').isAfter(moment())) {
          this.has_future_instructions = true;
        }

        ids.push(dosage_instruction.id);
      });

      this.all_dosage_instruction_ids = ids;
    }
  },

  watch: {
    // When this changes, it overrides the local value
    show_all_page(value) {
      this.show_all = value;
    },
    all_dosing_instructions: {
      deep: true,
      handler() {
        this.setDosageInstructionIds();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
    //
</style>
