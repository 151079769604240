import BaseModule from '@/vue/store/modules/base_module';

const state = {
  ...BaseModule.state,
  quick_filters: {
    'Actueel': true,
    'Alles openstaand': false,
    'Uitgegeven': false,
  },
  toggleQuickFilters: true,
  sort_columns: [{column_name: 'intended_datetime', direction: 'DESC'}],
};

// getters
const getters = {
  ...BaseModule.getters,
};

// actions
const actions = {
  ...BaseModule.actions,
  fetch_single({state, commit, dispatch, getters, rootState}, payload) {
    return dispatch('api/getEndpoint', {
      endpoint: '/api/v1/patients/' + payload.patient_id + '/dispenses/' + payload.dispense_id,
    }, {root: true}).then(response => {
      return response.data.data;
    });
  },
  store({state, commit, dispatch, getters, rootState}, payload) {
    return dispatch('api/postEndpoint', {
      endpoint: '/api/v1/patients/' + payload.patient_id + '/dispenses/',
      data: payload.data,
    }, {root: true}).then(response => {});
  },
  undo({state, commit, dispatch, getters, rootState}, payload) {
    return dispatch('api/postEndpoint', {
      endpoint: '/api/v1/patients/' + payload.patient_id + '/dispenses/delete',
      data: payload.data,
    }, {root: true}).then(response => {});
  },
};

// mutations
const mutations = {
  ...BaseModule.mutations,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
