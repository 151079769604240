<template>
  <component :is="action_type"
             class="base-action-modal"
             :action="action"
             :patient="patient"
             v-if="!action.redirect"

             :refresh_patient_medication_data="refresh_patient_medication_data"

             @closed="closed"
             @processed="processed"
  ></component>
</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';

/**
 * Dit zijn alle ActionDialogs waar de modal in kan "transformeren"
 * Deze corresponderen met de action_type die door de ActionResource wordt terug gestuurd.
 * Als dit een custom action type is, moet je die hieronder toevoegen
 */
import EditActionModal from './EditActionModal';
import OpnameContraIndicaties from '../action-dialogs/OpnameContraIndicaties';
import OpnameIntoleranties from '../action-dialogs/OpnameIntoleranties';
import IntakeMedicatieImport from '../action-dialogs/IntakeMedicatieImport';
import Covid19Vaccination from './vaccination/Covid19Vaccination';
import Covid19ReVaccination from './vaccination/Covid19ReVaccination';
import GenericVaccination from './vaccination/GenericVaccination';
import VerifyHomeMedication from '../action-dialogs/VerifyHomeMedication';
import OpiumPharmacyReturn from '../action-dialogs/OpiumPharmacyReturn';
import NavigatesProgrammatically from '@/vue/mixins/navigation/NavigatesProgrammatically';

export default {
  components: {
    EditActionModal,
    OpnameContraIndicaties,
    OpnameIntoleranties,
    IntakeMedicatieImport,
    Covid19Vaccination,
    Covid19ReVaccination,
    GenericVaccination,
    VerifyHomeMedication,
    OpiumPharmacyReturn,
  },
  emits: ['processed','closed'],

  props: {
    'action': {required: true},
    'patient': {required: true},
    'title': {},
    'size': {},
    'loading': {},

    // Deze waarde wordt meegegeven aan de ActionModals
    'refresh_patient_medication_data': {required: false, default: true},
  },

  mixins: [
    BaseComponentsMixin,
    NavigatesProgrammatically
  ],

  data: function () {
    return {
      //
    };
  },

  computed: {
    action_type() {
      if (this.action.isActive) {
        return this.action.actionType;
      }
      return 'edit-action-modal';
    },
    to() {
      // Deze gebruiken we voor de auto-redirect
      return this.action.redirect;
    }
  },

  created() {
    // Als er een redirect URL wordt meegestuurd dan willen we hier automatisch naartoe navigeren
    if (this.action.redirect) {
      this.navigateProgrammatically();
    }
  },

  mounted() {
    //
  },

  methods: {
    processed() {
      this.$emit('processed');
      this.suppress_action_dialog();
    },
    closed() {
      this.$emit('closed');
      this.suppress_action_dialog();
    },
    /**
     * Updates the current_user's array so the dialog won't bother him/her again
     */
    suppress_action_dialog() {
      this.$store.commit('current_user/set_processed_action_dialog', this.action);
    },
  },

  watch: {
    action_type() {
      // Zonder deze watcher wordt hij vervangen voordat hij upstream geprocessed is.
      // We kunnen hier veilig de boel verwerken omdat de enige reden dat het action_type veranderd
      // is dat hij door de backend verwerkt is van actief naar inactief, of vice versa
      this.processed();
    }
  }
};
</script>
