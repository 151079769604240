<template>

  <div :class="'reading-tr scrollable-data-table-row ' + (render_filler ? 'd-flex' : '')">
    <div v-on:click="showEditReadingModal(date)" class="d-inline-block cell" v-for="date in dates" :key="date" :style="'width:' + cell_width + 'px;'">
      {{ uitslagOnDate(date) }}
      <span v-if="normaalwaardeindicatieOnDate(date)" class="badge badge-sm badge-warning">{{ normaalwaardeindicatieOnDate(date) }}</span>
      <span v-if="opmerkingOnDate(date)" class="badge badge-sm badge-medimo-50" :title="opmerkingOnDate(date)">i</span>
    </div>
    <div v-if="render_filler" class="cell flex-grow-1"></div>

  </div>
</template>

<script>

export default {
  emits: ['updated','selected'],
  components: {
  },

  props: [
    'bepaling',
    'readings',
    'dates',
    'patient',
    'cell_width',
    'render_filler'
  ],

  mixins: [],

  data: function () {
    return {
    };
  },

  created() {},

  mounted() {
    this.$emit('updated');
  },

  computed: {
  },

  methods: {
    uitslagOnDate(date) {
      if (typeof this.readings[date] === 'undefined' || this.readings[date].uitslag === '') {
        return '-';
      }
      return this.readings[date].uitslag;
    },
    normaalwaardeindicatieOnDate(date) {
      if (typeof this.readings[date] === 'undefined') {
        return false;
      }
      return this.readings[date].normaalwaardeindicatie;
    },
    opmerkingOnDate(date) {
      if (typeof this.readings[date] === 'undefined') {
        return false;
      }
      return this.readings[date].opmerking;
    },
    showEditReadingModal(date) {
      if (typeof this.readings[date] === 'undefined') {
        return;
      }
      const model = this.readings[date];
      this.$emit('selected', model);
    }
  },

  watch: {
    readings() {
      this.$emit('updated');
    }
  }
};
</script>
