import Utility from '@/vue/utility/utility';
import BaseModule from '@/vue/store/modules/base_module';

// initial state
// shape: [{ id, quantity }]
const state = {
  ...BaseModule.state,

  // Generated met https://www.json-generator.com/#
  // Super handig :)
  data: [
    {
      "id": "5e9838df463b63572dc567f2",
      "index": 0,
      "guid": "34cd845d-d83f-4cd5-bc52-6a73a3140297",
      "isActive": true,
      "balance": "$2,506.27",
      "age": 30,
      "eyeColor": "brown",
      "name": "Kris Sargent",
      "gender": "female",
      "company": "GYNKO",
      "email": "krissargent@gynko.com",
      "phone": "+1 (894) 486-3733",
      "address": "811 Sunnyside Avenue, Jenkinsville, Alabama, 4117",
      "about": "Ullamco enim commodo adipisicing consequat. Fugiat irure ullamco ullamco est labore fugiat duis reprehenderit aliquip Lorem laboris est cillum in. Duis do est exercitation elit duis elit cupidatat proident occaecat minim laboris. Culpa sint non minim dolor nostrud.\r\n",
      "registered": "2019-03-29T10:16:24 -01:00",
      "tags": [
        "velit",
        "minim"
      ],
      "colleagues": [
        {
          "id": 0,
          "name": "Hollie Mclean"
        },
        {
          "id": 1,
          "name": "Sondra Blackburn"
        },
        {
          "id": 2,
          "name": "Rush Richardson"
        }
      ]
    },
    {
      "id": "5e9838dff3b3cf7a3c132877",
      "index": 1,
      "guid": "5d3152cf-05d7-40d5-9568-2cb735b59c9d",
      "isActive": true,
      "balance": "$1,590.24",
      "age": 28,
      "eyeColor": "brown",
      "name": "Ethel Stafford",
      "gender": "female",
      "company": "MICROLUXE",
      "email": "ethelstafford@microluxe.com",
      "phone": "+1 (994) 426-3012",
      "address": "725 Shale Street, Riegelwood, Puerto Rico, 8942",
      "about": "Reprehenderit incididunt aliquip sit ad exercitation duis dolor cillum esse voluptate excepteur dolore. Consectetur non irure sit tempor irure Lorem reprehenderit. Excepteur dolore dolor est magna nostrud excepteur adipisicing eu consectetur ad Lorem. Sit sint deserunt fugiat officia velit id fugiat elit sit excepteur fugiat tempor.\r\n",
      "registered": "2015-10-25T09:42:10 -01:00",
      "tags": [
        "ea",
        "elit"
      ],
      "colleagues": [
        {
          "id": 0,
          "name": "Russell Mcdaniel"
        },
        {
          "id": 1,
          "name": "Nanette Cooley"
        },
        {
          "id": 2,
          "name": "Frankie Valenzuela"
        }
      ]
    },
    {
      "id": "5e9838dfc7f6393ef7889baa",
      "index": 2,
      "guid": "d330effe-50d5-45cd-88d3-76e0ab4d6526",
      "isActive": true,
      "balance": "$3,498.30",
      "age": 24,
      "eyeColor": "blue",
      "name": "Bernadette Pollard",
      "gender": "female",
      "company": "TELPOD",
      "email": "bernadettepollard@telpod.com",
      "phone": "+1 (912) 500-2947",
      "address": "154 Tapscott Avenue, Faxon, Northern Mariana Islands, 9749",
      "about": "Sunt pariatur labore cillum labore sint. Consequat magna qui amet non quis consectetur aliquip aliquip adipisicing non et aliqua adipisicing consectetur. Amet qui et qui exercitation eiusmod do incididunt ad ipsum elit officia. Occaecat ad pariatur occaecat labore.\r\n",
      "registered": "2018-07-30T02:29:53 -02:00",
      "tags": [
        "excepteur",
        "officia"
      ],
      "colleagues": [
        {
          "id": 0,
          "name": "Angelica Conley"
        },
        {
          "id": 1,
          "name": "Cameron Booth"
        },
        {
          "id": 2,
          "name": "Nicholson Morrison"
        }
      ]
    },
    {
      "id": "5e9838df0070c242dd8bb005",
      "index": 3,
      "guid": "5e472d31-2201-4001-a355-65e1f66f6afa",
      "isActive": true,
      "balance": "$3,753.47",
      "age": 26,
      "eyeColor": "brown",
      "name": "Adrienne Randall",
      "gender": "female",
      "company": "HAWKSTER",
      "email": "adriennerandall@hawkster.com",
      "phone": "+1 (938) 445-3467",
      "address": "178 Erskine Loop, Wawona, Illinois, 4463",
      "about": "Reprehenderit officia nostrud amet reprehenderit sint labore sint qui mollit exercitation laboris elit. Elit esse velit dolor enim in enim consequat elit anim dolore exercitation. Elit occaecat sint in fugiat veniam aute culpa consequat exercitation deserunt minim.\r\n",
      "registered": "2017-05-05T01:56:57 -02:00",
      "tags": [
        "consequat",
        "irure"
      ],
      "colleagues": [
        {
          "id": 0,
          "name": "Lucy Cruz"
        },
        {
          "id": 1,
          "name": "Gill Cardenas"
        }
      ]
    },
    {
      "id": "5e9838dfb900c1cffa029438",
      "index": 4,
      "guid": "cb35011a-f065-4281-9147-73f884968ace",
      "isActive": true,
      "balance": "$3,491.22",
      "age": 33,
      "eyeColor": "green",
      "name": "Socorro Hess",
      "gender": "female",
      "company": "PHOLIO",
      "email": "socorrohess@pholio.com",
      "phone": "+1 (841) 584-3187",
      "address": "783 Moffat Street, Corinne, Maryland, 8753",
      "about": "Velit consequat aliquip minim cupidatat excepteur exercitation mollit nulla. Qui id veniam dolor esse ipsum ullamco dolor dolor eiusmod labore minim Lorem deserunt sint. Enim mollit culpa magna laboris duis reprehenderit deserunt.\r\n",
      "registered": "2017-04-18T03:58:04 -02:00",
      "tags": [
        "reprehenderit",
        "consequat"
      ],
      "colleagues": [
        {
          "id": 0,
          "name": "Murphy Lopez"
        }
      ]
    },
    {
      "id": "5e9838df2802066443c6937e",
      "index": 5,
      "guid": "6978db1f-b159-4664-9a39-c30ba80fda13",
      "isActive": false,
      "balance": "$1,265.10",
      "age": 40,
      "eyeColor": "brown",
      "name": "Bobbi Hoffman",
      "gender": "female",
      "company": "ZOMBOID",
      "email": "bobbihoffman@zomboid.com",
      "phone": "+1 (829) 445-2002",
      "address": "438 Guider Avenue, Cherokee, Oklahoma, 2734",
      "about": "Excepteur qui dolor labore consequat anim pariatur. Aliquip minim minim cupidatat id qui et consectetur aute do minim. Esse sint non aute occaecat sunt veniam occaecat aute laboris laborum. Magna deserunt est commodo duis commodo. Mollit ad eiusmod nisi tempor officia mollit excepteur exercitation id proident. Enim labore laborum minim sint do non nulla nostrud laboris eiusmod minim. Duis voluptate Lorem consectetur cupidatat esse ea tempor nisi ea.\r\n",
      "registered": "2017-06-22T01:06:34 -02:00",
      "tags": [
        "consectetur",
        "in"
      ],
      "colleagues": [
        {
          "id": 0,
          "name": "Wilson Chaney"
        },
        {
          "id": 1,
          "name": "Alissa Dickson"
        },
        {
          "id": 2,
          "name": "Short Ramos"
        }
      ]
    },
    {
      "id": "5e9838dfd458cd340e99059b",
      "index": 6,
      "guid": "59e83c6c-99a2-421d-9fad-b52984485d0a",
      "isActive": true,
      "balance": "$3,675.36",
      "age": 33,
      "eyeColor": "blue",
      "name": "Mia Heath",
      "gender": "female",
      "company": "SPLINX",
      "email": "miaheath@splinx.com",
      "phone": "+1 (847) 479-3234",
      "address": "786 Willmohr Street, Skyland, North Carolina, 5983",
      "about": "Enim velit ea mollit qui culpa consectetur laborum tempor. Adipisicing mollit cillum et non occaecat. Culpa aute cillum eiusmod laborum eiusmod. Et exercitation ad qui labore sint culpa minim ut aute ad irure non fugiat. Magna consectetur anim est nisi do qui do sit. Non dolore enim nulla ullamco aute Lorem qui non eu in pariatur occaecat.\r\n",
      "registered": "2015-07-20T06:55:13 -02:00",
      "tags": [
        "sit",
        "labore"
      ],
      "colleagues": [
        {
          "id": 0,
          "name": "Courtney Patel"
        },
        {
          "id": 1,
          "name": "Zimmerman Flowers"
        }
      ]
    },
    {
      "id": "5e9838dffaa397c03a8460e3",
      "index": 7,
      "guid": "083c5d9a-cfc0-4eac-9669-6b39c3170af1",
      "isActive": false,
      "balance": "$3,621.62",
      "age": 39,
      "eyeColor": "brown",
      "name": "Fern Holcomb",
      "gender": "female",
      "company": "SUNCLIPSE",
      "email": "fernholcomb@sunclipse.com",
      "phone": "+1 (909) 550-3815",
      "address": "858 Ridgewood Avenue, Bowie, Louisiana, 213",
      "about": "Occaecat anim elit sunt dolore irure ea tempor anim aliqua laboris. Excepteur aliquip occaecat officia aliquip sunt voluptate duis. Consequat sit anim anim labore excepteur nisi ipsum fugiat nisi. Tempor exercitation irure eu proident anim exercitation. Officia laborum pariatur officia dolore quis mollit. Voluptate ad reprehenderit in do dolor. Labore commodo pariatur elit anim ut laborum ipsum anim culpa aliqua.\r\n",
      "registered": "2019-11-21T12:19:16 -01:00",
      "tags": [
        "amet",
        "ea"
      ],
      "colleagues": [
        {
          "id": 0,
          "name": "Julianne Figueroa"
        }
      ]
    },
    {
      "id": "5e9838df7008d6ed368e45b9",
      "index": 8,
      "guid": "6f79cace-7a9b-47ab-ae40-ba56cbb49ac1",
      "isActive": true,
      "balance": "$1,311.42",
      "age": 29,
      "eyeColor": "blue",
      "name": "Mckay Clay",
      "gender": "male",
      "company": "XYMONK",
      "email": "mckayclay@xymonk.com",
      "phone": "+1 (930) 465-2608",
      "address": "240 Clarkson Avenue, Blende, Montana, 1369",
      "about": "Ullamco commodo fugiat cupidatat nulla incididunt aliquip sit aliqua. Incididunt laborum in nulla enim excepteur quis magna elit nulla amet. Cillum nostrud ullamco veniam commodo excepteur pariatur anim deserunt officia. Sint labore commodo commodo voluptate eu mollit in nostrud labore culpa voluptate. Id Lorem nisi adipisicing et tempor occaecat.\r\n",
      "registered": "2014-07-23T01:03:57 -02:00",
      "tags": [
        "nostrud",
        "do"
      ],
      "colleagues": [
        {
          "id": 0,
          "name": "Felicia Knapp"
        },
        {
          "id": 1,
          "name": "Jeanne Pruitt"
        },
        {
          "id": 2,
          "name": "Wilkinson Moon"
        }
      ]
    },
    {
      "id": "5e9838df04ff08c34759b456",
      "index": 9,
      "guid": "251386bc-9cf5-4923-9a5c-e0c357eaeedb",
      "isActive": false,
      "balance": "$3,334.54",
      "age": 39,
      "eyeColor": "green",
      "name": "Hunt Franklin",
      "gender": "male",
      "company": "BUZZOPIA",
      "email": "huntfranklin@buzzopia.com",
      "phone": "+1 (975) 406-3067",
      "address": "761 Amherst Street, Wells, Nevada, 1747",
      "about": "Aliqua incididunt esse nulla est laboris nisi laboris id in esse. Ut veniam dolore culpa nisi amet cupidatat. Laborum excepteur officia commodo in id deserunt duis incididunt pariatur. Tempor veniam in ut pariatur. Laboris sint sunt tempor id. Anim non occaecat sunt cupidatat.\r\n",
      "registered": "2017-08-22T03:46:35 -02:00",
      "tags": [
        "non",
        "sunt"
      ],
      "colleagues": [
        {
          "id": 0,
          "name": "Torres Navarro"
        },
        {
          "id": 1,
          "name": "Hattie Vang"
        },
        {
          "id": 2,
          "name": "Freda Gould"
        }
      ]
    }
  ],
};

// getters
const getters = {
  ...BaseModule.getters,
};

// actions
const actions = {
  ...BaseModule.actions,
  // dummy_action ({state, commit, dispatch, getters, rootState}, payload) {
  //     //
  // }
};

// mutations
const mutations = {
  ...BaseModule.mutations,
  // dummy_mutation (state, id) {
  //     state.id = id;
  // },
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
