const change_events = {
  CHANGE_INSTRUCTION: 2,
  CHANGE_INSTRUCTION_TO_MANUALDOSING_SCHEMA: 3,

  CORRECT_INSTRUCTION: 11,
  CANCEL_INSTRUCTION: 4,
  CORRECT_INSTRUCTION_TO_MANUAL_DOSING_SCHEMA: 5,

  MANUALDOSING_SCHEMA_CHANGED: 6,
  ADDITION: 13,
  STOP_INSTRUCTION: 14,
  
  CANCEL_STOP: 15,
  RESTART: 16,
  CORRECT_STOP: 17,
};

const start_events = {
  START_INSTRUCTION: 8,
  START_MANUAL_DOSING_SCHEMA: 9,
};

export default {
  // namespaced: true,
  change_events: change_events,
  start_events: start_events,
};
