<template>
  <medimo-form-row class="schema-form-row" margin_classes="mb-1" style="position:relative;">
    <medimo-form-column v-if="use_fixed_date" column_classes="col-3 align-items-center" :id="'date-'+dateLabel">
      <medimo-date-time-picker class="date-label"
                               :show_icon="mdPlus"
                               datetime_format="DD-MM-YYYY"
                               :disabled="true"
                               :modelValue="dateLabel"
                               :showDayOfWeek="true"
      ></medimo-date-time-picker>
    </medimo-form-column>
    <medimo-form-column v-if="!use_fixed_date" column_classes="col-3 align-items-center" :id="'date-'+dateLabel">
      {{ dateLabel }}
    </medimo-form-column>
    <medimo-form-column class="administration-columns" :no_margin="true" column_classes="col p-0">
      <medimo-form-row class="no-gutters" margin_classes="">
        <medimo-form-column column_classes="col-2"
                            v-for="(time, index) in times" :key="time + index">
          <!--
              I'm removing the not-working "active" value, since that setup broke
              :active="times[field_with_focus.timeIndex] === time && dates[field_with_focus.dateIndex] === date"
              Replacing it with an auto-focus when it's the first input on that last row.
              Needs a better keynav solution in the future
          -->
          <administration
              :read_only="read_only"
              :active="is_last_row && index === 0"
              :time="time"
              :date="date"
              @update_selected_field="$emit('update_selected_field', $event)"
              @input="$emit('input', $event)"
              :values="values"
              @keydown_tab="onTabLastField(index, $event)"
              @keyup_down="onArrowDown($event)"
              :is_last_row="is_last_row"
              :dosage_instruction="dosage_instruction"
          >
          </administration>
        </medimo-form-column>
      </medimo-form-row>
    </medimo-form-column>
    <medimo-form-column class="remove-row-column" column_classes="col-auto align-items-center" v-if="!read_only && isLast && index !== 0">
      <a class="clickable" @click.prevent="$emit('remove_date', date)">
        <fa-icon icon="fa-solid fa-remove" title="Dag verwijderen" />
      </a>
    </medimo-form-column>
    <div v-if="!isLast" style="width:21px"></div>
    <medimo-form-column class="remove-row-column" column_classes="col-auto d-flex align-items-center" v-else-if="read_only && (times.length < 6 || lgPlus)">
      <!-- Otherwise, to maintain the design margins, we use a white one -->
      <fa-icon icon="fa-solid fa-remove" class="text-white" />
    </medimo-form-column>
  </medimo-form-row>
</template>

<script>
import moment from 'moment-mini';
import Administration from './Administration';
import MedimoFormRow from '@/vue/components/general/form/base/MedimoFormRow';
import MedimoFormColumn from '@/vue/components/general/form/base/MedimoFormColumn';
import MedimoDateTimePicker from '@/vue/components/general/form/base/MedimoDateTimePicker';
import UsesMediaQueries from '@/vue/mixins/UsesMediaQueries';

export default {
  emits: ['update_selected_field','input','navigate','remove_date','create_new_row'],
  components: {
    Administration,
    MedimoFormRow,
    MedimoFormColumn,
    MedimoDateTimePicker,
  },

  props: [
    "read_only",
    "date",
    "index",
    "dates",
    "times",
    "values",
    "field_with_focus",
    "create_template",
    "use_fixed_date",
    "is_last_row",
    "dosage_instruction",
  ],

  mixins: [
    UsesMediaQueries,
  ],

  computed: {
    isLast: function () {
      if (this.dates.length === 1) {
        return false;
      }
      return this.date === this.dates[this.dates.length - 1];
    },
    dateLabel() {
      if (this.create_template || !this.use_fixed_date) {
        const numberOfDay = moment(this.date, 'YYYY-MM-DD').startOf('day').diff(moment(this.dates[0], 'YYYY-MM-DD').startOf('day'), 'days') + 1;
        return 'Dag ' + numberOfDay;
      }
      return moment(this.date, 'YYYY-MM-DD').format("YYYY-MM-DD");
    }
  },

  methods: {
    onTabLastField(index, $event) {
      // If we press the shiftKey as well, we do not want to go forwards, but backwards. Therefore we can skip the other checks
      if ($event.shiftKey) {
        return;
      }

      // To capture a tab going OUT from the last field, we're using keydown.
      // So if there are 6 times, the index we want is 5.
      const tabbedOnLastInput = index === (this.times.length - 1);

      // Then we make sure we're on the last row as well. If both match, we
      // trigger the custom event to create a new row.
      setTimeout(() => {
        if (this.is_last_row && tabbedOnLastInput) {
          this.$emit('create_new_row');
        }
      }, 20);
    },
    onArrowDown(event) {
      if (this.is_last_row) {
        // Deze blur is hier nodig om de waarde in het schema te updaten,
        // zonder blur wordt de nieuwe rij aangemaakt zonder dat de waarde is weggeschreven via de store
        event.target.blur();
        this.$emit('create_new_row');
      }
    }
  }
};
</script>
