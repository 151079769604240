import {GeneratedHealthcareProfessionalObject} from '@/domain/models/generated/GeneratedHealthcareProfessionalObject';

export class HealthcareProfessionalObject extends GeneratedHealthcareProfessionalObject {
  public fullName() {
    if (this.text) {
      return this.text;
    }
    return this.firstName + ' ' + this.lastName;
  }
}
