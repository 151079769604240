import BaseModule from '@/vue/store/modules/base_module';
import Utility from "@/vue/utility/utility";

const state = {
  ...BaseModule.state,
  search_endpoint: '/api/v1/stock/pharmacy-return',
  quick_filters: {
    showAll: {label: 'Toon alles', value: false},
  },
  useLocalQuickFilters: true,
  default_sort_columns: [{column_name: 'date', direction: 'ASC'}],
  sort_columns: [{column_name: 'date', direction: 'ASC'}],
};

const actions = {
  ...BaseModule.actions,
  fetch({state, commit, dispatch}) {
    return dispatch('api/getEndpoint', {
      endpoint: state.search_endpoint
    }, {root: true}).then(response => {
      commit('set_data', response.data.data);
    });
  },
  save({state, commit, dispatch, getters, rootState}, payload) {
    return dispatch('api/postEndpoint', {
      endpoint: state.search_endpoint + '/' + payload.id,
      data: payload.data
    }, {root: true}).then(response => {
      commit('remove_from_data', response.data.data);
    });
  },
  saveMultiple({state, commit, dispatch, getters, rootState}, payload) {
    return dispatch('api/postEndpoint', {
      endpoint: state.search_endpoint,
      data: payload
    }, {root: true}).then(response => {
      response.data.data.forEach(data => {
        commit('remove_from_data', data);
      });
    });
  },
};
const getters = {
  ...BaseModule.getters,
  show_all_quick_filter(state, getters, rootState) {
    const user = rootState.current_user.data;

    return (data, filter_state) => {
      return data.filter((row) => {
        if (filter_state === true) {
          return true;
        }

        return row.clusterId === user.cluster_id;
      });
    };
  },
};
const mutations = {
  ...BaseModule.mutations,
  remove_from_data(state, payload) {
    Utility.delete_model_by_property(state.data, 'id', payload.id);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
