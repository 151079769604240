<template>
  <div class="date-time-picker-wrapper" style="position:relative;"
       :class="'medimo-datetime-picker h-100 ' + (validation_class.length ? 'form-control ' + validation_class : '') + ' ' + (hasSlot ? 'custom-element' : '') + ' ' + (showDayOfWeek ? 'with-day-of-week' : '')"
       @click="$emit('click')"
  >

    <div :class="'input-container w-100'" :style="input_container_styles" @scroll="onScroll">

      <medimo-form-row :no_margins="no_margins" margin_classes="pr-0">
        <medimo-form-column class="medimo-date-time-picker-column-1" column_classes="col-12 col-sm" :no_margin="no_margins">
          <!-- v-bind:value="localDateTime" -->
          <VueCtkDateTimePicker
              :id="id"
              style="position:relative;"
              ref="VueCtkDateTimePicker"
              v-model="localDateTime"
              :format="external_datetime_format"
              color="#1b4475"
              button-color="#1b4475"
              locale="nl"
              button-now-translation="Nu"
              :no-label="true"
              :no-header="true"
              :disabledHours="disabled_hours"
              :minute-interval="60"
              :min-date="minDate"
              :max-date="maxDate"
              :no-value-to-custom-elem="true"
              :no-keyboard="true"
              :only-date="no_time_picker"
              :only-time="only_time"
              @selectedDate="$emit('selectedDate')"
          >
            <span v-if="hasSlot" @click.stop="toggleDateTimePicker">
              <slot name="custom"></slot>
            </span>
            <input v-else
                   @click="toggleDateTimePicker"
                   class="medimo-datetime-picker-input"
                   :name="name"
                   @input="onTypeInput($event)"
                   @blur="onTypeBlur($event)"
                   :class="'form-control datetime-text-input ' + (is_invalid ? 'is-invalid' : '') + ' '  + (disabled ? 'disabled' : '')"
                   type="text"
                   :placeholder="placeholder"
                   :disabled="disabled"
                   v-model="inputDateTime"/>
          </VueCtkDateTimePicker>
          <!-- Moet in een span omdat de fa-icon in Vue3 geen native meer ondersteunt en ook geen @click emit -->
          <span v-if="!is_invalid && !hasSlot && show_icon" @click.stop="toggleDateTimePicker">
            <fa-icon icon="fa-light fa-calendar-days" size="lg" class="clickable"/>
          </span>
        </medimo-form-column>
        <medimo-form-column :no_margin="no_margins" v-if="help_text.length">
          <medimo-tooltip :content="help_text">
            <fa-icon icon="fa-solid fa-circle-question" :class="'text-primary' + (disabled ? ' disabled' : '')" size="lg"/>
          </medimo-tooltip>
        </medimo-form-column>
        <medimo-form-column column_classes="col-12 col-sm-auto button-row" v-if="shortcuts" :no_margin="no_margins">

            <div class="row">
              <div class="col-auto">
                <slot name="pre-buttons"></slot>
              </div>
              <div class="col-auto" v-if="show_shortcut_now">
                <a :class="'now-button ml-1 mr-3 ' + (disabled ? 'disabled' : 'is-clickable')" @click.prevent="disabled ? null : now()">
                  <fa-icon icon="fa-regular fa-clock" size="lg" />
                  Nu
                </a>
              </div>
              <div class="col-auto" v-if="show_shortcut_today">
                <a :class="'today-button ml-1 mr-3 ' + (disabled ? 'disabled' : 'is-clickable')" @click.prevent="disabled ? null : today()">
                  <fa-icon icon="fa-regular fa-clock" size="lg" /> <span>Vandaag</span>
                </a>
                </div>
                <div class="col-auto" v-if="show_shortcut_tomorrow">
                    <a :class="'ml-1 mr-3 ' + (disabled ? 'disabled' : 'is-clickable')" @click.prevent="disabled ? null : tomorrow()">
                      <fa-icon icon="fa-regular fa-clock" size="lg" /> <span>Morgen</span>
                    </a>
                </div>
                <div class="col-auto" v-if="show_shortcut_weekbox">
                    <a :class="'ml-1 mr-3 ' + (disabled ? 'disabled' : 'is-clickable')" @click.prevent="disabled ? null : weekBox()">
                      <fa-icon icon="fa-kit fa-capsule" size="lg" /> <span>{{weekdoos_string}}</span>
                    </a>
                </div>
                <div class="col-auto" v-if="showPeriodSelectorSwitch">
                  <a :class="'ml-1 mr-3 is-clickable'" @click.prevent="$emit('switchToPeriodSelector')">
                    <fa-icon icon="fa-regular fa-dot-circle" size="lg" />
                    <span>Periode</span>
                  </a>
                </div>
            </div>

        </medimo-form-column>

      </medimo-form-row>

    </div>
  </div>
</template>

<style scoped lang="scss">
@import "/variables";

a.disabled:hover {
  text-decoration: none;
}

.medimo-datetime-picker {
  position:relative;
  .button-row a, .fa-circle-question {
    position: relative;
    top: 8px;
  }

  &.with-day-of-week {
    input[type=text] {
      font-size: 0.830rem; // Als we de dag ervoor tonen maken we iets ruimte
    }
  }

  svg.svg-inline--fa {
    color: $medimo;

    &.fa-calendar-days {
      position: absolute;
      color: $medimo-25;
      right: 12px;
      top: 8px;
    }
  }
}
</style>

<script>
import VueCtkDateTimePicker from '@/vue/components/general/form/date-time-picker';
import Utility from '@/vue/utility/utility';
import moment from 'moment-mini';
import MedimoTooltip from '@/vue/components/general/MedimoTooltip';
import MedimoFormRow from '@/vue/components/general/form/base/MedimoFormRow';
import MedimoFormColumn from '@/vue/components/general/form/base/MedimoFormColumn';
import ClickOutside from '/vue/directives/ClickOutside';
import { nextTick } from 'vue';
import { v4 as uuidv4 } from 'uuid';

export default {
  components: {
    VueCtkDateTimePicker,
    MedimoTooltip,
    MedimoFormRow,
    MedimoFormColumn,
  },

  emits: ['click','update:modelValue', 'blur', 'selectedDate'],

  directives: {
    clickOutside: ClickOutside
  },

  props: {
    'no_margins': {default: true},
    is_invalid: {default: false},

    // Set to true to disable the input field
    disabled: {default: false},

    // Use this to set the input & output datetime_format
    datetime_format: {default: 'DD-MM-YYYY HH:mm'},


    minDate: {default: null, type: [String, null]},
    maxDate: {default: null},

    // Set to TRUE to enable the quick "Nu" "Vandaag" etc. shortcuts
    shortcuts: {default: false},
    show_shortcut_now: {default: true},
    show_shortcut_today: {default: true},
    show_shortcut_tomorrow: {default: true},
    show_shortcut_weekbox: {default: true},

    // Set to FALSE to disable the calendar icon - handy on mobile for example
    show_icon: {default: true},

    deadline_today: {required: false},
    deadline_tomorrow: {required: false},
    deadline_weekbox: {required: false},
    only_time: {default: false},
    modelValue: {default: false},
    placeholder: {default: 'Selecteer datum/tijd'},

    input_container_styles: {default: ''},

    // Set either of these to TRUE to turn it into a DatePicker (and edit datetime_format accordingly)
    time_disabled: {default: false},
    only_date: {default: false},

    // When set to TRUE, will set the time to 00:00 when selecting a date
    time_reset: {default: true},

    // Set to TRUE to close the DTP when a user clicks on a date button
    close_on_date_change: {default: false},
    // Set to TRUE to close the DTP when a user clicks on a time button
    close_on_time_change: {default: true},

    'name': {default: ''},
    'help_text': {default: ''},
    'extra_classes': {default: ''},
    'validation_class': {default: ''},
    showPeriodSelectorSwitch: {default: false},

    showDayOfWeek: {default: false},
  },

  mixins: [],

  data: function () {
    return {
      id: uuidv4(),
      open: false,

      // Used to track the dateTime input in the input field
      // Sinds de Vue3 upgrade nodig omdat copy/pasten niet meer werkte zoals voorheen met de @input en @blur
      // events. Hij had dan nog de oude waarde in de event.target.value zitten. Met v-model werken we daar omheen
      inputDateTime: '',

      // Used to locally track the dateTime and ensure it works in tandem with the v-model prop
      localDateTime: '',

      // Used to monitor the minute changes
      previousLocalDateTime: '',

      // Used to properly reset the caret position after input
      caret_position: 0,
    };
  },

  computed: {
    uid() {
      return this._.uid;
    },
    medimoDateTimePickerUid() {
      return this.uid;
    },
    no_time_picker() {
      return this.time_disabled || this.only_date;
    },
    minute_interval: function () {
      if (this.no_time_picker) {
        return 1;
      } else {
        return 1;
      }
    },
    disabled_hours: function () {
      if (this.no_time_picker) {
        const hours = [];
        for (let i = 1; i < 24; i++) {
          hours.push(i.toString());
        }
        return hours;
      } else {
        return [];
      }
    },
    hasSlot() {
      // Moet nu zo omdat in Vue3 $slots.default altijd true is.
      return !!this.$slots.custom;
    },
    // Used locally to work with the dates.
    local_datetime_format() {
      // When we're dealing with time, it's always HH:mm for convenience
      if (this.only_time) {
        return 'HH:mm';
      }
      return 'YYYY-MM-DD HH:mm';
    },
    // Used locally to work with the dates.
    external_datetime_format() {
      // When we're dealing with time, it's always HH:mm for convenience
      if (this.only_time) {
        return 'HH:mm';
      }
      return this.datetime_format;
    },
    weekdoos_string() {
      return Utility.ucfirst(this.$store.state.current_user.data.language.weekdoos);
    },
    timeShouldBeReset() {
      // We resetten de tijd alleen als de DTP open is
      return this.$refs['VueCtkDateTimePicker'].pickerOpen && this.time_reset;
    },
  },

  mounted() {
    // Whent the value is empty, we don't need all the moment magic, yet.
    if (this.modelValue === '' || this.modelValue === null) {
      return false;
    }
    // // When there's no modelValue set, we use today @ 00:00
    const modelValue = this.modelValue ? this.modelValue : moment(moment().format('YYYY-MM-DD') + ' 00:00').format(this.local_datetime_format);
    this.localDateTime = moment(modelValue, this.local_datetime_format).format(this.external_datetime_format);
    this.previousLocalDateTime = moment(modelValue, this.local_datetime_format).format(this.external_datetime_format);
    this.inputDateTime = this.localDateTime;
  },

  methods: {
    onTypeBlur(event) {
      let inputDateTime = this.inputDateTime;
      if (this.only_time && inputDateTime.length === 3) {
        inputDateTime = '0' + inputDateTime;
      }
      this.localDateTime = moment(inputDateTime, this.external_datetime_format).format(this.external_datetime_format);
      this.$emit('blur');
    },
    onTypeInput(event) {
      // The cursor usually jumps when inputting the date by keyboard, so we have to reset the caret here
      this.caret_position = event.target.selectionStart;
      nextTick(() => {
        event.target.setSelectionRange(this.caret_position, this.caret_position);
      });
    },
    now() {
      this.setByShortcut('deadline_now', moment());
    },
    today() {
      this.setByShortcut('deadline_today', this.deadline_today);
    },
    tomorrow() {
      this.setByShortcut('deadline_tomorrow', this.deadline_tomorrow);
    },
    weekBox() {
      this.setByShortcut('deadline_weekbox', this.deadline_weekbox);
    },
    setByShortcut(property_name, value) {
      if (typeof value === 'undefined') {
        console.error('No ' + property_name + ' has been defined in the DateTimePicker.');
      } else {
        this.localDateTime = value.format(this.external_datetime_format);
      }
    },
    emitLocalValue(value) {
      const aboutToEmit = moment(value, this.external_datetime_format).format(this.local_datetime_format);
      // At times we just want a blank entry, or a user can input a faulty entry. Instead of showing the error message, we just clear
      // the input.
      if (aboutToEmit === 'Invalid date') {
        this.localDateTime = '';
        return false;
      }
      nextTick(() => {
        this.$emit('update:modelValue', aboutToEmit);
      });
    },
    closeDateTimePicker() {
      setTimeout(() => {
        this.$refs['VueCtkDateTimePicker'].pickerOpen = false;
      }, 10);
    },
    openDateTimePicker() {
      if (!this.disabled) {
        setTimeout(() => {
          this.$refs['VueCtkDateTimePicker'].pickerOpen = true;
        }, 10);
      }
    },
    toggleDateTimePicker() {
      if (!this.disabled) {
        setTimeout(() => {
          this.$refs['VueCtkDateTimePicker'].pickerOpen = !this.$refs['VueCtkDateTimePicker'].pickerOpen;
        }, 10);
      }
    },
    onScroll(event) {
      //
    },
    // Use this method to check if 2 dates have been rounded up.
    timeIsRoundedUp(oldDate, newDate) {
      return (newDate.hours() - oldDate.hours() === 1) && (oldDate.minutes() !== 0 && newDate.minutes() === 0);
    }
  },

  watch: {
    inputDateTime(value) {
      // Adds the day string to the display value when needed
      const dayOfWeek = this.modelValue !== '' ? moment(this.modelValue, this.local_datetime_format).format('dddd').substring(0, 2) : null;
      if (this.showDayOfWeek && dayOfWeek && !value.includes(dayOfWeek)) {
        this.inputDateTime = dayOfWeek + ' ' + value;
      }
    },
    modelValue(value) {
      const formattedValue = value !== '' ? moment(value, this.local_datetime_format).format(this.external_datetime_format) : value;
      // Only use moment when it's not an empty string.
      this.localDateTime = formattedValue;
      this.inputDateTime = formattedValue;
    },
    /**
     * We use this watcher to hook into specific changes and act accordingly. We want different behaviour
     * based on a timeChange or dateChange.
     *
     * @param localDateTime
     * @returns {boolean}
     */
    localDateTime(localDateTime) {
      // Het is een gecompliceerd zooitje aan het worden, maar deze vangt nu iig af dat hij altijd leeg
      // blijft bij een invalid date. Ondanks dat dit ook al gebeurd in de emitLocalValue, maar da's dus te laat.
      if (localDateTime === 'Invalid date') {
        this.emitLocalValue('');
        this.$emit('update:modelValue', '');
        return false;
      }
      // Stop processing / kicking it up when the date is invalid. Allows for keyboard input without
      // breaking reactivity.
      const dateTime = moment(localDateTime, this.external_datetime_format, true);
      if (!dateTime._isValid) {
        return false;
      }

      const newDate = moment(localDateTime, this.external_datetime_format);
      const oldDate = moment(this.previousLocalDateTime, this.external_datetime_format);

      const dateChanged = (newDate.date() !== oldDate.date()) || (newDate.month() !== oldDate.month()) || (newDate.year() !== oldDate.year());
      const hoursChanged = newDate.hours() !== oldDate.hours();
      const minutesChanged = newDate.minutes() !== oldDate.minutes();

      // We can get custom input form the buttons that would set a time to 16:06, however, next time we change the date
      // the time will change from 16:06 to 16:00 because that's the nearest hour that's auto-selected in the TimePicker. Triggering
      // a "time has changed" event. We don't want that. So we reset to 00:00 if the time is rounded down as well

      // When a specific time is being set with one of the buttons, we don't want the time_reset behaviour.
      if (this.timeShouldBeReset && dateChanged && !hoursChanged) {
        // Overwrite the localValue with the time-resetted one
        const day = dateTime.date().toString().padStart(2, '0');
        const month = (dateTime.month() + 1).toString().padStart(2, '0');
        // When the time should be reset on a date change, and it hasn't changed already, we set it to 00:00 here.
        localDateTime = day + '-' + month + '-' + dateTime.year() + ' 00:00';
      }

      // Update the previous one to match the new value
      this.previousLocalDateTime = localDateTime;

      if (dateChanged || hoursChanged || minutesChanged) {
        // Update the datetime with the updated values one to match the new value, so we don't trigger the value watcher through
        // v-model update.
        this.localDateTime = localDateTime;

        // And kick it up, for the v-model
        this.emitLocalValue(localDateTime);
      }

      // Then the datetimepicker if need be.
      if ((this.close_on_time_change && hoursChanged) || this.close_on_date_change && dateChanged) {
        this.closeDateTimePicker();
      }
    }
  }
};
</script>
