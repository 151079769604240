<template>
  <medimo-external-data-table
      store_name="log"
      :name="'log-table-' + type + '-' + typeId"
      :number_of_rows="logs.length"
      :minimum_height="true"
      :search_on_load="true"
      :autofocus="true"
      :enable_filter="enable_filter"
      :privacy_filter="false"
  >
    <medimo-thead>
      <medimo-tr>
        <medimo-th sort_column_1="created_at" column_name_1="Log" store_name="log"></medimo-th>
      </medimo-tr>
    </medimo-thead>
    <medimo-tbody>
      <template v-for="log in logs">
        <medimo-tr :keynav="false" :disabled="true" :inactive="true">
          <medimo-td>
            <template v-for="dataline in log.data">
              <span v-html="dataline" /><br/>
            </template>
            {{ log.by }}
          </medimo-td>
        </medimo-tr>
      </template>
    </medimo-tbody>
  </medimo-external-data-table>
</template>

<script>
import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';

export default {
  mixins: [ BaseComponentsMixin ],
  props: {
    type: { type: String, required: true },
    typeId: { type: Number, default: null },
    enable_filter: { type: Boolean, default: true }
  },
  computed: {
    logs() {
      return this.$store.state.log.search_results;
    }
  },
  created() {
    this.$store.commit('log/clear_search_results');
    this.$store.dispatch('log/set_endpoint', {type: this.type, typeId: this.typeId});
    this.$store.commit('log/set_state', { property: 'sort_columns', value: [{column_name: 'created_at', direction: 'DESC'}] });
  }
};
</script>

<style lang="scss" scoped>
.tr:hover {
  cursor: auto !important;
  .td {
    cursor: auto !important;
  }
}
</style>
