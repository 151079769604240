<template>
  <div v-if="patient.currentlyImport || patient.location.moduleAIS !== 'N'" style="display:inline-block;">

    <medimo-link
        id="import-link"
        :class="'status badge badge-sm ml-1 ' + (patient.currentlyImport ? 'badge-medimo-50' : 'badge-warning')"
        @click.native="show_import_modal = true"
        :to="''">
      <medimo-tooltip
          :content="'Apotheek-import'"
          class="small">IMPORT
      </medimo-tooltip>
    </medimo-link>

    <import-overview-modal
        v-if="show_import_modal"
        :patient="patient"
        @closed="show_import_modal = false"
    ></import-overview-modal>
  </div>
</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import ImportOverviewModal from "@/vue/components/patients/import/ImportOverviewModal.vue";

export default {

  components: {
    ImportOverviewModal
  },

  mixins: [
    BaseComponentsMixin,
  ],

  props: [
    'patient'
  ],

  data: function() {
    return {
      show_import_modal: false,
    };
  },

  computed: {

  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    //
  },

  watch: {
    //
  }

};
</script>
