<template>
    <medi-modal
            @closed="ignoreForNow"
            :hide_overlay="hide_overlay"
            :loading="loading"
            size="small"
    >
        <template v-slot:modal-header>
            <div class="modal-title col-auto">
                {{ action.name }}
            </div>
        </template>

        {{ action.description }}
        <p>Ga na en voer in indien van toepassing</p>

        <template v-slot:action-buttons>
            <medimo-button type="success" class="mr-auto" @click="no_indications">
                Er zijn geen contra-indicaties
            </medimo-button>
            <medimo-button type="button" class="btn btn-primary ml-1" @click="ignoreForNow">
                Nu even niet
            </medimo-button>
        </template>

    </medi-modal>
</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import MediModal from '@/vue/components/general/MediModal';
import NavigatesProgrammatically from '@/vue/mixins/navigation/NavigatesProgrammatically';

export default {
  components: {
    MediModal,
  },

  emits: ['closed','processed'],

  props: [
    'action',
    'hide_overlay',
  ],

  mixins: [
    BaseComponentsMixin,
    NavigatesProgrammatically,
  ],

  data: function () {
    return {
      agreed: false,
      note: '',
      loading: false,

      checked: false,

      // Can be changed on the Fly, see decline()
      to: '/'
    };
  },

  computed: {
    //
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    ignoreForNow() {
      this.$emit('closed');
    },
    no_indications() {
      this.loading = true;
      return this.$store.dispatch('patients/update_action_from_patient', {
        patient_id: this.action.patientId,
        handler: 'noIndications',
        action_id: this.action.id,
        data: {
          explanation: 'Geen indicaties',
          informThem: false,
        }
      }).then(() => {
        this.loading = false;
        this.$emit('processed');
        this.$emit('closed');
      }).catch(() => {
        // Needed for IE11, there's no .finally()
        this.loading = false;
      });
    },
  },

  watch: {
    //
  }
};
</script>
