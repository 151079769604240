import BaseModule from '@/vue/store/modules/base_module';

const state = {
  ...BaseModule.state,
  quick_filters: {
    active: {label: 'Alleen actief', value: true},
  },
  sort_columns: [{column_name: 'drug.naam', direction: 'ASC'}],
};

const actions = {
  ...BaseModule.actions,
  set_endpoint({state, commit, dispatch}, payload) {
    commit('set_search_endpoint', '/api/v1/stock/' + payload.type + '/' + payload.typeId + '/stock-records');
  },
  fetch_single({state, commit, dispatch, getters, rootState}, payload) {
    return dispatch('api/getEndpoint', {
      endpoint: '/api/v1/stock/stock-records/' + payload.id
    }, {root: true}).then(response => {
      commit('add_to_data', response.data.data);
    });
  },
  save_options({state, commit, dispatch}, payload) {
    return dispatch('api/postEndpoint', {
      endpoint: '/api/v1/stock/stock-records/' + payload.id + '/save',
      data: payload.data
    }, {root: true}).then(response => {
      commit('add_to_data', response.data.data);
    });
  },
  mutate({state, commit, dispatch}, payload) {
    return dispatch('api/postEndpoint', {
      endpoint: '/api/v1/stock/stock-records/' + payload.id + '/mutate',
      data: payload.data
    }, {root: true}).then(response => {
      commit('add_to_data', response.data.data);
    });
  },
  save_stock_to_drug({state, commit, dispatch}, payload) {
    return dispatch('api/postEndpoint', {
      endpoint: '/api/v1/stock/' + payload.type + '/' + payload.typeId + '/stock-records/save-drug-to-stock',
      data: payload.data
    }, {root: true});
  },
  delete({state, commit, dispatch}, payload) {
    return dispatch('api/deleteEndpoint', {
      endpoint: '/api/v1/stock/stock-records/' + payload.id,
      data: payload.data
    }, {root: true});
  },
};
const getters = { ...BaseModule.getters };
const mutations = { ...BaseModule.mutations };

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
