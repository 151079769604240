import BaseModule from '@/vue/store/modules/base_module';

// initial state
// shape: [{ id, quantity }]
const state = {
  ...BaseModule.state,

  data: [],
  showVisitNumber: false,
  quick_filters: {
    'Actief': true,
  },
  filter_columns: [
    // 'example'
  ],
  default_sort_columns: [{column_name: 'locationName', direction: 'ASC'}],
  sort_columns: [{column_name: 'locationName', direction: 'ASC'}]
};

// getters
const getters = {
  ...BaseModule.getters,

  showVisitNumber(state, getters, rootState, rootGetters) {
    return state.showVisitNumber;
  },
  actief_quick_filter(state, getters, rootState, rootGetters) {
    return (data, filter_state) => {
      if (!filter_state) return data;

      return data.filter((model) => {
        return model.history === 0;
      });
    };
  },
};

// actions
const actions = {
  ...BaseModule.actions,
  fetch ({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/patients/' + payload.patientId + '/ward-assignments';

    return dispatch('api/getEndpoint', {
      endpoint: endpoint,
      data: {} // Empty, since it's a GET
    }, {root: true}).then(response => {
      commit('set_data', response.data.patientWardAssignments);
      commit('set_state', { property: 'showVisitNumber', value: response.data.showVisitNumber});
    });
  },
  create ({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/patients/' + payload.patientId + '/ward-assignments';
    return dispatch('api/postEndpoint', {
      endpoint: endpoint,
      data: payload.wardAssignment
    }, {root: true}).then(response => {
      commit('add_to_data', response.data.data);
      return response;
    });
  },
  update ({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/patients/' + payload.patientId + '/ward-assignments/' + payload.wardAssignment.id;
    return dispatch('api/putEndpoint', {
      endpoint: endpoint,
      data: payload.wardAssignment
    }, {root: true}).then(response => {
      commit('add_to_data', response.data.data);
      return response;
    });
  },
};

// mutations
const mutations = {
  ...BaseModule.mutations,
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
