import {MedicationOverviewRow} from '@/domain/models/MedicationOverviewRow';
import {PharmaceuticalProduct} from '@/domain/models/PharmaceuticalProduct';
import {ParticularitiesObject} from '@/domain/models/ParticularitiesObject';
import {CodeObject} from '@/domain/models/CodeObject';

export class GeneratedMedicationOverviewRow { 
  public id: number;
  public active: boolean;
  public patientId: number;
  public medicationAgreementId: number | null;
  public medicationUseId: number | null;
  public administrationAgreement: number | null;
  public pharmaceuticalTreatmentId: number;
  public type: string;
  public pharmaceuticalProduct: PharmaceuticalProduct;
  public description: string;
  public particularities: ParticularitiesObject;
  public drugName: string;
  public drugProperties: string;
  public drugAtc: string;
  public isMedicalDevice: boolean;
  public isDosingInstruction: boolean;
  public isMedicationUse: boolean;
  public date: string;
  public startDate: string;
  public endDate: string;
  public startDateTime: string;
  public endDateTime: string;
  public isStop: boolean;
  public isTemporaryStop: boolean;
  public comment: string | null;
  public usageType: string;
  public sequence: number;
  public isCyclicalPeriod: boolean;
  public cyclicalPeriodHumanReadable: string;
  public cyclicalPeriodInstructions: string[];
  public orderUnit: CodeObject;

  constructor(id: number, active: boolean, patientId: number, medicationAgreementId: number | null = null, medicationUseId: number | null = null, administrationAgreement: number | null = null, pharmaceuticalTreatmentId: number, type: string, pharmaceuticalProduct: PharmaceuticalProduct, description: string, particularities: ParticularitiesObject, drugName: string, drugProperties: string, drugAtc: string, isMedicalDevice: boolean, isDosingInstruction: boolean, isMedicationUse: boolean, date: string, startDate: string, endDate: string, startDateTime: string, endDateTime: string, isStop: boolean, isTemporaryStop: boolean, comment: string | null = null, usageType: string, sequence: number, isCyclicalPeriod: boolean, cyclicalPeriodHumanReadable: string, cyclicalPeriodInstructions: string[], orderUnit: CodeObject) {
    this.id = id;
    this.active = active;
    this.patientId = patientId;
    this.medicationAgreementId = medicationAgreementId;
    this.medicationUseId = medicationUseId;
    this.administrationAgreement = administrationAgreement;
    this.pharmaceuticalTreatmentId = pharmaceuticalTreatmentId;
    this.type = type;
    this.pharmaceuticalProduct = pharmaceuticalProduct;
    this.description = description;
    this.particularities = particularities;
    this.drugName = drugName;
    this.drugProperties = drugProperties;
    this.drugAtc = drugAtc;
    this.isMedicalDevice = isMedicalDevice;
    this.isDosingInstruction = isDosingInstruction;
    this.isMedicationUse = isMedicationUse;
    this.date = date;
    this.startDate = startDate;
    this.endDate = endDate;
    this.startDateTime = startDateTime;
    this.endDateTime = endDateTime;
    this.isStop = isStop;
    this.isTemporaryStop = isTemporaryStop;
    this.comment = comment;
    this.usageType = usageType;
    this.sequence = sequence;
    this.isCyclicalPeriod = isCyclicalPeriod;
    this.cyclicalPeriodHumanReadable = cyclicalPeriodHumanReadable;
    this.cyclicalPeriodInstructions = cyclicalPeriodInstructions;
    this.orderUnit = orderUnit;
  }

  public static fromJson(json: unknown): MedicationOverviewRow {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new MedicationOverviewRow(
      parsedJson['id'] as number,
      parsedJson['active'] as boolean,
      parsedJson['patientId'] as number,
      parsedJson['medicationAgreementId'] as number,
      parsedJson['medicationUseId'] as number,
      parsedJson['administrationAgreement'] as number,
      parsedJson['pharmaceuticalTreatmentId'] as number,
      parsedJson['type'] as string,
      PharmaceuticalProduct.fromJson(parsedJson['pharmaceuticalProduct']),
      parsedJson['description'] as string,
      ParticularitiesObject.fromJson(parsedJson['particularities']),
      parsedJson['drugName'] as string,
      parsedJson['drugProperties'] as string,
      parsedJson['drugAtc'] as string,
      parsedJson['isMedicalDevice'] as boolean,
      parsedJson['isDosingInstruction'] as boolean,
      parsedJson['isMedicationUse'] as boolean,
      parsedJson['date'] as string,
      parsedJson['startDate'] as string,
      parsedJson['endDate'] as string,
      parsedJson['startDateTime'] as string,
      parsedJson['endDateTime'] as string,
      parsedJson['isStop'] as boolean,
      parsedJson['isTemporaryStop'] as boolean,
      parsedJson['comment'] as string,
      parsedJson['usageType'] as string,
      parsedJson['sequence'] as number,
      parsedJson['isCyclicalPeriod'] as boolean,
      parsedJson['cyclicalPeriodHumanReadable'] as string,
      parsedJson['cyclicalPeriodInstructions'] as string[],
      CodeObject.fromJson(parsedJson['orderUnit']),
    );
  }

  public static multipleFromJson(json: unknown): MedicationOverviewRow[] {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    const items: MedicationOverviewRow[] = [];
    for (const item of parsedJson) {
      items.push(this.fromJson(item));
    }
    return items;
  }
}
