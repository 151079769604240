import BaseModule from '@/vue/store/modules/base_module';

const state = {
  ...BaseModule.state,
  options: {},
  history: {},
};

const getters = { };

const actions = {

  fetchOptions({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/patients/' + payload.patient_id + '/bem/options';

    return dispatch('api/getEndpoint', {
      endpoint: endpoint,
      data: {}
    }, {root: true}).then(response => {
      commit('add_to_options', {patient_id: payload.patient_id, bem_options: response.data.data});
    });
  },

  update({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/patients/' + payload.patient_id + '/bem/update';

    return dispatch('api/postEndpoint', {
      endpoint: endpoint,
      data: payload.data
    }, {root: true}).then(response => {
      commit('patients/add_to_data', response.data.data, { root: true });
      commit('clear_history', {patient_id: payload.patient_id });
    });
  },

  fetchHistory({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/patients/' + payload.patient_id + '/bem/history';

    return dispatch('api/getEndpoint', {
      endpoint: endpoint,
      data: {} // Empty, since it's a GET
    }, {root: true}).then(response => {
      commit('add_to_history', {patient_id: payload.patient_id, bem_history: response.data.data});
    });
  },
};

const mutations = {
  ...BaseModule.mutations,

  add_to_options(state, payload) {
    state.options[payload.patient_id] = payload.bem_options;
  },
  add_to_history(state, payload) {
    state.history[payload.patient_id] = payload.bem_history;
  },
  clear_history(state, payload) {
    delete state.history[payload.patient_id];
  }
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
