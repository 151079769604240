export class Thrombosis {
  public hasData: boolean;
  public agencyName: string;
  public agencyContactDetails: string;
  public nextBloodTest: string;
  public doseUntil: string;
  public latestChangeInData: string;
  public latestImport: string;
  public latestSuccessfulImport: string;

  constructor(hasData: boolean, agencyName: string, agencyContactDetails: string, nextBloodTest: string, doseUntil: string, latestChangeInData: string, latestImport: string, latestSuccessfulImport: string) {
    this.hasData = hasData;
    this.agencyName = agencyName;
    this.agencyContactDetails = agencyContactDetails;
    this.nextBloodTest = nextBloodTest;
    this.doseUntil = doseUntil;
    this.latestChangeInData = latestChangeInData;
    this.latestImport = latestImport;
    this.latestSuccessfulImport = latestSuccessfulImport;
  }

  public static fromJson(json: unknown): Thrombosis {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new Thrombosis(
      parsedJson['hasData'] as boolean,
      parsedJson['agencyName'] as string,
      parsedJson['agencyContactDetails'] as string,
      parsedJson['nextBloodTest'] as string,
      parsedJson['doseUntil'] as string,
      parsedJson['latestChangeInData'] as string,
      parsedJson['latestImport'] as string,
      parsedJson['latestSuccessfulImport'] as string,
    );
  }
}
