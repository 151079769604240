<template>
  <ActionsDataTableRow
      :refresh_patient_medication_data="true"
      :patient="props.patient"
      :action="props.action"
      @refresh_table="processedModal"
      :index="props.action.id">
    <MedimoTd column_classes="col col-sm-6">
      <span>{{ props.action.name }}</span>
      <fa-icon v-if="props.action.priority" icon="fa-solid fa-circle-exclamation" size="lg"/>
      <fa-icon v-if="props.action.privacy" icon="fa-solid fa-key" size="lg"/>
    </MedimoTd>
    <MedimoTd>
      {{ props.action.assignedTo }}
    </MedimoTd>
    <MedimoTd v-bind:class="{'text-danger': validUntilPassed}">
      {{ parsedValidUntilDate }}
    </MedimoTd>
  </ActionsDataTableRow>
</template>

<script setup lang="ts">
import Dates from '@/vue/utility/dates';
import moment from 'moment-mini';
import MedimoTd from '@/vue/components/general/table/MedimoTd.vue';
import ActionsDataTableRow from './ActionsDataTableRow.vue';
import {Action} from "@/domain/models/Action";
import {Patient} from "@/domain/models/Patient";
import {computed} from "vue";

const emit = defineEmits(['refresh_table']);

interface Props {
  action: Action;
  patient: Patient;
}

const props = defineProps<Props>();

const parsedValidUntilDate = computed<string>(() => {
  if (props.action.validUntil === null) {
    return 'Onbekend';
  }
  return Dates.setDate(props.action.validUntil).dutchDate();
});

const validUntilPassed = computed<boolean>(() => {
  if (props.action.validUntil === null) {
    return false;
  }

  return (moment(props.action.validUntil).diff(moment(), 'days') < 0);
});

function processedModal() {
  emit('refresh_table');
}
</script>
