import BaseModule from '@/vue/store/modules/base_module';

const state = {
  ...BaseModule.state,
  data: [],
  params: [],
  quick_filters: {
    'reason': {
      'group': {
        'Voorschriften' : false,
        'Opt-ins' : false,
        'Niet-medisch' : false,
        'LSP' : false,
      }
    },
    'Deze week' : false,
  },

  sort_columns: [
    {column_name: 'nen7513_patient_access.id', direction: 'DESC'},
    {column_name: 'userSession.user_name', direction: 'ASC'}
  ],
};

// getters
const getters = {
  ...BaseModule.getters,
};

// actions
const actions = {
  ...BaseModule.actions,
};

// mutations
const mutations = {
  ...BaseModule.mutations,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
