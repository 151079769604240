import BaseModule from '@/vue/store/modules/base_module';

// initial state
// shape: [{ id, quantity }]
const state = {
  ...BaseModule.state,

  authoriseMutationChannel: null,
};

// getters
const getters = {
  ...BaseModule.getters,
};

// actions
const actions = {
  ...BaseModule.actions,
  initialize({state, commit, dispatch, getters, rootState}, payload) {
    const notificationChannel = window.medimo.WebSocket.join('Notification');
    notificationChannel.on('Notification', (payload) => {
      dispatch('notifications/add_notification', {
        message: payload.message,
        title: payload.title,
        type: payload.type,
        href: payload.href,
        visible_for: payload.visibleFor,
        new: payload.isNew,
      }, {root: true});
    });
    notificationChannel.on('Alert', (payload) => {
      dispatch('notifications/add_alert', {
        message: payload.message,
        title: payload.title,
        type: payload.type,
        href: payload.href,
        visible_for: payload.visibleFor,
        new: payload.isNew,
      }, {root: true});
    });

    const reportChannel = window.medimo.WebSocket.join('Report');
    reportChannel.on('ReportReady', (payload) => {

      commit('reports/add_to_data', payload.report, {root: true});

      dispatch('notifications/add_notification', {
        title: payload.title,
        type: payload.type,
        href: payload.href,
        visible_for: payload.visibleFor,
        new: payload.isNew,
      }, {root: true});
    });

    const photoReviewChannel = window.medimo.WebSocket.join('PhotoReview');
    photoReviewChannel.on('New', (payload) => {
      const notyTitle = 'Een collega wacht op een fotocontrole';

      // Alleen bij de eerste trigger tonen we de noty
      // en laten we 15 seconden geen nieuwe meer zien
      const hasTdrApprovalNoty = rootState.notifications.data.filter(notification => {
        return notification.title === notyTitle;
      }).length > 0;

      if (!hasTdrApprovalNoty) {
        dispatch('notifications/add_notification', {
          message: 'Klik hier om de controlepagina te openen',
          title: notyTitle,
          type: 'warning',
          href: '/aq/claim',
          visible_for: 15000,
          new: true
        }, {root: true});
      }
    });
  }
};

// mutations
const mutations = {
  ...BaseModule.mutations,
  //
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
