import BaseModule from '../base_module';
import moment from 'moment-mini';

// initial state
// shape: [{ id, quantity }]
const state = {
  ...BaseModule.state,

  is_offline: false,
};

// getters
const getters = {
  ...BaseModule.getters,
  is_offline(state) {
    return state.is_offline;
  },
};

// actions
const actions = {
  ...BaseModule.actions,
  initialize({state, commit, dispatch, getters, rootState}, payload) {

    window.addEventListener("offline", (event) => {
      if (!state.is_offline) {
        commit('is_offline');
      }
    });

    window.addEventListener("online", (event) => {
      if (state.is_offline) {
        commit('is_online');
      }
    });

  }
};

// mutations
const mutations = {
  ...BaseModule.mutations,

  is_offline(state) {
    state.is_offline = true;
  },
  is_online(state) {
    state.is_offline = false;
  },
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
