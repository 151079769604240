<script>
import ChecksIfVuePage from './ChecksIfVuePage';

export default {
  props: {
    target: {default: '_self'}
  },
  mixins: [ChecksIfVuePage],
  computed: {
    isVueRoute() {
      return this.pathIsVueRoute(this.toPath);
    },
    isVuePage() {
      return this.currentPageIsVuePage();
    },
    toPath() {
      return this.to.replace(new RegExp('^' + window.location.origin), '');
    }
  },
  methods: {
    navigateProgrammatically(replace = false) {
      if (typeof this.to === 'undefined') {
        console.error('this.to is undefined by a component that uses the NavigatesProgrammatically Mixin');
        return false;
      }

      // Use Vue router when applicable
      if (!this.isVueRoute) {
        this.navigateByLinkClick(replace);
      } else if (!this.isVuePage) { // Make sure we're at a place that can actually use vue navigation
        this.navigateByLinkClick(replace);
      } else if (replace) {
        this.$router.replace(this.toPath);
      } else {
        this.$router.push(this.toPath);
      }
    },
    navigateByLinkClick(replace = false) {
      if (this.target === '_blank') {
        window.open(this.toPath, this.target);
      } else if (replace) {
        window.location.replace(this.toPath);
      } else {
        window.location.href = this.toPath;
      }
    }
  },
};
</script>
