<template>
  <div class="col-md-4" v-if="patient.authority.canViewEpisodes">

    <div v-if="patient.authority.canEditEpisodes" class="w-100 p-2 mb-1 text-primary bg-medimo-10 is-clickable hover" @click="$emit('showModalEpisodes')">
      <strong>Episodes</strong>
    </div>
    <div v-else class="w-100 p-2 mb-1 text-primary bg-medimo-10">
      <strong>Episodes</strong>
    </div>
    <div class="p-1 ml-1" v-for="episode in episodes" v-if="episodes.length">
      - {{episode.code}} {{ episode.title }}
      <span class="text-danger" v-if="typeof episode.attributes !== 'undefined'" v-for="attribute in episode.attributes">
          {{ attribute }}
      </span>
    </div>
    <div class="p-1 ml-1" v-if="episodes.length === 0">
      - Geen episodes gevonden
    </div>
  </div>
</template>

<script>

export default {

  components: {},
  emits: ['showModalEpisodes'],
  props: [
    'patient_id'
  ],

  computed: {
    patient() {
      return this.$store.getters['patients/find'](this.patient_id);
    },
    episodes() {
      return this.$store.getters['episodes/active'];
    },
  }
};
</script>

<style>
.hover:hover {
  text-decoration: underline;
}
</style>
