<script>
import {nextTick} from 'vue';

export default {
  components: {},

  props: {
    'breadcrumb_title': {
      required: true,
      type: String,
    },
    'disable_breadcrumb': {
      default: false,
      type: Boolean,
    },
  },

  mixins: [],

  data: function () {
    return {
      //
    };
  },

  computed: {
    //
  },

  created() {
    //
  },

  mounted() {
    this.updateBreadCrumb();
  },

  methods: {
    updateBreadCrumb() {
      const breadCrumb = {
        title: this.breadcrumb_title,
        path: window.location.pathname,
      };
      if (!this.disable_breadcrumb) {
        this.$store.dispatch('settings/breadcrumbs/add_breadcrumb_trail', breadCrumb);
        nextTick(() => {
          this.$store.dispatch('settings/breadcrumbs/add_breadcrumb', breadCrumb);
        });
      }
    }
  },

  watch: {
    breadcrumb_title(newVal, oldVal) {
      this.updateBreadCrumb();
    }
  }
};
</script>
