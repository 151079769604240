<template>
    <medimo-form>
      <medimo-form-row>

        <medimo-form-column class="col-12 col-md-6">

          <medimo-form-row class="mb-3">
            <medimo-form-column class="font-weight-bold col-12">
              Toe te voegen intoleranties:
            </medimo-form-column>
          </medimo-form-row>

          <medimo-form-row v-if="checked_options_groep.length > 0" class="mb-3">
            <medimo-form-column class="text-de col-12">
              Allergie/Intolerantie groepen:
            </medimo-form-column>
            <medimo-form-column v-for="option in checked_options_groep" :key="option.id" class="col-12">
              <span>- {{ option.text }}</span>
            </medimo-form-column>
          </medimo-form-row>

          <medimo-form-row v-if="checked_options_stof.length > 0" class="mb-3">
            <medimo-form-column class="col-12">
              Allergie/Intolerantie stof:
            </medimo-form-column>
            <medimo-form-column v-for="option in checked_options_stof" :key="option.id" class="col-12">
              <span>- {{ option.text }}</span>
            </medimo-form-column>
          </medimo-form-row>

          <medimo-form-row v-if="checked_options_stof_toedien.length > 0" class="mb-3">
            <medimo-form-column class="col-12">
              Allergie/Intolerantie stof incl. toedienwijze:
            </medimo-form-column>
            <medimo-form-column v-for="option in checked_options_stof_toedien" :key="option.id" class="col-12">
              <span>- {{ option.text }}</span>
            </medimo-form-column>
          </medimo-form-row>

          <medimo-form-row v-if="checked_options_preparaat.length > 0" class="mb-3">
            <medimo-form-column class="col-12">
              Allergie/Intolerantie handelspreparaat:
            </medimo-form-column>
            <medimo-form-column v-for="option in checked_options_preparaat" :key="option.id" class="col-12">
              <span>- {{ option.text }}</span>
            </medimo-form-column>
          </medimo-form-row>
        </medimo-form-column>

        <medimo-form-column class="font-weight-bold col-12 col-md-6">
          <medication-guard
              :loading="medication_guard_loading"
              :signals="signals">
          </medication-guard>
        </medimo-form-column>
      </medimo-form-row>

    </medimo-form>
</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import MedicationGuard from '@/vue/components/status/medication-guard/MedicationGuard';

export default {
  components: {
    MedicationGuard,
  },

  props: {
    'patient': {required: true},
    'intolerance_id': {required: true},
    'intolerance_type': {required: true},
    'info': {required: true},
    'register_date': {required: true}
  },

  mixins: [
    BaseComponentsMixin
  ],

  data: function () {
    return {
      loading: false,
      medication_guard_loading: false,
    };
  },

  computed: {
    signals() {
      return this.$store.getters['medication_guard_signals/for_added_intolerance'](this.intolerance_id);
    },
    checked_options_groep() {
      return this.$store.getters['intolerances_zindex/checkedOptionsGroep'];
    },
    checked_options_stof() {
      return this.$store.getters['intolerances_zindex/checkedOptionsStof'];
    },
    checked_options_stof_toedien() {
      return this.$store.getters['intolerances_zindex/checkedOptionsStofEnToedien'];
    },
    checked_options_preparaat() {
      return this.$store.getters['intolerances_zindex/checkedOptionsPreparaat'];
    },
    intolerances_to_be_added() {
      return this.$store.getters['intolerances_zindex/allCheckedOptions'];
    }
  },

  created() {
  },

  mounted() {
    this.fetchSignals();
  },

  methods: {
    fetchSignals() {

      this.loading = true;

      const vm = this;
      this.medication_guard_loading = true;
      this.$store.dispatch('intolerances/fetchMedicationGuardSignals', {
        patient_id: this.patient.id,
        intolerances_to_be_added: this.intolerances_to_be_added,
        intolerance_type: this.intolerance_type,
      }).then(response => {

        // Other signals
        response.data.data.other_signals.forEach((signal) => {
          signal.intoleranceId = vm.intolerance_id;
          this.$store.commit('medication_guard_signals/add_signal', {signal: signal, tempNotes: []});
        });

        this.medication_guard_loading = false;
        this.loading = false;
      });
    },
  },

  watch: {
  }
};
</script>
