import BaseModule from '@/vue/store/modules/base_module';

const state = {
  ...BaseModule.state,
  quick_filters: {
    'actueel': true,
    'alles openstaand': false,
    'inactief': false,
  },
  toggleQuickFilters: true,
  search_columns: [
    'action.name',
    'patient.naam',
    'ward.naam',
    'location.naam',
  ],
};

const getters = { ...BaseModule.getters };
const actions = { ...BaseModule.actions };
const mutations = { ...BaseModule.mutations };

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
