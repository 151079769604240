import {MedicationVerificationItems} from '@/domain/models/MedicationVerificationItems';
import {MedicationVerificationItem} from '@/domain/models/MedicationVerificationItem';

export class GeneratedMedicationVerificationItems { 
  public newest: MedicationVerificationItem;
  public items: MedicationVerificationItem[];

  constructor(newest: MedicationVerificationItem, items: MedicationVerificationItem[]) {
    this.newest = newest;
    this.items = items;
  }

  public static fromJson(json: unknown): MedicationVerificationItems {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new MedicationVerificationItems(
      MedicationVerificationItem.fromJson(parsedJson['newest']),
      parsedJson['items'] ? parsedJson['items'].map((e: unknown) => MedicationVerificationItem.fromJson(e)) : null,
    );
  }

  public static multipleFromJson(json: unknown): MedicationVerificationItems[] {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    const items: MedicationVerificationItems[] = [];
    for (const item of parsedJson) {
      items.push(this.fromJson(item));
    }
    return items;
  }
}
