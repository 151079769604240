import Vue from 'vue';
import BaseModule from '@/vue/store/modules/base_module';

// initial state
// shape: [{ id, quantity }]
const state = {
  ...BaseModule.state,

  // Generated met https://www.json-generator.com/#
  // Super handig :)
  data: [],

  templates: {},

  chat_messages: {},  // messages per action id

  quick_filters: {
    // Grouped quick filter:
    'Status': {
      'mustBeActive': true,
      'group': {
        'Actueel': true,
        'Alles openstaand': false,
        'Inactief': false,
      }
    },
  },
  toggleQuickFilters: true,
  mustBeActive: true,
};

// getters
const getters = {
  ...BaseModule.getters,
};

// actions
const actions = {
  ...BaseModule.actions,

  fetchTemplates({state, commit, dispatch, getters, rootState}, payload) {

    const endpoint = '/api/v1/actions/fetch-templates';

    return dispatch('api/getEndpoint', {
      endpoint: endpoint,
      data: {}
    }, {root: true}).then(response => {
      commit('add_to_templates', {patient_id: payload.patient_id, templates: response.data.data});
    });
  },

  store({state, commit, dispatch, getters, rootState}, payload) {

    const endpoint = '/api/v1/actions/' + payload.patient_id + '/store';

    return dispatch('api/postEndpoint', {
      rejectErrors: payload.rejectErrors,
      endpoint: endpoint,
      data: payload.data
    }, {root: true}).then(response => {

    });
  },

  update({state, commit, dispatch, getters, rootState}, payload) {

    const endpoint = '/api/v1/actions/' + payload.patient_id + '/update/' + payload.handler + '/' + payload.action_id;

    return dispatch('api/postEndpoint', {
      rejectErrors: payload.rejectErrors,
      endpoint: endpoint,
      data: payload.data
    }, {root: true}).then(response => {
      commit('update_selected_search_result', response.data.data);
      return response;
    });
  },

  fetchChatMessages({state, commit, dispatch, getters, rootState}, payload) {

    const endpoint = '/api/v1/actions/' + payload.patient_id + '/chat/fetch/' + payload.action_id;

    return dispatch('api/getEndpoint', {
      rejectErrors: true,
      endpoint: endpoint,
      data: {}
    }, {root: true}).then(response => {
      commit('add_to_chat_messages', {action_id: payload.action_id, chat_messages: response.data.data});
    });
  },

  addChatMessage({state, commit, dispatch, getters, rootState}, payload) {

    const endpoint = '/api/v1/actions/' + payload.patient_id + '/chat/add/' + payload.action_id;

    return dispatch('api/postEndpoint', {
      rejectErrors: true,
      endpoint: endpoint,
      data: payload.data
    }, {root: true}).then(response => {
      commit('add_to_chat_messages', {action_id: payload.action_id, chat_messages: response.data.data});
    });
  },
};

// mutations
const mutations = {
  ...BaseModule.mutations,

  add_to_templates(state, payload) {
    state.templates = payload.templates;
  },

  add_to_chat_messages(state, payload) {
    state.chat_messages[payload.action_id] = payload.chat_messages;
  },
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations,
};
