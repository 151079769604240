<template>
  <medimo-form-row :no_margins="no_margins">
    <medimo-form-column v-if="label.length" :no_margin="no_margins" :column_classes="inline ? label_column_classes : 'col-12'">
      <label class="textarea pr-2" :class="validation_class" :for="name">
        {{ label }}
      </label>
    </medimo-form-column>
    <medimo-form-column :no_margin="no_margins" :column_classes="inline ? 'col' : 'col-12'">
      <medimo-textarea
          :class="extra_classes"
          :cols="cols"
          :rows="rows"
          :disabled="disabled"

          v-bind:modelValue="modelValue"
          @update:modelValue="onInput"

          :name="name"
          :placeholder="placeholder"
          :validation_class="validation_class"
          :max_characters="max_characters"
          :help_text="help_text">
      </medimo-textarea>
    </medimo-form-column>
  </medimo-form-row>
</template>

<script>

import MedimoTextarea from './MedimoTextarea';
import MedimoFormRow from './MedimoFormRow';
import MedimoFormColumn from './MedimoFormColumn';

export default {

  components: {
    MedimoTextarea,
    MedimoFormRow,
    MedimoFormColumn,
  },

  emits: ['update:modelValue'],

  props: {
    'no_margins': {default: true},
    'inline': {default: true},
    'modelValue': {default: ''},
    'name': {default: ''},
    'label': {default: ''},
    'placeholder': {default: ''},
    'extra_classes': {default: ''},
    'validation_class': {default: ''},
    'help_text': {type: [String, null], default: ''},
    'cols': {default: 20},
    'rows': {default: 5},
    'disabled': {default: false},
    'max_characters': {default: null},

    // By default the label takes the space it needs, and no more.
    // You can specify a specific column width here if you want to change this behaviour for mobile, tablet or desktop
    'label_column_classes': {default: 'col-auto'},
  },

  mixins: [],

  data: function () {
    return {
      //
    };
  },

  computed: {
    //
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    onInput(value) {
      this.$emit('update:modelValue', value);
    }
  },

  watch: {
    //
  }
};
</script>
