import {MedicationAgreement} from "@/domain/models/MedicationAgreement";
import {MedicationAgreementDiffObject} from "@/domain/models/MedicationAgreementDiffObject";

export class UnprocessedMedicationAgreement {
  public medicationAgreement: MedicationAgreement;
  public mutations: MedicationAgreementDiffObject[];

  constructor(medicationAgreement: MedicationAgreement, mutations: MedicationAgreementDiffObject[]) {
    this.medicationAgreement = medicationAgreement;
    this.mutations = mutations;
  }

  public static fromJson(json: unknown): UnprocessedMedicationAgreement {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new UnprocessedMedicationAgreement(
      MedicationAgreement.fromJson(parsedJson['medicationAgreement']),
      parsedJson['mutations'].map((e: unknown) => MedicationAgreementDiffObject.fromJson(e)),
    );
  }

  public static multipleFromJson(json: unknown): UnprocessedMedicationAgreement[] {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    const items: UnprocessedMedicationAgreement[] = [];
    for (const item of parsedJson) {
      items.push(this.fromJson(item));
    }
    return items;
  }
}
