<template>
  <div v-if="patient.isThrombosisPatient" style="display:inline-block;">
    <medimo-link
        id="thrombosis-link"
        :class="'status badge badge-sm ml-1 ' + (patient.thrombosis.hasData ? 'badge-medimo-50' : 'badge-warning')"
        @click.native="showThrombosisModal = true"
        :to="''">
      <medimo-tooltip
          :content="patient.thrombosis.hasData ? patient.thrombosis.agencyName : 'Trombosedienst'"
          class="small">TROMB
      </medimo-tooltip>
    </medimo-link>

    <thrombosis-overview-modal
        v-if="showThrombosisModal"
        :patient="patient"
        @closed="showThrombosisModal = false"
    ></thrombosis-overview-modal>
  </div>
</template>

<script setup lang="ts">
import MedimoLink from "@/vue/components/general/MedimoLink.vue";
import MedimoTooltip from "@/vue/components/general/MedimoTooltip.vue";
import ThrombosisOverviewModal from "@/vue/components/patients/thrombosis/ThrombosisOverviewModal.vue";
import {Patient} from "@/domain/models/Patient";
import {ref} from "vue";

interface Props {
  patient: Patient,
}

defineProps<Props>();

const showThrombosisModal = ref(false);

</script>
