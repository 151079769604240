import {InstructionsForUse} from '@/domain/models/InstructionsForUse';
import {DosingInstructionObject} from '@/domain/models/DosingInstructionObject';
import {CodeObject} from '@/domain/models/CodeObject';
import {QuantityObject} from '@/domain/models/QuantityObject';

export class GeneratedInstructionsForUse { 
  public description: string;
  public dosingInstructions: DosingInstructionObject[];
  public routeOfAdministration: CodeObject | null;
  public additionalInstructions: CodeObject[] | null;
  public repeatPeriodCyclicalSchedule: QuantityObject | null;

  constructor(description: string, dosingInstructions: DosingInstructionObject[], routeOfAdministration: CodeObject | null = null, additionalInstructions: CodeObject[] | null = null, repeatPeriodCyclicalSchedule: QuantityObject | null = null) {
    this.description = description;
    this.dosingInstructions = dosingInstructions;
    this.routeOfAdministration = routeOfAdministration;
    this.additionalInstructions = additionalInstructions;
    this.repeatPeriodCyclicalSchedule = repeatPeriodCyclicalSchedule;
  }

  public static fromJson(json: unknown): InstructionsForUse {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new InstructionsForUse(
      parsedJson['description'] as string,
      parsedJson['dosingInstructions'] ? parsedJson['dosingInstructions'].map((e: unknown) => DosingInstructionObject.fromJson(e)) : null,
      parsedJson['routeOfAdministration'] ? CodeObject.fromJson(parsedJson['routeOfAdministration']) : null,
      parsedJson['additionalInstructions'] ? parsedJson['additionalInstructions'].map((e: unknown) => CodeObject.fromJson(e)) : null,
      parsedJson['repeatPeriodCyclicalSchedule'] ? QuantityObject.fromJson(parsedJson['repeatPeriodCyclicalSchedule']) : null,
    );
  }

  public static multipleFromJson(json: unknown): InstructionsForUse[] {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    const items: InstructionsForUse[] = [];
    for (const item of parsedJson) {
      items.push(this.fromJson(item));
    }
    return items;
  }
}
