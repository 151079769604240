<template>
  <medi-modal
      :loading="loading"
      :title="'Details bepaling ' + reading.bepaling + ' - ' + parsedBepalingOpDate"
      size="medium"
      @closed="close"
  >
    <medimo-form>

      <medimo-form-row v-if="reading.bepaling">
        <medimo-form-column column_classes="col-3">Bepaling</medimo-form-column>
        <medimo-form-column column_classes="col-9">{{ reading.bepaling }}</medimo-form-column>
      </medimo-form-row>

      <medimo-form-row v-if="reading.uitslag">
        <medimo-form-column column_classes="col-3">Uitslag</medimo-form-column>
        <medimo-form-column column_classes="col-9">{{ reading.uitslag }}</medimo-form-column>
      </medimo-form-row>

      <medimo-form-row v-if="reading.eenheid">
        <medimo-form-column column_classes="col-3">Eenheid</medimo-form-column>
        <medimo-form-column column_classes="col-9">{{ reading.eenheid }}</medimo-form-column>
      </medimo-form-row>

      <medimo-form-row v-if="reading.ondergrens">
        <medimo-form-column column_classes="col-3">Ondergrens</medimo-form-column>
        <medimo-form-column column_classes="col-9">{{ reading.ondergrens }}</medimo-form-column>
      </medimo-form-row>

      <medimo-form-row v-if="reading.bovengrens">
        <medimo-form-column column_classes="col-3">Bovengrens</medimo-form-column>
        <medimo-form-column column_classes="col-9">{{ reading.bovengrens }}</medimo-form-column>
      </medimo-form-row>

      <medimo-form-row>
        <medimo-form-column column_classes="col-3">Bepaling door</medimo-form-column>
        <medimo-form-column column_classes="col-9">{{ reading.bepaling_door }}</medimo-form-column>
      </medimo-form-row>

      <medimo-form-row>
        <medimo-form-column column_classes="col-3">Bepaling op</medimo-form-column>
        <medimo-form-column column_classes="col-9">{{ parsedBepalingOpDate }}</medimo-form-column>
      </medimo-form-row>

      <medimo-form-row>
        <medimo-form-column column_classes="col-3">Ingevoerd op</medimo-form-column>
        <medimo-form-column column_classes="col-9">{{ parsedUpdatedAtDate }}</medimo-form-column>
      </medimo-form-row>

      <medimo-form-row>
        <medimo-form-column column_classes="col-3">Opmerking</medimo-form-column>
        <medimo-form-column column_classes="col-9">{{ reading.opmerking }}</medimo-form-column>
      </medimo-form-row>

    </medimo-form>
  </medi-modal>
</template>

<script>

import Dates from '@/vue/utility/dates';
import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import MedimoAlert from '@/vue/components/general/MedimoAlert';
import MedimoNotification from '@/vue/components/general/MedimoNotification';
import MedimoValidatedTextarea from '@/vue/components/general/form/MedimoValidatedTextarea';

export default {
  emits: ['closed'],
  components: {
    MedimoValidatedTextarea,
    MedimoNotification,
    MedimoAlert
  },

  props: [
    'reading',
  ],

  mixins: [
    BaseComponentsMixin
  ],

  data: function () {
    return {
      loading: false
    };
  },

  computed: {
    parsedBepalingOpDate() {
      return Dates.setDate(this.reading.datum).dutchDateTime();
    },
    parsedUpdatedAtDate() {
      return Dates.setDate(this.reading.updated_at).dutchDateTime();
    },
  },

  created() {
  },

  mounted() {
  },

  methods: {
    close(event) {
      this.$emit('closed');
    },
  },

  watch: {

  }
};
</script>
