<template>
  <medimo-form-row :no_margins="no_margins">
    <medimo-form-column v-if="label.length || labelCanBeEmpty" :no_margin="no_margins" :column_classes="inline ? label_column_classes : 'col-12'">
      <label :class="'pr-2 text'" :for="name">
        {{ label }}
      </label>
    </medimo-form-column>
    <medimo-form-column :no_margin="no_margins" :column_classes="inline ? 'col' : 'col-12'">
      <label class="text" v-bind:class="{'font-weight-bold': bold_text, 'font-italic': italic_text}">
        {{ text }}
      </label>
      <input type="hidden" :value="text" :name="name" />
    </medimo-form-column>
  </medimo-form-row>
</template>

<script>

import MedimoFormRow from './MedimoFormRow';
import MedimoFormColumn from './MedimoFormColumn';

export default {

  components: {
    MedimoFormRow,
    MedimoFormColumn,
  },

  props: {
    text: {required: true},
    label: {required: true},

    no_margins: {default: true},
    inline: {default: true},
    extra_classes: {default: ''},
    labelCanBeEmpty: {default: false},

    bold_text: {default: false},
    italic_text: {default: false},
    name: {default: ''},

    // By default the label takes the space it needs, and no more.
    // You can specify a specific column width here if you want to change this behaviour for mobile, tablet or desktop
    label_column_classes: {default: 'col-auto'},
  },

  mixins: [],

  data: function () {
    return {
      //
    };
  },

  computed: {
    hasHelpText() {
      return this.help_text.length;
    }
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    onInput(value) {
      this.$emit('input', value);
    },
    onBlur(value) {
      this.$emit('blur', value);
    },
  },

  watch: {
    //
  }
};
</script>
