<template>
  <medimo-tr @triggered="$emit('selected')">

    <medimo-td column_classes="col col-sm-6">
      <span>{{ intolerance.text }}</span>

      <span v-if="intolerance.attributes" class='text-danger'>
        {{ intolerance.attributes }}
      </span>
    </medimo-td>

  </medimo-tr>
</template>

<script>

import MedimoTd from '@/vue/components/general/table/MedimoTd';
import MedimoTr from '@/vue/components/general/table/MedimoTr';

export default {
  emits: ['selected'],
  components: {
    MedimoTd,
    MedimoTr,
  },

  props: [
    'intolerance',
  ],

  mixins: [],

  data: function () {
    return {

    };
  },

  computed: {
  },

  created() {
  },

  mounted() {
  },

  methods: {

  },

  watch: {
  }
};
</script>