const state = {
  error_bag: {},
  old_error_notifications: [],
  timeout_timers: {
    // Deze gebruiken we om per request naar een specifiek endpoint
    // de timeout van 60 seconden te verifieren. Firefox maakt
    // namelijk geen onderscheid tussen een abort door timeout,
    // of een abort door weg navigeren, i.e. een cancel.
    // We houden hierin een unieke timer per endpoint request bij,
    // en we slaan de interval op, om die altijd te kunnen annuleren
  },
};
// getters
const getters = {
  failedReponse(state, getters, rootState) {
    return (response) => {
      // This one ensures compatibility with the Old notifications
      // They send 200 responses regardless, so we catch them here and show
      // the notifications in Vue
      if (response && response.data.length > 0 && Array.isArray(response.data)) {
        const notifications = [];
        response.data.forEach(responseNotification => {
          if (responseNotification.hasOwnProperty('notification')) {
            const notification = responseNotification.notification;

            if (notification.type === 'error') {
              notification.type = 'danger';
            }

            notifications.push(notification);
          }
        });
        return notifications;
      }
      return false;
    };
  },
  hasOldStyleErrors(state, getters, rootState) {
    return state.old_error_notifications.length > 0;
  }
};

// actions
const actions = {
  getEndpoint({state, commit, dispatch, getters, rootState}, payload) {
    payload.method = 'get';
    return new Promise((resolve, reject) => {
      dispatch('callApi', payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  postEndpoint({state, commit, dispatch, getters, rootState}, payload) {
    payload.method = 'post';
    return new Promise((resolve, reject) => {
      dispatch('callApi', payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  putEndpoint({state, commit, dispatch, getters, rootState}, payload) {
    payload.method = 'put';
    return new Promise((resolve, reject) => {
      dispatch('callApi', payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  deleteEndpoint({state, commit, dispatch, getters, rootState}, payload) {
    payload.method = 'delete';
    return new Promise((resolve, reject) => {
      dispatch('callApi', payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  callApi({state, commit, dispatch, getters, rootState, rootGetters}, payload) {
    if (rootGetters['application/online_check/is_offline']) {
      dispatch('notifications/addWarningAlert', {
        title: 'U bent momenteel offline. Wacht even en probeer het opnieuw.',
        visible_for: 2500,
      }, {root: true});
      return new Promise((resolve, reject) => {
        reject('Offline');
      });
    }

    commit('clear_error_bag');
    commit('clear_old_error_notifications');
    // Verify the contents of the payload
    if (typeof payload.data === "undefined") {
      payload.data = {};
    }
    // Verify the contents of the config
    if (typeof payload.config === "undefined") {
      payload.config = {};
    }

    // Zo kunnen we de timeout overriden bij zwaardere calls
    if (typeof payload.timeout !== "undefined") {
      window.axios.defaults.timeout = payload.timeout;
    }

    state.timeout_timers[payload.endpoint] = 0;
    state.timeout_timers[payload.endpoint + '_interval'] = setInterval(() => {
      state.timeout_timers[payload.endpoint]++;
    }, 1000);

    // We doen het nu op deze wijze zodat de inputs gelijk zijn voor GET en POST, PUT etc.
    // Met de vorige manier was de tweede input van GET de config, maar de 2e input van POST, PUT etc. de data
    // dit kan onverwachte problemen opleveren
    const config = {
      method: payload.method,
      url: payload.endpoint,
      params: payload.params, // query params
      data: payload.data,
      signal: payload.signal ?? null // Used to cancel calls with the AbortController from axios.
    };
    const finalConfig = Object.assign(config, payload.config);
    return new Promise((resolve, reject) => {
      window.axios(finalConfig)
        .then(response => {
          clearInterval(state.timeout_timers[payload.endpoint + '_interval']);

          // Als we een 200 terug krijgen, kan deze alsnog een error noty bevatten
          return dispatch('processPossibleOldStyleErrorNotification', response).then(notifications => {
            // errorNotifications kunnen op moment van schrijven ook success notifications
            // zijn. Hier even een filter, later refactoren.
            if (notifications && notifications.filter(notification => {
              return notification.type === 'danger';
            }).length) {
              reject(response);
            } else {
              dispatch('onSuccess', response);
              resolve(response);
            }
          });

        })
        .catch(error => {
          if (error.code === 'ECONNABORTED' && state.timeout_timers[payload.endpoint] >= 60) {
            dispatch('notifications/addDangerAlert', {
              message: 'Verzoek afgebroken omdat deze langer dan ' + (window.axios.defaults.timeout / 1000) + ' seconden duurde. Neem contact op met de Medimo Helpdesk als dit vaker gebeurt.'
            }, {root: true});
            dispatch('sentry/send', { message: error.code + ': ' + error.message, trace: error.stack }, {root: true});
          }

          dispatch('onError', error);

          // Altijd clearen
          clearInterval(state.timeout_timers[payload.endpoint + '_interval']);

          reject(error);
        });
    });
  },
  onSuccess({state, commit, dispatch, getters, rootState}, response) {
    // Oude stijl notifications in v3 tonen
    const notifications = [];
    if (typeof response.data === 'object') {
      Object.keys(response.data).forEach(objectKey => {
        const data = response.data[objectKey];
        if (data && typeof data.responseType !== 'undefined' && data.responseType === 'Notification' && !getters['hasOldStyleErrors']) {
          notifications.push(data.notification);
        }
      });
    }
    else if (Array.isArray(response.data) && response.data.length) {
      response.data.forEach(data => {
        if (typeof data.responseType !== 'undefined' && data.responseType === 'Notification' && !getters['hasOldStyleErrors']) {
          notifications.push(data.notification);
        }
      });
    }

    notifications.forEach(notification => {
      dispatch('notifications/add_alert', {
        type: notification.type,
        message: notification.text,
      }, {root: true});
    });
  },
  onError({state, commit, dispatch, getters, rootState, rootGetters}, error) {
    // Ook een error status kan oude error noty bevatten
    dispatch('processPossibleOldStyleErrorNotification', error.response);

    // Er kan in de code gecancelled worden, een specifieke error die altijd code-matig
    // getriggered wordt, die we dan hier weer moeten negeren:
    if (error.constructor.name === 'Cancel') {
      if (process.env.APP_ENV === 'development' || process.env.APP_ENV === 'testing') {
        console.warn('Een request is afgebroken.');
      }
      // En we breken hier dan direct deze catch.
      return false;
    }

    if (error.response && error.response.status === 500) {
      const title =  'Er is een fout opgetreden bij een verzoek naar ' + error.response.config.url;

      // Met de timeout geven we de Sentry even om te genereren en de ID terug te geven
      setTimeout(() => {
        const sentry_id = rootGetters['sentry/sentry_id'];
        dispatch('notifications/add_alert', {
          type: 'danger',
          visible_for: 10000,
          title: title,
          message: 'Er is een logging verzonden naar het ontwikkelteam van Medimo.\n' +
            'Indien u deze fout vaker tegenkomt gelieve de Medimo helpdesk hiervan te waarschuwen.' +
            (sentry_id.length ? '\n' + sentry_id : '')
        }, {root: true});
      }, 500);
    } else if (error.response && error.response.status === 404) {
      dispatch('notifications/add_alert', {
        type: 'danger',
        title: 'Pagina niet gevonden',
        message: 'Betreft: ' + error.response.config.url
      }, {root: true});
    }
    else if (error.response && error.response.status === 401) {
      dispatch('notifications/addDangerAlert', {
        message: 'Ongeautoriseerd verzoek. U wordt automatisch uitgelogd.'
      }, {root: true});
      // Unauthorized, we loggen diegene uit
      window.medimo.allowNavigationOnUnsavedChanges = true; // app.js checks this value when the user changes pages but he/she has important changes.
      window.location.href = "/logout";
    }

    // If there an error bag, we fill it
    else if (error.response && typeof error.response.data.errors !== "undefined") {
      commit('fill_error_bag', {errors: error.response.data.errors, status: error.response.headers.status});

      if (error.response.status === 422) {
        Object.keys(error.response.data.errors).forEach(field_name => {
          // Iedere error bag heeft per veld weer een array met meldingen
          error.response.data.errors[field_name].forEach(errorMessage => {
            dispatch('notifications/add_alert', {
              type: 'danger',
              visible_for: 10000,
              message: errorMessage
            }, {root: true}
            );
          });
        });
      } else {
        dispatch('notifications/add_alert', {
          type: 'danger',
          message: error.response.data.message,
        }, {root: true}
        );
      }
      commit('settings/errors/add_to_page_errors', {
        errors: error.response.data.errors,
        status: error.response.status
      }, {root: true});
    }
    // This global error catcher allows for specific triggers based on the error
    // Useful for error bags, auth flows, stuff that can pop up all over
    else if (error.response?.data?.message !== undefined) {
      dispatch('notifications/add_alert', {
        type: 'danger',
        message: error.response.data.message,
      }, {root: true});
    }

    // This global error catcher allows for specific triggers based on the error
    // Useful for error bags, auth flows, stuff that can pop up all over
    else if (error.response) {
      let message = 'Er is een onbekende fout opgetreden.';

      if (error.response.status === 401) {
        message = 'Ongeautoriseerde request naar: ' + error.response.config.url + ' (401)';
      }
      if (error.response.status === 422) {
        message = 'Ongeldige waardes in request naar: ' + error.response.config.url + ' (422)';
      }

      setTimeout(() => {
        const sentry_id = rootGetters['sentry/sentry_id'];
        dispatch('notifications/add_alert', {
          type: 'danger',
          message: message + (sentry_id.length ? '\n' + sentry_id : ''),
        }, {root: true}
        );
      },500);

    }
  },
  /**
   * Binnen Medimo kun je op diverse manieren errors terug krijgen van de backend. Onderstaand checkt de response
   * (ongeacht HTTP status) op de aanwezigheid van de "oude stijl" notys, en verwerkt / toont die
   *
   * @param state
   * @param commit
   * @param dispatch
   * @param getters
   * @param rootState
   * @param response
   * @returns {null|*}
   */
  processPossibleOldStyleErrorNotification({state, commit, dispatch, getters, rootState}, response) {
    const errorNotifications = getters['failedReponse'](response);

    if (errorNotifications && errorNotifications.length) {
      commit('set_old_error_notifications', errorNotifications);
      errorNotifications.forEach(errorNotification => {
        dispatch('notifications/add_alert', {
          type: errorNotification.type,
          message: errorNotification.text,
        }, {root: true}
        );
      });

      return errorNotifications;
    }

    return null;
  }
};
// mutations
const mutations = {
  fill_error_bag(state, data) {
    const error_bag = {};
    Object.keys(data.errors).forEach((key) => {
      // We moeten array waardes even flattenen hier. Als een array waarde verkeerd is
      // komt die terug als key.2, waarbij het cijfer de index is, maar dat werkt niet met de forms,
      // Zo wel:
      const message = data.errors[key];
      if (key.includes('.')) {
        key = key.split('.')[0];
      }
      error_bag[key] = message;
    });
    state.error_bag = error_bag;
  },
  clear_error_bag(state) {
    state.error_bag = {};
  },
  clear_old_error_notifications(state) {
    state.old_error_notifications = [];
  },
  set_old_error_notifications(state, error_notifications) {
    state.old_error_notifications = error_notifications;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
