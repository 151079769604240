<template>
  <div class="col-md-4 mt-2 mt-md-0" v-if="patient.authority.canViewIndications">
    <div v-if="patient.authority.canEditIndications" class="w-100 p-2 mb-1 text-primary bg-medimo-10 is-clickable hover" @click="$emit('showModalIndications')">
      <strong>Contra-indicaties</strong>
    </div>
    <div v-else class="w-100 p-2 mb-1 text-primary bg-medimo-10">
      <strong>Contra-indicaties</strong>
    </div>
    <div class="p-1 ml-1" v-if="indications === null">
      - Onbekend of er contra-indicaties zijn
    </div>
    <div class="p-1 ml-1" v-else-if="indications.length === 0">
      - Geen contra-indicaties aanwezig
    </div>
    <div class="p-1 ml-1" v-for="indication in indications">
      - {{ indication.title }}
      <span class="text-danger" v-if="typeof indication.attributes !== 'undefined'" v-for="attribute in indication.attributes">{{ attribute }}</span>
    </div>
  </div>
</template>

<script>

export default {

  components: {},
  emits: ['showModalIndications'],
  props: [
    'patient_id'
  ],

  computed: {
    patient() {
      return this.$store.getters['patients/find'](this.patient_id);
    },
    indications() {
      return this.$store.getters['indications/active'];
    },
  }
};
</script>

<style>
.hover:hover {
  text-decoration: underline;
}
</style>
