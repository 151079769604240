<template>
    <div @click="$emit('click')" :class="'medimo-form-row row flex-row ' + all_margin_classes">
        <slot></slot>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
export default defineComponent({
  components: {},
  emits: ['click'],
  props: {
    'margin_classes': {default: 'mt-1 mb-1'},

    // Set to TRUE to disable margins on the row, needed for some nested situations
    // When you want the grid, but not the margins
    'no_margins': {default: false},
  },

  mixins: [],

  data: function () {
    return {
      //
    };
  },

  computed: {
    all_margin_classes() {
      if (this.no_margins) {
        return 'mt-0 mb-0';
      }
      return this.margin_classes;
    }
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    //
  },

  watch: {
    //
  }
});
</script>
