<script>

// /form/base
import MedimoCheckbox from '@/vue/components/general/form/base/MedimoCheckbox';
import MedimoLabeledCheckbox from '@/vue/components/general/form/base/MedimoLabeledCheckbox';
import MedimoInput from '@/vue/components/general/form/base/MedimoInput';
import MedimoLabeledInput from '@/vue/components/general/form/base/MedimoLabeledInput';
import MedimoLabeledTextarea from '@/vue/components/general/form/base/MedimoLabeledTextarea';
import MedimoLabeledSelect2 from '@/vue/components/general/form/base/MedimoLabeledSelect2';
import MedimoRadio from '@/vue/components/general/form/base/MedimoRadio';
import MedimoSelect from '@/vue/components/general/form/base/MedimoSelect';
import MedimoSelect2 from '@/vue/components/general/form/base/MedimoSelect2';
import MedimoVueSelect2 from '@/vue/components/general/form/base/vue-select2/MedimoVueSelect2';
import MedimoLabeledVueSelect2 from '@/vue/components/general/form/base/vue-select2/MedimoLabeledVueSelect2';
import MedimoValidatedVueSelect2 from '@/vue/components/general/form/base/vue-select2/MedimoValidatedVueSelect2';
import MedimoTextarea from '@/vue/components/general/form/base/MedimoTextarea';
import MedimoTextEditor from '@/vue/components/general/form/base/MedimoTextEditor';
// /form
import MedimoForm from '@/vue/components/general/form/MedimoForm';
import MedimoFormRow from '@/vue/components/general/form/base/MedimoFormRow';
import MedimoFormColumn from '@/vue/components/general/form/base/MedimoFormColumn';
import MedimoValidatedInput from '@/vue/components/general/form/MedimoValidatedInput';
import MedimoValidatedSelect from '@/vue/components/general/form/MedimoValidatedSelect';
import MedimoValidatedSelect2 from '@/vue/components/general/form/MedimoValidatedSelect2';
import MedimoValidatedTextarea from '@/vue/components/general/form/MedimoValidatedTextarea';
import MedimoValidatedCheckbox from '@/vue/components/general/form/MedimoValidatedCheckbox';
import MedimoDateTimePicker from '@/vue/components/general/form/base/MedimoDateTimePicker';
import MedimoValidatedDateTimePicker from '@/vue/components/general/form/MedimoValidatedDateTimePicker';
import MedimoLabeledDateTimePicker from '@/vue/components/general/form/base/MedimoLabeledDateTimePicker';
import MedimoDatePicker from '@/vue/components/general/form/base/MedimoDatePicker';
import MedimoValidatedDatePicker from '@/vue/components/general/form/MedimoValidatedDatePicker';
import MedimoLabeledDatePicker from '@/vue/components/general/form/base/MedimoLabeledDatePicker';
// /layout
import MedimoStyle from '@/vue/components/general/layout/MedimoStyle';
import PageBlock from '@/vue/components/general/layout/PageBlock';
import PageComponent from '@/vue/components/general/layout/PageComponent';
import MedimoLayout from '@/vue/components/general/layout/MedimoLayout';
// /table
import MedimoTable from '@/vue/components/general/table/MedimoTable';
import MedimoDataTable from '@/vue/components/general/table/MedimoDataTable';
import MedimoExternalDataTable from '@/vue/components/general/table/MedimoExternalDataTable';
import MedimoLocalDataTable from '@/vue/components/general/table/MedimoLocalDataTable';
import MedimoThead from '@/vue/components/general/table/MedimoThead';
import MedimoTbody from '@/vue/components/general/table/MedimoTbody';
import MedimoTr from '@/vue/components/general/table/MedimoTr';
import MedimoTh from '@/vue/components/general/table/MedimoTh';
import MedimoTd from '@/vue/components/general/table/MedimoTd';
// /pages/layouts
import MasterLayout from '@/vue/pages/__layouts__/__MasterLayout__';
// /
import MedimoAlert from '@/vue/components/general/MedimoAlert';
import MedimoNotification from '@/vue/components/general/MedimoNotification';
import MedimoAlertContainer from '@/vue/components/general/MedimoAlertContainer';
import MedimoBadge from '@/vue/components/general/MedimoBadge';
import MedimoNumberBadge from '@/vue/components/general/MedimoNumberBadge';
import MedimoSortButton from '@/vue/components/general/table/MedimoSortButton';
import MedimoButton from '@/vue/components/general/MedimoButton';
import MedimoConfirmButton from '@/vue/components/general/MedimoConfirmButton';
import MedimoButtonLink from '@/vue/components/general/MedimoButtonLink';
import MediModal from '@/vue/components/general/MediModal';
import MedimoDropdown from '@/vue/components/general/MedimoDropdown';
import MedimoDropdownButton from '@/vue/components/general/MedimoDropdownButton';
import MedimoDropdownItem from '@/vue/components/general/MedimoDropdownItem';
import MedimoLink from '@/vue/components/general/MedimoLink';
import MedimoLoader from '@/vue/components/general/MedimoLoader';
import MedimoLoaderOverlay from '@/vue/components/general/MedimoLoaderOverlay';
import MedimoPagination from '@/vue/components/general/MedimoPagination';
import MedimoTooltip from '@/vue/components/general/MedimoTooltip';
import MedimoTabs from '@/vue/components/general/MedimoTabs';

export default {
  components: {
    MedimoLayout,
    MedimoCheckbox,
    MedimoLabeledCheckbox,
    MedimoInput,
    MedimoLabeledInput,
    MedimoLabeledSelect2,
    MedimoLabeledTextarea,
    MedimoRadio,
    MedimoSelect,
    MedimoSelect2,
    MedimoVueSelect2,
    MedimoLabeledVueSelect2,
    MedimoValidatedVueSelect2,
    MedimoTextarea,
    MedimoTextEditor,
    MasterLayout,
    PageBlock,
    PageComponent,
    MedimoStyle,
    MedimoAlert,
    MedimoNotification,
    MedimoAlertContainer,
    MedimoBadge,
    MedimoNumberBadge,
    MedimoSortButton,
    MedimoButton,
    MedimoConfirmButton,
    MedimoButtonLink,
    MedimoLoader,
    MedimoLoaderOverlay,
    MedimoPagination,
    MedimoTooltip,
    MedimoDateTimePicker,
    MedimoValidatedDateTimePicker,
    MedimoLabeledDateTimePicker,
    MedimoDatePicker,
    MedimoValidatedDatePicker,
    MedimoLabeledDatePicker,
    MedimoForm,
    MedimoFormRow,
    MedimoFormColumn,
    MedimoValidatedInput,
    MedimoValidatedSelect,
    MedimoValidatedSelect2,
    MedimoValidatedTextarea,
    MedimoDataTable,
    MedimoExternalDataTable,
    MedimoLocalDataTable,
    MedimoValidatedCheckbox,
    MedimoTable,
    MedimoTbody,
    MedimoThead,
    MedimoTr,
    MedimoTh,
    MedimoTd,
    MediModal,
    MedimoDropdown,
    MedimoDropdownButton,
    MedimoDropdownItem,
    MedimoLink,
    MedimoTabs,
  },
};
</script>
