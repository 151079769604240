<template>
    <medimo-tr @triggered="showEditEpisodeModal">

        <medimo-td column_classes="col col-sm-1">
            <span>{{ episode.code }}</span>
        </medimo-td>

        <medimo-td column_classes="col col-sm-7">
            <span>{{ episode.title }}</span>
        </medimo-td>

        <medimo-td column_classes="col col-sm-2">
            <span>{{ parsedStartDate }}</span>
        </medimo-td>

        <medimo-td column_classes="col col-sm-2">
            <span>{{ parsedEndDate }}</span>
        </medimo-td>

    </medimo-tr>
</template>

<script>
import Dates from '@/vue/utility/dates';
import MedimoTd from '@/vue/components/general/table/MedimoTd';
import MedimoTr from '@/vue/components/general/table/MedimoTr';
import EditActionModal from './EditEpisodeModal';

export default {
  components: {
    EditActionModal,
    MedimoTd,
    MedimoTr,
  },
  emits: ['selected'],
  props: [
    'episode',
    'patient',
  ],

  mixins: [],

  data: function () {
    return {
      trigger_edit_action: false
    };
  },

  computed: {
    parsedStartDate() {
      return Dates.setDate(this.episode.start_date).dutchDateTime();
    },
    parsedEndDate() {
      return Dates.setDate(this.episode.end_date).dutchDateTime();
    }
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    showEditEpisodeModal() {
      this.$emit('selected');
    },
  },

  watch: {
    //
  }
};
</script>
