'use strict';

const ManualDosingSwitcher = require('/modules/manualdosing/ManualDosingSwitcher');
const moment = require("moment");

class ProtocolStatusTemplate {
  constructor(usedEpisodeNames) {
    this.usedEpisodeNames = usedEpisodeNames;
  }

  editStartStatusTemplate(listId, protocolId, formId, usageTypeSelectDisabled, switchToManualDosingSchemaDisabled, onSave, values, type) {
    let formType = 'start';
    if (typeof type !== 'undefined') formType = type;

    //Add event listeners for final save
    //Added this way because there can be another 'choose drug' popup
    window.$(document).off('medimo.saveStatusTemplate');
    window.$(document).on('medimo.saveStatusTemplate', onSave);

    //Add event listeners to init form
    //Added this way because there can be another 'choose drug' popup
    window.$(document).on('medimo.openStatusTemplate', () => {
      this._initiateStatusTemplate(this, usageTypeSelectDisabled, switchToManualDosingSchemaDisabled);
    });

    window.addAjaxLoader();
    let editStatusRoute = '/config/resource/druglist/druglist-protocol/create?template=status&listid='+listId+'&id='+protocolId+'&formType='+formType;

    if (values !== false) {
      const drugId = values.drugId;
      editStatusRoute += '&gnmid='+drugId;
    }

    window.ajax.get(editStatusRoute, {
      success: (result) => {
        window.processAjaxUpdate(result);
        window.$('.medimomodal').css('width', '615px');

        //We set this trigger insided insertValuesToForm
        //So we have to unset it before setting it again
        window.$(document).off('medimo.manualDosingVue.loaded');

        if (values !== false) {
          this.insertValuesToForm(formId, values);
        } else {
          this._addEpisodeSelectChangeListener();
        }
      }
    });
  }

  _initiateStatusTemplate(vm, usageTypeSelectDisabled, switchToManualDosingSchemaDisabled) {
    const formSelector = '.medimomodal';

    vm.$amountsFull = window.$(formSelector + ' #amountsfull');
    vm.$prescribeOptionsBox = window.$(formSelector + ' #additionalPrescribeOptions');
    vm.$infusionBox = window.$(formSelector + ' #infusionAmountDiv');
    vm.$daysDurationWrapper = window.$(formSelector + ' #daysDurationWrapper');
    vm.$daysDuration = window.$(formSelector + ' #daysDuration');

    //If this item has children, then usageTypeSelectDisabled === true
    //Because the children allowed is bases on the usageType, eg
    //A temporary provisional cannot have a change- or stop provisional as follow up
    if (usageTypeSelectDisabled === true) {
      window.$(formSelector + ' #radio_usageType_T').attr('disabled',true);
      window.$(formSelector + ' #radio_usageType_C').attr('disabled',true);
      window.$(formSelector + ' #radio_usageType_C').attr('title', 'Omdat er een opvolger is kan deze optie niet worden aangepast');
      window.$(formSelector + ' #radio_usageType_T').attr('title', 'Omdat er een opvolger is kan deze optie niet worden aangepast');
    } else {
      window.$(formSelector + ' #radio_usageType_T').attr('disabled',false);
      window.$(formSelector + ' #radio_usageType_C').attr('disabled',false);
      window.$(formSelector + ' #radio_usageType_C').attr('title', '');
      window.$(formSelector + ' #radio_usageType_T').attr('title', '');
    }

    if (switchToManualDosingSchemaDisabled === true) {
      window.$(formSelector + ' #addManualDosingLink').hide();
    }

    vm._initiateDosageIntervalSelect(formSelector);
    vm._initateDosingUnitsSelect(formSelector);
    vm._initateDispenseUnitsSelect(formSelector);
    vm._initiateDaysDuration(formSelector);
    vm._initiateAdministrationRouteSelect(formSelector);
  }

  _addEpisodeSelectChangeListener() {
    let vm = this;
    window.$('#episodesMultiSelect').on('change', function(e) {
      const items = window.$(this).select2('data');
      items.forEach(function (item) {
        vm.usedEpisodeNames[item.id] = item.text;
      });
    });
  }

  _initiateAdministrationRouteSelect(formSelector) {
    this.$administrationRoute =  window.$(formSelector + ' [name="administrationRoute"]');
    this.$administrationRoute.select2({minimumResultsForSearch: 0, placeholder: "Selecteer toedieningsweg"});
  }

  _initiateDosageIntervalSelect(formSelector) {
    this.$dosageInterval = window.$(formSelector + ' [name="dosageInterval"]');
    this.$dosageInterval.select2({minimumResultsForSearch: 0, placeholder: ""});

    let vm = this;

    window.$('select[name="dosageInterval"]').on('change', function(e) {
      const item = window.$(this).select2('data');
      window.$(this).select2('close');
      window.$(formSelector + ' #dosageIntervalText').val(item[0].text);
      if (window.$(item[0].element).data('infusion')) {
        vm._activateInfusionView();
      } else if (!window.$('#infusionAmountDiv').hasClass('hidden')) {
        vm._deactiveateInfusionView();
      }
    });
  }

  _initateDosingUnitsSelect(formSelector) {
    this.$dosingUnits = window.$(formSelector + ' [name="dosingUnits"]');
    this.$dosingUnits.select2({minimumResultsForSearch: Infinity, placeholder: ""});
  }

  _initateDispenseUnitsSelect(formSelector) {
    this.$dispenseUnit = window.$(formSelector + ' [name="dispenseUnit"]');
    this.$dispenseUnit.select2({minimumResultsForSearch: Infinity, placeholder: ""});
  }

  _initiateDaysDuration(formSelector) {
    let vm = this;

    // On initial load, a formulary may have set the #radio_usageType_T to T, in which case, we need to enable it here
    // This is only needed on initialization when the blade sets the value. After that, the JS takes over
    if (window.$(formSelector + ' #radio_usageType_T')[0]?.hasAttribute('checked')) {
      vm._showDaysDuration();
    }

    window.$(formSelector + ' [name=usageType]').change(function() {
      if (this.value !== 'T') {
        vm._hideDaysDuration();
      } else {
        vm._showDaysDuration();
      }
    });
  }

  insertValuesToForm(formId, values) {
    const formSelector = '.medimomodal';

    for (let name in values) {
      const value = values[name];

      name = name.replace('[', '\\[');
      name = name.replace(']', '\\]');

      if (name === 'dosageInterval') {
        this.$dosageInterval.val(value).trigger("change");
      } else if (name === 'episodes') {
        const episodeCodes = value;
        this.$episodeSelect = window.$(formSelector + ' #episodesMultiSelect');
        let vm = this;
        if (Array.isArray(episodeCodes)) {
          episodeCodes.forEach(function (value) {
            const option = new Option(vm.usedEpisodeNames[value], value, true, true);
            vm.$episodeSelect.append(option).trigger("change");
          });
        }
        vm._addEpisodeSelectChangeListener();
      } else if (name ==='dosingUnits') {
        this.$dosingUnits.val(value).trigger("change");
      } else if (name ==='dispenseUnit') {
        this.$dispenseUnit.val(value).trigger("change");
      } else if (name ==='startMoment') {
        window.$('.medimomodal-content' + ' #radio_startMoment_1').prop('checked', false);
        window.$('.medimomodal-content' + ' #radio_startMoment_2').prop('checked', false);
        window.$('.medimomodal-content' + ' #radio_startMoment_3').prop('checked', false);
        window.$('.medimomodal-content' + ' #radio_startMoment_4').prop('checked', false);
        window.$('.medimomodal-content' + ' #radio_startMoment_'+value).prop('checked', true);
      } else if (name === 'whenNeeded' || name === 'continuousSchema' || name === 'other_prescriber' || name === 'external_supply') {
        if (value === 'on') {
          window.$('.medimomodal-content' + ' [name="'+name+'"]').prop('checked', true);
        } else {
          window.$('.medimomodal-content' + ' [name="'+name+'"]').prop('checked', false);
        }
      } else if (name ==='usageType') {
        window.$('.medimomodal-content' + ' #radio_usageType_C').prop('checked', false);
        window.$('.medimomodal-content' + ' #radio_usageType_T').prop('checked', false);
        window.$('.medimomodal-content' + ' #radio_usageType_'+value).prop('checked', true);
        if (value === 'T') {
          this._showDaysDuration();
        }
      } else if (name === 'administrationRoute') {
        this.$administrationRoute.val(value).trigger('change');
      } else {
        window.$('.medimomodal-content' + ' [name="' + name + '"]').val(value);
      }
    }

    // Uncheck standard times if not given
    if (values['useStandardTimes'] !== 'on') {
      window.$('.medimomodal-content' + ' [name="useStandardTimes"]').prop('checked', false);
    }

    if (values['manualdosing-enabled']) {

      window.$(document).on('medimo.manualDosingVue.loaded', function () {

        // Deze kan later helemaal weg
        // const manualDosingSwitcher = new ManualDosingSwitcher();
        // manualDosingSwitcher.enableManualDosingView();

        //These triggers are cached in the Manualdosing Vue component in .. js/scripts/manualdosing-init.js
        window.$(document).trigger('medimo.updateVueManualSchema', {'vueState': values['manualdosing-values']});
        window.$(document).trigger('medimo.updateStartDateTime', {'startDateTime': moment()});
      });
    }
  }

  _hideDaysDuration() {
    this.$daysDurationWrapper.hide();
    this.$daysDuration.prop('disabled', true);
  }

  _showDaysDuration() {
    this.$daysDuration.prop('disabled', false);
    this.$daysDurationWrapper.show();
  }

  findValue(values, name) {
    return values.find(function(item) { return item.name===name; });
  }

  _activateInfusionView() {
    this.$amountsFull.slideUp();
    this._hideElement(this.$amountsFull);
    this._showElement(this.$infusionBox);
    this.$prescribeOptionsBox.css('height', 75);
  }

  _deactiveateInfusionView() {
    this._showElement(this.$amountsFull);
    this.$amountsFull.slideDown();
    this._hideElement(this.$infusionBox);
    this.$prescribeOptionsBox.css('height', 200);
  }

  _hideElement($element) {
    $element.addClass('hidden');
  }

  _showElement($element) {
    $element.removeClass('hidden');
  }
}

if (typeof window === 'object') {
  window.ProtocolStatusTemplate = ProtocolStatusTemplate;
}

if (typeof module === 'object' && typeof module.exports !== 'undefined') {
  module.exports = ProtocolStatusTemplate;
}
