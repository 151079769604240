import Times from '@/vue/utility/times';

export default {
  decodeQuantity: function(quantity) {

    if (!quantity) {
      quantity = '';
    }

    const quantityParts = quantity.split('~');

    const times = [
      {time: "", value: "0"},
      {time: "", value: "0"},
      {time: "", value: "0"},
      {time: "", value: "0"},
      {time: "", value: "0"},
      {time: "", value: "0"},
    ];

    if (quantity === '') {
      return times;
    }

    for (let i=0; i<quantityParts.length; i++) {
      // set default value
      // times[i] = defaultTimes[i];

      // overwrite with custom value
      const parts = quantityParts[i].split('^');
      times[i].value = parts[0];
      times[i].time = parts[1];
    }

    return times;
  },
  encodeQuantity: function(times) {
    let quantity = '';

    times.forEach(function (time) {
      if (time.time !== "" && time.value !== '0' && time.value !== 0 && time.value !== '') {
        quantity += time.value + '^' + Times.encodeTime(time.time) + '~';
      }
    });
    return quantity.substring(0, quantity.length - 1);
  }

};
