<template>
  <div @click.stop="$emit('click')" :class="'d-inline schema-button clickable ' + (badge ? 'badge badge-sm' : '')">
    <fa-icon :icon="'fa-solid ' + icon" /> Schema
  </div>
</template>

<script>
export default {
  props: {
    'show_all': {required: true},
    'badge': {default: true},
  },
  emits: ['click'],
  computed: {
    icon() {
      return this.show_all ? 'fa-caret-up' : 'fa-caret-down';
    }
  }
};
</script>

<style lang="scss" scoped>
@import "/variables";

.schema-button {
  white-space: nowrap;
}

.schema-button.badge.badge-sm {
  color: $primary;
  font-size: 10px;
  background-color: transparent;
  border: 1px solid $primary;
}

</style>
