import Utility from '@/vue/utility/utility';
import BaseModule from '@/vue/store/modules/base_module';

// initial state
// shape: [{ id, quantity }]
const state = {
  ...BaseModule.state,

  withdrawals: [],
};

// getters
const getters = {
  ...BaseModule.getters,
  withdrawals_by_ward(state, getters, rootState, rootGetters) {
    return (ward_id) => {
      // Kan op dit punt niet omdat we met een discrepantie zitten dat de StockResource enkel de locatie ID mee kan sturen
      // terwijl we in de frontend en in de dispense_id enkel werken met de ward_id.
      // Dit vang ik nu op door altijd te resetten als de DispenseModal geopend wordt, maar niet ideaal.
      return state.withdrawals;
    };
  },
  withdrawal_amount(state, getters, rootState, rootGetters) {
    return (withdrawal_id) => {
      const withdrawal = state.withdrawals.filter(withdrawal => {
        return withdrawal.id === withdrawal_id;
      });
      return withdrawal.length ? parseFloat(withdrawal[0].amount) : 0;
    };
  }
};

// actions
const actions = {
  ...BaseModule.actions,
  // dummy_action ({state, commit, dispatch, getters, rootState}, payload) {
  //     //
  // }
};

// mutations
const mutations = {
  ...BaseModule.mutations,
  reset_withdrawals(state, drug) {
    state.withdrawals = [];
  },
  add_to_withdrawals(state, drug) {
    drug = Utility.deep_clone(drug);
    const new_data = state.withdrawals;

    // If an model with that ID already exists, we just replace it
    if (Utility.id_already_exists(state.withdrawals, drug.id)) {
      const existing_index = Utility.find_index_of_matching_element(state.withdrawals, 'id', drug.id);
      state.withdrawals[existing_index] = drug;
    }
    // If not, we add it to the top.
    else {
      new_data.unshift(drug);
      state.withdrawals = new_data;
    }
  },
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
