'use strict';

class ManualDosingSwitcher {
  _getTimesandAmounts() {
    const defaultTimes = ['08:00', '10:00', '12:00', '13:00', '17:00', '21:00'];
    const defaultAmounts = [0,0,0,0,0,0];
    const data = { times: defaultTimes, amounts: defaultAmounts };

    for (const count in [0,1,2,3,4,5]) {
      data.amounts[count] = parseFloat(window.$("input[name='time_amount["+count+"]']").val().replace(',', '.') );
    }

    return data;
  }

  enableManualDosingView() {
    const timesAndAmounts = this._getTimesandAmounts();

    window.$('[name="dosageInterval"]').hide();

    window.$('.show-on-manualdosing').show();
    $(document).trigger('medimo.manualDosingVue.mount');

    window.$('.show-on-normal-dosing').hide();

    window.$('#manualdosing-enabled').val(1);
    window.$('#manualdosing').show();

    window.$('#additionalPrescribeOptions').css('width', '500px');
    window.$('.form-status-checkboxes').css('margin-left', '0px');

    window.$(document).trigger('medimo.useFlexDate');
    window.$(document).trigger('medimo.vueSchemaUpdateTimes', {'timesAndAmounts': timesAndAmounts});
  }
}

if (typeof window === 'object') {
  window.ManualDosingSwitcher = ManualDosingSwitcher;
}

if (typeof module === 'object' && typeof module.exports !== 'undefined') {
  module.exports = ManualDosingSwitcher;
}
