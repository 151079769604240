import {PharmaceuticalProduct} from '@/domain/models/PharmaceuticalProduct';
import {CodeObject} from '@/domain/models/CodeObject';

export class GeneratedPharmaceuticalProduct { 
  public id: number;
  public hpk: string;
  public gpk: string;
  public prk: string;
  public zi: string;
  public description: string;
  public isMagistralPreparation: string;
  public productCode: CodeObject[];
  public drugId: number | null;

  constructor(id: number, hpk: string, gpk: string, prk: string, zi: string, description: string, isMagistralPreparation: string, productCode: CodeObject[], drugId: number | null = null) {
    this.id = id;
    this.hpk = hpk;
    this.gpk = gpk;
    this.prk = prk;
    this.zi = zi;
    this.description = description;
    this.isMagistralPreparation = isMagistralPreparation;
    this.productCode = productCode;
    this.drugId = drugId;
  }

  public static fromJson(json: unknown): PharmaceuticalProduct {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new PharmaceuticalProduct(
      parsedJson['id'] as number,
      parsedJson['hpk'] as string,
      parsedJson['gpk'] as string,
      parsedJson['prk'] as string,
      parsedJson['zi'] as string,
      parsedJson['description'] as string,
      parsedJson['isMagistralPreparation'] as string,
      parsedJson['productCode'] ? parsedJson['productCode'].map((e: unknown) => CodeObject.fromJson(e)) : null,
      parsedJson['drugId'] as number,
    );
  }

  public static multipleFromJson(json: unknown): PharmaceuticalProduct[] {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    const items: PharmaceuticalProduct[] = [];
    for (const item of parsedJson) {
      items.push(this.fromJson(item));
    }
    return items;
  }
}
