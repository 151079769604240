<template>
  <medi-modal class="medication-guard-chat-modal"
              :title="title"
              :loading="loading"
              @closed="$emit('closed')"
              v-bind="$attrs">
    <medimo-form>

      <medimo-form-row class="mb-3">
        <medimo-form-column>
          <medimo-tooltip extra_classes="w-100" content="Informeer de anderen" :delay="100" :showOnClick="false">
            <medimo-button id="btnToggleChat" icon="icon-b-chat" size="sm" :type="informThem ? 'primary' : 'light'" @click="informThem = ! informThem">
            </medimo-button>
          </medimo-tooltip>
        </medimo-form-column>
        <medimo-form-column>
          <medimo-tooltip extra_classes="w-100" content="Maak een notitie, ook zichtbaar voor anderen" :delay="100" :showOnClick="false">
            <medimo-button id="btnToggleNote" icon="icon-recipe" size="sm" :type="! informThem ? 'primary' : 'light'" @click="informThem = ! informThem">
            </medimo-button>
          </medimo-tooltip>
        </medimo-form-column>


        <!-- spacer -->
        <medimo-form-column column_classes="col-2 ml-auto"></medimo-form-column>

        <medimo-form-column column_classes="col-2" v-if="showPharmacy">
          <medimo-tooltip extra_classes="w-100" content="Wijzig deelname apotheek" :delay="100" :showOnClick="false">
            <medimo-button icon="icon-single-02" size="sm" class="btn-block btn-pharmacy" :type="Pharmacy ? 'primary' : 'light'" @click="Pharmacy = ! Pharmacy">
              Apotheek
            </medimo-button>
          </medimo-tooltip>
        </medimo-form-column>
        <medimo-form-column column_classes="col-2" v-if="showPhysician">
          <medimo-tooltip extra_classes="w-100" content="Wijzig deelname arts" :delay="100" :showOnClick="false">
            <medimo-button icon="icon-single-02" size="sm" class="btn-block btn-physician" :type="Physician ? 'primary' : 'light'" @click="Physician = ! Physician">
              Arts
            </medimo-button>
          </medimo-tooltip>
        </medimo-form-column>
        <medimo-form-column column_classes="col-2" v-if="showCare">
          <medimo-tooltip extra_classes="w-100" content="Wijzig deelname zorg" :delay="100" :showOnClick="false">
            <medimo-button icon="icon-multiple-19" size="sm" class="btn-block btn-care" :type="Care ? 'primary' : 'light'" @click="Care = ! Care">
              Zorg
            </medimo-button>
          </medimo-tooltip>
        </medimo-form-column>
        <medimo-form-column>
          <medimo-tooltip extra_classes="w-100" content="Voeg extra deelnemers toe" :delay="100" :showOnClick="false">
            <medimo-button icon="icon-i-add" size="sm"
                           :type="selectExtraParticipants ? 'primary' : 'light'"
                           @click="selectExtraParticipants = ! selectExtraParticipants"
            >
            </medimo-button>
          </medimo-tooltip>
        </medimo-form-column>
      </medimo-form-row>

      <!-- Extra participants -->
      <medimo-form-row class="mt-2" v-if="selectExtraParticipants">
        <medimo-form-column column_classes="col">
          <medimo-select2 placeholder="Selecteer extra deelnemers"
                          :ajaxCall="extraParticipantsAjaxCallUrl"
                          :multiple="true"
                          @select="selectParticipant"
                          @unselect="unSelectParticipant"
          ></medimo-select2>
        </medimo-form-column>
      </medimo-form-row>

      <medimo-form-row class="mt-2">
        <medimo-form-column column_classes="col">
          <medimo-validated-input placeholder="Uw onderwerp" name="subject" v-model="subject">
          </medimo-validated-input>
        </medimo-form-column>
      </medimo-form-row>

      <medimo-form-row class="mt-2">
        <medimo-form-column column_classes="col">
          <medimo-validated-textarea placeholder="Uw bericht" name="message" :inline="false" v-model="message">
          </medimo-validated-textarea>
        </medimo-form-column>
      </medimo-form-row>

      <medimo-form-row class="mt-3">
        <medimo-form-column v-if="patient.userIsDirectCareGiver">
          <medimo-tooltip extra_classes="w-100" content="Gemarkeerd als prive" :delay="100" :showOnClick="false">
            <medimo-button id="btnPrivacy" disabled icon="fa-solid fa-key" size="sm" :type="privacy ? 'primary' : 'light'" @click="privacy = ! privacy">
            </medimo-button>
          </medimo-tooltip>
        </medimo-form-column>
        <medimo-form-column>
          <medimo-tooltip extra_classes="w-100" content="Vraag expliciet om een reactie" :delay="100" :showOnClick="false">
            <medimo-button id="btnRequestResponse" size="sm" icon="fa-solid fa-reply-all" :type="requestResponse ? 'primary' : 'light'" @click="requestResponse = ! requestResponse">
            </medimo-button>
          </medimo-tooltip>
        </medimo-form-column>
        <medimo-form-column>
          <medimo-tooltip extra_classes="w-100" content="Bericht is urgent!" :delay="100" :showOnClick="false">
            <medimo-button id="btnPriority" icon="fa-solid fa-circle-exclamation" size="sm" :type="priority ? 'primary' : 'light'" @click="priority = ! priority" />
          </medimo-tooltip>
        </medimo-form-column>
        <medimo-form-column>
          <medimo-tooltip extra_classes="w-100" content="Stel een datum in wanneer u graag uiterlijk een reactie ontvangt." :delay="100" :showOnClick="false">
            <medimo-button id="btnCalendar" icon="fa-light fa-calendar-days" size="sm" :type="selectValidUntil ? 'primary' : 'light'" @click="selectValidUntil = ! selectValidUntil">
            </medimo-button>
          </medimo-tooltip>
        </medimo-form-column>
      </medimo-form-row>

      <medimo-form-row class="mt-3">
        <medimo-form-column v-if="selectValidUntil">
          <medimo-labeled-date-picker v-model="validUntil" name="validUntil" placeholder="Selecteer datum" label="Reactie voor:"></medimo-labeled-date-picker>
        </medimo-form-column>
      </medimo-form-row>

    </medimo-form>

    <template v-slot:action-buttons>
      <medimo-button type="button" class="send-button btn btn-primary" @click="storeChatMessage">
        Opslaan
      </medimo-button>
      <medimo-button type="button" class="btn-outline-primary" @click="$emit('closed')">
        Annuleren
      </medimo-button>
    </template>
  </medi-modal>
</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';

export default {
  inheritAttrs: false,
  emits: ['closed','stored'],

  props: {
    'patient_id': { required: true },
    'trigger_model': { default: '' },
    'trigger_model_id': { default: null },
    'chat_subject': {default: ''}
  },

  mixins: [BaseComponentsMixin],

  data: function () {
    return {
      loading: false,

      informThem: 1,
      Pharmacy: 0,
      Physician: 0,
      Care: 0,

      showPharmacy: true,
      showPhysician: true,
      showCare: true,

      selectExtraParticipants: false,
      extraParticipants: [], // ["145180:Pharmacy"]

      subject: '',
      message: '',
      requestResponse: 0,
      priority: 0,
      privacy: 0,
      triggerModel: '',
      triggerModelId: null,

      selectValidUntil: false,
      validUntil: '', // 27-05-2020
    };
  },

  computed: {
    type() {
      return this.informThem ? 'het bericht' : 'de notitie';
    },
    title() {
      return this.informThem ? 'Nieuw bericht' : 'Nieuwe notitie';
    },
    patientId() {
      return this.patient_id;
    },
    patient() {
      return this.$store.getters['patients/find'](this.patient_id);
    },
    extraParticipantsAjaxCallUrl() {
      return '/config/resource/select2/view-prescription-users-without-thirdparty?patientId=' + this.patientId;
    },
    current_user() {
      return this.$store.state.current_user.data;
    }
  },

  mounted() {
    this.initForm();
    this.setTriggerModel();
  },

  methods: {
    storeChatMessage() {
      this.loading = true;

      const vm = this;

      this.$store.dispatch('patient_chats/store', {
        patient_id: this.patient_id,
        data: {
          informThem: this.informThem ? 1 : 0,
          Pharmacy: this.Pharmacy ? 1 : 0,
          Physician: this.Physician ? 1 : 0,
          Care: this.Care ? 1 : 0,
          extraParticipants: this.extraParticipants,
          subject: this.subject,
          message: this.message,
          requestResponse: this.requestResponse ? 1 : 0,
          priority: this.priority ? 1 : 0,
          privacy: this.privacy ? 1 : 0,
          validUntil: this.validUntil,
          triggerModel: this.triggerModel,
          triggerModelId: this.triggerModelId,
        }
      })
        .then((response) => {
          this.loading = false;

          // update active chats
          const data = response.data.data;
          data.patient_id = this.patient_id;
          this.$store.dispatch('patients/update_active_chat_count', data, { root: true});

          // notification
          if (response.data.data) {
            this.$store.dispatch('notifications/add_alert', {
              type: 'success',
              message: 'Succesvol verzonden',
            });
          }

          // reset form values
          vm.initForm();

          // notify parent
          vm.$emit('stored');
        }).catch(() => {
          this.loading = false;
        });
    },
    selectParticipant(participant) {
      this.extraParticipants.push(participant.id);
    },
    unSelectParticipant(participant) {
      this.extraParticipants = this.extraParticipants.filter(extraParticipant => {
        return extraParticipant !== participant.id;
      });
    },
    setTriggerModel() {
      this.triggerModel = this.trigger_model;
      this.triggerModelId = this.trigger_model_id;
    },
    initForm() {
      this.resetParticipants();
      this.resetInputValues();
      this.resetToggles();
    },
    resetInputValues() {
      this.subject = this.chat_subject;
      this.message = '';
    },
    resetToggles() {
      this.requestResponse = 0;
      this.priority = 0;
      this.privacy = 0;
      this.selectValidUntil = false;
    },
    resetParticipants() {
      if (this.current_user.entityType === 'Pharmacy') {
        this.Physician = 1;
        this.showPharmacy = false;
      } else if (this.current_user.entityType === 'Physician') {
        this.Pharmacy = 1;
        this.showPhysician = false;
      } else if (this.current_user.entityType === 'Location' || this.current_user.entityType === 'Ward') {
        this.Physician = 1;
        this.Pharmacy = 1;
        this.showCare = false;
      }
    },
  },

  watch: {
    selectValidUntil(selectValidUntil) {
      // Reset validUntil when it's unselected
      if (!selectValidUntil) {
        this.validUntil = '';
      }
    },
  }
};
</script>
<style lang="scss">
.medication-guard-chat-modal {
  #btnToggleChat.btn.btn-sm i.medimo-icon.icon-b-chat {
    top: 4px;
  }
  #btnToggleNote.btn.btn-sm i.medimo-icon.icon-recipe {
    top: 3px;
  }

  .fa-reply-all {
    top: 1px;
    position: relative;
  }
}
</style>
