import {Location} from '@/domain/models/Location';

export class GeneratedLocation { 
  public id: number;
  public name: string;
  public poli: string;
  public thuis: string;
  public moduleTDR: string;
  public moduleAIS: string;
  public tdl: string;
  public totaal: string;
  public nfreden: string;
  public tdrOnlyGiveAssigned: string;

  constructor(id: number, name: string, poli: string, thuis: string, moduleTDR: string, moduleAIS: string, tdl: string, totaal: string, nfreden: string, tdrOnlyGiveAssigned: string) {
    this.id = id;
    this.name = name;
    this.poli = poli;
    this.thuis = thuis;
    this.moduleTDR = moduleTDR;
    this.moduleAIS = moduleAIS;
    this.tdl = tdl;
    this.totaal = totaal;
    this.nfreden = nfreden;
    this.tdrOnlyGiveAssigned = tdrOnlyGiveAssigned;
  }

  public static fromJson(json: unknown): Location {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new Location(
      parsedJson['id'] as number,
      parsedJson['name'] as string,
      parsedJson['poli'] as string,
      parsedJson['thuis'] as string,
      parsedJson['moduleTDR'] as string,
      parsedJson['moduleAIS'] as string,
      parsedJson['tdl'] as string,
      parsedJson['totaal'] as string,
      parsedJson['nfreden'] as string,
      parsedJson['tdrOnlyGiveAssigned'] as string,
    );
  }

  public static multipleFromJson(json: unknown): Location[] {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    const items: Location[] = [];
    for (const item of parsedJson) {
      items.push(this.fromJson(item));
    }
    return items;
  }
}
