<template>
  <div style="height: 100%; width: 100%; position: absolute; top:0; left:0;" v-if="showLoader" id="medimo-loader">
    <div class="d-flex" :style="overlayStyle">
      <medimo-loader :size="size">
        <span class="text-medimo" v-if="messageVisible">{{ loadingMessage }}</span>
      </medimo-loader>
    </div>
  </div>
</template>

<script>

import MedimoLoader from './MedimoLoader';

export default {
  components: {
    MedimoLoader
  },

  props: {
    loading: {},
    size: {default: 32},
    top: {default: 0},
    transition: {default: 'fade'},
    message: {default: 'Het kan soms iets langer duren'},
    showErrorMessageAfter: {default: 15000},
    showBackground: {default: true},

    // Na 30 seconden verwijderen we by default de loader weer.
    // Het risico is dat gebruikers denken dat het klaar is als er een hele lange request draait
    // Maar het andere risico is dat een loader blijft staan als een request afgebroken is en de code
    // niet voorziet in het automatisch weg halen. Dit laatste gebeurde te vaak.
    // Nu alle uncaughtExceptions ook een Sentry sturen
    // en we sowieso geen lange verwerkingstijden van requests verwachten is autoHiden preferabel
    autoHideLoader: {
      default: 60000
    }
  },

  mixins: [],

  data: function () {
    return {
      messageVisible: false,
      loadingMessage: '',

      // Deze bepaald met wat extra logica of de loader zichtbaar is.
      // In het verleden kon het zo zijn dat de developer vergat de loader weg te halen
      // waardoor gebruikers niet meer konden interacteren met de applicatie.
      // Met deze waarde + een default time-out
      showLoader: false,
      requestTimeoutHandler: null,
    };
  },

  computed: {
    overlayStyle() {
      return {
        backgroundColor: this.showBackground ? 'rgba(255,255,255,0.5)' : 'transparent',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: this.top,
        left: 0,
        zIndex: 999,
      };
    }
  },

  created() {
    //
  },

  beforeUnmount() {
    this.clearLoader();
  },

  mounted() {
    this.loadingMessage = this.message;

    // In de pipeline werkt de `immediate` niet van de watcher, dus is deze work-around nodig:
    this.initiateLoader(this.loading);
  },

  methods: {
    activateMessage() {
      if (this.loading) {
        this.messageVisible = true;
      }
    },
    activateErrorMessage() {
      // Deze triggeren we als hij na this.showErrorMessageAfter milliseconden nog steeds aan het laden is.
      if (this.loading) {
        this.loadingMessage = 'Er lijkt iets mis gegaan te zijn, refresh de pagina en probeer opnieuw';
      }
    },
    initiateLoader(loading) {
      this.showLoader = loading;

      if (loading === false) {
        this.messageVisible = false;
      } else {
        // We wachten heel even een paar seconden voordat we het bericht activeren,
        // en als hij dan nog steeds aan het laden is, tonen we het
        this.messageTimeoutHandler = setTimeout(() => {
          this.activateMessage();
        }, this.showErrorMessageAfter);
        // We wachten 60 seconden
        // en als hij dan nog steeds aan het laden is, tonen we de error message
        this.requestTimeoutHandler = setTimeout(() => {
          if (this.loading) {
            this.showLoader = false;
            if (process.env.APP_ENV === 'development' || process.env.APP_ENV === 'testing') {
              this.$root.handleError('De handeling is na ' + (this.autoHideLoader / 1000) + ' seconden nog steeds actief. Dit is te lang. Het development team is hiervan op de hoogte gesteld.');
            }

            this.sendSentry();
          }
        }, this.autoHideLoader);
      }
    },
    clearLoader() {
      this.showLoader = false;
      this.messageVisible = false;
      clearTimeout(this.messageTimeoutHandler);
      clearTimeout(this.requestTimeoutHandler);
    },
    debugGetVueParentComponentsStack() {
      let count = 0;
      let parent = this.$parent;
      const parentComponentNames = [];
      return parentComponentNames;
      while (count <= 24) {
        // There are cases where the parent will be undefined, when that is so, just break the loop and show the warning.
        if (!parent) {
          break;
        }

        parentComponentNames.push(parent.$options._componentTag);

        parent = parent.$parent;
        count++;
      }
      return parentComponentNames;
    },
    sendSentry() {
      // Puur tijdelijk voor de debug stack:
      const parentComponentNames = this.debugGetVueParentComponentsStack();

      const sentryId = this.$root.sendSentry('Een MedimoLoaderOverlay is na ' + (this.autoHideLoader / 1000) + ' seconden nog steeds actief en is automatisch uitgeschakeld op: ' + this.$route.path, null, {
        parentComponentNames: parentComponentNames,
      });
    }
  },

  watch: {
    loading(value) {
      if (value && !this.showLoader) {
        this.initiateLoader(value);
      } else if (!value) {
        this.clearLoader();
      }
    },
    showLoader(value) {
      //
    }
  }
};
</script>
