<template>
  <div class="card border-top-0">
    <div class="card-header clickable unselectable" @click="open = !open">
      {{ title }}
    </div>
    <div class="card-body" v-show="open">
      <slot></slot>
    </div>
  </div>
</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';

export default {
  components: {},

  props: {
    title: {required: true},
    openPropertyName: {required: true},
  },

  mixins: [BaseComponentsMixin],

  data: function () {
    return {
      //
    };
  },

  computed: {
    open: {
      get() {
        return this.$store.state.settings.navigation.adminPanel[this.openPropertyName];
      },
      set(value) {
        this.$store.commit('settings/navigation/setAdminPanel', {
          property: this.openPropertyName, value: value
        });
      }
    },
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    //
  },

  watch: {
    //
  }
};
</script>
