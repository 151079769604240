import BaseModule from '@/vue/store/modules/base_module';

const state = {
  ...BaseModule.state,
  search_endpoint: '/api/v1/logs/tdr-event-queue',
  quick_filters: {},
  sort_columns: [],
};

const getters = {...BaseModule.getters};
const actions = {...BaseModule.actions};
const mutations = {...BaseModule.mutations};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
