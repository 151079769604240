<template>
  <div class="row action-buttons">
    <div class="text-primary" v-bind:class="{'ml-auto': currentlyOn('lg+')}" v-if="! currentlyOnStatusPage">
      <medimo-button-link class="medicatie-button" @click.prevent="show_modal_statusoverview++" icon="fa-kit fa-capsule">Medicatie</medimo-button-link>
    </div>
    <div class="text-primary medicatie-button" v-bind:class="{'ml-auto': currentlyOn('lg+')}" v-if="currentlyOnStatusPage">
      <medimo-button-link icon="" @click="trigger_copy++">
        <copy-print-status-table-buttons :container_component="$parent.$parent.$parent" :trigger="trigger_copy">Medicatie</copy-print-status-table-buttons>
      </medimo-button-link>
    </div>
    <!--
        This one has ml-auto when the previous one doesn't exist so the buttons don't
        Go out of whack
     -->
    <div class="text-primary" v-if="patient.authority.canViewLabResults">
      <medimo-button-link :to="''"
                          @click="show_readings_modal = true"
                          :badge_count="patient.labCount"
                          icon="fa-solid fa-chart-simple">Meetwaarden
      </medimo-button-link>
    </div>

    <!--
        This one has ml-auto when the previous ones don't
     -->
    <template v-if="showChatsAndActions">
      <div class="text-primary">
        <medimo-button-link icon="fa-regular fa-clipboard-check" @click="show_modal_actions = true" :to="''" :badge_count="patient.assignedActionsCount">Acties</medimo-button-link>
      </div>
      <div class="text-primary" id="patient-info-messages-button">
        <medimo-button-link icon="fa-duotone fa-messages" @click="show_modal_chats = true" :to="''" :badge_count="patient.activeChats.count">Berichten</medimo-button-link>
      </div>
    </template>

    <chats-data-table-modal
        v-if="show_modal_chats"
        :patient="patient"
        @closed="show_modal_chats = false"
    ></chats-data-table-modal>

    <actions-data-table-modal
        v-if="show_modal_actions"
        :patient="patient"
        @closed="show_modal_actions = false">
    </actions-data-table-modal>

    <status-overview-modal
        v-if="show_modal_statusoverview"
        @closed="show_modal_statusoverview = false"
        :patient="patient">
    </status-overview-modal>

    <reading-data-table-modal
        v-if="show_readings_modal"
        :patient="patient"
        @closed="show_readings_modal = false">
    </reading-data-table-modal>
  </div>
</template>

<script>

import MedimoButtonLink from '@/vue/components/general/MedimoButtonLink';
import UsesMediaQueries from '@/vue/mixins/UsesMediaQueries';
import StatusOverviewModal from '@/vue/components/status/status-overview-modal/StatusOverviewModal';
import ActionsDataTableModal from './actions/ActionsDataTableModal';
import ReadingDataTableModal from './readings/ReadingDataTableModal';
import ChatsDataTableModal from './chats/ChatsDataTableModal';
import CopyPrintStatusTableButtons from '@/vue/components/status/tables/buttons/CopyPrintStatusTableButtons';

export default {
  components: {
    CopyPrintStatusTableButtons,
    ChatsDataTableModal,
    ReadingDataTableModal,
    MedimoButtonLink,
    StatusOverviewModal,
    ActionsDataTableModal
  },

  props: [
    'patient'
  ],

  mixins: [
    UsesMediaQueries
  ],

  data: function () {
    return {
      show_modal_statusoverview: 0,
      show_modal_actions: 0,
      show_modal_chats: 0,
      show_readings_modal: false,
      trigger_copy: 0,
    };
  },

  computed: {
    showChatsAndActions() {
      return this.$store.state.current_user.data.userType !== 'thirdParty' || this.$store.state.current_user.data.is_fabber;
    },
    currentlyOnStatusPage() {
      // We use this one to hide the Medication button on the status page
      return this.$route.name === 'status-patient_id';
    },
    actions() {
      return this.$store.getters['patients/get_active_actions'](this.patient.id);
    },
    actionsCount() {
      return this.actions.length;
    },
  },

  created() {
    //
  },

  mounted() {
    if (this.$route.query.showAction) {
      setTimeout(() => {
        this.show_modal_actions++;
      }, 100);
    }
    if (this.$route.query.showChat) {
      setTimeout(() => {
        this.show_modal_chats++;
      }, 100);
    }
  },

  methods: {
    autoOpenChatByHash() {
      if (this.$route.hash.includes('#showChat=')) {
        this.show_modal_chats++;
      }
    }
  },

  watch: {
    '$route.hash': { deep: true, handler() {
      this.autoOpenChatByHash();
    }
    }
  }
};
</script>
