<template>
  <medi-modal @closed="close" id="manual-dosing-history-modal" title="Historie handmatig schema" size="small" :loading="manualDosingHistory === null">
    <medimo-table v-if="manualDosingHistory && typeof manualDosingHistory.dosings !== 'undefined'" :enable_filter="false" :number_of_rows="Object.keys(manualDosingHistory.dosings).length">
      <medimo-thead>
        <medimo-tr>
          <medimo-th></medimo-th>
          <medimo-th v-for="(timing, index) in manualDosingHistory.timings" :key="index">{{ timing }}</medimo-th>
        </medimo-tr>
      </medimo-thead>
      <medimo-tbody>
        <medimo-tr v-for="(dosing, date) in manualDosingHistory.dosings" :key="date">
          <medimo-td>{{ date }}</medimo-td>
          <medimo-td v-for="(timing, index) in manualDosingHistory.timings" :key="index">
            <template v-if="dosing[timing]">{{ dosing[timing] }} {{ manualDosingHistory.unit }}</template>
          </medimo-td>
        </medimo-tr>
      </medimo-tbody>
    </medimo-table>
    <div v-if="manualDosingHistory && typeof manualDosingHistory.message !== 'undefined'">
      {{ manualDosingHistory.message }}
    </div>
  </medi-modal>
</template>

<script>
import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import MediModal from '@/vue/components/general/MediModal';

export default {
  components: { MediModal },
  emits: ['closed'],
  props: { 'dosage_instruction': {required: true} },
  mixins: [BaseComponentsMixin],
  data: function () {
    return {
      manualDosingHistory: null
    };
  },
  created() {
    if (this.manualDosingHistory === null) {
      const vm = this;
      this.$store.dispatch('api/getEndpoint', {
        endpoint: '/api/v1/mutations/' + this.dosage_instruction.mutation.id + '/manual-dosing-history'
      }).then(response => {
        vm.manualDosingHistory = response.data.data;
      });
    }
  },
  methods: {
    close() { this.$emit('closed'); }
  }
};
</script>
