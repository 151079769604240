<template>
  <medimo-data-table
      :name="name"
      :loading="loading"
      :show_loader="show_loader"
      :store_name="store_name_local"
      :meta_data="meta_data"
      :number_of_rows="number_of_rows"
      :autofocus="autofocus"
      :auto_trigger_id="auto_trigger_id"
      :pagination_enabled="pagination_enabled"
      :exportable="exportable"
      :copyable="copyable"
      :privacy_filter="privacy_filter"
      :privacy_filter_property="privacy_filter_property"
      :privacy_filter_value="privacy_filter_value"
      :enable_filter="enable_filter"
      :filter_name="filter_name"
      :isLocalDataTable="true"

      v-bind="$attrs"

      :minimum_height="minimum_height"
      :no_results_message="no_results_message"

      @switch_to_page="switch_to_page($event)"

      :quick_filters="quick_filters"

      :extra_buttons_class="extra_buttons_class"
      :quick_filter_buttons_class="quick_filter_buttons_class"
      :table_links_class="table_links_class"

      :auto_store_settings="auto_store_settings"
      :reset_stored_settings="reset_stored_settings"
  >

    <!-- custom elements placed instead of default quick filters -->
    <template v-slot:extra_buttons v-if="$slots['extra_buttons']">
      <slot name="extra_buttons"></slot>
    </template>

    <slot></slot>
  </medimo-data-table>
</template>

<script>
import MedimoDataTable from './MedimoDataTable';
import MedimoQuickFilterButton from './MedimoQuickFilterButton';
import MedimoAlert from '../MedimoAlert';
import MedimoFormColumn from '@/vue/components/general/form/base/MedimoFormColumn';

export default {
  components: {
    MedimoDataTable,
    MedimoQuickFilterButton,
    MedimoAlert,
    MedimoFormColumn,
  },

  props: {
    'name': {default: ''},

    'store_name': {default: null, type: [String, null]},
    'models': {type: Array},
    'minimum_height': {default: false},

    'enable_filter': {default: true},
    'filter_name': {default: 'filtertext'},

    // An integer that lets the table know how many rows are rendered in it's slot
    'number_of_rows': {default: 0, required: true},

    // The standard message to show when no table rows are present
    'no_results_message': {default: 'Geen resultaten gevonden.'},

    'loading': {default: false},

    // Focust automatisch het zoekveld, maar kan daarmee ook de pagina scrollen
    'autofocus': {default: true},

    'pagination_enabled': {default: true},
    'exportable': {default: false},
    'copyable': {default: false},

    // Privacy by Design
    // Door een property en value mee te geven zoals bijv. patient_id en 12345 filter je _altijd_ op die waardes
    // ongeacht de andere settings. Zo kun je een modellen set altijd juist filteren, ook als per ongeluk nog "oude" data in
    // de store zit
    // By default staat dit aan, door privacy_filter op false te zetten, disable je deze privacy filter bewust.
    'privacy_filter': {
      default: true,
    },
    'privacy_filter_property': {default: null},
    'privacy_filter_value': {default: null},

    // Met deze kunnen we de loader ook handmatig triggeren, na bijvoorbeeld een @event
    'show_loader': {default: false},

    'quick_filter_buttons_class': {default: 'col-md-6'},
    'table_links_class': {default: 'col-md'},
    'extra_buttons_class': {default: 'col-md'},

    // Zet deze op true om automatisch input van een gebruiker op te slaan
    // zodat deze restored wordt als ze terug navigeren naar deze pagina
    'auto_store_settings': {default: false},
    'auto_trigger_id': {default: null}, // Set to an ID, same as the MedimoTr, to trigger that row and _not_ select the first row

    // ++ this value to reset the default table settings
    'reset_stored_settings': {default: 0},
  },

  mixins: [
    //
  ],

  data: function () {
    return {
      store_name_local: 'local_data_table',
      pageJustLoaded: true,
    };
  },

  computed: {
    uid() {
      return this._.uid;
    },
    medimoLocalDataTableUid() {
      return this.uid;
    },
    current_page: {
      get() {
        return this.$store.getters[this.store_name_local + '/current_page'];
      },
      set(page_number) {
        if (!this.pageJustLoaded) {
          this.$store.commit(this.store_name_local + '/set_current_page', page_number);
        }
      }
    },
    quick_filters() {
      return this.$store.getters[this.store_name_local + '/quick_filters'];
    },
    sort_columns() {
      return this.$store.getters[this.store_name_local + '/sort_columns'];
    },
    query() {
      return this.$store.getters[this.store_name_local + '/query'];
    },
    meta_data() {
      return this.$store.getters[this.store_name_local + '/paginated_meta_data'];
    },
    usesDefaultStore() {
      return Array.isArray(this.models) && this.store_name === null;
    },
    data_table_identifier() {
      // Same url, but different query would result in same table identifier if we did not include the query.
      // For the patient selector we need different table identifiers when the query differs.
      // Could screw us up later, as the query does not necessarily mean the table data is different..
      return this.$store.getters['settings/data_tables/data_table_identifier'](this.name, this.$route.name, '_QUERY_' + JSON.stringify(this.$route.query));
    },
    storedPage() {
      return this.$store.getters['settings/data_tables/stored_page'](this.data_table_identifier);
    },
  },

  created() {
    this.setupStore();
  },

  unmounted() {
    if (this.usesDefaultStore) {
      this.$store.commit(this.store_name_local + '/set_state', {
        property: 'data',
        value: [],
      });
      this.$store.commit(this.store_name_local + '/set_state', { property: 'sort_columns', value: [] });
      this.$store.commit(this.store_name_local + '/clear_search_settings');
    }
    this.$store.commit(this.store_name_local + '/set_state', {
      property: 'useLocalQuickFilters',
      value: false,
    });
  },

  mounted() {
    setTimeout(() => {
      // De eerste seconde na pageload willen we evt opgeslagen settings loaden
      // Als dan bijv de query automatisch ge-set wordt, willen we direct loaden met de opgeslagen pagina, en niet pagina 1
      this.pageJustLoaded = false;
    }, 1000);

    this.stored_data_table_identifier = this.data_table_identifier;
  },

  methods: {
    switch_to_page(page_number) {
      // Switch pages instantly
      this.current_page = page_number;
    },
    setupStore() {
      // Aan het begin willen we bepalen of alle waardes vanuit een predefined
      // store moeten komen. Of dat we de default gebruiken die na iedere
      // refresh / navigatie overscheven wordt
      // In dit geval willen we buiten de store om de datatable gebruiken
      if (this.usesDefaultStore) {
        this.store_name_local = 'local_data_table';
        this.$store.commit(this.store_name_local + '/set_state', {
          property: 'data',
          value: this.models,
        });
      }
      // Zo niet, gebruiken we de store die is meegegeven
      else {
        this.store_name_local = this.store_name;
      }

      // We gebruiken understaande waarde om lokaal gebruik van quickFilters te activeren
      this.$store.commit(this.store_name_local + '/set_state', {
        property: 'useLocalQuickFilters',
        value: true,
      });
    }
  },

  watch: {
    query() {
      // When the query changes, we reset the page to 1 (which auto-reloads the data as well)
      this.switch_to_page(this.pageJustLoaded ? this.storedPage : 1);
    },
    sort_columns: {
      deep: true,
      handler() {
        // When the sort_columns change, refetch the data, fast
        this.switch_to_page(this.pageJustLoaded ? this.storedPage : 1);
      }
    },
    models: {
      deep: true,
      handler() {
        // Soms laadt de local data pas later, dan willen we die wel
        // de store in pushen
        this.setupStore();
      }
    },
    quick_filters: {
      deep: true,
      handler() {
        // When the quick_filters change (active to inactive, vice versa), refetch the data, fast
        this.switch_to_page(this.pageJustLoaded ? this.storedPage : 1);
      }
    },
    // Used to reset the data after a route change, preventing old data from being shown.
    '$route': {
      deep: true,
      handler() {
        // When the route changes, refetch the data, fast
        this.switch_to_page(1);
      }
    },
    number_of_rows(value) {
      // When the new number of rows of this page is 0 but the current_page is > 1 we auto-switch to the previous page
      // to prevent showing an empty page
      if (value === 0 && this.current_page !== 1) {
        this.switch_to_page(this.current_page - 1);
      }
    }
  }
};
</script>
