import BaseModule from '@/vue/store/modules/base_module';

// initial state
// shape: [{ id, quantity }]
const state = {
  ...BaseModule.state,

  search_endpoint: '/api/v1/locations/search',

  filter_columns: [
    //
  ],
  search_columns: [
    //
  ],

  quick_filters: {},
  default_quick_filters: {},

  sort_columns: [{column_name: 'naam', direction: 'ASC'},],
  default_sort_columns: [{column_name: 'naam', direction: 'ASC'},],
};

// getters
const getters = {
  ...BaseModule.getters
};

// actions
const actions = {
  ...BaseModule.actions,
};

// mutations
const mutations = {
  // dummy_mutation (state, id) {
  //     state.id = id;
  // },
  ...BaseModule.mutations
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
