<template>
  <div ref="medimo-th" :class="'medimo-th th unselectable ' + column_classes + ' ' + (sort_column_1.length ? 'clickable' : '')"
       @click="toggleDirection(1)" :uid="uid"
  >
    <slot name="left"></slot>
    <span :class="(sort_column_1.length ? 'clickable' : '')" @click.stop="toggleDirection(1)">
            <span v-if="column_name_1.length">
                {{ column_name_1 }}
            </span>
            <span v-else>
                <slot></slot>
            </span>
        </span>
    <medimo-sort-button v-if="sort_column_1.length" :value="sort_direction_1" @click.stop="toggleDirection(1)"></medimo-sort-button>

    <span :class="'text-medimo-50 ml-4 ' + (sort_column_2.length ? 'clickable' : '')" v-if="column_name_2.length"
          @click.stop="toggleDirection(2)"
    >
            {{ column_name_2 }}
        </span>
    <medimo-sort-button v-if="sort_column_2.length" :value="sort_direction_2" @click.stop="toggleDirection(2)"></medimo-sort-button>
    <slot name="right"></slot>
    <!-- Copy Content Button -->
    <medimo-copy-table-button
        class="ml-4"
        v-if="is_first_th_computed && medimo_table_is_copyable"
        tooltip_content_start="Kopiëer tabel naar klembord"
        row_component_css_selector=".medimo-tr.tr"
        :cell_component_css_selector="['.medimo-td.td', '.medimo-th.th']"
        :container_component="medimo_table"
    ></medimo-copy-table-button>
  </div>
</template>

<script>
import MedimoSortButton from './MedimoSortButton';
import WorksWithParentsSiblingsAndChildren from '@/vue/mixins/WorksWithParentsSiblingsAndChildren';
import MedimoCopyTableButton from '../MedimoCopyTableButton';
import {nextTick} from 'vue';

export default {
  components: {
    MedimoSortButton,
    MedimoCopyTableButton,
  },

  props: {
    // Use this to enable the copy buttons and other stuff
    // For some reason does not work with a prod build in Vue3, so manual for now
    is_first_th: {default: false},

    // Use this to set the width
    'column_classes': {default: 'col'},

    // The name of the store we're sorting the rows from
    // Used in the action that we need to dispatch
    // Since all stores extend the BaseModule (...right?) it has default sort functions
    'store_name': {default: 'local_data_table'},

    // The column name / description shown in the Table
    'column_name_1': {default: ''},
    // The column name as it's defined in the Resource. Usually corresponds with the Database column name
    'sort_column_1': {default: ''},

    // Provides a secondary sort function, used on the Status page for example to sort by ATC
    'column_name_2': {default: ''},
    'sort_column_2': {default: ''},

    // Set to FALSE to disable copying data from this cell to the clipboard when clicking the COPY icon
    'copy': {default: true},
  },

  mixins: [
    WorksWithParentsSiblingsAndChildren,
  ],

  data() {
    return {
      siblings: [],
    };
  },

  computed: {
    uid() {
      return this._.uid;
    },
    medimoThUid() {
      return this.uid;
    },
    sort_direction_1() {
      return this.getSortDirection(1);
    },
    sort_direction_2() {
      return this.getSortDirection(2);
    },
    storeIsSet() {
      return this.store_name.length > 0;
    },
    sort_columns() {
      if (!this.storeIsSet) {
        return [];
      }
      return this.$store.getters[this.store_name + '/sort_columns'];
    },
    medimo_table() {
      return this.find_matching_parent('medimoTableUid');
    },
    medimo_table_is_copyable() {
      return this.medimo_table.copyable;
    },
    // Checks if this <medimo-th> is actually the first one of the table
    is_first_th_computed() {
      if (this.is_first_th) {
        return true;
      }
      // It's always an array, because of there's 1 medimo-th, it would contain itself
      if (this.siblings && this.siblings.length) {
        let firstMedimoThUid = parseInt(this.siblings[0].getAttribute('uid'));
        return firstMedimoThUid === this.uid;
      }
      return false;
    }
  },

  created() {
    //
  },

  mounted() {
    nextTick(() => {
      this.siblings = Array.from(this.find_siblings_css(this.medimo_table.$el, '.medimo-th.th'));
    });
  },

  methods: {
    toggleDirection(number) {
      if (this.sort_column_1.length === 0 && this.sort_column_2.length === 0) {
        return false;
      }

      // Since this is the toggle, we remove all previous sorting orders first
      this.$store.commit(this.store_name + '/clear_other_sort_columns', this['sort_column_' + number]);

      if (this['sort_direction_' + number] === '' || typeof this['sort_direction_' + number] === 'undefined') {
        this.$store.commit(this.store_name + '/sort', {column_name: this['sort_column_' + number], direction: 'ASC'});
      } else if (this['sort_direction_' + number] === "ASC") {
        this.$store.commit(this.store_name + '/sort', {column_name: this['sort_column_' + number], direction: 'DESC'});
      } else if (this['sort_direction_' + number] === 'DESC') {
        this.$store.commit(this.store_name + '/sort', {column_name: this['sort_column_' + number], direction: 'ASC'});
      }
    },
    validateStoreName() {
      if (this.store_name.length === 0) {
        console.error('The store_name property is empty in MedimoTh');
      }
    },
    getSortDirection(number) {
      // als we op meerdere kolommen sorteren, dan disable indicator
      if (this.sort_columns.length > 1) {
        return '';
      }
      const sort_column = this.sort_columns.filter(sort_column => {
        return sort_column.column_name === this['sort_column_' + number];
      });

      if (sort_column.length === 0) {
        return '';
      }

      return sort_column[0].direction;
    }
  },

  watch: {
    //
  }
};
</script>
