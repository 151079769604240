<template>
  <div>
    <medimo-labeled-input :extra_classes="extra_classes"
                          :validation_class="validation_class"
                          :label_column_classes="label_column_classes"
                          :input_column_classes="input_column_classes"
                          :type="type"
                          v-bind:modelValue="modelValue"
                          :name="name"
                          :label="label"
                          :no_margins="no_margins"
                          :inline="inline"
                          :placeholder="placeholder"
                          :disabled="disabled"
                          :help_text="help_text"
                          :hide_zero="hide_zero"
                          @update:modelValue="onInput"
                          @blur="$emit('blur', $event)"
                          @focus="$emit('focus', $event)"
                          @keyup="$emit('keyup', $event)"
                          @keydown_backspace="$emit('keydown_backspace')" @keydown_tab="$emit('keydown_tab')" @keydown_enter="$emit('keydown_enter')" @keydown_left="$emit('keydown_left')" @keydown_up="$emit('keydown_up')" @keydown_right="$emit('keydown_right')" @keydown_down="$emit('keydown_down')" @keydown_period="$emit('keydown_period')" @keyup_backspace="$emit('keyup_backspace')" @keyup_tab="$emit('keyup_tab')" @keyup_enter="$emit('keyup_enter')" @keyup_left="$emit('keyup_left')" @keyup_up="$emit('keyup_up')" @keyup_right="$emit('keyup_right')" @keyup_down="$emit('keyup_down')" @keyup_period="$emit('keyup_period')"
                          :focus="focus"
                          :min="min"
                          :max="max"
                          :step="step"
                          :isRow="isRow"
    >
    </medimo-labeled-input>
    <!-- Gaat nu via de Alerts -->
    <!--    <div class="invalid-feedback" v-show="invalid">-->
    <!--      {{ invalid_message }}-->
    <!--    </div>-->
  </div>
</template>

<script>

import MedimoLabeledInput from './base/MedimoLabeledInput';
import IsValidatedFormComponentMixin from '@/vue/mixins/form/IsValidatedFormComponentMixin';

export default {

  components: {MedimoLabeledInput},

  emits: ['keyup', 'focus', 'blur', 'update:modelValue', 'keydown_backspace', 'keydown_tab', 'keydown_enter', 'keydown_left', 'keydown_up', 'keydown_right', 'keydown_down', 'keydown_period', 'keyup_backspace', 'keyup_tab', 'keyup_enter', 'keyup_left', 'keyup_up', 'keyup_right', 'keyup_down', 'keyup_period'],

  props: {
    'no_margins': {default: true},
    'modelValue': {default: ''},
    'inline': {default: true},
    'name': {default: ''},
    'label': {default: ''},
    'disabled': {default: false},
    'placeholder': {default: ''},
    'extra_classes': {default: ''},
    'type': {default: 'text'},
    'help_text': {default: ''},
    'focus': {default: false},
    'hide_zero': {default: false},

    // By default the label takes the space it needs, and no more.
    // You can specify a specific column width here if you want to change this behaviour for mobile, tablet or desktop
    'label_column_classes': {default: 'col-auto'},
    'input_column_classes': {default: 'col-sm'},
    'min': {},
    'max': {},
    'step': {},

    // If we want to use this component multiple times in a custom layout, we can omit the column/row
    isRow: {default: true},
  },

  mixins: [
    IsValidatedFormComponentMixin
  ],

  data: function () {
    return {
      //
    };
  },

  computed: {},

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    onInput(value) {
      this.$emit('update:modelValue', value);
    }
  },

  watch: {
    //
  }
};
</script>
