<template>
  <div v-if="show">
    <div v-if="line"
         class="border_left"
         style="position:relative;
                min-height: 145px;
                margin-left: 17px;">
    </div>
    <div :title="data.value" v-else
         style="position:relative; min-height: 145px; ">
      <span :class="{highlighted: highlighted, all_borders: data.value}"
            style="font-size: 0.95em; padding: 8px; min-height: 100px; margin: 0px; position: absolute; width: 120px;">
         <span class="clickable"
               @click="toggleParent"
               style="position: absolute; top: 19px; left: 25px;"
               v-if="parentNodeId && parentIsToggled">
           ...
         </span>
         <span  v-else>
           {{ limitedValue }}
         </span>
      </span>
      <span v-if="data.arrows && !collapsed"
            @click="toggle"
            class="is-clickable"
            :style="{fontWeight: answerIsYes ? 'bold' : 'regular'}"
            style="margin-left: 3px; margin-top: 16px; right: 0px; position: absolute; width: 20px;">
        <fa-icon :icon="'fa-solid ' + isToggled ? 'fa-caret-down' : 'fa-caret-right'" size="lg" />
        <span v-if="!collapsed" style="margin-left: 5px;">
          {{data.no_arrow ? 'Ja': ''}}
        </span>
      </span>
      <span v-if="data.no_arrow && !collapsed"
            :style="{fontWeight: answerIsNo ? 'bold' : 'regular'}"
            style="margin: 0px 10px 5px 10px; bottom: 0px; position: absolute; width: 100px;">
        <fa-icon icon="fa-solid fa-caret-down" size="lg" />Nee
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @import "/variables";

  .borders {
    border-left: 1px solid $medimo-25;
    border-top: 1px solid $medimo-25;
  }
  .border_left {
    border-left: 1px solid $medimo-25;
  }
  .all_borders {
    border: 1px solid $medimo-25;
  }
  .highlighted {
    background: $medimo-10;
  }
</style>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';

export default {
  components: {},

  props: {
    'data': {required: true},
    'all_data': {required: true},
    'row_index': {required: true},
    'col_index': {required: true},
    'trace_questions': {default: false},
    'visible': {required: true},
    'toggled': {required: true}
  },

  emits: ['toggle'],

  mixins: [BaseComponentsMixin],

  data: function () {
    return {};
  },

  computed: {
    limitedValue() {
      // Guard
      if (typeof this.data.value === 'undefined') {
        return '';
      }
      // Add spaces around '/' to improve layout
      const value = this.data.value.replace('/', ' / ');
      if (value.length > 50) {
        return value.substring(0, 47) + '...';
      }
      return value;
    },
    answerIsYes() {
      return this.answer === true;
    },
    answerIsNo() {
      return this.answer === false;
    },
    answer() {
      if (this.trace_questions) {
        if (this.nodeId && Object.keys(this.trace_questions).includes('' + this.nodeId)) {
          //It's a question with nodeId included in the trace_questions: highlight it
          return this.trace_questions[this.nodeId].answer;
        }
      }
      return null;
    },
    line() {
      return this.data.value && this.data.value === '|';
    },
    parentIsToggled() {
      if (this.parentRowIndex !== this.row_index) {
        return false;
      }
      return Object.keys(this.toggled).includes(''+this.parentNodeId) && this.toggled[''+this.parentNodeId] !== false;
    },
    collapsed() {
      if (this.parentNodeId && this.parentIsToggled) {
        return true;
      }
      return false;
    },
    show() {
      if (this.collapsed) {
        return true;
      }
      return this.visible;
    },
    isToggled() {
      return Object.keys(this.toggled).includes(''+this.nodeId) && this.toggled[''+this.nodeId] !== false;
    },
    nodeId() {
      if (this.data.values && this.data.values.node) {
        return this.data.values.node.id;
      }
      return false;
    },
    parent() {
      if (!this.data.values) {
        return false;
      }
      return this.data.values.parent;
    },
    parentNodeId() {
      if (!this.parent) {
        return false;
      }
      return this.parent[2];
    },
    parentColIndex() {
      if (!this.parent) {
        return false;
      }
      return this.parent[1];
    },
    parentRowIndex() {
      if (!this.parent) {
        return false;
      }
      return this.parent[0];
    },
    highlighted() {
      if (this.trace_questions) {
        if (this.nodeId) {
          //It's a question with nodeId included in the trace_questions: highlight it
          return Object.keys(this.trace_questions).includes(''+this.nodeId);
        }
        else if (this.parentNodeId) {

          if (!this.trace_questions[this.parentNodeId]) {
            return false;
          }

          const parentRow = this.parentRowIndex;
          const parentCol = this.parentColIndex;
          const parentAnswer = this.trace_questions[this.parentNodeId].answer;

          //It's an answer/signal, highlight it if

          //The parent's answer is yes and we are on the same row
          if (parentAnswer && parentRow === this.row_index) {
            return true;
          }
          //The parent's answer is no and we are on the same column
          else if (!parentAnswer && parentCol === this.col_index) {
            return true;
          }
        }
      }
      return false;
    },
  },

  methods: {
    toggle() {
      this.$emit('toggle', this.row_index, this.col_index, this.nodeId);
    },
    toggleParent() {
      this.$emit('toggle', this.parentRowIndex, this.parentColIndex, this.parentNodeId);
    },
    get_data(i,j) {
      if (this.all_data[i] && this.all_data[i][j] && this.all_data[i][j]) {
        return this.all_data[i][j];
      }
      else {
        return false;
      }
    }
  }
};
</script>
