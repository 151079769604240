import {AbstractMedication} from "@/controllers/AbstractMedication";
import {AdministrationAgreement} from "@/domain/models/AdministrationAgreement";
import {AbstractMedicationData} from "@/controllers/AbstractMedicationData";

export class AdministrationAgreementController extends AbstractMedication<AdministrationAgreement> {
  protected storeName = 'mp9/administration_agreements';
  protected apiEndpoint = 'administration-agreement';

  protected fromJson(data: unknown): AdministrationAgreement {
    return AdministrationAgreement.fromJson(data);
  }
}

export type AdministrationAgreementData = AbstractMedicationData<AdministrationAgreement>
