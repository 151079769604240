import Vue from 'vue';
import Utility from '@/vue/utility/utility';
import BaseModule from '@/vue/store/modules/base_module';

// initial state
// shape: [{ id, quantity }]
const state = {
  data: []
};

// getters
const getters = {
  find_option(state, getters, rootState) {
    return (administrationRoute) => getters['find_by_prop']({property: 'value', value: administrationRoute});
  },
  ...BaseModule.getters
};

// actions
const actions = {
  ...BaseModule.actions,

  fetchSelect({state, commit, dispatch, getters, rootState}, payload) {

    const endpoint = '/api/v1/actions/fetch-templates';

    return dispatch('api/getEndpoint', {
      endpoint: endpoint,
      data: {}
    }, {root: true}).then(response => {
      commit('add_to_templates', {patient_id: payload.patient_id, templates: response.data.data});
    });
  },
};

// mutations
const mutations = {
  ...BaseModule.mutations
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
