<template>
  <medi-modal id="thrombosis-overview-modal" :loading="loading" title="Trombosedienst gegevens" size="medium" @closed="emit('closed')">
    <medimo-form>
      <medimo-form-row>
        <medimo-form-column column_classes="col-12">
          <p v-if="message" v-html="message"></p>
          <medimo-notification class="mb-2" type='danger' :exclamation="true" v-if="patient.thrombosis.latestImport > patient.thrombosis.latestSuccessfulImport">
            De laatste trombose import van {{ patient.thrombosis.latestImport }} was niet succesvol
          </medimo-notification>

          <MedimoLabeledTextOnly label_column_classes="col-sm-6" :bold_text="true" label="Trombosedienst:" :text="patient.thrombosis.agencyName"></MedimoLabeledTextOnly>
          <MedimoLabeledTextOnly label_column_classes="col-sm-6" :bold_text="true" label="" :label-can-be-empty="true" v-if="patient.thrombosis.agencyContactDetails" :text="patient.thrombosis.agencyContactDetails"></MedimoLabeledTextOnly>

          <MedimoLabeledTextOnly class="grouped" label_column_classes="col-sm-6" :bold_text="true" label="Doseergegevens tot en met:" :text="patient.thrombosis.doseUntil"></MedimoLabeledTextOnly>
          <MedimoLabeledTextOnly class="grouped" label_column_classes="col-sm-6" :bold_text="true" label="De laatste keer dat gegevens zijn gewijzigd:" :text="patient.thrombosis.latestChangeInData"></MedimoLabeledTextOnly>
          <MedimoLabeledTextOnly label_column_classes="col-sm-6" :bold_text="true" label="De laatste succesvolle import was op:" :text="patient.thrombosis.latestSuccessfulImport"></MedimoLabeledTextOnly>

          <MedimoLabeledTextOnly label_column_classes="col-sm-6" :bold_text="true" label="De volgende prikdatum is op:" :text="patient.thrombosis.nextBloodTest"></MedimoLabeledTextOnly>

        </medimo-form-column>
      </medimo-form-row>
    </medimo-form>

    <template v-slot:action-buttons>
      <medimo-button v-if="currentUser.has_thrombosis_webservice" class="mr-2" @click.native="thrombosisImport">
        Trombosedienst inplannen
      </medimo-button>
      <medimo-button v-if="currentUser.has_thrombosis_webservice" type="outline-primary" @click.native="showImportHistoryModal = true">
        Historie
      </medimo-button>
      <medimo-button class="ml-auto" @click.native="emit('closed')">
        Sluiten
      </medimo-button>
    </template>

    <thrombosis-history-modal
        v-if="showImportHistoryModal"
        :patient-id="patient.id"
        @closed="showImportHistoryModal = false"
    ></thrombosis-history-modal>
  </medi-modal>
</template>

<script setup lang="ts">
import MediModal from "@/vue/components/general/MediModal.vue";
import MedimoForm from "@/vue/components/general/form/MedimoForm.vue";
import MedimoFormColumn from "@/vue/components/general/form/base/MedimoFormColumn.vue";
import MedimoFormRow from "@/vue/components/general/form/base/MedimoFormRow.vue";
import MedimoLabeledTextOnly from "@/vue/components/general/form/base/MedimoLabeledTextOnly.vue";
import MedimoNotification from "@/vue/components/general/MedimoNotification.vue";
import MedimoButton from "@/vue/components/general/MedimoButton.vue";
import ThrombosisHistoryModal from "@/vue/components/patients/thrombosis/ThrombosisHistoryModal.vue";
import {Patient} from "@/domain/models/Patient";
import {computed, ref} from "vue";
import {store} from "@/vue/store";

const emit = defineEmits(['closed']);

interface Props {
  patient: Patient,
}

const props = defineProps<Props>();

const loading = ref(false);
const showImportHistoryModal = ref(false);
const message = ref('');

const currentUser = computed(() => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  return store.state.current_user.data;
});

function thrombosisImport(): void {
  loading.value = true;
  store.dispatch('api/getEndpoint', {
    endpoint: '/api/v1/patients/' + props.patient.id + '/thrombosis/plan',
  }).then(result => {
    store.dispatch(`notifications/addSuccessAlert`, {
      message: result.data.message
    });
  }).catch(() => {
    // empty catch to prevent uncaught error notification
  }).finally(() => {
    loading.value = false;
  });
}
</script>
<style scoped lang="scss">
#thrombosis-overview-modal .grouped {
  max-height: 20px;
}
</style>
