import Utility from '@/vue/utility/utility';
import BaseModule from '@/vue/store/modules/base_module';

// initial state
// shape: [{ id, quantity }]
const state = {
  db_data: [],      // Database models
  shadow_data: [],  // Temporary models when editing
  data: [],         // Local updated models
  lastTempId: 0,
};

// getters
const getters = {
  ...BaseModule.getters,
  for_medication_treatment_id(state, getters, rootState, rootGetters) {
    return (medication_treatment_id) => {
      return Utility.find_models_by_property(state.data, 'medication_treatment_id', medication_treatment_id);
    };
  },
  for_shadow_medication_treatment_id(state, getters, rootState, rootGetters) {
    return (medication_treatment_id) => {
      return Utility.find_models_by_property(state.shadow_data, 'medication_treatment_id', medication_treatment_id);
    };
  },
  for_db_medication_treatment_id(state, getters, rootState, rootGetters) {
    return (medication_treatment_id) => {
      return Utility.find_models_by_property(state.db_data, 'medication_treatment_id', medication_treatment_id);
    };
  },
  can_be_extended(state, getters, rootState, rootGetters) {
    return (id) => {
      if (isNaN(id) && id.startsWith('new-')) {
        //Don't parse id to int, because the model has a string as id.
      } else {
        id = parseInt(id);
      }

      const dosageInstructions = rootGetters['dosage_instructions/for_shadow_medication_agreement'](id);
      const lastDosageInstruction = dosageInstructions[dosageInstructions.length-1]; //TODO Ramon use sort function

      // A medication agreement can be extended when
      // - The last dosage instruction is no manual dosing schema
      // - The last dosage instruction is no stop

      if (lastDosageInstruction && (lastDosageInstruction.usageType === 'S' || lastDosageInstruction.prescription_type === 4)) {
        return false;
      }
      if (lastDosageInstruction && lastDosageInstruction.stop_datetime) {
        return false;
      }

      return true;
    };
  },
  can_be_changed(state, getters, rootState, rootGetters) {
    return (id) => {
      if (isNaN(id) && id.startsWith('new-')) {
        //Don't parse id to int, because the model has a string as id.
      } else {
        id = parseInt(id);
      }

      const dosageInstructions = rootGetters['dosage_instructions/for_shadow_medication_agreement'](id);
      const lastDosageInstruction = dosageInstructions.sort(function(a,b) {return a.id < b.id;})[0];

      // A medication agreement can be extended when
      // - The last dosage instruction is no stop

      if (lastDosageInstruction && lastDosageInstruction.stop_datetime) {
        return false;
      }

      return true;
    };
  },
};

// actions
const actions = {
  ...BaseModule.actions,
  create_temporary({state, commit, dispatch, getters, rootState}, payload)
  {
    return new Promise((resolve, reject) => {
      const dosingInstruction = payload.dosingInstruction;
      const medicationTreatmentId = payload.medicationTreatmentId;
      const manualDosingSchema = payload.manualDosingSchema;

      const tempId = state.lastTempId + 1;
      //Find last temp id in store
      state.lastTempId = tempId;

      const newTempId = 'new-' + tempId;

      dosingInstruction.medication_treatment_id = medicationTreatmentId;
      dosingInstruction.medication_agreement_id = newTempId;

      const medicationAgreement = {
        id: newTempId,
        patient_id: dosingInstruction.patient_id,
        drug_id: dosingInstruction.drug_id,
        prkode: dosingInstruction.prkode,
        medication_treatment_id: medicationTreatmentId,
        attributes: [
          'NIV2',
          'KK'
        ]
      };

      commit('medication_agreements/add_to_shadow_data', medicationAgreement, {root: true});

      dispatch('dosage_instructions/create_temporary', {dosingInstruction: dosingInstruction, manualDosingSchema: manualDosingSchema}, {root: true})
        .then(function(newDosingInstructionId) {
          resolve(
            {
              newMedicationAgreementId: newTempId,
              newDosingInstructionId: newDosingInstructionId});
        });
    });
  },
  create({state, commit, dispatch, getters, rootState}, payload) {

    const dosageInstruction = payload.dosage_instruction;
    const medicationTreatmentId = payload.medication_treatment_id;

    const tempId = state.lastTempId + 1;
    //Find last temp id in store
    state.lateTempId = tempId;

    const newTempId = 'new-' + tempId;

    dosageInstruction.medication_agreement_id = newTempId;

    const medicationAgreement = {
      id: newTempId,
      patient_id: dosageInstruction.patient_id,
      drug_id: dosageInstruction.drug_id,
      medication_treatment_id: medicationTreatmentId,
      attributes: [
        'NIV2',
        'KK'
      ]
    };

    commit('medication_agreements/add_to_data', medicationAgreement,  {root: true});

    dispatch('dosage_instructions/create', dosageInstruction,  {root: true});

    return newTempId;
  },

};

// mutations
const mutations = {
  ...BaseModule.mutations,
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
