const state = {
  data: [],
  type: null,
};

// getters
const getters = {
  data(state) {
    return state.data;
  },
  type(state) {
    return state.type;
  },
};

// actions
const actions = {
};

// mutations
const mutations = {
  set_data(state, data) {
    state.data = data;
  },
  set_type(state, type) {
    state.type = type;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
