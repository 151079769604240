export class VueWarning extends Error {
  constructor(error, message, ...params) {
    super(...params);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, VueWarning);
    }
    this.name = 'VueWarning';
    this.stack = error;
    this.message = message;
  }
}

export class VueError extends Error {
  constructor(error, message, ...params) {
    super(...params);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, VueError);
    }
    this.name = 'VueError';
    this.stack = error;
    this.message = message;
  }
}

export class JsUncatchedError extends Error {
  constructor(error, message, ...params) {
    super(...params);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, JsUncatchedError);
    }
    this.name = 'JsUncatchedError';
    this.stack = error;
    this.message = message;
  }
}

export class ManualVueSentry extends Error {
  constructor(error, message, ...params) {
    super(...params);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ManualVueSentry);
    }
    this.name = 'ManualVueSentry';
    this.stack = error;
    this.message = message;
  }
}
