<script>

import ChecksIfVuePage from './ChecksIfVuePage';

export default {
  components: {
    //
  },

  mixins: [
    ChecksIfVuePage,
  ],

  props: {
    'to': {
      type: String,
      required: true
    },
  },

  computed: {
    isVueRoute() {
      return this.pathIsVueRoute(this.to);
    },
    isVuePage() {
      return this.currentPageIsVuePage();
    }
  },

  methods: {}

};
</script>
