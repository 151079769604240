<template>
    <div class="tbody" style="position:absolute; left: -9999px;">
        <medimo-tr :keynav="false">
            <medimo-td>‏‏ &nbsp;</medimo-td>
        </medimo-tr>
    </div>
</template>

<script>
import MedimoTr from './MedimoTr';
import MedimoTd from './MedimoTd';

export default {
  components: {
    MedimoTr,
    MedimoTd,
  },

  props: [],

  mixins: [],

  data: function () {
    return {
      //
    };
  },

  computed: {
    //
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    //
  },

  watch: {
    //
  }
};
</script>
