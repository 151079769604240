<template>
  <medimo-vue-select2
      :minimum_results_for_search="5"
      label_column_classes="col-6"
      :placeholder="placeholder"
      :help_text="help_text"
      :multiple="multiple"
      :options="options"
      :loading="loading"
      api_url="/api/v1/patients/search"
      api_request_type="GET"
      optionsLabelProperty="name_dob_ward"
      optionsValueProperty="id"
      :api_data="{
        max_results: 100,
        enriched_patient_name: true,
        cluster_id: cluster_id,
        sort_columns: ['naam'],
      }"
      :min_input_length="min_input_length"
      v-model="value">
  </medimo-vue-select2>
</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import MedimoVueSelect2 from '@/vue/components/general/form/base/vue-select2/MedimoVueSelect2';

export default {
  emits: ['changed'],
  components: {MedimoVueSelect2},

  props: {
    placeholder: {default: 'Selecteer een patient'},
    help_text: {default: ''},
    label: {default: ''},
    cluster_id: {default: null},
    multiple: {default: false},
    fetch_on_load: {default: false},
    min_input_length: {default: 3},
  },

  mixins: [BaseComponentsMixin],

  data: function () {
    return {
      value: null,
      id: null,
      loading: false,
    };
  },

  computed: {
    options() {
      return this.models.map(model => {
        return {value: model.id, text: model.naam};
      });
    },
    name() {
      if (this.id === null) {
        return '';
      }
      return this.options.filter(option => {
        return option.value === this.id;
      })[0];
    },
    models() {
      return this.$store.getters['patients/search_results'];
    },
  },

  created() {
    //
  },

  mounted() {
    if (this.fetch_on_load) {
      this.fetchOptions();
    }
  },

  methods: {
    selected(data) {
      this.id = parseInt(data.id);
    },
    fetchOptions() {
      // Alleen ophalen als er 0 clusters in zitten en de data dus nog opgehaald moet worden.
      if (this.models.length !== 0) {
        return;
      }
      this.loading = true;
      this.$store.commit('patients/set_param', {name: 'results_per_page', value: 'all'});
      this.$store.commit('patients/set_param', {name: 'cluster_id', value: this.cluster_id});
      return this.$store.dispatch('patients/search', {})
        .then(response => { this.loading = false; })
        .catch(() => { this.loading = false; });
    }
  },

  watch: {
    value(value) {
      this.$emit('changed', value);
    },
    id() {
      this.$emit('changed', {
        name: this.name,
        id: this.id,
      });
    },
    cluster_id() {
      this.id = null;
      this.$store.commit('patients/clear_search_results');
    }
  }
};
</script>
