import Utility from '@/vue/utility/utility';
import BaseModule from '@/vue/store/modules/base_module';

// initial state
// shape: [{ id, quantity }]
const state = {
  ...BaseModule.state,

  sort_columns: [
    {column_name: 'name', direction: 'ASC'}
  ],
};

// getters
const getters = {
  ...BaseModule.getters,
};

// actions
const actions = {
  ...BaseModule.actions,
  fetch_full({state, commit, dispatch, getters, rootState}, payload) {

    // Haalt de volledige security group, permissies owners en managers op
    const security_group_id = payload.security_group_id;

    return dispatch('api/getEndpoint', {
      // Deze haalt de security group data up _inclusief_ de leden
      endpoint: '/api/v1/security-groups/' + security_group_id,
    }, {root: true}).then(response => {
      // Is er altijd maar 1
      if (response.data.data) {
        commit('update_or_create_selected_search_result', response.data.data);
        dispatch('fill_secondary_data');

        return response.data.data;
      }
    });
  },
  fill_secondary_data({state, commit, dispatch, getters, rootState}, payload) {
    dispatch('fill_security_group_members');
    dispatch('fill_permissions');
  },
  fill_security_group_members({state, commit, dispatch, getters, rootState}, payload) {
    const members = [];
    // Als de security groups updaten, fillen we ook opnieuw de permissies
    state.search_results.forEach(securityGroup => {
      if (typeof securityGroup['members'] !== 'undefined') {
        securityGroup['members'].forEach(member => {
          member.security_group_id = securityGroup.id;
          members.push(member);
        });
      }
    });

    commit('permissions/security_group_members/set_data', members, {root: true});
  },
  fill_permissions({state, commit, dispatch, getters, rootState}, payload) {
    const permissions = [];
    // Als de security groups updaten, fillen we ook opnieuw de permissies
    state.search_results.forEach(securityGroup => {
      if (typeof securityGroup.permissions !== 'undefined') {
        securityGroup.permissions.forEach(permission => {
          permissions.push(permission);
        });
      }
    });

    commit('permissions/permissions/set_data', permissions, {root: true});
  },
  delete({state, commit, dispatch, getters, rootState}, payload) {
    const security_group_id = payload.security_group.id;
    // GET dus, geen DELETE for, reasons
    return dispatch('api/getEndpoint', {
      // Deze haalt de security group data up _inclusief_ de leden
      endpoint: '/config/authority/securitygroups/delete/' + security_group_id + '?suppressNotys=true',
    }, {root: true}).then(response => {
      return response;
    });
  }
};

// mutations
const mutations = {
  ...BaseModule.mutations,
  // dummy_mutation (state, id) {
  //     state.id = id;
  // },
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
