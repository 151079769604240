<template>
    <medimo-form-row margin_classes="mb-0" class="schema-form-thead" style="position:relative;" ref="time_header">
        <medimo-form-column column_classes="col-3" v-if="show_first_column">
            <span v-html="getHistoryLink()"></span>
        </medimo-form-column>
        <medimo-form-column :column_classes="extra_input_column ? 'col col-md p-0' : 'col p-0'">
            <medimo-form-row class="h-100 no-gutters" margin_classes="">
                <medimo-form-column :column_classes="'d-flex ' + time_label_column_classes" v-for="(time, index) in times" :key="time + index">
                    <div class="my-auto w-100 text-center">
                        <time-label
                                :read_only="read_only"
                                :use_standard_times="use_standard_times"
                                @change_time="$emit('change_time', $event)"
                                @delete_time="$emit('delete_time', $event)"
                                :delete_disabled="delete_disabled"
                                :times="times"
                                :time="time"
                                :index="index"
                                :show_hours_only="(width < 597 && times.length === 6) || (width < 480 && times.length >= 5)"
                        >
                        </time-label>
                    </div>
                </medimo-form-column>
            </medimo-form-row>
        </medimo-form-column>
        <medimo-form-column column_classes="col" v-if="extra_input_column">
            <!-- Empty, needed to keep in sync with the SchemaFormDosingInstructionItem -->
        </medimo-form-column>
        <medimo-form-column column_classes="add-row-column col-auto d-flex" v-if="show_last_column">
            <div class="my-auto ml-auto">
                <add-time-column-time-picker class="time-header-add-time-picker"
                                             @add_time="$emit('add_time', $event)"
                                             v-if="!use_standard_times && times.length < 6 && !extra_input_column"
                ></add-time-column-time-picker>
                <span v-else>
                    <!-- Otherwise, to maintain the design margins, we use a white one so it stays invisible, but takes up the space -->
                    <fa-icon icon="fa-solid fa-plus" class="text-white" size="lg" />
                </span>
            </div>
        </medimo-form-column>
    </medimo-form-row>
</template>

<script>

import TimeLabel from './TimeLabel';
import MedimoFormRow from '@/vue/components/general/form/base/MedimoFormRow';
import MedimoFormColumn from '@/vue/components/general/form/base/MedimoFormColumn';
import MedimoButtonLink from '@/vue/components/general/MedimoButtonLink';
import MedimoDateTimePicker from '@/vue/components/general/form/base/MedimoDateTimePicker';
import UsesMediaQueries from '@/vue/mixins/UsesMediaQueries';
import AddTimeColumnTimePicker from './components/AddTimeColumnTimePicker';

export default {
  emits: ['change_time','delete_time','add_time'],
  components: {
    TimeLabel,
    MedimoFormRow,
    MedimoFormColumn,
    MedimoButtonLink,
    MedimoDateTimePicker,
    AddTimeColumnTimePicker,
  },

  props: {
    "delete_disabled": {default: false},
    "times": {required: true},
    "use_standard_times": {default: false},
    "status_id": {default: false},
    "read_only": {default: false},

    // Set to FALSE to add the "Add Time TimePicker" to the last Time's column, instead of a unique column
    "extra_input_column": {default: true},

    // Set to false to hide the first column
    "show_first_column": {default: true},
    "show_last_column": {default: true},


    time_label_column_classes: {default: 'col-2'},

  },

  mixins: [
    UsesMediaQueries,
  ],

  data: function () {
    return {
      width: 0,
    };
  },

  computed: {
    //
  },

  created() {
    //
  },

  mounted() {
    this.width = this.$refs.time_header.$el.clientWidth; // helaas niet reactive te doen
  },

  methods: {
    add_time(event) {
      this.$emit('add_time', event);
    },
    change_time(event) {
      this.$emit('change_time', event);
    },
    delete_time(event) {
      this.$emit('delete_time', event);
    },
    getHistoryLink: function () {
      if (typeof this.status_id != 'undefined' && this.status_id !== '' && this.status_id !== false && this.status_id != 0) {
        const currentDate = moment().format('YYYY-MM-DD');
        return '<a class="ajax-load full-page-load" id="openHistoryPopup" href="/manualdosing/fullHistory/' + this.status_id + '/' + currentDate + '"><i class="fa fa-history" aria-hidden="true"></i></i></a>';
      } else {
        return '&nbsp;';
      }
    }
  },

  watch: {
    //
  }
};
</script>
