<template>
  <PatientInfoMediModal
      :patient_id="props.patient.id"
      :loading="loading"
      title="Acties"
      size="large"
      id="actions-data-table-modal"
      :min_height="0"
      @closed="close()"
    >

    <MedimoExternalDataTable
        :name="'patient-actions-data-table-' + props.patient.id"
        :store_name="storeName"
        :number_of_rows="models.length"
        :minimum_height="true"
        :refresh_table="refreshTable"
        :search_on_load="true"
        privacy_filter_property="patientId"
        :privacy_filter_value="props.patient.id"
        >
      <MedimoThead>
        <MedimoTr>
          <MedimoTh column_classes="col col-sm-6" :store_name="storeName" sort_column_1="name">Actie</MedimoTh>
          <MedimoTh :store_name="storeName">Wordt uitgevoerd door</MedimoTh>
          <MedimoTh :store_name="storeName" sort_column_1="validUntil">Te voltooien voor</MedimoTh>
        </MedimoTr>
      </MedimoThead>
      <MedimoTbody>
        <PatientActionsDataTableRow
            v-for="model in models"
            @refresh_table="refreshTable++"
            :key="model.id"
            :action="model"
            :patient="props.patient"
            @click="openEditActionModal(model)"
        ></PatientActionsDataTableRow>
      </MedimoTbody>
    </MedimoExternalDataTable>

        <template v-slot:action-buttons>
          <MedimoButton class="mr-md-auto float-md-none" @click="openAddActionModal">
                Nieuwe toevoegen
          </MedimoButton>
          <MedimoButton @click="close">
                Sluiten
          </MedimoButton>
        </template>

    <AddActionModal
        :patient="props.patient"
        @processed="refreshTable++"
        @closed="showAddAction = false"
        v-if="showAddAction"
    ></AddActionModal>

      <!-- Auto Open Actie -->
    <BaseActionModal
        :action="autoOpenAction"
        :patient="props.patient"
        @processed="refreshTable++"
        @closed="closeAutoOpenModal()"
        v-if="autoOpenAction"
    ></BaseActionModal>

  </PatientInfoMediModal>
</template>

<script setup lang="ts">
import MedimoExternalDataTable from "@/vue/components/general/table/MedimoExternalDataTable.vue";
import MedimoThead from "@/vue/components/general/table/MedimoThead.vue";
import MedimoTr from "@/vue/components/general/table/MedimoTr.vue";
import MedimoTh from "@/vue/components/general/table/MedimoTh.vue";
import MedimoTbody from "@/vue/components/general/table/MedimoTbody.vue";
import MedimoButton from "@/vue/components/general/MedimoButton.vue";
import PatientActionsDataTableRow from './PatientActionsDataTableRow.vue';
import PatientInfoMediModal from '@/vue/components/modals/PatientInfoMediModal.vue';
import BaseActionModal from '@/vue/components/patients/action-dialogs/_BaseActionModal.vue';
import AddActionModal from '@/vue/components/patients/action-dialogs/AddActionModal.vue';
import {useNavigatesProgramatically} from '@/vue/composables/NavigatesProgramatically';
import {Action} from "@/domain/models/Action";
import {Patient} from "@/domain/models/Patient";
import {computed, onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import {useStore} from "vuex";

const emit = defineEmits(['closed']);

interface Props {
  patient: Patient;
}

const props = defineProps<Props>();

const route = useRoute();
const store = useStore();
const storeName = ref<string>('actions');

const loading = ref<boolean>(false);

const showAddAction = ref<boolean>(false);
const showEditAction = ref<boolean>(false);
const refreshTable = ref<number>(0);
const autoOpenActionId = ref<string | null>(null);

onMounted(() => {
  store.commit(storeName.value + '/set_search_endpoint', '/api/v1/actions/' + props.patient.id);

  if (showInactiveActions.value) {
    store.commit(storeName.value + '/toggle_grouped_quick_filter', {
      'quick_filter_group_name': 'Status',
      'quick_filter_name': 'Inactief',
    });

    if (showActionParameter()) {
      store.commit(storeName.value + '/set_filter_by_ids', [showActionParameter()]);
    }
  }

  checkForAutoOpenAction();
});

// This is the computed property that would return the models / data that would generate the rows
// usually an array / object of models from the Vuex store
const models = computed<Action[]>(() => {
  // Here the search_results array is returned, since the Backend handles the sorting and filtering for us
  return store.getters[storeName.value + '/search_results'].filter((model: Action) => {
    return model.patientId === props.patient.id;
  });
});

const autoOpenAction = computed<number | null>(() => {
  if (autoOpenActionId.value === null) {
    return null;
  }

  // Werkt alleen met actieve acties, in toekomst wellicht extra functionaliteit wenselijk
  const patientOpenActions = props.patient.actions.filter((action) => {
    return action.id === parseInt(autoOpenActionId.value!);
  });
  if (patientOpenActions.length) {
    return patientOpenActions[0];
  }

  // Dit is de array van de tabel zelf, als die met quickfilters gestuurd wordt kunnen dat ook inactieve zijn
  const tableActions = models.value.filter((action) => {
    return action.id === parseInt(autoOpenActionId.value!);
  });
  if (tableActions.length) {
    return tableActions[0];
  }

  return null;
});

const hasAutoOpenAction = computed<boolean>(() => {
  return typeof route.query.showAction !== 'undefined';
});
const showInactiveActions = computed<boolean>(() => {
  return route.query.showInactiveActions == '1';
});

function close(): void {
  emit('closed');
}

function openAddActionModal(): void {
  showAddAction.value = true;
}

const { navigateProgrammatically } = useNavigatesProgramatically();
function openEditActionModal(action: Action): void {
  if (action.redirect !== null) {
    navigateProgrammatically(action.redirect);
  } else {
    showEditAction.value = true;
  }
}

function closeAutoOpenModal(): void {
  autoOpenActionId.value = null;
  delete route.query.showAction;
  store.commit(storeName.value + '/set_filter_by_ids', []);
}

function showActionParameter(): string | null {
  const showAction = route.query.showAction;
  if (typeof showAction === 'string') {
    return showAction;
  }

  return null;
}

function checkForAutoOpenAction(): void {
  if (!hasAutoOpenAction.value) {
    return;
  }

  const actionId = showActionParameter();
  if (!actionId) {
    return;
  }

  autoOpenActionId.value = actionId;

  // En nu clearen we de URL, zonder een nieuwe history aan te maken, maar de huidige vervangen
  let queryString: string = '?showAction=' + autoOpenActionId.value;
  if (showInactiveActions.value) {
    queryString += '&showInactiveActions=1';
  }

  store.dispatch('settings/navigation/clearQueryString', {
    query_string: queryString
  });
}
</script>
