<template>
    <div v-if="read_only" class="time-label">
      {{timeLabel}}
      <slot></slot>
    </div>
    <div v-else-if="delete_disabled" class="time-label">
        {{timeLabel}}
        <a class="time-label-button clickable" @click.stop.prevent="$emit('delete_time', time)" v-if="lgPlus"></a>
        <slot></slot>
    </div>
    <div v-else-if="!use_standard_times" class="d-inline-block time-label">
        <medimo-date-time-picker :only_time="true" v-model="timeLabel" v-click-outside="hideClearCross">
          <template v-slot:custom>
            <span class="clickable" @click="showClearCross">{{ timeLabel }}</span>
          </template>
        </medimo-date-time-picker>
        <a class="time-label-button clickable" @click.stop.prevent="$emit('delete_time', time)" v-if="showRemoveIcon">
            <fa-icon icon="fa-solid fa-remove" />
        </a>
        <slot></slot>
    </div>
    <div v-else class="time-label">
        {{timeLabel}}
        <a class="time-label-button clickable" @click.stop.prevent="$emit('delete_time', time)" v-if="showRemoveIcon">
          <fa-icon icon="fa-solid fa-remove" />
        </a>
        <slot></slot>
    </div>
</template>

<script>
import Times from '@/vue/utility/times';
import MedimoFormRow from '@/vue/components/general/form/base/MedimoFormRow';
import MedimoFormColumn from '@/vue/components/general/form/base/MedimoFormColumn';
import MedimoDateTimePicker from '@/vue/components/general/form/base/MedimoDateTimePicker';
import UsesMediaQueries from '@/vue/mixins/UsesMediaQueries';

export default {
  emits: ['delete_time','change_time'],
  components: {
    MedimoFormRow,
    MedimoFormColumn,
    MedimoDateTimePicker,
  },

  props: ['read_only', 'times', 'time', 'index', 'use_standard_times', 'delete_disabled', 'show_hours_only'],

  mixins: [UsesMediaQueries],

  data: function () {
    return {
      originalTime: false,

      // Used on mobile to show the cross after clicking on the time header
      showRemoveIcon: false,
    };
  },

  computed: {
    timeLabel: {
      get() {
        if (this.use_standard_times) {
          const timeIndex = this.times.indexOf(this.time) + 1;
          return 'Tijd ' + timeIndex;
        } else {
          const time = Times.decodeTime(this.time);
          // Als de parent heel smal is besparen we wat ruimte en voorkomen we collapse door alleen de uren te tonen
          if (this.show_hours_only) {
            return time.split(':')[0];
          }
          return time;
        }
      },
      set(value) {
        this.changeTime(value);
      }
    }
  },

  created() {
    //
  },

  mounted() {
    this.originalTime = this.time;
  },

  methods: {
    changeTime(value) {

      this.$emit('change_time', {
        newTime: Times.encodeTime(value),
        oldTime: this.originalTime
      });

      this.originalTime = value;
    },
    showClearCross() {
      this.showRemoveIcon = true;
    },
    hideClearCross() {
      // Need the delay for the click outside
      setTimeout(() => {
        this.showRemoveIcon = false;
      }, 10);
    }
  },

  watch: {
    timeLabel() {
      this.showRemoveIcon = false;
    }
  }
};
</script>
