<template>
  <reading-data-table-scrollable
      id="readings-data-table"
    :number_of_rows="number_of_rows">

    <medimo-form-row v-if="number_of_rows" class="table-head-wrap">

      <medimo-form-column class="col-lg-3 col-md-4 text-nowrap" style="padding-right:0;">
        <div class="font-weight-bold head reading-tr">
          <br />Bepaling
          <div style="float:right;">
            <fa-icon v-if="can_scroll_left" icon="fa-solid fa-square-caret-left" size="lg" class="text-primary" @click="scrollLeft" />
            <fa-icon v-if="can_scroll_right" icon="fa-solid fa-square-caret-right" size="lg" class="text-primary" @click="scrollRight" />
          </div>
        </div>
      </medimo-form-column>

      <medimo-form-column id="scroll-horizontal-head" ref="scroll-horizontal-head" class="col-lg-7 col-md-6 text-nowrap" style="padding:0">
        <div :class="'scrollable-data-table-row ' + (render_row_filler ? 'd-flex' : '')" style="height:54px;">
          <div v-for="date in dates" class="head d-inline-block font-weight-bold" :style="'width:' + cell_width + 'px;'">
            {{ formatDate(date) }}<br />
            {{ formatTime(date) }}
          </div>
          <div v-if="render_row_filler" class="head flex-grow-1"></div>
        </div>
      </medimo-form-column>

      <medimo-form-column id="head-ref" ref="head-ref" class="col-lg-2 col-md-2" style="padding:0;">
        <div class="head d-inline-block font-weight-bold" style="width:45%;">
          <br />Ref
        </div><div class="head d-inline-block font-weight-bold" style="width:55%;">
        <br />Eenheid
      </div>
      </medimo-form-column>

    </medimo-form-row>


    <medimo-form-row v-if="number_of_rows" class="table-body-wrap">

      <medimo-form-column class="col-lg-3 col-md-4 text-nowrap" style="padding-right:0;">
        <div v-for="bepaling in bepalingen" :key="bepaling" class="reading-tr">
          <div class="cell bepaling">
            {{ bepaling }}
          </div>
        </div>
      </medimo-form-column>

      <medimo-form-column id="scroll-horizontal-body" ref="scroll-horizontal-body" class="col-lg-7 col-md-6 text-nowrap" style="padding:0;">
        <template v-for="bepaling in bepalingen">
          <reading-data-table-date-row-dates
              :bepaling="bepaling"
              :readings="results[bepaling]"
              :dates="dates"
              :patient="patient"
              :cell_width="cell_width"
              :render_filler="render_row_filler"
              @selected="modelSelected"
              @updated="scrollHorizontalToEnd"
          ></reading-data-table-date-row-dates>
        </template>
      </medimo-form-column>

      <medimo-form-column class="col-lg-2 col-md-2 text-nowrap" style="padding:0;">
        <template v-for="bepaling in bepalingen">
          <reading-data-table-date-row-ref
              class="reading-data-table-row"
              :bepaling="bepaling"
              :readings="results[bepaling]"
              :dates="dates"
              :patient="patient"
              @selected="modelSelected"
              @updated="scrollHorizontalToEnd"
          ></reading-data-table-date-row-ref>
        </template>
      </medimo-form-column>

    </medimo-form-row>


    <medimo-form-row v-else>
      <medimo-form-column class="col-12">
        <div class="font-weight-bold head reading-tr"><br />Bepaling</div>
        <div class="reading-tr"><div class="cell bepaling">Geen resultaten gevonden</div></div>
      </medimo-form-column>
    </medimo-form-row>

  </reading-data-table-scrollable>
</template>

<script>
import moment from 'moment-mini';
import UsesMediaQueries from '@/vue/mixins/UsesMediaQueries';
import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import ReadingDataTableDateRowDates from './ReadingDataTableDateRowDates';
import ReadingDataTableDateRowRef from './ReadingDataTableDateRowRef';
import MedimoQuickFilterButton from '@/vue/components/general/table/MedimoQuickFilterButton';
import ReadingDataTableScrollable from './ReadingDataTableScrollable';
import ThrottlesRequests from '@/vue/mixins/ThrottlesRequests';
import MedimoFormColumn from '@/vue/components/general/form/base/MedimoFormColumn';

export default {
  emits: ['openEditReadingModal'],

  components: {
    MedimoFormColumn,
    ReadingDataTableScrollable,
    ReadingDataTableDateRowDates,
    ReadingDataTableDateRowRef,
    MedimoQuickFilterButton
  },

  props: {
    'refresh_table': {required: true},
    'patient': {required: true},
  },

  mixins: [
    BaseComponentsMixin,
    ThrottlesRequests,
    UsesMediaQueries
  ],

  data: function () {
    return {
      store_name: 'readings',
      can_scroll_left: false,
      can_scroll_right: false,
      times_scrolled_left: 0,
      cell_width: 93,
    };
  },

  mounted() {
    // manual set privacy filter on patient_id
    this.$store.commit(this.store_name + '/set_privacy_filter', {
      property: 'patient_id',
      value: this.patient_id,
    });

    window.addEventListener('resize', this.scrollHorizontalToEnd);
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.scrollHorizontalToEnd);
  },

  methods: {
    formatDate(date) {
      return moment(date).format('DD-MM-YYYY');
    },
    formatTime(date) {
      return moment(date).format('HH:mm');
    },
    searchPage(page_number, show_loader = true, delay = 300) {
      this.show_loader = show_loader;
      this.dispatchThrottled(this.store_name + '/search', {
        page: page_number,
      }, delay).catch(error => {
        if (error.message !== 'Throttled') {
          // nothing we can do
          this.$root.sendSentry(error);
        }
      });
    },
    scrollHorizontalToEnd() {
      const scrollableHead = this.$refs['scroll-horizontal-head'];
      if (typeof scrollableHead === 'undefined') {
        return;
      }
      this.times_scrolled_left = 0;
      this.scroll(scrollableHead.$el.scrollWidth);
    },
    scrollLeft() {
      this.times_scrolled_left++;
      this.scroll(-this.cell_width);
    },
    scrollRight() {
      this.times_scrolled_left--;
      this.scroll(this.cell_width);
    },
    scroll(amount) {
      const scrollableHead = this.$refs['scroll-horizontal-head'];
      if (typeof scrollableHead === 'undefined') {
        return;
      }
      scrollableHead.$el.scrollLeft += amount;

      // scroll rows
      this.$refs['scroll-horizontal-body'].$el.scrollLeft += amount;

      this.setCanScroll();
    },
    setCanScroll() {
      const scrollableHead = this.$refs['scroll-horizontal-head'];
      if (typeof scrollableHead === 'undefined') {
        return;
      }

      const headUnit = this.$refs['head-ref'].$el;
      const widthScrollableDiv = scrollableHead.$el.getBoundingClientRect().width;
      const positionLastColumn = headUnit.offsetLeft + headUnit.offsetWidth;
      const emptyWidth = widthScrollableDiv - positionLastColumn;

      this.can_scroll_left = (scrollableHead.$el.scrollLeft > 0 && emptyWidth < 0);
      this.can_scroll_right = (this.times_scrolled_left > 0);
    },
    modelSelected(model) {
      this.$emit('openEditReadingModal', model);
    },
  },

  computed: {
    render_row_filler() {
      if (this.dates.length < 7) {
        return true;
      }
      return false;
    },
    number_of_rows() {
      return this.models.length;
    },
    patient_id() {
      return this.patient.id;
    },
    models() {
      return this.$store.getters[this.store_name + '/filtered_search_results'];
    },
    results() {
      return this.$store.getters[this.store_name + '/resultsPerBepaling'];
    },
    dates() {
      return this.$store.getters[this.store_name + '/dates'];
    },
    bepalingen() {
      return this.$store.getters[this.store_name + '/bepalingen'];
    },
    can_scroll_any() {
      return this.can_scroll_left || this.can_scroll_right;
    },
  },
  watch: {
    refresh_table() {
      this.searchPage(1, true, 0);
    },
    models() {
      this.times_scrolled_left = 0;
    }
  }
};
</script>

<style scoped>

.table-head-wrap {
  overflow-y:hidden;
  overflow-x:hidden;
  margin-right:10px;
}
.table-body-wrap {
  height:466px;
  overflow-y:scroll;
  overflow-x:hidden;
}
#scroll-horizontal-head, #scroll-horizontal-body {
  overflow-x:hidden;
  overflow-y:hidden;
  padding-right:0;
}

</style>
