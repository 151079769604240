<script>
import moment from 'moment-mini';

export default {
  data: function () {
    return {
      pre_validation_message: null,
    };
  },

  methods: {
    validateInput() {
      if (this.medication_guard_view) {
        this.store();
        return;
      }

      this.pre_validation_message = this.validateRegisterAndStopDate();
      if (this.pre_validation_message === null) {
        this.store();
      }
    },

    validateRegisterAndStopDate() {
      if (this.stop_date && this.stop_date !== '') {
        const stop_date = moment(this.stop_date);
        const register_date = moment(this.register_date);

        if (this.stopDateIsBeforeStartDate(stop_date, register_date)) {
          return 'De stopdatum ligt voor de vaststellingsdatum, weet u dit zeker?';
        }

        if (this.stopDateIsBeforeNow(stop_date)) {
          return 'De stopdatum ligt in het verleden, weet u dit zeker?';
        }
      }

      return null;
    },
    stopDateIsBeforeNow(stop_date) {
      return stop_date.isBefore(moment());
    },
    stopDateIsBeforeStartDate(stop_date, register_date) {
      return stop_date.isBefore(register_date);
    }
  },

  watch: {
    register_date() {
      this.pre_validation_message = null;
    },
    stop_date() {
      this.pre_validation_message = null;
    }
  }
};
</script>
