<template>
    <div class="col-12 text-left"
    >
        <div :class="'p-2 d-flex ' + (small ? 'text-small text-muted mb-1' : '')">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';

export default {
  components: {},

  props: {
    // Will make the text smaller and muted
    'small': {default: false},
  },

  mixins: [BaseComponentsMixin],

  data: function () {
    return {
      //
    };
  },

  computed: {},

  created() {
    //
  },

  mounted() {
    //
  },
  unmounted() {
    //
  },

  methods: {},

  watch: {}
};
</script>
