class InstanceContainer {
  instances = new Map<string, object>();

  register(instanceKey: string, instance: object): void {
    this.instances.set(instanceKey, instance);
  }

  provide(instanceKey: string): object | null {
    if (!this.instances.has(instanceKey)) {
      return null;
    }
    return this.instances.get(instanceKey) as object;
  }
}

export default InstanceContainer;
