<template>
    <transition name="fade">
        <li class="nav-item d-flex align-items-center" v-if="show">
            <div id="medimo-notifications" v-click-outside="hideResults">
                <fa-icon icon="fa-solid fa-bell" :class="'clickable bell text-primary' + (shake ? ' shake' : '')" size="lg" @click="show_notifications = !show_notifications" />
                <span v-if="new_notification_count > 0" class="clickable badge badge-danger badge-pill">{{ new_notification_count }}</span>
                <div class="list-group" ref="medimo-notifications-list" v-show="show_notifications">
                    <transition name="fade">
                        <div class="row m-2">
                            <div class="col-12 my-2"
                                 v-for="notification in notifications" :key="notification.id"
                            >
                                <div class="">
                                    <medimo-notification :to="notification.href"
                                                         :type="notification.type"
                                                         :hide_after="notification.visible_for"
                                                         :id="notification.id"
                                    >
                                        <fa-icon icon="fa-solid fa-bell" class="bell" size="lg" /> {{ notification.title }}
                                        <template v-slot:body v-if="notification.message">
                                            {{ notification.message }}
                                        </template>
                                    </medimo-notification>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </li>
    </transition>
</template>

<script>
import MedimoLink from '@/vue/components/general/MedimoLink';
import MedimoButtonLink from '@/vue/components/general/MedimoButtonLink';
import MedimoNotification from '@/vue/components/general/MedimoNotification';
import moment from 'moment-mini';

export default {
  components: {
    MedimoNotification,
    MedimoButtonLink,
    MedimoLink,
  },

  props: [],

  mixins: [],

  data: function () {
    return {
      shake: false,
      show_notifications: false,

      // We store the timeOut here so we can cancel it if the user navigates away
      updateNewsReadTimeout: null,
    };
  },

  computed: {
    show() {
      return this.$store.state.current_user.isLoaded && this.notification_count > 0;
    },
    current_user() {
      return this.$store.state.current_user.data;
    },
    medimo_notifications_dropdown_closed_x_times: {
      get() {
        return this.$store.state.current_user.medimo_notifications_dropdown_closed_x_times;
      },
      set(amount) {
        this.$store.commit('current_user/set_medimo_notifications_dropdown_closed_x_times', amount);
      }
    },

    notifications() {
      return this.$store.state.notifications.data;
    },
    new_notification_count() {
      return this.notifications.filter(notification => {
        return notification.new;
      }).length;
    },
    notification_count() {
      return this.notifications.length;
    },
    seconds_since_last_login() {
      const last_login = moment(this.current_user.latest_login);
      const now = moment();
      return (now - last_login) / 1000;
    }
  },

  created() {
    // this.$store.dispatch('notifications/add_notification', )
  },

  unmounted() {
    this.cancelUpdateNewsReadTimestamp();
  },

  mounted() {
    // Slight delay on all the values to make sure no notys are forgotten
    // Saves setting a watcher and probably getting unexpected behaviour in the future
    setTimeout(() => {
      // Shake it on the homepage!
      if (this.$route.path === '/' && this.new_notification_count > 0) {
        this.shakeIt();
      }

      // When there are new notifications, and we're within 30 seconds logging in, open it
      if (this.new_notification_count > 0 && this.seconds_since_last_login <= 30) {
        this.show_notifications = true;
      }
    }, 1000);
  },

  methods: {
    shakeIt() {
      this.shake = true;
      setTimeout(() => {
        this.shake = false;
      }, 1500); // Shake it 3 times
    },
    hideResults() {
      this.show_notifications = false;
      this.medimo_notifications_dropdown_closed_x_times++;
    },
    updateNewsReadTimestamp(immediately = false) {
      // This updates the news_read value of users
      // after a 10 second delay
      this.updateNewsReadTimeout = setTimeout(() => {
        this.$store.dispatch('current_user/update', {
          'data': {
            'latest_news_read': moment().format('YYYY-MM-DD HH:mm:ss'),
          }
        });
      }, (immediately ? 0 : 10000));
    },
    cancelUpdateNewsReadTimestamp() {
      // If the timeOut is set, it's an ID
      if (this.updateNewsReadTimeout > 0) {
        clearTimeout(this.updateNewsReadTimeout);
      }
    },
    checkForWarningNotifications() {
      if (typeof this.current_page === 'undefined') {
        return null;
      }
    }
  },

  watch: {
    show_notifications(show_notifications) {
      // Update the user's timestamp when the notifications are shown and there
      // are new news items
      if (show_notifications && this.new_notification_count > 0) {
        this.updateNewsReadTimestamp();
      } else {
        this.cancelUpdateNewsReadTimestamp();
      }
    },
    current_user() {
      // And we check if we need to update the warning notifications
      this.checkForWarningNotifications();
    },
    new_notification_count(new_value, old_value) {
      if (new_value > old_value) {
        this.show_notifications = true;
      }
    },
    medimo_notifications_dropdown_closed_x_times(amount) {
      // pas als het 5 is, zodat het max 1 keer voor komt
      if (amount === 5) {
        this.updateNewsReadTimestamp(true);
      }
    }
  }
};
</script>
