<template>
    <medi-modal
        :loading="loading"
        :title="'Nieuwe episode: '+zindex_episode.text"
        size="medium"
        @closed="onClosed()"
    >
        <medimo-form>

            <medimo-notification type="danger" :exclamation="true" v-if="already_active">
                Deze episode is al actief!
            </medimo-notification>

            <medimo-form-row>
                <medimo-form-column column_classes="col-12">
                    <medimo-labeled-input
                        :disabled="true"
                        label_column_classes="col-12 col-sm-3"
                        label="Code"
                        name="code"
                        :modelValue="zindex_episode.code + ' - ' + zindex_episode.text"
                    ></medimo-labeled-input>
                </medimo-form-column>
            </medimo-form-row>

            <medimo-form-row>
                <medimo-form-column column_classes="col-12">
                    <medimo-validated-input
                        :disabled="already_active"
                        label_column_classes="col-12 col-sm-3"
                        label="Episode"
                        placeholder=""
                        name="title"
                        v-model="title">
                    </medimo-validated-input>
                </medimo-form-column>
            </medimo-form-row>

            <medimo-form-row>
                <medimo-form-column column_classes="col-12">
                    <medimo-validated-date-picker
                        :disabled="already_active"
                        label_column_classes="col-12 col-sm-3"
                        label="Datum vanaf"
                        datetime_format="DD-MM-YYYY HH:mm"
                        name="start_date"
                        v-model="start_date">
                    </medimo-validated-date-picker>
                </medimo-form-column>
            </medimo-form-row>

            <medimo-form-row>
                <medimo-form-column column_classes="col-12">
                    <medimo-validated-date-picker
                        :disabled="already_active"
                        label_column_classes="col-12 col-sm-3"
                        label="Datum tot"
                        datetime_format="DD-MM-YYYY HH:mm"
                        name="end_date"
                        placeholder="(Optioneel)"
                        v-model="end_date">
                    </medimo-validated-date-picker>
                </medimo-form-column>
            </medimo-form-row>

        </medimo-form>

        <template v-slot:action-buttons>
            <medimo-button v-if="!already_active" :disabled="saveButtonDisabled" @click="store">
                Opslaan
            </medimo-button>
            <medimo-button @click="close(false)" type="outline-primary">
                Annuleren
            </medimo-button>
        </template>

    </medi-modal>
</template>

<script>
import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import moment from 'moment-mini';
import MedimoFilterButton from '@/vue/components/general/form/base/MedimoFilterButton';
import MedimoAlert from '@/vue/components/general/MedimoAlert';
import MedimoNotification from '@/vue/components/general/MedimoNotification';

export default {
  components: {
    MedimoNotification,
    MedimoAlert,
    MedimoFilterButton
  },

  emits: ['closed'],

  props: {
    'zindex_episode': {required: true},
    'patient': {required: true},
  },

  mixins: [
    BaseComponentsMixin
  ],

  data: function () {
    return {
      loading: false,
      code: '',
      title: '',
      start_date: moment(moment(), "DD-MM-YYYY"),
      end_date: '',
      add_disabled: true,
      already_active: false,
    };
  },

  computed: {
    saveButtonDisabled() {
      return !(this.title);
    },
  },

  created() {
    this.title = this.zindex_episode.text;
  },

  mounted() {
    this.already_active = this.$store.getters['episodes/find_by_code'](this.zindex_episode.code);
  },

  methods: {
    close(refresh) {
      this.$emit('closed', refresh);
    },
    onClosed(event) {
      this.$emit('closed');
    },

    store() {
      this.loading = true;
      this.$store.dispatch('episodes/store', {
        patient_id: this.patient.id,
        data: {
          code: this.zindex_episode.id,
          title: this.title,
          start_date: this.start_date,
          end_date: this.end_date
        }
      }).then(() => {
        this.loading = false;
        this.close(true);
      }).catch(() => {
        this.loading = false;
      });
    },
  },

  watch: {
    //
  }
};
</script>
