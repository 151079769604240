import BaseModule from '@/vue/store/modules/base_module';
import moment from 'moment-mini';

// initial state
const state = {
  ...BaseModule.state,
  data: [],
  last_update: null,
};

// getters
const getters = {
  ...BaseModule.getters,
  last_update(state, getters, rootState, rootGetters) {
    return state.last_update;
  },
};

// actions
const actions = {
  ...BaseModule.actions,

  fetch({state, commit, dispatch, getters, rootState}, payload) {
    // Als de data nog nooit geupdatete is, of als dat meer dan 14 dagen geleden is.
    if (state.last_update === null
      || typeof state.last_update === 'undefined'
      || moment(state.last_update).add(14,'days').isBefore(moment())) {
      commit('set_last_update');
      return dispatch('api/getEndpoint', {
        endpoint: '/api/v1/patients/' + payload.patient_id + '/dispense-settings/templates',
      }, {root: true}).then(response => {
        commit('set_data', response.data.data);
      });
    }
  },
};

// mutations
const mutations = {
  ...BaseModule.mutations,
  set_last_update(state, payload) {
    state.last_update = moment().format('YYYY-MM-DD HH:mm');
  },
  set_data(state, payload) {
    state.data = payload;
  },
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
