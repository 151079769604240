<template>
    <mq-layout :mq="visible_on">
        <slot></slot>
    </mq-layout>
</template>

<script>
export default {
  components: {},

  props: [
    'visible_on' // Can be sm, md, lg and xl. use a "+" like md+ to say "md and higher"
  ],

  mixins: [],

  data: function () {
    return {
      //
    };
  },

  computed: {
    //
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    //
  },

  watch: {
    //
  }
};
</script>
