<template>
  <medimo-link v-bind="$attrs"
               :class="'medimo-dropdown-item dropdown-item dropdown-item-' + variant + ' ' + (disabled ? 'disabled' : '')"
               :to="to"

               :trigger="trigger"

               @click="isClicked"
  >
    <slot></slot>
  </medimo-link>
</template>

<script>
import DeterminesVueOrHrefLink from '@/vue/mixins/navigation/DeterminesVueOrHrefLink';
import MedimoLink from './MedimoLink';

export default {
  components: {
    MedimoLink
  },

  emits: ['click'],

  props: {
    'to': {default: ''},

    // Colors the content. Works with any of the bootstrap $theme-colors and medimo $theme-colors
    'variant': {default: 'medimo'},

    // Disables the button for read-only purposes
    'disabled': {default: false},

    // Closes the dropdown open after a click;
    // Sometimes you might not want this: for example with the "Overrule Blokkade" option, that enables other dropdown items
    'close_dropdown': {default: true},
  },

  mixins: [
    DeterminesVueOrHrefLink
  ],

  data: function () {
    return {
      // Changing this will trigger a programmatic navigation
      trigger: false,
    };
  },

  computed: {
    //
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    // We need to catch this click event because we don't just want to navigate, we want to navigate AND send word to the MedimoDropdown to close!
    isClicked() {
      if (!this.disabled) {

        if (this.close_dropdown) {
          this.$parent.open_dropdown = false;
        }

        if (this.to.length > 0) {
          this.trigger = !this.trigger;
        }

        this.$emit('click');
      }
    }
  },

  watch: {
    //
  }
};
</script>
