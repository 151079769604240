import BaseModule from '@/vue/store/modules/base_module';

// initial state
const state = {
  ...BaseModule.state,
  data: [],
  quick_filters: {
    'geen inschrijftoken': false,
    'bijna verlopen': false,
  },
  toggleQuickFilters: true,
  // sort_columns: [{column_name: 'name', direction: 'ASC'}],
};

// getters
const getters = {
  ...BaseModule.getters,
  geen_inschrijftoken_quick_filter(state, getters, rootState, rootGetters) {
    return (data) => {
      return data;
    };
  },
  bijna_verlopen_quick_filter(state, getters, rootState, rootGetters) {
    return (data) => {
      return data;
    };
  },
};

// actions
const actions = {
  ...BaseModule.actions,
  fetch({state, commit, dispatch}, payload) {
    return dispatch('api/getEndpoint', {
      endpoint: payload.endpoint
    }, {root: true}).then(response => {
      commit('set_data', response.data.data);
    });
  },
  signTokens({state, commit, dispatch}, payload) {
    return dispatch('api/postEndpoint', {
      endpoint: '/api/v1/lsp/token/signTokens',
      data: {
        wardId: payload.wardId,
        patients: payload.patients,
      }
    }, {root: true});
  }
};

// mutations
const mutations = {
  ...BaseModule.mutations,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
