<template>
  <medi-modal
      :loading="loading"
      :title="modalTitle"
      size="medium"
      @closed="onClosed()"
  >
    <medimo-form>

      <medimo-form-row>
        <medimo-form-column column_classes="col-12">
          <medimo-labeled-input
              :disabled="true"
              label_column_classes="col-12 col-sm-3"
              label="Bepaling"
              name="bepaling"
              v-model="bepaling"
          ></medimo-labeled-input>
        </medimo-form-column>
      </medimo-form-row>

      <medimo-form-row>
        <medimo-form-column column_classes="col-6">
          <medimo-validated-input
              label_column_classes="col-12 col-sm-6"
              label="Uitslag"
              placeholder=""
              name="uitslag"
              v-model="uitslag">
          </medimo-validated-input>
        </medimo-form-column>
      </medimo-form-row>

      <medimo-form-row>
        <medimo-form-column column_classes="col-6">
          <medimo-validated-input
              label_column_classes="col-12 col-sm-6"
              label="Eenheid"
              placeholder=""
              name="eenheid"
              v-model="eenheid">
          </medimo-validated-input>
        </medimo-form-column>
      </medimo-form-row>

      <medimo-form-row>
        <medimo-form-column column_classes="col-6">
          <medimo-validated-input
              label_column_classes="col-12 col-sm-6"
              label="Ondergrens"
              placeholder=""
              name="ondergrens"
              v-model="ondergrens">
          </medimo-validated-input>
        </medimo-form-column>
      </medimo-form-row>

      <medimo-form-row>
        <medimo-form-column column_classes="col-6">
          <medimo-validated-input
              label_column_classes="col-12 col-sm-6"
              label="Bovengrens"
              placeholder=""
              name="bovengrens"
              v-model="bovengrens">
          </medimo-validated-input>
        </medimo-form-column>
      </medimo-form-row>

      <medimo-form-row>
        <medimo-form-column column_classes="col-12">
          <medimo-validated-date-time-picker
              label_column_classes="col-12 col-sm-3"
              label="Meetdatum en -tijd"
              datetime_format="DD-MM-YYYY HH:mm"
              name="datum"
              v-model="datum">
          </medimo-validated-date-time-picker>
        </medimo-form-column>
      </medimo-form-row>

      <medimo-form-row>
        <medimo-form-column column_classes="col-12">
          <medimo-validated-textarea
              label_column_classes="col-12 col-sm-3"
              label="Opmerking"
              placeholder=""
              name="opmerking"
              rows="2"
              v-model="opmerking">
          </medimo-validated-textarea>
        </medimo-form-column>
      </medimo-form-row>

    </medimo-form>

    <template v-slot:action-buttons v-if="!confirm_delete">
      <medimo-button class="mr-auto" type="outline-primary" @click.stop="confirm_delete = true">
        Verwijderen
      </medimo-button>
      <medimo-button :disabled="saveButtonDisabled" @click="update">
        Opslaan
      </medimo-button>
      <medimo-button @click="close(false)" type="outline-medimo">
        Annuleren
      </medimo-button>
    </template>

    <template v-slot:action-buttons v-else>
      <medimo-notification type="danger" class="mr-auto col-md-6">
        <fa-icon icon="fa-solid fa-bell" size="lg" class="bell" /> Weet je het zeker?
      </medimo-notification>
      <medimo-button @click="confirm_delete = false" type="outline-primary">
        Annuleren
      </medimo-button>
      <medimo-button @click.prevent="remove">
        Ja, ik weet het zeker
      </medimo-button>
    </template>

  </medi-modal>
</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import MedimoAlert from '@/vue/components/general/MedimoAlert';
import MedimoNotification from '@/vue/components/general/MedimoNotification';
import MedimoValidatedTextarea from '@/vue/components/general/form/MedimoValidatedTextarea';

export default {
  emits: ['closed'],
  components: {
    MedimoValidatedTextarea,
    MedimoNotification,
    MedimoAlert
  },

  props: [
    'reading',
  ],

  mixins: [
    BaseComponentsMixin
  ],

  data: function () {
    return {
      loading: false,
      save_disabled: true,

      code: '',
      bepaling: '',
      uitslag: '',
      eenheid: '',
      ondergrens: '',
      bovengrens: '',
      opmerking: '',
      datum: '',

      confirm_delete: false,
    };
  },

  computed: {
    saveButtonDisabled() {
      // uitslag aangepast
      if (this.uitslag && this.uitslag !== this.reading.uitslag) {
        return false;
      }
      // datum aangepast
      if (this.datum && this.datum !== this.reading.datum) {
        return false;
      }
      // ondergrens aangepast
      if (this.ondergrens && this.ondergrens !== this.reading.ondergrens) {
        return false;
      }
      // bovengrens aangepast
      if (this.bovengrens && this.bovengrens !== this.reading.bovengrens) {
        return false;
      }
      // eenheid aangepast
      if (this.eenheid && this.eenheid !== this.reading.eenheid) {
        return false;
      }
      // opmerking aangepast
      if (this.opmerking && this.opmerking !== this.reading.opmerking) {
        return false;
      }

      return true;
    },
    modalTitle() {
      if (this.confirm_delete === true) {
        return 'Verwijder meetwaarde: ' + this.reading.bepaling;
      } else {
        return 'Wijzig meetwaarde: ' + this.reading.bepaling;
      }
    },
  },

  created() {
  },

  mounted() {
    this.loadReading();
  },

  methods: {
    close(refresh) {
      this.$emit('closed', refresh);
    },
    onClosed(event) {
      this.$emit('closed');
    },

    loadReading() {
      this.bepaling = this.reading.bepaling;
      this.uitslag = this.reading.uitslag;
      this.eenheid = this.reading.eenheid;
      this.ondergrens = this.reading.ondergrens;
      this.bovengrens = this.reading.bovengrens;
      this.opmerking = this.reading.opmerking;

      this.datum = this.reading.datum;
      if (this.datum === null) {
        this.datum = '';
      }
    },

    update() {
      this.loading = true;
      this.$store.dispatch('readings/update', {
        patient_id: this.reading.patient_id,
        reading_id: this.reading.id,
        data: {
          uitslag: this.uitslag,
          eenheid: this.eenheid,
          ondergrens: this.ondergrens,
          bovengrens: this.bovengrens,
          opmerking: this.opmerking,
          datum: this.datum,
          updated_at: this.reading.updated_at
        }
      }).then(() => {
        this.loading = false;
        this.close(true);
      }).catch(() => {
        this.loading = false;
      });
    },

    remove() {
      this.loading = true;
      this.$store.dispatch('readings/delete', {
        patient_id: this.reading.patient_id,
        reading_id: this.reading.id,
      }).then(() => {
        this.loading = false;
        this.close(true);
      }).catch(() => {
        this.loading = false;
      });
    }
  },

  watch: {
    reading(value) {
      this.loadReading();
    },
    trigger() {
      this.confirm_delete = false;
    }
  }
};
</script>
