<template>
    <div
            ref="parent"
            :class="[{
      'is-focused': isFocus,
      'has-value': value,
      'has-error': errorHint,
      'is-disabled': isDisabled,
      'is-dark': dark,
      'no-label': noLabel
    }, inputSize]"
            class="field flex align-center"
            @click="focusInput"
    >
        <input
                :id="$attrs.id"
                ref="CustomInput"
                v-bind="$attrs"
                :value="value"
                :placeholder="label"
                :style="[borderStyle]"
                type="text"
                class="field-input"
                :class="{ 'no-clear-button': noClearButton }"
                readonly
                @focus="$emit('focus')"
                @blur="$emit('blur')"
                @click="$emit('click')"
        >
        <label
                v-if="!noLabel"
                ref="label"
                :for="$attrs.id"
                :class="errorHint ? 'text-danger' : null"
                :style="[colorStyle]"
                class="field-label"
                @click="focusInput"
        >
            {{ hint || label }}
        </label>
        <CustomButton
                v-if="hasClearButton"
                :color="dark ? '#757575' : 'rgba(0, 0, 0, 0.54)'"
                :dark="dark"
                class="field-clear-button"
                round
                @click="$emit('clear')"
        >
      <span class="fs-16">
        ✕
      </span>
        </CustomButton>
    </div>
</template>

<script>
import CustomButton from './../CustomButton';

export default {
  name: 'CustomInput',
  components: {
    CustomButton
  },
  emits: ['focus','blur','click','clear'],
  inheritAttrs: false,
  props: {
    isFocus: {type: Boolean, default: false},
    value: {type: [String, Object], required: false, default: null},
    label: {type: String, default: 'Select date & time'},
    noLabel: {type: Boolean, default: false},
    hint: {type: String, default: null},
    errorHint: {type: Boolean, default: null},
    color: {type: String, default: null},
    dark: {type: Boolean, default: false},
    inputSize: {type: String, default: null},
    noClearButton: {type: Boolean, default: false}
  },
  computed: {
    borderStyle() {
      const cond = (this.isFocus && !this.errorHint);
      return cond
        ? {border: `1px solid ${this.color}`}
        : null;
    },
    colorStyle() {
      const cond = this.isFocus;
      return cond
        ? {color: `${this.color}`}
        : null;
    },
    hasClearButton() {
      return !this.noClearButton && !this.disabled && this.value;
    },
    /**
       * Returns true if the field is disabled
       * @function isDisabled
       * @returns {boolean}
       */
    isDisabled() {
      return typeof this.$attrs.disabled !== 'undefined' && this.$attrs.disabled !== false;
    }
  },
  methods: {
    focusInput() {
      this.$refs.CustomInput.focus();
      this.$emit('focus');
    }
  }
};
</script>
