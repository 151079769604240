import BaseModule from '@/vue/store/modules/base_module';
import moment from 'moment-mini';

const state = {
  ...BaseModule.state,
  guardIsTriggered: false,
  quick_filters: {},
};

// getters
const getters = {
  ...BaseModule.getters,
  unique_schema_identifier(state, getters, rootState, rootGetters) {
    return (dispense_schema_setting) => {
      return getters.unique_identifier(dispense_schema_setting.drug_id, dispense_schema_setting.first_dosing_instruction_id);
    };
  },
  unique_identifier(state, getters, rootState, rootGetters) {
    return (drug_id, dosing_instruction_id) => {
      let first_dosing_instruction_id = 0;
      if (drug_id == 90000001) {
        first_dosing_instruction_id = dosing_instruction_id;
      }

      return drug_id + '_' +  first_dosing_instruction_id;
    };
  },
  /**
   * Deze returned op basis van de identifier andere schema's voor datzelfde
   * medicijn
   */
  sibling_schemas(state, getters, rootState, rootGetters) {
    return (unique_identifier, schema_setting) => {
      const originalId = typeof schema_setting !== 'undefined' ? schema_setting.id : null;
      return state.data.filter(dispense_schema_setting => {
        return getters.unique_identifier(dispense_schema_setting.drug_id, dispense_schema_setting.first_dosing_instruction_id) === unique_identifier && dispense_schema_setting.id !== originalId;
      });
    };
  }
};

// actions
const actions = {
  ...BaseModule.actions,

  /**
   * Update en store gebruiken hetzelde endpoint. In beide gevallen updaten we daarna ook de hele dataset
   */
  delete({state, commit, dispatch, getters, rootState}, payload) {
    if (moment(payload.schema_setting.start_datetime).isAfter(moment())) {
      this.$store.dispatch('notifications/addDangerAlert', {message: 'Schema is al gestart. Verwijderen is niet meer mogelijk, enkel stop zetten.'});
      return;
    }

    return dispatch('api/postEndpoint', {
      endpoint: payload.endpoint,
      data: {id: payload.schema_setting.id}
    }, {root: true}).then(response => {
      this.$store.dispatch('notifications/addSuccessAlert', {message: 'Schema verwijderd'});
    }).catch(error => {

    });
  },
  /**
   * Update en store gebruiken hetzelde endpoint. In beide gevallen updaten we daarna ook de hele dataset
   */
  store({state, commit, dispatch, getters, rootState}, payload) {
    return dispatch('api/postEndpoint', {
      endpoint: payload.endpoint,
      data: payload.data
    }, {root: true});
  },
  fetch({state, commit, dispatch, getters, rootState}, payload) {
    return dispatch('api/getEndpoint', {
      endpoint: '/api/v1/patients/' + payload.patient_id + '/dispense-settings/',
    }, {root: true}).then(response => {
      commit('set_state', {
        property: 'data',
        value: response.data.data,
      });
    });
  },
  /*
   |--------------------------------------------------------------------------
   | Guards
   |--------------------------------------------------------------------------
   */
  /**
   * Checkt of start ouder is dan nu
   * Checkt of start of stop date niet <> start en stop_date van sibling schemas zitten
   * Negeert zichzelf (in geval van updates)
   * @param state
   * @param commit
   * @param dispatch
   * @param getters
   * @param rootState
   * @param schema_setting
   * @returns {boolean}
   */
  guardStartStopDates({state, commit, dispatch, getters, rootState}, schema_setting) {
    // Moet ouder zijn dan nu
    // Moet niet >= start_date en <= stop_date van andere schemas
    // als hij een ID > 0 heeft mag die die check negeren
    const unique_identifier = getters['unique_schema_identifier'](schema_setting);
    const siblings = getters['sibling_schemas'](unique_identifier, schema_setting);

    let startIsBeforeToday = false;
    // Checkt of start na vandaag is
    if (moment(schema_setting.start_date).isBefore(moment().format('YYYY-MM-DD'))) {
      startIsBeforeToday = true;
    }
    let stopIsAfterStart = false;
    if (schema_setting.stop_date !== null) {
      if (moment(schema_setting.start_date).isAfter(moment(schema_setting.stop_date))) {
        stopIsAfterStart = true;
      }
    }

    let startIsBetweenStartStop = false;
    let stopIsBetweenStartStop = false;
    // Negeert zichzelf (in geval van updates)
    siblings.filter(sibling => sibling.id !== schema_setting.id).forEach(sibling => {
      // Checkt of start of stop date niet <> start en stop_date van sibling schemas zitten
      // door de [] is ook de dag zelf een match.
      if (moment(schema_setting.start_date).isBetween(sibling.start_date, sibling.stop_date, 'day', [])) {
        startIsBetweenStartStop = true;
      }
      if (schema_setting.stop_date !== null) {
        if (moment(schema_setting.stop_date).isBetween(sibling.start_date, sibling.stop_date, 'day', [])) {
          stopIsBetweenStartStop = true;
        }
      }
    });

    if (startIsBeforeToday && !schema_setting.id) {
      dispatch('triggerGuard',
        'De start datum ligt in het verleden',
      );
    }
    if (stopIsAfterStart) {
      dispatch('triggerGuard',
        'De stop datum ligt voor de start datum',
      );
    }
    if (startIsBetweenStartStop) {
      dispatch('triggerGuard',
        'De start datum ligt tussen de start/stop van andere "' + schema_setting.drug_name + '" schemas',
      );
    }
    if (stopIsBetweenStartStop) {
      dispatch('triggerGuard',
        'De stop datum ligt tussen de start/stop van andere "' + schema_setting.drug_name + '" schemas'
      );
    }
  },
  /**
   * Alle related schemas moeten een stop date hebben
   * @param state
   * @param commit
   * @param dispatch
   * @param getters
   * @param rootState
   * @param schema_setting
   * @returns {boolean}
   */
  guardRelatedSchemasAllHaveStopDate({state, commit, dispatch, getters, rootState}, schema_setting) {
    // Alle related schemas moeten een stop date hebben
    const unique_identifier = getters['unique_schema_identifier'](schema_setting);
    const siblings = getters['sibling_schemas'](unique_identifier, schema_setting);
    let relatedSchemasHaveNoStopDate = false;
    siblings.filter(sibling => sibling.id !== schema_setting.id).forEach(sibling => {
      if (sibling.stop_date === "" || sibling.stop_date === null) {
        relatedSchemasHaveNoStopDate = true;
      }
    });

    if (relatedSchemasHaveNoStopDate) {
      dispatch('triggerGuard',
        'Er zijn nog actieve "' + schema_setting.drug_name + '" schemas zonder stop datum.'
      );
    }
  },
  /**
   * Verwijderen kan alleen als hij in de toekomst is
   * @param state
   * @param commit
   * @param dispatch
   * @param getters
   * @param rootState
   * @param schema_setting
   * @returns {boolean}
   */
  guardHasNotStartedYet({state, commit, dispatch, getters, rootState}, schema_setting) {
    // Alle related schemas moeten een stop date hebben
    const unique_identifier = getters['unique_schema_identifier'](schema_setting);
    const siblings = getters['sibling_schemas'](unique_identifier, schema_setting);
    let relatedSchemasHaveNoStopDate = false;
    siblings.forEach(sibling => {
      if (sibling.stop_date === "" || sibling.stop_date === null) {
        relatedSchemasHaveNoStopDate = true;
      }
    });

    if (relatedSchemasHaveNoStopDate) {
      dispatch('triggerGuard',
        'Er zijn nog actieve "' + schema_setting.drug_name + '" schemas zonder stop datum.'
      );
    }
  },
  triggerGuard({state, commit, dispatch, getters, rootState}, message) {
    commit('guardIsTriggered');
    dispatch('notifications/addDangerAlert', {
      message: message,
    }, {root: true});
  }
};

// mutations
const mutations = {
  ...BaseModule.mutations,
  guardIsTriggered (state) {
    state.guardIsTriggered = true;
  },
  resetGuards (state) {
    state.guardIsTriggered = false;
  },
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
