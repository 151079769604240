<template>
  <!-- Soms wil je een quick filter "onzichtbaar" toepassen, vandaar dat we een optionele "visible" waarde hebben -->
  <div class="row" v-if="quick_filters && Object.keys(quick_filters).length">
    <medimo-form-column v-for="(quick_filter_value, quick_filter_name) in quick_filters" :key="quick_filter_name">
      <template v-if="typeof quick_filter_value.visible === 'undefined' || quick_filter_value.visible === true">
        <!-- Als de Value een array is, hebben we met grouped buttons te maken en tonen we die anders -->
        <medimo-quick-filter-button-group v-if="areGroupedQuickFilters(quick_filter_value)"
                                          class="mb-2"
                                          :quick_filter_group_name="quick_filter_name"
                                          :quick_filter="quick_filter_value"
                                          :store_name="store_name"
        >
        </medimo-quick-filter-button-group>
        <medimo-quick-filter-button v-else
                                    class="mb-2"
                                    :store_name="store_name"
                                    :quick_filter_name="quick_filter_name"
                                    :quick_filter_value="quick_filter_value"
        >
        </medimo-quick-filter-button>
      </template>
    </medimo-form-column>
  </div>
</template>

<script>

import MedimoFormColumn from '@/vue/components/general/form/base/MedimoFormColumn';
import MedimoQuickFilterButton from './MedimoQuickFilterButton';
import MedimoQuickFilterButtonGroup from './MedimoQuickFilterButtonGroup';

/**
 * Dit component is nodig om de juiste logica toe te kunnen passen voor iedere
 * QuickFilter. Het aantal opties is te groot geworden om in de MedimoTable te proppen
 */
export default {
  components: {
    MedimoQuickFilterButtonGroup,
    MedimoQuickFilterButton,
    MedimoFormColumn
  },

  props: ['store_name', 'quick_filters'],

  mixins: [],

  data: function () {
    return {
      //
    };
  },

  computed: {},

  created() {
  },

  mounted() {
  },

  methods: {
    areGroupedQuickFilters(quick_filter_value) {
      return typeof quick_filter_value.group !== 'undefined';
    }
  },

  watch: {
    //
  }
};
</script>
