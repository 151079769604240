<template>
    <div class="medimo-tbody tbody" ref="medimo-tbody">
        <slot></slot>
    </div>
</template>

<script>
export default {
  components: {},

  props: [],

  mixins: [],

  data: function () {
    return {
      //
    };
  },

  computed: {
    //
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    //
  },

  watch: {
    //
  }
};
</script>
