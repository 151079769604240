<template>
  <div
      ref="time-picker"
      :class="{'inline': inline, 'is-dark': dark, 'with-border': !onlyTime }"
      class="time-picker"
  >
    <div @click="scrollUp" class="flex flex-direction-row time-picker-button justify-content-center align-center clickable">
      <fa-icon icon="fa-solid fa-caret-up" size="lg"/>
    </div>
    <div
        v-for="column in columns"
        :key="column.type"
        :ref="column.type"
        :class="[`time-picker-column-${column.type}`]"
        class="time-picker-column flex-1 flex text-center"
        @scroll="noScrollEvent
        ? null
        : column.type === 'hours' ? onScrollHours($event) : column.type === 'minutes' ? onScrollMinutes($event) : onScrollApms($event)
      "
    >
      <div class="w-100">
        <button
            v-for="item in column.items"
            :key="item.item"
            type="button"
            tabindex="-1"
            class="time-picker-column-item align-center justify-content-center"
            :class="{
            active: isActive(column.type, item.value),
            disabled: item.disabled
          }"
            @click="item.disabled ? null : setTime(item.value, column.type)"
        >
                      <span
                          class="time-picker-column-item-effect"
                      />
          <span class="time-picker-column-item-text flex-1">
                        {{ item.item }}
                      </span>
        </button>
      </div>
    </div>
    <div @click="scrollDown" class="flex flex-direction-row time-picker-button justify-content-center align-center clickable">
      <fa-icon icon="fa-solid fa-caret-down" size="lg"/>
    </div>
  </div>
</template>
<script>
import moment from 'moment-mini';
import { nextTick } from 'vue';

const ArrayHourRange = (start, end, twoDigit, isAfternoon, disabledHours, isTwelveFormat) => {
  return Array(end - start + 1).fill().map((_, idx) => {
    const n = start + idx;
    const number = !isAfternoon ? n : n + 12;
    const numberToTest = (number < 10 ? '0' : '') + number;
    return {
      value: number,
      item: (twoDigit && (n < 10) ? '0' : '') + n,
      disabled: disabledHours.includes(numberToTest)
    };
  });
};
const ArrayMinuteRange = (start, end, twoDigit, step = 1, disabledMinutes) => {
  const len = Math.floor(end / step) - start;

  return Array(len).fill().map((_, idx) => {
    const number = start + idx * step;
    const txtMinute = (twoDigit && (number < 10) ? '0' : '') + number;
    return {
      value: number,
      item: txtMinute,
      disabled: disabledMinutes.includes(txtMinute)
    };
  });
};

const debounce = (fn, time) => {
  let timeout;

  return function () {
    const functionCall = () => fn.apply(this, arguments);
    clearTimeout(timeout);
    timeout = setTimeout(functionCall, time);
  };
};

export default {
  name: 'TimePicker',
  emits: ['update:modelValue'],
  props: {
    modelValue: {type: String, default: null},
    format: {type: String, default: null},
    minuteInterval: {type: [String, Number], default: 1},
    height: {type: Number, required: true},
    color: {type: String, default: null},
    inline: {type: Boolean, default: null},
    visible: {type: Boolean, default: null},
    onlyTime: {type: Boolean, default: null},
    dark: {type: Boolean, default: null},
    disabledHours: {type: Array, default: () => ([])},
    minTime: {type: String, default: null},
    behaviour: {type: Object, default: () => ({})},
    maxTime: {type: String, default: null}
  },
  data() {
    return {
      hour: null,
      minute: null,
      apm: null,
      columnPadding: {},
      noScrollEvent: !!(this.modelValue && !this.inline),
      delay: 0,
      // Used in various parts to calculate the position of the Time in relation to the Days
      columnItemHeight: 24,
    };
  },
  computed: {
    styleColor() {
      return {
        backgroundColor: this.color,
      };
    },
    isTwelveFormat() {
      if (!this.format) {
        return false;
      }
      return this.format.includes('A') || this.format.includes('a');
    },
    hours() {
      if (!this.format) {
        return false;
      }
      const twoDigit = this.format.includes('hh') || this.format.includes('HH');
      const isAfternoon = this.apm ? this.apm === 'pm' || this.apm === 'PM' : false;
      const minH = this.isTwelveFormat ? 1 : 0;
      const maxH = this.isTwelveFormat ? 12 : 23;

      return ArrayHourRange(
        minH,
        maxH,
        twoDigit,
        isAfternoon,
        this._disabledHours,
        this.isTwelveFormat
      );
    },
    minutes() {
      if (!this.format) {
        return false;
      }
      const twoDigit = this.format.includes('mm') || this.format.includes('MM');
      return ArrayMinuteRange(0, 60, twoDigit, this.minuteInterval, this._disabledMinutes);
    },
    apms() {
      const ampm = this.isTwelveFormat
        ? this.minTime
          ? moment(this.minTime, 'hh:mm a').format('a')
          : this.maxTime
            ? moment(this.maxTime, 'hh:mm a').format('a')
            : ''
        : '';
      const upper = ampm
        ? [{value: ampm.toUpperCase(), item: ampm.toUpperCase()}]
        : [{value: 'AM', item: 'AM'}, {value: 'PM', item: 'PM'}];
      const lower = ampm
        ? [{value: ampm, item: ampm}]
        : [{value: 'am', item: 'am'}, {value: 'pm', item: 'pm'}];
      return this.isTwelveFormat
        ? this.format.includes('A') ? upper : lower
        : null;
    },
    columns() {
      return [
        {type: 'hours', items: this.hours},
        // {type: 'minutes', items: this.minutes},
        // ...(this.apms ? [{type: 'apms', items: this.apms}] : [])
      ];
    },
    _disabledHours() {
      let minEnabledHour = 0;
      let maxEnabledHour = 23;
      if (this.minTime) {
        minEnabledHour = this.isTwelveFormat
          ? this.minTime.toUpperCase().includes('AM')
            ? moment(this.minTime, 'h:mm a').add(1, 'hours').format('h')
            : parseInt(moment(this.minTime, 'h:mm a').add(1, 'hours').format('h')) + 12
          : moment(this.minTime, 'HH:mm').add(1, 'hours').format('HH');
      }
      if (this.maxTime) {
        maxEnabledHour = this.isTwelveFormat
          ? this.maxTime.toUpperCase().includes('AM')
            ? moment(this.maxTime, 'h:mm a').format('h')
            : parseInt(moment(this.maxTime, 'h:mm a').format('h'), 10) + 12
          : moment(this.maxTime, 'HH:mm').format('HH');
      }

      // In case if hour present as 08, 09, etc
      minEnabledHour = parseInt(minEnabledHour, 10);
      maxEnabledHour = parseInt(maxEnabledHour, 10);

      if (minEnabledHour !== 0 || maxEnabledHour !== 23) {
        const enabledHours = [...Array(24)]
          .map((_, i) => i)
          .filter(h => h >= minEnabledHour && h <= maxEnabledHour);

        // This fixes a bug where the TimePicker would determine the availability of an hour _before_ it knew the new value
        // this put the new hour out of bounds and reset the time to the nearest available value.
        // By using nextTick() the values used will be based on the new input, not the old, and it works.
        // This is why you never set values in computed properties kids!
        nextTick(() => {
          if (!enabledHours.includes(this.hour) && this.behaviour && this.behaviour.time && this.behaviour.time.nearestIfDisabled) {
            this.hour = enabledHours[0]; // eslint-disable-line
            this.emitValue();
          }
        });

        const _disabledHours = [...Array(24)]
          .map((_, i) => i)
          .filter(h => !enabledHours.includes(h))
          .map(h => h < 10 ? '0' + h : '' + h);
        this.disabledHours.forEach(h => _disabledHours.push(h));

        return _disabledHours;
      } else {
        return this.disabledHours;
      }
    },
    _disabledMinutes() {
      let minEnabledMinute = 0;
      let maxEnabledMinute = 60;
      if (this.isTwelveFormat) {
        if (this.minTime && this.apm) {
          const minTime = moment(this.minTime, 'h:mm a');
          const minTimeHour = parseInt(minTime.format('h'), 10) + (this.apm.toUpperCase() === 'PM' ? 12 : 0);
          minEnabledMinute = minTimeHour === this.hour ? parseInt(minTime.format('mm'), 10) : minEnabledMinute;
        } else if (this.maxTime) {
          const maxTime = moment(this.maxTime, 'h:mm a');
          const maxTimeHour = parseInt(maxTime.format('h'), 10) + (this.apm.toUpperCase() === 'PM' ? 12 : 0);
          maxEnabledMinute = maxTimeHour === this.hour ? parseInt(maxTime.format('mm'), 10) : maxEnabledMinute;
        }
      } else {
        if (this.minTime) {
          const minTime = moment(this.minTime, 'HH:mm');
          const minTimeHour = parseInt(moment(this.minTime, 'HH:mm').format('HH'), 10);
          minEnabledMinute = minTimeHour === this.hour ? parseInt(minTime.format('mm'), 10) : minEnabledMinute;
        } else if (this.maxTime) {
          const maxTime = moment(this.maxTime, 'HH:mm');
          const maxTimeHour = parseInt(moment(this.maxTime, 'HH:mm').format('HH'), 10);
          maxEnabledMinute = maxTimeHour === this.hour ? parseInt(maxTime.format('mm'), 10) : maxEnabledMinute;
        }
      }

      if (minEnabledMinute !== 0 || maxEnabledMinute !== 60) {
        const enabledMinutes = [...Array(60)]
          .map((_, i) => i)
          .filter(m => m >= minEnabledMinute && m <= maxEnabledMinute);

        if (!enabledMinutes.includes(this.minute) && this.behaviour && this.behaviour.time && this.behaviour.time.nearestIfDisabled) {
          this.minute = enabledMinutes[0]; // eslint-disable-line
          this.emitValue();
        }

        return [...Array(60)]
          .map((_, i) => i)
          .filter(m => !enabledMinutes.includes(m))
          .map(m => m < 10 ? '0' + m : '' + m);
      } else {
        return [];
      }
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.columnPad();
        this.initPositionView();
      }
    },
    modelValue(value) {
      if (value) {
        this.buildComponent();
        this.initPositionView();
      }
    },
    height(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.initPositionView();
      }
    }
  },
  mounted() {
    this.buildComponent();
    this.initPositionView();
  },
  methods: {
    getValue(scroll) {
      const itemHeight = 28;
      const scrollTop = scroll.target.scrollTop;
      return Math.round(scrollTop / itemHeight);
    },
    onScrollHours: debounce(function (scroll) {
    }, 100),
    onScrollMinutes: debounce(function (scroll) {
    }, 100),
    onScrollApms: debounce(function (scroll) {
    }, 100),
    isActive(type, value) {
      return (type === 'hours'
        ? this.hour
        : type === 'minutes'
          ? this.minute
          : this.apm ? this.apm : null) === value;
    },
    isHoursDisabled(h) {
      const hourToTest = this.apmType
        ? moment(`${h} ${this.apm}`, [`${this.hourType} ${this.apmType}`]).format('HH')
        : h < 10 ? '0' + h : '' + h;
      return this._disabledHours.includes(hourToTest);
    },
    isMinutesDisabled(m) {
      m = m < 10 ? '0' + m : '' + m;
      return this._disabledMinutes.includes(m);
    },
    buildComponent() {
      if (this.isTwelveFormat && !this.apms) window.console.error(`VueCtkDateTimePicker - Format Error : To have the twelve hours format, the format must have "A" or "a" (Ex : ${this.format} a)`);
      const tmpHour = parseInt(moment(this.modelValue, this.format).format('HH'));
      const hourToSet = this.isTwelveFormat && (tmpHour === 12 || tmpHour === 0)
        ? tmpHour === 0 ? 12 : 24
        : tmpHour;

      /**
       * Here we have two different behaviours. If the behaviour `nearestIfDisabled` is enabled
       * and the selected hour is disabled, we set the hour to the nearest hour available.
       * Otherwise just set the hour to the current value.
       */
      this.hour = this.behaviour && this.behaviour.time && this.behaviour.time.nearestIfDisabled && this.isHoursDisabled(hourToSet)
        ? this.getAvailableHour()
        : hourToSet;

      this.minute = parseInt(moment(this.modelValue, this.format).format('mm'));
      this.apm = this.apms && this.modelValue
        ? this.hour > 12
          ? this.apms.length > 1 ? this.apms[1].value : this.apms[0].value
          : this.apms[0].value
        : null;
      this.columnPad();
    },
    columnPad() {
      if (this.$refs['time-picker'] && (this.visible || this.inline)) {
        const run = (pad) => {
          this.columnPadding = {
            height: `14px`
          };
        };
        nextTick(() => {
          const pad = this.$refs['time-picker'].clientHeight / 2 - 28 / 2;
          run(pad);
        });
      } else {
        return null;
      }
    },
    initPositionView() {
      this.noScrollEvent = true;

      this.$refs['hours'][0].scrollTop = 0;
      const selected = this.selectedTimeElement();
      if (selected) {
        const boundsSelected = selected.getBoundingClientRect();
        const boundsElem = this.$refs['hours'][0].getBoundingClientRect();
        const timePickerHeight = this.$refs['time-picker'].clientHeight;
        // This was taken verbatim from the VueCtkDateTimepicker with the exception of the -10 at the end, which
        // slightly offsets the height so the times align with the days.
        this.scrollTimePicker((28 / 2) + boundsSelected.top - boundsElem.top - timePickerHeight / 2 + 38);
      }
    },
    scrollUp() {
      this.noScrollEvent = true;
      this.scrollTimePicker(0 - this.columnItemHeight);
    },
    scrollDown() {
      this.noScrollEvent = true;
      this.scrollTimePicker(this.columnItemHeight);
    },
    scrollTimePicker(pixels) {
      const selected = this.selectedTimeElement();
      const elem = this.$refs['hours'][0];
      if (selected) {
        const boundsSelected = selected.getBoundingClientRect();
        const boundsElem = elem.getBoundingClientRect();
        if (boundsSelected && boundsElem) {
          // Now we have to make sure it "jumps" to a good position in line with the daypicker.
          let scrollPosition = elem.scrollTop + pixels;
          // When it's not in line, the modulo won't be 23.
          if (scrollPosition % this.columnItemHeight !== 23) {
            // So we make sure we get the nearest modulo 23 value here:
            scrollPosition = Math.ceil(scrollPosition / this.columnItemHeight) * this.columnItemHeight;
          }
          elem.scrollTop = scrollPosition;
        }
      }
      setTimeout(() => {
        this.noScrollEvent = false;
      }, 50);
    },
    selectedTimeElement() {
      return this.$refs['hours'][0].querySelector(`.time-picker-column-item.active`);
    },
    getAvailableHour() {
      const availableHours = this.hours.find((element) => {
        return element.disabled === false;
      });
      return availableHours ? availableHours.value : null;
    },
    setTime(item, type) {
      if (type === 'hours') {
        this.hour = item;
      } else if (type === 'minutes') {
        this.minute = item;
      } else if (type === 'apms' && this.apm !== item) {
        const newHour = item === 'pm' || item === 'PM' ? this.hour + 12 : this.hour - 12;
        this.hour = newHour;
        this.apm = item;
      }
      this.emitValue();
    },
    emitValue() {
      const tmpHour = this.hour ? this.hour : this.getAvailableHour();
      let hour = this.isTwelveFormat && (tmpHour === 24 || tmpHour === 12)
        ? this.apm.toLowerCase() === 'am' ? 0 : 12
        : tmpHour;
      hour = (hour < 10 ? '0' : '') + hour;
      const minute = this.minute ? (this.minute < 10 ? '0' : '') + this.minute : '00';
      const time = `${hour}:${minute}`;
      this.$emit('update:modelValue', time);
    }
  }
};
</script>
