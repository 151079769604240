<template>
    <div class="input-group">
        <div class="row">
           <div class="col-12">
               <medimo-select :class="'form-control ' + validation_class + ' ' + extra_classes"
                              :type="type"
                              :options="options"
                              @input="onInput"
                              :extra_classes="extra_classes"
                              :name="name"
                              :label="label"
                              :placeholder="placeholder"
                              :help_text="help_text">

               </medimo-select>
           </div>
            <div class="col-12">
                <div class="invalid-feedback" v-show="invalid">
                    {{ invalid_message }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import MedimoSelect from './base/MedimoSelect';
import IsValidatedFormComponentMixin from '@/vue/mixins/form/IsValidatedFormComponentMixin';

export default {

  components: {MedimoSelect},
  emits: ['input'],

  props: {
    // 'value': {default: ''},
    'options': {},

    'name': {default: ''},
    'label': {default: ''},
    'placeholder': {default: ''},
    'extra_classes': {default: ''},
    'type': {default: 'text'},
    'help_text': {default: ''},
  },

  mixins: [
    IsValidatedFormComponentMixin
  ],

  data: function () {
    return {
      //
    };
  },

  computed: {

  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    // Here we watch the change of value and emit it to the parent for further processing
    onInput(value) {
      this.$emit('input', value);
    }
  },

  watch: {
    //
  }
};
</script>
