<script>
export default {
  data: function () {
    return {
      loadingThrottled: false,
      pendingActions: {}, // keep track of actions we are throttling at the moment, keyed by action_name
    };
  },

  methods: {
    /**
     * Use this method to only dispatch an action after X amount of seconds
     * Meant to be used for example with reloading / searching. If a user hasn't input something after
     * 300ms, it will fire the action
     *
     * @param action_name
     * @param payload
     * @param wait_time
     * @returns {Promise<unknown>}
     */
    dispatchThrottled(action_name, payload, wait_time = 300) {
      const pending = this.pendingActions[action_name];
      if (pending) {
        // cancel pending action
        clearTimeout(pending.timeoutHandler);
        pending.rejectCallback(new Error('Throttled'));
      }

      return new Promise((resolve, reject) => {
        // set timeout to dispatch action, so we have the opportunity to cancel
        const timeoutHandler = setTimeout(() => {
          this.loadingThrottled = this.shouldShowLoaderOnRequest;
          return this.$store.dispatch(action_name, payload).then(response => {
            resolve(response);
          }).catch(error => {
            reject(error);
          }).finally(() => {
            // clear pending action
            delete this.pendingActions[action_name];
            this.loadingThrottled = false;
          });
        }, wait_time);

        // store reject callback and timeout handler so we can cancel when needed
        this.pendingActions[action_name] = {
          'rejectCallback': reject,
          'timeoutHandler': timeoutHandler,
        };
      });
    },
  },
};
</script>
