<template>
  <div class="row" style="width:100%;">

    <episodes-status-column
        :patient_id="patient_id"
        @showModalEpisodes="show_modal_episodes = true">
    </episodes-status-column>

    <indications-status-column
        :patient_id="patient_id"
        @showModalIndications="show_modal_indications = true">
    </indications-status-column>

    <intolerances-status-column
        :patient_id="patient_id"
        @showModalIntolerances="show_modal_intolerances = true">
    </intolerances-status-column>

    <episodes-data-table-modal
        v-if="show_modal_episodes"
        :patient="patient"
        @closed="show_modal_episodes = false">
    </episodes-data-table-modal>

    <indications-data-table-modal
        v-if="show_modal_indications"
        :patient="patient"
        @closed="show_modal_indications = false">
    </indications-data-table-modal>

    <intolerances-data-table-modal
        v-if="show_modal_intolerances"
        :patient="patient"
        @closed="show_modal_intolerances = false">
    </intolerances-data-table-modal>

  </div>
</template>

<script>

import IntolerancesStatusColumn from '@/vue/components/patients/intolerances/IntolerancesStatusColumn';
import IndicationsStatusColumn from '@/vue/components/patients/indications/IndicationsStatusColumn';
import EpisodesStatusColumn from '@/vue/components/patients/episodes/EpisodesStatusColumn';
import IndicationsDataTableModal from '@/vue/components/patients/indications/IndicationsDataTableModal';
import EpisodesDataTableModal from '@/vue/components/patients/episodes/EpisodesDataTableModal';
import IntolerancesDataTableModal from '@/vue/components/patients/intolerances/IntolerancesDataTableModal';

export default {

  props: {
    patient_id: {
      required: true
    },
    episodes: {
      required: true
    },
    contra_indications: {
      required: true
    },
    intolerances: {
      required: true
    },
  },

  mounted() {

    // Add the episodes
    this.$store.commit('episodes/clear_data', [], {root: true});
    this.episodes.forEach(model => {
      this.$store.commit('episodes/add_to_data', model, {root: true});
    });
    this.$store.commit('episodes/set_search_results', this.episodes.reverse());

    // Add the Intolerances
    this.$store.commit('intolerances/clear_data', [], {root: true});
    this.intolerances.forEach(model => {
      this.$store.commit('intolerances/add_to_data', model, {root: true});
    });
    this.$store.commit('intolerances/set_search_results', this.intolerances.reverse());

    // Add the Contra Indicaties
    this.$store.commit('indications/clear_data', [], {root: true});
    this.contra_indications.forEach(model => {
      this.$store.commit('indications/add_to_data', model, {root: true});
    });
    this.$store.commit('indications/set_search_results', this.contra_indications.reverse());
  },

  computed: {
    patient() {
      return this.$store.getters['patients/find'](this.patient_id);
    }
  },

  components: {
    IntolerancesDataTableModal,
    IntolerancesStatusColumn,
    IndicationsStatusColumn,
    EpisodesStatusColumn,
    IndicationsDataTableModal,
    EpisodesDataTableModal,
  },

  data: function () {
    return {
      loading: true,
      show_modal_episodes: false,
      show_modal_indications: false,
      show_modal_intolerances: false,
    };
  },
};
</script>
