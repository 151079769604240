import BaseModule from '@/vue/store/modules/base_module';

const state = {
  ...BaseModule.state,
  toggleQuickFilters: false,
  search_columns: [
    'action.name',
    'patient.naam',
    'ward.naam',
    'location.naam',
  ],
};

const getters = { ...BaseModule.getters };
const actions = { ...BaseModule.actions };
const mutations = {
  ...BaseModule.mutations,
  init_quick_filters(state, quick_filter) {
    // Remove any old quick filters
    Object.keys(state.quick_filters).forEach(quick_filter => {
      delete state.quick_filters[quick_filter];
    });
    // Add the one dynamic quick filter
    state.quick_filters[quick_filter.name] = quick_filter.value;
    // Add the default quick filters
    state.quick_filters.openstaand = false;
    state.quick_filters.geweigerd = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
