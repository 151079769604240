<template>
    <medi-modal
            :loading="loading"
            :title="'Voer een WID controle uit voor ' + patient.name"
            size="medium"
            @closed="$emit('closed')"
    >
        <medimo-form>

            <medimo-form-row v-if="message">
                <medimo-form-column column_classes="col-12">
                  <p v-if="message" v-html="message"></p>
                </medimo-form-column>
            </medimo-form-row>

            <medimo-form-row>
                <medimo-form-column column_classes="col-12">
                    <medimo-labeled-input
                            label_column_classes="col-12 col-sm-4"
                            :label="'BSN'"
                            type="text"
                            v-model="bsn"
                    ></medimo-labeled-input>
                </medimo-form-column>
            </medimo-form-row>

            <medimo-form-row>
                <medimo-form-column column_classes="col-12">
                    <medimo-labeled-vue-select2
                        label_column_classes="col-12 col-sm-4"
                        select2_column_classes="col-12 col-sm-8"
                        label="Document type"
                        v-model="doc_type"
                        :options="[
                            {value: '', text: ''},
                            {value: 'Reisdocument', text: 'Reisdocument'},
                            {value: 'Rijbewijs', text: 'Rijbewijs'},
                            {value: 'Vreemdelingendocument', text: 'Vreemdelingendocument'},
                    ]"></medimo-labeled-vue-select2>
                </medimo-form-column>
            </medimo-form-row>

            <medimo-form-row>
                <medimo-form-column column_classes="col-12">
                    <medimo-labeled-input
                            label_column_classes="col-12 col-sm-4"
                            :label="'Document nummer'"
                            type="text"
                            v-model="doc_nr"
                    ></medimo-labeled-input>
                </medimo-form-column>
            </medimo-form-row>

        </medimo-form>

        <template v-slot:action-buttons>
            <medimo-button class="mr-md-auto" @click="checkWid">
                Controleren
            </medimo-button>
            <medimo-button @click="$emit('closed')">
                Sluiten
            </medimo-button>
        </template>

    </medi-modal>
</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import MedimoLabeledVueSelect2 from '../../general/form/base/vue-select2/MedimoLabeledVueSelect2';

export default {

  components: {MedimoLabeledVueSelect2},
  emits: ['closed'],
  mixins: [
    BaseComponentsMixin
  ],

  props: [
    'trigger',
    'patient'
  ],

  data: function () {
    return {
      loading: false,
      message: '',
      bsn: '',
      doc_type: '',
      doc_nr: ''
    };
  },

  methods: {
    checkWid() {

      this.loading = true;

      this.$store.dispatch('bsn/performWidCheck', {
        patient_id: this.patient.id,
        data: {
          bsn: this.bsn,
          doc_type: this.doc_type,
          doc_nr: this.doc_nr
        }
      }).then(() => {
        this.loading = false;
        this.message = this.$store.state.bsn.checkWidResult[this.patient.id];
      }).catch(() => {
        this.loading = false;
      });
    },
  },

  computed: {
    //
  },
};
</script>
