// initial state
// shape: [{ id, quantity }]
import Utility from '@/vue/utility/utility';
import dosageInstructionPackage from '@/vue/packages/dosageInstruction';
import BaseModule from '@/vue/store/modules/base_module';

const state = {
  ...BaseModule.state,
  shadow_data: [],
  data: [],
  signal_uid_opened: 0,
  loading: false,
  schema_loading: false,
  initPromise: false,
  updatePromise: false,
  cancelTokenSource: false,
  cancelSchemaTokenSource: false,
  isInitialized: {},
  shadow_traces: [],
  traces: [],
  quick_filters: [],
  filter_columns: [],
};

// getters
const getters = {
  init_promise(state) {
    return state.initPromise;
  },
  all_promises(state) {
    return [state.initPromise, state.updatePromise];
  },
  is_loading(state, getters, rootState) {
    return state.loading;
  },
  schema_is_loading(state, getters, rootState) {
    return state.schema_loading;
  },
  shadow_for_dosage_instruction(state, getters, rootState) {
    return (id) => Utility.find_models_by_property(state.shadow_data, 'dosage_instruction_id', Utility.parse_id(id));
  },
  shadow_for_schema(state, getters, rootState) {
    return (schema_id) => Utility.find_models_by_property(state.shadow_data, 'schema_id', schema_id);
  },
  shadow_for_schema_unique(state, getters, rootState) {
    return (schema_id) => {
      const signals = Utility.find_models_by_property(state.shadow_data, 'schema_id', schema_id);
      // We group all signals by uniqueHash
      // When saving notes and approving signals, we update all signals with the same hash
      const signalGroups = Utility.groupBy(signals, 'groupByHash');
      return signalGroups.map( (signalGroup) => {
        return signalGroup[0];
      });
    };
  },
  for_dosage_instructions(state, getters, rootState) {
    return (dosageInstructions) => {
      let signals = [];
      for (let i = 0; i < dosageInstructions.length; i++) {
        signals = signals.concat(getters['for_dosage_instruction'](dosageInstructions[i].id));
      }
      return signals;
    };
  },
  shadow_trace_for_dosage_instruction_and_protocol(state, getters) {
    return (payload) => {
      const dosingInstructionId = payload.dosing_instruction_id;
      const protocolId = payload.protocol_id;
      if (!payload.schema_view) {
        return state.shadow_traces[protocolId];
      }
      else {
        const traces = getters['shadow_traces_for_dosage_instruction'](dosingInstructionId);
        return traces[protocolId];
      }
    };
  },
  traces_for_dosage_instructions(state, getters, rootState) {
    return (dosingInstructions) => {
      const traces = {};
      for (let i = 0; i < dosingInstructions.length; i++) {
        const trace = getters['traces_for_dosage_instruction'](dosingInstructions[i].id);

        // traces for instruction not related to a schema
        if (trace && trace.data) {
          traces[dosingInstructions[i].id] = trace.data;
        }
        // traces for instructions, related to a schema
        else if (trace) {
          traces[dosingInstructions[i].id] = trace;
        }
      }
      return traces;
    };
  },
  traces_for_dosage_instruction(state, getters, rootState) {
    return (id) => Utility.find_model_by_property(state.traces, 'dosing_instruction_id', Utility.parse_id(id));
  },
  shadow_traces_for_dosage_instruction(state, getters, rootState) {
    return (id) => Utility.find_model_by_property(state.shadow_traces, 'dosing_instruction_id', Utility.parse_id(id));
  },
  required_unapproved_schema_signals(state, getters, rootState) {
    return (dosingInstructions) => {
      const invalidDosingInstructions = dosingInstructions.filter((dosingInstruction) => {
        return getters['required_unapproved_signals'](dosingInstruction);
      });
      return invalidDosingInstructions.length > 0;
    };
  },
  required_unapproved_signals(state, getters, rootState) {
    return (dosingInstruction) => {
      const signals = getters['shadow_for_dosage_instruction'](dosingInstruction.id);
      const required_unapproved = signals.filter((signal) => {
        return (signal.requiresApproval === 1 || signal.requiresApproval === true) && !signal.approved;
      });
      return required_unapproved.length > 0;
    };
  },
  for_dosage_instruction(state, getters, rootState) {
    return (id) => Utility.find_models_by_property(state.data, 'dosage_instruction_id', Utility.parse_id(id));
  },
  for_added_contra_indication(state, getters, rootState) {
    return (id) => Utility.find_models_by_property(state.shadow_data, 'contraIndicationId', Utility.parse_id(id));
  },
  for_added_intolerance(state, getters, rootState) {
    return (id) => Utility.find_models_by_property(state.shadow_data, 'intoleranceId', Utility.parse_id(id));
  },
  for_medication_evaluation(state, getters, rootState) {
    return (id) => Utility.find_models_by_property(state.shadow_data, 'medicationEvaluationId', Utility.parse_id(id));
  },
  find_other_shadow_signal_in_group(state, getters, rootState) {
    return (groupByHash) => Utility.find_model_by_property(state.shadow_data, 'groupByHash', groupByHash);
  },
  for_schema(state, getters, rootState) {
    return (schema_id) => Utility.find_models_by_property(state.data, 'schema_dosing_instruction_id', Utility.parse_id(schema_id));
  },
  get_temp_notes(state) {
    return (id) => {
      const signals = Utility.find_models_by_property(state.data, 'dosage_instruction_id', id);
      const notes = [];
      signals.forEach(function (signal) {
        if (typeof signal.note !== "undefined") {
          notes.push({screenId: signal.screenId, note: signal.note});
        }
      });
      return notes;
    };
  },
  get_schema_post_values(state, getters, rootState, rootGetters) {
    return (dosageInstructions) => {

      dosageInstructions = dosageInstructions.filter(function(dosageInstruction) {return !dosageInstruction.canceled;});

      const status_inputs = dosageInstructions.map(
        function (dosageInstruction) {
          const encodedQuantity = rootGetters['dosage_instructions/encoded_quantity'](dosageInstruction);
          return dosageInstructionPackage.getMedicationGuardValues(dosageInstruction, encodedQuantity);
        }
      );

      return {
        'process_step': 'Prescribe',
        'action': 'change',
        'status_inputs': status_inputs,
        'patient_id': dosageInstructions[0].patient_id
      };
    };
  },
  get_post_values(state, getters, rootState, rootGetters) {
    return (dosageInstruction) => {

      const medicationGuardSignals = getters['shadow_for_dosage_instruction'](dosageInstruction.id);

      const dosageSignals = medicationGuardSignals.filter((item => item.type === 'Dosage'));
      const mfbSignals = medicationGuardSignals.filter((item => item.type === 'mfbSignal'));
      const mfbErrors = medicationGuardSignals.filter((item => item.type === 'MFBError'));
      const otherSignals = medicationGuardSignals.filter((item => item.screenId && item.screenId.startsWith('othersignal'))); //Duplicate intolerance contraindications ..

      let dosageSignal = false;
      if (dosageSignals.length > 0) {
        dosageSignal = dosageSignals[0];
      }
      const encodedQuantity = rootGetters['dosage_instructions/encoded_quantity'](dosageInstruction);
      const postValues = dosageInstructionPackage.getMedicationGuardValues(dosageInstruction, encodedQuantity);
      postValues['rawMedicationGuardDosageSignal'] = JSON.stringify(dosageSignal);
      postValues['rawMedicationGuardMFBSignals'] = JSON.stringify(mfbSignals);
      postValues['rawMedicationGuardMFBErrors'] = JSON.stringify(mfbErrors);
      postValues['rawMedicationGuardOtherSignals'] = JSON.stringify(otherSignals);
      return postValues;
    };
  },
  shadow_traces(state) {
    return state.shadow_traces;
  }
  ,
  ...BaseModule.getters
};

// actions
const actions = {
  cancel({state, commit}) {
    //cancel previous request
    if (state.cancelTokenSource !== false) {
      state.cancelTokenSource.cancel();
    }
    commit('set_loading', false);
  },
  cancel_schema({state, commit}) {
    //cancel previous request
    if (state.cancelSchemaTokenSource !== false) {
      state.cancelSchemaTokenSource.cancel();
    }
    commit('set_schema_loading', false);
  },
  store_shadow_signals_with_traces({state, commit, dispatch}, dosingInstructionId) {
    dispatch('store_shadow_signals', dosingInstructionId);
    commit('store_shadow_traces_as', dosingInstructionId);
  },
  store_schema_traces({state, commit}, schemaId) {
    if (!state.shadow_traces) {
      //TODO Ramon double check raise sentry
      window.Sentry.captureMessage('Schema waarbij er geen traces zijn ingeladen. SchemaId: ' + schemaId);
      return;
    }
    Object.keys(state.shadow_traces).forEach( (dosingInstructionId) => {
      const trace = state.shadow_traces[dosingInstructionId];
      trace.dosing_instruction_id = dosingInstructionId;
      Utility.delete_models_by_property(state.traces, 'dosing_instruction_id', Utility.parse_id(dosingInstructionId));
      commit('add_to_trace_data', trace);
      return trace;
    });
  },
  store_shadow_signals({state, commit}, dosingInstructionId) {
    const shadowSignals = Utility.find_models_by_property(state.shadow_data, 'dosage_instruction_id', dosingInstructionId);

    //Clear the old local signals and copy the new shadow changes
    let localSignals = state.data;
    localSignals = localSignals.filter((localSignal) => {
      return localSignal.dosage_instruction_id !== dosingInstructionId;
    });
    shadowSignals.forEach((shadowSignal) => {
      localSignals.push(Utility.deep_clone(shadowSignal));
    });
    state.data = localSignals;

    //Clear the shadow changes
    let allShadowSignals = state.shadow_data;
    allShadowSignals = allShadowSignals.filter((shadowSignal) => {
      return shadowSignal.dosage_instruction_id !== dosingInstructionId;
    });

    state.shadow_data = allShadowSignals;
  },

  store_shadow_signals_with_traces_as({state, commit, dispatch, getters, rootState}, payload)
  {
    dispatch('store_shadow_signals_as', payload);
    commit('store_shadow_traces_as', payload.new_id);
  },
  store_shadow_signals_as({state, commit, dispatch, getters, rootState}, payload)
  {
    const models_to_copy = Utility.find_models_by_property(state.shadow_data, 'dosage_instruction_id', payload.old_id);

    models_to_copy.forEach(function(model_to_copy) {
      const clone = Utility.deep_clone(model_to_copy);
      clone['dosage_instruction_id'] = payload.new_id;
      commit('add_to_data', clone);
    });
  },
  reset_shadow_changes({state, commit, dispatch, getters, rootState, rootGetters}, dosageInstructionId) {
    state.shadow_data = [];
    const signals = getters['for_dosage_instruction'](dosageInstructionId);
    signals.forEach(function (signal) {
      commit('add_to_shadow_data', signal);
    });
  },
  reset_schema_shadow_changes({state, commit, dispatch, getters, rootState, rootGetters}, schemaFirstDosingInstructionId) {
    state.shadow_data = [];
    const signals = getters['for_schema'](schemaFirstDosingInstructionId);
    signals.forEach(function (signal) {
      commit('add_to_shadow_data', signal);
    });
  },
  store_shadow_changes({state, commit, dispatch, getters, rootState, rootGetters}, dosageInstructionId) {
    Utility.delete_models_by_property(state.data, 'dosage_instruction_id', Utility.parse_id(dosageInstructionId));
    const signals = getters['shadow_for_dosage_instruction'](dosageInstructionId);
    signals.forEach(function (signal) {
      commit('add_to_data', signal);
    });

    //TODO check of delete_models_by_property wel goed gaat, sowieso testen schrijven voor de Utility class
    Utility.delete_models_by_property(state.shadow_data, 'dosage_instruction_id', Utility.parse_id(dosageInstructionId));
  },

  process_schema_api_response({state, commit, dispatch, getters, rootState, rootGetters}, payload) {

    const data = payload.data;
    const dosageInstructions = payload.dosageInstructions;
    const tempNotes = getters['get_temp_notes'](dosageInstructions[0].id);

    data.forEach(function (action) {
      if (action.responseType === 'AddSignal') {
        const signal = action.functionData;
        signal['dosage_instruction_id'] = signal.statusId;
        signal['schema_id'] = dosageInstructions[0].first_dosing_instruction_id;
        signal['id'] = signal.screenId;

        //find other signal with same groupByHash, copy note and approval
        const otherSignal = getters['find_other_shadow_signal_in_group'](signal.groupByHash);
        if (otherSignal) {
          signal.note = otherSignal.note;
          signal.approved = otherSignal.approved;
        }

        commit('add_signal', {signal: signal, tempNotes: tempNotes});
      } else if (action.responseType === 'RemoveSignal') {
        const signal = action.functionData;
        commit('remove_schema_signal', {
          schema_id: signal.schema_id,
          unique_hash: signal.uniqueHash
        });
      }
      else if (action.responseType === 'AddTraces') {
        const traces = action.functionData;
        commit('set_shadow_traces', traces);
      } else {
        window.Sentry.captureException('Unknown medication guard response: ' + action.responseType);
      }
    });
  },

  process_api_response({state, commit, dispatch, getters, rootState, rootGetters}, payload) {
    const data = payload.data;
    const dosageInstruction = payload.dosageInstruction;
    const tempNotes = getters['get_temp_notes'](dosageInstruction.id);

    data.forEach(function (action) {
      if (action.responseType === 'AddSignal') {
        const signal = action.functionData;
        signal['dosage_instruction_id'] = dosageInstruction.id;
        signal['id'] = signal.screenId;

        commit('add_signal', {signal: signal, tempNotes: tempNotes});
      } else if (action.responseType === 'RemoveSignal') {
        commit('remove_signal', {
          id: dosageInstruction.id,
          screenId: action.functionData
        });
      } else if (action.responseType === 'AddTraces') {
        const traces = action.functionData;
        commit('set_shadow_traces', traces);
      }
      else {
        window.Sentry.captureException('Unknown medication guard response: ' + action.responseType);
      }
    });

    commit('set_loading', false);
  },
  get_initial_signals_schema({state, commit, dispatch, getters, rootState, rootGetters}, payload) {
    commit('set_schema_loading', true);

    //cancel previous request
    if (state.cancelSchemaTokenSource !== false) {
      state.cancelSchemaTokenSource.cancel();
    }
    const CancelToken = axios.CancelToken;
    commit('set_state', {property: 'cancelSchemaTokenSource', value: CancelToken.source()});

    const medGuardEndPoint = '/api/v1/patients/' + payload.patient_id + '/schema/signals';

    const postData = getters['get_schema_post_values'](payload.dosing_instructions);
    const schema_id = payload.schema_id;

    postData.signals = getters['shadow_for_schema'](schema_id);

    const initPromise = dispatch('api/postEndpoint', {
      endpoint: medGuardEndPoint,
      data: postData,
      config: {
        cancelToken: state.cancelSchemaTokenSource.token,
      }
    }, {root: true}).then(data => {
      dispatch('process_schema_api_response', {data: data.data, dosageInstructions: payload.dosing_instructions});
    }).then(() => {
      commit('set_schema_loading', false);
    }).catch(() => {
      commit('set_schema_loading', false);
    });

    commit('set_state', {property: 'initPromise', value: initPromise});
  },
  get_initial_signals_for_start({state, commit, dispatch, getters, rootState, rootGetters}, dosingInstruction) {
    return dispatch('get_initial_signals', {action: 'create', dosing_instruction: dosingInstruction});
  },
  get_initial_signals_for_stop({state, commit, dispatch, getters, rootState, rootGetters}, dosingInstruction) {
    return dispatch('get_initial_signals', {action: 'stop', dosing_instruction: dosingInstruction});
  },
  get_initial_signals_for_change({state, commit, dispatch, getters, rootState, rootGetters}, dosingInstruction) {
    return dispatch('get_initial_signals', {action: 'edit', dosing_instruction: dosingInstruction});
  },
  get_initial_signals({state, commit, dispatch, getters, rootState, rootGetters}, payload) {

    const action = payload.action; //create, edit, stop
    const dosingInstruction = payload.dosing_instruction;

    commit('set_loading', true);

    commit('clear', dosingInstruction.id);

    if (dosingInstruction.custom_medication_guard_signals) {
      dosingInstruction.custom_medication_guard_signals.forEach((custom_signal) => {
        custom_signal.dosage_instruction_id = dosingInstruction.id;
        custom_signal.order = 0;
        custom_signal.type = 'ExtraNotice';
        commit('add_custom_signal', custom_signal);
      });
    }

    const patientId = dosingInstruction.patient_id;
    const processStep = 'Prescribe';
    const drugId = dosingInstruction.drug_id;

    let medGuardEndPoint = '/status/initiatemedicationguarddosagesignals/' + processStep + '/' + patientId + '/' + drugId;
    if (!Utility.is_new_id(dosingInstruction.id)) {
      medGuardEndPoint = '/status/initiatemedicationguarddosagesignals/' + processStep + '/' + patientId + '/' + drugId + '/' + dosingInstruction.id;
    }

    if (dosingInstruction.statusId) {
      medGuardEndPoint = medGuardEndPoint + '/' + dosingInstruction.statusId;
    }

    const postValues = getters['get_post_values'](dosingInstruction);
    postValues.action = action;

    const initPromise = dispatch('api/postEndpoint', {
      endpoint: medGuardEndPoint,
      data: postValues,
    }, {root: true}).then(response => {
      commit('set_loading', false);
      dispatch('process_api_response', {data: response.data, dosageInstruction: dosingInstruction});
    }).catch(error => {
      //
    });

    commit('set_state', {property: 'initPromise', value: initPromise});
  },
  get_updated_signals_for_start({state, commit, dispatch, getters, rootState, rootGetters}, dosingInstruction) {
    return dispatch('get_updated_signals', {action: 'create', dosing_instruction: dosingInstruction});
  },
  get_updated_signals_for_stop({state, commit, dispatch, getters, rootState, rootGetters}, dosingInstruction) {
    return dispatch('get_updated_signals', {action: 'stop', dosing_instruction: dosingInstruction});
  },
  get_updated_signals_for_change({state, commit, dispatch, getters, rootState, rootGetters}, dosingInstruction) {
    return dispatch('get_updated_signals', {action: 'edit', dosing_instruction: dosingInstruction});
  },
  get_updated_signals({state, commit, dispatch, getters, rootState, rootGetters}, payload) {

    const action = payload.action; //create, edit, stop
    const dosageInstruction = payload.dosing_instruction;

    commit('set_loading', true);

    state.initPromise.then(data => {

      //cancel previous request
      if (state.cancelTokenSource !== false) {
        state.cancelTokenSource.cancel();
      }
      const CancelToken = axios.CancelToken;
      commit('set_state', {property: 'cancelTokenSource', value: CancelToken.source()});

      const patientId = dosageInstruction.patient_id;
      const processStep = 'Prescribe';
      const drugId = dosageInstruction.drug_id;

      let medGuardEndPoint = '/status/updatemedicationguarddosagesignals/' + processStep + '/' + patientId + '/' + drugId;
      if (!Utility.is_new_id(dosageInstruction.id)) {
        medGuardEndPoint = medGuardEndPoint + '/' + dosageInstruction.id;
      }

      const postValues = getters['get_post_values'](dosageInstruction);
      postValues.action = action;

      state.updatePromise = dispatch('api/postEndpoint', {
        endpoint: medGuardEndPoint,
        data: postValues,
        config: {
          cancelToken: state.cancelTokenSource.token,
        }
      }, {root: true}).then(response => {
        commit('set_loading', false);
        dispatch('process_api_response', {data: response.data, dosageInstruction: dosageInstruction});
      }).catch(() => {});
    });
  },
  ...BaseModule.actions
};

// mutations
const mutations = {
  store_shadow_traces_as(state, dosingInstructionId) {
    const shadowTrace = {};
    shadowTrace.data = state.shadow_traces;
    shadowTrace.dosing_instruction_id = dosingInstructionId;
    Utility.delete_models_by_property(state.traces, 'dosing_instruction_id', Utility.parse_id(dosingInstructionId));
    const traces = state.traces;
    traces.push(shadowTrace);
    state.traces = traces;
  },
  set_open_signal_uid(state, uid) {
    state.signal_uid_opened = uid;
  },
  set_shadow_traces(state, traces) {
    state.shadow_traces = traces;
  },
  add_custom_signal(state, payload) {
    const newData = state.shadow_data;
    newData.push(payload);
    state.shadow_data = newData;
  },
  add_signal(state, payload) {

    const signal = payload.signal;
    const tempNotes = payload.tempNotes;

    const tempNote = Utility.find_model_by_property(tempNotes, 'screenId', signal.screenId);
    if (tempNote) {
      signal.note = tempNote.note;
    }

    const newData = state.shadow_data;
    newData.push(signal);
    state.shadow_data = newData;
  },
  remove_signal(state, payload) {
    const dosageInstructionId = payload.id;
    const screenId = payload.screenId;
    const filtered = Utility.find_models_by_property(state.shadow_data, 'dosage_instruction_id', dosageInstructionId);
    const newData = Utility.delete_model_by_property(filtered, 'screenId', screenId);
    state.shadow_data = newData;
  },
  remove_schema_signal(state, payload) {
    const schemaId = payload.schema_id;
    const uniqueHash = payload.unique_hash;
    const filtered = Utility.find_models_by_property(state.shadow_data, 'schema_id', schemaId);
    const newData = Utility.delete_model_by_property(filtered, 'uniqueHash', uniqueHash);
    state.shadow_data = newData;
  },
  clear(state, signal) {
    state.shadow_data = [];
    state.signal_uid_opened = 0;
  },
  add_shadow_note(state, payload) {
    const dosageInstructionId = payload.signal.dosage_instruction_id;

    const contraIndicationId = payload.signal.contraIndicationId;

    const medicationEvaluationId = payload.signal.medicationEvaluationId;

    if (medicationEvaluationId) {
      const filteredSignals = Utility.find_models_by_property(state.shadow_data, 'medicationEvaluationId', medicationEvaluationId);
      const signal = Utility.find_model_by_property(filteredSignals, 'uniqueHash', payload.signal.uniqueHash);
      signal.note = payload.note;
    }
    else if (contraIndicationId) {
      const screenId = payload.signal.screenId;
      let filteredSignals = Utility.find_models_by_property(state.shadow_data, 'contraIndicationId', contraIndicationId);
      filteredSignals = Utility.find_models_by_property(filteredSignals, 'statusId', payload.signal.statusId);
      const signal = Utility.find_model_by_property(filteredSignals, 'screenId', screenId);
      signal.note = payload.note;
    }
    else if (payload.schema_view) {
      const groupByHash = payload.signal.groupByHash;
      const schemaId = payload.signal.schema_id;
      const filteredSignals = Utility.find_models_by_property(state.shadow_data, 'schema_id', schemaId);
      const signals = Utility.find_models_by_property(filteredSignals, 'groupByHash', groupByHash);
      signals.forEach( (signal) => {
        signal.note = payload.note;
      });
    }
    else {
      const screenId = payload.signal.screenId;
      const filteredSignals = Utility.find_models_by_property(state.shadow_data, 'dosage_instruction_id', dosageInstructionId);
      const signal = Utility.find_model_by_property(filteredSignals, 'screenId', screenId);
      signal.note = payload.note;
    }
  },
  update_approved(state, payload) {
    if (payload.schema_view) {
      const groupByHash = payload.signal.groupByHash;
      const schemaId = payload.signal.schema_id;
      const filteredSignals = Utility.find_models_by_property(state.shadow_data, 'schema_id', schemaId);
      const signals = Utility.find_models_by_property(filteredSignals, 'groupByHash', groupByHash);
      signals.forEach((signal) => {
        signal.approved = payload.approved;
      });
    } else {
      // Approve default by uniqueHash
      const signal = Utility.find_model_by_property(state.shadow_data, 'uniqueHash', payload.signal.uniqueHash);
      signal.approved = payload.approved;
    }
  },
  add_note(state, payload) {
    const dosageInstructionId = payload.signal.dosage_instruction_id;
    const screenId = payload.signal.screenId;

    const filteredSignals = Utility.find_models_by_property(state.notes, 'dosage_instruction_id', dosageInstructionId);
    const signal = Utility.find_model_by_property(filteredSignals, 'screenId', screenId);

    signal.note = payload.note;
  },
  add_to_trace_data(state, trace) {
    const traces = state.traces;
    traces.push(trace);
    state.traces = traces;
  },
  set_loading(state, value) {
    state.loading = value;
  },
  set_schema_loading(state, value) {
    state.schema_loading = value;
  },
  ...BaseModule.mutations
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations,
};
