<script>

export default {
  components: {},

  props: [
    'name'
  ],

  mixins: [],

  data: function () {
    return {
      //
    };
  },

  computed: {
    errors() {
      return this.$store.state.api.error_bag;
    },
    validation_class() {
      if (this.invalid) {
        return 'is-invalid';
      }
      return '';
    },
    invalid() {
      return this.errors.hasOwnProperty(this.name);
    },
    invalid_message() {
      if (this.invalid) {
        return '(' + this.errors[this.name] + ')';
      }
      return '';
    }
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    //
  },

  watch: {
    //
  }
};

</script>

<style>

</style>
