<template>
  <medimo-tr @triggered="showChatModal">

    <medimo-td column_classes="col col-sm-2">
      {{ parsedUpdatedAtDate }}
    </medimo-td>

    <medimo-td column_classes="col col-sm-6">
      <span>{{ chat.subject }}</span>
      <fa-icon v-if="chat.priority" icon="fa-solid fa-circle-exclamation" size="lg" />
      <fa-icon v-if="chat.privacy" icon="fa-solid fa-key" size="lg" />
    </medimo-td>

    <medimo-td column_classes="col col-sm-2">
      {{ chat.status }}
    </medimo-td>

    <medimo-td column_classes="col col-sm-2" v-bind:class="{'text-danger': validUntilPassed}">
      {{ parsedValidUntilDate }}
    </medimo-td>

  </medimo-tr>
</template>

<script>
import Dates from '@/vue/utility/dates';
import moment from 'moment-mini';
import MedimoTd from '@/vue/components/general/table/MedimoTd';
import MedimoTr from '@/vue/components/general/table/MedimoTr';

export default {
  components: {
    MedimoTd,
    MedimoTr,
  },
  emits: ['selected'],

  props: [
    'chat',
    'patient',
  ],

  mixins: [],

  data: function () {
    return {

    };
  },

  computed: {
    parsedUpdatedAtDate() {
      return Dates.setDate(this.chat.updatedAt).dutchDateTime();
    },
    parsedValidUntilDate() {
      if (this.chat.validUntil === null) {
        return 'Geen';
      }
      return Dates.setDate(this.chat.validUntil).dutchDate();
    },
    validUntilPassed() {
      return (moment(this.chat.validUntil).diff(moment(), 'days') < 0);
    }
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    showChatModal() {
      this.$emit('selected');
    },
  },

  watch: {
    //
  }
};
</script>
