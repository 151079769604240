import BaseModule from '@/vue/store/modules/base_module';

const state = {
  ...BaseModule.state,
  data: [],
  quick_filters: {},
  toggleQuickFilters: true,
  sort_columns: [],
  search_columns: [
    'batch_number',
    'patient.naam',
    'patient.naamvoor',
    'drug.fullnaam',
  ],
};

const actions = {
  ...BaseModule.actions,
  update({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/clusters/' + payload.cluster_id + '/vaccinations/' + payload.type + '/vaccinations/' + payload.vaccination_id;
    return dispatch('api/postEndpoint', {
      data: payload.data,
      rejectErrors: true,
      endpoint: endpoint,
    }, {root: true}).then(response => {
      commit('update_selected_search_result', response.data.data);
    });
  },
  delete({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/clusters/' + payload.cluster_id + '/vaccinations/' + payload.type + '/vaccinations/' + payload.vaccination_id;
    return dispatch('api/deleteEndpoint', {
      data: payload.data,
      rejectErrors: true,
      endpoint: endpoint,
    }, {root: true});
  }
};

const getters = { ...BaseModule.getters };
const mutations = { ...BaseModule.mutations };

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
