import { useChecksIfVuePage } from '@/vue/composables/ChecksIfVuePage';
import {useRouter} from "vue-router";

export function useNavigatesProgramatically() {
  const router = useRouter();
  const {pathIsVueRoute} = useChecksIfVuePage();
  const {currentPageIsVuePage} = useChecksIfVuePage();

  function isVueRoute(to: string): boolean {
    return pathIsVueRoute(to);
  }

  function isVuePage() {
    return currentPageIsVuePage();
  }

  function toPath(to: string) {
    return to.replace(new RegExp('^' + window.location.origin), '');
  }

  function navigateProgrammatically(to: string, replace = false) {
    to = toPath(to);

    // Use Vue router when applicable
    if (!isVueRoute(to)) {
      navigateByLinkClick(to, replace);
    } else if (!isVuePage) { // Make sure we're at a place that can actually use vue navigation
      navigateByLinkClick(to, replace);
    } else if (replace) {
      router.replace(to);
    } else {
      router.push(to);
    }
  }
  function navigateByLinkClick(to: string, replace: boolean, target = '_blank') {
    if (target === '_blank') {
      window.open(to, target);
    } else if (replace) {
      window.location.replace(to);
    } else {
      window.location.href = to;
    }
  }

  return {
    navigateProgrammatically,
  };
}
