<script>
export default {
  components: {},

  props: [],

  mixins: [],

  data: function () {
    return {
      //
    };
  },

  computed: {
    tabindex() {
      let tabindex = 2;
      this.$store.state.settings.navigation.availablePageNavItems.forEach((pageNavItemUid, index) => {
        if (this.uid === pageNavItemUid) {
          tabindex = index + 2;
        }
      });
      return tabindex;
    },
    uid() {
      return this._.uid;
    },
    selectedPageNavItemUid: {
      get() {
        return this.$store.state.settings.navigation.selectedPageNavItemUid;
      },
      set(value) {
        this.$store.commit('settings/navigation/setSelectedPageNavItemUid', value);
      }
    },
    isSelected() {
      return this.uid === this.selectedPageNavItemUid;
    },
  },

  created() {
    this.addToAvailablePageNavItems();
  },
  unmounted() {
    this.removeFromAvailablePageNavItems();
  },

  mounted() {
    // Add a refs to the root element so we can focus it when needed
    this.$el.ref = 'page-nav-item';
  },

  methods: {
    addToAvailablePageNavItems() {
      this.$store.commit('settings/navigation/addToAvailablePageNavItems', this.uid);
    },
    removeFromAvailablePageNavItems() {
      this.$store.commit('settings/navigation/removeFromAvailablePageNavItems', this.uid);
    },
  },

  watch: {
    isSelected(value) {
      if (value) {
        // When it's being selected, make sure it has focus
        // This way it's functional with Tab navigation as well.
        this.$refs['page-nav-item'].focus();
      }
    }
  }
};
</script>
