import Mp9BaseModule from "@/vue/store/modules/mp9/mp9_base_module";
import {AdministrationAgreement} from "@/domain/models/AdministrationAgreement";

// initial state
const state = {
  store_namespace: 'administration_agreements',
  object: AdministrationAgreement, // todo different solution? Used in the 'abstract' store class Mp9BaseModule, so it knows what type it should fromJson.
  ...Mp9BaseModule.state,
  data: [],
};

// getters
const getters = {
  ...Mp9BaseModule.getters,
};

// actions
const actions = {
  ...Mp9BaseModule.actions,
};

// mutations
const mutations = {
  ...Mp9BaseModule.mutations,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
