import BaseModule from '@/vue/store/modules/base_module';
import Utility from '@/vue/utility/utility';

// initial state
const state = {
  ...BaseModule.state,

  data: [],

  before: null,

  sort_columns: [{column_name: 'timestamp', direction: 'DESC'}],

  quick_filters: {
    'codes': {
      'group': {
        'Hematologie': false,
        'Chemie': false,
      }
    },
    'Afgelopen jaar': true
  },

  toggleQuickFilters: true,

  history: {},

  initial_data: {}
};

// getters
const getters = {
  ...BaseModule.getters,

  find(state, getters, rootState) {
    return (id) => Utility.find_model_by_id(state.data, id);
  },
  resultsPerBepaling(state, getters, rootState) {
    const search_results = getters.filtered_search_results;
    const results = [];
    search_results.forEach((model) => {
      const keyReading = model.bepaling;
      const keyDate = model.datum;
      if (typeof results[keyReading] === 'undefined') {
        results[keyReading] = [];
      }
      results[keyReading][keyDate] = model;
    });
    return results;
  },
  dates(state, getters, rootState) {
    const search_results = getters.filtered_search_results;
    const results = search_results.map(a => a.datum);
    const unique = results.filter(function(elem, index) {
      return results.indexOf(elem) === index;
    });
    return unique.sort();
  },
  bepalingen(state, getters, rootState) {
    const search_results = getters.filtered_search_results;

    // sorteer op id oplopend (labDetails.id)
    const ordered = search_results.sort((a, b) => {
      if (a.id > b.id) { return 1; }
      if (a.id < b.id) { return -1; }
      return 0;
    });

    // maak array met gesorteerde bepalingen als key
    const results = ordered.map(a => a.bepaling);

    // filter bepalingen, maak uniek
    return results.filter(function(elem, index) {
      return results.indexOf(elem) === index;
    });
  },
};

// actions
const actions = {
  ...BaseModule.actions,

  initialLoad({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/patients/' + payload.patient_id + '/readings/init';
    return dispatch('api/getEndpoint', {
      endpoint: endpoint,
      data: {}
    }, {root: true}).then(response => {
      commit('add_to_initial_data', {patient_id: payload.patient_id, initial_data: response.data.data});
    });
  },

  store({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/patients/' + payload.patient_id + '/readings/store';
    return dispatch('api/postEndpoint', {
      rejectErrors: true,
      endpoint: endpoint,
      data: payload.data,
    }, {root: true});
  },

  update({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/patients/' + payload.patient_id + '/readings/' + payload.reading_id + '/update';
    return dispatch('api/postEndpoint', {
      endpoint: endpoint,
      data: payload.data,
    }, {root: true});
  },

  delete({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/patients/' + payload.patient_id + '/readings/' + payload.reading_id + '/delete';
    return dispatch('api/postEndpoint', {
      endpoint: endpoint
    }, {root: true});
  },

  fetchHistory({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/patients/' + payload.patient_id + '/readings/history';
    return dispatch('api/getEndpoint', {
      endpoint: endpoint,
      data: {} // Empty, since it's a GET
    }, {root: true}).then(response => {
      commit('add_to_history', {patient_id: payload.patient_id, readings_history: response.data.data});
    });
  },
};

// mutations
const mutations = {
  ...BaseModule.mutations,

  add_to_history(state, payload) {
    state.history[payload.patient_id] = payload.readings_history;
  },
  add_to_initial_data(state, payload) {
    state.initial_data[payload.patient_id] = payload.initial_data;
  },
  clear_history(state, payload) {
    delete state.history[payload.patient_id];
  },
  remove_from_data(state, payload) {
    Utility.delete_models_by_property(state.data, 'id', payload.readings_id);
  }
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
