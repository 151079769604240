import BaseModule from '@/vue/store/modules/base_module';

// initial state
// shape: [{ id, quantity }]
const state = {
  ...BaseModule.state,

  search_columns: [
    'securityGroups.name',
    // 'securityGroups.permissions.description',
  ],
  filter_columns: [
    'name',
    'permissions.description',
  ],

  results_per_page: 10,

  sort_columns: [
    {column_name: 'name', direction: 'ASC'}
  ],
};

// getters
const getters = {
  ...BaseModule.getters,
};

// actions
const actions = {
  ...BaseModule.actions,
  remove_membership ({state, commit, dispatch, getters, rootState}, payload) {
    // Verwijderd de gebruiker lid uit een groep op basis van IDs
    const user_id = payload.user_id;
    const security_group_id = payload.security_group_id;

    return dispatch('api/deleteEndpoint', {
      // Geen idee wat de &dtr is, maar hij zat in de "oude" pagina ook
      endpoint: '/config/authority/securitygroupmembers/destroy/' + user_id + '?securityGroup=' + security_group_id + '&dtr=1&suppressNotys=true',
    }, {root: true});
  },
  fetch({state, commit, dispatch, getters, rootState}, payload) {
    // Haalt alle security groups en permissies op
    const user_id = payload.user_id;

    return dispatch('api/getEndpoint', {
      // Geen idee wat de &dtr is, maar hij zat in de "oude" pagina ook
      endpoint: '/api/v1/users/' + user_id + '/security-groups',
    }, {root: true}).then(response => {
      commit('set_data', response.data.data);
      commit('set_search_results', response.data.data);
      dispatch('fill_permissions');
    });
  },
  fetch_full({state, commit, dispatch, getters, rootState}, payload) {

    // Haalt alle security groups en permissies op
    const user_id = payload.user_id;
    const security_group_id = payload.security_group_id;

    return dispatch('api/getEndpoint', {
      // Deze haalt de security group data up _inclusief_ de leden
      endpoint: '/api/v1/users/' + user_id + '/security-groups/' + security_group_id,
    }, {root: true}).then(response => {
      commit('add_to_data', response.data.data);
      dispatch('fill_security_group_members');
      dispatch('fill_permissions');
    });
  },
  fill_permissions({state, commit, dispatch, getters, rootState}, payload) {
    const permissions = [];
    // Als de security groups updaten, fillen we ook opnieuw de permissies
    state.data.forEach(securityGroup => {
      if (typeof securityGroup.permissions !== 'undefined') {
        securityGroup.permissions.forEach(permission => {
          permission.security_group_members_count = securityGroup.members_count;
          permission.security_group_name = securityGroup.name;
          permissions.push(permission);
        });
      }
    });

    commit('user/permissions/set_data', permissions, {root: true});
  },
  fill_security_group_members({state, commit, dispatch, getters, rootState}, payload) {
    const members = [];
    // Als de security groups updaten, fillen we ook opnieuw de permissies
    state.data.forEach(securityGroup => {
      if (typeof securityGroup.members !== 'undefined') {
        securityGroup.members.forEach(member => {
          member.security_group_id = securityGroup.id;
          members.push(member);
        });
      }
    });

    commit('user/security_group_members/set_data', members, {root: true});
  },
  selected_security_group(state, getters, rootState, rootGetters) {
    return rootGetters['user/security_groups/find'](rootGetters['user/permissions/selected_security_group_id']);
  },
};

// mutations
const mutations = {
  ...BaseModule.mutations,
  // dummy_mutation (state, id) {
  //     state.id = id;
  // },
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
