<template>
  <div class="d-inline m-0 p-0">
    <patient-info-medi-modal
        v-show="show_main_modal"
        :patient_id="patient.id"
        :loading="loading"
        title="Berichten"
        size="large"
        :min_height="0"
        @closed="close"
    >

      <medimo-local-data-table
          :name="'chats-data-table-' + patient_id"
          :store_name="store_name"
          :number_of_rows="models.length"
          :minimum_height="true"

          privacy_filter_property="patientId"
          :privacy_filter_value="patient_id"
      >

        <template v-slot:extra_buttons>

          <div class="row h-100 quick-filter-buttons">
            <div class="row">
              <div class="medimo-form-column col-auto">
                <div class="quick-filter-group">
                  <!--
                    Dit moet even custom omdat het een local datatable is die externe data ophaalt met de filters...
                  -->
                  <medimo-quick-filter-button
                      quick_filter_name="Actief"
                      :quick_filter_value="true"
                      :store_name="null"
                      :is_active="show_active"
                      @toggled="toggleActive()"
                  >
                  </medimo-quick-filter-button>
                  <medimo-quick-filter-button
                      quick_filter_name="Gearchiveerd"
                      :quick_filter_value="true"
                      :store_name="null"
                      :is_active="!show_active"
                      @toggled="toggleActive()"
                  >
                  </medimo-quick-filter-button>
                </div>
              </div>
            </div>
          </div>
        </template>

        <medimo-thead>
          <medimo-tr>
            <medimo-th column_classes="col col-sm-2" :store_name="store_name" sort_column_1="updatedAt">Datum/tijd</medimo-th>
            <medimo-th column_classes="col col-sm-6" :store_name="store_name" sort_column_1="subject">Onderwerp</medimo-th>
            <medimo-th column_classes="col col-sm-2" :store_name="store_name">Status</medimo-th>
            <medimo-th column_classes="col col-sm-2" :store_name="store_name" sort_column_1="validUntil">Einddatum</medimo-th>
          </medimo-tr>
        </medimo-thead>
        <medimo-tbody>
          <template v-for="model in models">
            <chats-data-table-row
                :chat="model"
                @selected="openEditModal(model)"
            ></chats-data-table-row>
          </template>
        </medimo-tbody>
      </medimo-local-data-table>

      <template v-slot:action-buttons>
        <medimo-button class="mr-md-auto" @click="openAddModal">
          Nieuwe toevoegen
        </medimo-button>

        <medimo-button @click="close">
          Sluiten
        </medimo-button>
      </template>

      <add-chat-modal
          :patient_id="patient.id"
          :trigger_model="''"
          :trigger_model_id="null"
          v-if="show_add_chat_modal"
          @closed="closeAddModal"
          @stored="storedNewChat"
      ></add-chat-modal>
    </patient-info-medi-modal>

    <chat-messages-modal
        v-if="selected_chat"
        :chat="selected_chat"
        @closed="closedEditModal"
        @updatedChat="updatedChat"
    ></chat-messages-modal>
  </div>
</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import ChatsDataTableRow from '@/vue/components/patients/chats/ChatsDataTableRow';
import MedimoButtonLink from '@/vue/components/general/MedimoButtonLink';
import AddChatModal from './AddChatModal';
import PatientInfoMediModal from '@/vue/components/modals/PatientInfoMediModal';
import ChatMessagesModal from './ChatMessagesModal';
import MedimoQuickFilterButton from "@/vue/components/general/table/MedimoQuickFilterButton.vue";

export default {

  components: {
    MedimoQuickFilterButton,
    ChatMessagesModal,
    PatientInfoMediModal,
    AddChatModal,
    MedimoButtonLink,
    ChatsDataTableRow
  },

  emits: ['closed'],

  props: {
    'patient': {required: true},

    // Set deze om automatisch een chat te openen.
    // Dit kan ook met een url parameter, of hash
    // maar via Vue (v3 > v3) is een property wenselijker
    'auto_open_chat_id': {required: false, default: null},

    // Sommige chats willen we auto-inladen, maar de table modal niet tonen, dat kan dan zo:
    'show_main_modal': {default: true},
  },

  mixins: [
    BaseComponentsMixin,
  ],

  data: function () {
    return {
      store_name: 'patient_chats',
      loading: false,
      show_add_chat_modal: false,
      show_active: true,
      selected_chat: null,
      auto_opened_chat: false,
    };
  },

  computed: {
    patient_id() {
      return this.patient.id;
    },
    // This is the computed property that would return the models / data that would generate the rows
    // usually an array / object of models from the Vuex store
    models() {
      // Here the search_results array is returned, since the Backend handles the sorting and filtering for us
      return this.$store.getters[this.store_name + '/paginated_data'].filter((model) => {
        return model.patientId === this.patient_id;
      });
    },
    route() {
      return this.$route;
    },
    icon_active() {
      return this.show_active ? 'fa-solid fa-square-check' : 'fa-regular fa-square';
    },
    icon_archived() {
      return !this.show_active ? 'fa-solid fa-square-check' : 'fa-regular fa-square';
    }
  },

  mounted() {
    this.setEndpointAndRefresh();
    this.checkForAutoOpenChat();

    if (this.auto_open_chat_id !== null) {
      this.fetchSingleChat(this.auto_open_chat_id);
    }
  },

  created() {
    //
  },
  
  methods: {
    close() {
      this.$emit('closed');
    },

    // create chat modal methods
    openAddModal() {
      this.show_add_chat_modal = true;
    },
    closeAddModal() {
      this.show_add_chat_modal = false;
    },
    storedNewChat() {
      this.closeAddModal();
      this.setEndpointAndRefresh();
    },

    // create chat message modal methods
    openEditModal(chat) {
      this.selected_chat = chat;
    },
    closeEditModal() {
      this.selected_chat = null;

      // sluit ook de ChatsDataTableModal wanneer we een deeplinked chat sluiten
      // wanneer autoriseren en digitaal nakijken ook naar v3 zijn,
      // dan kunnen we daar gelijk het ChatMessagesModal gebruiken ipv te deeplinken via de DataTableModal
      if (this.auto_opened_chat === true) {
        this.close();
      }
    },
    closedEditModal() {
      this.selected_chat = null;
    },
    updatedChat() {
      this.selected_chat = null;
      this.setEndpointAndRefresh();

      if (this.auto_opened_chat === true) {
        this.close();
      }
    },
    toggleActive() {
      this.show_active = !this.show_active;
      this.setEndpointAndRefresh();
    },
    setEndpointAndRefresh() {
      this.loading = true;
      if (this.show_active) {
        this.$store.dispatch(this.store_name + '/fetchActive', {
          patient_id: this.patient.id
        }).then(() => {
          this.loading = false;
        });
      } else {
        this.$store.dispatch(this.store_name + '/fetchArchived', {
          patient_id: this.patient.id
        }).then(() => {
          this.loading = false;
        });
      }
    },
    checkForAutoOpenChat() {
      this.checkForAutoOpenChatByHash();
      this.checkForAutoOpenChatByQuery();
    },
    checkForAutoOpenChatByHash() {
      if (!this.$route.hash.includes('#showChat=')) {
        return;
      }

      // fetch auto open chat
      const auto_open_chat_id = this.$route.hash.split('=')[1];
      this.fetchSingleChat(auto_open_chat_id);
      this.auto_opened_chat = true;

      // En nu clearen we de hash, zonder een nieuwe history aan te maken
      this.$router.replace({
        path: this.$route.path,
        hash: ''
      });
    },
    checkForAutoOpenChatByQuery() {
      if (typeof this.$route.query.showChat === 'undefined') {
        return null;
      }

      // fetch auto open chat
      const auto_open_chat_id = this.$route.query.showChat;
      this.fetchSingleChat(auto_open_chat_id);

      // En nu clearen we de URL, zonder een nieuwe history aan te maken, maar de huidige vervangen
      const query_string = '?showChat=' + auto_open_chat_id;
      this.$store.dispatch('settings/navigation/clearQueryString', {
        query_string: query_string
      });
    },
    fetchSingleChat(chat_id) {
      this.$store.dispatch(this.store_name + '/fetchSingle', {
        patient_id: this.patient.id,
        chat_id: chat_id
      }).then(() => {
        if (typeof this.$store.state.patient_chats.single.id !== 'undefined') {
          const chat = this.$store.state.patient_chats.single;
          this.openEditModal(chat);
          this.loading = false;
        }
      });
    }
  },

  watch: {
    //
  }
};
</script>
