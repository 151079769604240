import BaseModule from '@/vue/store/modules/base_module';

const state = {
  ...BaseModule.state,
  search_endpoint: '/api/v1/patients/exact-search',
  quick_filters: {
    // Dit is een grouped quick filter:
    'Selectie': { // Dit is de group name
      'group': {
        'Alleen actief': true,
        'Alle': false
      }
    },

  },
  sort_columns: [],
};

const actions = { ...BaseModule.actions };
const getters = { ...BaseModule.getters };
const mutations = { ...BaseModule.mutations };

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
