<template>
  <medi-modal
     :patient_id="patientId"
     class="medication-guard-chat-modal"
     :title="'Details bericht'"
     :loading="loading"
     @closed="$emit('closed')">

    <template v-slot:title-buttons>
      <medimo-copy-table-button
          class="ml-2 d-inline"
          tooltip_content_start="Kopiëer naar klembord"
          :cell_component_css_selector="['.chatMessage']"
          :container_component="null"
          :custom_copy_event="true"
          @custom_copy_event="copyToClipboard"
      ></medimo-copy-table-button>

      <medimo-print-button
          @printClicked="printButtonHandler"
      ></medimo-print-button>
    </template>

    <medimo-form>
      <medimo-form-row class="mb-3">
        <medimo-form-column v-if="chat.privacy">
          <medimo-tooltip extra_classes="w-100" content="Gemarkeerd als prive" :delay="100" :showOnClick="false">
            <medimo-button disabled icon="icon-key" size="sm" :type="'primary'">
            </medimo-button>
          </medimo-tooltip>
        </medimo-form-column>

        <medimo-form-column v-if="chat.requiresResponse">
          <medimo-tooltip extra_classes="w-100" content="Expliciet om reactie gevraagd" :delay="100" :showOnClick="false">
            <medimo-button disabled icon="icon-b-chat" size="sm" :type="'primary'">
            </medimo-button>
          </medimo-tooltip>
        </medimo-form-column>

        <medimo-form-column v-if="chat.priority">
          <medimo-tooltip extra_classes="w-100" content="Bericht is urgent!" :delay="100" :showOnClick="false">
            <medimo-button disabled icon="fa-solid fa-circle-exclamation" size="sm" type="primary" />
          </medimo-tooltip>
        </medimo-form-column>

        <medimo-form-column v-if="chat.validUntil">
          <medimo-tooltip extra_classes="w-100" :content="'Graag reactie voor: '+this.parsedValidUntilDate" :delay="100" :showOnClick="false">
            <medimo-button icon="icon-recipe" size="sm" :type="'primary'">
            </medimo-button>
          </medimo-tooltip>
        </medimo-form-column>

        <medimo-form-column column_classes="col-2 ml-auto">
          <medimo-tooltip extra_classes="w-100" :content="tooltipPharmacy" :delay="100" :showOnClick="false">
            <medimo-button icon="icon-single-02" size="sm" class="btn-block" :type="this.chat.participatingPharmacy ? 'primary' : 'light'">
              Apotheek
            </medimo-button>
          </medimo-tooltip>
        </medimo-form-column>
        <medimo-form-column column_classes="col-2">
          <medimo-tooltip extra_classes="w-100" :content="tooltipPhysician" :delay="100" :showOnClick="false">
            <medimo-button icon="icon-single-02" size="sm" class="btn-block" :type="this.chat.participatingPhysician ? 'primary' : 'light'">
              Arts
            </medimo-button>
          </medimo-tooltip>
        </medimo-form-column>
        <medimo-form-column column_classes="col-2">
          <medimo-tooltip extra_classes="w-100" :content="tooltipCare" :delay="100" :showOnClick="false">
            <medimo-button icon="icon-multiple-19" size="sm" class="btn-block" :type="this.chat.participatingCare ? 'primary' : 'light'">
              Zorg
            </medimo-button>
          </medimo-tooltip>
        </medimo-form-column>
      </medimo-form-row>

        <medimo-form-row>
          <medimo-form-column column_classes="col-12 mb-2">
            <h4>Onderwerp: {{ title }}</h4>
          </medimo-form-column>
        </medimo-form-row>
        <medimo-form-row>
          <medimo-form-column column_classes="col-12"
                              v-for="(message, index) in chatMessages"
                              :index="index"
                              :key="message.id">
            <chat-message :message="message" :show_username="shouldShowUsername(index)"></chat-message>
          </medimo-form-column>
        </medimo-form-row>
        <medimo-form-row>
          <medimo-form-column column_classes="col-12">
            <medimo-validated-textarea
                label_column_classes="col-12 col-sm-3"
                label="Bericht toevoegen"
                placeholder="Uw bericht"
                name="new_message"
                v-model="new_message"
                rows="3"
            ></medimo-validated-textarea>
          </medimo-form-column>
        </medimo-form-row>
    </medimo-form>

    <template v-slot:action-buttons>
      <medimo-button type="button" class="btn-outline-primary" @click="$emit('closed')">
        Annuleren
      </medimo-button>
      <medimo-button v-if="canBeMarkedAsRead" type="button" class="btn btn-primary" @click="markChatAsRead">
        Gelezen
      </medimo-button>
      <medimo-button v-if="canBeClosed" type="button" class="send-button btn btn-primary" @click="closeChatMessage">
        Afsluiten
      </medimo-button>
      <medimo-button :disabled="saveButtonDisabled" type="button" class="send-button btn btn-primary" @click="addChatMessage">
        Opslaan
      </medimo-button>
    </template>
  </medi-modal>
</template>

<script>

import ChatMessage from './ChatMessage';
import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import PrintsHtml from '@/vue/mixins/PrintsHtml';
import MedimoDatePicker from '@/vue/components/general/form/base/MedimoDatePicker';
import Dates from '@/vue/utility/dates';
import MedimoValidatedTextarea from '@/vue/components/general/form/MedimoValidatedTextarea';
import MedimoCopyTableButton from '@/vue/components/general/MedimoCopyTableButton';
import MedimoButton from '@/vue/components/general/MedimoButton';
import moment from 'moment-mini';
import Copy from '@/vue/utility/copy';
import MedimoPrintButton from '@/vue/components/general/MedimoPrintButton';

export default {
  components: {
    MedimoPrintButton,
    MedimoButton,
    MedimoValidatedTextarea,
    MedimoDatePicker,
    ChatMessage,
    MedimoCopyTableButton
  },

  emits: ['closed','updatedChat'],

  props: {
    'chat': {required: true},
    'store': {default: 'patient_chats'}
  },

  mixins: [
    BaseComponentsMixin,
    PrintsHtml
  ],

  data: function () {
    return {
      loading: false,
      title: '',
      new_message: '',
    };
  },

  computed: {
    currentUser() {
      return this.$store.state.current_user.data;
    },
    patientId() {
      return this.chat.patientId;
    },
    patient() {
      return this.$store.getters['patients/find'](this.patientId);
    },
    chatMessages() {
      return this.$store.state.patient_chats.chat_messages[this.chat.id];
    },
    saveButtonDisabled() {
      return !this.new_message.length;
    },
    parsedValidUntilDate() {
      if (this.chat.validUntil === null) {
        return 'Geen';
      }
      return Dates.setDate(this.chat.validUntil).dutchDate();
    },
    tooltipPharmacy() {
      if (this.chat.extraParticipantsPharmacy) {
        return 'extra deelnemers: ' + this.chat.extraParticipantsPharmacy;
      }
      return 'geen extra deelnemers';
    },
    tooltipPhysician() {
      if (this.chat.extraParticipantsPhysician) {
        return 'extra deelnemers: ' + this.chat.extraParticipantsPhysician;
      }
      return 'geen extra deelnemers';
    },
    tooltipCare() {
      if (this.chat.extraParticipantsCare) {
        return 'extra deelnemers: ' + this.chat.extraParticipantsCare;
      }
      return 'geen extra deelnemers';
    },
    canBeClosed() {
      return this.chat.canBeClosed;
    },
    canBeMarkedAsRead() {
      return this.chat.canBeMarkedAsReadBy;
    },
  },

  created() {
    //
  },

  mounted() {
    this.setChat();
  },

  methods: {
    addChatMessage() {
      if (this.save_button_disabled) {
        return;
      }
      const vm = this;
      this.loading = true;
      this.$store.dispatch(this.store + '/reply', {
        patient_id: this.chat.patientId,
        chat_id: this.chat.id,
        data: {
          message: this.new_message
        },
      })
        .then(() => {
          this.new_message = '';
          this.loading = false;
          vm.$emit('updatedChat');
        })
        .catch(() => {
          this.loading = false;
        });
    },
    closeChatMessage() {
      const vm = this;
      this.loading = true;
      this.$store.dispatch(this.store + '/close', {
        patient_id: this.chat.patientId,
        chat_id: this.chat.id,
        data: {
          message: this.new_message
        },
      })
        .then(() => {
          this.new_message = '';
          this.loading = false;
          vm.$emit('updatedChat');
        })
        .catch(() => {
          this.loading = false;
        });
    },
    markChatAsRead() {
      const vm = this;
      this.loading = true;
      this.$store.dispatch(this.store + '/read', {
        patient_id: this.chat.patientId,
        chat_id: this.chat.id,
        data: {},
      })
        .then(() => {
          this.new_message = '';
          this.loading = false;
          vm.$emit('updatedChat');
        })
        .catch(() => {
          this.loading = false;
        });
    },
    fetchChatMessages() {
      this.loading = true;
      this.$store.dispatch(this.store + '/fetchChatMessages', {
        patient_id: this.chat.patientId,
        chat_id: this.chat.id,
      })
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    shouldShowUsername(index) {
      if (index === 0) {
        return true;
      }

      const currentMessage = this.chatMessages[index];
      const previousMessage = this.chatMessages[index-1];

      if (currentMessage.createdByUserName !== previousMessage.createdByUserName) {
        return true;
      }
      return false;
    },
    setChat() {
      if (this.chat) {
        this.title = this.chat.subject;
        this.fetchChatMessages();
      }
    },
    copyToClipboard() {
      const data = [];

      // patient
      data.push([this.patient.name]);
      data.push([this.patient.birthday + ', ' + this.patient.age + ' jaar']);
      let location = this.patient.location.name + ' - ' + this.patient.ward.name;
      if (this.patient.room) {
        location += ', kamer ' + this.patient.room;
      }
      data.push([location]);

      // lege regel
      data.push(['']);

      // chat onderwerp
      data.push(['Onderwerp: ' + this.chat.subject]);

      // chat messages
      let previousMessage = null;
      this.chatMessages.forEach(function(message) {

        // toon naam alleen indien afwijkt van vorige bericht
        if (previousMessage === null || message.createdByUserName !== previousMessage.createdByUserName) {

          // lege regel
          data.push(['']);

          // naam en datum
          const dateAndName = message.createdByUserName + ' - ' + moment(message.createdAt).format('DD-MM-YYYY HH:mm');
          data.push([dateAndName]);
        }

        const cleanMessage = message.message.replaceAll('<br />', ' ');
        data.push([cleanMessage]);

        previousMessage = message;
      });

      if (data.length === 0) {
        return this.$store.dispatch('notifications/addDangerAlert', {message: 'Geen resultaten om te kopieren'}, {root: true});
      }
      Copy.copyContentsToClipboard(data);
    },
    printButtonHandler() {
      // patient header
      let html = '<b>';
      html += this.patient.name + '<br />';
      html += this.patient.birthday + ', ' + this.patient.age + ' jaar <br />';
      html += this.patient.location.name + ' - ' + this.patient.ward.name + '<br />';
      if (this.patient.room) {
        html += ', kamer ' + this.patient.room + '<br />';
      }
      html += '<br />';

      // chat onderwerp
      html += 'Onderwerp: ' + this.chat.subject + '<br />';
      html += '</b>';

      let htmlMessages = '';

      // chat berichten
      let previousMessage = null;
      const vm = this;
      this.chatMessages.forEach(function(message) {
        // mijn berichten rechts
        let leftOrRight = 'float:left; clear:both;';
        if (message.createdByUserName === vm.currentUser.name) {
          leftOrRight = 'float:right; clear:both;';
        }

        // toon naam alleen indien afwijkt van vorige bericht
        let htmlMessage = '';
        if (previousMessage === null || message.createdByUserName !== previousMessage.createdByUserName) {
          htmlMessage = '<b>' + message.createdByUserName + ' - ' + moment(message.createdAt).format('DD-MM-YYYY HH:mm') + '</b><br />';
        } else {
          htmlMessage = '<b>' + moment(message.createdAt).format('DD-MM-YYYY HH:mm') + '</b><br />';
        }

        // toon bericht
        htmlMessage += '<pre>' + message.message + '</pre><br />';

        // bericht in div met float left or right
        htmlMessages += '<br /><div style="border: 1px solid #999; border-radius:2px; ' + leftOrRight + ' padding:5px; margin-bottom:10px; max-width:300px;">';
        htmlMessages += htmlMessage;
        htmlMessages += '</div>';

        previousMessage = message;
      });

      // bericht in div met max width centreren
      html += '<div style="margin:auto; max-width:500px; font-size:9px;">' + htmlMessages + '</div></body></html>';

      // roep mixin print method aan
      this.printHtml(html);
    }
  },

  watch: {
    chat() {
      this.setChat();
    }
  }
};
</script>
