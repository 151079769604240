<template>
  <input type="hidden" :name="name" :value="value">
  <div :id="id" class="main" v-html="value"></div>
</template>

<script setup lang="ts">

import {onMounted} from "vue";
import Quill from 'quill';

const emit = defineEmits(['update:modelValue']);

interface Props {
  id: string,
  name: string,
  value: string,
  readonly: boolean,
  placeholder: string,
  width?: string,
  height?: string,
}

const props = withDefaults(defineProps<Props>(), {
  id: undefined,
  name: undefined,
  value: undefined,
  readonly: undefined,
  placeholder: undefined,
  width: '500px',
  height: '100px',
});

onMounted(() => {
  $(function () {
    const quill = new Quill($('#' + props.id).get(0), {
      formats: ['bold', 'italic', 'link', 'list'],
      modules : {
        toolbar: props.readonly ? false : [
          ['bold', 'italic'],
          [{'list': 'ordered'}, {'list': 'bullet'}],
          ['link'],
          ['clean']
        ]
      },
      readOnly: props.readonly,
      placeholder: props.placeholder,
      theme: 'snow'
    });
    quill.on('text-change', function () {
      let text = quill.root.innerHTML;
      // do not save empty
      if (text === '<p><br></p>') {
        text = '';
      }

      $('input[name="' + props.name + '"]').val(text);
      emit('update:modelValue', text);
    });
  });
});

</script>

<style lang="scss" scoped>
  @import 'quill/dist/quill.snow.css';

  div {
    width: v-bind(width);
    height: v-bind(height);

    :deep(.ql-editor) { // Making it more specific than Quill's own css.
      p, ol, ul { // Reset margin and padding.
        margin: 0;
        padding: 0;
      }

      p {
        padding: 15px 0 0 0;
      }

      ol {
        padding: 15px 0 0 0;
      }

      p:first-child, ol:first-child, ul:first-child {
        padding: 0;
      }

      li:not(.ql-direction-rtl)::before {
        margin-left: -23px;
      }
    }
  }
</style>
