<template>
    <transition name="fade">
        <div id="nav-search" v-click-outside="hideResults" v-if="$store.state.current_user.isLoaded">
            <fa-icon icon="fa-solid fa-search" class="search is-input" size="lg" @click="toggler = !toggler" />
            <input type="text" v-model="query" placeholder="Zoeken in alles..."
                   autocomplete="off"
                   id="nav-search-query"
                   ref="navSearch"
                   @focusin="navSearchNavigation = true"
                   tabindex="1"
                   v-on:keyup.enter.stop="triggerKeyboardNavigation"
                   v-on:keyup.esc="hideResults"
                   v-bind:class="{'open': navSearchNavigation}"
            >
            <template v-if="navSearchNavigation">
                <fa-icon icon="fa-solid fa-remove" class="is-clickable d-block-inline d-sm-none" @click="toggler = !toggler" />
            </template>
            <transition name="fade">
                <div class="list-group" ref="nav-search-results" v-show="navSearchNavigation" @scroll="scrolled">
                    <div class="row no-gutters m-2" v-if="recentPatients.length > 0 || menuLinks.length > 0 || query.length > 0">

                        <!-- RECENTLY VISITED PATIENTS -->
                        <template v-if="query.length === 0 && recentPatients.length > 0">
                            <nav-search-divider>
                                Laatst bezochte {{ $store.state.current_user.data.language.patient_plural }}
                            </nav-search-divider>
                            <nav-search-link v-for="(patient, index) in recentPatients" :key="'patient' + patient.id + '-' + index"
                                             :triggerNavigation="navigationTrigger"
                                             :navSearchItem="{
                                                description: '',
                                                path: '/status/' + patient.id,
                                                title: patient.name,
                                                visible: true,
                            }"></nav-search-link>
                        </template>

                        <!-- MENU LINKS -->
                        <template v-if="query.length === 0 && menuLinks.length > 0">
                            <nav-search-divider>
                                Links
                            </nav-search-divider>
                            <nav-search-link v-for="(menuLink, index) in menuLinks" :key="'menuLink' + menuLink.path + index"
                                             :triggerNavigation="navigationTrigger"
                                             :navSearchItem="menuLink"
                                             target="_blank"
                            ></nav-search-link>
                        </template>

                        <!-- PATIENT SEARCH RESULTS -->
                        <template v-if="query.length > 0">
                            <nav-search-divider>
                                {{ $store.state.current_user.data.language.patient_plural }}
                            </nav-search-divider>
                            <nav-search-item v-if="loading">
                                <medimo-loader size="18"></medimo-loader>
                            </nav-search-item>
                            <template v-else-if="patientSearchResults.length">
                                <nav-search-link tabindex="0"
                                                 :key="'patientSearchResult' + index" :navSearchItem="patient"
                                                 :select_first_link="true"
                                                 :first_link_index="index"
                                                 :triggerNavigation="navigationTrigger"
                                                 v-for="(patient, index) in patientSearchResults"></nav-search-link>

                                <nav-search-item :small="true" v-if="patientSearchResultsTotal > 5">
                                    5 van de {{ patientSearchResultsTotal }} overeenkomstige resultaten
                                </nav-search-item>
                            </template>
                            <!-- When the user hasn't searched yet -->
                            <nav-search-item v-else-if="lastQuery === 0">
                                Begin met typen om te zoeken naar {{
                                $store.state.current_user.data.language.patient_plural }}...
                            </nav-search-item>
                            <nav-search-item v-else>
                                Geen {{ $store.state.current_user.data.language.patient_plural }} gevonden
                            </nav-search-item>
                        </template>

                        <!-- OVERVIEW PAGES -->
                        <template v-if="query.length > 0">
                            <nav-search-divider>
                                Pagina's
                            </nav-search-divider>
                            <nav-search-item v-if="loading">
                                <medimo-loader size="18"></medimo-loader>
                            </nav-search-item>
                            <component v-for="(menuItem, index) in menuItems"
                                       v-else-if="menuItems.length"
                                       :is="getNavSearchPageLinkComponentName(menuItem)"
                                       tabindex="0"
                                       :key="'navSearchItem' + menuItem.path + index"
                                       :navSearchItem="menuItem"
                                       :triggerNavigation="navigationTrigger"></component>
                            <nav-search-item v-if="menuItems.length === 0 && ! loading">
                                Geen pagina's gevonden
                            </nav-search-item>
                        </template>
                    </div>
                </div>
            </transition>
        </div>
    </transition>
</template>

<script>
import Utility from '@/vue/utility/utility';
import MedimoLoader from '@/vue/components/general/MedimoLoader';
import NavSearchItem from '@/vue/components/nav/NavSearchItem';
import NavSearchLink from '@/vue/components/nav/NavSearchLink';
import NavSearchReportLink from '@/vue/components/nav/NavSearchReportLink';
import NavSearchDivider from '@/vue/components/nav/NavSearchDivider';
import NavSearchAuthorisationListLink from "@/vue/components/nav/NavSearchAuthorisationListLink.vue";
import { nextTick } from 'vue';

export default {
  components: {
    NavSearchLink,
    NavSearchDivider,
    MedimoLoader,
    NavSearchItem,
    NavSearchReportLink,
    NavSearchAuthorisationListLink,
  },

  props: [],

  mixins: [],

  data: function () {
    return {
      query: '',
      loading: false,

      // This value keeps track of how recently the Query value has been changed
      // This in turn helps prevent frequent API calls
      lastQuery: 0,
      queryIndex: [],

      // Switching this from false to true or vice verse will trigger navigation of the selected link
      navigationTrigger: false,

      // This keeps track of the keyboard navigation's position in the NavSearchLinks
      navSearchLinkIndex: 0,

      // An array that keeps track of the available NavSearchLinks
      availableNavSearchLinks: [],
    };
  },

  computed: {
    menuItems() {
      const vm = this;
      const visibleRoutes = this.$store.getters['routes/visible_routes'].filter(route => {
        return Utility.matches_filter_query(route, vm.query, ['title', 'description']);
      });
        // Return the first 5
      return visibleRoutes.slice(0, 5);
    },
    toggler: {
      get() {
        return this.$store.state.settings.navigation.navSearchToggle;
      },
      set(value) {
        this.$store.commit('settings/navigation/toggleNavSearch');
      }
    },
    navSearchNavigation: {
      get() {
        return this.$store.state.settings.navigation.navSearchNavigation;
      },
      set(value) {
        this.$store.commit('settings/navigation/setNavSearchNavigation', value);
      }
    },
    selectedNavSearchLinkUid: {
      get() {
        return this.$store.state.settings.navigation.selectedNavSearchLinkUid;
      },
      set(value) {
        this.$store.commit('settings/navigation/setSelectedNavSearchLinkUid', value);
      }
    },
    // The Last available link is the last index
    lastLinkIndex() {
      return this.availableNavSearchLinks.length - 1;
    },
    // For later use, when we have a multi-column navsearch
    verticalNavigationSteps() {
      // if (this.menuItems.length > 6) {
      //     return 2;
      // }
      return 1;
      // },
    },
    recentPatients() {
      return this.$store.state.settings.breadcrumbs.patients;
    },
    patientSearchResults() {
      // Format the search results into a format usable for the NavSearchLink
      // description, path, title, right_title, and visible
      const patientResultsMenuItems = [];

      this.$store.state.patients.search_results.forEach(search_result => {
        const patient = {};
        patient.title = search_result.naam;
        patient.path = '/status/' + search_result.id;

        // Otherwise we wouldn't get it from the API
        patient.visible = true;

        // By default, show the birth date to the right
        patient.right_title = search_result.gebdat;

        if (typeof search_result.match !== 'undefined') {
          patient.right_title = search_result.match;
        }
        patientResultsMenuItems.push(patient);
      });

      return patientResultsMenuItems;
    },
    patientSearchResultsTotal() {
      return this.$store.state.patients.search_meta.total_results;
    },
    menuLinks() {
      const menuLinks = [];

      this.$store.state.current_user.data.menu_links.forEach(menu_link => {
        const menuLink = {};
        menuLink.title = '<i class="fa fa-external-link"></i> ' + menu_link.title;
        menuLink.path = menu_link.url;
        menuLink.visible = true;
        menuLink.target = '_blank';
        menuLinks.push(menuLink);
      });

      return menuLinks;
    },
    // Met de toevoeging van de NavSearchReportLink kan er een VueSelect2 open zijn
    // deze worden om buiten de modal zichtbaar te zijn aan de <body> toegevoegd.
    // Zonder deze check zou zowel de NavSearch als de Modal sluiten als je dan
    // een optie selecteert. Logica voor de MediModal en NavSearch is daarin gelijk.
    allowedToClose() {
      return this.$store.getters['settings/modals/allowed_to_close'];
    },
  },

  created() {
    // Then setup a listener
    window.addEventListener('keydown', this.navigate);
  },

  beforeUnmount() {
    window.removeEventListener('keydown', this.navigate);
  },

  mounted() {
    nextTick(() => {
      this.updateAvailableNavSearchLinks();
    });
  },

  methods: {
    // This one gets all the .nav-search-link elements inside the NavSearch and returns the UID as an array
    // Used to be a Store thing, but this should be a lot faster
    updateAvailableNavSearchLinks() {
      // missing forEach on NodeList for IE11
      if (window.NodeList && !NodeList.prototype.forEach) {
        NodeList.prototype.forEach = Array.prototype.forEach;
      }

      const uids = [];
      const vm = this;

      nextTick(() => {
        // On some mobile devices this returns false and subsequently throws an error
        if (typeof vm.$el.querySelectorAll === "function") {
          const navSearchLinkElements = vm.$el.querySelectorAll('.nav-search-link');
          if (typeof navSearchLinkElements.forEach === "function") {
            // safe to use the function
            navSearchLinkElements.forEach(element => {
              uids.push(parseInt(element.getAttribute('uid')));
            });
          }
        }

        this.availableNavSearchLinks = uids;

        // And then select the first one
        this.selectFirstNavSearchLink();
      });
    },
    selectFirstNavSearchLink() {
      if (this.availableNavSearchLinks.length > 0) {
        this.selectedNavSearchLinkUid = this.availableNavSearchLinks[0];
      }
    },
    scrolled(event) {
    },
    navigate(event) {
      // If the NavSearch is closed, disabled, disable the up/down listeners
      if (!this.navSearchNavigation) {
        return null;
      }

      if (event.keyCode == '37') {
        // left arrow
        // hide when there isn't a query active
        if (this.query.length === 0) {
          this.hideResults();
        }
      } else if (event.keyCode == '38') {
        // up arrow
        this.goBackward(this.verticalNavigationSteps);
      } else if (event.keyCode == '40') {
        // down arrow
        this.goForward(this.verticalNavigationSteps);
      }
    },
    triggerKeyboardNavigation() {
      this.navigationTrigger = !this.navigationTrigger;
    },
    goBackward(amount) {
      // If the new one is in the range of possibilities, change it!
      // Otherwise set it to the last possibility
      if (this.navSearchLinkIndex - amount < 0) {
        // Trigger the Search if it's less than 0
        this.$store.commit('settings/navigation/toggleNavSearch');
      } else {
        this.navSearchLinkIndex -= amount;
      }
    },
    goForward(amount) {
      // If the new one is in the range of possibilities, change it!
      // Otherwise set it to the last possibility
      if (this.navSearchLinkIndex + amount >= this.lastLinkIndex) {
        this.navSearchLinkIndex = this.lastLinkIndex;
      } else {
        this.navSearchLinkIndex += amount;
      }
    },
    hideResults() {
      if (this.navSearchNavigation && this.allowedToClose) {
        this.navSearchNavigation = false;
      }
    },
    focusSearch() {
      this.$refs['navSearch'].focus();
    },
    unFocusSearch() {
      this.$refs['navSearch'].blur();
    },
    getNavSearchPageLinkComponentName(menuItem) {
      // Hoe we het nu voor de v3 reports doen, maar onderstaande is meer foolproof
      if (menuItem.path.includes('/reports/v3/')) {
        return 'nav-search-report-link';
      }
      // Kun je in de routes.js meegeven om een custom component met functionaliteit te renderen
      // Niet vergeten deze ook te importeren en toe te voegen aan de `components` van dit component
      if (menuItem.componentName !== undefined) {
        return menuItem.componentName;
      }
      // Default
      return 'nav-search-link';
    }
  },

  watch: {
    query(value) {
      this.loading = true;
      this.lastQuery = this.lastQuery + 1;
      const currentQuery = this.lastQuery;

      // Keep track of the latest query
      this.queryIndex.push(this.lastQuery);

      setTimeout(() => {
        // When the last query is equal to this one, we fire it off.
        if (this.queryIndex[this.queryIndex.length - 1] === currentQuery) {
          this.$store.dispatch('patients/search', {query: this.query}).then(() => {
            this.loading = false;
          }).catch(error => {
            this.loading = false;
          });
        }
      }, 300); // Don't try and engage the API until 300ms of no typing
    },
    toggler(value) {
      // If it changed, we show the search
      if (value) {
        this.focusSearch();
      } else {
        this.navSearchNavigation = false;
      }
    },
    navSearchLinkIndex(value) {
      // Here we set the selected NavSearchLink UID based on the Available one's index
      // Needs to be NextTick because that's when the DOM has updated
      this.selectedNavSearchLinkUid = this.availableNavSearchLinks[value];
    },
    navSearchNavigation(open) {
      // Reset the query on close
      if (!open) {
        setTimeout(() => {
          this.query = '';
          this.unFocusSearch();
        }, 100);
      } else {
        this.focusSearch();
      }

      // This small delay hides the visible "jump" of the Blue from the selection / selected NavSearchLink
      setTimeout(() => {
        this.navSearchLinkIndex = 0;
      }, 300);
    },

    loading() {
      nextTick(() => {
        this.updateAvailableNavSearchLinks();
      });
    }
  }
};
</script>
