<template>
  <medimo-dropdown v-bind="$attrs" :text="text" :variant="variant"
                   @click="$emit('click')"
                   class="medimo-dropdown-button"
                   :split-to="isVueRoute && isVuePage ? to : ''"
                   :split-href="!isVueRoute || !isVuePage ? to : ''"
  >
    <slot></slot>
  </medimo-dropdown>
</template>

<script>
import DeterminesVueOrHrefLink from '@/vue/mixins/navigation/DeterminesVueOrHrefLink';
import MedimoDropdown from './MedimoDropdown';

export default {
  components: {
    MedimoDropdown,
  },
  emits: ['click'],
  props: {
    'text': {
      required: true,
    },
    'variant': {
      default: 'primary',
    },
    'to': {
      default: '',
    },
    'disabled_button': {
      default: false,
    }
  },

  mixins: [
    DeterminesVueOrHrefLink
  ],

  data: function () {
    return {
      //
    };
  },

  computed: {
    //
  },

  created() {
    //
  },

  mounted() {
    // setTimeout(() => {
    //   this.deactivateButton();
    // }, 500);
  },

  methods: {
    deactivateButton() {
      this.$el.querySelector("[id='first-button__BV_button_']").setAttribute("disabled", "");
    },
    activateButton() {
      this.$el.querySelector("[id='first-button__BV_button_']").removeAttribute("disabled");
    }
  },

  watch: {
    disabled_button(value) {
      if (value) {
        this.activateButton();
      } else {
        this.deactivateButton();
      }
    }
  }
};
</script>
