<template>
    <medimo-tooltip  :content="tooltip">
      <a :id="id" :class="'badge badge-' + type + ''"
         @click="$emit('click')"
      >
        <i v-if="icon.length" :class="icon_class"></i>
        <slot></slot>
      </a>
    </medimo-tooltip>
</template>

<script>
import MedimoTooltip from './MedimoTooltip';
export default {
  components: {MedimoTooltip},
  emits: ['click'],
  props: {
    id: {default: null},
    icon: {default: ''},
    type: {default: 'primary'},
    tooltip: {default: '', type: null},
  },

  mixins: [],

  data: function () {
    return {
      //
    };
  },

  computed: {
    icon_class() {
      return 'fa ' + this.icon;
    },
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    //
  },

  watch: {
    //
  }
};
</script>
