import BaseModule from '@/vue/store/modules/base_module';

const state = {
  ...BaseModule.state,

  export_columns: [
    // 'name',
  ],
};

// getters
const getters = {
  ...BaseModule.getters
};

// actions
const actions = {
  ...BaseModule.actions,

  fetch({state, commit, dispatch, getters, rootState, rootGetters}, reportType) {
    return dispatch('api/getEndpoint', {
      endpoint: '/api/v1/reports/list/'
    }, {root: true}).then(response => {
      commit('set_data', response.data.data);
      //
    }).catch(error => {
      //
    });
  },
  generate({state, commit, dispatch, getters, rootState, rootGetters}, payload) {
    const endpoint = '/api/v1/reports/type/' + payload.report_type;
    let data = {};

    if (typeof payload.report_options !== 'undefined') {
      data = payload.report_options;
    }

    return dispatch('api/getEndpoint', {
      endpoint: endpoint,
      params: data,
    }, {root: true}).then(response => {

      const report = response.data.data;

      if (report.generated_on === null) {
        // Als het rapport niet generated is, is hij gequeued en openen we de websocket
        dispatch('notifications/addInfoAlert', {
          message: 'Het overzicht wordt samengesteld. U krijgt een notificatie wanneer deze klaar staat.',
        }, {root: true});
      }

      return report;

    }).catch(error => {
      this.loading = false;
    });
  }
};

// mutations
const mutations = {
  ...BaseModule.mutations,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
