<template>
  <div :class="'medimo dropdown ' + (slim ? 'slim' : '')"
       v-bind="$attrs"
       v-click-outside="closeDropdown"
  >
    <!-- If it's a split button, we make it a btn-group for the right styling -->
    <div :class="(split ? 'btn-group' : '') + ' ' + (drop_up ? 'dropup' : '')">
      <!-- NON-SPLIT BUTTON -->
      <medimo-link
          :class="'btn is-clickable btn-' + variant + ' ' + (disabled ? 'disabled' : '') + ' ' + (!split ? 'dropdown-toggle' : '')"
          :to="to"
          :target="target"
          :trigger="navigation_trigger"
          @click="mainButtonClick()"
      >
                <span v-if="icon.length" class="medimo-dropdown-icon" style="position: relative;">
                    <fa-icon :icon="icon" size="lg"/>
                    <span v-if="badge_count" class="badge badge-danger badge-pill">{{ badge_count }}</span>
                    <fa-icon v-if="chevron_down" icon="fa-solid fa-caret-down" style="position: relative; left: -3px;"/>
                </span>
        <span v-else>{{ text }} <span v-if="badge_count" class="badge badge-danger badge-pill">{{ badge_count }}</span></span>
      </medimo-link>

      <!-- SPLIT BUTTON -->
      <button v-if="split" type="button" :class="'btn btn-' + variant + ' dropdown-toggle dropdown-toggle-split'"
              @click="open_dropdown = !open_dropdown"
      >
        <span class="sr-only">Toggle Dropdown</span>
      </button>

      <transition name="fade">
        <div @click="closeDropdownTrigger++" v-show="open_dropdown" :class="'medimo-dropdown-menu dropdown-menu ' + (right ? ' dropdown-menu-right' : '')">
          <slot></slot>
        </div>
      </transition>
    </div>
  </div>

</template>

<script>
import MedimoLink from './MedimoLink';

export default {
  components: {
    MedimoLink
  },

  emits: ['clicked'],

  props: {
    'icon': {default: ''},

    'chevron_down': {default: false},

    // When icon is left empty, this value will be used
    'text': {default: 'Default Button Text'},

    // Enables a badge, works with text and icon
    'badge_count': {default: 0},

    // Set to true to align the dropdown to the right side of the button
    'right': {default: false},

    // Colors the button
    'variant': {default: 'light'},

    // If you want the button to actually link somewhere instead of opening the dropdown, use this.
    // Mainly for split button use
    'to': {default: ''},

    // And then set this to configure how the link opens
    'target': {default: '_self'},

    // Creates a second button that actually opens the dropdown, leaving the main / text button to link somewhere else
    'split': {default: false},

    // Disables the button for read-only purposes
    'disabled': {default: false},

    // Action
    // Can be used to specify a Vuex action that should be triggered on click. Takes precedence over the "to" navigation
    'action': {default: ''},

    // Makes the div a slim version that doesn't have the default line-height and height
    // Easier to use in places where you don't want the div to heighten the parent element.
    // Use align-items-center or align-self-center for variable vertical alignment
    'slim': {default: false},

    'closeDropdownTrigger': {default: 0}, // ++ deze om de dropdown te sluiten
  },

  mixins: [
    //
  ],

  data: function () {
    return {
      open_dropdown: false,
      drop_up: false,

      // Used to navigate programmatically to the <medimo-link>
      navigation_trigger: false,
    };
  },

  computed: {
    //
  },

  created() {
    window.addEventListener('scroll', this.positionDropdown);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.positionDropdown);
  },

  mounted() {
    this.positionDropdown();
  },

  methods: {
    closeDropdown() {
      if (this.open_dropdown) {
        this.open_dropdown = false;
      }
    },
    // Two things can happen here - when it's a split button, this triggers a navigation
    // When it's a regular button, it opens the dropdown
    mainButtonClick() {
      // When a split button, we simply toggle and we're done
      if (!this.split) {
        this.open_dropdown = !this.open_dropdown;
      } else if (this.action.length) {
        // If an action has been specified, we dispatch the action
        this.$store.dispatch(this.action);
      } else {
        // In all other cases, we want to navigate to the <medimo-link>
        if (this.to.length) {
          this.navigation_trigger = !this.navigation_trigger;
        } else {
          this.$emit('clicked');
        }
      }
    },
    positionDropdown() {
      const distanceFromBottom = window.innerHeight - this.$el.getBoundingClientRect().y;
      // When the element is less than 400 pixels away from the bottom, drop up
      this.drop_up = distanceFromBottom < 400;
    }
  },

  watch: {
    closeDropdownTrigger() {
      this.open_dropdown = false;
    }
  }
};
</script>
