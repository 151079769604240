import {store} from "@/vue/store";
import {DispenseRequest} from "@/domain/models/DispenseRequest";
import {Options} from "@/controllers/InitialDataController";
import {DispenseRequestWarning} from "@/domain/models/DispenseRequestWarning";
import {DispenseRequestError} from "@/domain/models/DispenseRequestError";
import {CodeObject} from "@/domain/models/CodeObject";
import {MedicationOverviewRow} from "@/domain/models/MedicationOverviewRow";

export class DispenseRequestController {
  public async store(patientId: number, dispenseRequests: DispenseRequest[], pharmacyId: number): Promise<void> {
    await store.dispatch('api/postEndpoint', {
      endpoint: '/api/v1/mp9/patient/' + patientId + '/dispense-request',
      data: {
        dispenseRequests: dispenseRequests,
        pharmacyId: pharmacyId,
      }
    });
  }

  public async list(patientId: number): Promise<InitialDispenseRequestData> {
    const response = await store.dispatch('api/getEndpoint', {
      endpoint: '/api/v1/mp9/patient/' + patientId + '/dispense-request',
    });

    return {
      medication: MedicationOverviewRow.multipleFromJson(response.data.medication),
      additionalWishes: response.data.additionalWishes,
      defaultPharmacy: response.data.defaultPharmacy,
      dispenseLocations: response.data.dispenseLocations,
    };
  }

  public async create(patientId: number, medicationAgreementId: number): Promise<DispenseRequestResponse> {
    const response = await store.dispatch('api/postEndpoint', {
      endpoint: '/api/v1/mp9/patient/' + patientId + '/dispense-request/' + medicationAgreementId,
    });

    return {
      dispenseRequest: DispenseRequest.fromJson(response.data.dispenseRequest),
      dispenseRequestHistory: DispenseRequest.multipleFromJson(response.data.dispenseRequestHistory),
      errors: DispenseRequestError.multipleFromJson(response.data.errors),
      warnings: DispenseRequestWarning.multipleFromJson(response.data.warnings),
      hasToBeAuthorized: response.data.hasToBeAuthorized as boolean,
      disabledCalculation: response.data.disabledCalculation as boolean,
      showCalculation: response.data.showCalculation as boolean,
      financialIndicationCodes: CodeObject.multipleFromJson(response.data.financialIndicationCodes),
    };
  }

  public async calculate(patientId: number, medicationAgreementId: number, amount: number, numberOfRefills: number, numberOfDays: number, enoughUntil: string, type: string): Promise<CalculateResponse> {
    const response = await store.dispatch('api/postEndpoint', {
      endpoint: '/api/v1/mp9/patient/' + patientId + '/dispense-request/' + medicationAgreementId + '/calculate',
      data: {
        amount: amount,
        numberOfRefills: numberOfRefills,
        numberOfDays: numberOfDays,
        enoughUntil: enoughUntil,
        type: type
      },
    });

    return response.data;
  }
}

export interface CalculateResponse {
  amount: number,
  enoughUntil: string,
  numberOfDays: number,
  dispenseRequest: DispenseRequest,
}

export interface InitialDispenseRequestData {
  medication: MedicationOverviewRow[],
  additionalWishes: Options[],
  // dates: DispenseDates,
  defaultPharmacy: Options,
  dispenseLocations: Options[],
}

// export interface DispenseDates {
//   todayDate: string,
//   tomorrowDate: string,
//   nextDeliveryDate: string,
//   defaultDate: string,
// }

export interface DispenseRequestResponse {
  dispenseRequest: DispenseRequest,
  dispenseRequestHistory: DispenseRequest[],
  errors: DispenseRequestError[],
  warnings: DispenseRequestWarning[],
  hasToBeAuthorized: boolean,
  disabledCalculation: boolean,
  showCalculation: boolean,
  financialIndicationCodes: CodeObject[],
}
