// import VueRouter from 'vue-router';
import routes from 'vue-auto-routing';

import {createRouter, createWebHistory} from 'vue-router';

import PageNotFound from '@/vue/pages/404.vue';

routes.push({ path: '/:pathMatch(.*)*', name: '404-not-found', component: PageNotFound });
routes.push({ path: '/:pathMatch(.*)', name: '404-bad-not-found', component: PageNotFound });

export const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  routes, // short for `routes: routes`
});
