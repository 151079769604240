<template>
  <medi-modal
      title="Trombosedienst historie"
      size="large"
      :patient-id="patientId"
      @closed="emit('closed')"
  >
    <medimo-external-data-table
        :name="'thrombosis-log-data-table'"
        :store_name="storeName"
        :number_of_rows="logs.length"
        :minimum_height="true"
        :privacy_filter="false"
        quick_filter_buttons_class="col-md-5"
    >
      <medimo-thead>
        <medimo-tr>
          <medimo-th column_name_1="Datum/tijd" :store_name="storeName" sort_column_1="created_at" column_classes="col-sm-2"></medimo-th>
          <medimo-th column_name_1="Trombosedienst" :store_name="storeName" sort_column_1="importer"></medimo-th>
          <medimo-th column_name_1="Melding" :store_name="storeName" sort_column_1="message"></medimo-th>
        </medimo-tr>
      </medimo-thead>
      <medimo-tbody>
        <template v-for="log in logs" :key="log.id">
          <medimo-tr @click="loadModal(log)">
            <medimo-td column_classes="col-sm-2"> {{ log.created_at }}</medimo-td>
            <medimo-td> {{ log.importer }}</medimo-td>
            <medimo-td> {{ log.message }}</medimo-td>
          </medimo-tr>
        </template>
      </medimo-tbody>
    </medimo-external-data-table>
  </medi-modal>

  <medi-modal title="Trombosedienstmelding" @closed="showModal = false" v-if="showModal" :loading="loading">
    <div v-html="htmlContent"></div>
  </medi-modal>
</template>

<script setup lang="ts">
import MediModal from "@/vue/components/general/MediModal.vue";
import MedimoExternalDataTable from "@/vue/components/general/table/MedimoExternalDataTable.vue";
import MedimoThead from "@/vue/components/general/table/MedimoThead.vue";
import MedimoTbody from "@/vue/components/general/table/MedimoTbody.vue";
import MedimoTr from "@/vue/components/general/table/MedimoTr.vue";
import MedimoTh from "@/vue/components/general/table/MedimoTh.vue";
import MedimoTd from "@/vue/components/general/table/MedimoTd.vue";
import {ThrombosisLog} from "@/domain/models/ThrombosisLog";
import {computed, onMounted, ref} from "vue";
import {store} from "@/vue/store";

const emit = defineEmits(['closed']);

interface Props {
  patientId: number,
}

const props = defineProps<Props>();

const storeName = ref('log_thrombosis');
const loading = ref(false);
const showModal = ref(false);
const htmlContent = ref('');

const logs = computed<ThrombosisLog[]>(() => {
  return store.getters[storeName.value + '/search_results'];
});

onMounted(() => {
  store.commit(storeName.value + '/set_search_endpoint', '/api/v1/patients/' + props.patientId + '/thrombosis/history');
});

function loadModal(log: ThrombosisLog): void {
  loading.value = true;
  showModal.value = true;

  store.dispatch('api/getEndpoint', {
    'endpoint': '/api/v1/patients/' + props.patientId + '/thrombosis/popup/' + log.id
  }).then(response => {
    htmlContent.value = response.data;
    loading.value = false;
  }).catch(() => {
    loading.value = false;
  });
}

</script>
