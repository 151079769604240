<template>

    <patient-info-medi-modal
        :patient_id="patient.id"
        :loading="loading"
        @closed="$emit('closed')"
        title="Medicatiestatus"
        size="large"
        :ref="'info-modal'+this.uid"
        :min_height="0">

        <template v-slot:title-buttons>

            <medimo-copy-table-button
                    class="ml-2"
                    tooltip_content_start="Kopiëer alle tabellen naar klembord"
                    row_component_css_selector=".medimo-tr.tr"
                    :cell_component_css_selector="['.medimo-td.td', '.medimo-th.th']"
                    :container_component="this_component"
            ></medimo-copy-table-button>

            <medimo-print-button
                @printClicked="printButtonHandler"
            ></medimo-print-button>

        </template>

        <status-overview-content
            :patient="patient"
            @change_loading="loading=$event"
        ></status-overview-content>
    </patient-info-medi-modal>
</template>

<script>

import PatientInfoMediModal from '@/vue/components/modals/PatientInfoMediModal';
import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import IntoleranceRow from './IntoleranceRow';
import IndicationRow from './IndicationRow';
import StatusOverviewMedicationTable from './StatusOverviewMedicationTable';
import MedimoCopyTableButton from '@/vue/components/general/MedimoCopyTableButton';
import PrintsHtml from '@/vue/mixins/PrintsHtml';
import MedimoPrintButton from '@/vue/components/general/MedimoPrintButton';
import StatusOverviewContent from './StatusOverviewContent';

export default {
  emits: ['closed'],
  components: {
    StatusOverviewContent,
    MedimoPrintButton,
    StatusOverviewMedicationTable,
    IndicationRow,
    IntoleranceRow,
    MedimoCopyTableButton,
    PatientInfoMediModal
  },

  props: [
    'patient',
  ],

  mixins: [
    BaseComponentsMixin,
    PrintsHtml
  ],

  data: function () {
    return {
      loading: false,
    };
  },

  computed: {
    patient_id() {
      return this.$route.params.patient_id;
    },
    uid() {
      return this._.uid;
    },
    this_component() {
      return this;
    }
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    printButtonHandler() {

      // patient header
      let html = '<b>';
      html += this.patient.name + '<br />';
      html += this.patient.birthday + ', ' + this.patient.age + ' jaar <br />';
      html += this.patient.location.name + ' - ' + this.patient.ward.name;
      if (this.patient.room) {
        html += ', kamer ' + this.patient.room;
      }
      html += '</b><br /><br /><br />';

      // get modal body
      const medimodal = this.$refs['info-modal'+this.uid].$el.querySelector('.modal-body');
      if (medimodal == null) { return; }

      html = this.addMedimoTableCss(html);

      // roep mixin print method aan
      this.printHtml(html + medimodal.innerHTML);
    }
  },

  watch: {
    //
  }
};
</script>

<style>

</style>
