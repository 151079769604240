<template>
  <button
      @click="$emit('click')"
      :class="{
                uneven: isInUnevenRow,
                'left-column': isInLeftColumn,
                'first-row': isInFirstRow,
                selected: isSelected(day) && !isDisabled(day),
                disabled: (isDisabled(day) || isWeekEndDay(day)),
                enable: !(isDisabled(day) || isWeekEndDay(day)),
                between: isBetween(day) && range,
                first: firstInRange(day) && range,
                last: lastInRange(day) && !!modelValue.end && range,
                'other-month': !current_month,
                'current-month': current_month
            }"
      :disabled="isDisabled(day) || isWeekEndDay(day)"
      type="button"
      tabindex="-1"
      class="datepicker-day flex align-center justify-content-center"
  >
        <span v-if="isToday(day)"
              class="datepicker-today"
        ></span>
    <span v-show="!isDisabled(day) || isSelected(day)"
          :style="bgStyle"
          class="datepicker-day-effect"
    ></span>
    <span v-if="isKeyboardSelected(day)"
          class="datepicker-day-keyboard-selected"
    ></span>
    <span :class="'datepicker-day-text flex-1 ' + (isToday(day) ? 'today' : '')">
            {{ day.format('D') }}
        </span>
  </button>
</template>

<script>
import moment from 'moment-mini';

export default {
  components: {},
  emits: ['click'],
  props: {
    'day': {},
    'color': {},

    // Used to "know" on what row we are. There are always 7 items per row.
    'index': {},

    // If the button is from the next or previous month, we style it differently
    current_month: {type: Boolean, default: false},
    modelValue: {},
    minDate: {type: String, default: null},
    maxDate: {type: String, default: null},
    locale: {type: String, default: null},
    noWeekendsDays: {type: Boolean, default: null},
    disabledWeekly: {type: Array, default: () => ([])},
    disabledDates: {type: Array, default: () => ([])},
    enabledDates: {type: Array, default: () => ([])},
  },

  mixins: [],

  data: function () {
    return {
      range: false, // disabled for now
    };
  },

  computed: {
    bgStyle() {
      return {
        backgroundColor: this.color
      };
    },
    isInUnevenRow() {
      // There's probably a very fancy mathematical way of doing this, but my coffee hasn't started to work yet.

      // When the index is between 0 and 6, it's uneven
      // When it's between 7 and 13, it's even
      // When the index is between 14 and 20, it's uneven
      // When it's between 21 and 27, it's even
      // When the index is between 28 and 34, it's uneven
      // When it's between 35 and 41, it's even
      const index = this.index;
      if (index >= 0 && index <= 6) {
        return true;
      }
      if (index >= 14 && index <= 20) {
        return true;
      }
      if (index >= 28 && index <= 34) {
        return true;
      }
      return false;
    },
    isInLeftColumn() {
      return this.index % 7 === 0;
    },
    isInFirstRow() {
      return this.index < 7;
    }
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {

    isToday(day) {
      return moment(day.format('YYYY-MM-DD')).isSame(moment().format('YYYY-MM-DD'));
    },
    isSelected(day) {
      const date = [
        ...(this.modelValue && this.modelValue.start
          ? [moment(this.modelValue.start).format('YYYY-MM-DD')]
          : this.range ? [] : [moment(this.modelValue).format('YYYY-MM-DD')]),
        ...(this.modelValue && this.modelValue.end
          ? [moment(this.modelValue.end).format('YYYY-MM-DD')]
          : this.range ? [] : [moment(this.modelValue).format('YYYY-MM-DD')])
      ];
      return date.indexOf(day.format('YYYY-MM-DD')) > -1;
    },
    isBetween(day) {
      const range = this.modelValue && this.modelValue.end
        ? moment.range(moment(this.modelValue.start), moment(this.modelValue.end)).contains(day)
        : false;
      return range;
    },
    firstInRange(day) {
      return this.modelValue && this.modelValue.start ? moment(moment(this.modelValue.start).format('YYYY-MM-DD')).isSame(day.format('YYYY-MM-DD')) : false;
    },
    lastInRange(day) {
      return this.modelValue && this.modelValue.end ? moment(moment(this.modelValue.end).format('YYYY-MM-DD')).isSame(day.format('YYYY-MM-DD')) : false;
    },
    isWeekEndDay(day) {
      const dayConst = moment(day).day();
      const weekendsDaysNumbers = [6, 0];
      return this.noWeekendsDays ? weekendsDaysNumbers.indexOf(dayConst) > -1 : false;
    },
    isDisabled(day) {
      return (
        this.isDateDisabled(day) ||
          !this.isDateEnabled(day) ||
          this.isBeforeMinDate(day) ||
          this.isAfterEndDate(day) ||
          this.isDayDisabledWeekly(day) ||
          (this.isWeekEndDay(day) && this.noWeekendsDays)
      );
    },

    isKeyboardSelected(day) {
      return false; // Keynav ondersteunen we hier nog niet
      // return day && this.modelValue ? day.format('YYYY-MM-DD') === this.modelValue : null;
    },
    isDateDisabled(day) {
      return this.disabledDates.indexOf(day.format('YYYY-MM-DD')) > -1;
    },
    isDateEnabled(day) {
      return this.enabledDates.length === 0 || this.enabledDates.indexOf(day.format('YYYY-MM-DD')) > -1;
    },
    isBeforeMinDate(day) {
      return day.isBefore(moment(this.minDate, 'YYYY-MM-DD'));
    },
    isAfterEndDate(day) {
      return moment(day).isAfter(this.maxDate);
    },

    isDayDisabledWeekly(day) {
      const dayConst = moment(day).day();
      return this.disabledWeekly.indexOf(dayConst) > -1;
    },
  },

  watch: {
    //
  }
};
</script>
