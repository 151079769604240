<template>
       <medimo-form>

            <medimo-form-row>
                <medimo-form-column column_classes="col-12 col-sm-6">
                    <medimo-labeled-vue-select2
                        label_column_classes="col-12 col-sm-4"
                        select2_column_classes="col-12 col-sm-8"
                        label="Toestemming"
                        v-model="direction"
                        :options="[
                            {value: '', text: ''},
                            {value: 'ALLOW', text: 'Toestaan'},
                            {value: 'DENY', text: 'Weigeren'},
                    ]"></medimo-labeled-vue-select2>
                </medimo-form-column>
            </medimo-form-row>

            <medimo-form-row>
                <medimo-form-column column_classes="col-12">
                    <medimo-labeled-textarea
                            label_column_classes="col-12 col-sm-2"
                            label="Info"
                            name="info"
                            v-model="info"
                    ></medimo-labeled-textarea>
                </medimo-form-column>
            </medimo-form-row>

            <medimo-form-row>
                <medimo-form-column column_classes="col-12 col-sm-6">
                    <medimo-labeled-date-time-picker
                            label_column_classes="col-12 col-sm-4"
                            label="Startdatum"
                            name="startDate"
                            datetime_format="DD-MM-YYYY HH:mm"
                            v-model="start_date"
                    ></medimo-labeled-date-time-picker>
                </medimo-form-column>
            </medimo-form-row>

            <medimo-form-row>
                <medimo-form-column column_classes="col-12 col-sm-6">
                    <medimo-labeled-date-time-picker
                            label_column_classes="col-12 col-sm-4"
                            label="Einddatum"
                            name="endDate"
                            datetime_format="DD-MM-YYYY HH:mm"
                            v-model="end_date"
                    ></medimo-labeled-date-time-picker>
                </medimo-form-column>
            </medimo-form-row>

        </medimo-form>
</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import MedimoLabeledSelect2 from '@/vue/components/general/form/base/MedimoLabeledSelect2';
import MedimoLabeledTextarea from '@/vue/components/general/form/base/MedimoLabeledTextarea';
import MedimoLabeledDateTimePicker from '@/vue/components/general/form/base/MedimoLabeledDateTimePicker';
import moment from 'moment-mini';
import MedimoFormColumn from '@/vue/components/general/form/base/MedimoFormColumn';
import MedimoLabeledVueSelect2 from '@/vue/components/general/form/base/vue-select2/MedimoLabeledVueSelect2';

export default {

  components: {
    MedimoLabeledVueSelect2,
    MedimoFormColumn,
    MedimoLabeledDateTimePicker,
    MedimoLabeledTextarea,
    MedimoLabeledSelect2
  },

  props: [
    'trigger',
    'patient',
    'title',
    'type'
  ],

  mixins: [
    BaseComponentsMixin
  ],

  data: function () {
    return {
      token_modal_trigger: false,
      loading: false,
      direction: '',
      info: '',
      start_date: '',
      end_date: '',
      string17_1: ''
    };
  },

  computed: {
    optin() {
      const optins = this.$store.state.optin.optins[this.patient.id];

      if (typeof optins === 'undefined') {
        return {};
      }

      const optin = optins[this.type];

      if (typeof optin === 'undefined') {
        return {};
      }

      return optin;
    },
  },

  created() {

  },

  mounted() {

  },

  unmounted() {
    //
  },

  methods: {

    fetchOptin() {
      // only fetch when not already available
      if (this.optin.id) {
        return;
      }

      this.loading = true;

      this.$store.dispatch('optin/fetch', {
        patient_id: this.patient.id
      }).then(() => {
        this.loading = false;
        this.updateFormValues();
      }).catch(() => {
        this.loading = false;
        this.updateFormValues();
      });
    },

    updateOptin() {
      this.loading = true;

      this.$store.dispatch('optin/update', {
        patient_id: this.patient.id,
        data: {
          type: this.type,
          direction: this.direction,
          info: this.info,
          start_date: this.start_date,
          end_date: this.end_date
        }
      }).then(() => {
        this.loading = false;
        this.close();
      }).catch(() => {
        this.loading = false;
      });
    },

    updateFormValues() {
      this.direction = this.optin.direction;
      this.info = this.optin.info;

      if (this.optin.start_date) {
        this.start_date = this.optin.start_date;
      }
      if (this.optin.end_date) {
        this.end_date = this.optin.end_date;
      }
    },

    checkFormValuesChanged() {

      const start_date = moment(this.start_date, 'DD-MM-YYYY HH:mm');
      const start_date_optin = moment(this.optin.start_date, 'DD-MM-YYYY HH:mm');

      const end_date = moment(this.end_date, 'DD-MM-YYYY HH:mm');
      const end_date_optin = moment(this.optin.end_date, 'DD-MM-YYYY HH:mm');

      this.save_button_disable = (
        this.direction === this.optin.direction
          && this.info === this.optin.info
          && start_date.toISOString() === start_date_optin.toISOString()
          && end_date.toISOString() === end_date_optin.toISOString()
      );
    },

    triggerToken() {
      this.token_modal_trigger = !this.token_modal_trigger;
    }
  },

  watch: {
    trigger(value) {
      if (value) {
        this.fetchOptin();
      }
    },
    direction(value) {
      this.checkFormValuesChanged();
    },
    info(value) {
      this.checkFormValuesChanged();
    },
    start_date(value) {
      this.checkFormValuesChanged();
    },
    end_date(value) {
      this.checkFormValuesChanged();
    },
  }
};
</script>
