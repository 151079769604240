<template>
    <component is="style">
      <slot></slot>
    </component>
</template>

<script>
export default {
  mounted() {
    const styleTag = document.createElement("style");
    styleTag.textContent = this.$slots.default()[0].text;
    const head = document.getElementsByTagName("head")[0];
    head.appendChild(styleTag);
  },
};
</script>
