import {CodeObject} from '@/domain/models/CodeObject';
import {CodeSystem} from '@/domain/models/CodeSystem';

export class GeneratedCodeObject { 
  public system: CodeSystem | null;
  public code: string;
  public display: string | null;

  constructor(system: CodeSystem | null = null, code: string, display: string | null = null) {
    this.system = system;
    this.code = code;
    this.display = display;
  }

  public static fromJson(json: unknown): CodeObject {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new CodeObject(
      parsedJson['system'] ? CodeSystem.fromJson(parsedJson['system']) : null,
      parsedJson['code'] as string,
      parsedJson['display'] as string,
    );
  }

  public static multipleFromJson(json: unknown): CodeObject[] {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    const items: CodeObject[] = [];
    for (const item of parsedJson) {
      items.push(this.fromJson(item));
    }
    return items;
  }
}
