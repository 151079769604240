import {DispenseRequestError} from '@/domain/models/DispenseRequestError';

export class GeneratedDispenseRequestError { 
  public text: string;

  constructor(text: string) {
    this.text = text;
  }

  public static fromJson(json: unknown): DispenseRequestError {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new DispenseRequestError(
      parsedJson['text'] as string,
    );
  }

  public static multipleFromJson(json: unknown): DispenseRequestError[] {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    const items: DispenseRequestError[] = [];
    for (const item of parsedJson) {
      items.push(this.fromJson(item));
    }
    return items;
  }
}
