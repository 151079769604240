import Utility from '@/vue/utility/utility';
import BaseModule from '@/vue/store/modules/base_module';

// initial state
// shape: [{ id, quantity }]
const state = {
  data: [],

  query: '',
  filter_columns: [
    'title',
    'description',
    'created_at'
  ],

  meta: {
    current_page: 1,
    from: 1,
    to: 50,
    last_page: 1,
    per_page: 50,
    total: 0
  }
};

// getters
const getters = {
  filtered_requests_for_change(state, getters, rootState) {

    return (query) => state.data.filter((request_for_change) => {

      return Utility.matches_filter_query(request_for_change, query, state.filter_columns);

    });

  },
  by_id(state, getters, rootState) {
    return (id) => Utility.find_model_by_id(state.data, id);
  },
  isClosed(state, getters, rootState) {
    return (id) => {
      return getters.by_id(id).status === 2;
    };
  },
  isInProgress(state, getters, rootState) {

    // New
    // Open
    // Pending
    const validStateIds = [4, 6];

    return (id) => {
      return validStateIds.includes(getters.by_id(id).status);
    };
  },
  isUnprocessed(state, getters, rootState) {
    return (id) => {
      return getters.by_id(id).status === 0;
    };
  },
  otrsLink(state, getters, rootState) {
    return (id) => {
      return 'https://otrs.medimo.nl/otrs/index.pl?Action=AgentTicketZoom;TicketID=' + getters.by_id(id).otrs_ticket_id;
    };
  },
  jiraLink(state, getters, rootState) {
    return (id) => {
      if (getters.by_id(id).jira_issue_id == null) {
        return '';
      }
      const jira_issue_id = getters.by_id(id).jira_issue_id.toString();
      if (jira_issue_id.length > 0) {
        return 'https://medimo.atlassian.net/browse/' + jira_issue_id;
      }
      return '';
    };
  },
  statusText(state, getters, rootState) {
    return (id) => {
      if (getters.by_id(id).jira_issue_id !== null) {
        return 'Goedgekeurd';
      }
      if (getters.by_id(id).status === 1) {
        return 'Nieuw';
      }
      if (getters.isInProgress(id)) {
        return 'In Behandeling';
      }
      if (getters.isUnprocessed(id)) {
        return 'Contact Support';
      }
    };
  },
  typeText(state, getters, rootState) {
    return (id) => {
      return getters.by_id(id).type_string;
    };
  },
  statusType(state, getters, rootState) {
    return (id) => {
      if (getters.statusText(id) === 'Goedgekeurd') {
        return 'success';
      }
      if (getters.statusText(id) === 'Nieuw' || getters.statusText(id) === 'In Behandeling') {
        return 'primary';
      }
      if (getters.statusText(id) === 'Contact Support') {
        return 'danger';
      }
    };
  }
};

// actions
const actions = {

  go_to_page ({state, commit, dispatch, getters, rootState}, page) {
    commit('set_page', page);
    return dispatch('fetch');
  },


  fetch ({state, commit, dispatch, getters, rootState}, payload) {

    const endpoint = '/customer/requests-for-change/list?page=' + state.meta.current_page + '&per_page=' + state.meta.per_page;

    return dispatch('api/getEndpoint', {
      endpoint: endpoint,
      data: {} // Empty, since it's a GET
    }, {root: true}).then(response => {

      commit('set_data', response.data.data);
      commit('set_meta', response.data.meta);

    });
  },
  fetch_single({state, commit, dispatch, getters, rootState}, payload) {
    return dispatch('api/postEndpoint', {
      endpoint: '/customer/requests-for-change/' + payload.id
    }, {root: true}).then(response => {

      commit('add_to_data', response.data.data);

    });
  },
  create ({state, commit, dispatch, getters, rootState}, payload) {
    return dispatch('api/postEndpoint', {
      endpoint: '/customer/requests-for-change/',
      rejectErrors: true,
      data: payload,
    }, { root: true }).then(data => {
      commit('add_to_data', data.data.data);
    });
  },
  create_jira_issue ({state, commit, dispatch, getters, rootState}, payload) {

    return dispatch('api/postEndpoint', {
      endpoint: '/customer/requests-for-change/' + payload.id + '/jira-issue',
      data: payload,
    }, { root: true }).then(data => {
      commit('add_to_data', data.data.data);
    });
  },

  update({state, commit, dispatch, getters, rootState}, payload) {
    return dispatch('api/postEndpoint', {
      endpoint: '/customer/requests-for-change/' + payload.id,
      data: payload.data,
    }, {root: true}).then(data => {
      commit('add_to_data', data.data.data);
    });
  },

};

// mutations
const mutations = {
  ...BaseModule.mutations,

  set_data(state, value) {
    state.data = value;
  },
  set_meta(state, value) {
    state.meta = value;
  },
  set_page(state, value) {
    state.meta.current_page = value;
  },
};

export default {
  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
