<template>
    <medi-modal
        :loading="loading"
        :title="modalTitle"
        size="medium"
        @closed="onClosed()"
    >
        <medimo-form>

          <medimo-form-row class="mb-2">
            <medimo-form-column column_classes="col-12">
              <medimo-validated-select2
                  :disabled="confirm_delete"
                  label_column_classes="col-12 col-sm-3"
                  label="Allergie/intolerantie"
                  name="intolerance_type"
                  :options="intoleranceOptions"
                  v-model="intolerance_type"
              ></medimo-validated-select2>
            </medimo-form-column>
          </medimo-form-row>

          <medimo-form-row class="mb-2">
              <medimo-form-column column_classes="col-12">
                  <medimo-validated-input
                      :disabled="confirm_delete"
                      label_column_classes="col-12 col-sm-3"
                      label="Bijzonderheden"
                      name="info"
                      v-model="info"
                  ></medimo-validated-input>
              </medimo-form-column>
          </medimo-form-row>

          <medimo-form-row class="mb-2">
              <medimo-form-column column_classes="col-12">
                  <medimo-validated-date-picker
                      :disabled="confirm_delete"
                      label_column_classes="col-12 col-sm-3"
                      label="Vaststellingsmoment"
                      placeholder="(Optioneel)"
                      name="register_date"
                      v-model="register_date">
                  </medimo-validated-date-picker>
              </medimo-form-column>
          </medimo-form-row>

        </medimo-form>

        <template v-slot:action-buttons v-if="!confirm_delete">
            <medimo-button class="mr-auto" type="danger" @click.stop="confirm_delete = true">
                Verwijderen
            </medimo-button>
            <medimo-button class="mr-2" @click="update">
                Opslaan
            </medimo-button>
            <medimo-button class="mr-1" @click="close(false)" type="outline-primary">
                Annuleren
            </medimo-button>
        </template>

        <template v-slot:action-buttons v-else>
            <medimo-notification type="danger" class="mr-auto col-md-6">
              <fa-icon icon="fa-solid fa-bell" class="bell" size="lg" /> Weet je het zeker?
            </medimo-notification>
            <medimo-button @click="confirm_delete = false" type="outline-primary">
                Annuleren
            </medimo-button>
            <medimo-button @click.prevent="remove">
               Ja, ik weet het zeker
            </medimo-button>
        </template>

    </medi-modal>
</template>

<script>
import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import PatientInfoMediModal from '@/vue/components/modals/PatientInfoMediModal';

export default {
  emits: ['processed','closed'],
  components: {
    PatientInfoMediModal,
  },

  props: [
    'intolerance',
    'height',
    'patient_id',
  ],

  mixins: [
    BaseComponentsMixin
  ],

  data: function () {
    return {
      intolerance_type: '',
      title: '',
      info: '',
      register_date: '',
      loading: false,
      confirm_delete: false,
    };
  },

  computed: {
    intoleranceOptions() {
      return [
        { value: 'A', text: 'Allergie' },
        { value: 'I', text: 'Intolerantie' }
      ];
    },
    intoleranceType() {
      if (this.intolerance_type === 'A') {
        return 'allergie';
      }
      return 'intolerantie';
    },
    modalTitle() {
      if (this.confirm_delete === true) {
        return 'Verwijder ' + this.intoleranceType + ' ' + this.intolerance.title;
      } else {
        return 'Wijzig ' + this.intoleranceType + ' ' + this.intolerance.title;
      }
    },
  },

  created() {
  },

  mounted() {
    this.loadIntolerance();
  },

  methods: {
    updated() {
      this.$emit('processed');
    },
    close(refresh) {
      this.$emit('closed');
    },
    onClosed(event) {
      this.$emit('closed');
    },

    loadIntolerance() {
      this.title = this.intolerance.title;
      this.info = this.intolerance.info;
      this.register_date = this.intolerance.register_date;
      this.intolerance_type = this.intolerance.intolerance_type;
      if (this.register_date === null) {
        this.register_date = '';
      }
    },

    update() {
      this.loading = true;
      this.$store.dispatch('intolerances/update', {
        patient_id: this.intolerance.patient_id,
        intolerance_id: this.intolerance.id,
        data: {
          intolerance_type: this.intolerance_type,
          info: this.info,
          register_date: this.register_date,
        }
      }).then((data) => {
        this.loading = false;
        this.updated();
      }).catch(() => {
        this.loading = false;
      });
    },

    remove() {
      this.loading = true;
      this.$store.dispatch('intolerances/delete', {
        patient_id: this.intolerance.patient_id,
        intolerance_id: this.intolerance.id,
      }).then(() => {
        this.loading = false;
        this.updated();
      }).catch(() => {
        this.loading = false;
      });
    }
  },

  watch: {
    intolerance() {
      this.loadIntolerance();
    },
  }
};
</script>
