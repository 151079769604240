<template>
  <div
      class="year-month-selector flex flex-direction-column"
      :class="{'dark': dark}"
  >
    <div class="flex justify-content-right">
      <CustomButton
          :color="dark ? '#757575' : this.color"
          :dark="dark"
          with-border
          @click="$emit('back')"
      >
        <span class="fs-16">
          ✕
        </span>
      </CustomButton>
    </div>
    <div class="flex-1 flex flex-wrap justify-content-between align-center">
      <CustomButton
          v-for="(m, index) in months"
          :key="index"
          :color="color"
          :selected="currentMonth === index"
          :dark="dark"
          class="month-button"
          with-border
          @click="selectMonth(index)"
      >
        {{ m }}
      </CustomButton>
      <CustomButton
          v-for="year in years"
          :key="year"
          :color="color"
          :dark="dark"
          :selected="currentYear === year"
          with-border
          @click="selectYear(year)"
      >
        {{ year }}
      </CustomButton>
    </div>
  </div>
</template>

<script>
import {getMonthsShort} from '@/vue/components/general/form/date-time-picker/modules/month';
import CustomButton from '@/vue/components/general/form/date-time-picker/_subs/CustomButton';

const ArrayRange = (start, end) => {
  return Array(end - start + 1).fill().map((_, idx) => {
    const n = start + idx;
    return n;
  });
};

export default {
  name: 'YearMonthSelector',
  components: {
    CustomButton
  },
  emits: ['back','update:modelValue'],
  props: {
    locale: {type: String, default: null},
    dark: {type: Boolean, default: null},
    color: {type: String, default: null},
    mode: {type: String, default: null},
    month: {type: Object, default: null}
  },
  data() {
    return {
      months: null,
      years: null
    };
  },
  computed: {
    currentMonth() {
      return this.month.month;
    },
    currentYear() {
      return this.month.year;
    },
    isMonthMode() {
      return this.mode === 'month';
    }
  },
  mounted() {
    if (this.isMonthMode) {
      this.getMonths();
    } else {
      this.getYears();
    }
  },
  methods: {
    getMonths() {
      this.years = null;
      this.months = getMonthsShort(this.locale);
    },
    getYears() {
      this.months = null;
      this.years = ArrayRange(this.month.year - 7, this.month.year + 7);
    },
    selectMonth(monthNumber) {
      this.$emit('update:modelValue', {month: monthNumber, year: this.currentYear});
    },
    selectYear(year) {
      this.$emit('update:modelValue', {month: this.currentMonth, year: year});
    }
  }
};
</script>
