import BaseModule from '@/vue/store/modules/base_module';

const state = {
  ...BaseModule.state,

  data: [],

  patient_accessed_top10: [],
  user_patients_accessed_top10: [],
  patient_accessed_scatter_chart: [{'': {}}],
  patient_accessed_tree_map: [{}],

  statistics: {
    anomaly_count: 0,
    max_anomaly_count: 0,
    total_anomaly_count: 0,
    avg_anomaly_count: 0,
    anomalies_seen: 0,
  },

  quick_filters: {
    'status': {
      'group': {
        'Ongezien' : false,
        'Opgeslagen' : false,
      }
    },
    'type': {
      'group': {
        'Dossier inzages' : false,
        'Patienten bekeken' : false,
      }
    },
    'Afgelopen week' : false,
  },
};

// getters
const getters = {
  ...BaseModule.getters,
  patient_accessed_top10(state, getters, rootState) {
    return state.patient_accessed_top10;
  },
  user_patients_accessed_top10(state, getters, rootState) {
    return state.user_patients_accessed_top10;
  },
  patient_accessed_scatter_chart(state, getters, rootState) {
    return state.patient_accessed_scatter_chart;
  },
  patient_accessed_tree_map(state, getters, rootState) {
    return state.patient_accessed_tree_map;
  },
  statistics_anomaly_count(state, getters, rootState) {
    return state.statistics.anomaly_count;
  },
  statistics_max_anomaly_count(state, getters, rootState) {
    return state.statistics.max_anomaly_count;
  },
  statistics_total_anomaly_count(state, getters, rootState) {
    return state.statistics.total_anomaly_count;
  },
  statistics_avg_anomaly_count(state, getters, rootState) {
    return state.statistics.avg_anomaly_count;
  },
  statistics_anomalies_seen(state, getters, rootState) {
    return state.statistics.anomalies_seen;
  },

};

// actions
const actions = {
  ...BaseModule.actions,
  update ({state, commit, dispatch, getters, rootState}, payload) {
    return dispatch('api/postEndpoint', {
      endpoint: '/api/v1/reports/nen7513-anomalies/' + payload.id,
      data: payload.data
    }, {root: true}).then(response => {
      commit('update_selected_search_result', response.data.data);
      // En ook in de subset results zodat die gelijk lopen.
      commit('nen7513_anomalies/subset/update_selected_search_result', response.data.data, {root: true});
      // En dan even de stats updaten
      dispatch('fetch_statistics');
    });
  },
  fetch_patient_accessed_top10({state, commit, dispatch, getters, rootState}, payload) {
    if (typeof payload === 'undefined') {
      payload = {data: {}};
    }
    return dispatch('api/getEndpoint', {
      endpoint: '/api/v1/reports/nen7513-anomalies/patient-accessed/top-10',
      data: payload.data
    }, {root: true}).then(response => {
      commit('set_state', {property: 'patient_accessed_top10', value: response.data.data});
    });
  },
  fetch_user_patients_accessed_top10({state, commit, dispatch, getters, rootState}, payload) {
    if (typeof payload === 'undefined') {
      payload = {data: {}};
    }
    return dispatch('api/getEndpoint', {
      endpoint: '/api/v1/reports/nen7513-anomalies/user-patients-accessed/top-10',
      data: payload.data
    }, {root: true}).then(response => {
      commit('set_state', {property: 'user_patients_accessed_top10', value: response.data.data});
    });
  },
  fetch_patient_accessed_scatter_chart({state, commit, dispatch, getters, rootState}, payload) {
    if (typeof payload === 'undefined') {
      payload = {data: {}};
    }
    return dispatch('api/postEndpoint', {
      endpoint: '/api/v1/reports/nen7513-anomalies/patient-accessed/scatter-chart',
      data: payload.data
    }, {root: true}).then(response => {
      commit('set_state', {property: 'patient_accessed_scatter_chart', value: response.data.data});
    });
  },
  fetch_patient_accessed_tree_map({state, commit, dispatch, getters, rootState}, payload) {
    if (typeof payload === 'undefined') {
      payload = {data: {}};
    }
    return dispatch('api/postEndpoint', {
      endpoint: '/api/v1/reports/nen7513-anomalies/patient-accessed/tree-map',
      data: payload.data
    }, {root: true}).then(response => {
      commit('set_state', {property: 'patient_accessed_tree_map', value: response.data.data});
    });
  },
  fetch_statistics({state, commit, dispatch, getters, rootState}, payload) {
    if (typeof payload === 'undefined') {
      payload = {data: {}};
    }
    return dispatch('api/getEndpoint', {
      endpoint: '/api/v1/reports/nen7513-anomalies/statistics',
      data: payload.data
    }, {root: true}).then(response => {
      commit('set_state', {property: 'statistics', value: response.data.data});
    });
  },
  fetch_single({state, commit, dispatch, getters, rootState}, payload) {
    return this.$store.dispatch('api/getEndpoint', {
      endpoint: '/api/v1/reports/nen7513-anomalies/' + payload.id,
      data: {}
    }).then(response => {
      //
    });
  },
  alert ({state, commit, dispatch, getters, rootState}, payload) {
    return dispatch('api/postEndpoint', {
      endpoint: '/api/v1/reports/nen7513-anomalies/' + payload.id + '/alert',
    }, {root: true}).then(response => {
      dispatch('notifications/addSuccessAlert', {
        title: 'Een alert email is succesvol verzonden naar de volgende email adressen: ' + response.data.email_addresses.join(', '),
      }, {root: true});
    });
  }
};

// mutations
const mutations = {
  ...BaseModule.mutations,
  // dummy_mutation (state, id) {
  //     state.id = id;
  // },
};

import subset_search from './subset';

export default {

  namespaced: true,

  modules: {
    subset: subset_search,
  },

  state,
  getters,
  actions,
  mutations
};
