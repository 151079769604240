<template>
  <div v-if="Object.keys(report.filters).length" class="ml-auto">

    <medimo-tooltip content="Klik hier om filters toe te passen voor dit overzicht" :force-visible="showTooltip">
      <medimo-button-link :outline="true" icon="fa-solid fa-gear" @click="showModal = true"
                          :padding_class="'pr-2 pl-2 text-small'" class="advanced-filter-button"
      >
        Geavanceerd
      </medimo-button-link>
    </medimo-tooltip>

    <report-advanced-filters-modal v-if="showModal"
                                   @closed="showModal = false"
                                   :report="report">
    </report-advanced-filters-modal>
  </div>
</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import WardSelect2 from '@/vue/components/selectors/WardSelect2';
import LocationSelect2 from '@/vue/components/selectors/LocationSelect2';
import ReportAdvancedFiltersModal from './ReportAdvancedFiltersModal';

export default {
  components: {ReportAdvancedFiltersModal, LocationSelect2, WardSelect2},

  props: ['report'],

  mixins: [BaseComponentsMixin],

  data: function () {
    return {
      showModal: false,
      showTooltip: false,
    };
  },

  computed: {
    show_advanced_filters_tooltip: {
      get() {
        return this.$store.state.current_user.show_advanced_filters_tooltip;
      },
      set(value) {
        this.$store.commit('current_user/set_show_advanced_filters_tooltip', value);
      }
    },
  },

  created() {
    //
  },

  mounted() {
    this.triggerTooltip();
  },

  methods: {
    triggerTooltip() {
      if (this.show_advanced_filters_tooltip) {
        this.showTooltip = true;
        this.show_advanced_filters_tooltip = false;
      }
    }
  },

  watch: {
    //
  }
};
</script>
