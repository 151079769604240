import {MedicationGuardSignal} from "@/domain/models/MedicationGuardSignal";

export class MedicationGuard {
  public signals: MedicationGuardSignal[];

  constructor(signals: MedicationGuardSignal[]) {
    this.signals = signals;
  }

  public static fromJson(json: unknown): MedicationGuard {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new MedicationGuard(
      parsedJson['signals'] ? parsedJson['signals'].map((e: unknown) => MedicationGuardSignal.fromJson(e)) : null,
    );
  }
}
