<template>
  <medimo-table
      class="medimo-data-table"
      :loading="loading || show_loader"
      :minimum_height="minimum_height"
      :store_name="store_name"
      :autofocus="autofocus"
      :name="name"
      :copyable="copyable"
      :enable_filter="enable_filter"
      :filter_name="filter_name"
      :filter_help_text="filter_help_text"
      :show_help_text="show_help_text"

      v-bind="$attrs"

      :number_of_rows="number_of_rows"
      :no_search_message="no_search_message"
      :no_results_message="no_results_message + ' ' + extra_results_message"

      :extra_buttons_class="extra_buttons_class"
      :quick_filter_buttons_class="quick_filter_buttons_class"
      :table_links_class="table_links_class"

      :overflow="overflow"
      :doneLoading="doneLoading"
      :auto_trigger_id="auto_trigger_id"
      :isExternalDataTable="isExternalDataTable"
      :isLocalDataTable="isLocalDataTable"
  >
    <template v-slot:store_settings>
      <medimo-tooltip class="favorite-setting" content="Bewaar filter instellingen"
                      @click="toggle_store_settings">
        <fa-icon :icon="(isStored ? 'fa-solid' : 'fa-regular') + ' fa-star'"/>
      </medimo-tooltip>
    </template>
    <template v-slot:quick_filter_buttons v-if="Object.keys(quick_filters).length">
      <medimo-quick-filters :store_name="store_name" :quick_filters="quick_filters"></medimo-quick-filters>
    </template>
    <template v-slot:extra_buttons v-if="$slots.extra_buttons || exportable">
      <slot name="extra_buttons" v-if="$slots.extra_buttons"></slot>
      <medimo-data-table-export-button
          v-bind:class="{'ml-auto': !!$slots.extra_buttons && alignExportButton}"
          v-if="exportable"
          :store_name="store_name"
      >
      </medimo-data-table-export-button>
    </template>

    <slot></slot>

    <template v-slot:pagination v-if="pagination_enabled">
      <div class="row pagination-container" v-if="typeof meta_data.total !== 'undefined'">
        <div class="col-12 col-sm-3">
          <ul class="pagination">
            <li class="page-item info">
                            <span class="page-link text-small">
                                {{ meta_data.from === null ? 0 : meta_data.from }} tot {{ meta_data.to === null ? 0 : meta_data.to }} van {{ meta_data.total }} resultaten
                            </span>
            </li>
          </ul>
        </div>
        <div class="col-12 col-sm align-self-center">
          <ul class="pagination  justify-content-end">
            <li class="page-item previous" v-bind:class="{'disabled': page === 1}">
              <medimo-link to="" class="page-link unselectable" @click="switch_to_page(page - 1, 'template1')">
                <fa-icon icon="fa-solid fa-caret-left" size="lg"/>
                <span class="d-none d-sm-inline">Vorige</span>
              </medimo-link>
            </li>

            <template v-if="last_page > 10 && page >= 5">
              <li class="page-item">
                <medimo-link to="" class="page-link" @click="switch_to_page(1, 'template2')">
                  1
                </medimo-link>
              </li>
              <li class="page-item disabled">
                <medimo-link to="" class="page-link">
                  ...
                </medimo-link>
              </li>
            </template>

            <template v-for="(page_number, index) in page_numbers">
              <li :class="'page-item ' +  is_active_page(page_number)">
                <medimo-link class="page-link" to="" @click="switch_to_page(page_number, 'template3')">
                  {{ page_number }}
                </medimo-link>
              </li>
            </template>

            <!-- Show a disabled [1] if there are 0 pages -->
            <li :class="'page-item disabled'" v-if="page_numbers.length === 0">
              <medimo-link class="page-link" to="">
                1
              </medimo-link>
            </li>

            <template v-if="last_page > 10 && page <= (last_page - 3)">
              <li class="page-item disabled">
                <medimo-link to="" class="page-link">
                  ...
                </medimo-link>
              </li>
              <li class="page-item">
                <medimo-link to="" class="page-link" @click="switch_to_page(last_page, 'template4')">
                  {{ last_page }}
                </medimo-link>
              </li>
            </template>

            <li class="page-item next" v-bind:class="{'disabled': page === last_page || page_numbers.length === 0}">
              <medimo-link to="" class="page-link unselectable" @click="switch_to_page(page + 1, 'template5')">
                <span class="d-none d-sm-inline">Volgende</span>
                <fa-icon icon="fa-solid fa-caret-right" size="lg"/>
              </medimo-link>
            </li>
          </ul>
        </div>
      </div>
    </template>

    <template v-slot:select v-if="selection_enabled">
      <div class="row">
        <div class="col-12 col-sm">
          <medimo-link to="" @click="select_all()">
            Selecteren
          </medimo-link>
          /
          <medimo-link to="" @click="deselect_all()">
            Deselecteren
          </medimo-link>
        </div>
      </div>
    </template>
  </medimo-table>
</template>

<script>
import MedimoTable from './MedimoTable';
import MedimoLink from '../MedimoLink';
import MedimoAlert from '../MedimoAlert';
import MedimoDataTableExportButton from './MedimoDataTableExportButton';
import MedimoTooltip from '../MedimoTooltip';
import MedimoFormColumn from '@/vue/components/general/form/base/MedimoFormColumn';
import MedimoQuickFilters from './MedimoQuickFilters';
import {nextTick} from 'vue';

export default {
  emits: ['switch_to_page','select_all','deselect_all'],
  components: {
    MedimoQuickFilters,
    MedimoFormColumn,
    MedimoTooltip,
    MedimoTable,
    MedimoLink,
    MedimoAlert,
    MedimoDataTableExportButton,
  },

  props: {

    'name': {default: ''},

    'store_name': {required: true},
    'minimum_height': {default: false},
    'enable_filter': {default: true},
    'filter_name': {default: 'filtertext'},
    'filter_help_text': {default: ''},
    'show_help_text': {default: true},

    // An integer that lets the table know how many rows are rendered in it's slot
    'number_of_rows': {default: 0, required: true},
    // If this message isset and there is no search available, it will show instead of no_results_message when there are 0 <medimo-tr> rows.
    'no_search_message': {default: false},
    // The standard message to show when no table rows are present
    'no_results_message': {default: 'Geen resultaten gevonden.'},

    // Door deze property op true te zetten activeer je de mogelijkheid tot exporteren van _alle_ resultaten
    // niet alleen de zichtbare resultaten. Zie medimo-external-data-table.vue voor een implementatie voorbeeld
    'exportable': {default: false},
    'copyable': {default: false},

    // Contains an object with meta data from the current data set.
    // Total, current page, etc. etc. This meta_data follows the Laravel pagination standard:
    //
    // current_page: 1
    // from: 1
    // last_page: 9535
    // path: "https://medimo.test/api/v1/example-data"
    // per_page: 10
    // to: 10
    // total: 95344
    'meta_data': {required: true},

    'autofocus': {default: true},

    // Show / hide the table loader with this boolean
    'loading': {required: true},
    // Met deze kunnen we de loader ook handmatig triggeren, na bijvoorbeeld een @event
    'show_loader': {required: false, default: false},

    // Turn on/off pagination
    'pagination_enabled': {default: true},

    // Will add an select all and deselect all links to the table.
    // Parent widget needs to implement the select_all and deselect_all method
    'selection_enabled': {default: false},

    // Privacy by design features
    // Als je moet weten wat dit is zit je verkeerd. Zie MedimoLocalDatatable en External
    'privacy_filter': {default: true},
    'privacy_filter_property': {},
    'privacy_filter_value': {},

    // Controleer de breedte van de extra_buttons slot
    'quick_filter_buttons_class': {default: 'col-md-6'},
    'table_links_class': {default: 'col-md'},
    'extra_buttons_class': {default: 'col-md'},

    'overflow': {default: 'inherit'},

    // Zet deze op true om automatisch input van een gebruiker op te slaan
    // zodat deze restored wordt als ze terug navigeren naar deze pagina
    'auto_store_settings': {default: false},
    'auto_trigger_id': {default: null},

    // ++ this value to reset the default table settings
    'reset_stored_settings': {default: 0},

    doneLoading: {default: 0},
    isExternalDataTable: {default: false},
    isLocalDataTable: {default: false},
    alignExportButton: {default: true}
  },

  mixins: [
    //
  ],

  data: function () {
    return {
      // Gebruikt voor additionele boodschap in geval van geen resultaten
      'extra_results_message': '',
      'stored_data_table_identifier': '', // Wordt automatisch ge-set, zonder deze is de route string verkeerd als je weg navigeert
    };
  },

  computed: {
    page() {
      return this.meta_data.current_page;
    },
    last_page() {
      return Math.ceil(this.meta_data.total / this.meta_data.per_page);
    },
    page_numbers() {
      const page_numbers = [];

      const last_page = this.last_page;
      if (last_page < 10) {
        let page = 1;
        while (page <= last_page) {
          page_numbers.push(page);
          page++;
        }
      }
      // Generate the moving window when beyond page 7
      else if (this.page >= 5 && this.page <= (this.last_page - 3)) {

        page_numbers.push(this.page - 1);
        page_numbers.push(this.page);
        page_numbers.push(this.page + 1);

      } else if (this.page > (this.last_page - 3)) {

        page_numbers.push(this.last_page - 4);
        page_numbers.push(this.last_page - 3);
        page_numbers.push(this.last_page - 2);
        page_numbers.push(this.last_page - 1);
        page_numbers.push(this.last_page);

      } else {
        // The first 10 if not.
        // Someone could probably code this a lot cleaner but hey, it gets the job done atm!
        page_numbers.push(1);
        page_numbers.push(2);
        page_numbers.push(3);
        page_numbers.push(4);
        page_numbers.push(5);
      }

      return page_numbers;
    },
    data_table_identifier() {
      if (this.stored_data_table_identifier.length === 0) {
        // Same url, but different query would result in same table identifier if we did not include the query.
        // For the patient selector we need different table identifiers when the query differs.
        // Could screw us up later, as the query does not necessarily mean the table data is different..
        return this.$store.getters['settings/data_tables/data_table_identifier'](this.name, this.$route.name, '_QUERY_' + JSON.stringify(this.$route.query));
      }
      return this.stored_data_table_identifier;
    },
    isStored() {
      return this.storedSettings !== undefined;
    },
    storedSettings() {
      return this.$store.getters['settings/data_tables/is_stored'](this.data_table_identifier);
    },
    isStoredDefault() {
      return this.defaultSettings !== undefined;
    },
    defaultSettings() {
      const default_identifier = this.data_table_identifier.split('_QUERY_')[0]; // Get rid of query from identifier.
      return this.$store.getters['settings/data_tables/is_stored_default'](default_identifier);
    },
    storedPage() {
      return this.$store.getters['settings/data_tables/stored_page'](this.data_table_identifier);
    },
    query() {
      return this.$store.getters[this.store_name + '/query'];
    },
    quick_filters() {
      return this.$store.getters[this.store_name + '/quick_filters'];
    },
    sort_columns() {
      return this.$store.getters[this.store_name + '/sort_columns'];
    },
    navigated_deeper() {
      return this.$store.getters['settings/breadcrumbs/navigated_deeper'];
    },
    navigated_up() {
      return !this.navigated_deeper;
    },
    page_refreshed() {
      return this.$store.getters['settings/breadcrumbs/page_refreshed'];
    }
  },

  created() {
    this.initialize_privacy_filter();
  },

  mounted() {
    // When not set, store default settings:
    this.store_default_settings();

    // Als hij opgeslagen is, en de vorige pagina was _dieper_, dan restoren we
    // i.e. van /status/123/details naar /status/123
    if (this.isStored) {
      // Als de gebruiker zojuist omhoog heeft genavigeerd (i.e. van /status/123 naar /status
      // dan restoren we de settings
      // maar ook als de pagina gerefreshed is
      if (this.navigated_up || this.page_refreshed) {
        this.$store.dispatch('settings/data_tables/restore_settings', {
          data_table_identifier: this.data_table_identifier,
        });
      } else {
        // Als ze zojuist dieper gaan, van /status naar /status/123 (waar op /status/123 de table staat)
        // dan clearen we alles
        this.clear_stored_page();
        this.clear_stored_settings();
      }
    } else if (this.auto_store_settings) {
      setTimeout(() => {
        // Wanneer nog niet stored, maar wel moet auto-storen, opslaan
        // Bewust met een delay van 500ms om geen errors te krijgen dat
        // we settings willen storen die nog niet van de backend terug zijn
        // Traag genoeg om nog geen user input te hebben, snel genoeg om
        // niet op te vallen.
        if (this.number_of_rows) {
          this.store_settings('mounted');
        }
      }, 1000);
    }

    if ((process.env.APP_ENV === 'development' || process.env.APP_ENV === 'testing') && this.name.length === 0) {
      this.$store.dispatch('notifications/addDangerAlert', {
        message: 'Voeg een table "name" toe. Zonder een unieke NAME property mee te geven aan de datatable kan het zijn dat de settings van meerdere datatables op 1 pagina (bij bijv. meerdere modals) elkaar overschrijven.'
      });
    }
  },

  beforeUnmount() {
    // We restoren _altijd_ naar de default
    // ook als hij is opgeslagen, dan restored die weer automatisch op de volgende load
    this.restore_default_settings();
  },

  methods: {
    switch_to_page(page_number, log = '') {
      this.$emit('switch_to_page', page_number);
    },
    is_active_page(page_number) {
      if (this.page === page_number) {
        return 'active';
      }
      return '';
    },
    toggle_store_settings() {
      // Als hij al opgeslagen is, verwijderen we de settings
      if (this.isStored) {
        this.clear_stored_settings();
        return;
      }
      this.store_settings('toggle_store_settings');
    },
    store_page() {
      this.$store.commit('settings/data_tables/set_stored_page', {
        data_table_identifier: this.data_table_identifier,
        page: this.page,
      });
    },
    clear_stored_page() {
      if (this.storedPage !== 1) {
        this.$store.commit('settings/data_tables/clear_stored_page', {
          data_table_identifier: this.data_table_identifier
        });
      }
    },
    store_settings(log) {
      this.$store.dispatch('settings/data_tables/store_settings', {
        data_table_identifier: this.data_table_identifier,
        store_name: this.store_name,
        meta_data: this.meta_data,
      });
    },
    store_default_settings() {
      this.stored_data_table_identifier = this.data_table_identifier;
      this.$store.dispatch('settings/data_tables/store_default_settings', {
        data_table_identifier: this.data_table_identifier,
        store_name: this.store_name,
        meta_data: this.meta_data,
      });
    },
    restore_default_settings() {
      // When not set, store default settings:
      this.$store.dispatch('settings/data_tables/restore_settings', {
        restore_default: true,
        data_table_identifier: this.data_table_identifier,
      });
    },
    update_stored_settings() {
      if (this.isStored || this.auto_store_settings) {
        this.store_settings('update_stored_settings');
      }
    },
    clear_stored_settings() {
      if (this.isStored) {
        this.$store.commit('settings/data_tables/clear_stored_settings', {
          data_table_identifier: this.data_table_identifier
        });
      }
    },
    // Deze functie verwerkt de privacy by design & default van een MedimoDataTable
    initialize_privacy_filter() {
      let privacy_filter = {
        property: this.privacy_filter_property,
        value: this.privacy_filter_value,
      };
      // Alleen als hij true is (default) doen we alle checks
      if (this.privacy_filter) {
        if (this.privacy_filter_property === null || this.privacy_filter_value === null) {
          const msg = 'Je moet een privacy_filter_property en privacy_filter_value meegeven aan een MedimoDataTable';
          if (process.env.APP_ENV === 'development' || process.env.APP_ENV === 'testing') {
            console.error(msg);
            this.$store.dispatch('notifications/addDangerAlert', {
              message: msg
            });
          }
          // Als beide null zijn willen we 100% zeker zijn dat er geen data getoont wordt, met
          // niet bestaande waardes forceren we een lege table
          privacy_filter = {property: 'non_existing_property', value: 'thatwillnevermatch'};
          this.extra_results_message = 'Reden: privacy filter';
          this.$store.commit(this.store_name + '/set_privacy_filter', privacy_filter);
          // En zorgen we dat er een Sentry wordt aangemaakt
          throw msg;
        }
        // Mogelijk dubbelop, maar na een exception stop hij met verwerken, en werd de table
        // in sommige gevallen alsnog gevuld
        this.$store.commit(this.store_name + '/set_privacy_filter', privacy_filter);
      }
    },
    select_all() {
      this.$emit('select_all');
    },
    deselect_all() {
      this.$emit('deselect_all');
    }
  },

  watch: {
    isStored(value) {
      if (value === false) {
        this.restore_default_settings();
      }
    },
    page(page) {
      if (page > 1) {
        this.store_settings();
      }

      // Bij een pagina update zorgen we dat de opgeslagen data weer klopt
      this.store_page();
      this.update_stored_settings();
    },
    meta_data: {
      deep: true,
      handler(meta_data) {
        // Alleen als de storedPage en current_page niet overeen komen (na een page-load bijv)
        // switchen we automatisch weer naar diezelfde pagina

        // TODO VUE3 Lijkt verwijderd te kunnen worden? Gaf infinite loop errors met back/forth tabel page
        // console.log('meta_data watcher', meta_data.current_page, this.storedPage);
        // if (meta_data.current_page !== this.storedPage) {
        //   this.switch_to_page(this.storedPage, 'MedimoDataTable meta_data watcher');
        // }
      }
    },
    reset_stored_settings() {
      this.restore_default_settings();
    },
    query() {
      // Bij een query update zorgen we dat de opgeslagen data weer klopt
      this.update_stored_settings();
    },
    quick_filters: {
      deep: true,
      handler() {
        // Bij een quick filters update zorgen we dat de opgeslagen data weer klopt
        this.update_stored_settings();
      }
    },
    sort_columns: {
      deep: true,
      handler(newValue) {
        // Als deze wijzigt van de default dan opslaan
        // Moet even om geen undefined kraks te krijgen
        if (this.defaultSettings !== undefined && this.defaultSettings.sort_columns !== undefined) {
          // Deze moet omdat het vergelijken van 2 inhoudelijk identieke observed objects alsnog false kan zijn
          const flatNewColumns = JSON.stringify(newValue);
          const flatDefaultColumns = JSON.stringify(this.defaultSettings.sort_columns);
          if (flatNewColumns !== flatDefaultColumns) {
            this.store_settings();
          }
        }
        // Bij een quick filters update zorgen we dat de opgeslagen data weer klopt
        this.update_stored_settings();
      }
    },
  }
};
</script>
