import ChangeConstants from '@/vue/constants/agreement_changes';
import BaseModule from '@/vue/store/modules/base_module';
import Utility from '@/vue/utility/utility';
import Changes from '@/vue/utility/dosingInstructionChanges';

// initial state
const state = {
  changes: {},
  last_mutation_id: 0,
};

// getters
const getters = {
  all_changes(state) {
    return state.changes;
  },
  changes(state, getters, rootState, rootGetters) {
    return (medicationAgreementId) => {
      return state.changes[medicationAgreementId];
    };
  },
  pretty_changes_for_instruction(state, getters, rootState, rootGetters) {
    return (dosingInstruction) => {

      if (dosingInstruction.restart) {
        return [];
      }

      const changes = getters['changes_for_instruction'](dosingInstruction);

      return changes.map((change) => {
        return Changes.prettifyChange(change);
      });
    };
  },
  changes_for_instruction(state, getters, rootState, rootGetters) {
    return (dosingInstruction) => {
      const changeItems = getters['change_items_for_instruction'](dosingInstruction);

      if (changeItems.length === 0) {
        return [];
      }
      // In current implementation, we only allow one change item (mutation) per instruction id:
      const changeItem = changeItems[0];

      if (!changeItem.original_instruction_ids) {
        return [];
      }

      let originalInstruction = null;
      if (changeItem.original_instruction_ids[0] === changeItem.new_instruction_ids[0]) {
        originalInstruction = rootGetters['dosage_instructions/find_db_model'](changeItem.original_instruction_ids[0]);
      }
      else {
        originalInstruction = rootGetters['dosage_instructions/find'](changeItem.original_instruction_ids[0]);
      }

      const newInstruction = rootGetters['dosage_instructions/find'](changeItem.new_instruction_ids[0]);

      return rootGetters['dosage_instructions/get_changes_between_instructions']({'old': originalInstruction, 'new': newInstruction});
    };
  },
  for_agreements(state, getters) {
    return (agreements) => {
      const agreementIds = agreements.map((agreement) => {
        return agreement.id;
      });
      const changes = [];
      agreementIds.forEach((agreementId) => {
        const items = state.changes[agreementId];
        if (items) {
          changes.push(items);
        }
      });

      // Quick hack to get IE11 working again
      const flatDeep = function(arr, d = 1) {
        return d > 0 ? arr.reduce((acc, val) => acc.concat(Array.isArray(val) ? flatDeep(val, d - 1) : val), [])
          : arr.slice();
      };

      return flatDeep(changes, 1);
    };
  },
  change_types_for_instruction(state, getters) {
    return (dosingInstruction) => {
      const changeItems = getters['change_items_for_instruction'](dosingInstruction);
      return changeItems.map( (changeItem) => { return changeItem.mutation_type; });
    };
  },
  change_items_for_instruction(state, getters) {
    return (dosingInstruction) => {
      const agreement_id = dosingInstruction.medication_agreement_id;
      const changeItems = state.changes[agreement_id];
      if (!changeItems) {
        return [];
      } else {
        return changeItems.filter((changeItem) => {
          return changeItem.dosing_instruction_id === dosingInstruction.id ||
              (changeItem.new_instruction_ids && changeItem.new_instruction_ids.includes(dosingInstruction.id));
        });
      }
    };
  },
  exist_for_instruction(state, getters) {
    return (dosingInstruction) => {
      const changeItem = getters['change_items_for_instruction'](dosingInstruction);
      return changeItem.length > 0;
    };
  },
  unsaved_stop_for_instruction(state, getters) {
    return (dosingInstruction) => {
      const changeItems = getters['change_items_for_instruction'](dosingInstruction);

      const stopChangeItems = changeItems.filter( (changeItem) => {
        return changeItem.mutation_type === ChangeConstants.change_events.STOP_INSTRUCTION;
      });

      if (stopChangeItems.length === 1) {
        return stopChangeItems[0];
      }
      return false;
    };
  },
  unsaved_correct_stop_for_instruction(state, getters) {
    return (dosingInstruction) => {
      const changeItems = getters['change_items_for_instruction'](dosingInstruction);

      const stopChangeItems = changeItems.filter( (changeItem) => {
        return changeItem.mutation_type === ChangeConstants.change_events.CORRECT_STOP;
      });

      if (stopChangeItems.length === 1) {
        return stopChangeItems[0];
      }
      return false;
    };
  },
  ...BaseModule.getters,
};

// actions
const actions = {
  remove_stop_item({state, dispatch, commit, getters}, instruction) {
    let agreementChanges = state.changes[instruction.medication_agreement_id];
    const stopTypes = [ChangeConstants.change_events.STOP_INSTRUCTION];
    if (agreementChanges) {
      agreementChanges = agreementChanges.filter( (change) => {
        return !(stopTypes.includes(change.mutation_type) && change.dosing_instruction_id === instruction.id);
      });
      state.changes[instruction.medication_agreement_id] = agreementChanges;
    }
    else {
      window.Sentry.captureException('removing stop item which does not exist');
    }
  },
  remove_correct_stop_item({state, dispatch, commit, getters}, instruction) {
    let agreementChanges = state.changes[instruction.medication_agreement_id];
    const stopTypes = [ChangeConstants.change_events.CORRECT_STOP];
    if (agreementChanges) {
      agreementChanges = agreementChanges.filter( (change) => {
        return !(stopTypes.includes(change.mutation_type) && change.dosing_instruction_id === instruction.id);
      });
      state.changes[instruction.medication_agreement_id] = agreementChanges;
    }
    else {
      window.Sentry.captureException('removing correct stop item which does not exist');
    }
  },
  remove_change_items_for_first_dosing_instruction_id({state, getters, dispatch, rootState, rootGetters}, firstDosingInstructionId) {
    const dosingInstructions = rootGetters['dosage_instructions/for_first_dosing_instruction_id'](firstDosingInstructionId);

    if (dosingInstructions.length === 0) {
      return;
    }

    const medicationAgreementId = dosingInstructions[0].medication_agreement_id;
    const agreementChangeItems = state.changes[medicationAgreementId];
    const dosingInstructionIds = dosingInstructions.map( (dosingInstruction) => { return dosingInstruction.id; });

    if (agreementChangeItems) {
      const filteredAgreementChangeItems = agreementChangeItems.filter((changeItem) => {
        return !(changeItem.new_instruction_ids && dosingInstructionIds.includes(changeItem.new_instruction_ids[0]))  //start, change and cancel mutattions
          && !dosingInstructionIds.includes(changeItem.dosing_instruction_id); //stop mutation
      });
      state.changes[medicationAgreementId] = filteredAgreementChangeItems;
    }
  },
  remove_change_items({state, getters, dispatch}, dosingInstruction) {
    const changeItems = getters['change_items_for_instruction'](dosingInstruction);

    changeItems.forEach( (changeItem) => {
      dispatch('remove_change_item', changeItem);
    });

  },
  remove_change_item({state, dispatch, commit, getters}, changeItem) {
    //Reset original instructions, if the original is changed:
    if (Utility.is_equal(changeItem.original_instruction_ids, changeItem.new_instruction_ids)) {
      if (changeItem.original_instruction_ids) {
        changeItem.original_instruction_ids.forEach((id) => {
          commit('dosage_instructions/clear_local_changes', id, {root: true});
        });
      }
    }

    //When clearing an addition, reset the mutation_datetime,
    //otherwise it is seen as a stopped instruction
    if (changeItem.mutation_type === ChangeConstants.change_events.ADDITION) {
      commit('dosage_instructions/update_prop', {
        id: changeItem.original_instruction_ids[0],
        prop: 'mutation_datetime',
        value: null,
      }, {root: true});
    }

    //Delete new instructions
    changeItem.new_instruction_ids.forEach((id) => {
      if (Utility.is_new_id(id)) {
        commit('dosage_instructions/delete_local_model', id, {root: true});
        commit('dosage_instructions/delete_shadow_model', id, {root: true});
      }
    });

    //Delete manual dosing schema
    if ([
      ChangeConstants.change_events.CHANGE_INSTRUCTION_TO_MANUALDOSING_SCHEMA,
      ChangeConstants.change_events.CORRECT_INSTRUCTION_TO_MANUAL_DOSING_SCHEMA,
      ChangeConstants.change_events.MANUALDOSING_SCHEMA_CHANGED,
      ChangeConstants.start_events.START_MANUAL_DOSING_SCHEMA].includes(changeItem.mutation_type)) {
      const firstNewInstructionId = changeItem.new_instruction_ids[0];
      commit('manual_dosing_schemas/clear_local_changes', firstNewInstructionId, {root: true});
    }

    //Delete change item
    const changeItems = state.changes[changeItem.medication_agreement_id];
    if (changeItems) {
      const newChangeItems = changeItems.filter((item) => {
        return item.mutation_id !== changeItem.mutation_id;
      });
      state.changes[changeItem.medication_agreement_id] = newChangeItems;
    }
  },
  add_start_manualdosing_schema({dispatch}, dosingInstruction) {
    const payload = {
      mutation_type: ChangeConstants.start_events.START_MANUAL_DOSING_SCHEMA,
      new_instruction_ids: [dosingInstruction.id],
      medication_agreement_id: dosingInstruction.medication_agreement_id,
    };
    dispatch('add_change_item', payload);
  },
  add_start_dosing_instruction({dispatch}, dosingInstruction) {
    const payload = {
      mutation_type: ChangeConstants.start_events.START_INSTRUCTION,
      new_instruction_ids: [dosingInstruction.id],
      medication_agreement_id: dosingInstruction.medication_agreement_id,
    };
    dispatch('add_change_item', payload);
  },
  add_change_instruction({dispatch}, payload) {
    payload.mutation_type = ChangeConstants.change_events.ADDITION;
    dispatch('add_change_instruction_base', payload);
  },
  add_change_instruction_to_manualdosing_schema({dispatch}, payload) {
    payload.mutation_type = ChangeConstants.change_events.CHANGE_INSTRUCTION_TO_MANUALDOSING_SCHEMA;
    dispatch('add_change_instruction_base', payload);
  },
  add_change_instruction_base({dispatch}, payload) {
    const change = {};
    change.original_instruction_ids = [payload.original_instruction.id];
    change.new_instruction_ids = payload.new_instruction_ids;
    change.mutation_type = payload.mutation_type;
    change.medication_agreement_id = payload.original_instruction.medication_agreement_id;

    dispatch('add_change_item', change);
  },
  add_addition({dispatch}, additionalDosingInstruction) {
    const change = {};
    change.original_instruction_ids = [additionalDosingInstruction.parent_id];
    change.new_instruction_ids = [additionalDosingInstruction.id];
    change.mutation_type = ChangeConstants.change_events.ADDITION;
    change.medication_agreement_id = additionalDosingInstruction.medication_agreement_id;

    dispatch('add_change_item', change);
  },
  add_restart({dispatch, commit}, additionalDosingInstruction) {
    const change = {};
    change.original_instruction_ids = [additionalDosingInstruction.parent_id];
    change.new_instruction_ids = [additionalDosingInstruction.id];
    change.mutation_type = ChangeConstants.change_events.RESTART;
    change.medication_agreement_id = additionalDosingInstruction.medication_agreement_id;

    commit('add_restart_if_needed', {agreement_id: change.medication_agreement_id, item: change});
  },
  add_stop_instruction({dispatch, commit}, payload) {
    payload.mutation_type = ChangeConstants.change_events.STOP_INSTRUCTION;
    commit('add_or_update_stop', {agreement_id: payload.medication_agreement_id, item: payload});
  },
  add_correction_stop_instruction({dispatch, commit}, payload) {
    payload.mutation_type = ChangeConstants.change_events.CORRECT_STOP;
    commit('add_or_update_stop', {agreement_id: payload.medication_agreement_id, item: payload});
  },
  add_cancel_stop_item({dispatch}, dosingInstruction) {
    const change = {};
    change.original_instruction_ids = [dosingInstruction.id];
    change.new_instruction_ids = [dosingInstruction.id];
    change.mutation_type = ChangeConstants.change_events.CANCEL_STOP;
    change.medication_agreement_id = dosingInstruction.medication_agreement_id;

    dispatch('add_change_item', change);
  },
  add_correct_instruction({dispatch}, dosingInstruction) {
    const payload = {
      instruction: dosingInstruction,
      mutation_type: ChangeConstants.change_events.CORRECT_INSTRUCTION
    };
    dispatch('add_correct_instruction_base', payload);
  },
  add_cancel_pending_instruction({dispatch}, dosingInstruction) {
    const payload = {
      instruction: dosingInstruction,
      mutation_type: ChangeConstants.change_events.CANCEL_INSTRUCTION
    };
    dispatch('add_correct_instruction_base', payload);
  },
  add_correct_instruction_to_manualdosing_schema({dispatch}, dosingInstruction) {
    const payload = {
      instruction: dosingInstruction,
      mutation_type: ChangeConstants.change_events.CORRECT_INSTRUCTION_TO_MANUAL_DOSING_SCHEMA
    };
    dispatch('add_correct_instruction_base', payload);
  },
  add_correct_instruction_base({dispatch}, payload) {
    const change = {};
    change.original_instruction_ids = [payload.instruction.id];
    change.new_instruction_ids = [payload.instruction.id];
    change.mutation_type = payload.mutation_type;
    change.medication_agreement_id = payload.instruction.medication_agreement_id;
    dispatch('add_change_item', change);
  },
  add_change_manual_dosing_instruction({dispatch}, dosingInstruction) {
    const change_item = {
      mutation_type: ChangeConstants.change_events.MANUALDOSING_SCHEMA_CHANGED,
      original_instruction_ids: [dosingInstruction.id],
      new_instruction_ids: [dosingInstruction.id],
      medication_agreement_id: dosingInstruction.medication_agreement_id
    };
    dispatch('add_change_item', change_item);
  },
  add_change_item({state, commit, dispatch, getters, rootState}, payload) {
    const agreement_id = payload.medication_agreement_id;
    commit('add_change_if_needed', {agreement_id: agreement_id, item: payload});
  },
};

// mutations
const mutations = {
  clear_corrections_related_to_instruction(state, dosingInstruction) {
    const medicationAgreementId = dosingInstruction.medication_agreement_id;
    let changeItems = state.changes[medicationAgreementId];

    if (changeItems) {
      //remove the correction
      //by keeping the others
      changeItems = changeItems.filter((changeItem) => {
        // Starts
        if (!changeItem.original_instruction_ids) {
          return true;
        }
        // Other dosing instructions
        if (changeItem.original_instruction_ids[0] !== dosingInstruction.id) {
          return true;
        }
        // Other types (not a correction)
        if (changeItem.mutation_type !== ChangeConstants.change_events.CORRECT_INSTRUCTION &&
           changeItem.mutation_type !== ChangeConstants.change_events.CORRECT_INSTRUCTION_TO_MANUAL_DOSING_SCHEMA) {
          return true;
        }
        return false;
      });
      state.changes[medicationAgreementId] = changeItems;
    }

  },
  update_original_instruction_on_cancel(state, payload) {
    const successor = payload.successor;
    const canceled_dosing_instruction = payload.canceled_dosing_instruction;

    const agreement_id = successor.medication_agreement_id;
    let changeItems = state.changes[agreement_id];

    changeItems = changeItems.map((changeItem) => {
      const sameInstruction = Utility.is_equal(changeItem.new_instruction_ids, [successor.id]);
      const sameOriginalInstruction = Utility.is_equal(changeItem.original_instruction_ids, [canceled_dosing_instruction.id]);

      if (sameInstruction && sameOriginalInstruction) {
        changeItem.original_instruction_ids = [canceled_dosing_instruction.parent_id];
      }
      return changeItem;
    });
    state.changes[agreement_id] = changeItems;
  },
  clear(state, payload) {
    state.changes = {};
  },
  add_or_update_stop(state, payload) {
    const agreement_id = payload.agreement_id;
    const item = payload.item;

    let changeItems = state.changes[agreement_id];
    if (!changeItems) {
      const mutation_id = state.last_mutation_id + 1;

      item.mutation_id = mutation_id;
      changeItems = [item];
      state.last_mutation_id = mutation_id;
    } else {
      //remove the current stop mutation/change for the instruction
      changeItems = changeItems.filter((changeItem) => {
        return !(changeItem.mutation_type == payload.item.mutation_type &&
            changeItem.dosing_instruction_id == payload.item.dosing_instruction_id);
      });
      //add the updated stop mutation/change
      const mutation_id = state.last_mutation_id + 1;
      item.mutation_id = mutation_id;
      changeItems.push(item);
      state.last_mutation_id = mutation_id;
    }
    state.changes[payload.agreement_id] = changeItems;
  },
  add_restart_if_needed(state, payload) {
    const agreement_id = payload.agreement_id;
    let changeItems = state.changes[agreement_id];
    if (!changeItems) {
      const mutation_id = state.last_mutation_id + 1;
      const item = payload.item;
      item.mutation_id = mutation_id;
      changeItems = [item];
      state.last_mutation_id = mutation_id;
    } else {

      //For a stop or restart, we only add a new change item, if none existed yet:
      const existingItems = changeItems.filter((changeItem) => {
        return changeItem.mutation_type === payload.item.mutation_type && changeItem.original_instruction_ids.includes(payload.item.original_instruction_ids[0]);
      });
      if (existingItems.length === 0) {
        const mutation_id = state.last_mutation_id + 1;
        const item = payload.item;
        item.mutation_id = mutation_id;
        changeItems.push(item);
        state.last_mutation_id = mutation_id;
      }
    }
    state.changes[payload.agreement_id] = changeItems;
  },
  add_change_if_needed(state, payload) {
    const agreement_id = payload.agreement_id;
    let changeItems = state.changes[agreement_id];
    if (!changeItems) {
      const mutation_id = state.last_mutation_id + 1;
      const item = payload.item;
      item.mutation_id = mutation_id;
      changeItems = [item];
      state.last_mutation_id = mutation_id;
    } else {
      //For a change item, we clear the current change for the instruction, and add a new one:

      //Only keep other changes
      changeItems = changeItems.filter((changeItem) => {
        if (changeItem.original_instruction_ids) {
          // case change
          return changeItem.mutation_type !== payload.item.mutation_type ||
            !changeItem.original_instruction_ids.includes(payload.item.original_instruction_ids[0]);
        } else if (changeItem.new_instruction_ids) {
          // case start
          return !changeItem.new_instruction_ids.includes(payload.item.new_instruction_ids[0]);
        }
        else {
          // case (temporary) stop
          return true;
        }
      });

      //add the new change
      const mutation_id = state.last_mutation_id + 1;
      const item = payload.item;
      item.mutation_id = mutation_id;
      changeItems.push(item);
      state.last_mutation_id = mutation_id;
    }

    //update vue store with actual changeitems
    state.changes[payload.agreement_id] = changeItems;
  },
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
