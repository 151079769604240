import Utility from '@/vue/utility/utility';
import BaseModule from '@/vue/store/modules/base_module';

// initial state
// shape: [{ id, quantity }]
const state = {
  ...BaseModule.state,

  search_columns: [
    // 'securityGroups.name',
    // 'securityGroups.permissions.description',
  ],
  filter_columns: [
    // 'name',
    // 'permissions.description',
  ],
  sort_columns: [
    {column_name: 'timestamp', direction: 'DESC'}
  ],
};

// getters
const getters = {
  ...BaseModule.getters,
};

// actions
const actions = {
  ...BaseModule.actions,
  fetch({state, commit, dispatch, getters, rootState}, payload) {
    const userId = payload.user_id;
    const securityGroupId = payload.security_group_id;
    const endpoint = '/api/v1/security-groups/' + securityGroupId + '/logs';

    return dispatch('api/getEndpoint', {
      endpoint: endpoint,
      data: {}
    }, {root: true}).then(response => {
      commit('set_data', response.data.data);
    });
  }
};

// mutations
const mutations = {
  ...BaseModule.mutations,
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
