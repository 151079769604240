import Vue from 'vue';
import BaseModule from '@/vue/store/modules/base_module';

const state = {
  ...BaseModule.state,
  toggleQuickFilters: false,
  sort_columns: [{ column_name: 'id', direction: 'DESC' }],
  search_columns: ['created_date_time|dutch_date', 'stockRecord.unit', 'amount', 'reason.reason', 'current_amount', 'session.user_name', 'drug.naam'],
};

const getters = {
  ...BaseModule.getters
};

const actions = {
  ...BaseModule.actions,
  set_endpoint({state, commit, dispatch}, payload) {
    commit(
      'set_search_endpoint',
      '/api/v1/stock/' + payload.type + '/' + payload.typeId + (payload.recordId ? '/stock-records/' + payload.recordId : '') + '/stock-record-details'
    );
  },
  fetch_single({state, commit, dispatch, getters, rootState}, payload) {
    return dispatch('api/getEndpoint', {
      endpoint: '/api/v1/stock/stock-record-details/' + payload.id
    }, {root: true}).then(response => {
      commit('add_to_data', response.data.data);
    });
  },
  save({state, commit, dispatch, getters, rootState}, payload) {
    return dispatch('api/postEndpoint', {
      endpoint: '/api/v1/stock/stock-record-details/' + payload.id,
      data: payload.data
    }, {root: true}).then(response => {
      commit('add_to_data', response.data.data);
    });
  },
  saveMultiple({state, commit, dispatch, getters, rootState}, payload) {
    return dispatch('api/postEndpoint', {
      endpoint: '/api/v1/stock/stock-record-details',
      data: payload
    }, {root: true}).then(response => {
      commit('add_to_data', response.data.data);
    });
  },
};

const mutations = {
  ...BaseModule.mutations,
  init_quick_filters(state, quick_filters) {
    // Remove any old quick filters
    Object.keys(state.quick_filters).forEach(quick_filter => {
      delete state.quick_filters[quick_filter];
    });
    // Add the dynamic quick filters
    if (quick_filters) {
      for (const quick_filter of quick_filters) {
        state.quick_filters[quick_filter.name] = quick_filter.value;
      }
    }
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
