import BaseModule from '@/vue/store/modules/base_module';

// initial state
const state = {
  ...BaseModule.state,
  data: [],
  medication: {},
  episodes: {},
  indications: {},
  intolerances: {},
  medicationguard: {},
  actions: {},
  quick_filters: {},
  toggleQuickFilters: true,
  related_mutations: {},
  default_open_tab: 1
};

// getters
const getters = {
  ...BaseModule.getters,
};

// actions
const actions = {
  ...BaseModule.actions,

  fetch({state, commit, dispatch, getters, rootState}, payload) {
    payload.endpoint = '/api/v1/patients/medication-evaluation/' + payload.patient_id;
    return dispatch('api/getEndpoint', {
      endpoint: payload.endpoint,
      data: {} // Empty, since it's a GET
    }, {root: true}).then(response => {
      commit('set_data', response.data.data);
    });
  },

  start({state, commit, dispatch, getters, rootState}, payload) {
    payload.endpoint = '/api/v1/patients/medication-evaluation/' + payload.patient_id + '/start';
    return dispatch('api/postEndpoint', {
      endpoint: payload.endpoint,
      data: {}
    }, {root: true});
  },

  close({state, commit, dispatch, getters, rootState}, payload) {
    payload.endpoint = '/api/v1/patients/medication-evaluation/' + payload.patient_id + '/close/' + payload.medication_evaluation_id;
    return dispatch('api/postEndpoint', {
      endpoint: payload.endpoint,
      data: {}
    }, {root: true}).then(response => {
      commit('add_to_data', response.data.data);
    });
  },

  fetchTabMedication({state, commit, dispatch, getters, rootState}, payload) {
    payload.tab = 'medication';
    return dispatch('fetchTab', payload).then(response => {
      commit('add_to_medication', {medication_evaluation_id: payload.medication_evaluation_id, medication: response.data.data});
    });
  },
  fetchTabIndications({state, commit, dispatch, getters, rootState}, payload) {
    payload.tab = 'indications';
    return dispatch('fetchTab', payload).then(response => {
      commit('add_to_indications', {medication_evaluation_id: payload.medication_evaluation_id, indications: response.data.data.indications});
      commit('add_to_episodes', {medication_evaluation_id: payload.medication_evaluation_id, episodes: response.data.data.episodes});
      commit('add_to_intolerances', {medication_evaluation_id: payload.medication_evaluation_id, intolerances: response.data.data.intolerances});
    });
  },
  fetchTabActions({state, commit, dispatch, getters, rootState}, payload) {
    payload.tab = 'actions';
    return dispatch('fetchTab', payload).then(response => {
      commit('add_to_actions', {medication_evaluation_id: payload.medication_evaluation_id, actions: response.data.data});
    });
  },
  fetchTabActionReport({state, commit, dispatch, getters, rootState}, payload) {
    payload.tab = 'actionReport';
    return dispatch('fetchTab', payload).then(response => {
      commit('add_to_actions', {medication_evaluation_id: payload.medication_evaluation_id, actions: response.data.data});
    });
  },
  fetchTabReadings({state, commit, dispatch, getters, rootState}, payload) {
    payload.tab = 'readings';
    return dispatch('fetchTab', payload).then(response => {
      commit('medication_evaluation_readings/set_data', response.data.data, {root: true});
    });
  },
  fetchTab({state, commit, dispatch, getters, rootState}, payload) {
    payload.endpoint = '/api/v1/patients/medication-evaluation/' + payload.patient_id + '/' + payload.medication_evaluation_id + '/tab/' + payload.tab;
    return dispatch('api/getEndpoint', {
      endpoint: payload.endpoint,
      data: {} // Empty, since it's a GET
    }, {root: true});
  },
  fetchRelatedMutations({state, commit, dispatch, getters, rootState}, payload) {
    payload.endpoint = '/api/v1/patients/medication-evaluation/' + payload.patient_id + '/status-history/' + payload.first_status_id;
    return dispatch('api/getEndpoint', {
      endpoint: payload.endpoint,
      data: {} // Empty, since it's a GET
    }, {root: true}).then(response => {
      commit('add_to_related_mutations', {first_status_id: payload.first_status_id, related_mutations: response.data.data});
    });
  },
  fetchMedicationGuardSignals({state, commit, dispatch, getters, rootState}, payload) {
    payload.endpoint = '/api/v1/patients/' + payload.patient_id + '/all/signals';
    return dispatch('api/getEndpoint', {
      endpoint: payload.endpoint
    }, {root: true});
  },
  saveMedicationGuardSignals({state, commit, dispatch, getters, rootState}, payload) {
    payload.endpoint = '/api/v1/patients/medication-evaluation/' + payload.patient_id + '/save-signals/' + payload.medication_evaluation_id;
    return dispatch('api/postEndpoint', {
      endpoint: payload.endpoint,
      data: payload.data
    }, {root: true}).then(response => {
      if (typeof response.data.notification !== 'undefined') {
        dispatch('notifications/addInfoAlert', {
          title: response.data.notification
        }, { root: true});
      }
    });
  },
};

// mutations
const mutations = {
  ...BaseModule.mutations,

  add_to_medication(state, payload) {
    state.medication[payload.medication_evaluation_id] = payload.medication;
  },
  add_to_indications(state, payload) {
    state.indications[payload.medication_evaluation_id] = payload.indications;
  },
  add_to_episodes(state, payload) {
    state.episodes[payload.medication_evaluation_id] = payload.episodes;
  },
  add_to_intolerances(state, payload) {
    state.intolerances[payload.medication_evaluation_id] = payload.intolerances;
  },
  add_to_readings(state, payload) {
    state.readings[payload.medication_evaluation_id] = payload.readings;
  },
  add_to_medicationguard(state, payload) {
    state.medicationguard[payload.medication_evaluation_id] = payload.medicationguard;
  },
  add_to_related_mutations(state, payload) {
    state.related_mutations[payload.first_status_id] = payload.related_mutations;
  },
  add_to_actions(state, payload) {
    state.actions[payload.medication_evaluation_id] = payload.actions;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
