import {AbstractMedication} from "@/controllers/AbstractMedication";
import {MedicationAgreement} from "@/domain/models/MedicationAgreement";
import {AbstractMedicationData} from "@/controllers/AbstractMedicationData";

export class MedicationAgreementController extends AbstractMedication<MedicationAgreement> {
  protected storeName = 'mp9/medication_agreements';
  protected apiEndpoint = 'medication-agreement';

  protected fromJson(data: unknown): MedicationAgreement {
    return MedicationAgreement.fromJson(data);
  }
}

export type MedicationAgreementData = AbstractMedicationData<MedicationAgreement>
