<template>
  <span class="badge badge-danger" v-if="showZero || number > 0">{{ number }}</span>
</template>

<script>
export default {
  props: {
    number: {required: true, type: Number},
    showZero: {default: false, type: Boolean},
  },
};
</script>

<style lang="scss" scoped>
.badge {
  margin-left: 5px;
  height: 17px;
  min-width: 17px;
  padding: 0.4em 0.65em;
}
</style>
