<template>
  <medi-modal
      :loading="loading"
      title="Meetwaarden historie"
      size="medium"
      :patient_id="patient_id"
      @closed="onClosed()"
  >
    <medimo-table
        :read_only="true"
        :enable_filter="false"
        :number_of_rows="readings_history.length"
        :copyable="true"
    >
      <medimo-thead></medimo-thead>

      <medimo-tbody>
        <medimo-tr :disabled="true" :key="readings_history.id" v-for="history in readings_history">
          <medimo-td col_width="12">
            <span v-for="log in history.log.data">
              <span v-html="log"></span><br />
            </span>
            <span>{{ history.log.by }}</span>
          </medimo-td>
        </medimo-tr>
      </medimo-tbody>
    </medimo-table>

  </medi-modal>
</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import moment from 'moment-mini';

export default {
  emits: ['closed'],

  props: [
    'patient_id'
  ],

  mixins: [
    BaseComponentsMixin
  ],

  created() {
    this.fetchHistory();
  },

  data: function () {
    return {
      loading: false,
      picked: '',
    };
  },

  computed: {
    readings_history() {
      const readings_history = this.$store.state.readings.history[this.patient_id];
      if (typeof readings_history === 'undefined') {
        return [];
      }
      return readings_history;
    },
  },

  methods: {
    fetchHistory() {

      // If don't already have some history, we show the loader
      if (this.readings_history.length === 0) {
        this.loading = true;
      }
      this.$store.dispatch('readings/fetchHistory', {
        patient_id: this.patient_id
      }).then(() => {
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },

    onClosed(event) {
      this.$emit('closed');
    },
  },

  watch: {
    //
  }
};
</script>
