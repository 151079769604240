import BaseModule from '@/vue/store/modules/base_module';

const state = {
  ...BaseModule.state,
  search_endpoint: '/api/v1/logs/webservices',
  quick_filters: {},
  sort_columns: [{
    'column_name': 'created_at',
    'direction': 'DESC',
  }],
};

const getters = {...BaseModule.getters};
const actions = {
  ...BaseModule.actions,
  fetchWebservices({state, commit, dispatch}, payload) {
    return dispatch('api/getEndpoint', {
      endpoint: state.search_endpoint + '/distinct',
      data: {}
    }, {root: true});
  }
};
const mutations = {...BaseModule.mutations};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
