import Vue from 'vue';
import BaseModule from '/vue/store/modules/base_module';

// initial state
// shape: [{ id, quantity }]
const state = {
  ...BaseModule.state,

  data: [],

  quick_filters: {'View': {'mustBeActive': true, 'group': {'Actief': true, 'Vervallen': false, 'Overleden': false, 'Historisch': false,}},},
  default_quick_filters: {
    'View': {
      'mustBeActive': true,
      'group': {
        'Actief': true,
        'Vervallen': false,
        'Overleden': false,
        'Historisch': false,
      }
    },
  },
  sort_columns: [{column_name: 'patientData.naam', direction: 'ASC'}],
  default_sort_columns: [{column_name: 'patientData.naam', direction: 'ASC'}],

  search_columns: [
    'patientData.naam',
    'patientData.naamvoor',
    'instelling.naam',
    'afdeling.naam',
    'patientData.kamer',
    'patientData.gebdat'
  ],

  search_endpoint: '/api/v1/patients/selector',
};

// getters
const getters = {
  ...BaseModule.getters,
  // dummy_getter(state, getters, rootState, rootGetters) {
  //   //
  // }
};

// actions
const actions = {
  ...BaseModule.actions,
  // dummy_action ({state, commit, dispatch, getters, rootState}, payload) {
  //     //
  // }
};

// mutations
const mutations = {
  ...BaseModule.mutations,
  setActief (state, value) {
    state.quick_filters['View']['group']['Actief'] = value;
  },
  setHistorisch (state, value) {
    state.quick_filters['View']['group']['Historisch'] = value;
  },
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
