<template>
    <span class="medimo-copy-button text-medimo-50 ml-2 clickable" @click.stop="copyContentsToClipboard">
        <medimo-tooltip :content="tooltip_content" style="display:inline;">
            <fa-icon icon="fa-regular fa-files" flip="vertical" size="lg" />
        </medimo-tooltip>
    </span>
</template>

<script>
import MedimoTooltip from './MedimoTooltip';
import CopiesContent from '@/vue/mixins/CopiesContent';
import WorksWithParentsSiblingsAndChildren from '@/vue/mixins/WorksWithParentsSiblingsAndChildren';
import Copy from '@/vue/utility/copy';

export default {
  components: {
    MedimoTooltip,
  },

  props: {
    /**
       * Door een array of string mee te geven kun je hiermee makkelijk dingen naar het klembord kopieren
       */
    copyData: {default: null},

    /**
       * Deze twee waarden kunnen voor en achter de gekopieerde tekst komen.
      */
    prefix: {default: ''},
    suffix: {default: ''},

    /**
       * The default value the user sees when they hover over the clipboard icon
       */
    tooltip_content_start: {default: 'Kopiëer naar klembord'},

    /**
       * Use this property when you have your own method for exporting data
       */
    custom_copy_event: {default: null}
  },

  emits: ['custom_copy_event'],

  mixins: [
    CopiesContent,
    WorksWithParentsSiblingsAndChildren,
  ],

  data: function () {
    return {
      tooltip_content: '',
      virtualTable: '',
    };
  },

  computed: {
    //
  },

  created() {
    //
  },

  mounted() {
    // To have a single point of truth, we set an initial value based on the data, only to overwrite that later
    // if the user clicks the button
    this.tooltip_content = this.tooltip_content_start;
  },

  methods: {
    toggleTooltipMessage() {
      this.tooltip_content = 'Kopiëren gelukt!';
      setTimeout(() => {
        this.tooltip_content = this.tooltip_content_start;
      }, 5000);
    },
    copyContentsToClipboard() {

      // custom hook is set
      if (this.custom_copy_event !== null) {
        this.$emit('custom_copy_event');
        this.toggleTooltipMessage();
        return;
      }

      if (this.copyData === null) {
        this.$store.dispatch('notifications/addWarningAlert', {
          message: 'Geen data gevonden om te kopieren.'
        });
        return;
      }

      this.toggleTooltipMessage();
      Copy.copyValueToClipboard(this.copyData, this.prefix, this.suffix);
    },
  },

  watch: {
    //
  }
};
</script>
