<template>
    <span :class="'medimo-copy-button ml-2 clickable ' + text_class" @click.stop="copyContentsToClipboard">
        <medimo-tooltip :content="tooltip_content" style="display:inline;">
            <fa-icon icon="fa-regular fa-files" size="lg"/> <slot></slot>
        </medimo-tooltip>
    </span>
</template>

<script>
import MedimoTooltip from './MedimoTooltip';
import CopiesContent from '@/vue/mixins/CopiesContent';
import WorksWithParentsSiblingsAndChildren from '@/vue/mixins/WorksWithParentsSiblingsAndChildren';
import Copy from '@/vue/utility/copy';

export default {
  components: {
    MedimoTooltip,
  },

  props: {
    /**
     * The container_component that contains the data we want to copy.
     * It's the one we search within for the row and cell components. Will usually be the parent, but
     * it's safer to always specify.
     */
    container_component: {required: true},

    /**
     * Als je deze leeg laat en alleen de cell_component_uid_properties vult, krijg je een lijst waarbij iedere cel een nieuwe regel is!
     * Als je hem wel vult dan zullen alle matching cell css elementen in een regel naar het clipboard gekopieerd worden
     */
    row_component_css_selector: {default: ''}, // Kan een string zijn, of een array met values

    /**
     * Has to be an array. This will be a custom defined uid data, prop or computed property
     * Example: for the MedimoTable, it would be: ['medimoTdUid', 'medimoThUid']
     */
    cell_component_css_selector: {required: true, type: Array},

    /**
     * The default value the user sees when they hover over the clipboard icon
     */
    tooltip_content_start: {default: 'Kopiëer naar klembord'},

    /**
     * Use this property when you have your own method for exporting data
     */
    custom_copy_event: {default: null},

    /**
     * Zet een custom delimiter
     */
    delimiter: {default: null},

    // Door deze te ++en kun je de copy programmatically triggeren
    trigger: {default: 0},

    // Class om de text kleur te veranderen. Zeker als er text bij zit, wil je dat kunnen sturen
    text_class: {default: 'text-medimo-50'},

    /**
     * Deze twee waarden kunnen als eerste en laatste regel in de gekopieerde data komen
     */
    prefix: {default: ''},
    suffix: {default: ''},

    extraData: {
      default: () => {
        return [];
      }
    },
  },

  emits: ['custom_copy_event'],

  mixins: [
    CopiesContent,
    WorksWithParentsSiblingsAndChildren,
  ],

  data: function () {
    return {
      tooltip_content: '',
      virtualTable: '',
    };
  },

  computed: {
    //
  },

  created() {
    //
  },

  mounted() {
    // To have a single point of truth, we set an initial value based on the data, only to overwrite that later
    // if the user clicks the button
    this.tooltip_content = this.tooltip_content_start;
  },

  methods: {
    toggleTooltipMessage() {
      this.tooltip_content = 'Kopiëren gelukt!';
      setTimeout(() => {
        this.tooltip_content = this.tooltip_content_start;
      }, 5000);
    },
    copyContentsToClipboard() {

      // custom hook is set
      if (this.custom_copy_event !== null) {
        this.$emit('custom_copy_event');
        this.toggleTooltipMessage();
        return;
      }

      // If a row component is defined, this means we have to copy it to clipboard in a table-like manner
      // so that there's an X and Y axis
      let dataToCopy = [];
      if (Array.isArray(this.row_component_css_selector)) {
        this.row_component_css_selector.forEach(cssSelector => {
          dataToCopy.push(this.createDataArray(cssSelector));
        });
      } else {
        dataToCopy = this.createDataArray(this.row_component_css_selector);
      }

      this.extraData.forEach((extraDataRow, index) => {
        if (index === 0) {
          dataToCopy.push(['‎']); // Lege row eerst
        }
        if (Array.isArray(extraDataRow)) {
          // Hier issie multidimensional, dus kan er zo in
          dataToCopy.push(extraDataRow);
        }
        // Hier issie plat, dus moeten we het even een row maken met 1 cel
        dataToCopy.push([extraDataRow]);
      });

      Copy.copyContentsToClipboard(dataToCopy, this.delimiter, this.prefix, this.suffix);

      this.toggleTooltipMessage();
    },
    createDataArray(rowCssSelector) {
      let dataToCopy = [];
      if (rowCssSelector.length) {
        dataToCopy = this.createMultiDimensionalDataArray();
      } else {
        // Otherwise we create a virtual Table that's more like a list, with a single TR and TD per cell
        dataToCopy = this.createTwoDimensionalDataArray();
      }
      return dataToCopy;
    },
    createTwoDimensionalDataArray() {
      const data = [];

      let rows = [];
      rows = this.find_siblings_css(this.container_component.$el, this.cell_component_css_selector);
      rows.forEach(row => {
        if (this.shouldCopy(row)) {
          data.push([row.innerText]);
        }
      });

      return data;
    },
    createMultiDimensionalDataArray() {
      const rowData = [];

      // Find all the rows
      let rows = [];
      rows = this.find_siblings_css(this.container_component.$el, [this.row_component_css_selector]);

      rows.forEach(row => {

        let rowCells = [];
        rowCells = this.find_siblings_css(row, this.cell_component_css_selector);

        // Defined the CellContents
        const cellContents = [];

        // Get the content of all the cells
        rowCells.forEach(cellComponent => {

          // If you set a "copy" variable or prop to "false", it will not be copied
          // Default behaviour is that it will be
          if (this.shouldCopy(cellComponent)) {
            cellContents.push(cellComponent.innerText);
          }
        });

        // Hier voegen we vervolgens de array met cell content toe aan de rowData
        rowData.push(cellContents);
      });

      return rowData;
    },
    shouldCopy(cellComponent) {
      return (!cellComponent.hasAttribute('copy') || cellComponent.hasAttribute('copy') && cellComponent.getAttribute('copy') === false);
    }
  },

  watch: {
    trigger(value) {
      // On change, trigger the copy too
      this.copyContentsToClipboard();
    }
  }
};
</script>
