import BaseStockRecordDetail from '@/vue/store/modules/stock/base_stock_record_detail';

const state = {
  ...BaseStockRecordDetail.state,
  search_columns: ['created_date_time|dutch_date', 'stockRecord.unit', 'amount', 'reason.reason', 'current_amount', 'session.user_name'],
  quick_filters: {
    'history': {
      'group': {
        'order history': {
          'value': false,
          'label': 'Bestellingen'
        },
        'reservation history': {
          'value': false,
          'label': 'Reserveringen'
        }
      }
    },
  },
};
const actions = {
  ...BaseStockRecordDetail.actions
};
const getters = {
  ...BaseStockRecordDetail.getters
};
const mutations = {
  ...BaseStockRecordDetail.mutations
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
