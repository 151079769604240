<template>
  <medi-modal
      :class="'find-zindex-episode-table'"
      :id="'find-zindex-episode-table'"
      title="Zoek episode om toe te voegen"
      size="large"
      :min_height="0"
      @closed="close"
  >
    <medimo-external-data-table
        :store_name="store_name"
        :number_of_rows="models.length"
        :minimum_height="true"
        :name="'patient-zindex-episodes-' + patient.id"
        :privacy_filter="false"
    >
      <medimo-thead>
        <medimo-tr>
          <medimo-th column_classes="col col-sm-3" :store_name="store_name" sort_column_1="ICPC1">Code</medimo-th>
          <medimo-th column_classes="col col-sm-9" :store_name="store_name" sort_column_1="ICPCTXT">Omschrijving</medimo-th>
        </medimo-tr>
      </medimo-thead>
      <medimo-tbody>
        <template v-for="model in models">
          <find-zindex-episode-data-table-row
              :episode="model"
              @selected="$emit('zindexEpisodeSelected', model);"
          ></find-zindex-episode-data-table-row>
        </template>
      </medimo-tbody>
    </medimo-external-data-table>

  </medi-modal>
</template>

<script>


import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import FindZindexEpisodeDataTableRow from './FindZindexEpisodeDataTableRow';

export default {
  components: {
    FindZindexEpisodeDataTableRow
  },
  emits: ['zindexEpisodeSelected','closed'],

  props: {
    'patient': {required: true},
  },

  mixins: [BaseComponentsMixin],

  data: function () {
    return {
      store_name: 'episodes_zindex',
    };
  },

  computed: {
    models() {
      return this.$store.getters[this.store_name + '/search_results'];
    }
  },

  created() {

  },

  mounted() {
    // When ready, we set the search endpoint
    this.$store.commit(this.store_name + '/set_search_endpoint', '/api/v1/patients/' + this.patient.id + '/episodes/search-icpc');
  },

  unmounted() {

  },

  methods: {
    close() {
      this.$emit('closed');
    },
  },

  watch: {
  }
};
</script>
