<template>
    <div class="my-auto mx-auto text-center">
      <div class="medimo-loader-1 my-auto mx-auto" :style="dimensions">
        <img class="medimo-icon-is-spinning" src="/dist/assets/loader.svg" :style="dimensions" alt="">
      </div>
      <br/>
      <slot></slot>
    </div>
</template>

<script>
export default {
  props: {
    size: {default: 32}
  },
  computed: {
    dimensions() {
      return 'width: ' + this.size + 'px; height: ' + this.size + 'px;';
    }
  }
};
</script>
