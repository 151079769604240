import Utility from '@/vue/utility/utility';
import BaseModule from '@/vue/store/modules/base_module';

const state = {
  data: [],
};

// getters
const getters = {
  ...BaseModule.getters
};

// actions
const actions = {
  ...BaseModule.actions,
  fetch_single({state, commit, dispatch, getters, rootState}, payload) {
    const drugId = payload.id;
    const drug = getters.find(drugId);
  },
  fetch_single_for_patient({state, commit, dispatch, getters, rootState}, payload) {
    const drugId = payload.drug_id;
    const patientId = payload.patient_id;

    const endpoint = '/api/v1/patients/' + patientId + '/drugs/taxe/' + drugId;

    return dispatch('api/getEndpoint', {
      endpoint: endpoint,
      data: {}
    }, {root: true}).then(response => {
      commit('add_to_data', response.data.data);
    });
  },
};

// mutations
const mutations = {
  ...BaseModule.mutations,
  set_data(state, new_data) {
    state.data = new_data.data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
