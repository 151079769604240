<template>
  <!--         @focusin="setNavLinkUid"-->
  <div :class="'nav-search-link col-12 text-left link ' + (isSelected ? 'selected' : '')"
       @click.prevent="trigger"
       :uid="uid"
       ref="nav-search-link"
  >
    <div class="">
      <medimo-link :preventDefault="true" :target="target" :to="to" :trigger="navigate" style="width: 100%;">
        <span v-html="navSearchItem.title"></span> <span class="float-right text-small text-muted mr-2 mt-1"
                                                         v-if="navSearchItem.right_title">{{ navSearchItem.right_title }}</span>
      </medimo-link>
    </div>
  </div>
</template>

<script>
import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import {nextTick} from 'vue';

export default {
  components: {},

  emits: ['clicked'],

  props: {
    // To make sure the Links are grouped properly, we prepend the index with a number
    // Otherwise the keyoard navigation might start in the middle
    'indexGroup': {default: 0},

    // Contains a title, path, visible (boolean), and optionally, right_title property
    'navSearchItem': {},

    // Set to '_blank' to open in a new window
    'target': {default: '_self'},

    // Use these to auto-select the first link
    'select_first_link': {default: false},
    'first_link_index': {default: -1},

    // Switching this from false to true or vice verse will trigger navigation of the this link, when selected
    'triggerNavigation': {default: false},

    // Set this to false to only emit the @clicked event
    'navigateOnClicked': {default: true},
    // Set this to false to keep the NavSearchLink open after a click. Preferred if you want / need to do other things
    // before navigating. Without this, the component would be destroyed
    'closeOnClicked': {default: true},
  },

  mixins: [BaseComponentsMixin],

  data: function () {
    return {
      navigate: false,
    };
  },

  computed: {
    uid() {
      return this._.uid;
    },
    selectedNavSearchLinkUid: {
      get() {
        return this.$store.state.settings.navigation.selectedNavSearchLinkUid;
      },
      set(value) {
        this.$store.commit('settings/navigation/setSelectedNavSearchLinkUid', value);
      }
    },
    isSelected() {
      return this.uid === this.selectedNavSearchLinkUid;
    },
    to() {
      if (typeof this.navSearchItem.url !== 'undefined') {
        return this.navSearchItem.url;
      }
      return this.navSearchItem.path;
    }
  },

  created() {
    //
  },

  mounted() {

    if (this.first_link_index === 0 && this.select_first_link) {
      // if (false) {
      nextTick(() => {
        this.selectedNavSearchLinkUid = this.uid;
      });
    }
  },
  unmounted() {
    //
  },

  methods: {
    setNavLinkUid() {
      this.selectedNavSearchLinkUid = this.uid;
    },
    trigger() {
      this.$emit('clicked');
      if (this.navigateOnClicked) {
        this.navigate = !this.navigate;
      }
      if (this.closeOnClicked) {
        // Close the NavSearch, with a slight delay to prevent weird navigation behaviour
        setTimeout(() => {
          this.$store.commit('settings/navigation/setNavSearchNavigation', false);
        }, 1);
      }
    },
  },

  watch: {
    triggerNavigation(value) {
      // Since this watcher is only triggered on a change, it's safe to always fire the event
      if (this.isSelected) {
        this.trigger();
      }
    }
  }
};
</script>
