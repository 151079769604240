<template>
    <component :is="tag" @click="onClick" :class="'medimo-tooltip '+(displayBlock ? 'd-inline-block' : '')+' ' + (center ? 'text-center' :'') + ' ' + extra_classes">
        <slot></slot>
    </component>
</template>
<script>
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

export default {
  components: {},
  emits: ['click'],
  props: {
    'content': {default: ''},
    'center': {default: false},
    'extra_classes': {default: ''},
    'tag': {default: 'span'},

    // Set the delay in ms you want to set before the Tooltip pops up
    'delay': {default: 300},
    'showOnClick': {default: true},

    // Set this to true to show the tooltip with a click or hover
    'forceVisible': {default: false},

    // In sommige gevallen komt het design mooier uit als je deze op FALSE zet
    'displayBlock': {default: true},
  },
  mixins: [],
  data: function () {
    return {
      visible: false,
      tooltip: null,
    };
  },
  computed: {
    computed_variable() {
      return '';
    }
  },
  created() {
    //
  },
  beforeUnmount() {
    // Kan bij het heel snel laden en unloaden van een component null blijven
    if (this.tooltip !== null) {
      this.tooltip.hide();
      this.tooltip.disable();
      this.tooltip = null;
    }
  },
  mounted() {
    this.tooltip = tippy(this.$el, {
      content: this.content,
      arrow: true,
      delay: this.delay,
    });
    this.toggleTooltip();
  },
  methods: {
    toggleTooltip() {
      if (this.content && this.content.length) {
        this.tooltip.enable();
      } else {
        this.tooltip.disable();
      }
    },
    onClick() {
      // Sometimes we want to disable showing the tooltip on a click.
      if (!this.showOnClick) {
        return null;
      }
      this.visible = !this.visible;

      this.$emit('click');
    },
  },
  watch: {
    // Watch the change and show / hide the tooltip
    visible(value) {
      value ? this.tooltip.show() : this.tooltip.hide();
    },
    content(value) {
      if (typeof this.tooltip.setContent === 'function') {
        this.tooltip.setContent(value);
        this.toggleTooltip();
      }
    },
    forceVisible: {
      immediate: true,
      handler(forceVisible) {
        this.visible = forceVisible;
      }
    }
  }
};
</script>
<style>
.medimo-tooltip:focus {
  outline: 0;
}
</style>
