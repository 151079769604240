import BaseModule from '@/vue/store/modules/base_module';

const state = {
  ...BaseModule.state
};

const getters = {};

const actions = {

  planAisImport({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/patients/' + payload.patient_id + '/plan-ais-import/';

    return dispatch('api/getEndpoint', {
      endpoint: endpoint,
      data: {} // Empty, since it's a GET
    }, {root: true}).then(response => {
      return response.data;
    });
  },
};

const mutations = {
  ...BaseModule.mutations,
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
