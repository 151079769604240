<template>
  <medimo-tr @triggered="showEditIndicationModal">

    <medimo-td column_classes="col col-sm-3">
      <span>{{ indication.title }}</span>
    </medimo-td>

    <medimo-td column_classes="col col-sm-3">
      <span>{{ parsedRegisteredDate }}</span>
    </medimo-td>

    <medimo-td column_classes="col col-sm-3">
      <span>{{ indication.info }}</span>
    </medimo-td>

    <medimo-td column_classes="col col-sm-3">
      <span>{{ parsedEndDate }}</span>
    </medimo-td>

  </medimo-tr>
</template>

<script>
import Dates from '@/vue/utility/dates';
import MedimoTd from '@/vue/components/general/table/MedimoTd';
import MedimoTr from '@/vue/components/general/table/MedimoTr';
import EditIndicationModal from './EditIndicationModal';

export default {
  components: {
    EditIndicationModal,
    MedimoTd,
    MedimoTr,
  },

  emits: ['selected'],

  props: [
    'indication',
    'patient',
  ],

  mixins: [],

  data: function () {
    return {
      trigger_edit_action: false
    };
  },

  computed: {
    parsedRegisteredDate() {
      if (this.indication.register_date === null) {
        return 'Onbekend';
      }
      return Dates.setDate(this.indication.register_date).dutchDate();
    },
    parsedEndDate() {
      return Dates.setDate(this.indication.stop_date).dutchDateTime();
    }
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    showEditIndicationModal() {
      this.$emit('selected');
    },
  },

  watch: {
    //
  }
};
</script>
