import {RangeObject} from '@/domain/models/RangeObject';
import {CodeObject} from '@/domain/models/CodeObject';
import {QuantityObject} from '@/domain/models/QuantityObject';

export class GeneratedRangeObject { 
  public minimumValue: number | null;
  public maximumValue: number | null;
  public nominalValue: number | null;
  public isUnspecified: boolean | null;
  public unit: CodeObject | null;
  public timeUnit: QuantityObject | null;

  constructor(minimumValue: number | null = null, maximumValue: number | null = null, nominalValue: number | null = null, isUnspecified: boolean | null = null, unit: CodeObject | null = null, timeUnit: QuantityObject | null = null) {
    this.minimumValue = minimumValue;
    this.maximumValue = maximumValue;
    this.nominalValue = nominalValue;
    this.isUnspecified = isUnspecified;
    this.unit = unit;
    this.timeUnit = timeUnit;
  }

  public static fromJson(json: unknown): RangeObject {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new RangeObject(
      parsedJson['minimumValue'] as number,
      parsedJson['maximumValue'] as number,
      parsedJson['nominalValue'] as number,
      parsedJson['isUnspecified'] as boolean,
      parsedJson['unit'] ? CodeObject.fromJson(parsedJson['unit']) : null,
      parsedJson['timeUnit'] ? QuantityObject.fromJson(parsedJson['timeUnit']) : null,
    );
  }

  public static multipleFromJson(json: unknown): RangeObject[] {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    const items: RangeObject[] = [];
    for (const item of parsedJson) {
      items.push(this.fromJson(item));
    }
    return items;
  }
}
