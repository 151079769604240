<template>
  <medi-modal :loading="loading" :title="'Nieuwe meetwaarde: '+selected_bepaling.reading" size="medium" @closed="close(false)">
    <medimo-form>
      <medimo-form-row class="mb-2">
        <medimo-form-column column_classes="col-12">
          <medimo-labeled-input :disabled="true" label_column_classes="col-12 col-sm-3" label="Bepaling" name="bepaling" v-model="selected_bepaling.reading" />
        </medimo-form-column>
      </medimo-form-row>
      <medimo-form-row class="mb-2">
        <medimo-form-column column_classes="col-6">
          <medimo-validated-input label_column_classes="col-12 col-sm-6" label="Uitslag" name="uitslag" v-model="uitslag" />
        </medimo-form-column>
      </medimo-form-row>
      <medimo-form-row class="mb-2">
        <medimo-form-column column_classes="col-6">
          <medimo-validated-input label_column_classes="col-12 col-sm-6" label="Eenheid" name="eenheid" v-model="eenheid" />
        </medimo-form-column>
      </medimo-form-row>
      <medimo-form-row class="mb-2">
        <medimo-form-column column_classes="col-6">
          <medimo-validated-input label_column_classes="col-12 col-sm-6" label="Ondergrens" name="ondergrens" v-model="ondergrens" />
        </medimo-form-column>
      </medimo-form-row>
      <medimo-form-row class="mb-2">
        <medimo-form-column column_classes="col-6">
          <medimo-validated-input label_column_classes="col-12 col-sm-6" label="Bovengrens" name="bovengrens" v-model="bovengrens" />
        </medimo-form-column>
      </medimo-form-row>
      <medimo-form-row class="mb-2" :no_margins="true">
        <medimo-form-column column_classes="col-12">
          <medimo-validated-date-time-picker label_column_classes="col-12 col-sm-3" label="Meetdatum en -tijd" datetime_format="DD-MM-YYYY HH:mm" name="datum" v-model="datum" />
        </medimo-form-column>
      </medimo-form-row>
      <medimo-form-row class="mb-2">
        <medimo-form-column column_classes="col-12">
          <medimo-validated-textarea label_column_classes="col-12 col-sm-3" label="Opmerking" name="opmerking" rows="2" v-model="opmerking" />
        </medimo-form-column>
      </medimo-form-row>
    </medimo-form>

    <template v-slot:action-buttons>
      <medimo-button @click="store">Opslaan</medimo-button>
      <medimo-button @click="close(false)" type="outline-primary">Annuleren</medimo-button>
    </template>
  </medi-modal>
</template>

<script>
import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import moment from 'moment-mini';

export default {
  emits: ['closed'],

  props: {
    'selected_bepaling': {required: true},
    'patient': {required: true},
  },

  mixins: [
    BaseComponentsMixin
  ],

  data: function () {
    return {
      loading: false,

      uitslag: '',
      eenheid: this.selected_bepaling.default_unit,
      ondergrens: '',
      bovengrens: '',
      opmerking: '',
      datum: moment().startOf('day'),
    };
  },
  methods: {
    close(refresh) {
      this.$emit('closed', refresh);
    },
    store() {
      this.loading = true;
      this.$store.dispatch('readings/store', {
        patient_id: this.patient.id,
        data: {
          code: this.selected_bepaling.code,
          uitslag: this.uitslag,
          eenheid: this.eenheid,
          ondergrens: this.ondergrens,
          bovengrens: this.bovengrens,
          opmerking: this.opmerking,
          datum: this.datum
        }
      }).then((data) => {
        this.loading = false;
        if (data && data.data.notification) {
          this.$store.dispatch('notifications/addSuccessAlert', {
            title: data.data.notification
          });
        }
        this.close(true);
      }).catch(() => {
        this.loading = false;
      });
    },
  }
};
</script>
