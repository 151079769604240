import BaseModule from '../base_module';

// initial state
// shape: [{ id, quantity }]
const state = {
  ...BaseModule.state,
  per_page: 5,
  sort_columns: [
    {column_name: 'isOnHold', direction: 'ASC'},
    {column_name: 'start_moment_date', direction: 'ASC'},
  ]
};

// getters
const getters = {
  ...BaseModule.getters,
  websocketChannel(state, getters, rootState, rootGetters) {
    return state.websocketChannel;
  }
};

// actions
const actions = {
  ...BaseModule.actions,
  fetch_patient_data({state, commit, dispatch, getters, rootState}, payload) {
    return dispatch('api/getEndpoint', {
      endpoint: '/api/v1/mutations/' + (payload.examine ? 'examine' : 'authorise') + '/patient/' + payload.id + '/data',
    }, {root: true}).then(response => {

      // We voegen 'm toe aan de store zodat het werkt met alle andere components
      dispatch('patients/add_to_data', response.data.data.patient, {root: true});

      return response.data.data;
    });
  },

  fetch_authorise({state, commit, dispatch, getters, rootState}, payload) {
    let endpoint = '/api/v1/mutations/authorise/patient/' + payload.patient_id;
    if (payload.drugRequests) {
      endpoint += '?drugRequests=1';
    }
    return dispatch('api/getEndpoint', {
      endpoint: endpoint,
      data: {} // Empty, since it's a GET
    }, {root: true}).then(response => {
      // We re-setten hier de data om te voorkomen dat er oude data blijft hangen.
      commit('set_data', response.data.data);
    });
  },
  fetch_examine({state, commit, dispatch, getters, rootState}, payload) {
    return dispatch('api/getEndpoint', {
      endpoint: '/api/v1/mutations/examine/patient/'+ payload.patient_id,
      data: {} // Empty, since it's a GET
    }, {root: true}).then(response => {
      // We re-setten hier de data om te voorkomen dat er oude data blijft hangen.
      commit('set_data', response.data.data);
    });
  },
  fetch_full_authorise({state, commit, dispatch, getters, rootState}, payload) {
    return dispatch('api/getEndpoint', {
      endpoint: '/api/v1/mutations/authorise/patient/' + payload.patient_id + '/' + payload.mutation_id,
      data: {} // Empty, since it's a GET
    }, {root: true}).then(response => {
      return response.data.data;
    });
  },
  fetch_full_examine({state, commit, dispatch, getters, rootState}, payload) {
    return dispatch('api/getEndpoint', {
      endpoint: '/api/v1/mutations/examine/patient/' + payload.patient_id + '/' + payload.mutation_id,
      data: {} // Empty, since it's a GET
    }, {root: true}).then(response => {
      return response.data.data;
    });
  },
  hold_mutation({state, commit, dispatch, getters, rootState}, payload) {
    const mutationId = payload.mutation_id;
    return dispatch('api/postEndpoint', {
      endpoint: '/mutations/authorize/' + mutationId + '?holdMutationPopup=hold&suppressNotys=1',
      data: {
        comment: payload.comment,
      },
    }, {root: true});
  },
  join_channel({state, commit, dispatch, getters, rootState}, patient_id) {
    const websocketChannel = window.medimo.WebSocket.join('MutationPatient.' + patient_id);
    commit('set_state', {
      property: 'websocketChannel',
      value: websocketChannel,
    });
  },
  leave_channel({state, commit, dispatch, getters, rootState}) {
    getters['websocketChannel'].leave();
    commit('set_state', {
      property: 'websocketChannel',
      value: null,
    });
  }
};

// mutations
const mutations = {
  ...BaseModule.mutations,
  // dummy_mutation (state, id) {
  //     state.id = id;
  // },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
