import {Location} from "@/domain/models/Location";
import {Ward} from "@/domain/models/Ward";
import {Authority} from "@/domain/models/Authority";
import {Thrombosis} from "@/domain/models/Thrombosis";

export class Patient {
  public actions: Array<any>;
  public activeChats: number;
  public age: number;
  public assignedActionsCount: number;
  public authority: Authority;
  public authorizedBasicInfo: boolean;
  public birthday: string;
  public currentlyActive: boolean;
  public currentlyDBC: boolean;
  public currentlyELV: boolean;
  public currentlyImport: boolean;
  public ddsUntill: string | null;
  public deceased: boolean;
  public episodes: Array<any>;
  public hasBsn: boolean;
  public hasLocation: boolean;
  public id: number;
  public independenceClassification: string | null;
  public independenceClassificationId: number;
  public indications: Array<any> | null;
  public intolerances: Array<any> | null;
  public labCount: number;
  public latestImport: string;
  public location: object;
  public name: string;
  public nextPackageDateTime: string;
  public notice: string;
  public optInAllowed: number;
  public optInCount: number;
  public patientInfo: string;
  public profile_picture: string;
  public readOnly: boolean;
  public room: string;
  public rx_outpatient: string;
  public userIsDirectCareGiver: boolean;
  public ward: Ward;
  public warnings: Array<any>;
  public mp9: boolean;
  public thrombosis: Thrombosis;
  public isThrombosisPatient: boolean;

  constructor(actions: Array<any>, activeChats: number, age: number, assignedActionsCount: number, authority: Authority, authorizedBasicInfo: boolean, birthday: string, currentlyActive: boolean, currentlyDBC: boolean, currentlyELV: boolean, currentlyImport: boolean, ddsUntill: string | null, deceased: boolean, episodes: Array<any>, hasBsn: boolean, hasLocation: boolean, id: number, independenceClassification: string | null, independenceClassificationId: number, indications: Array<any> | null, intolerances: Array<any> | null, labCount: number, latestImport: string, location: object, name: string, nextPackageDateTime: string, notice: string, optInAllowed: number, optInCount: number, patientInfo: string, profile_picture: string, readOnly: boolean, room: string, rx_outpatient: string, userIsDirectCareGiver: boolean, ward: Ward, warnings: Array<any>, mp9: boolean, thrombosis: Thrombosis, isThrombosisPatient: boolean) {
    this.actions = actions;
    this.activeChats = activeChats;
    this.age = age;
    this.assignedActionsCount = assignedActionsCount;
    this.authority = authority;
    this.authorizedBasicInfo = authorizedBasicInfo;
    this.birthday = birthday;
    this.currentlyActive = currentlyActive;
    this.currentlyDBC = currentlyDBC;
    this.currentlyELV = currentlyELV;
    this.currentlyImport = currentlyImport;
    this.ddsUntill = ddsUntill;
    this.deceased = deceased;
    this.episodes = episodes;
    this.hasBsn = hasBsn;
    this.hasLocation = hasLocation;
    this.id = id;
    this.independenceClassification = independenceClassification;
    this.independenceClassificationId = independenceClassificationId;
    this.indications = indications;
    this.intolerances = intolerances;
    this.labCount = labCount;
    this.latestImport = latestImport;
    this.location = location;
    this.name = name;
    this.nextPackageDateTime = nextPackageDateTime;
    this.notice = notice;
    this.optInAllowed = optInAllowed;
    this.optInCount = optInCount;
    this.patientInfo = patientInfo;
    this.profile_picture = profile_picture;
    this.readOnly = readOnly;
    this.room = room;
    this.rx_outpatient = rx_outpatient;
    this.userIsDirectCareGiver = userIsDirectCareGiver;
    this.ward = ward;
    this.warnings = warnings;
    this.mp9 = mp9;
    this.thrombosis = thrombosis;
    this.isThrombosisPatient = isThrombosisPatient;
  }

  public static fromJson(json: unknown): Patient {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new Patient(
      parsedJson['actions'] as Array<any>,
      parsedJson['activeChats'] as number,
      parsedJson['age'] as number,
      parsedJson['assignedActionsCount'] as number,
      parsedJson['authority'],
      parsedJson['authorizedBasicInfo'],
      parsedJson['birthday'],
      parsedJson['currentlyActive'],
      parsedJson['currentlyDBC'],
      parsedJson['currentlyELV'],
      parsedJson['currentlyImport'],
      parsedJson['ddsUntill'],
      parsedJson['deceased'],
      parsedJson['episodes'],
      parsedJson['hasBsn'],
      parsedJson['hasLocation'],
      parsedJson['id'],
      parsedJson['independenceClassification'],
      parsedJson['independenceClassificationId'],
      parsedJson['indications'],
      parsedJson['intolerances'],
      parsedJson['labCount'],
      parsedJson['latestImport'],
      Location.fromJson(parsedJson['location']),
      parsedJson['name'],
      parsedJson['nextPackageDateTime'],
      parsedJson['notice'],
      parsedJson['optInAllowed'],
      parsedJson['optInCount'],
      parsedJson['patientInfo'],
      parsedJson['profile_picture'],
      parsedJson['readOnly'],
      parsedJson['room'],
      parsedJson['rx_outpatient'],
      parsedJson['userIsDirectCareGiver'],
      Ward.fromJson(parsedJson['ward']),
      parsedJson['warnings'],
      parsedJson['mp9'],
      Thrombosis.fromJson(parsedJson['thrombosis']),
      parsedJson['isThrombosisPatient'] as boolean,
    );
  }
}
