import BaseModule from '@/vue/store/modules/base_module';
import moment from 'moment-mini';

const state = {
  used: false,

  was_set: false,
  initPromises: [],

  data: {},

};

// getters
const getters = {
  is_used(state, getters, rootState) {
    return state.used;
  },
  initPromises(state, getters, rootState) {
    return state.initPromises;
  },
  was_set(state, getters, rootState) {
    return state.was_set;
  },
  data(state, getters, rootState) {
    return state.data;
  },
};

// mutations
const mutations = {
  ...BaseModule.mutations,
  is_used(state, data) {
    state.used = true;
  },
  add_to_initPromises(state, promise) {
    state.initPromises.push(promise);
  },
  delete(state) {
    // Irritant genoeg kan de initial_data zowel een object (vaak vanuit de backend) als een array zijn (de default)
    // Dit pogen te veranderen (i.e., een default van een leeg object) zorgt voor 273 falende acceptance tests.
    // Zou goed kunnen dat hier in de toekomst nog meer "types" bij moeten om goed te kunnen deleten.
    if (state.data.length !== 0 || Object.keys(state.data.length).length > 0) {
      state.data = {};
    }
  },
  set_data(state, data) {
    if (!state.was_set) {
      state.data = data;
      state.was_set = true;
    }
  },
};

const actions = {
  // dummy_action ({state, commit, dispatch, getters, rootState}, payload) {
  trigger_refresh_medication({state, commit, dispatch, getters, rootState}, patientId) {
    commit('medication_agreement_changes/clear', {}, {root: true});
    dispatch('medication_treatments/reset_all_local_data', {}, {root: true}); // including shadow data
    dispatch('medication_treatments/fetch', {patientId: patientId}, {root: true}).then((response) => {
      dispatch('initial_data/process_backend_medication_data', {data: response.data}, {root: true});
    });
  },

  process_backend_medication_data({state, commit, dispatch, getters, rootState}, payload) {

    const data = payload.data;
    const only_update = payload.only_update;

    if (data.patient && data.medication_treatments && data.medication_agreements && data.dosage_instructions) {
      commit('medication_treatments/set_last_medication_refresh', {moment: moment(), patient_id: data.patient.id}, {root: true});
    }

    dispatch('dispatch_if_defined', {data: data, property: 'patient', action: 'patients/add_to_data'});


    dispatch('commit_if_defined',
      {data: data, property: 'drug', mutation: 'drugs/add_to_data'});
    dispatch('commit_if_defined',
      {data: data, property: 'drugs', mutation: 'drugs/set_data'});
    dispatch('commit_if_defined',
      {data: data, property: 'dosage_intervals', mutation: 'dosage_intervals/set_data'});
    dispatch('commit_if_defined',
      {data: data, property: 'dosage_units', mutation: 'dosage_units/set_data'});
    dispatch('commit_if_defined',
      {data: data, property: 'administration_routes', mutation: 'administration_routes/set_data'});

    //Specific case for opening the medication status popup (in patient header)
    //If localModels are loaded, we only want to update the db data
    //Because we might be in the middle of the process of editing local models
    //The medication status popup is showing db_data
    const localModelsLoaded = rootState.dosage_instructions.data.length > 0;

    if (only_update && localModelsLoaded) {
      dispatch('commit_if_defined', {data: data, property: 'medication_treatments', mutation: 'medication_treatments/set_db_data'});
      dispatch('commit_if_defined', {data: data, property: 'medication_agreements', mutation: 'medication_agreements/set_db_data'});

      dispatch('dispatch_if_defined', {data: data, property: 'dosage_instructions', action: 'dosage_instructions/set_with_db_extended'});
    } else {
      dispatch('dispatch_if_defined',
        {data: data, property: 'medication_treatments', action: 'medication_treatments/set_with_db_and_local_model'});
      dispatch('dispatch_if_defined',
        {data: data, property: 'medication_agreement', action: 'medication_agreements/add_with_shadow'});
      dispatch('dispatch_if_defined',
        {data: data, property: 'medication_agreements', action: 'medication_agreements/set_with_db_and_local_model'});
      dispatch('dispatch_if_defined',
        {data: data, property: 'dosage_instructions', action: 'dosage_instructions/set_with_db_and_local_model_extended'});
    }

    dispatch('commit_if_defined',
      {data: data, property: 'pendingLspLink', mutation: 'status_pending_links/set_lsp'});
    dispatch('commit_if_defined',
      {data: data, property: 'pendingProtocolLink', mutation: 'status_pending_links/set_protocol'});
    dispatch('commit_if_defined',
      {data: data, property: 'pendingProvisionalLink', mutation: 'status_pending_links/set_provisional'});
    dispatch('commit_if_defined',
      {data: data, property: 'pendingProvisionalDrugRequestLink', mutation: 'status_pending_links/set_provisional_drug_request'});
    dispatch('commit_if_defined',
      {data: data, property: 'lspInformationLink', mutation: 'status_pending_links/set_lsp_information'});
  },

  commit_if_defined({state, commit, dispatch, getters, rootState}, payload) {

    const data = payload.data;
    const property = payload.property;
    const mutation = payload.mutation;

    if (typeof data[property] !== 'undefined') {
      commit(mutation, data[property], {root: true});
    }
  },

  dispatch_if_defined({state, commit, dispatch, getters, rootState}, payload) {

    const data = payload.data;
    const property = payload.property;
    const action = payload.action;
    const result_prop = payload.result_prop; // Lijkt nergens te worden gebruikt?

    const vm = this;
    if (typeof data[property] !== 'undefined') {
      const promise = new Promise((resolve, reject) => {
        dispatch(action, data[property], {root: true})
          .then(function (result) {
            if (typeof result_prop !== 'undefined') {
              vm[result_prop] = result;
            }
            resolve();
          });
      });

      commit('add_to_initPromises', promise);
    }
  }
};

export default {
  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
