import { createStore } from 'vuex';
// Persistent Storage of specific stores
// import VuexPersistence from 'vuex-persist/dist/umd/index.min';
import store_options from "./store_options";

/**
 * Utility Class for general use
 */

import VuexPersistence from 'vuex-persist';

const vuexLocal = new VuexPersistence({
  key: 'data',
  storage: window.sessionStorage,

  // Here we can define what stores we actually want to save
  // It reduces the full state, to only include the stores we define here
  // It was the only obvious way to get it working with nested stores.
  reducer: (state) => ({
    settings: {
      breadcrumbs: state.settings.breadcrumbs,
      data_tables: {
        default_settings: state.settings.data_tables.default_settings,
        stored_settings: state.settings.data_tables.stored_settings,
        stored_pages: state.settings.data_tables.stored_pages,
      },
      reports: state.settings.reports,
      selects: state.settings.selects,
      navigation: {
        adminPanel: state.settings.navigation.adminPanel,
        statusForwardRedirectLink: state.settings.navigation.statusForwardRedirectLink,
      },
    },
    dosage_intervals: {data: state.dosage_intervals.data},
    dosage_units: {data: state.dosage_units.data},
    administration_routes: {data: state.administration_routes.data},
    notifications: {alerts: state.notifications.alerts},

    // Stores the ActionDialogs the user has interacted with
    current_user: {
      current_cluster_info: state.current_user.current_cluster_info,
      processed_action_dialogs: state.current_user.processed_action_dialogs,
      medimo_notifications_dropdown_closed_x_times: state.current_user.medimo_notifications_dropdown_closed_x_times,
      show_status_switch_table_tooltip: state.current_user.show_status_switch_table_tooltip,
      show_status_patient_selector_tooltip: state.current_user.show_status_patient_selector_tooltip,
      show_advanced_filters_tooltip: state.current_user.show_advanced_filters_tooltip,
      show_all_mutations: state.current_user.show_all_mutations,
      waarneming_quick_filter_setting: state.current_user.waarneming_quick_filter_setting,
    },
    patient: {
      dispenses: {
        dispense_schema_templates: {
          data: state.patient.dispenses.dispense_schema_templates.data,
          last_update: state.patient.dispenses.dispense_schema_templates.last_update,
        }
      }
    }
  }),
  filter: (mutation) => {
    /**
     *     !!! Alle data van Vuex die we persisten wordt opgeslagen in de sessionStorage
     *     !!! dit betekent dat Tab A in de browser niet dezelfde persisted data heeft
     *     !!! als Tab B
     *
     *     !!! Data persisten is dus alleen geschikt voor dingen die je voor een gebruiker
     *     !!! wil persisten in een scherm, en tijdens het navigeren tussen v2 en v3 paginas.
     *     !!! Zoals bijvoorbeeld settings, of de breadcrumbs.
     *
     *     !!! Het is niet geschikt als "source of truth" voor belangrijke data
     *     !!! zoals je dat in een SPA zou doen. Daarvoor is de API leading.
     */
    const persisted_mutations = [
      'settings/reports/set_view',
      'settings/data_tables/set_stored_page',
      'settings/data_tables/clear_stored_page',
      'settings/data_tables/clear_all_stored_pages',
      'settings/data_tables/clear_all_stored_settings',
      'settings/data_tables/set_stored_setting',
      'settings/data_tables/set_default_setting',
      'settings/data_tables/clear_stored_settings',
      'settings/data_tables/clear_all_stored_settings',
      'settings/breadcrumbs/set_patient_breadcrumbs',
      'settings/breadcrumbs/set_breadcrumbs',
      'settings/breadcrumbs/set_breadcrumb_trail',
      'settings/breadcrumbs/delete_last_breadcrumb',
      'settings/breadcrumbs/remove_breadcrumbs_after',
      'settings/breadcrumbs/clear_breadcrumbs',
      'settings/breadcrumbs/clear_patients',
      'settings/navigation/setAdminPanel',
      'settings/navigation/setStatusForwardRedirectLink',
      'dosage_intervals/set_data',
      'dosage_units/set_data',
      'administration_routes/set_data',
      'dispense_schemas/set_data',
      'if_necessary_mp9/set_data',
      'current_user/set_processed_action_dialog',
      'current_user/set_current_cluster_info',
      'current_user/set_show_status_switch_table_tooltip',
      'current_user/set_show_status_patient_selector_tooltip',
      'current_user/set_show_advanced_filters_tooltip',
      'current_user/set_waarneming_quick_filter_setting',
      'notifications/add_notification',
      'notifications/delete_notification',
      'notifications/delete_alert',
      'patient/dispenses/dispense_schema_templates/set_data',
      'patient/dispenses/dispense_schema_templates/set_last_update',
    ];

    return persisted_mutations.includes(mutation.type);
  }
});

// Load store options via other separate file, so the vue tests can use them as well
const options = store_options.store_options;
options.plugins = [vuexLocal.plugin];

export const store = createStore(options);
