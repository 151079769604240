<template>
  <div class="d-flex align-items-center">
    <div id="breadcrumb-container"
         v-if="currentlyOn('lg+')"
         class="mx-auto my-auto v-cloak"
         v-bind:class="{'overview-page': currentlyOnOverviewPage, 'pl-lg-3': currentlyOnOverviewPage, 'p-0': !currentlyOnOverviewPage}">
      <div class="d-flex justify-content-between w-100">
        <div class="">
          <bread-crumbs></bread-crumbs>
        </div>
      </div>
    </div>
    <ul v-else class="navbar-nav mr-auto">
      <li class="nav-item d-flex align-items-center">
        <bread-crumbs></bread-crumbs>
      </li>
    </ul>
  </div>
</template>

<script>

import BreadCrumbs from './BreadCrumbs';
import UsesMediaQueries from '@/vue/mixins/UsesMediaQueries';

export default {
  components: {
    BreadCrumbs
  },

  props: [],

  mixins: [
    UsesMediaQueries
  ],

  data: function () {
    return {
      showCenter: true,
    };
  },

  computed: {
    navigated_deeper() {
      return this.$store.getters['settings/breadcrumbs/navigated_deeper'];
    },
    page_refreshed() {
      return this.$store.getters['settings/breadcrumbs/page_refreshed'];
    },
    currentlyOnOverviewPage() {
      return this.$store.state.settings.navigation.currentlyOnOverviewPage;
    }
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    //
  },

  watch: {
    //
  }
};
</script>
