import BaseModule from '@/vue/store/modules/base_module';
import utility from '@/vue/utility/utility';

// initial state
// shape: [{ id, quantity }]
const state = {
  stored_settings: {},
  stored_pages: {},
  default_settings: {},

  ...BaseModule.state,
};

// getters
const getters = {
  ...BaseModule.getters,
  is_stored(state, getters, rootState) {
    return (data_table_identifier) => {
      return state.stored_settings[data_table_identifier];
    };
  },
  is_stored_default(state, getters, rootState) {
    return (data_table_identifier) => {
      return state.default_settings[data_table_identifier];
    };
  },
  stored_page(state, getters, rootState) {
    return (data_table_identifier) => {
      const storedPage = state.stored_pages[data_table_identifier];
      // Als er eentje opgeslagen is, returnen we die, anders altijd de eerste pagina
      return typeof storedPage !== 'undefined' ? storedPage : 1;
    };
  },
  data_table_identifier() {
    return (name, route_name, extras = '') => {
      const identifier = name.length ? name : 'default';
      // We voegen hier alsnog de route_name toe, om een zo uniek mogelijke identifier te krijgen
      // en ook een component met unieke tabel name die op 2 verschillende pagina's gebruikt wordt
      // z'n eigen stored settings heeft.
      return route_name + '-' + identifier + extras;
    };
  }
};

// actions
const actions = {
  ...BaseModule.actions,
  store_default_settings({state, commit, dispatch, getters, rootState, rootGetters}, payload) {
    const default_identifier = payload.data_table_identifier.split('_QUERY_')[0]; // Get rid of query from identifier.

    if (typeof state.default_settings[default_identifier] === 'undefined') {
      // Als de default_ varianten ge-set zijn, vullen we automatisch de non default_ waardes.
      // Als de default_ niet ge-set zijn, komt de non-default terug en vervang hij dus zichzelf, ook prima
      // Door alle rolling changes is het nu wel wat onoverzichtelijk aan het worden, refactor naar local data is wenselijk
      // Soms worden er in de components quick filters on-the-fly toegevoegd, dus we setten hier, niet overschrijven
      const defaultQuickFilters = utility.deep_clone(rootGetters[payload.store_name + '/default_quick_filters']);
      Object.keys(defaultQuickFilters).forEach(quickFilterName => {
        commit(payload.store_name + '/set_quick_filter', {
          name: quickFilterName,
          value: defaultQuickFilters[quickFilterName],
        }, {root: true});
      });

      commit(payload.store_name + '/set_state', {
        property: 'sort_columns',
        value: utility.deep_clone(rootGetters[payload.store_name + '/default_sort_columns']),
      }, {root: true});

      commit('set_default_setting', {
        data_table_identifier: default_identifier,
        settings: {
          store_name: payload.store_name,
          query: '',
          current_page: 1,
          per_page: utility.deep_clone(payload.meta_data.per_page),
          search_meta: utility.deep_clone(rootGetters[payload.store_name + '/search_meta']),
          quick_filters: utility.deep_clone(rootGetters[payload.store_name + '/default_quick_filters']),
          sort_columns: utility.deep_clone(rootGetters[payload.store_name + '/default_sort_columns']),
        }
      });
    }
  },
  store_settings({state, commit, dispatch, getters, rootState, rootGetters}, payload) {
    // Slaat de: quickfilters, query, en huidige pagina op
    // We doen dat door de Datatable name + Route / url te gebruiken:
    commit('set_stored_setting', {
      data_table_identifier: payload.data_table_identifier,
      settings: {
        store_name: payload.store_name,
        query: utility.deep_clone(rootGetters[payload.store_name + '/query']),
        current_page: utility.deep_clone(payload.meta_data.current_page),
        per_page: utility.deep_clone(payload.meta_data.per_page),
        search_meta: utility.deep_clone(rootGetters[payload.store_name + '/search_meta']),
        quick_filters: utility.deep_clone(rootGetters[payload.store_name + '/quick_filters']),
        sort_columns: utility.deep_clone(rootGetters[payload.store_name + '/sort_columns']),
      }
    });
  },
  restore_all_default_settings({state, commit, dispatch, getters, rootState, rootGetters}, payload) {
    Object.keys(state.default_settings).forEach(data_table_identifier => {
      dispatch('restore_settings', {
        'data_table_identifier': data_table_identifier,
        'restore_default': true,
      });
    });
  },
  restore_settings({state, commit, dispatch, getters, rootState, rootGetters}, payload) {
    let settings = undefined;
    settings = getters['is_stored'](payload.data_table_identifier);
    if (payload.restore_default) {
      const default_identifier = payload.data_table_identifier.split('_QUERY_')[0]; // Get rid of query from identifier.
      settings = getters['is_stored_default'](default_identifier);
    }
    if (typeof settings === 'undefined') {
      return;
    }

    const restoreKeys = ['current_page', 'query', 'per_page', 'quick_filters', 'sort_columns', 'search_meta'];

    // Hier restoren we de store waardes voor die keys en is de opgeslagen waarde weer intact!
    restoreKeys.forEach(key => {
      commit(
        settings.store_name + '/set_state',
        {
          property: key,
          value: utility.deep_clone(settings[key]),
        },
        {root: true}
      );
    });

    // De current_page en search_meta moeten als laatste, anders reset het wijzigen van de query, sort, etc. de pagina
    // Intended behaviour, maar raar als je het niet verwacht.
    setTimeout(() => {
      commit(
        settings.store_name + '/set_state',
        {
          property: 'current_page',
          value: settings.current_page,
        },
        {root: true}
      );
    }, 250);
    // Ook onderstaand doen we met een kleine delay anders reset het
    setTimeout(() => {
      commit(
        settings.store_name + '/set_state',
        {
          property: 'search_meta',
          value: settings.search_meta,
        },
        {root: true}
      );
    }, 250);
  },
  clear_stored_settings({state, commit, dispatch, getters, rootState, rootGetters}, payload) {
    // Dan hier lokaal
    commit('clear_stored_settings', payload);
  },
  clear_all_stored_settings({state, commit, dispatch, getters, rootState, rootGetters}, payload) {
    commit('clear_all_stored_settings');
  }
};

// mutations
const mutations = {
  ...BaseModule.mutations,
  set_stored_setting (state, payload) {
    state.stored_settings[payload.data_table_identifier] = payload.settings;
  },
  set_default_setting (state, payload) {
    state.default_settings[payload.data_table_identifier] = payload.settings;
  },
  clear_default_settings (state, payload) {
    state.default_settings = {};
  },
  clear_stored_settings(state, payload) {
    delete state.stored_settings[payload.data_table_identifier];
  },
  clear_all_stored_settings(state) {
    state.stored_settings = {};
  },
  set_stored_page (state, payload) {
    state.stored_pages[payload.data_table_identifier] = payload.page;
  },
  clear_stored_page(state, payload) {
    delete state.stored_pages[payload.data_table_identifier];
  },
  clear_all_stored_pages(state) {
    state.stored_pages = {};
  }
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
