<template>
  <div class="container">
    <medimo-loader-overlay :loading="loading"></medimo-loader-overlay>
    <medimo-form v-if="!loading">

      <medimo-form-row>
        <medimo-form-column column_classes="col-12">
          <medimo-labeled-select2
              :editable="true"
              label_column_classes="col-12 col-sm-3"
              label="Naam"
              placeholder="Naam actie"
              name="name"
              :options="template_options"
              :modelValue="action.template_id"
              @update:modelValue="update('template_id', $event)"
              @editedText="update('name', $event); loadTemplate()"
              :editable_text="action.name"
              :allow_editable_text_only="true"
          ></medimo-labeled-select2>
        </medimo-form-column>
      </medimo-form-row>

      <medimo-form-row>
        <medimo-form-column column_classes="col-12">
          <medimo-labeled-textarea
              label_column_classes="col-12 col-sm-3"
              label="Toelichting"
              placeholder="Toelichting (optioneel)"
              name="description"
              :modelValue="action.description"
              @update:modelValue="update('description', $event)"
              rows="3"
          ></medimo-labeled-textarea>
        </medimo-form-column>
      </medimo-form-row>

      <medimo-form-row>
        <medimo-form-column column_classes="col-12 col-sm-3">
          <label>Verantwoordelijk</label>
        </medimo-form-column>

        <medimo-form-column column_classes="col-12 col-sm-6">

          <medimo-filter-button @toggle="update('is_assigned_to_physician',!action.is_assigned_to_physician)"
                                :active="action.is_assigned_to_physician"
                                extra_classes="isAssignedToPhysician">
            Arts
          </medimo-filter-button>

          <medimo-filter-button @toggle="update('is_assigned_to_care',!action.is_assigned_to_care)"
                                :active="action.is_assigned_to_care"
                                extra_classes="isAssignedToCare">
            Zorg
          </medimo-filter-button>

          <medimo-filter-button @toggle="update('is_assigned_to_pharmacy',!action.is_assigned_to_pharmacy)"
                                :active="action.is_assigned_to_pharmacy"
                                extra_classes="isAssignedToPharmacy">
            Apotheker
          </medimo-filter-button>

        </medimo-form-column>
      </medimo-form-row>

      <medimo-form-row>
        <medimo-form-column column_classes="col-12 col-sm-3">
          <label>Zichtbaar voor</label>
        </medimo-form-column>

        <medimo-form-column column_classes="col-12 col-sm-9">

          <medimo-filter-button v-if="is_visible_to_physician_enabled"
                                @toggle="update('is_visible_to_physician',!action.is_visible_to_physician)"
                                :active="action.is_visible_to_physician"
                                extra_classes="isVisibleToPhysician"
          >Arts
          </medimo-filter-button>

          <medimo-filter-button v-if="is_visible_to_care_enabled"
                                @toggle="update('is_visible_to_care',!action.is_visible_to_care)"
                                :active="action.is_visible_to_care"
                                extra_classes="isVisibleToCare"
          >Zorg
          </medimo-filter-button>

          <medimo-filter-button v-if="is_visible_to_pharmacy_enabled"
                                @toggle="update('is_visible_to_pharmacy',!action.is_visible_to_pharmacy)"
                                :active="action.is_visible_to_pharmacy"
                                extra_classes="isVisibleToPharmacy"
          >Apotheker
          </medimo-filter-button>

        </medimo-form-column>

      </medimo-form-row>

      <medimo-form-row>
        <medimo-form-column column_classes="col-12 col-sm-3">
          <label>Starten na</label>
        </medimo-form-column>
        <medimo-form-column column_classes="col-12 col-sm-1">
          <medimo-input
              name="start_after_days"
              :modelValue="action.start_after_days"
              @update:modelValue="update('start_after_days', $event)"
          ></medimo-input>
        </medimo-form-column>
        <medimo-form-column column_classes="col-12 col-sm-2">
          <label>dag(en)</label>
        </medimo-form-column>
      </medimo-form-row>

      <medimo-form-row>
        <medimo-form-column column_classes="col-12 col-sm-3">
          <label>Voltooien binnen</label>
        </medimo-form-column>
        <medimo-form-column column_classes="col-12 col-sm-1">
          <medimo-input
              name="finish_within_days"
              :modelValue="action.finish_within_days"
              @update:modelValue="update('finish_within_days', $event)"
          ></medimo-input>
        </medimo-form-column>
        <medimo-form-column column_classes="col-12 col-sm-2">
          <label>dag(en)</label>
        </medimo-form-column>
      </medimo-form-row>

      <medimo-form-row>
        <medimo-form-column column_classes="col-12 col-sm-3">
          <label>Opties</label>
        </medimo-form-column>
        <medimo-form-column column_classes="col-12 col-sm-9">
          <medimo-filter-button
              @toggle="update('is_private',!action.is_private)"
              :active="action.is_private"
              extra_classes="isPrivate"
              help_text="Markeer als privé actie">
            Prive
          </medimo-filter-button>

          <medimo-filter-button
              @toggle="update('inform_them',!action.inform_them)"
              :active="action.inform_them"
              extra_classes="informThem"
              help_text="Informeer betrokkenen dat de actie is aangemaakt">
            Informeer
          </medimo-filter-button>

          <medimo-filter-button
              @toggle="update('is_recurrent',!action.is_recurrent)"
              :active="action.is_recurrent"
              extra_classes="isRecurrent"
              help_text="Actie keert terug na op te geven aantal dagen">
            Herhalen
          </medimo-filter-button>

          <medimo-filter-button
              @toggle="update('inform_by_mail',!action.inform_by_mail)"
              :active="action.inform_by_mail"
              extra_classes="remind"
              help_text="Stuur een extra herinneringsmail op de dag dat de actie voltooid moet worden">
            Herinner
          </medimo-filter-button>
        </medimo-form-column>
      </medimo-form-row>

      <medimo-form-row v-if="action.is_recurrent">
        <medimo-form-column column_classes="col-12 col-sm-3">
          <label>Herhalen na</label>
        </medimo-form-column>
        <medimo-form-column column_classes="col-12 col-sm-1">
          <medimo-input
              name="repeatAfterDays"
              :modelValue="action.repeat_after_days"
              @update:modelValue="update('repeat_after_days', $event)"
          ></medimo-input>
        </medimo-form-column>
        <medimo-form-column column_classes="col-12 col-sm-2">
          <label>dagen</label>
        </medimo-form-column>
      </medimo-form-row>

    </medimo-form>
  </div>
</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import moment from 'moment-mini';
import MedimoFilterButton from '@/vue/components/general/form/base/MedimoFilterButton';
import utility from '@/vue/utility/utility';
import MedimoLoader from '@/vue/components/general/MedimoLoader';
import MedimoLoaderOverlay from '@/vue/components/general/MedimoLoaderOverlay';

export default {
  components: {MedimoLoaderOverlay, MedimoLoader, MedimoFilterButton},
  emits: ['update'],
  props: {
    show_related_status_option: false,
    user_base_entity_type: {required: true},
    action: {required: true},
  },

  mixins: [BaseComponentsMixin],

  data: function () {
    return {
      templates: '',
      loading: true,
    };
  },

  computed: {
    is_visible_to_care_enabled() {
      return this.user_base_entity_type !== 'Care';
    },
    is_visible_to_pharmacy_enabled() {
      return this.user_base_entity_type !== 'Pharmacy';
    },
    is_visible_to_physician_enabled() {
      return this.user_base_entity_type !== 'Physician';
    },
    unfiltered_templates: function () {
      return this.$store.state.actions.templates;
    },
    template_options: function () {
      const fetched_templates = this.unfiltered_templates;
      if (!fetched_templates.length) {
        return [{value: '', text: ''}];
      }

      const options = [];
      fetched_templates.forEach((item, index) => {
        options.push({
          value: item.id.toString(),
          text: item.name
        });
      });

      return options;
    },
  },

  created() {
    this.fetchTemplates();
  },

  methods: {
    update(prop, value) {
      this.$emit('update', prop, value);
    },
    fetchTemplates() {

      // only fetch when not already available
      if (this.templates.length > 0) {
        return;
      }

      this.loading = true;

      this.$store.dispatch('actions/fetchTemplates', {}).then(() => {
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    loadTemplate() {
      if (this.action.template_id === '') {
        return;
      }

      const template = utility.find_model_by_id(this.unfiltered_templates, this.action.template_id);

      if (template.isAssignedToCare) {
        this.update('is_assigned_to_care', true);
      } else if (template.isAssignedToPharmacy) {
        this.update('is_assigned_to_pharmacy', true);
      } else if (template.isAssignedToPhysician) {
        this.update('is_assigned_to_physician', true);
      }

      this.update('inform_them', template.chatEnabled);
      this.update('repeat_after_days', template.repeatAfterDays);
      this.update('start_after_days', template.startAfterDays);
      this.update('is_recurrent', template.isRecurrent);

      if (this.is_visible_to_care_enabled) {
        this.update('is_visible_to_care', template.isParticipatingCare);
      }

      if (this.is_visible_to_pharmacy_enabled) {
        this.update('is_visible_to_pharmacy', template.isParticipatingPharmacy);
      }

      if (this.is_visible_to_physician_enabled) {
        this.update('is_visible_to_physician', template.isParticipatingPhysician);
      }
    },
  },

  watch: {
    'action.is_assigned_to_physician': function (value) {
      if (value) {
        this.update('is_visible_to_physician', true);
      }
    },
    'action.is_assigned_to_care': function (value) {
      if (value) {
        this.update('is_visible_to_care', true);
      }
    },
    'action.is_assigned_to_pharmacy': function (value) {
      if (value) {
        this.update('is_visible_to_pharmacy', true);
      }
    },
  }
};
</script>
