import BaseModule from '@/vue/store/modules/base_module';

const state = { ...BaseModule.state };
const getters = { ...BaseModule.getters };
const actions = {
  ...BaseModule.actions,
  set_endpoint({state, commit, dispatch}, payload) {
    if (typeof payload.typeId != 'undefined') {
      commit('set_search_endpoint', '/api/v1/logs/' + payload.type + '/' + payload.typeId);
    } else {
      commit('set_search_endpoint', '/api/v1/logs/' + payload.type);
    }
  },
};
const mutations = { ...BaseModule.mutations };

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
