<template>
    <medi-modal
            :loading="loading"
            title="LSP historie"
            size="medium"
            :patient_id="patient_id"
            @closed="onClosed()"
    >
        <medimo-table
                :read_only="true"
                :enable_filter="false"
                :number_of_rows="history.length"
                :copyable="true"
        >
            <medimo-thead></medimo-thead>
            <medimo-tbody>
                <medimo-tr :disabled="true" :key="historyItem.id" v-for="historyItem in history">
                    <medimo-td col_width="12">
                        <span v-for="row in historyItem.human.data">
                            <span v-html="row"></span><br>
                        </span>
                        {{historyItem.human.by}}
                    </medimo-td>
                </medimo-tr>
            </medimo-tbody>
        </medimo-table>

    </medi-modal>
</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import MediModal from '@/vue/components/general/MediModal';
import MedimoTable from '@/vue/components/general/table/MedimoTable';
import moment from 'moment-mini';

export default {
  emits: ['closed'],

  components: {
    MediModal,
    MedimoTable
  },

  props: [
    'patient_id'
  ],

  mixins: [
    BaseComponentsMixin
  ],

  data: function () {
    return {
      loading: false,
      picked: '',
    };
  },

  mounted() {
    this.fetchHistory();
  },

  computed: {
    history() {
      const logHistory = this.$store.state.optin.history[this.patient_id];

      if (typeof logHistory === 'undefined') {
        return [];
      }

      return logHistory;
    }
  },

  methods: {
    fetchHistory() {

      // If don't already have some history, we show the loader
      if (this.history.length === 0) {
        this.loading = true;
      }

      this.$store.dispatch('optin/fetchHistory', {
        patient_id: this.patient_id
      }).then(() => {
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },

    parsedDateTime(time) {
      return moment(time).format('DD-MM-YYYY HH:mm:ss');
    },

    onClosed(event) {
      this.$emit('closed');
    }
  },

  watch: {
    //
  }

};
</script>
