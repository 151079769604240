import Utility from '@/vue/utility/utility';
import BaseModule from '@/vue/store/modules/base_module';

// initial state
// shape: [{ id, quantity }]
const state = {
  ...BaseModule.state,

  // Generated met https://www.json-generator.com/#
  // Super handig :)
  data: [],

  quick_filters: {
    // Zie base_module voor de meest recente omschrijving qua mogelijkheden
    'Alleen Paracetamol': false,
    'Alleen Ibuprofen': false,
    'Patient': false,
    'Error 500': false,
    'Medicijnen': {
      'group': {
        'Alleen Paracetamol': false,
        'Alleen Ibuprofen': false,
        'Alleen Asperine': false,
      }
    },
  },
  // Set to TRUE to allow only 1 quick filter to be active at once.
  // Automatically sets all other to FALSE when setting a new one to TRUE
  toggleQuickFilters: true,

  // Zetten we hier gewoon hardcoded
  export_endpoint: '/api/v1/example-data/export',
};

// getters
const getters = {
  ...BaseModule.getters,
};

// actions
const actions = {
  ...BaseModule.actions,
  // dummy_action ({state, commit, dispatch, getters, rootState}, payload) {
  //     //
  // }
};

// mutations
const mutations = {
  ...BaseModule.mutations,
  // dummy_mutation (state, id) {
  //     state.id = id;
  // },
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
