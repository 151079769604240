import {GeneratedAdministrationAgreement} from '@/domain/models/generated/GeneratedAdministrationAgreement';
import moment from "moment";

export class AdministrationAgreement extends GeneratedAdministrationAgreement {
  public isActive() {
    const now = moment();

    if (this.startDateTime?.isBefore(now) && this.endDateTime === null) {
      return true;
    }

    if (this.startDateTime?.isBefore(now) && this.endDateTime?.isAfter(now)) {
      return true;
    }

    return false;
  }

  public hasToBeChanged(): boolean {
    return this.copyIndicator || this.distributedIndicator;
  }

  public hasToBeStopped(): boolean {
    return this.copyIndicator || this.distributedIndicator;
  }
}
