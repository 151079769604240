<script>

import BaseComponentsMixin from '../BaseComponentsMixin';

export default {
  components: {},

  props: {route_params: {}},

  mixins: [BaseComponentsMixin],

  data: function () {
    return {
      alwaysShowAdvancedFilterModal: false,
    };
  },

  computed: {
    report_type() {
      // Hier zetten we de URL om naar de juiste syntax die de backend weer nodig heeft om het juiste
      // Report model op te halen. /reports/v3/patients/with-bem/ wordt dan patients--with-bem die vervolgens weer \App\Reports\Patients\WithBem wordt.
      let namespaces = this.route;
      namespaces = namespaces.replace('/reports/v3', '');
      namespaces = namespaces.split('/');

      let reportType = '';
      namespaces.forEach(namespace => {
        if (namespace.length) {
          reportType += namespace + '--';
        }
      });
      // De laatste -- halen we uiteraard even weg:
      reportType = reportType.substring(0, reportType.length - 2);

      return reportType;
    },
    shouldBeGeneratedWithFilters() {
      return typeof this.routeData.with_filters !== 'undefined' && this.routeData.with_filters;
    },
    routeData() {
      const routeData = this.$store.getters['routes/routes']({to: this.route, params: this.route_params});
      if (typeof routeData !== 'undefined') {
        return routeData;
      }
      // Als je hier uit komt vanwege deze warning: dubbelcheck de route property value van dit component op een Overview pagina. Als die niet matched met een key in de routes.js store krijg je deze error.
      console.error(this.route  + ' is undefined in routes.js');
    },
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    createReport() {
      if (this.shouldBeGeneratedWithFilters) {
        this.fetchDefaultReport();
      }
      else {
        // Tonen we het default generaten modal
        this.showModal = true;
      }
    },
    fetchDefaultReport() {
      // Halen we de Default waardes op
      this.$store.dispatch('api/getEndpoint', {
        endpoint: '/api/v1/reports/type/' + this.report_type + '/defaults',
      }).then(response => {
        this.report = response.data.data;

        // Als we een id mee krijgen, is er een bestaand report, dus redirect
        if (this.report.id !== null && !this.alwaysShowAdvancedFilterModal) {
          this.$router.push('/reports/' + this.report.id);
        } else {
          // Tonen we de popup (moet in de else, anders springt hij toch even omhoog)
          this.showAdvancedFiltersModal = true;
        }

      }).catch(error => {
      });
    }
  },

  watch: {
    //
  }
};
</script>
