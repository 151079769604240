'use strict';

class ZorgIdConnector {
  constructor(notifier, Websocket) {
    this._notifier = notifier;
    this._Websocket = Websocket;
    this._channelPart = 'ZorgId';
    this._eventSessionOpened = 'SessionOpened';
    this._eventSessionOpenCancelled = 'SessionOpenCancelled';
  }

  /**
   * Send the 'initiate authentication' request to our backend
   *
   * @private
   * @param {string} url
   * @param {string|Object} data
   * @returns {Promise}
   */
  _authenticationRequest(url, data) {
    return new Promise((resolve, reject) => {
      ajax.post(url, {
        contentType: 'application/json',
        data: data,
        success: response => {
          if (response instanceof Array) {
            // We got a formajaxprocessor response from the backend, rewrite 'response', so we can pick it up.
            // This should not happen, so its an error in any case
            response = { type: 'error', message: 'Er is een onbekende fout opgetreden. Laad de pagina opnieuw en probeer nogmaals.' };
          }

          if (response.type === 'error') {
            reject(response);
          } else {
            resolve(response);
          }
        },
        error: () => {
          // There was an error in connecting with our backend, or the backend did not respond with status 200
          reject(new Error('Er is een onbekende fout opgetreden. Laad de pagina opnieuw en probeer nogmaals.'));
        }
      });
    });
  }

  /**
   * Send the 'initiate authentication' request to our backend
   *
   * @private
   * @param {string} patientId
   * @param {string} action
   * @param {string} mostRecent
   * @returns {Promise}
   */
  _signRequest(patientId, action, mostRecent) {

    const url = "/lsp/requestzorgid/init";

    const data = {
      patientId: patientId,
      action: action,
      mostRecent: mostRecent
    };

    return new Promise((resolve, reject) => {
      ajax.post(url, {
        data: data,
        success: response => {
          if (response instanceof Array) {
            // We got a formajaxprocessor response from the backend, rewrite 'response', so we can pick it up.
            // This should not happen, so its an error in any case
            response = { type: 'error', message: 'Er is een onbekende fout opgetreden. Laad de pagina opnieuw en probeer nogmaals.' };
          }

          if (response.type === 'error') {
            reject(response);
          } else {
            resolve(response);
          }
        },
        error: () => {
          // There was an error in connecting with our backend, or the backend did not respond with status 200
          reject(new Error('Er is een onbekende fout opgetreden. Laad de pagina opnieuw en probeer nogmaals.'));
        }
      });
    });
  }


  /**
   * Handler when we receive a session is opened event from the websocket
   *
   * @param message
   */
  handleSessionOpenedEvent(message) {
    //let jsonData = JSON.stringify(message.sessionId);
    window.location.href =  '/zorgid/login/' + message.sessionId;
    // ajax.post('/zorgid/login', {
    //   contentType: 'application/json',
    //   data: jsonData,
    //   success: response => {
    //     processAjaxUpdate(response);
    //   }
    // });
  }

  /**
   * Execute the ZorgId login
   */
  login() {
    window.addAjaxLoader();
    ajax.get('/zorgid/start-session', {
      error: result => {
        window.removeAjaxLoader();
      },
      success: result => {
        if (result.result === "error") {
          this._notifier.showError('ZorgID kan niet gestart worden, probeer het later nog een keer');
          window.removeAjaxLoader();
        } else {
          this._processLoginResult(result);
        }
      }});
  }

  _processLoginResult(result) {
    // Create a timeout to do something when the login procedure is not being fullfilled.
    // Remove the ajaxloader so a user can do something else
    const timeout = setTimeout(() => {
      window.removeAjaxLoader();
      this._notifier.showError('Nog geen reactie van ZorgID ontvangen, controleer of ZorgID geinstalleerd is');
    }, 60000);

    function cleanup() {
      window.removeAjaxLoader();
      clearTimeout(timeout);
    }

    // Open the websocket with the ZorgIdSessionId we received from the backend.
    const channel = this._Websocket.join(this._channel(result.sessionId));

    // When a message is received
    channel.on(this._eventSessionOpened, message => {
      this.handleSessionOpenedEvent(message);
      cleanup();
    });

    channel.on(this._eventSessionOpenCancelled, message => {
      this._notifier.showWarning("Inloggen bij ZorgID is afgebroken");
      cleanup();
    });

    this.openZorgIdProtocol(result.nonce, result.environment);
  }

  /**
   * @private
   * @param {string} sessionId
   * @returns {string}
   */
  _channel(sessionId) {
    return `${this._channelPart}.${sessionId}`;
  }

  /**
   * Execute the ZorgId login but only on the background, so no
   */
  async loginBackground(callback) {
    window.addAjaxLoader();
    ajax.get('/zorgid/start-session', {
      error: result => {
        window.removeAjaxLoader();
      },
      success: async (result) => {
        if (result.result === "error") {
          this._notifier.showError('ZorgID kan niet gestart worden, probeer het later nog een keer');
          window.removeAjaxLoader();
        } else {
          try {
            await this._processBackgroundLoginResult(result);
            callback();
          } catch (error) {
            this._notifier.showWarning("Inloggen bij ZorgID is afgebroken");
          }
        }
      }
    });
  }

  /**
   *
   * @returns {Promise<void>}
   * @private
   * @param patientId
   */
  async signInschrijfToken(patientId) {
    return ajax.post('/api/v1/lsp/signtoken/' + patientId).promise();
  }

  _processBackgroundLoginResult(result) {
    function cleanup() {
      window.removeAjaxLoader();
    }

    const promise = new Promise((resolve, reject) => {
      // Open the websocket with the ZorgIdSessionId we received from the backend.
      const channel = this._Websocket.join(this._channel(result.sessionId));

      // When a message is received
      channel.on(this._eventSessionOpened, message => {
        // Now login the user
        ajax.get('/zorgid/login/' + message.sessionId, {
          contentType: 'application/json',
          success: response => {
            resolve();
          }
        });
        cleanup();
      });

      // When a cancel event is received we reject the promise.
      channel.on(this._eventSessionOpenCancelled, message => {
        cleanup();
        reject();
      });
    });

    this.openZorgIdProtocol(result.nonce, result.environment);

    return promise;
  }

  /**
   *
   * @param patientId
   * @param action
   * @param mostRecent
   * @returns {Promise<void>}
   */
  async executeAsyncSignRequest(patientId, action, mostRecent) {
    try {
      const response = await this._signRequest(patientId, action, mostRecent);
      this._notifier.showSuccess(response.message);
    } catch (errorResponse) {
      // Notify user of error
      this._notifier.showError(errorResponse.message);
    }
  }

  /**
   * Open the ZorgId protocol handler
   *
   * @param nonce Unique string used to make the client known to the backend
   * @param environment Environment we let the Desktop client switch to
   */
  openZorgIdProtocol(nonce, environment) {
    const uri = "zorgid://s?n=" + nonce + "&e=" + environment;
    window.location.href = uri;
  }
}

if (typeof window === 'object') {
  window.ZorgIdConnector = ZorgIdConnector;
}

if (typeof module === 'object' && typeof module.exports !== 'undefined') {
  module.exports = ZorgIdConnector;
}
