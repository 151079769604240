import {Location} from '@/domain/models/Location';

export class Ward {
  public id: number;
  public name: string;
  public deletedAt: string;
  public st_r1: string;
  public st_r2: string;
  public st_r3: string;
  public st_r4: string;
  public st_r5: string;
  public st_r6: string;
  public deadlineTodayMinutes: number;
  public deadlinevandaag: number;
  public createdAt: string;
  public location: Location;

  constructor(id: number, name: string, deletedAt: string, st_r1: string, st_r2: string, st_r3: string, st_r4: string, st_r5: string, st_r6: string, deadlineTodayMinutes: number, deadlinevandaag: number, createdAt: string, location: Location) {
    this.id = id;
    this.name = name;
    this.deletedAt = deletedAt;
    this.st_r1 = st_r1;
    this.st_r2 = st_r2;
    this.st_r3 = st_r3;
    this.st_r4 = st_r4;
    this.st_r5 = st_r5;
    this.st_r6 = st_r6;
    this.deadlineTodayMinutes = deadlineTodayMinutes;
    this.deadlinevandaag = deadlinevandaag;
    this.createdAt = createdAt;
    this.location = location;
  }

  public static fromJson(json: unknown): Ward {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new Ward(
      parsedJson['id'] as number,
      parsedJson['name'] as string,
      parsedJson['deletedAt'] as string,
      parsedJson['st_r1'] as string,
      parsedJson['st_r2'] as string,
      parsedJson['st_r3'] as string,
      parsedJson['st_r4'] as string,
      parsedJson['st_r5'] as string,
      parsedJson['st_r6'] as string,
      parsedJson['deadlineTodayMinutes'] as number,
      parsedJson['deadlinevandaag'] as number,
      parsedJson['createdAt'] as string,
      Location.fromJson(parsedJson['location']),
    );
  }
  
  public times(): string[] {
    return [
      this.st_r1,
      this.st_r2,
      this.st_r3,
      this.st_r4,
      this.st_r5,
      this.st_r6,
    ];
  }
}
