import {DosingInstructionObject} from "@/domain/models/DosingInstructionObject";
import ViewType from "@/enums/ViewType";
import {store} from "@/vue/store";

export class DosingInstructionController {
  public async transform(patientId: number, dosingInstructions: DosingInstructionObject[], currentViewType: ViewType, nextViewType: ViewType): Promise<DosingInstructionObject[] | null> {
    const response = await store.dispatch('api/postEndpoint', {
      endpoint: '/api/v1/mp9/patient/' + patientId + '/dosing-instruction/transform',
      data: {
        currentViewType: currentViewType,
        nextViewType: nextViewType,
        dosingInstructions: dosingInstructions
      }
    });

    if (response.data.data) {
      return DosingInstructionObject.multipleFromJson(response.data.data);
    }

    return null;
  }
}
