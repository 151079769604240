<template>
  <medi-modal
      :loading="loading"
      :title="action.name"
      size="medium"
      @closed="ignoreForNow"
  >
    <medimo-form class="mb-4">
      <template v-if="action.type === 'influenza'">
        <medimo-form-row>
          <medimo-form-column  column_classes="mb-2">
            <medimo-link to="https://www.rivm.nl/griep-griepprik/griepprik/werking">Klik hier voor toelichting</medimo-link>
          </medimo-form-column>
        </medimo-form-row>
        <medimo-form-row>
          <medimo-form-column  column_classes="mb-2">
            <medimo-link
                to="https://handleidingen.nhg.org/griepvaccinatie/">
              NHG-Praktijkhandleiding Griepvaccinatie
            </medimo-link>
          </medimo-form-column>
        </medimo-form-row>
        <medimo-form-row>
          <medimo-form-column column_classes="mb-4">
            Mogelijke contra-indicatie of reden voor uitstel voor vaccinatie kunnen zijn:<br>
            Acute infectieziekte en/of koorts, geplande operatie binnen 48 uur na vaccinatie,
            bewezen anafylaxie voor kippenei-eiwit, conserveermiddelen en/of antibioticum neomycine of gentamicine.<br>
            Ga na of {{ language.patient }} wel vaccinatie mag ontvangen of stel zo nodig uit.
          </medimo-form-column>
        </medimo-form-row>
      </template>

      <medimo-validated-vue-select2
          class="mt-1 mb-3"
          label_column_classes="col-md-7"
          :help_text="'Maak een keuze'"
          :label="'Wil, kan en mag de ' + language.patient + ' gevaccineerd worden?'"
          :options="[
              {value: 'Y', text: 'Ja, wil vaccinatie'},
              {value: 'N', text: 'Nee, weigert vaccinatie'},
              {value: 'Uitstel', text: 'Uitstellen'},
              {value: 'Overig', text: 'Overig'},
            ]"
          v-model="wants_to_be_vaccinated"
          name="wants_to_be_vaccinated"
      ></medimo-validated-vue-select2>

      <medimo-validated-textarea
          v-if="wants_to_be_vaccinated !== 'Y'"
          class="mt-1 mb-3"
          label_column_classes="col-12 col-sm-3"
          label="Toelichting"
          placeholder="Uw toelichting"
          name="explanation"
          v-model="explanation"
          rows="3"
      ></medimo-validated-textarea>

      <medimo-validated-date-picker
          v-if="wants_to_be_vaccinated === 'Uitstel'"
          class="mt-1 mb-1"
          placeholder="Selecteer datum"
          label_column_classes="col-12 col-sm-3"
          :label="next_valid_until_label"
          :minDate="minDate"
          name="next_valid_until_date"
          v-model="next_valid_until_date"
      ></medimo-validated-date-picker>

      <template v-if="wants_to_be_vaccinated === 'Y'">
        <vaccine-select2 :type="action.type" v-model="drug_id"></vaccine-select2>
        <vaccine-batch-select2 v-if="drug_id.length"  :type="action.type" :vaccine_id="drug_id" v-model="batch_number"></vaccine-batch-select2>
      </template>
    </medimo-form>

    <template v-slot:action-buttons>
      <medimo-button type="success" @click="submit" :disabled="!readyToSubmit">Afhandelen</medimo-button>
      <medimo-button type="medimo" class="ml-auto" @click="ignoreForNow">Nu even niet</medimo-button>
    </template>
  </medi-modal>
</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import VaccineSelect2 from '@/vue/components/patients/action-dialogs/forms/VaccineSelect2';
import VaccineBatchSelect2 from '@/vue/components/patients/action-dialogs/forms/VaccineBatchSelect2';
import MedimoValidatedVueSelect2 from '@/vue/components/general/form/base/vue-select2/MedimoValidatedVueSelect2';

export default {
  components: {
    VaccineSelect2,
    VaccineBatchSelect2,
    MedimoValidatedVueSelect2
  },
  emits: ['closed','processed'],
  props: [
    'action',
    'patient',
    'refresh_patient_medication_data',
  ],
  mixins: [
    BaseComponentsMixin
  ],
  data: function () {
    return {
      loading: false,
      wants_to_be_vaccinated: '',
      drug_id: '',
      batch_number: '',
      next_valid_until_date: '',
      explanation: '',
      button_save_text: 'Opslaan',
      next_valid_until_label: 'Stel uit tot',
    };
  },
  computed: {
    language() {
      return this.$store.getters['current_user/language'];
    },
    readyToSubmit() {
      if ((this.wants_to_be_vaccinated === 'N' || this.wants_to_be_vaccinated === 'Overig') && this.explanation.length) {
        return true;
      }
      if (this.wants_to_be_vaccinated === 'Uitstel' && this.explanation.length && this.next_valid_until_date.length) {
        return true;
      }
      if (this.drug_id.length && this.batch_number.length) {
        return true;
      }

      return false;
    },
    minDate() {
      return this.action.validUntil;
    }
  },
  created() {},
  mounted() {},
  methods: {
    close() {
      this.$emit('closed');
    },
    ignoreForNow() {
      this.close();
    },
    submit() {
      this.loading = true;
      return this.$store.dispatch('patients/update_action_from_patient', {
        action_id: this.action.id,
        patient_id: this.action.patientId,
        handler: 'vaccinate',
        refresh_patient_medication_data: this.refresh_patient_medication_data,
        data: {
          wants_to_be_vaccinated: this.wants_to_be_vaccinated,
          vaccin: this.drug_id,
          batch_number: this.batch_number,
          explanation: this.explanation,
          next_valid_until_date: this.next_valid_until_date,
        }
      }).then(() => {
        this.loading = false;
        this.processed_action_dialog();
        this.$store.dispatch('notifications/addSuccessAlert', {
          message: 'Vaccinatie geregistreerd',
        });
        this.$emit('processed');
        this.close();
      }).catch(() => {
        // Needed for IE11, there's no .finally()
        this.loading = false;
      });
    },
  },
  watch: {}
};
</script>
