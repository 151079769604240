<template>

  <medimo-form-row v-if="options.length > 0" class="mb-3">
    <medimo-form-column class="font-weight-bold col-12">
      {{ label }}
    </medimo-form-column>
    <medimo-form-column v-for="option in options" :key="option.id" :class="col_class">
      <div v-if="isAlreadyActive(option)" class="medimo-checkbox align-items-center row">
        <div class="checkbox-label right inline col-auto order-12">
          <label class="checkbox-label">{{ option.text }}</label>
          <span class="col text-danger text-small">(is al actief)</span>
        </div>
        <div class="checkbox-container col-auto order-1"></div>
      </div>
      <medimo-labeled-checkbox v-else
        :id="type+option.id"
        :label="option.text"
        label_position="right"
        extra_classes_container=""
        :modelValue="option.checked"
        @update:modelValue="toggleOption(option, $event)">
      </medimo-labeled-checkbox>
    </medimo-form-column>
  </medimo-form-row>

</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';

export default {
  components: {

  },

  props: {
    options: {required: true},
    label: {required: true},
    type: {required: true},
    col_class: {default: 'col-12'}
  },

  mixins: [
    BaseComponentsMixin
  ],

  data: function () {
    return {

    };
  },

  computed: {
  },

  created() {
  },

  mounted() {
  },

  methods: {
    isAlreadyActive(option) {
      // niet gevonden bij patient, dus mag toegevoegd worden
      const result = this.$store.getters['intolerances/find_by_zindex'](option.type, option.id);
      if (result === null || result.length === 0) {
        return false;
      }
      // komt al voor bij patient, deselect en disable
      if (option.checked === true) {
        this.toggleOption(option, false);
      }
      return true;
    },
    toggleOption(option, event) {
      option.checked = event;
      this.$store.commit('intolerances_zindex/toggle_option', {option: option});
    },
  },

  watch: {

  }
};
</script>
