<template>
  <div style="position:relative;" :class="'medimo-textarea ' + (validation_class.length ? '' + validation_class : '')">
        <textarea :class="'medimo-textarea form-control ' + extra_classes"
                  :cols="cols"
                  :rows="rows"
                  v-bind:value="modelValue"
                  :name="name"
                  :placeholder="placeholder"
                  :disabled="disabled"
                  v-on:input="$emit('update:modelValue', $event.target.value)">
        </textarea>
        <small class="float-right mt-1" v-if="max_characters">
            {{ modelValue.length }}/{{ max_characters }}
        </small>
        <medimo-tooltip v-if="help_text.length" :content="help_text">
          <fa-icon icon="fa-solid fa-circle-question" class="text-primary" size="lg" />
        </medimo-tooltip>
    </div>
</template>

<script>

import MedimoTooltip from '@/vue/components/general/MedimoTooltip';

export default {

  components: {
    MedimoTooltip
  },
  emits: ['update:modelValue'],
  props: {
    'modelValue': {default: ''},
    'name': {default: ''},
    'placeholder': {default: ''},
    'help_text': {default: ''},
    'extra_classes': {default: ''},
    'validation_class': {default: ''},
    'cols': {default: 20},
    'rows': {default: 5},
    'disabled': {default: false},
    'max_characters': {default: null},
  },

  mixins: [],

  data: function () {
    return {
      //
    };
  },

  computed: {
    //
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    //
  },

  watch: {
    //
  }
};
</script>
