<template>
  <div class="medimo-table m-height">
    <medimo-loader-overlay transition="" :size="32" :loading="loadingThrottled"></medimo-loader-overlay>
    <div class="row tr">
      <div class="col-md-3 mb-2">
        <medimo-form>
          <medimo-input input_class="table-filter"
                        label="Filter"
                        :name="'filtertextReadings'"
                        placeholder="Zoek / filter"
                        ref="table-filter"
                        v-model="query"
                        @focus="focusTable"
          >
          </medimo-input>
        </medimo-form>
      </div>
      <div class="col-md">
        <div class="row h-100">
          <medimo-quick-filters :store_name="store_name" :quick_filters="quick_filters"></medimo-quick-filters>
        </div>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import MedimoQuickFilters from '@/vue/components/general/table/MedimoQuickFilters';
import ThrottlesRequests from '@/vue/mixins/ThrottlesRequests';
import { nextTick } from 'vue';

export default {
  components: {
    MedimoQuickFilters
  },
  props: {
    'set_query_action_name': {default: ''},
    'number_of_rows': 0,
  },
  mixins: [
    BaseComponentsMixin,
    ThrottlesRequests,
  ],
  data: function () {
    return {
      store_name: 'readings',
      search_on_load: true,
      show_loader: true,
      tooltip_content: 'Kopiëer naar klembord',
      tooltip_content_start: 'Kopiëer naar klembord'
    };
  },
  created() {
    // Fetch results and show the loader when there are no rows.
    if (this.search_on_load) {
      if (this.number_of_rows === 0) {
        this.searchPage(0, (this.number_of_rows === 0), 0);
      }
    }
  },
  methods: {
    searchPage(page_number, show_loader = true, delay = 300) {
      this.show_loader = show_loader;

      this.dispatchThrottled(this.store_name + '/search', {
        page: page_number,
      }, delay).catch(error => {
        if (error.message !== 'Throttled') {
          // nothing we can do
          this.$root.sendSentry(error);
        }
      });
    },
    focusTable() {
      // Set this table as the active one
      this.$store.commit('settings/navigation/setActiveTableUid', this.uid);
      nextTick(() => {
        this.$store.dispatch('settings/navigation/selectFirstTableRow');
      });
      this.focusFilter();
    },
    focusFilter() {
      if (this.isTheActiveTable && this.enable_filter) {
        // Auto focus the filter field
        this.$refs['table-filter']().$el.querySelector('input').focus();
      }
    },
  },
  computed: {
    query: {
      get() {
        if (this.store_name.length) {
          return this.$store.getters[this.store_name + '/query'];
        }
        return '';
      },
      set(value) {
        if (this.set_query_action_name.length > 0) {
          this.$store.dispatch(this.set_query_action_name, value);
        } else {
          this.$store.commit(this.store_name + '/set_query', value);
        }
      }
    },
    uid() {
      return this._.uid;
    },
    quick_filters() {
      return this.$store.getters[this.store_name + '/quick_filters'];
    },
  },
  watch: {
    query() {
      // When the query changes, we reset the page to 1 (which auto-reloads the data as well)
      // And set loading to true, so it always shows the loader on a query Search
      this.searchPage(1);
    },

    quick_filters: {
      deep: true,
      handler() {
        // When the quick_filters change, refetch the data, fast
        this.searchPage(1, true, 0);
      }
    },
  },
};
</script>

<style>
.head {
  border-bottom: 2px solid #5579A3;
  padding: 8px;
  overflow: hidden;
  max-height: 54px;
}
.cell {
  color: #1C4B84;
  padding: 8px;
  cursor: pointer;
}
.bepaling {
  overflow: hidden;
  max-height: 35px;
}
.reading-tr div {
  border-bottom: 1px solid #E8EDF2;
  color: #1C4B84;
}
.reading-tr:nth-of-type(even) div {
  background-color: #F9FAFC;
}
</style>
