import {store} from "@/vue/store";
import DataType from "@/enums/DataType";
import {AbstractMedicationData} from "@/controllers/AbstractMedicationData";
import {AbstractMedicationStore} from "@/controllers/AbstractMedicationStore";

export abstract class AbstractPharmaceuticalTreatment<T> extends AbstractMedicationStore {
  protected abstract storeName: string;
  protected abstract dataType: string;

  public async store(patientId: number, pharmaceuticalTreatmentId: number): Promise<void> {
    const userModifiedData = this.userModifiedData(pharmaceuticalTreatmentId);

    // When treatment zero its a new one.
    let apiEndPoint = '';
    if (pharmaceuticalTreatmentId === 0) {
      apiEndPoint = '/api/v1/mp9/patient/' + patientId + '/pharmaceutical-treatment/';
    } else {
      apiEndPoint = '/api/v1/mp9/patient/' + patientId + '/pharmaceutical-treatment/' + pharmaceuticalTreatmentId;
    }

    await store.dispatch('api/postEndpoint', {
      endpoint: apiEndPoint,
      data: {data: userModifiedData, type: this.dataType}
    });

    store.commit(this.storeName + '/clearData');
  }

  public async read(patientId: number, pharmaceuticalTreatmentId: number): Promise<AbstractMedicationData<T>[] | null> {
    const dataFromStore = this.dataFromStore(pharmaceuticalTreatmentId);

    // When we already have one with T available we take it from the store.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (dataFromStore !== null && dataFromStore.length > 0) {
      return Promise.resolve(dataFromStore);
    }

    // New pharmaceutical treatment so we throw an error if nothing in the store.
    if (pharmaceuticalTreatmentId === 0) {
      throw new Error('New pharmaceutical treatment not found.');
    }

    const response = await store.dispatch('api/getEndpoint', {
      endpoint: '/api/v1/mp9/patient/' + patientId + '/pharmaceutical-treatment/' + pharmaceuticalTreatmentId,
    });

    const pharmaceuticalTreatment = response.data.data;

    this.storeMedicationAgreements(pharmaceuticalTreatment);
    this.storeMedicationUses(pharmaceuticalTreatment);
    this.storeAdministrationAgreements(pharmaceuticalTreatment);
    this.storeVariableDosingRegimens(pharmaceuticalTreatment);
    this.storeDrug(pharmaceuticalTreatment);

    return this.dataFromStore(pharmaceuticalTreatmentId);
  }

  public async readFromStore(id: number, dataType: DataType): Promise<T | null> {
    try {
      return store.getters[this.storeName + '/' + dataType](id);
    } catch (error: unknown) {
      throw new Error();
    }
  }

  public async readFromStoreByProperty(id: number, property: string): Promise<T | null> {
    return store.getters[this.storeName + '/backendByProperty'](id, property);
  }

  public removeFromStore(): void {
    store.commit('mp9/medication_agreements/clearData');
    store.commit('mp9/administration_agreements/clearData');
    store.commit('mp9/variable_dosing_regimens/clearData');
    store.commit('mp9/medication_uses/clearData');
  }

  public draftHasChanges(id: number): boolean {
    // console.log(JSON.stringify(store.getters[this.storeName + '/' + DataType.Draft](id))); // has changes because of the startDateTime update.
    // console.log(JSON.stringify(store.getters[this.storeName + '/' + DataType.Backend](id)));
    console.log(id);
    return true; // todo mp9 implement logic to determine if it has changes. When userModified is set compare to that one, else to the backend version.
  }

  private dataFromStore(pharmaceuticalTreatmentId: number): AbstractMedicationData<T>[] {
    try {
      return store.getters[this.storeName + '/findByTreatment'](pharmaceuticalTreatmentId);
    } catch (error: unknown) {
      throw new Error();
    }
  }

  private userModifiedData(pharmaceuticalTreatmentId: number): T[] {
    const dataFromStore: AbstractMedicationData<T>[] = this.dataFromStore(pharmaceuticalTreatmentId);

    // Retrieve all user modified records.
    const userModifiedData: T[] = [];
    for (const data of dataFromStore) {
      if (data.userModified !== null) {
        userModifiedData.push(data.userModified);
      }
    }

    return userModifiedData;
  }
}
