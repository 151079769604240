import Utility from '@/vue/utility/utility';
import BaseModule from '@/vue/store/modules/base_module';

// initial state
// shape: [{ id, quantity }]
const state = {
  ...BaseModule.state,

  search_columns: [
    // 'securityGroups.name',
    // 'securityGroups.permissions.description',
  ],
  filter_columns: [
    // 'name',
    // 'permissions.description',
  ],
  sort_columns: [
    {column_name: 'name', direction: 'ASC'}
  ],

};

// getters
const getters = {
  ...BaseModule.getters,
};

// actions
const actions = {
  ...BaseModule.actions,
};

// mutations
const mutations = {
  ...BaseModule.mutations,
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
