import {MedicationVerificationItem} from '@/domain/models/MedicationVerificationItem';
import {PharmaceuticalProduct} from '@/domain/models/PharmaceuticalProduct';
import moment from 'moment';

export class GeneratedMedicationVerificationItem { 
  public id: number;
  public type: string;
  public typeIcon: string;
  public pharmaceuticalProduct: PharmaceuticalProduct;
  public pharmaceuticalTreatmentId: number;
  public description: string;
  public startDateTime: moment.Moment | null;
  public endDateTime: moment.Moment | null;
  public actor: string;
  public stopped: boolean;
  public dosingChanged: boolean;
  public temporaryStop: boolean;
  public afterLastVerificationDate: boolean;
  public comment: string | null;

  constructor(id: number, type: string, typeIcon: string, pharmaceuticalProduct: PharmaceuticalProduct, pharmaceuticalTreatmentId: number, description: string, startDateTime: moment.Moment | null = null, endDateTime: moment.Moment | null = null, actor: string, stopped: boolean, dosingChanged: boolean, temporaryStop: boolean, afterLastVerificationDate: boolean, comment: string | null = null) {
    this.id = id;
    this.type = type;
    this.typeIcon = typeIcon;
    this.pharmaceuticalProduct = pharmaceuticalProduct;
    this.pharmaceuticalTreatmentId = pharmaceuticalTreatmentId;
    this.description = description;
    this.startDateTime = startDateTime;
    this.endDateTime = endDateTime;
    this.actor = actor;
    this.stopped = stopped;
    this.dosingChanged = dosingChanged;
    this.temporaryStop = temporaryStop;
    this.afterLastVerificationDate = afterLastVerificationDate;
    this.comment = comment;
  }

  public static fromJson(json: unknown): MedicationVerificationItem {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new MedicationVerificationItem(
      parsedJson['id'] as number,
      parsedJson['type'] as string,
      parsedJson['typeIcon'] as string,
      PharmaceuticalProduct.fromJson(parsedJson['pharmaceuticalProduct']),
      parsedJson['pharmaceuticalTreatmentId'] as number,
      parsedJson['description'] as string,
      parsedJson['startDateTime'] ? moment.parseZone(parsedJson['startDateTime']) : null,
      parsedJson['endDateTime'] ? moment.parseZone(parsedJson['endDateTime']) : null,
      parsedJson['actor'] as string,
      parsedJson['stopped'] as boolean,
      parsedJson['dosingChanged'] as boolean,
      parsedJson['temporaryStop'] as boolean,
      parsedJson['afterLastVerificationDate'] as boolean,
      parsedJson['comment'] as string,
    );
  }

  public static multipleFromJson(json: unknown): MedicationVerificationItem[] {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    const items: MedicationVerificationItem[] = [];
    for (const item of parsedJson) {
      items.push(this.fromJson(item));
    }
    return items;
  }
}
