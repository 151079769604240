<template>
    <div class="col-12 text-left nav-search-divider"
         ref="nav-search-divider"
    >
        <div class="capitalize-first">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
  components: {},

  props: {
    //
  },

  mixins: [],

  data: function () {
    return {
      //
    };
  },

  computed: {
    //
  },

  created() {
    //
  },

  mounted() {
    //
  },
  unmounted() {
    //
  },

  methods: {},

  watch: {}
};
</script>
