<template>
  <div>
    <div id="schema-form" v-if="manual_dosing_schema_decoded">
      <time-header
          :read_only="read_only"
          :extra_input_column="false"
          :show_first_column="true"
          :show_last_column="!read_only && (times.length < 6 || lgPlus)"
          :use_standard_times="use_standard_times"
          @change_time="changeTime"
          @add_time="addTime"
          @delete_time="deleteTime"
          :status_id="status_id"
          :times="times">
      </time-header>
      <date-row v-for="(date, index) in dates"
                :read_only="read_only"
                @navigate="navigate"
                @update_selected_field="updateSelectedField"
                @input="updateInput"
                @remove_date="removeDate"
                :field_with_focus="fieldWithFocus"
                :dates="dates"
                :date="date"
                :index="index"
                :times="times"
                :use_fixed_date="useFixedDate"
                :create_template="create_template"
                :key="date"
                :values="values"
                @create_new_row="addDate"
                :is_last_row="index === (dates.length - 1)"
                :dosage_instruction="dosage_instruction"
      ></date-row>
      <next-date-and-clear-buttons-row
          v-if="!read_only"
          :create_template="create_template"
          :use_fixed_date="useFixedDate"
          @add_date="addDate"
          @clear="clear"
          :start-date-time="start_datetime"
          :dates="dates"
          :times="times"
          :dosage_instruction="dosage_instruction"
      >
      </next-date-and-clear-buttons-row>
      <textarea v-show="false" name="manualdosing-values" id="manualdosing-values" v-model="jsonState"/>
    </div>
  </div>
</template>

<script>

import TimeHeader from '@/vue/components/status/dosage-instructions/manual-dosing-schema/TimeHeader';
import DateRow from './DateRow';
import Utility from '@/vue/utility/utility';
import Times from '@/vue/utility/times';
import moment from 'moment-mini';
import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import NextDateAndClearButtonsRow from './NextDateAndClearButtonsRow';
import UsesMediaQueries from '@/vue/mixins/UsesMediaQueries';

export default {

  components: {
    NextDateAndClearButtonsRow,
    DateRow,
    TimeHeader
  },

  props: {
    read_only: {default: false},
    manual_dosing_schema_decoded_raw: {default: null},
    manual_dosing_schema_decoded_as_json: {default: null},
    create_template: {default: false},
    start_datetime_prop: {default: null},
    status_id: {default: 0},
    store_id_prop: {default: null},
    use_standard_times: {default: false},
    dosage_instruction: {default: false}
  },

  mixins: [
    BaseComponentsMixin,
    UsesMediaQueries,
  ],

  data: function () {
    return {
      numberOfTimes: 0,
      fieldWithFocus: {timeIndex: 0, dateIndex: 0},
      useFixedDate: true,
      store_id: null,
      start_datetime: null,
    };
  },

  computed: {
    jsonState: function () {

      const this2 = this;
      const state = {};
      this.dates.forEach(function (date) {
        const timeQuantities = {};
        this2.times.forEach(function (time) {
          const valueIndex = Utility.find_index_of_matching_elements(this2.values, 'time', time, 'date', date);
          if (valueIndex === -1) {
            timeQuantities[Times.decodeTime(time)] = 0;
          } else {
            timeQuantities[Times.decodeTime(time)] = this2.values[valueIndex].value;
          }

        });

        state[date] = timeQuantities;
      });

      return JSON.stringify(state);
    },
    dates: function () {
      if (this.manual_dosing_schema_decoded) {
        return this.manual_dosing_schema_decoded.dates;
      } else return [];
    },
    times: function () {
      if (this.manual_dosing_schema_decoded) {
        return this.manual_dosing_schema_decoded.times.sort(function (a, b) {
          return parseInt(a) - parseInt(b);
        });
      } else return [];
    },
    values: function () {
      if (this.manual_dosing_schema_decoded) {
        return this.manual_dosing_schema_decoded.values;
      } else return [];
    },
    manual_dosing_schema_decoded: function () {
      if (this.store_id) {
        return this.$store.getters['manual_dosing_schemas/find_shadow_model'](this.store_id);
      }
    }
  },

  created() {
    if (this.dosage_instruction) {
      this.store_id = this.dosage_instruction.id;
      this.start_datetime = this.dosage_instruction.start_moment_datetime;

      if (!this.manual_dosing_schema_decoded) {

        const startMoment = moment(this.dosage_instruction.start_moment_datetime, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD');

        let times = this.dosage_instruction.times.map(timeValue => {
          return timeValue.time;
        });
        times = times.filter(time => {
          return time !== '';
        });

        let values = this.dosage_instruction.times.filter(
          timeValue => {
            return timeValue.time !== '' && timeValue.value !== '' && timeValue.value !== '0';
          });

        values = values.map(value => {
          value.date = startMoment;
          return value;
        });

        const manual_dosing_schema_decoded = {
          dates: [startMoment],
          times: times,
          values: values,
          id: this.dosage_instruction.id
        };

        const vm = this;
        this.$store.dispatch('manual_dosing_schemas/create', manual_dosing_schema_decoded)
          .then(function (newId) {
            vm.store_id = newId;
            vm.initEventListeners();
          });
      }

    } else {
      if (!this.start_datetime_prop) {
        this.start_datetime = moment().startOf('day');
      } else {
        this.start_datetime = this.start_datetime_prop;
      }

      if (!this.store_id_prop) {
        let manual_dosing_schema_decoded = this.manual_dosing_schema_decoded_raw;
        if (this.manual_dosing_schema_decoded_as_json) {
          manual_dosing_schema_decoded = JSON.parse(this.manual_dosing_schema_decoded_as_json);
        }
        if (!manual_dosing_schema_decoded) {
          manual_dosing_schema_decoded = {
            dates: [moment().format('YYYY-MM-DD')],
            times: ["800", "1200", "1600", "2200"],
            values: []
          };
        }

        const vm = this;
        this.$store.dispatch('manual_dosing_schemas/create', manual_dosing_schema_decoded)
          .then(function (newId) {
            vm.store_id = newId;
            vm.initEventListeners();
          });
      } else {
        this.store_id = this.store_id_prop;
      }
    }

    this.fieldWithFocus.timeIndex = 0;
    this.fieldWithFocus.dateIndex = 0;
  },

  mounted() {
  },

  methods: {
    updateSelectedField(payload) {
      const time = payload.time;
      const date = payload.date;

      const timeIndex = this.times.indexOf(time);
      const dateIndex = this.dates.indexOf(date);

      this.fieldWithFocus.timeIndex = timeIndex;
      this.fieldWithFocus.dateIndex = dateIndex;
    },
    initEventListeners: function () {
      const vm = this;

      $(document).on('medimo.updateStartDateTime', function (e, response) {
        const startDateMoment = moment(response.startDateTime, 'DD-MM-YYYY HH:mm');
        vm.updateStartDateTime(startDateMoment);
      });

      $(document).on('medimo.updateUseStandardTimes', function (e, response) {
        vm.use_standard_times = response.useStandardTimes;
      });

      // for protocol status template
      $(document).on('medimo.useFlexDate', function (e, response) {
        vm.useFixedDate = false;
      });

      // Handle provisional cases
      if ($('#isProvisional').val()) {
        $(document).on('medimo.updateUseFixedDate', function (e, response) {
          vm.useFixedDate = response.useFixedDate;
          vm.updateStartDateTimeOnProvisionals();
        });

        if ($('#checkbox_fixedDate').length > 0) {

          const useFixedDate = $('#checkbox_fixedDate').is(":checked");
          if (useFixedDate !== undefined) {
            vm.useFixedDate = useFixedDate;
            vm.updateStartDateTimeOnProvisionals();
          }
        } else if ($('#forceFixedDate').val() == 1) {
          vm.useFixedDate = true;
        } else {
          vm.useFixedDate = false;
        }
      }

      $(document).on('medimo.vueSchemaUpdateTimes', function (e, response) {
        vm.$store.dispatch('manual_dosing_schemas/set_times_and_amounts', {
          id: vm.store_id,
          times: response.timesAndAmounts.times,
          amounts: response.timesAndAmounts.amounts
        });
      });

      $(document).on('medimo.updateVueManualSchema', function (e, response) {

        const vueState = JSON.parse(response.vueState);
        const newDates = Object.keys(vueState);
        if (newDates.length > 0) {
          const newTimes = Object.keys(vueState[newDates[0]]);
          const newValues = [];

          for (let i = 0; i < newDates.length; i++) {
            const newTimes = Object.keys(vueState[newDates[i]]);
            for (let j = 0; j < newTimes.length; j++) {
              newValues.push({
                date: newDates[i],
                time: Times.decodeTime(newTimes[j]),
                value: vueState[newDates[i]][newTimes[j]]
              });
            }
          }

          vm.$store.dispatch('manual_dosing_schemas/set_schema', {
            id: vm.store_id,
            values: newValues,
            times: newTimes,
            dates: newDates
          });
        }
      });

      $(document).trigger('medimo.manualDosingVue.loaded');
    },
    updateStartDateTimeOnProvisionals: function () {
      if (this.useFixedDate) {
        const startDateMoment = moment($('#startdatetime').val(), 'DD-MM-YYYY HH:mm');
        this.updateStartDateTime(startDateMoment);
      } else {
        this.updateStartDateTime(moment());
      }
    },
    clear: function () {
      this.$store.dispatch('manual_dosing_schemas/clear', this.store_id);
    },
    changeTime: function (payload) {
      const newTime = payload.newTime;
      const oldTime = payload.oldTime;

      this.$store.dispatch('manual_dosing_schemas/update_time', {
        id: this.store_id,
        newTime: newTime,
        oldTime: oldTime
      });
    },
    deleteTime: function (time) {

      this.$store.dispatch('manual_dosing_schemas/delete_time', {
        id: this.store_id,
        time: time,
      });
    },
    addTime: function (time) {
      this.$store.dispatch('manual_dosing_schemas/add_time', {
        id: this.store_id,
        time: time
      });
    },
    addDate: function () {
      if (this.dates.length >= 31) {
        this.$store.dispatch('notifications/addDangerAlert', {title: 'U kunt maximaal 31 dagen voorschrijven'});
        return;
      }

      const lastDate = this.dates[this.dates.length - 1];
      const nextDate = moment(lastDate, 'YYYY-MM-DD').add(1, 'days');
      const values = Utility.find_models_by_property(this.values, 'date', lastDate);

      this.$store.dispatch('manual_dosing_schemas/add_date', {
        id: this.store_id,
        date: nextDate.format('YYYY-MM-DD'),
        values: values,
      });
    },
    removeDate: function (date) {
      this.$store.dispatch('manual_dosing_schemas/remove_date', {
        id: this.store_id,
        date: date
      });
    },
    updateInput: function (payload) {
      const time = payload.time;
      const date = payload.date;
      const value = payload.value;

      this.$store.dispatch('manual_dosing_schemas/set_value',
        {
          id: this.store_id,
          time: time,
          date: date,
          value: value,
        });
    },
    navigate: function (offsets) {
      // Disabled, and has been since 2020
      // Ran into it again upgrading to Vue3
      // Disabling it at the root now.
      return null;
    },
    updateStartDateTime(newStartDateTime) {

      if (!newStartDateTime._isAMomentObject) {
        newStartDateTime = moment(newStartDateTime, 'YYYY-MM-DD HH:mm');
      }

      //Only update if we have a new start datetime
      if (this.dates[0] === newStartDateTime.format('YYYY-MM-DD')) {
        return;
      }

      const oldDates = Utility.deep_clone(this.dates);
      const newDates = [];

      const oldValues = Utility.deep_clone(this.values);
      const newValues = [];

      for (let i = 0; i < oldDates.length; i++) {
        const newDate = newStartDateTime.clone().add(i, "days");
        newDates.push(newDate.format('YYYY-MM-DD'));

        //replace old date in index for new date
        const valueEntries = Utility.find_models_by_property(oldValues, 'date', moment(oldDates[i], 'YYYY-MM-DD').format('YYYY-MM-DD'));
        valueEntries.forEach(function (valueEntry) {
          newValues.push({time: valueEntry.time, date: newDate.format('YYYY-MM-DD'), value: valueEntry.value});
        });
      }

      this.$store.dispatch('manual_dosing_schemas/set_values', {id: this.store_id, values: newValues});
      this.$store.dispatch('manual_dosing_schemas/set_dates', {id: this.store_id, dates: newDates});
    },
  },

  watch: {
    times: function () {
      this.numberOfTimes = this.times.length;
    },
    manual_dosing_schema_decoded: function () {
      if (!this.dosage_instruction) {
        return;
      }

      if (this.dosage_instruction.continuous_schema) {
        this.dosage_instruction.stop_datetime = null;
      } else {
        this.dosage_instruction.stop_datetime = this.$store.getters['manual_dosing_schemas/get_stop_datetime'](this.dosage_instruction.id);
      }
    },
    startDateTime: function (value) {
      this.updateStartDateTime(value);
    }
  }
};
</script>
