<template>
  <div v-if="signalData && signalData.protocolId" class="mt-3">
    <a class="clickable" @click="showMfbProtocolViewer = true">Bekijk MFB-protocol</a>
    <medi-modal :size="size" v-if="showMfbProtocolViewer" @closed="showMfbProtocolViewer = false" title="Bekijk het MFB-protocol">
      <mfb-protocol-viewer
          :protocol_id="signalData.protocolId"
          :release_id="signalData.protocolReleaseId"
          :trace="traceData"
          @update_size="updateSize"
      ></mfb-protocol-viewer>
    </medi-modal>
  </div>
</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import MfbProtocolViewer from '@/vue/components/mfb/MfbProtocolViewer';
import MediModal from '@/vue/components/general/MediModal';

export default {
  components: {MediModal, MfbProtocolViewer},

  props: {
    'signal_json': {required: false},
    'signal': {required: false},
    'trace': {required: false},
  },

  mixins: [BaseComponentsMixin],

  data: function () {
    return {
      showMfbProtocolViewer: false,
      signalData: false,
      traceData: false,
      size: 'medium'
    };
  },

  computed: {
    //
  },

  created() {
    if (this.signal_json) {
      this.signalData = JSON.parse(this.signal_json);
      this.traceData = this.signalData.trace;
    }
    else {
      this.signalData = this.signal;
      this.traceData = this.trace;
    }
  },

  mounted() {
    //
  },

  methods: {
    updateSize(size) {
      this.size = size;
    }
  },

  watch: {
    //
  }
};
</script>
