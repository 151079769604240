import BaseModule from '@/vue/store/modules/base_module';

const state = {
  ...BaseModule.state,
  search_endpoint: '/api/v1/logs/meetwaarden',
  quick_filters: {'Fouten': false},
  sort_columns: [
    {column_name: 'receiveTimestamp', direction: 'DESC'}
  ],
};

const getters = { ...BaseModule.getters };
const actions = {
  ...BaseModule.actions,
  postPatient({state, commit, dispatch, getters, rootState}, payload) {
    return dispatch('api/postEndpoint', payload, {root: true}).then(response => {
      commit('update_selected_search_result', response.data);

      return response.data;
    });
  }
};
const mutations = { ...BaseModule.mutations };

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
