<template>
  <medi-modal
      title="Import historie"
      size="large"
      :patient_id="patient_id"
      @closed="$emit('closed')"
  >
    <medimo-external-data-table
        :name="'edi-log-data-table'"
        :store_name="store_name"
        :number_of_rows="logs.length"
        :minimum_height="true"
        :privacy_filter="false"
        quick_filter_buttons_class="col-md-5"
    >
      <medimo-thead>
        <medimo-tr>
          <medimo-th column_name_1="datum/tijd" :store_name="store_name" sort_column_1="created_at" column_classes="col-sm-2"></medimo-th>
          <medimo-th column_name_1="Apotheek" :store_name="store_name" sort_column_1="pharmacy.naam"></medimo-th>
          <medimo-th column_name_1="Melding" :store_name="store_name" sort_column_1="message"></medimo-th>
          <medimo-th column_name_1="Succesvol" :store_name="store_name" sort_column_1="success" column_classes="col-sm-2"></medimo-th>
        </medimo-tr>
      </medimo-thead>
      <medimo-tbody>
        <template v-for="log in logs" :key="log.id">
          <medimo-tr :link="(log.link_to_data ?? '')" target="_blank">
            <medimo-td column_classes="col-sm-2"> {{ log.created_at }}</medimo-td>
            <medimo-td> {{ log.pharmacy }}</medimo-td>
            <medimo-td> {{ log.message }}</medimo-td>
            <medimo-td column_classes="col-sm-2"> {{ log.success }}</medimo-td>
          </medimo-tr>
        </template>
      </medimo-tbody>
    </medimo-external-data-table>
  </medi-modal>
</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';

export default {

  props: [
    'patient_id'
  ],

  mixins: [
    BaseComponentsMixin
  ],

  data: function () {
    return {
      store_name: 'log_import',
    };
  },

  created() {
    this.$store.commit(this.store_name + '/set_search_endpoint', '/api/v1/logs/import/' + this.patient_id);
  },

  computed: {
    logs() {
      return this.$store.getters[this.store_name + '/search_results'];
    }

  },

  methods: {

  },

  watch: {
    //
  }

};
</script>
