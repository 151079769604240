<template>
  <medi-modal :loading="loading"
              :title="'Toestemming voor ' + title"
              size="medium"
              @closed="close">

      <optin-history-modal :patient_id="patient.id" v-if="show_history" @closed="show_history = false">
      </optin-history-modal>

    <template v-if="show_summary % 2 != 0">
      <medimo-form-row>
        <medimo-form-column column_classes="col-3"><b>Huidige optin</b></medimo-form-column>
      </medimo-form-row>
      <template v-if="optin.direction !== undefined">
        <medimo-form-row>
          <medimo-form-column column_classes="col-3">Toegang</medimo-form-column>
          <medimo-form-column>{{ optin.direction == 'ALLOW' ? 'Toestaan' : 'Weigeren' }}</medimo-form-column>
        </medimo-form-row>
        <medimo-form-row>
          <medimo-form-column column_classes="col-3">Startdatum</medimo-form-column>
          <medimo-form-column>{{ formatValidUntilDate(optin.start_date) }}</medimo-form-column>
        </medimo-form-row>
        <medimo-form-row>
          <medimo-form-column column_classes="col-3">Einddatum</medimo-form-column>
          <medimo-form-column>{{ optin.end_date != null ? formatValidUntilDate(optin.end_date) : 'geen' }}</medimo-form-column>
        </medimo-form-row>
      </template>
      <template v-else>
        <medimo-form-row>
          <medimo-form-column>Onbekend of {{ language.patient }} toestemming wil geven voor het LSP</medimo-form-column>
        </medimo-form-row>
      </template>
    </template>
    <br>
    <template v-if="show_tokens % 2 != 0">
      <medimo-form-row>
        <medimo-form-column column_classes="col-3"><b>Inschrijftoken</b></medimo-form-column>
      </medimo-form-row>
      <medimo-form v-if="validToken">
        <medimo-form-row>
          <medimo-form-column column_classes="col-3">Aangemaakt door</medimo-form-column>
          <medimo-form-column>{{ validToken.username }}</medimo-form-column>
        </medimo-form-row>
        <medimo-form-row>
          <medimo-form-column column_classes="col-3">Geldig tot</medimo-form-column>
          <medimo-form-column>{{ formatValidUntilDate(validToken.valid_until) }}</medimo-form-column>
        </medimo-form-row>
      </medimo-form>
      <medimo-form v-else>
        <medimo-form-row>
          <medimo-form-column>Geen inschrijftoken aangemaakt</medimo-form-column>
        </medimo-form-row>
      </medimo-form>
    </template>

    <template v-if="show_optin % 2 != 0">
      <medimo-form>
        <medimo-form-row>
          <medimo-form-column column_classes="col-12 col-sm-6">
            <medimo-labeled-vue-select2
                class="lsp-optin-toestaan"
                label_column_classes="col-12 col-sm-4"
                select2_column_classes="col-12 col-sm-8"
                label="Toestemming"
                placeholder="Maak een keuze"
                v-model="direction"
                :options="[
                            {value: '', text: ''},
                            {value: 'ALLOW', text: 'Toestaan'},
                            {value: 'DENY', text: 'Weigeren'},
                    ]"></medimo-labeled-vue-select2>
          </medimo-form-column>
        </medimo-form-row>

        <medimo-form-row>
          <medimo-form-column column_classes="col-12">
            <medimo-labeled-textarea
                label_column_classes="col-12 col-sm-2"
                label="Info"
                name="info"
                v-model="info"
            ></medimo-labeled-textarea>
          </medimo-form-column>
        </medimo-form-row>

        <medimo-form-row>
          <medimo-form-column column_classes="col-12 col-sm-6">
            <medimo-labeled-vue-select2
                label_column_classes="col-12 col-sm-4"
                select2_column_classes="col-12 col-sm-8"
                label="Informatieset"
                v-model="information_version"
                help_text="Versie van de folder waar de client mee akkoord is gegaan"
                :options="[
                                  {value: '0', text: 'Onbekend'},
                                  {value: '1', text: 'maart 2019'},
                                  {value: '2', text: 'volgjezorg mei 2021'}
                                ]">
            </medimo-labeled-vue-select2>
          </medimo-form-column>
        </medimo-form-row>

        <medimo-form-row>
          <medimo-form-column column_classes="col-12 col-sm-6">
            <medimo-labeled-date-time-picker
                label_column_classes="col-12 col-sm-4"
                label="Startdatum"
                name="startDate"
                datetime_format="DD-MM-YYYY HH:mm"
                v-model="start_date"
            ></medimo-labeled-date-time-picker>
          </medimo-form-column>
        </medimo-form-row>

        <medimo-form-row>
          <medimo-form-column column_classes="col-12 col-sm-6">
            <medimo-labeled-date-time-picker
                label_column_classes="col-12 col-sm-4"
                label="Einddatum"
                name="endDate"
                datetime_format="DD-MM-YYYY HH:mm"
                v-model="end_date"
            ></medimo-labeled-date-time-picker>
          </medimo-form-column>
        </medimo-form-row>
      </medimo-form>
    </template>

        <template v-slot:action-buttons>
            <medimo-button class="mr-2" @click="show_optin++; show_tokens++; show_summary++">
              Nieuwe optin
            </medimo-button>
            <medimo-button class="mr-2" type="danger" v-if="validToken" :disabled="invalidate_button_loading" @click="invalidateToken">
              Inschrijftoken intrekken
            </medimo-button>
            <medimo-button class="mr-2" v-if="!validToken" :disabled="create_button_loading" @click="createToken">
              Inschrijftoken aanmaken
            </medimo-button>
            <medimo-button type="outline-primary" class="mr-md-auto" @click="show_history = true">
                Historie
            </medimo-button>

            <medimo-button @click="updateOptin" :disabled="save_button_disable" v-if="show_tokens % 2 == 0">
                Opslaan
            </medimo-button>
            <medimo-button @click="close">
                Sluiten
            </medimo-button>
        </template>
    </medi-modal>
</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import MediModal from '@/vue/components/general/MediModal';
import EditOptInModal from '@/vue/components/patients/optin/EditOptInModal';
import MedimoButton from '@/vue/components/general/MedimoButton';
import moment from 'moment-mini';
import MedimoForm from '@/vue/components/general/form/MedimoForm';
import MedimoFormRow from '@/vue/components/general/form/base/MedimoFormRow';
import MedimoFormColumn from '@/vue/components/general/form/base/MedimoFormColumn';
import MedimoLabeledDateTimePicker from '@/vue/components/general/form/base/MedimoLabeledDateTimePicker';
import MedimoLabeledTextarea from '@/vue/components/general/form/base/MedimoLabeledTextarea';
import MedimoLabeledSelect2 from '@/vue/components/general/form/base/MedimoLabeledSelect2';
import MedimoSelect2 from '@/vue/components/general/form/base/MedimoSelect2';
import OptinHistoryModal from './OptinHistoryModal';
import ZorgIdConnector from '/modules/zorg-id/ZorgIdConnector';
import MedimoLabeledVueSelect2 from '@/vue/components/general/form/base/vue-select2/MedimoLabeledVueSelect2';

export default {
  emits: ['closed'],

  components: {
    MedimoLabeledVueSelect2,
    OptinHistoryModal,
    MedimoSelect2,
    EditOptInModal,
    MediModal,
    MedimoForm,
    MedimoFormRow,
    MedimoFormColumn,
    MedimoLabeledDateTimePicker,
    MedimoLabeledTextarea,
    MedimoLabeledSelect2,
    MedimoButton
  },

  props: [
    'patient',
    'title',
    'type'
  ],

  mixins: [
    BaseComponentsMixin
  ],

  data: function () {
    return {
      invalidate_button_loading: false,

      show_optin: 0, // Optin view
      show_tokens: 1, // Of we de tokenview willen tonen
      show_history: false, // Of we de historyview willen tonen
      show_summary: 1, // Samenvatting

      trigger_modal_optin: false,
      loading: false,
      save_button_disable: true,
      create_button_loading: false,
      store_name: 'lsp_token',

      token_modal_trigger: false,
      direction: '',
      information_version: '0',
      info: '',
      start_date: '',
      end_date: '',
    };
  },

  computed: {
    language() {
      return this.$store.getters['current_user/language'];
    },
    validToken() {
      return this.$store.getters["lsp_token/validPatientToken"](this.patient.id);
    },

    save_button_disabled() {

      // toelichting vereist
      if (this.explanation_visible && !this.explanation.length) {
        return true;
      }
      // message empty
      if (this.active_view === 'history' && !this.message.length) {
        return true;
      }
      // date in past, or less then validUntil
      const nextValid = moment(this.next_valid_until_date);
      if (nextValid.isBefore() || nextValid.isBefore(this.action.validUntil)) {
        return true;
      }

      return false;
    },

    inschrijftoken_show_button() {
      // Bepaal adhv de koppelvlak instelling of we de knop wel of niet willen tonen.
      return this.$store.state.current_user.data.cluster_permissions.webservices.showInschrijftokens;
    },

    inschrijftoken_button_type() {
      if (this.show_tokens % 2 == 0) {
        return 'primary';
      }
      return 'outline-primary';
    },

    optin() {
      const optins = this.$store.state.optin.optins[this.patient.id];

      if (typeof optins === 'undefined') {
        return {};
      }

      const optin = optins[this.type];

      if (typeof optin === 'undefined') {
        return {};
      }

      return optin;
    },
  },

  created() {
  },

  mounted() {
    this.fetchOptin();
    this.$store.dispatch(
      "lsp_token/fetch", {patientId: this.patient.id}
    );
  },

  unmounted() {
    //
  },

  methods: {
    close() {
      this.$emit('closed');
    },

    async createToken() {
      const hasZorgIdSession = this.$store.state.current_user.data.uzi_card != '';
      const zc = new ZorgIdConnector(window.notifier, window.medimo.WebSocket);

      this.create_button_loading = true;

      // Detecteren wel of geen sessie
      if (hasZorgIdSession) {
        this.executeCreateToken();
      } else {
        notifier.showSuccess("ZorgID wordt gestart");
        zc.loginBackground(() => {
          this.executeCreateToken();
        });
      }
    },

    invalidateToken() {
      const hasZorgIdSession = this.$store.state.current_user.data.uzi_card != '';
      const zc = new ZorgIdConnector(window.notifier, window.medimo.WebSocket);

      this.invalidate_button_loading = true;
      const tokenId = this.validToken.id;

      // Detecteren wel of geen sessie
      if (hasZorgIdSession) {
        this.executeInvalidateToken(tokenId);
      } else {
        notifier.showSuccess("ZorgID wordt gestart");
        zc.loginBackground(() => {
          this.executeInvalidateToken(tokenId);
        });
      }
    },

    /**
     * Daadwerkelijk aanmaken.
     */
    executeCreateToken() {
      this.$store.dispatch("lsp_token/createToken", {patientId: this.patient.id})
        .then(result => {
          this.create_button_loading = false;
        }).catch(error => {
          this.create_button_loading = false;
        });
    },

    /**
     * Daadwerkelijk verwijderen
     */
    executeInvalidateToken(tokenId) {
      this.$store.dispatch(
        "lsp_token/invalidateToken",
        {patientId: this.patient.id, tokenId: tokenId}).then(result => {
        this.invalidate_button_loading = false;
      }).catch(error => {
        this.invalidate_button_loading = false;
      });
    },

    closed() {
      this.$emit('closed');
    },

    formatValidUntilDate(validUntil) {
      return moment(validUntil).format('DD-MM-YYYY');
    },

    fetchOptin() {
      // only fetch when not already available
      if (this.optin.id) {
        return;
      }

      this.loading = true;

      this.$store.dispatch('optin/fetch', {
        patient_id: this.patient.id
      }).then(() => {
        this.loading = false;
        this.updateFormValues();
      }).catch(() => {
        this.loading = false;
        this.updateFormValues();
      });
    },

    updateOptin() {
      this.loading = true;

      this.$store.dispatch('optin/update', {
        patient_id: this.patient.id,
        data: {
          type: this.type,
          direction: this.direction,
          info: this.info,
          start_date: this.start_date,
          end_date: this.end_date,
          information_version: this.information_version
        }
      }).then(() => {
        this.loading = false;
        this.close();
      }).catch(() => {
        this.loading = false;
      });
    },

    updateFormValues() {
      this.direction = this.optin.direction;
      this.info = this.optin.info;

      if (this.optin.start_date) {
        this.start_date = this.optin.start_date;
      }
      if (this.optin.end_date) {
        this.end_date = this.optin.end_date;
      }
      if (this.optin.information_version) {
        this.information_version = this.optin.information_version;
      }
    },

    checkFormValuesChanged() {

      const start_date = moment(this.start_date, 'DD-MM-YYYY HH:mm');
      const start_date_optin = moment(this.optin.start_date, 'DD-MM-YYYY HH:mm');

      const end_date = moment(this.end_date, 'DD-MM-YYYY HH:mm');
      const end_date_optin = moment(this.optin.end_date, 'DD-MM-YYYY HH:mm');

      this.save_button_disable = (
        this.direction === this.optin.direction
          && this.info === this.optin.info
          && start_date.toISOString() === start_date_optin.toISOString()
          && end_date.toISOString() === end_date_optin.toISOString()
          && this.optin.information_version === this.information_version
      );
    },

    triggerToken() {
      this.token_modal_trigger = !this.token_modal_trigger;
    }
  },

  watch: {
    direction(value) {
      this.checkFormValuesChanged();
    },
    info(value) {
      this.checkFormValuesChanged();
    },
    start_date(value) {
      this.checkFormValuesChanged();
    },
    end_date(value) {
      this.checkFormValuesChanged();
    },
    information_version(value) {
      this.checkFormValuesChanged();
    }
  }
};
</script>
