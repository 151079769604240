<template>
    <span :class="'medimo-sort-button ' + margin"
          @click="sortToggle()">
        <!-- Down and Down animation Arrow -->
        <fa-icon icon="fa-solid fa-caret-down" class="text-medimo hoverable" v-bind:class="{'active': sort_by === 'DESC'}" />
        <fa-icon icon="fa-solid fa-caret-down" class="text-medimo hoverable" v-if="desc_clicked" v-bind:class="{'active': sort_by === 'DESC', 'animate': desc_clicked}" />

        <!-- Up and Up animation Arrow -->
        <fa-icon icon="fa-solid fa-caret-up" class="text-medimo hoverable" v-bind:class="{'active': sort_by === 'ASC'}" />
        <fa-icon icon="fa-solid fa-caret-up" class="text-medimo hoverable" v-if="asc_clicked" v-bind:class="{'active': sort_by === 'ASC', 'animate': asc_clicked}" />
    </span>
</template>

<script>
export default {
  emits: ['input'],

  props: {
    value: {default: ''},
    margin: {default: 'ml-2'},

    // Set this to TRUE if you want to allow users to sort by multiple columns, in the order the "activate" them.
    multi_sort: {default: false},
  },
  data: function () {
    return {
      // Can be ASC or DESC but is empty by default
      sort_by: '',

      // Triggers the animation on the triangle clicks
      desc_clicked: false,
      asc_clicked: false,
    };
  },
  mounted() {
    this.sort_by = this.value;
  },

  methods: {
    sortToggle() {
      if (this.sort_by === '') {
        this.sort_by = 'ASC';
      } else if (this.sort_by === 'ASC') {
        this.sort_by = 'DESC';
      } else {
        this.sort_by = '';
      }
    },
    sortBy(value) {
      if (!this.multi_sort) {
        return this.sortToggle();
      }

      // Clear the Sort if it's identical
      if (this.sort_by === value) {
        this.sort_by = '';
      } else {
        this.sort_by = value;
      }
    },
    animateArrow(direction) {
      this[direction + '_clicked'] = true;
      setTimeout(() => {
        // This toggles the animation on the icons
        this[direction + '_clicked'] = false;
      }, 350);
    }
  },

  watch: {
    // When this changes, we emit the value
    sort_by(value) {
      // And now for the animation:
      if (value === 'DESC') {
        this.animateArrow('desc');
      } else if (value === 'ASC') {
        this.animateArrow('asc');
      } else if (value === '') {
        this.animateArrow('asc');
        this.animateArrow('desc');
      }
      this.$emit('input', this.sort_by);
    },
    value(value) {
      this.sort_by = value;
    },
  }
};
</script>

<style scoped lang="scss">
.medimo-sort-button {
  position: relative;

  svg.svg-inline--fa {
    // We need this because the icons aren't to the edge of the space they inhabit, this aligns them properly
    left: 2px;
    opacity: 0.25;
    position: absolute;

    &.fa-caret-up {
      top: 0;
    }

    &.fa-caret-down {
      top: 6px;
    }

    &.active {
      opacity: 1;
    }
  }
}

.animate {
  animation-name: scaleAndFade;
  animation-duration: 0.35s;
  animation-timing-function: ease-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
}

@keyframes scaleAndFade {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.8);
    opacity: 0;
  }
}
</style>
