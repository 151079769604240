export default {
  encodeTime: function (time) {
    if (time === "") {
      return "";
    }
    //parse time from 09:00 to 900
    //and parseInt to remove zero from start
    time = ""+parseInt(time.replace(':', ''));

    //Add zero if time is in minutes, eg 00:55
    if (time.length === 2) {
      return '0' + time;
    }
    //Add zeros if time is in minutes, like 00:08
    else if (time.length === 1) {
      return '00' + time;
    }
    return time;
  },
  decodeTime: function (time) {
    // Om 0.15 of .15 als tijd te ondersteunen vervangen we de . met een :
    time = time.replace('.', ':');

    //parse time from 0900 to 09:00(
    if (time.includes(':')) {
      return time;
    }
    else if (time.length === 4) {
      return time[0] + time[1] + ':' + time[2] + time[3];
    }
    else if (time.length === 3) {
      return '0' + time[0] + ':' + time[1] + time[2];
    }
    else if (time.length === 2) {
      return time[0] + time[1] + ':00';
    }
    else if (time.length === 1) {
      return '0' + time[0] + ':00';
    }
    else {
      return time;
    }
  },
  validateTime: function (time) {
    const encoded = this.encodeTime(time);
    const timeString = time.replace(":", '');

    if (timeString === '') {
      return true;
    }

    if (isNaN(timeString)) {
      return false;
    }
    else if (timeString.length > 4) {
      return false;
    }
    //eg 56
    else if (timeString.length === 2 && parseInt(timeString)>23) {
      return false;
    }
    else if (encoded < 0 || encoded >= 2400) {
      return false;
    }
    // Wat hier mis kan (kon, na deze fix) was dat een tijd als 2280 geaccepteerd werd. Terwijl
    // de minuten natuurlijk nooit meer dan 59 kunnen zijn. We pakken nu de laatste 2 cijfers en controleren die.
    // Omdat de string 3 of 4 karakters kan zijn, met een klein beetje logic.
    else if (parseInt(encoded[(encoded.length - 2)] + encoded[(encoded.length - 1)]) > 59) {
      return false;
    }
    return true;
  },
};
