import {GeneratedPharmaceuticalProduct} from '@/domain/models/generated/GeneratedPharmaceuticalProduct';

export class PharmaceuticalProduct extends GeneratedPharmaceuticalProduct {
  static readonly notInTaxeId = 90000001;

  public notInTaxeProduct(): boolean {
    return this.drugId === PharmaceuticalProduct.notInTaxeId;
  }

  public reference(): string {
    if (this.zi) {
      return 'zi ' + this.zi;
    }

    if (this.hpk) {
      return 'hpk ' + this.hpk;
    }

    if (this.prk) {
      return 'prk ' + this.prk;
    }

    if (this.isMagistralPreparation) {
      return 'e.p.';
    }

    return '';
  }
}
