import Utility from '@/vue/utility/utility';
import BaseModule from '@/vue/store/modules/base_module';

// initial state
// shape: [{ id, quantity }]
const state = {
  ...BaseModule.state,
  data: [],

  search_columns: [
    // 'permissions.naam',
  ],

  sort_columns: [
    {column_name: 'description', direction: 'ASC'}
  ],

  /**
   * Als deze > 0 dan gebruiken we die om alleen permissies van deze security groep te zien
   */
  selected_security_group_id: 0,
};

// getters
const getters = {
  ...BaseModule.getters,

  /**
   * We overschrijven hier de default filtered_data zodat we voordat de MedimoLocalDataTable
   * de paginering e.d. bepaald al de permissies van een bepaalde security group er uit _kan_ filtered
   *
   * @param state
   * @param getters
   * @param rootState
   * @returns {(function(*): *)|(function(*): *)}
   */
  filtered_data(state, getters, rootState) {
    let filtered_data = state.data.filter((model) => {
      return Utility.matches_filter_query(model, state.query, state.filter_columns);
    });

    if (state.selected_security_group_id > 0) {
      filtered_data = filtered_data.filter(model => {
        return parseInt(model.security_group_id) === parseInt(state.selected_security_group_id);
      });
    }

    return getters.privacy_filtered_data(filtered_data);
  },
  selected_security_group_id(state, getters, rootState) {
    return state.selected_security_group_id;
  },
  selected_security_group(state, getters, rootState, rootGetters) {
    return rootGetters['user/security_groups/find'](state.selected_security_group_id);
  },
  selected_security_group_name(state, getters, rootState, rootGetters) {
    // Deze is voor ExternalDataTable
    // let securityGroup = rootGetters['user/security_groups/find_search_result'](state.selected_security_group_id);
    const securityGroup = rootGetters['user/security_groups/find'](state.selected_security_group_id);
    if (securityGroup) {
      return securityGroup.name;
    }
    return '';
  },
};

// actions
const actions = {
  ...BaseModule.actions,
  // dummy_action ({state, commit, dispatch, getters, rootState}, payload) {
  //     //
  // }
};

// mutations
const mutations = {
  ...BaseModule.mutations,
  set_selected_security_group_id(state, security_group_id) {
    state.selected_security_group_id = security_group_id;
  },
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
