import Utility from '@/vue/utility/utility';
import BaseModule from '@/vue/store/modules/base_module';

const state = {
  data: []
};

// getters
const getters = {
  ...BaseModule.getters,
  select2_options(state, getters, rootState, rootGetters)  {
    if (!state.data.length) {
      return [{value: '', text: ''}];
    }

    const options = [];
    state.data.forEach((item, index) => {
      options.push({
        value: item.id,
        text: item.name
      });
    });

    return options;
  },
};

// actions
const actions = {
  fetch_all({state, commit, dispatch, getters, rootState}, payload) {
    // We fetchen alleen als er nog geen redenen ingeladen zijn.
    // Wellicht in de toekomst optioneel maken, maar zo zat het nu overal in de code
    if (state.data.length > 0) {
      return null;
    }
    const endpoint = '/api/v1/medication-agreement/change-reasons';

    return dispatch('api/getEndpoint', {
      endpoint: endpoint,
      data: {}
    }, {root: true}).then(response => {
      commit('set_data', {data: response.data.data});
    });
  },
};

// mutations
const mutations = {
  ...BaseModule.mutations,
  set_data(state, new_data) {
    state.data = new_data.data;
  },
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
