<template>
    <div v-if="patient.authority.shouldShowOptInButton" style="display:inline-block;">
    <medimo-link
            id="lsp-optin-link"
            :class="'status badge badge-sm ml-1 ' + (patient.optInCount ? 'badge-medimo-50' : 'badge-warning')"
            :disabled="!patient.authorizedBasicInfo"
            @click="trigger_modal_optin = !trigger_modal_optin"
            :to="''">
        <medimo-tooltip
                :content="language.patient + ' heeft ' + (patient.optInAllowed ? 'WEL' : 'GEEN') + ' toestemming gegeven voor het LSP'"
                v-if="patient.optInCount"
                class="small">LSP {{ (patient.optInAllowed ? '+' : '-') }}
        </medimo-tooltip>
        <medimo-tooltip v-else
                        :content="'Onbekend of ' + language.patient + ' toestemming wil geven voor het LSP'"
                        class="small lsp-optin-unknown">LSP
        </medimo-tooltip>
    </medimo-link>

    <lsp-modal v-if="trigger_modal_optin"
               :patient="patient"
               title="Landelijk schakelpunt"
               type="1"
               @closed="trigger_modal_optin = false"/>
  </div>
</template>

<script>
import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import EditOptInModal from '@/vue/components/patients/optin/EditOptInModal';
import LspModal from './lsp/LspModal';

export default {
  components: { EditOptInModal, LspModal },
  props: ['language', 'patient'],
  mixins: [ BaseComponentsMixin ],
  data: function () {
    return {
      trigger_modal_optin: false
    };
  }
};
</script>
