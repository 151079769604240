import BaseModule from '../base_module';

// initial state
// shape: [{ id, quantity }]
const state = {
  ...BaseModule.state,
  per_page: 5,
  sort_columns: [
    {column_name: 'timestamp', direction: 'ASC'},
  ]
};

// getters
const getters = {
  ...BaseModule.getters,
  websocketChannel(state, getters, rootState, rootGetters) {
    return state.websocketChannel;
  }
};

// actions
const actions = {
  ...BaseModule.actions,
  fetch({state, commit, dispatch, getters, rootState}, payload) {
    return dispatch('api/getEndpoint', {
      endpoint: '/api/v1/mutations/ward/' + payload.ward_id,
      data: {} // Empty, since it's a GET
    }, {root: true}).then(response => {
      // We re-setten hier de data om te voorkomen dat er oude data blijft hangen.
      commit('set_data', response.data.data);

      return response;
    });
  },
  ...BaseModule.actions,
  fetch_full({state, commit, dispatch, getters, rootState}, payload) {
    return dispatch('api/getEndpoint', {
      endpoint: '/api/v1/mutations/' + payload.mutation_id,
      data: {} // Empty, since it's a GET
    }, {root: true}).then(response => {
      return response.data.data;
    });
  },
  join_channel({state, commit, dispatch, getters, rootState}, ward_id) {
    const websocketChannel = window.medimo.WebSocket.join('MutationWard.' + ward_id);
    commit('set_state', {
      property: 'websocketChannel',
      value: websocketChannel,
    });
  },
  leave_channel({state, commit, dispatch, getters, rootState}) {
    getters['websocketChannel'].leave();
    commit('set_state', {
      property: 'websocketChannel',
      value: null,
    });
  }
};

// mutations
const ward_mutations = {
  ...BaseModule.mutations,
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations: ward_mutations
};
