'use strict';

if (typeof window === 'object') {
  window.ScriptTagTracker = ScriptTagTracker;
}

if (typeof module === 'object' && typeof module.exports !== 'undefined') {
  module.exports = ScriptTagTracker;
}

function ScriptTagTracker(sentry) {
  this._sentry = sentry;
}

ScriptTagTracker.prototype.loadError = function (scriptTag) {
  this._sentry.withScope(scope => {
    scope.setExtra('file', scriptTag.src);
    this._sentry.captureMessage('A script tag was NOT loaded!');
  });
  // this._sentry.captureMessage('A script tag was NOT loaded!', { extra: { file: scriptTag.src } });
  // Redirect to home when OK button is clicked (alert() equals to false then)
  if (!alert('De pagina kon niet juist worden geladen. Controleer uw internetverbinding.\n\nKlik op "OK" om naar de home-pagina te gaan.')) {
    window.location.href = '/';
  }
};

ScriptTagTracker.prototype.loadSuccess = function (scriptTag) {
  this._sentry.addBreadcrumb({
    message: 'File "'+scriptTag.src+'" loaded!',
    category: 'script-tag-tracker'
  });
};
