<template>
  <medi-modal
      :loading="loading"
      :title="title"
      size="medium"
      :patient_id="patient.id"
      @closed="onClosed()"
  >
    <span>
      {{ this.patient.name }} heeft een nierwaarde van <b>{{ initial_data.most_recent_kidney_value }}</b>
      <br /><br />
    </span>

    <span v-if="initial_data.has_kidney_contra_indication">
      Bij deze waarde is er geen sprake meer van de contra-indicatie:
      <br /><br />
      <b>'Verminderde Nierfunctie'</b>
    </span>

    <span v-else>
      <br /><br />
      Bij deze waarde is er sprake van de contra-indicatie
      <br/><br/>
      <b>'Verminderde Nierfunctie'</b>
    </span>

    <template v-slot:action-buttons>
      <medimo-button @click="remove" v-if="initial_data.has_kidney_contra_indication">
        Verwijder contra-indicatie
      </medimo-button>
      <medimo-button @click="add" v-else>
        Voeg contra-indicatie toe
      </medimo-button>
      <medimo-button @click="close" type="outline-primary">
        Annuleren
      </medimo-button>
    </template>

  </medi-modal>
</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import moment from 'moment-mini';

export default {
  emits: ['closed','processed'],

  props: [
    'patient',
  ],

  mixins: [
    BaseComponentsMixin
  ],

  data: function () {
    return {
      loading: false,
    };
  },

  computed: {
    title() {
      if (this.initial_data.has_kidney_contra_indication === true) {
        return 'Contra-indicatie Verminderde Nierfunctie verwijderen';
      } else {
        return 'Contra-indicatie Verminderde Nierfunctie toevoegen';
      }
    },
    initial_data() {
      const initial_data = this.$store.state.readings.initial_data[this.patient.id];
      if (typeof initial_data === 'undefined') {
        return [];
      }
      return initial_data;
    },
  },

  methods: {
    close() {
      this.$emit('closed');
    },
    processed() {
      this.$emit('processed');
    },
    onClosed(event) {
      this.$emit('closed');
    },
    remove() {
      this.loading = true;
      this.$store.dispatch('indications/delete', {
        patient_id: this.patient.id,
        indication_id: this.initial_data.kidney_contra_indication_id,
      }).then(() => {
        this.loading = false;
        this.processed();
      }).catch(() => {
        this.loading = false;
      });
    },
    add() {
      this.loading = true;
      this.$store.dispatch('indications/store', {
        patient_id: this.patient.id,
        data: {
          zindex_indication: this.initial_data.contra_indication_kidney_bst,
          info: 'Toegevoegd vanuit meetwaarden',
          register_date: moment() // @TODO: moet format hebben?
        }
      }).then(() => {
        this.loading = false;
        this.processed();
      }).catch(() => {
        this.loading = false;
      });
    }
  },
};
</script>
