<template>
  <medi-modal
      v-if="tree_filter_modal_props"
      :title="tree_filter_modal_props.title"
      size="large"
      :ref="'modal' + uid"
      @closed="close()"
  >
    <filter-tree :tree="tree" :filter_route="filter_route" />
    <medimo-labeled-checkbox v-model="inverse" label="Patient voldoet niet aan bovenstaande criteria" label_position="right" />

    <template v-slot:action-buttons>
      <button @click.prevent="storeFilter($event)">Opslaan</button>
    </template>
  </medi-modal>
</template>

<script>
import FilterTree from './FilterTree';
import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import MedimoLabeledCheckbox from "@/vue/components/general/form/base/MedimoLabeledCheckbox.vue";
import Utility from '@/vue/utility/utility';

export default {
  emits: ['closed', 'save'],
  components: {FilterTree, MedimoLabeledCheckbox},

  props: [
    'height',
    'filter_route',
    'tree_filter_modal_props',
    'language'
  ],

  data() {
    return {
      tree: {
        groups: this.tree_filter_modal_props.groups,
        logic: this.tree_filter_modal_props.logic,
      },
      //if inverse === true: the answer is true when patient does NOT meet the given criteria
      inverse: this.tree_filter_modal_props.inverse,
    };
  },

  mixins: [BaseComponentsMixin],

  computed: {
    uid() {
      return this._.uid;
    },
  },

  methods: {
    formatGroup: function (group) {
      let returnString = '';

      if (typeof group.groups !== 'undefined') {
        let firstGroup = true;
        group.groups.forEach((subGroup) => {
          const subGroupText = this.formatGroup(subGroup);
          // If we cannot show anything, filter this group from title. It will be removed in the backend
          if (!subGroupText) {
            return;
          }

          if (firstGroup) {
            firstGroup = false;
          } else {
            returnString += '<br/><div style="margin: 3px;"><b>' + this.formatLogic(group.logic) + '</b></div>';
          }
          returnString += subGroupText;
        });
      }

      if (typeof group.items !== 'undefined') {
        let firstItem = true;
        group.items.forEach((item) => {
          // If we cannot show anything, filter this item from title. It will be removed in the backend
          if (!item.text) {
            return;
          }

          if (firstItem) {
            firstItem = false;
            returnString += ' ';
          } else {
            returnString += '<br/> <b>' + this.formatLogic(group.logic) + '</b> ';
          }
          returnString += item.text;
        });
      }

      return returnString;
    },
    formatLogic: function (logic) {
      return logic === 'a' ? 'en' : 'of';
    },
    storeFilter() {
      const formattedFilter = this.formatGroup(this.tree);
      if (formattedFilter) {
        const title = '<b>' + Utility.ucfirst(this.language['patient']) + ' voldoet ' + (this.inverse ? 'NIET ' : '') + 'aan:</b><br />' + formattedFilter;
        const json =  JSON.stringify({
          'logic': this.tree.logic,
          'groups': this.tree.groups,
          'inverse': this.inverse
        });
        this.$emit('save', this.tree_filter_modal_props.rowid, this.tree_filter_modal_props.colid, this.tree_filter_modal_props.option, json, title);
      }

      this.close();
    },
    close() {
      this.$emit('closed');
    }
  }
};
</script>
