<template>
    <div class="medimo-table" style="display: inline-block;">

        <medimo-tooltip v-if="help_text.length" class="form-info text-primary" :content="help_text" :center="false">
            <medimo-button-link
                    :outline="true"
                    :icon="icon"
                    :transparent="! active"
                    @click="$emit('toggle')"
                    :padding_class="'py-0 pr-3 pl-2'"
                    :class="'mr-1 quick-filter-button ' + extra_classes"
            >
                <slot></slot>
            </medimo-button-link>
        </medimo-tooltip>

        <medimo-button-link v-else
                :outline="true"
                :icon="icon"
                :transparent="! active"
                @click="$emit('toggle')"
                :padding_class="'py-0 pr-3 pl-2'"
                :class="'mr-1 quick-filter-button ' + extra_classes"
        >
            <slot></slot>
        </medimo-button-link>
    </div>
</template>

<script>

import MedimoButtonLink from '../../MedimoButtonLink';
import MedimoCheckbox from '@/vue/components/general/form/base/MedimoCheckbox';
import MedimoButton from '../../MedimoButton';
import MedimoTooltip from '../../MedimoTooltip';

export default {
  components: {
    MedimoTooltip,
    MedimoButtonLink,
    MedimoCheckbox,
    MedimoButton,
  },

  emits: ['toggle'],

  props: {
    'active': {default: false},
    'extra_classes': {default: ''},
    'help_text': {default: ''}
  },

  mixins: [],

  data: function () {
    return {
    };
  },

  computed: {
    icon() {
      return this.active ? 'fa-solid fa-square-check' : 'fa-regular fa-square';
    }
  },

  created() {},
  mounted() {},
  methods: {},
  watch: {}
};
</script>
