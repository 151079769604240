<template>
  <medimo-vue-select2
      label_column_classes="col-6"
      :placeholder="placeholder"
      :help_text="help_text"
      :loading="loading"
      api_url="/api/v1/drug-id/search"
      api_request_type="GET"
      :min_input_length="min_input_length"
      :fetch_on_load="fetch_on_load"
      v-model="value"
  />
</template>

<script>
import MedimoVueSelect2 from '@/vue/components/general/form/base/vue-select2/MedimoVueSelect2';

export default {
  components: {MedimoVueSelect2},
  props: {
    placeholder: {default: 'Selecteer een taxe id'},
    help_text: {default: ''},
    label: {default: ''},
    multiple: {default: false},
    fetch_on_load: {default: true},
    min_input_length: {default: 3},
  },
  data: function () {
    return {
      value: '',
      loading: false,
    };
  },
  watch: {
    value(value) {
      this.$emit('changed', value);
    },
  }
};
</script>
