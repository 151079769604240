import BaseModule from '@/vue/store/modules/base_module';

const state = {
  ...BaseModule.state,
  search_endpoint: '/api/v1/pharmacies/search',
  sort_columns: [
    {column_name: 'apot.naam', direction: 'ASC'},
  ],
  search_columns: [
    'apot.naam',
    'apot.plaats',
  ],
  quick_filters: {},
  data: []
};

const getters = { ...BaseModule.getters };
const actions = { ...BaseModule.actions };
const mutations = { ...BaseModule.mutations };

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
