import {MedicationAgreementDiffObject} from '@/domain/models/MedicationAgreementDiffObject';

export class GeneratedMedicationAgreementDiffObject { 
  public description: string;
  public mutation: string | null;
  public current: string | null;

  constructor(description: string, mutation: string | null = null, current: string | null = null) {
    this.description = description;
    this.mutation = mutation;
    this.current = current;
  }

  public static fromJson(json: unknown): MedicationAgreementDiffObject {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new MedicationAgreementDiffObject(
      parsedJson['description'] as string,
      parsedJson['mutation'] as string,
      parsedJson['current'] as string,
    );
  }

  public static multipleFromJson(json: unknown): MedicationAgreementDiffObject[] {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    const items: MedicationAgreementDiffObject[] = [];
    for (const item of parsedJson) {
      items.push(this.fromJson(item));
    }
    return items;
  }
}
