<template>
    <medimo-notification :alert="true" v-bind="$attrs">
        <slot></slot>
        <div v-if="$slots.body" class="body">
            <slot name="body"></slot>
        </div>
    </medimo-notification>
</template>

<script>
import MedimoNotification from './MedimoNotification';
export default {
  // Accepts the exact same things as the MedimoNotification, please check there.
  // The MedimoAlert is a simple wrapper that toggles :alert="true"
  // Feature-wise, they're identical.
  props: {
    //
  },

  components: {
    MedimoNotification,
  }
};
</script>
