<script>

export default {
  data: function () {
    return {
      //
    };
  },

  props: {
    // Deze wordt gebruikt worden om data vanuit de backend mee te geven aan een pagina.
    // NIET AANKOMEN. NIET GEBRUIKEN
    // Gebruik this.initial_data (computed property) om je initial_data set op te halen.
    'initial_data_object': {default: () => {return {};}},
  },

  computed: {
    initPromises: {
      set(value) {
        this.$store.commit('initial_data/set_state', {
          property: 'initPromises',
          value: value,
        });
      },
      get() {
        return this.$store.getters['initial_data/initPromises'];
      },
    },
    initial_data() {
      return this.$store.getters['initial_data/data'];
    }
  },

  created() {
    this.initPromises = [];

    // Op deze manier kan het maximaal 1x per F5 / Pageload ge-set worden.
    if (! this.$store.getters['initial_data/was_set'] && Object.keys(this.initial_data_object).length) {
      this.$store.commit('initial_data/set_data', this.initial_data_object);
    }
  },

  methods: {
    process_initial_data: function () {
      const initial_data_is_processed = this.$store.getters['initial_data/is_used'];

      if (!initial_data_is_processed && typeof this.initial_data !== 'undefined') {
        this.$store.commit('initial_data/is_used');

        this.$store.dispatch('initial_data/process_backend_medication_data', {
          data: this.initial_data,
        });

        return true;
      }
      return false;
    },
  },

  watch: {}
};
</script>
