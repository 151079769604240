<template>
    <medimo-link @click="clicked($event)" :tag="tag" :class="'btn btn-link ' + padding_class + ' ' + transparent_class + ' ' + (slim ? 'slim' : '')" :style="extra_styles" :to="to">
        <span v-if="icon_position === 'right'" class="mr-1">
            <slot></slot>
        </span>
        <i v-if="icon_class.length" :class="icon_class">
            <span v-if="badge_count" class="badge badge-danger badge-pill">{{ badge_count }}</span>
        </i>
        <span v-else-if="icon_fa.length" style="position: relative">
            <fa-icon :icon="icon_fa" :class="margin + ' mr-2'" size="lg" />
            <span v-if="badge_count" class="badge badge-danger badge-pill">{{ badge_count }}</span>
        </span>
        <span v-if="icon_position === 'left'">
            <slot></slot>
        </span>
    </medimo-link>
</template>

<script>
import MedimoLink from './MedimoLink';

export default {
  components: {
    MedimoLink
  },

  emits: ['click'],

  props: {
    icon: {default: 'fa-solid fa-plus'},
    to: {default: ''},
    badge_count: {default: 0},
    padding_class: {default: 'py-0 px-3'},
    icon_position: {default: 'left'},

    // Makes the button a slim version that doesn't have the default button line-height and height
    // Easier to use in places where you don't want the button to heighten the parent element.
    // Use align-items-center or align-self-center for variable vertical alignment
    slim: {default: false},

    // Set to TRUE to slightly hide the button
    transparent: {default: false},

    // Set to TRUE to show an outline
    outline: {default: false},

    // Inject custom styles. Use sparingly.
    extra_styles: {default: ''},

    margin_override: {default: 'mr-1'},
  },

  computed: {
    icon_class() {
      return (!this.icon.length || this.icon.startsWith('fa-')) ? '' : (this.icon + this.margin);
    },
    icon_fa() {
      return this.icon.startsWith('fa-') ? this.icon : '';
    },
    margin() {
      return this.badge_count > 0 ? ' mr-2 ' : ' ' + this.margin_override + ' '; // Add a margin when there's a visible badge
    },
    transparent_class() {
      return this.transparent ? 'transparent' : '';
    },
    tag() {
      if (this.to === '') {
        return 'span';
      }
      return 'a';
    }
  },

  methods: {
    clicked(event) {
      this.$emit('click', event);
    }
  }
};
</script>
