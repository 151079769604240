import {GeneratedVariableDosingRegimen} from '@/domain/models/generated/GeneratedVariableDosingRegimen';
import moment from "moment";
import SnomedCt from "@/enums/SnomedCt";

export class VariableDosingRegimen extends GeneratedVariableDosingRegimen {
  public isActive() {
    const now = moment();

    if (this.startDateTime?.isBefore(now) && this.endDateTime === null) {
      return true;
    }

    if (this.startDateTime?.isBefore(now) && this.endDateTime?.isAfter(now)) {
      return true;
    }

    return false;
  }

  public hasBeenCancelled(): boolean {
    if (this.stopType === null) {
      return false;
    }

    return this.stopType?.code === SnomedCt.Cancelled;
  }

  public hasToBeChanged(): boolean {
    return this.copyIndicator || this.distributedIndicator;
  }

  public hasToBeStopped(): boolean {
    return this.copyIndicator || this.distributedIndicator;
  }
}
