<template>
    <medi-modal
        :loading="loading"
        :title="'Nieuwe contra-indicatie: '+zindex_indication.text"
        size="medium"
        @closed="onClosed()"
    >
        <medimo-form v-if="!medication_guard_view">

          <medimo-notification type="danger" class="mb-4" :exclamation="true" v-if="already_active">
              Deze contra-indicatie is al actief!
          </medimo-notification>

          <medimo-form-row>
              <medimo-form-column column_classes="col-12">
                  <medimo-labeled-input
                      :disabled="true"
                      label_column_classes="col-12 col-sm-3"
                      label="Contra-indicatie"
                      name="contraIndicatie"
                      :modelValue="zindex_indication.text"
                  ></medimo-labeled-input>
              </medimo-form-column>
          </medimo-form-row>

          <medimo-form-row>
              <medimo-form-column column_classes="col-12">
                  <medimo-validated-input
                      :disabled="already_active"
                      label_column_classes="col-12 col-sm-3"
                      label="Bijzonderheden"
                      name="info"
                      v-model="info"
                      placeholder="(optioneel)"
                  ></medimo-validated-input>
              </medimo-form-column>
          </medimo-form-row>

          <medimo-form-row>
              <medimo-form-column column_classes="col-12">
                  <medimo-validated-date-picker
                      :disabled="already_active"
                      label_column_classes="col-12 col-sm-3"
                      label="Vaststellingsmoment"
                      placeholder="Selecteer datum"
                      datetime_format="DD-MM-YYYY HH:mm"
                      name="register_date"
                      v-model="register_date"
                      help_text="Wanneer heeft u of een eerdere zorgverlener vastgesteld dat deze contraindicatie van toepassing is? M.a.w. vanaf wanneer moet deze contraindicatie van kracht zijn? Indien u dit niet weet, vul dan nu/vandaag in.">
                  </medimo-validated-date-picker>
              </medimo-form-column>
          </medimo-form-row>

          <medimo-form-row>
              <medimo-form-column column_classes="col-12">
                  <medimo-validated-date-picker
                      :disabled="already_active"
                      label_column_classes="col-12 col-sm-3"
                      label="Stopdatum"
                      placeholder="(optioneel)"
                      datetime_format="DD-MM-YYYY HH:mm"
                      name="stop_date"
                      v-model="stop_date">
                  </medimo-validated-date-picker>
              </medimo-form-column>
          </medimo-form-row>
        </medimo-form>

        <medimo-form v-if="medication_guard_view">
          <medication-guard v-if="!already_active"
                        :loading="medication_guard_loading"
                        :signals="signals">
          </medication-guard>
        </medimo-form>

        <template v-slot:action-buttons>
          <medimo-notification type="danger" class="mr-auto col-md-6 d-block" v-if="pre_validation_message !== null">
            <fa-icon icon="fa-solid fa-bell" class="bell" size="lg" /> {{ pre_validation_message }}
          </medimo-notification>

          <medimo-button @click="validateInput()" v-if="!already_active && pre_validation_message === null">
              Opslaan
          </medimo-button>

          <medimo-button @click="store" v-if="pre_validation_message !== null">
            Ja, ik weet het zeker
          </medimo-button>

          <medimo-button @click="close(false)" type="outline-primary">
              Annuleren
          </medimo-button>
        </template>
    </medi-modal>
</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import PatientInfoMediModal from '@/vue/components/modals/PatientInfoMediModal';
import moment from 'moment-mini';
import MedicationGuard from '@/vue/components/status/medication-guard/MedicationGuard';
import ValidateStartStop from '../../../mixins/indications/ValidateStartStop';

export default {
  components: {
    MedicationGuard,
    PatientInfoMediModal,
  },
  emits: ['closed'],

  props: {
    'zindex_indication': {required: true},
    'patient': {required: true},
  },

  mixins: [
    BaseComponentsMixin,
    ValidateStartStop,
  ],

  data: function () {
    return {
      medication_guard_loading: false,
      medication_guard_view: false,
      loading: false,
      info: '',
      register_date: moment(moment(), "DD-MM-YYYY"),
      stop_date: null,
      already_active: false,
      view_medicationguard: false,
    };
  },

  computed: {
    signals() {
      return this.$store.getters['medication_guard_signals/for_added_contra_indication'](this.zindex_indication.id);
    },
    traces() {
      return this.$store.state['medication_guard_signals'].shadow_traces;
    },
  },

  created() {
  },

  mounted() {
  },

  methods: {
    close(refresh) {
      this.clearSignalsAndTraces();
      this.$emit('closed', refresh);
    },
    onClosed(event) {
      this.clearSignalsAndTraces();
      this.$emit('closed');
    },

    clearSignalsAndTraces() {
      this.$store.commit('medication_guard_signals/clear');
    },

    fetchSignals() {

      this.loading = true;

      const vm = this;
      this.medication_guard_loading = true;
      this.$store.dispatch('indications/fetchMedicationGuardSignals', {
        patient_id: this.patient.id,
        bst_id: this.zindex_indication.id
      }).then(response => {

        // Traces
        this.$store.commit('medication_guard_signals/set_shadow_traces', response.data.data.mfb_traces);

        // MFB signals
        response.data.data.mfb_signals.forEach((signal) => {
          signal.contraIndicationId = vm.zindex_indication.id;
          this.$store.commit('medication_guard_signals/add_signal', {signal: signal, tempNotes: []});
        });

        // Other signals
        response.data.data.other_signals.forEach((signal) => {
          signal.contraIndicationId = vm.zindex_indication.id;
          this.$store.commit('medication_guard_signals/add_signal', {signal: signal, tempNotes: []});
        });

        // Error signals
        response.data.data.mfb_errors.forEach((signal) => {
          signal.contraIndicationId = vm.zindex_indication.id;
          this.$store.commit('medication_guard_signals/add_signal', {signal: signal, tempNotes: []});
        });

        this.medication_guard_loading = false;
        this.loading = false;
      });
    },

    store() {
      // er zijn signals, maar medication guard view is nog niet getoond
      if (this.signals.length && this.medication_guard_view === false) {
        this.medication_guard_view = true;
        this.pre_validation_message = null;
        return;
      }

      // check if there are any unapproved signals that should be approved
      const requiredSignalsExist = this.signals.filter((signal) => {
        return (signal.requiresApproval === 1 || signal.requiresApproval === true) && !signal.approved;
      });
      const unapprovedSignalsExist = requiredSignalsExist.length > 0;
      if (unapprovedSignalsExist) {
        this.$store.dispatch('notifications/addDangerAlert', {
          title: 'Medicatiebewakingssignalen met een * dienen geautoriseerd te worden'
        });
        return;
      }

      // store contra-indication
      this.loading = true;
      this.$store.dispatch('indications/store', {
        patient_id: this.patient.id,
        data: {
          zindex_indication: this.zindex_indication.id,
          info: this.info,
          register_date: this.register_date !== '' ? this.register_date : null,
          stop_date: this.stop_date !== '' ? this.stop_date : null,
          signals: this.signals,
          traces: this.traces
        }
      }).then(() => {
        this.loading = false;
        this.close(true);
      }).catch(() => {
        this.loading = false;
      });
    },
  },

  watch: {
    zindex_indication: {
      immediate: true,
      handler() {
        this.fetchSignals();
        this.already_active = this.$store.getters['indications/find_by_zindex_id'](this.zindex_indication.id);
      }
    },
    trigger(value) {
      this.medication_guard_view = false;
    },
    info(value) {
      this.signals.forEach((signal) => {
        signal.displayInfo.contraIndicationInfo = value;
      });
    },
  }
};
</script>
