<template>
  <patient-info-medi-modal
      :patient_id="patient.id"
      :loading="loading"
      title="Meetwaarden"
      id="reading-data-table-modal"
      size="extra-large"
      :min_height="0"
      @closed="close"
  >

    <template v-slot:title-buttons>
      <medimo-copy-table-button
          class="ml-2 d-inline"
          tooltip_content_start="Kopiëer naar klembord"
          :cell_component_css_selector="[]"
          :container_component="null"
          :custom_copy_event="true"
          @custom_copy_event="copyToClipboard"
      ></medimo-copy-table-button>

      <medimo-print-button
          @printClicked="printButtonHandler"
      ></medimo-print-button>

    </template>

    <reading-data-table-flat v-if="view_flat"
        :patient="patient"
        :refresh_table="refresh_table"
        @openEditReadingModal="openEditReadingModal">
    </reading-data-table-flat>

    <reading-data-table-date v-if="!view_flat"
        :patient="patient"
        :refresh_table="refresh_table"
        @openEditReadingModal="openEditReadingModal">
    </reading-data-table-date>

    <template v-slot:action-buttons>
      <medimo-button v-if="has_edit_permissions" @click="openFindBepalingModal">
        Nieuwe toevoegen
      </medimo-button>
      <medimo-button @click="toggleDatumUitslag">
        <template v-if="view_flat">Bekijk per uitslag</template>
        <template v-else>Bekijk per datum</template>
      </medimo-button>
      <medimo-button v-if="zorgportaal_url" :to="zorgportaal_url">
        Zorgportaal
      </medimo-button>
      <medimo-button v-if="kidney_warning" type="danger" @click="openKidneyWarningModal">
        Verminderde nierfunctie
      </medimo-button>
      <medimo-button class="mr-md-auto" v-if="has_edit_permissions" type="outline-primary" @click="openHistoryModal">
        Historie
      </medimo-button>
      <medimo-button @click="close">
        Sluiten
      </medimo-button>
    </template>

    <reading-history-modal
        :patient_id="patient.id"
        v-if="show_history_modal"
        @closed="show_history_modal = false"
    ></reading-history-modal>

    <add-reading-modal
        :patient="patient"
        v-if="show_add_reading_modal"
        :selected_bepaling="selected_bepaling"
        @closed="closeAddReadingModal">
    </add-reading-modal>

    <edit-reading-modal
        v-if="selected_reading && selected_reading.readonly === false && show_edit_reading_modal"
        :reading="selected_reading"
        :loading="loading"
        :patient="patient"
        @closed="closeEditReadingModal">
    </edit-reading-modal>

    <edit-reading-read-only-modal
        v-if="selected_reading && selected_reading.readonly === true && show_edit_reading_modal"
        :reading="selected_reading"
        :loading="loading"
        :patient="patient"
        @closed="closeEditReadingModal"
    >
    </edit-reading-read-only-modal>

    <kidney-contra-indication-modal
        v-if="show_kidney_contra_indication_modal && kidney_warning"
        :patient="patient"
        @closed="closeKidneyContraIndicationModal"
        @processed="contraIndicationKidneyProcessed">
    </kidney-contra-indication-modal>

    <find-bepaling-data-table-modal
        v-if="show_find_bepaling_modal"
        :patient="patient"
        @closed="show_find_bepaling_modal = false"
        @bepalingSelected="bepalingSelected">
    </find-bepaling-data-table-modal>

  </patient-info-medi-modal>
</template>

<script>
import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import PatientInfoMediModal from '@/vue/components/modals/PatientInfoMediModal';
import AddReadingModal from './AddReadingModal';
import EditReadingModal from './EditReadingModal';
import FindBepalingDataTableModal from './FindBepalingDataTableModal';
import ReadingHistoryModal from './ReadingHistoryModal';
import KidneyContraIndicationModal from './KidneyContraIndicationModal';
import EditReadingReadOnlyModal from './EditReadingReadOnlyModal';
import ReadingDataTableFlat from './ReadingDataTableFlat';
import ReadingDataTableDate from './ReadingDataTableDate';
import MedimoCopyTableButton from '@/vue/components/general/MedimoCopyTableButton';
import moment from 'moment-mini';
import Copy from '@/vue/utility/copy';
import PrintsHtml from '@/vue/mixins/PrintsHtml';
import MedimoPrintButton from '@/vue/components/general/MedimoPrintButton';

export default {
  emits: ['closed'],
  components: {
    MedimoPrintButton,
    ReadingDataTableDate,
    ReadingDataTableFlat,
    FindBepalingDataTableModal,
    AddReadingModal,
    EditReadingModal,
    EditReadingReadOnlyModal,
    PatientInfoMediModal,
    ReadingHistoryModal,
    KidneyContraIndicationModal,
    MedimoCopyTableButton
  },

  props: {
    'patient': {required: true},
  },

  mixins: [
    BaseComponentsMixin,
    PrintsHtml
  ],

  data: function () {
    return {
      store_name: 'readings',
      loading: false,

      show_history_modal: false,
      show_add_reading_modal: false,
      show_edit_reading_modal: false,
      show_find_bepaling_modal: false,
      show_kidney_contra_indication_modal: false,

      refresh_table: 0,

      view_flat: false,

      selected_reading: false,
      selected_bepaling: false,
    };
  },

  computed: {
    has_edit_permissions() {
      return this.initial_data.has_edit_permissions && this.initial_data.has_edit_permissions === true;
    },
    zorgportaal_url() {
      if (this.initial_data.zorgportaal_url) {
        return this.initial_data.zorgportaal_url;
      }
      return false;
    },
    kidney_warning() {

      // nierwaarden onbekend, geen waarschuwing nodig
      if (this.initial_data.most_recent_kidney_value === false) {
        return false;
      }
      // nierwaarde te laag, maar geen contraindicatie
      if (this.initial_data.most_recent_kidney_value < this.initial_data.minimal_kidney_function_value) {
        if (this.initial_data.has_kidney_contra_indication === false) {
          return true;
        }
      }
      else {
        // nierwaarde ok, maar wel contraindicatie
        if (this.initial_data.has_kidney_contra_indication === true) {
          return true;
        }
      }
      return false;
    },
    initial_data() {
      const initial_data = this.$store.state.readings.initial_data[this.patient.id];
      if (typeof initial_data === 'undefined') {
        return [];
      }
      return initial_data;
    },
    dates() {
      return this.$store.getters[this.store_name + '/dates'];
    },
    bepalingen() {
      return this.$store.getters[this.store_name + '/bepalingen'];
    },
    resultsPerBepaling() {
      return this.$store.getters[this.store_name + '/resultsPerBepaling'];
    }
  },

  created() {
    this.$store.commit(this.store_name + '/set_search_results', this.$store.state.readings.data);
    this.initialLoad();
  },

  mounted() {
    this.$store.commit(this.store_name + '/set_search_endpoint', '/api/v1/patients/' + this.patient.id + '/readings');

    // determine view based on current_user parameter
    this.view_flat = this.$store.getters['current_user/data'].readings_view_flat;
  },

  methods: {
    close() {
      this.$emit('closed');
      this.show_history_modal = false;
    },
    toggleDatumUitslag() {
      this.view_flat = !this.view_flat;

      // And store in the backend via the user parameters
      this.$store.dispatch('current_user/update_parameters', {data: {readings_view_flat: this.view_flat}});
    },
    openEditReadingModal(reading) {
      this.selected_reading = reading;
      this.show_edit_reading_modal = true;
    },
    closeEditReadingModal(refresh) {
      this.initialLoad();
      this.show_edit_reading_modal = false;
      if (refresh) {
        this.refresh_table++;
      }
    },
    openFindBepalingModal() {
      this.show_find_bepaling_modal++;
    },
    bepalingSelected(model) {
      this.selected_bepaling = model;
      this.show_find_bepaling_modal = false;      // close find modal
      this.show_add_reading_modal = true;     // open add modal
    },
    closeAddReadingModal(refresh) {
      this.show_add_reading_modal = false;
      if (refresh) {
        this.refresh_table++;
        this.initialLoad();
      }
    },
    openHistoryModal() {
      this.show_history_modal = !this.show_history_modal;
    },
    openKidneyWarningModal() {
      // nierwaarde te laag, maar geen contraindicatie
      if (this.initial_data.most_recent_kidney_value < this.initial_data.minimal_kidney_function_value) {
        if (this.initial_data.has_kidney_contra_indication === false) {
          this.openKidneyContraIndicationModal();
        }
      }
      // nierwaarde ok, maar wel contraindicatie
      else {
        if (this.initial_data.has_kidney_contra_indication === true) {
          this.openKidneyContraIndicationModal();
        }
      }
    },
    openKidneyContraIndicationModal() {
      this.show_kidney_contra_indication_modal = true;
    },
    contraIndicationKidneyProcessed() {
      this.most_recent_kidney_value = false;
      this.initialLoad();
      this.closeKidneyContraIndicationModal();
    },
    closeKidneyContraIndicationModal() {
      this.show_kidney_contra_indication_modal = false;
    },
    initialLoad() {
      this.$store.dispatch('readings/initialLoad', {
        patient_id: this.patient.id
      }).then(() => {
      }).catch(() => {
      });
    },
    copyToClipboard() {

      /**
       * Deze functie bouwt een multidimensionale array op, die vervolgens als (html) tabel naar clipboard wordt gekopieerd.
       * Deze array bestaat uit 3 onderdelen:
       *
       * 1. bovenaan een tabel met daarin patient gegevens
       * 2. daaronder een tabel met bepalingen en per datum de uitslag, net zoals de perDatum view
       * 3. onderaan een tabel met opmerkingen die bij de uitslagen horen
       */

      const data = [];

      // 1. tabel met patient gegevens
      data.push([this.patient.name]);
      data.push([this.patient.birthday + ', ' + this.patient.age + ' jaar']);
      let location = this.patient.location.name + ' - ' + this.patient.ward.name;
      if (this.patient.room) {
        location += ', kamer ' + this.patient.room;
      }
      data.push([location]);

      // lege regel
      data.push(['']);

      let colnum = 0;
      const opmerkingen = [];

      // 2. tabel met bepalingen en uitslagen per datum
      const head = [];
      head[colnum++] = 'Bepaling';

      const dates = this.dates;
      dates.forEach(function(date, dateIndex) {
        head[colnum++] = moment(date).format('DD-MM-YYYY HH:mm');
      });
      head[colnum++] = 'Ref';
      head[colnum++] = 'Eenheid';
      data.push(head);

      colnum = 0;

      const vm = this;

      // loop bepalingen
      this.bepalingen.forEach(function(item, index) {

        // nieuwe regel
        const row = [];
        row[colnum++] = item;

        // uitslagen voor deze bepaling
        const dateResults = vm.resultsPerBepaling[item];

        // loop door alle datums
        vm.dates.forEach(function(date, dateIndex) {
          const bepalingOnDate = dateResults[date];
          if (typeof bepalingOnDate !== 'undefined') {

            // uitslag
            let col = ''+bepalingOnDate.uitslag;

            // normaalwaarde indicator
            if (bepalingOnDate.normaalwaardeindicatie) {
              col += ' ['+bepalingOnDate.normaalwaardeindicatie+']';
            }

            // opmerking
            if (bepalingOnDate.opmerking) {
              opmerkingen[opmerkingen.length] = bepalingOnDate.opmerking;
              col += ' [opm'+opmerkingen.length+']';
            }

            row[colnum++] = col;
          } else {
            row[colnum++] = '-';
          }
        });

        // add ref value
        row[colnum] = '-';
        for (const date in dateResults) {
          const reading = dateResults[date];
          if (reading.ondergrens && reading.bovengrens) {
            row[colnum] = reading.ondergrens + ' - ' + reading.bovengrens;
            break;
          } else if (reading.ondergrens) {
            row[colnum] = reading.ondergrens;
            break;
          } else if (reading.bovengrens) {
            row[colnum] = reading.bovengrens;
            break;
          }
        }
        colnum++;

        // add unit
        row[colnum] = '-';
        for (const date in dateResults) {
          const reading = dateResults[date];
          if (reading.eenheid) {
            row[colnum] = reading.eenheid;
            break;
          }
        }
        data.push(row);
        colnum = 0;
      });

      // lege regel
      data.push(['']);

      // 3. opmerkingen die bij de uitslagen horen
      opmerkingen.forEach(function(item, index) {
        const row = [];
        row[0] = 'Opm'+(index+1);
        row[1] = item;
        data.push(row);
      });

      if (data.length === 0) {
        return this.$store.dispatch('notifications/addDangerAlert', {message: 'Geen resultaten om te kopieren'}, {root: true});
      }
      Copy.copyContentsToClipboard(data);
    },

    printButtonHandler() {

      let html = '';

      // bepaal aantal pagina's
      const amountOfDates = this.dates.length;
      const datesPerPage = 5;
      const pages = Math.ceil(amountOfDates / datesPerPage);

      let opmerkingen = [];

      // print pages
      for (let i = 0; i < pages; i++) {

        opmerkingen = [];

        // patient header
        html += '<b>';
        html += this.patient.name + '<br />';
        html += this.patient.birthday + ', ' + this.patient.age + ' jaar <br />';
        html += this.patient.location.name + ' - ' + this.patient.ward.name + '<br />';
        if (this.patient.room) {
          html += ', kamer ' + this.patient.room + '<br />';
        }
        html += '</b><br /><br />';

        // header
        html += '<table style="font-size:9px; width:100%;">';
        html += '<tr>';
        html += '<td style="width:200px; border-bottom:1px solid #999; font-weight:bold;"><br />Bepaling</td>';

        const minIndex = i * datesPerPage;
        const maxIndex = ((i+1) * datesPerPage) - 1;

        // loop door alle datums
        this.dates.forEach(function (date, dateIndex) {

          // toon alleen datums voor deze pagina
          if (dateIndex >= minIndex && dateIndex <= maxIndex) {
            html += '<td style="border-bottom:1px solid #999; font-weight:bold;">' + moment(date).format('DD-MM-YYYY') + ' <br /> ' + moment(date).format('HH:mm') + '</td>';
          }
        });
        html += '<td style="width:50px; border-bottom:1px solid #999; font-weight:bold;"><br />Ref</td>';
        html += '<td style="width:50px;border-bottom:1px solid #999; font-weight:bold;"><br />Eenheid</td>';
        html += '</tr>';

        // loop door alle bepalingen
        const vm = this;
        this.bepalingen.forEach(function(item, index) {
          html += '<tr><td>' + item + '</td>';

          // uitslagen voor deze bepaling
          const dateResults = vm.resultsPerBepaling[item];

          // loop door alle datums
          vm.dates.forEach(function(date, dateIndex) {

            // toon alleen datums voor deze pagina
            if (dateIndex >= minIndex && dateIndex <= maxIndex) {

              const bepalingOnDate = dateResults[date];
              if (typeof bepalingOnDate !== 'undefined') {

                // uitslag
                let col = '' + bepalingOnDate.uitslag;

                // normaalwaarde indicator
                if (bepalingOnDate.normaalwaardeindicatie) {
                  col += ' [' + bepalingOnDate.normaalwaardeindicatie + ']';
                }

                // opmerking
                if (bepalingOnDate.opmerking) {
                  opmerkingen[opmerkingen.length] = bepalingOnDate.opmerking;
                  col += ' [opm' + opmerkingen.length + ']';
                }

                html += '<td>' + col + '</td>';
              } else {
                html += '<td>-</td>';
              }
            }
          });

          // add ref value
          let refValue = '-';
          for (const date in dateResults) {
            const reading = dateResults[date];
            if (reading.ondergrens && reading.bovengrens) {
              refValue = reading.ondergrens + ' - ' + reading.bovengrens;
              break;
            } else if (reading.ondergrens) {
              refValue = reading.ondergrens;
              break;
            } else if (reading.bovengrens) {
              refValue = reading.bovengrens;
              break;
            }
          }
          html += '<td>' + refValue + '</td>';

          // add unit
          let unit = '-';
          for (const date in dateResults) {
            const reading = dateResults[date];
            if (reading.eenheid) {
              unit = reading.eenheid;
              break;
            }
          }
          html += '<td>' + unit + '</td>';
          html += '</tr>';
        });
        html += '</table>';

        // render opmerkingen
        html += '<table style="page-break-after: always; margin-top:25px; font-size:9px; width:100%">';
        html += '<tr><td colspan="2" style="font-weight:bold; border-bottom: 1px solid #999;">Opmerkingen</td>';
        if (opmerkingen.length > 0) {
          opmerkingen.forEach(function (item, index) {
            html += '<tr><td style="width:200px;">' + 'Opm' + (index + 1) + '</td><td>' + item + '</td></tr>';
          });
        } else {
          html += '<tr><td>Geen opmerkingen</td></tr>';
        }
        html += '</table>';
      }

      // roep mixin print method aan
      this.printHtml(html);
    }
  },

  watch: {
    //
  }
};
</script>
