<script>
/**
 * This Mixin helps components that need to find other components that are their parents or children.
 * Useful for finding siblings, or specific parents, to then build upon.
 */

export default {
  computed: {
    //
  },

  methods: {
    /**
     * @param componentElement Moet een $el zijn, dus als het een component / pagina betreft, is het this.$el
     * @param cssSelector
     * @returns {*}
     */
    find_siblings_css(componentElement, cssSelector) {
      return componentElement.querySelectorAll(cssSelector);
    },
    /**
     * Finds for a specific Parent Vue component and returns it as soon as there's a match
     *
     * @param parent_component_uid_property
     * @param levels_up
     * @returns {boolean|*|Vue}
     */
    find_matching_parent(parent_component_uid_property, levels_up = 6) {
      let count = 0;
      let parent = this.$parent;

      while (count <= levels_up) {
        // Als de parent leeg is, direct er uit
        if (!parent) {
          break;
        }
        if (parent[parent_component_uid_property] !== undefined) {
          return parent;
        }
        parent = parent.$parent;
        count++;
      }

      // If there are no matches, throw an error
      if (process.env.APP_ENV === 'development' || process.env.APP_ENV === 'testing') {
        console.warn('No parent with data/prop/computed "' + parent_component_uid_property + '" found for <' + this.componentToFileName(this) + '> component.');
      }
    },
    componentToFileName(component) {
      // WARNING: deze was veranderd in Vue3 en maakt gebruik van diepe interne Vue logica
      // kan in de toekomst dus onaangekondigd wijzigen.
      let componentFileName = component._.type.__file;

      // Deze exploden we het path van
      if (componentFileName !== undefined) {
        componentFileName = componentFileName.split('/');
        componentFileName = componentFileName[componentFileName.length -1];
        componentFileName = componentFileName.replace('.vue', '');
      }
      return componentFileName;
    },
  },

  watch: {
    //
  }
};
</script>
