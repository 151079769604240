import BaseModule from '/vue/store/modules/base_module';

const state = {
  ...BaseModule.state,
  search_endpoint: '/api/v1/logs/tromb',
  quick_filters: {errors: {label: 'Fouten', value: false}},
  sort_columns: [
    {column_name: 'created_at', direction: 'DESC'}
  ],
};

const getters = { ...BaseModule.getters };
const actions = { ...BaseModule.actions };
const mutations = { ...BaseModule.mutations };

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
