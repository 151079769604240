import {CodeSystem} from '@/domain/models/CodeSystem';

export class GeneratedCodeSystem { 
  public id: number;
  public oid: string;
  public url: string;

  constructor(id: number, oid: string, url: string) {
    this.id = id;
    this.oid = oid;
    this.url = url;
  }

  public static fromJson(json: unknown): CodeSystem {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new CodeSystem(
      parsedJson['id'] as number,
      parsedJson['oid'] as string,
      parsedJson['url'] as string,
    );
  }

  public static multipleFromJson(json: unknown): CodeSystem[] {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    const items: CodeSystem[] = [];
    for (const item of parsedJson) {
      items.push(this.fromJson(item));
    }
    return items;
  }
}
