<template>
    <div :class="'medimo-form-column ' + all_column_classes + ' ' + order_classes">
        <slot></slot>
    </div>
</template>

<script>
export default {
  components: {},

  props: {
    'column_classes': {default: 'col-auto'},

    // Used to change the order of columns when needed:
    // https://getbootstrap.com/docs/4.0/layout/grid/#reordering
    'order_classes': {default: ''},

    // Set to TRUE to disable padding on the columns, needed for some nested situations
    // When you want the grid, but not the margins
    'no_padding': {default: false},

    // Identical to no_padding, but follows the same logic as the MedimoFormRow, so it's defined for uniformity sake
    'no_margin': {default: false},

  },

  mixins: [],

  data: function () {
    return {
      //
    };
  },

  computed: {
    all_column_classes() {
      if (this.no_padding || this.no_margin) {
        return 'pt-0 pb-0 ' + ' ' + this.column_classes;
      }
      return this.column_classes;
    }
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    //
  },

  watch: {
    //
  }
};
</script>
