import {store} from '@/vue/store';
import {MedicationOverviewRow} from "@/domain/models/MedicationOverviewRow";
import utility from "@/vue/utility/utility";
import Dates from "@/vue/utility/dates";
import {GroupedMedicationOverviewRows, ObjectOfMedicationOverviewRows, SortedGroupedMedicationOverviewRows} from "@/interfaces/mp9/InstructionInterfaces";
import moment from "moment";

export class MedicationOverviewController {

  private storeName = 'mp9/medication_overview';

  public async read(patientId: number): Promise<MedicationOverviewRow[]|null> {
    const response = await store.dispatch('api/getEndpoint', {
      endpoint: '/api/v1/mp9/patient/' + patientId + '/medication-overview',
    });

    this.setDataOnStore(response.data.medicationOverviewRows);
    store.commit('status_pending_links/set_data', response.data.statusPendingLinks);

    return MedicationOverviewRow.multipleFromJson(response.data.medicationOverviewRows);
  }

  public clearData(): void {
    store.commit(this.storeName + '/set_data', []);
  }

  private setDataOnStore(data: unknown): MedicationOverviewRow[] | null {
    store.commit(this.storeName + '/set_data', data);
    return this.dataFromStore();
  }

  public dataFromStore(): MedicationOverviewRow[] | null {
    return store.getters[this.storeName + '/data'];
  }

  public medicationOverviewRowsGrouped() {
    return this.groupMedicationOverviewRows(this.medicationOverviewRows(), 'pharmaceuticalTreatmentId', 'medicationAgreementId');
  }

  public stoppedMedicationOverviewRowsGrouped() {
    return this.groupMedicationOverviewRows(this.stoppedMedicationOverviewRows(), 'pharmaceuticalTreatmentId', 'medicationAgreementId');
  }

  public medicalDevicesGrouped() {
    return this.groupMedicationOverviewRows(this.medicalDevices(), 'pharmaceuticalTreatmentId', 'medicationAgreementId');
  }

  public medicationUsesGrouped() {
    return this.groupMedicationOverviewRows(this.medicationUses(), 'pharmaceuticalTreatmentId', 'medicationUseId');
  }

  private groupMedicationOverviewRows(medicationOverviewRows: ObjectOfMedicationOverviewRows, firstKey: 'pharmaceuticalTreatmentId', secondKey: 'medicationAgreementId' | 'medicationUseId'): SortedGroupedMedicationOverviewRows {
    const groupedMedicationOverviewRows: GroupedMedicationOverviewRows = {};

    const arrayOfRows = utility.convert_object_to_array(medicationOverviewRows) as Array<MedicationOverviewRow>;

    arrayOfRows.forEach(medicationOverviewRow => {
      const indexOne = medicationOverviewRow[firstKey]! ? medicationOverviewRow[firstKey] : 0;
      const indexTwo = medicationOverviewRow[secondKey] !== null ? medicationOverviewRow[secondKey]! : 0;

      if (groupedMedicationOverviewRows[indexOne] === undefined) {
        groupedMedicationOverviewRows[indexOne] = {
          drugName: medicationOverviewRow.drugName,
          usageType: medicationOverviewRow.usageType,
          drugAtc: medicationOverviewRow.drugAtc,
          startDateTime: medicationOverviewRow.startDateTime,
          medications: {},
        };
      }
      if (groupedMedicationOverviewRows[indexOne]!.medications[indexTwo]! === undefined) {
        groupedMedicationOverviewRows[indexOne]!.medications[indexTwo] = [];
      }

      groupedMedicationOverviewRows[indexOne]!.medications[indexTwo]!.push(medicationOverviewRow);
    });

    return this.sortGroupedMedicationOverviewRows(groupedMedicationOverviewRows);
  }

  // Sorten moet hier opnieuw op Pharmaceutical niveau
  private sortGroupedMedicationOverviewRows(groupedMedicationOverviewRows: GroupedMedicationOverviewRows): SortedGroupedMedicationOverviewRows {
    const array: SortedGroupedMedicationOverviewRows = [];
    Object.keys(groupedMedicationOverviewRows).forEach(pharmaceuticalID => {
      array.push(groupedMedicationOverviewRows[pharmaceuticalID]!);
    });

    // We moeten na de groepering weer re-sorten
    return utility.sort_data_by_columns(array, store.getters[this.storeName + '/sort_columns']);
  }

  public medicationOverviewRows(showAll = true, showIfStopped = false): ObjectOfMedicationOverviewRows {
    return utility.convert_array_to_object(store.getters[this.storeName + '/sorted_local_data'].filter((medicationOverviewRow: MedicationOverviewRow) => {
      return medicationOverviewRow.isDosingInstruction && this.instructionIsVisible(medicationOverviewRow, true, true, showAll, showIfStopped);
    })) as ObjectOfMedicationOverviewRows;
  }
  public stoppedMedicationOverviewRows(): ObjectOfMedicationOverviewRows {
    return utility.convert_array_to_object(utility.clone(store.getters[this.storeName + '/sorted_local_data']).filter((medicationOverviewRow: MedicationOverviewRow) => {
      return medicationOverviewRow.isDosingInstruction && this.isStoppedRow(medicationOverviewRow);
    })) as ObjectOfMedicationOverviewRows;
  }
  public medicationUses(showAll = true, showIfStopped = false): ObjectOfMedicationOverviewRows {
    return utility.convert_array_to_object(store.getters[this.storeName + '/sorted_local_data'].filter((medicationOverviewRow: MedicationOverviewRow) => {
      return medicationOverviewRow.isMedicationUse && this.instructionIsVisible(medicationOverviewRow, true, true, showAll, showIfStopped);
    })) as ObjectOfMedicationOverviewRows;
  }
  public medicalDevices(showAll = true, showIfStopped = false): ObjectOfMedicationOverviewRows {
    return utility.convert_array_to_object(store.getters[this.storeName + '/sorted_local_data'].filter((medicationOverviewRow: MedicationOverviewRow) => {
      return medicationOverviewRow.isMedicalDevice && this.instructionIsVisible(medicationOverviewRow, true, true, showAll, showIfStopped);
    })) as ObjectOfMedicationOverviewRows;
  }

  public isStoppedRow(medicationOverviewRow: MedicationOverviewRow): boolean {
    return !!(medicationOverviewRow.endDateTime && moment(medicationOverviewRow.endDateTime).isBefore(moment()));
  }

  public instructionIsVisible(medicationOverviewRow: MedicationOverviewRow, isFirstMedicationOverviewRow: boolean, hasFutureInstructions: boolean, showAll: boolean, showIfStopped: boolean) {
    // Always show temprarily stopped rows
    // Always show when show_if_stopped
    if (medicationOverviewRow.isTemporaryStop || showIfStopped) {
      return true;
    }

    // If stopped in past dont show
    if (medicationOverviewRow.endDateTime && ! Dates.setDate(medicationOverviewRow.endDateTime).isTodayOrFuture()) {
      return false;
    }

    // Always show if it's the first row
    if (isFirstMedicationOverviewRow) {
      return true;
    }

    // Always show if it's part of a schema, and showAll is true
    if (hasFutureInstructions && showAll) {
      return true;
    }

    // When all should be open, return all
    if (!showAll) {
      return false;
    }

    // When it's between the stop and end date
    else if (Dates.nowIsBetweenDates(medicationOverviewRow.startDateTime, medicationOverviewRow.endDateTime)) {
      return true;
    }

    return false;
  }

  /*
   |--------------------------------------------------------------------------
   | Table Sort Methods
   |--------------------------------------------------------------------------
   */
  /**
   * @param sortState Can be either 'start_moment_datetime' or 'ABC'
   */
  public switchSortTo(sortState: string) {
    if (sortState === 'startDate') {
      store.commit(this.storeName + '/sort', {column_name: 'startDate', direction: 'DESC'});
    }
    if (sortState === 'ABC') {
      store.commit(this.storeName + '/sort', {column_name: 'drugName', direction: 'ASC'});
      store.commit(this.storeName + '/sort', {column_name: 'startDate', direction: 'ASC'});
    }
  }
}
