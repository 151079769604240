import {MedicationUse} from '@/domain/models/MedicationUse';
import {CodeSystem} from '@/domain/models/CodeSystem';
import {IdentifierObject} from '@/domain/models/IdentifierObject';
import {PharmaceuticalProduct} from '@/domain/models/PharmaceuticalProduct';
import {TimeIntervalObject} from '@/domain/models/TimeIntervalObject';
import {InstructionsForUse} from '@/domain/models/InstructionsForUse';
import {CodeValue} from '@/domain/models/CodeValue';
import {PractitionerRole} from '@/domain/models/PractitionerRole';
import {HealthcareProfessional} from '@/domain/models/HealthcareProfessional';
import {HealthcareProvider} from '@/domain/models/HealthcareProvider';
import moment from 'moment';

export class GeneratedMedicationUse { 
  public id: number;
  public patientId: number;
  public description: string;
  public externalIdentifierSystem: CodeSystem;
  public externalIdentifier: IdentifierObject;
  public pharmaceuticalProduct: PharmaceuticalProduct;
  public medicationAgreementId: number | null;
  public administrationAgreementId: number | null;
  public pharmaceuticalTreatmentId: number;
  public periodOfUse: TimeIntervalObject;
  public startDateTime: moment.Moment | null;
  public endDateTime: moment.Moment | null;
  public instructionsForUse: InstructionsForUse;
  public stopType: CodeValue | null;
  public reasonModificationOrDiscontinuation: CodeValue | null;
  public reasonModificationOrDiscontinuationText: string | null;
  public comment: string | null;
  public reasonForUse: string | null;
  public useIndicator: boolean | null;
  public asAgreedIndicator: boolean | null;
  public prescriber: PractitionerRole | null;
  public assertedDateTime: moment.Moment;
  public informantIsPatient: boolean;
  public informantContactPerson: string | null;
  public authorIsPatient: boolean;
  public authorProfessional: HealthcareProfessional | null;
  public authorProvider: HealthcareProvider | null;
  public copyIndicator: boolean;
  public distributedIndicator: boolean;

  constructor(id: number, patientId: number, description: string, externalIdentifierSystem: CodeSystem, externalIdentifier: IdentifierObject, pharmaceuticalProduct: PharmaceuticalProduct, medicationAgreementId: number | null = null, administrationAgreementId: number | null = null, pharmaceuticalTreatmentId: number, periodOfUse: TimeIntervalObject, startDateTime: moment.Moment | null = null, endDateTime: moment.Moment | null = null, instructionsForUse: InstructionsForUse, stopType: CodeValue | null = null, reasonModificationOrDiscontinuation: CodeValue | null = null, reasonModificationOrDiscontinuationText: string | null = null, comment: string | null = null, reasonForUse: string | null = null, useIndicator: boolean | null = null, asAgreedIndicator: boolean | null = null, prescriber: PractitionerRole | null = null, assertedDateTime: moment.Moment, informantIsPatient: boolean, informantContactPerson: string | null = null, authorIsPatient: boolean, authorProfessional: HealthcareProfessional | null = null, authorProvider: HealthcareProvider | null = null, copyIndicator: boolean, distributedIndicator: boolean) {
    this.id = id;
    this.patientId = patientId;
    this.description = description;
    this.externalIdentifierSystem = externalIdentifierSystem;
    this.externalIdentifier = externalIdentifier;
    this.pharmaceuticalProduct = pharmaceuticalProduct;
    this.medicationAgreementId = medicationAgreementId;
    this.administrationAgreementId = administrationAgreementId;
    this.pharmaceuticalTreatmentId = pharmaceuticalTreatmentId;
    this.periodOfUse = periodOfUse;
    this.startDateTime = startDateTime;
    this.endDateTime = endDateTime;
    this.instructionsForUse = instructionsForUse;
    this.stopType = stopType;
    this.reasonModificationOrDiscontinuation = reasonModificationOrDiscontinuation;
    this.reasonModificationOrDiscontinuationText = reasonModificationOrDiscontinuationText;
    this.comment = comment;
    this.reasonForUse = reasonForUse;
    this.useIndicator = useIndicator;
    this.asAgreedIndicator = asAgreedIndicator;
    this.prescriber = prescriber;
    this.assertedDateTime = assertedDateTime;
    this.informantIsPatient = informantIsPatient;
    this.informantContactPerson = informantContactPerson;
    this.authorIsPatient = authorIsPatient;
    this.authorProfessional = authorProfessional;
    this.authorProvider = authorProvider;
    this.copyIndicator = copyIndicator;
    this.distributedIndicator = distributedIndicator;
  }

  public static fromJson(json: unknown): MedicationUse {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new MedicationUse(
      parsedJson['id'] as number,
      parsedJson['patientId'] as number,
      parsedJson['description'] as string,
      CodeSystem.fromJson(parsedJson['externalIdentifierSystem']),
      IdentifierObject.fromJson(parsedJson['externalIdentifier']),
      PharmaceuticalProduct.fromJson(parsedJson['pharmaceuticalProduct']),
      parsedJson['medicationAgreementId'] as number,
      parsedJson['administrationAgreementId'] as number,
      parsedJson['pharmaceuticalTreatmentId'] as number,
      TimeIntervalObject.fromJson(parsedJson['periodOfUse']),
      parsedJson['startDateTime'] ? moment.parseZone(parsedJson['startDateTime']) : null,
      parsedJson['endDateTime'] ? moment.parseZone(parsedJson['endDateTime']) : null,
      InstructionsForUse.fromJson(parsedJson['instructionsForUse']),
      parsedJson['stopType'] ? CodeValue.fromJson(parsedJson['stopType']) : null,
      parsedJson['reasonModificationOrDiscontinuation'] ? CodeValue.fromJson(parsedJson['reasonModificationOrDiscontinuation']) : null,
      parsedJson['reasonModificationOrDiscontinuationText'] as string,
      parsedJson['comment'] as string,
      parsedJson['reasonForUse'] as string,
      parsedJson['useIndicator'] as boolean,
      parsedJson['asAgreedIndicator'] as boolean,
      parsedJson['prescriber'] ? PractitionerRole.fromJson(parsedJson['prescriber']) : null,
      moment.parseZone(parsedJson['assertedDateTime']),
      parsedJson['informantIsPatient'] as boolean,
      parsedJson['informantContactPerson'] as string,
      parsedJson['authorIsPatient'] as boolean,
      parsedJson['authorProfessional'] ? HealthcareProfessional.fromJson(parsedJson['authorProfessional']) : null,
      parsedJson['authorProvider'] ? HealthcareProvider.fromJson(parsedJson['authorProvider']) : null,
      parsedJson['copyIndicator'] as boolean,
      parsedJson['distributedIndicator'] as boolean,
    );
  }

  public static multipleFromJson(json: unknown): MedicationUse[] {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    const items: MedicationUse[] = [];
    for (const item of parsedJson) {
      items.push(this.fromJson(item));
    }
    return items;
  }
}
