<template>
  <div class="col-md-4 mt-2 mt-md-0" v-if="patient.authority.canViewIntolerances">

    <div v-if="patient.authority.canEditIntolerances" @click="$emit('showModalIntolerances')" class="w-100 p-2 mb-1 text-primary bg-medimo-10 is-clickable hover">
      <strong>Allergieën / Intoleranties</strong>
    </div>
    <div v-else class="w-100 p-2 mb-1 text-primary bg-medimo-10">
      <strong>Allergieën / Intoleranties</strong>
    </div>

    <div class="p-1 ml-1" v-if="intolerances === null">
      - Onbekend of er allergieën/intoleranties zijn
    </div>
    <div class="p-1 ml-1" v-else-if="intolerances.length === 0">
      - Geen allergieën/intoleranties aanwezig
    </div>
    <div class="p-1 ml-1" v-for="intolerance in intolerances">
      - {{ intolerance.title }}
      <span class="text-danger" v-if="typeof intolerance.attributes !== 'undefined'" v-for="attribute in intolerance.attributes">{{ attribute }}</span>
    </div>
  </div>
</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import NavigatesProgrammatically from '@/vue/mixins/navigation/NavigatesProgrammatically';

export default {
  emits: ['showModalIntolerances'],

  mixins: [
    BaseComponentsMixin,
    NavigatesProgrammatically
  ],

  components: {

  },

  props: [
    'patient_id'
  ],

  computed: {
    patient() {
      return this.$store.getters['patients/find'](this.patient_id);
    },
    intolerances() {
      return this.$store.getters['intolerances/active'];
    },
  },

  methods: {

  }
};
</script>
