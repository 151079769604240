<template>
    <master-layout breadcrumb_title="Cluster Select">

        <page-block id="cluster-select">
            <div class="h1 mb-2">
              Selecteer uw cluster
            </div>

            <medimo-external-data-table
                    store_name="clusters"
                    :name="'cluster-selector-datatable'"
                    :show_loader="loading"
                    :minimum_height="true"
                    :number_of_rows="clusters_data.length"

                    :privacy_filter="false"
            >
                <medimo-thead>
                    <medimo-tr>
                        <medimo-th column_classes="col-12 col-sm-7"
                                   store_name="clusters"
                                   column_name_1="Naam"
                                   sort_column_1="name"
                        ></medimo-th>
                        <medimo-th column_classes="col-12 col-sm-5"
                                   store_name="clusters"
                                   column_name_1="Plaats"
                                   sort_column_1="city"
                        ></medimo-th>
                    </medimo-tr>
                </medimo-thead>
                <medimo-tbody v-if="clusters_data.length" id="cluster-select-body">
                    <cluster-selector-row
                            @startLoading="loading = true"
                            @stopLoading="loading = false"
                            :returnPath="returnPath"
                            :key="cluster.id"
                            :cluster="cluster" v-for="cluster in clusters_data"
                    ></cluster-selector-row>
                </medimo-tbody>

            </medimo-external-data-table>
        </page-block>

    </master-layout>
</template>

<script>
import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import ClusterSelectorRow from './ClusterSelectorRow';

export default {
  components: {
    ClusterSelectorRow
  },

  props: {
    // Used with InitialData, prevents an API call and uses these clusters instead
    // Uses the data structure of Laravel's Resource with data, meta and links properties
    clustersData: {
      default: () => {
        return {
          data: [],
        };
      }
    },
    // Used to redirect the user after a successful Cluster switch
    returnPath: {default: ''},
  },

  mixins: [
    BaseComponentsMixin,
  ],

  data: function () {
    return {
      loading: false,
    };
  },

  computed: {
    // Used for the Programmatic navigation
    to() {
      return this.returnPath;
    },
    clusters_data: {
      get() {
        return this.$store.state.clusters.search_results;
      },
      set(value) {
        this.$store.commit('clusters/set_search_results', value);
      }
    },
    clusters_meta: {
      get() {
        return this.$store.state.clusters.search_meta;
      },
      set(value) {
        this.$store.commit('clusters/set_search_meta', value);
      }
    },
    showClusterSelector: {
      get() {
        return this.$store.state.settings.navigation.showClusterSelector;
      },
      set(value) {
        this.$store.commit('settings/navigation/setShowClusterSelector', value);
      }
    },
    sort_columns() {
      return this.$store.state.clusters.sort_columns;
    },
    session_cluster_data() {
      return this.$store.state.current_user.data.clusters;
    }
  },

  created() {
    if (this.session_cluster_data.data.length > 0) {
      this.clusters_data = this.session_cluster_data.data;
      this.clusters_meta = this.session_cluster_data.meta;
    }
    if (typeof this.clustersData.meta !== 'undefined') {
      this.clusters_data = this.clustersData.data;
      this.clusters_meta = this.clustersData.meta;
    } else {
      // It happens thanks to the MedimoDataTable
    }
  },

  mounted() {
    //
  },

  methods: {
    //
  },

  watch: {
    //
  }
};
</script>
