import BaseModule from '@/vue/store/modules/base_module';

const state = {
  ...BaseModule.state,
  sort_columns: [
    {
      column_name: 'title', direction: "ASC",
    }
  ],
};

// getters
const getters = {
  ...BaseModule.getters
};

// actions
const actions = {
  ...BaseModule.actions,
};

// mutations
const mutations = {
  ...BaseModule.mutations,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
