<template>
  <!-- Als het een Element of Text type is, renderen we hem -->
  <div class="xml data-tree-branch" :style="'margin-left: ' + getMarginValue(layer)" v-if="nodeType !== 0">
    <div class="data-tree-tag">
      <div class="data-tree-tag-open" :class="{clickable: collapsable, inline: !hasContent}" @click.stop="collapsable ? collapsed=!collapsed : ''" v-show="nodeType === 1 && show">
        <div :style="'opacity: ' + (matchesFilter ? '1' : opacityHidden)">&lt;</div>
        <div class="data-tree-data-name" :style="'opacity: ' + (matchesFilterInput(tagName) ? '1' : opacityHidden)">
          {{ tagName }}
        </div>
        <div v-if="hasAttributes">
          <div v-for="(attribute, index) in element.attributes" :key="index">
            <div class="data-tree-attr" :style="'opacity: ' + ((matchesFilterInput(attribute.value)) || (matchesFilterInput(attribute.name)) ? '1' : opacityHidden)">
              <div class="data-tree-attr-name">
                &nbsp;{{ attribute.name }}
              </div>
              <div>=</div>
              <div class="data-tree-attr-value">"<em>{{ attribute.value }}</em>"</div>
            </div>
          </div>
        </div>
        <div :style="'opacity: ' + (matchesFilter ? '1' : opacityHidden)">></div>

        <div v-if="nodeType === 1 && show && collapsable">
          <!-- Dit zijn de collapse icons -->
          <div @click.stop="collapsed=false" style="cursor:pointer" v-if="collapsed">
            <fa-icon :icon="collapsedIcon" />
          </div>
          <!-- Dit zijn de collapse icons -->
          <div @click.stop="collapsed=true" style="cursor:pointer" v-if="!collapsed">
            <fa-icon :icon="expandedIcon" />
          </div>
        </div>
      </div>

      <!-- Hier staat alles wat _in_ de tag zit, dus of andere tags, of TEXT content -->
      <div class="tag-content" v-show="!collapsed">
        <!--
            En hier duiken we dieper, indien nodig (als er kinderen zijn)
            Anders tonen we gewoon de data

            Als er Children van nodeType Element zijn, gaan we recursive door.
            Zo niet, dan tonen we de text "as is"
         -->
        <xml-tree-branch v-if="!hasOnlyTextChildren"
                         v-for="(child, index) in children" :key="index"
                         :element="child"
                         :layer="(layer + 1)"
                         :query-value="queryValue"
                         :only-show-matches="onlyShowMatches"
                         :fade-match-content="fadeMatchContent"
                         :show-match-content="showMatchContent"
                         :parent-matches-filter="matchesFilter || parentMatchesFilter"
                         :auto-collapse-after="autoCollapseAfter"
                         :properties-to-show="propertiesToShow"
        ></xml-tree-branch>
        <!-- De waarde geven we nog 1 indentation extra -->
        <div v-else class="data-tree-tag-value" :style="'margin-left: ' + (getMarginValue((layer + 1))) + '; opacity: ' + (matchesFilterInput(value) ? '1' : opacityHidden)" v-show="show">
          {{ value }}
        </div>
      </div>
      <!-- Dit als hij gecollapsed is -->
      <div v-show="collapsed" @click.stop="collapsed = false" class="clickable mr-2">
        ...
      </div>

      <!-- Open en close zien we alleen als het een Element type is -->
      <!-- Als de inhoud leeg is, tonen we hem op 1 regel, da's mooier -->
      <div class="data-tree-tag-close" :class="{clickable: collapsable, inline: !hasContent}" @click.stop="collapsable ? collapsed=!collapsed : ''" v-show="nodeType === 1 && show">
        <div :style="'opacity: ' + (matchesFilter ? '1' : opacityHidden)">&lt;/</div>
        <div class="data-tree-data-name" :style="'opacity: ' + (matchesFilterInput(tagName) ? '1' : opacityHidden)">{{ tagName }}</div>
        <div :style="'opacity: ' + (matchesFilter ? '1' : opacityHidden)">></div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import utility from '@/vue/utility/utility';
import JsonTreeBranch from "@/vue/components/data-viewers/Json/JsonTreeBranch.vue";

export default {
  name: 'xmlTreeBranch',
  emits: ['showProperty'],

  components: {JsonTreeBranch},

  props: {
    queryValue: '',
    onlyShowMatches: {required: true},
    autoCollapseAfter: {required: true},
    fadeMatchContent: {default: false}, // Content van matches krijgt dan opacity X
    showMatchContent: {default: true}, // Content van matches is zichtbaar
    parentMatchesFilter: {default: false},
    element: {default: undefined}, // Dit is de XML "data"
    layer: {default: 0}, // gebruiken we voor de marge, geeft aan hoe diep we zitten in de recursiveness
    propertiesToShow: {default: []},
  },

  mixins: [BaseComponentsMixin],

  data: function () {
    return {
      collapsed: false,
      collapsedIcon: 'fa-solid fa-caret-up',
      expandedIcon: 'fa-solid fa-caret-down',
    };
  },

  computed: {
    opacityHidden() {
      return this.fadeMatchContent ? '0.15' : 1;
    },
    children() {
      const children = [];
      let node = this.element.firstChild;
      while (node) {
        children.push(node);
        node = node.nextSibling;
      }

      return children;
    },
    hasOnlyTextChildren() {
      if (!this.hasChildren) {
        return false;
      }

      const textChildren = this.children.filter(child => {
        return child.nodeType === 3;
      });

      // Als het aantal childs met nodeType Text gelijk is aan het totaal zijn we zeker:
      return textChildren.length === this.children.length;
    },
    show() {
      if (this.parentMatchesFilter && this.showMatchContent) {
        return true;
      }
      else if (this.onlyShowMatches) {
        return this.matchesFilter;
      }
      return true;
    },
    matchesFilter() {
      return this.tagMatchesFilter || this.attributesMatchFilter || this.contentMatchesFilter;
    },
    tagMatchesFilter() {
      return this.matchesFilterInput(this.tagName);
    },
    attributesMatchFilter() {
      let attributeMatchesFilter = false;
      if (this.hasAttributes) {
        Object.values(this.element.attributes).forEach(attribute => {
          if (this.matchesFilterInput(attribute.value) || this.matchesFilterInput(attribute.name)) {
            attributeMatchesFilter = true;
          }
        });
      }
      return attributeMatchesFilter;
    },
    contentMatchesFilter() {
      // De \n komt voor bij hoger liggende nodes. Door hier op te filteren is alleen de directe parent node _met_ de tekst true
      return this.element.textContent.includes(this.queryValue) && !this.element.textContent.includes("\n");
    },
    hasAttributes() {
      if (this.nodeType === 1) {
        return this.element.hasAttributes();
      }
      return false;
    },
    hasChildren() {
      return this.children.length;
    },
    tagName() {
      if (this.nodeType === 1) {
        return this.element.nodeName;
      }
      if (this.nodeType === 3) {
        return this.element.data.trim();
      }
      return 'noTagName';
    },
    nodeType() {
      // https://developer.mozilla.org/en-US/docs/Web/API/Node/nodeType
      // 1 = Element
      // 3 = Text
      if (typeof this.element.nodeType === 'undefined') {
        return 0;
      }
      return this.element.nodeType;
    },
    value() {
      if (this.children.length === 1) {
        if (this.children[0].nodeType === 3) {
          return this.children[0].data.trim();
        }
      }
      return '';
    },
    hasContent() {
      return (this.value.length || this.children.length > 0) && !this.collapsed;
    },
    collapsable() {
      return this.hasChildren && !this.hasOnlyTextChildren;
    }
  },

  created() {
    if ((this.layer > this.autoCollapseAfter && this.collapsable) || this.propertiesToShow.length !== 0) {
      this.collapsed = true;
    }
    if (this.propertiesToShow.includes(this.jsonKey)) {
      this.collapsed = false;
      this.$emit("showProperty");
    }
  },

  methods: {
    showProperty() {
      this.collapsed = false;
      this.$emit("showProperty");
    },
    matchesFilterInput(value) {
      if (this.queryValue === '') {
        return true;
      }
      return utility.string_contains_query(value, this.queryValue);
    },
    getMarginValue(layer) {
      if (layer < 3) {
        return '0em;';
      }
      return '1.5em;';
    },
    autoCollapse() {
      if (this.layer > this.autoCollapseAfter && this.collapsable) {
        this.collapsed = true;
      }
    }
  },

  watch: {
    queryValue(query) {
      // Als er een query is willen we hem automatisch open klappen, anders geldt de auto-collapse
      if (query.length) {
        this.collapsed = false;
        return;
      }
      this.autoCollapse();
    }
  }
};
</script>
<style lang="scss" scoped>
  div {
    display: inline;

    &.data-tree-branch, .data-tree-tag-value {
      display: block;
    }
  }
</style>
