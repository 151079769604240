<template>
  <div class="protocol-item">
    <template v-if="item.show">
      <div class="item-text">
        <div v-if="options.length > 0 && !item.type" class="options">
          <div v-for="option in options" @click="selectOption(option)" :id="getOptionId(option)" class="button">
            <fa-icon icon="fa-solid fa-plus" /> {{ getOptionTitle(option) }}
          </div>
        </div>
        <div @click="selectOption(item.type)" v-else class="title">
          <div v-html="title.html"></div>
          <div><pre>{{ showHtmlEntities(title.txt) }}</pre></div>
        </div>
      </div>

      <div class="remove">
        <template v-if="(item.type && item.parent===false && item.children.length===0) || (item.children.length===0 && item.parent!==false)">
          <fa-icon icon="fa-solid fa-times" @click="clear()" />
        </template>
      </div>
      <div class="clear"></div>
      <div class="addBottom">
        <fa-icon v-if="showAddBottom()" icon="fa-solid fa-plus" @click="addBottom()" />
      </div>
      <div class="addRight">
        <fa-icon v-if="showAddRight()" icon="fa-solid fa-plus" @click="addRight()" :id="getOptionId('plus')" />
        <fa-icon v-if="showArrow()" icon="fa-solid fa-arrow-right" />
      </div>
    </template>
  </div>
</template>

<script>
import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import OptionHelper from '/modules/protocol/OptionHelper';

export default {
  props: {
    ruleid: {required: true},
    item: {required: true},
    options: {required: true}
  },
  data: function () {
    return {
      displayOptions: true,
    };
  },
  mixins: [BaseComponentsMixin],
  computed: {
    title() {
      if (this.item.title) {
        return (new OptionHelper(this.language)).getTitle(this.item);
      } else {
        return {html: this.getOptionTitle(this.item.type), 'txt': ''};
      }
    },
    language() {
      return this.$store.state.current_user.data.language;
    },
  },
  methods: {
    getOptionId(option) {
      //Id is used in acceptance tests:
      return 'option' + this.ruleid + '' + this.item.row + '' + this.item.col + '' + option;
    },
    getOptionTitle: function (option) {
      if (option === OptionHelper.startOption) return 'Start medicatie';
      if (option === OptionHelper.changeOption) return 'Wijzig medicatie';
      if (option === OptionHelper.stopOption) return 'Stop medicatie';
      if (option === OptionHelper.actionOption) return 'Voeg actie toe';
      if (option === OptionHelper.filterOption) return 'Filter criteria';
      if (option === OptionHelper.filterStatusOption) return 'Zoek medicatie';
      if (option === OptionHelper.stopFoundStatusOption) return 'Stop gevonden medicatie';
      if (option === OptionHelper.customQuestionOption) return 'Stel vraag';
      if (option === OptionHelper.customAnswerOption) return 'Antwoordoptie';
    },
    clear: function () {
      this.$emit('clear', this.ruleid, this.item);
    },
    showAddRight: function () {
      return !(
        // do NOT show when:
        !this.item.type ||
        this.item.children.length > 0 ||
        this.item.type === OptionHelper.startOption && this.item.values.usageType !== 'C' ||
        this.item.type === OptionHelper.startOption && this.item.values.usageType === 'S' ||
        this.item.type === OptionHelper.changeOption && this.item.values.usageType === 'S' ||
        this.item.type === OptionHelper.actionOption ||
        this.item.type === OptionHelper.stopOption ||
        this.item.type === OptionHelper.stopFoundStatusOption
      );
    },
    showArrow: function () {
      return this.item.type && this.item.children.length > 0;
    },
    addRight: function () {
      this.$emit('add', 'right', this.ruleid, this.item);
    },
    showAddBottom: function () {
      if (this.item.col === 0) return false;

      return this.item.type && this.item.type !== OptionHelper.stopOption && this.item.type !== OptionHelper.changeOption;
    },
    addBottom: function () {
      this.$emit('add', 'bottom', this.ruleid, this.item);
    },
    selectOption: function (option) {
      this.$emit('select', option, this.ruleid, this.item);
    },
    showHtmlEntities(input) {
      const doc = new DOMParser().parseFromString(input, "text/html");
      return doc.documentElement.textContent;
    }
  }
};
</script>

<style scoped lang="scss">
pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}
svg.svg-inline--fa {
  color: #bababa;
  cursor: pointer;
}
.protocol-item {
  height: 100%;
  padding: 0 0 10px 10px;
  .item-text {
    float: left;
    width: 195px;
    min-height: 10px;
    .options {
      width: 190px;
      padding-top: 5px;
      .button {
        color: #444;
        background-color: #F3F3F3;
        //padding: 8px;
        //height: 30px;

        width: 100%;
        text-align: left;
        cursor: pointer;
        padding: 1px 1px 1px 10px;
        height: 25px;
        margin-top: 1px;
        margin-bottom: 1px;
        border: 1px solid #BABABA;
      }
    }
    .title {
      margin-bottom: 15px;
      padding-top: 5px;
      cursor:pointer;
      text-align: left;
    }
  }
  .remove {
    width:14px;
    min-height: 50px;
    position: absolute;
    top: 3px;
    right: 0;
  }
  .addBottom {
    position:absolute;
    bottom: 0;
    left: 95px;
  }
  .addRight {
    position:absolute;
    bottom: 30%;
    right: 3px;
  }
}
</style>
