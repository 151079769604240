<template>
    <medi-modal
        :loading="loading"
        :title="modalTitle"
        size="medium"
        @closed="onClosed()"
    >
        <medimo-form>

            <medimo-form-row>
                <medimo-form-column column_classes="col-12">
                    <medimo-labeled-input
                        :disabled="true"
                        label_column_classes="col-12 col-sm-3"
                        label="Code"
                        name="code"
                        v-model="code"
                    ></medimo-labeled-input>
                </medimo-form-column>
            </medimo-form-row>

            <medimo-form-row>
                <medimo-form-column column_classes="col-12">
                    <medimo-validated-input
                        label_column_classes="col-12 col-sm-3"
                        label="Episode"
                        placeholder=""
                        name="title"
                        v-model="title">
                    </medimo-validated-input>
                </medimo-form-column>
            </medimo-form-row>

            <medimo-form-row>
                <medimo-form-column column_classes="col-12">
                    <medimo-validated-date-time-picker
                        label_column_classes="col-12 col-sm-3"
                        label="Datum vanaf"
                        datetime_format="DD-MM-YYYY HH:mm"
                        name="start_date"
                        v-model="start_date">
                    </medimo-validated-date-time-picker>
                </medimo-form-column>
            </medimo-form-row>

            <medimo-form-row>
                <medimo-form-column column_classes="col-12">
                    <medimo-validated-date-time-picker
                        label_column_classes="col-12 col-sm-3"
                        label="Datum tot"
                        datetime_format="DD-MM-YYYY HH:mm"
                        name="end_date"
                        placeholder="(Optioneel)"
                        v-model="end_date">
                    </medimo-validated-date-time-picker>
                </medimo-form-column>
            </medimo-form-row>

        </medimo-form>

        <template v-slot:action-buttons v-if="!confirm_delete">
            <medimo-button class="mr-auto" type="danger" @click.stop="confirm_delete = true">
                Verwijderen
            </medimo-button>
            <medimo-button :disabled="saveButtonDisabled" @click="update" class="mr-2">
                Opslaan
            </medimo-button>
            <medimo-button @click="close(false)" type="outline-medimo">
                Annuleren
            </medimo-button>
        </template>

        <template v-slot:action-buttons v-else>
            <medimo-notification type="danger" class="mr-auto col-md-6">
                <fa-icon icon="fa-solid fa-bell" class="bell" size="lg" /> Weet je het zeker?
            </medimo-notification>
            <medimo-button @click="confirm_delete = false" type="outline-primary">
                Annuleren
            </medimo-button>
            <medimo-button @click.prevent="remove">
                Ja, ik weet het zeker
            </medimo-button>
        </template>

    </medi-modal>
</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import MedimoAlert from '@/vue/components/general/MedimoAlert';
import MedimoNotification from '@/vue/components/general/MedimoNotification';

export default {
  components: {
    MedimoNotification,
    MedimoAlert
  },

  emits: ['closed'],

  props: [
    'episode',
    'trigger'
  ],

  mixins: [
    BaseComponentsMixin
  ],

  data: function () {
    return {
      loading: false,
      save_disabled: true,

      code: '',
      title: '',
      start_date: '',
      end_date: '',

      confirm_delete: false,
    };
  },

  computed: {
    saveButtonDisabled() {
      // titel aangepast
      if (this.title && this.title !== this.episode.title) {
        return false;
      }
      // start datum aangepast
      if (this.start_date && this.start_date !== this.episode.start_date) {
        return false;
      }
      // eind datum toegevoegd
      if (!this.episode.end_date && this.end_date) {
        return false;
      }
      // eind datum verwijderd
      if (this.episode.end_date && !this.end_date) {
        return false;
      }
      // eind datum gewijzigd
      if (this.episode.end_date && this.end_date && this.episode.end_date !== this.end_date) {
        return false;
      }
      return true;
    },
    modalTitle() {
      if (this.confirm_delete === true) {
        return 'Verwijder episode: ' + this.episode.title;
      } else {
        return 'Wijzig episode: ' + this.episode.title;
      }
    },
  },

  created() {
  },

  mounted() {
    this.loadEpisode();
  },

  methods: {
    close(refresh) {
      this.$emit('closed', refresh);
    },
    onClosed(event) {
      this.$emit('closed');
    },

    loadEpisode() {
      this.code = this.episode.code;
      this.title = this.episode.title;
      this.start_date = this.episode.start_date;

      this.end_date = this.episode.end_date;
      if (this.end_date === null) {
        this.end_date = '';
      }
    },

    update() {
      this.loading = true;
      this.$store.dispatch('episodes/update', {
        patient_id: this.episode.patient_id,
        episode_id: this.episode.id,
        data: {
          title: this.title,
          start_date: this.start_date,
          end_date: this.end_date,
          updated_at: this.episode.updated_at
        }
      }).then(() => {
        this.loading = false;
        this.close(true);
      }).catch(() => {
        this.loading = false;
      });
    },

    remove() {
      this.loading = true;
      this.$store.dispatch('episodes/delete', {
        patient_id: this.episode.patient_id,
        episode_id: this.episode.id,
      }).then(() => {
        this.loading = false;
        this.close(true);
      }).catch(() => {
        this.loading = false;
      });
    }
  },

  watch: {
    episode(value) {
      this.loadEpisode();
    }
  }
};
</script>
