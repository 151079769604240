import {TimeIntervalObject} from '@/domain/models/TimeIntervalObject';
import {QuantityObject} from '@/domain/models/QuantityObject';
import moment from 'moment';

export class GeneratedTimeIntervalObject { 
  public startDateTime: moment.Moment | null;
  public endDateTime: moment.Moment | null;
  public duration: QuantityObject | null;
  public condition: string | null;

  constructor(startDateTime: moment.Moment | null = null, endDateTime: moment.Moment | null = null, duration: QuantityObject | null = null, condition: string | null = null) {
    this.startDateTime = startDateTime;
    this.endDateTime = endDateTime;
    this.duration = duration;
    this.condition = condition;
  }

  public static fromJson(json: unknown): TimeIntervalObject {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new TimeIntervalObject(
      parsedJson['startDateTime'] ? moment.parseZone(parsedJson['startDateTime']) : null,
      parsedJson['endDateTime'] ? moment.parseZone(parsedJson['endDateTime']) : null,
      parsedJson['duration'] ? QuantityObject.fromJson(parsedJson['duration']) : null,
      parsedJson['condition'] as string,
    );
  }

  public static multipleFromJson(json: unknown): TimeIntervalObject[] {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    const items: TimeIntervalObject[] = [];
    for (const item of parsedJson) {
      items.push(this.fromJson(item));
    }
    return items;
  }
}
