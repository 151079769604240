import BaseModule from '@/vue/store/modules/base_module';

/**
 * Puur voor het zoeken in subsets zonder de algemene dataTable te verstoren
 * Updates e.d. lopen nog steeds via de andere store.
 */
const state = {
  ...BaseModule.state,

  data: [],
  params: [],

  quick_filters: {
    'status': {
      'group': {
        'Ongezien' : false,
        'Opgeslagen' : false,
      }
    },
    'type': {
      'group': {
        'Dossier inzages' : false,
        'Patienten bekeken' : false,
      }
    },
    'Afgelopen week' : false,
  },
};

// getters
const getters = {
  ...BaseModule.getters,
};

// actions
const actions = {
  ...BaseModule.actions,
};

// mutations
const mutations = {
  ...BaseModule.mutations,
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
