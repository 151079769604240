<template>
  <div class="thead">
    <slot></slot>
  </div>
</template>

<script>
export default {
  components: {},

  props: [],

  mixins: [],

  data: function () {
    return {
      //
    };
  },

  computed: {
    medimoTheadUid() {
      return this.uid;
    },
    uid() {
      return this._.uid;
    },
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    //
  },

  watch: {
    //
  }
};
</script>
