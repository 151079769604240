import moment from 'moment-mini';

export default {
  intersect(array_a, array_b) {
    return array_a.filter(value => array_b.includes(value));
  },
  random_integer(start, end) {
    return Math.floor(Math.random() * end) + start;
  },
  convert_object_to_array(object, keyPropertyName = 'key') {
    const obj = object;
    return Object.keys(obj).map(key => {
      const newData = obj[key];
      newData[keyPropertyName] = key;
      // return newData;
      return obj[key];
    });
  },
  convert_array_to_object(array) {
    const newObject = {};
    array.forEach((element, index) => {
      newObject[index] = element;
    });
    return newObject;
  },
  remove_duplicate_spaces_and_linebreaks(string) {
    return string.replace(/[\r\n]+/gm, " ").replace(/ {1,}/gm, " ").trim();
  },
  is_new_id(id) {
    return isNaN(id) && id.startsWith('new-');
  },
  parse_id(id) {
    if (!id) {
      return id;
    }

    if (this.is_new_id(id)) {
      //Don't parse id to int, because the model has a string as id.
    } else {
      id = parseInt(id);
    }
    return id;
  },
  convert_dates_to_numeral_strings(string) {
    const dates = string.match(/....-..-../g);

    if (dates == null) {
      return string;
    }

    dates.forEach(date => {
      const date_array = date.split('-');

      // And now we re-arrange. 311119 can be 2019-11-31 or 1919-11-31
      const year = date_array[0].slice(-2);
      const month = date_array[1];
      const day = date_array[2];

      string = string + ' ' + day + month + year;
    });

    return string;
  },
  /**
   * Converts a nested object's values to a string
   * @param object
   * @param filter_columns array Can be in regular string, or dotted notation
   * @param current_column string The current filter column that's being checked. Used for the loop
   */
  filtered_nested_object_to_string(object, filter_columns = [], current_column = '') {
    let string = '';

    Object.keys(object).forEach(key => {
      const value = object[key];

      // For readability. What we call filter_columns (referring to the DB table columns) are actually keys here
      // When the
      let column = current_column;
      // If the column is not NaN, we add it to the dotted notation. By doing this, we are unaffected by nested
      // objects or array indexes
      // Changing 0.models.value.3.more_values to models.value.more_values
      if (isNaN(parseInt(key))) {
        column = (column + '.' + key).replace(/^\./g, ''); // We trim the first ., if need be
      }

      // If it's an object, we continue the loop
      // If it's an array, we continue the loop
      if ((typeof value === 'object' || Array.isArray(value)) && value !== null) {
        string = string + ' ' + this.filtered_nested_object_to_string(value, filter_columns, column);
      } else {
        // Then finally, we check if we should add this value, based on the given filter columns
        // When there are no given filter columns, we add it straight away
        // Or if the current column is in the filter columns
        if (filter_columns.length === 0 || filter_columns.includes(column)) {
          string = string + ' ' + value;
        }
      }
    });

    // Removing excess spaces before the final return
    return string.replace(/\s+/g, ' ');
  },
  is_equal(a, b) {
    return JSON.stringify(a) === JSON.stringify(b);
  },
  deep_clone(obj) {
    return JSON.parse(JSON.stringify(obj));
  },
  clone(obj) {
    return this.deep_clone(obj);
  },
  is_object(item) {
    return (typeof item === "object" && !Array.isArray(item) && item !== null);
  },
  /**
   * Turns strings with special characters like ë into their regular counterparts
   *
   * @param string
   * @returns {*}
   */
  normalized_string(string) {
    // On NFD vs. NFC: NFD is good for certain internal processing - if you want to make accent-insensitive searches or sorting, having your string in NFD makes it much easier and faster. Another usage is making more robust slug titles. These are just the most obvious ones, I am sure there are plenty of more uses.
    return string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  },
  /**
   * Veranderd een string zoals "Snake Case" naar snake_case en "snakeCase" ook naar snake_case
   *
   * @param string
   * @returns {*}
   */
  convert_to_snake_case(string) {
    // Zie mijn StackOverflow answer: https://stackoverflow.com/a/65003355/5377276 voor voorbeelden
    return string.charAt(0).toLowerCase() + string.slice(1)
      .replace(/\W+/g, " ") // Remove all excess white space and replace & , . etc.
      .replace(/([a-z])([A-Z])([a-z])/g, "$1 $2$3") // Put a space at the camelCase
      .split(/\B(?=[A-Z]{2,})/) // Now split the multi-uppercases
      .join(' ') // And join back with spaces.
      .split(' ') // Split all the spaces again, this time we're fully converted
      .join('_') // And finally snake_case things up
      .toLowerCase(); // With a nice lower case
  },
  convert_to_kebab_case(string) {
    if (typeof string !== 'string') {
      return '';
    }
    let final = string.split('').map((letter, idx) => {
      return letter.toUpperCase() === letter
        ? `${idx !== 0 ? '-' : ''}${letter.toLowerCase()}`
        : letter;
    }).join('');
    // Remove spaces, underscores and multiple dashes
    final = final.replace(' ', '-');
    final = final.replace('_', '-');
    return final.replace(/-{2,}/g,"-");
  },
  // Searches for the existence in an array,
  // Helper function for dealing with replace or add during commits
  id_already_exists(array, id) {
    return this.find_index_of_matching_element(array, 'id', id) !== -1;
  },
  /**
   *
   * @param array
   * @param property_name
   * @param property_value
   * @returns {*}
   */
  find_index_of_matching_element(array, property_name, property_value) {
    property_value = this.parse_id_if_needed(property_name, property_value);
    return array.findIndex(x => x[property_name] === property_value);
  },
  /**
   *
   * @param array
   * @param property_name1
   * @param property_name2
   * @param property_value1
   * @param property_value2
   * @returns {*}
   */
  find_index_of_matching_elements(array, property_name1, property_value1, property_name2, property_value2) {
    property_value1 = this.parse_id_if_needed(property_name1, property_value1);
    property_value2 = this.parse_id_if_needed(property_name2, property_value2);
    return array.findIndex(x => x[property_name1] === property_value1 && x[property_name2] === property_value2);
  },
  find_index_of_matching_value(array, needle) {
    let valueIndex = -1;
    array.forEach((arrayValue, index) => {
      if (arrayValue === needle) {
        valueIndex = index;
      }
    });
    return valueIndex;
  },
  ucfirst(string) {
    if (typeof string !== "undefined") {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return '';
  },
  lcfirst(string) {
    if (typeof string !== "undefined") {
      return string.charAt(0).toLowerCase() + string.slice(1);
    }
    return '';
  },

  /**
   * Use this helper to remove an ID from an array of IDs and return the sorted array
   *
   * @param idArray
   * @param idToBeRemoved
   * @returns {*}
   */
  remove_id_from_array(idArray, idToBeRemoved) {
    const newItems = idArray.filter(function (availableUid) {
      return availableUid !== idToBeRemoved;
    });

    return newItems;
  },
  /**
   * Use this to remove an object (i.e. model) from an array based on an ID property
   *
   * @param object_array
   * @param id
   * @returns {*}
   */
  remove_object_from_array_by_id(object_array, id) {
    return object_array.filter((object) => {
      return object.id !== id;
    });
  },
  remove_object_from_array_by_key(object_array, key, value) {
    return object_array.filter((object) => {
      return object[key] !== value;
    });
  },
  remove_duplicates_from_array(array) {
    const result = [];
    array.forEach(function(item) {
      if (result.indexOf(item) < 0) {
        result.push(item);
      }
    });
    return result;
  },
  remove_duplicates_from_array_by_key(array, key) {
    return [...new Map(array.map(item => [item[key], item])).values()];
  },

  /*
   |--------------------------------------------------------------------------
   | Model Helpers
   |--------------------------------------------------------------------------
   |
   |
   |
   |
   */
  /**
   * @param models
   * @param id
   * @returns {*}
   */
  find_model_by_id(models, id) {
    return this.find_model_by_property(models, 'id', this.parse_id(id));
  },

  /**
   * Searches within an array of models for the matching model
   *
   * @param models
   * @param property_name
   * @param property_value
   * @param nested
   */
  find_model_by_property(models, property_name, property_value, nested = false) {
    let matching_model = null;

    // Loop through the models
    models.forEach(model => {

      // Get all the properties from the object into an array
      const model_properties = Object.keys(model);

      // Loop through the properties and find a match
      model_properties.forEach(property => {

        // When it's the property we're after, check if the value matches
        if (property === property_name) {

          // And then finally, we make sure the value (i.e. the ID) matches the one of the model
          if (this.parse_id_if_needed(property, model[property]) === this.parse_id_if_needed(property, property_value)) {
            matching_model = model;
          }
        }
      });
    });

    return matching_model;
  },

  /**
   *
   * @param property
   * @param value
   */
  parse_id_if_needed(property_name, property_value) {
    if (property_name === 'id' || property_name.endsWith('_id')) {
      return this.parse_id(property_value);
    }
    return property_value;
  },

  /**
   * Searches within an array of models for the matching models
   *
   * @param models
   * @param property_name
   * @param property_value
   * @param nested
   */
  find_models_by_property(models, property_name, property_value) {
    property_value = this.parse_id_if_needed(property_name, property_value);
    const filtered = models.filter(item => this.parse_id_if_needed(property_name, item[property_name]) === property_value);
    return filtered;
  },

  groupBy(arr, prop) {
    const map = new Map(Array.from(arr, obj => [obj[prop], []]));
    arr.forEach(obj => map.get(obj[prop]).push(obj));
    return Array.from(map.values());
  },

  groupByWithKeys(arr, prop) {
    return arr.reduce((r, a) => {
      r[a[prop]] = [...r[a[prop]] || [], a];
      return r;
    }, {});
  },


  /**
   * Searches within an array of models and deletes the matching model
   */
  delete_model_by_property(models, property_name, property_value) {
    const index = models.map(e => e[property_name]).indexOf(property_value);
    if (index !== -1) {
      models.splice(index, 1);
    }
    return models;
  },

  /**
   * Searches within an array of models and deletes the matching models
   */
  delete_models_by_property(models, property_name, property_value) {
    for (let i = 0; i < models.length; i++) {
      if (models[i][property_name] === property_value) {
        models.splice(i, 1);
        i--;
      }
    }
    return models;
  },

  /**
   * Set a value of a model by dot notation.
   * Example: 'attributes.warning' would set the model.attributes.warning value
   *
   * @param model
   * @param path
   * @param value
   */
  set_by_dot(model, path, value) {
    return path.split('.').reduce(function (prev, curr, _idx, _arr) {
      if (_idx == (_arr.length - 1) && prev) {
        prev[curr] = value;
      }

      return prev ? prev[curr] : null;
    }, model || self);
  },

  /**
   *
   * SEARCH METHODS
   *
   *
   * ! will make it pass if it does NOT have that string
   *
   * || will mean OR, so a combination of things.
   *
   * When searching in "" it means that full string needs to be in there
   *
   * a simple space will mean AND
   *
   * non case-sensitive
   *
   * > will allow the user to filter between dates
   *
   *
   */
  matches_filter_query(string, filter_queries, filter_columns = []) {

    if (this.is_object(string)) {
      string = this.filtered_nested_object_to_string(string, filter_columns);
    }

    // Add numerical dates to the string, to be matched
    string = this.convert_dates_to_numeral_strings(string);

    // If it contains a daterange, we hit another function
    if (this.regexIndexOf(filter_queries, '>') !== -1) {
      return this.contains_date_range(string, filter_queries);
    }

    // If not, we continue
    const filter_queries_OR_array = filter_queries.split('||');

    // If the query is empty, return true
    if (filter_queries.length < 1) {
      return true;
    }

    const normalized_string = this.normalized_string(string);

    let passed = false;
    filter_queries_OR_array.forEach(function (filter_query_AND_string) {
      const passes_filter = this.array_contains_query(normalized_string, filter_query_AND_string.trim()) ? true : false;
      if (passes_filter) {
        passed = true;
      }
    }.bind(this));
    return passed;
  },
  array_contains_query(string, filter_queries_array) {

    // var filter_queries_AND_array = filter_queries_array.split(' ');
    const filter_queries_AND_array = filter_queries_array.match(/[^\s"]+|"([^"]*)"/g); // Single words to elements and "fixed string of words" to elements

    // When the array is empty, return true
    if (filter_queries_AND_array == null) {
      return true;
    }

    let passed = true;
    filter_queries_AND_array.forEach(function (filter_query) {
      const passes_filter = this.string_contains_query(string, filter_query.trim()) ? true : false;
      if (!passes_filter) {
        passed = false;
      }
    }.bind(this));
    return passed;
  },
  string_contains_query(string, query) {
    string = this.normalized_string(string.toLowerCase());
    query = this.normalized_string(query.toLowerCase());

    // If it's an empty string (meaning it's part of an OR query), return false.
    // This keeps the overview cleaner
    if (query.length < 1) {
      return 0;
    }
    // If there are quotation marks, now's the time to remove them
    query = query.replace(/"/g, "");

    // If there's a single | in there because the user is about to type 2, filter it
    query = query.replace("|", "");

    // If there's an ! in there, it should pass if that name is NOT in there
    if (query.includes('!')) {
      query = query.replace('!', ''); // Replaced the exclamation mark ofcourse
      return this.regexIndexOf(string, query) === -1;
    }
    return this.regexIndexOf(string, query) !== -1;
  },
  regexIndexOf(string, regex_search) {
    // Escape for regex usage. Hier vervangen we alle special characters
    // en escapen ze zodat ze in de regex als string, en niet als modifier gezien worden
    regex_search = regex_search.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');

    const indexInSuffix = string.search(regex_search);
    return indexInSuffix < 0 ? indexInSuffix : indexInSuffix;
  },
  contains_date_range(string, filter_queries) {

    // If the search contains a date range, we use this one.
    // ['2017-08-11','2017-08-11']
    const from_to_array = filter_queries.split('>');
    let from = from_to_array[0].trim();
    let to = from_to_array[1].trim();

    // Quickly return false if either string is empty
    if (from.length < 1 || to.length < 1) {
      return false;
    }

    // Find the actual date first:
    // And only use the first one
    let start_date = string.match(/....-..-../g)[0];

    start_date = new Date(start_date);
    from = new Date(from);
    to = new Date(to);

    if (start_date >= from && start_date <= to) {
      return true;
    }
    return false;
  },
  string_is_in_object(object, query) {
    const string = JSON.stringify(object).toLowerCase();
    query = query.toLowerCase();

    if (query.length > 0) {
      return this.string_contains_query(string, query);
    }
    return true;

  },
  sort_data_by_value(data, sort_columns) {
    data.sort(function (a, b) {
      if (a[sort_columns[0]] < b[sort_columns[0]]) {
        return -1;
      }
      if (a[sort_columns[0]] > b[sort_columns[0]]) {
        return 1;
      }
      return 0;
    });

    return data;
  },
  sort_array_of_objects_asc_by_property(array, property) {
    return array.sort((a,b) => (a[property] > b[property]) ? 1 : ((b[property] > a[property]) ? -1 : 0));
  },
  /**
   * Used to sort data arrays by column values. Can be nested as well:
   * medication_treatments.medication_agreements.dosage_instructions.start_datetime
   *
   * @param data
   * @param sort_columns object The sort columns, syntax like: {column_name1: "ASC", column_name2: "DESC"}, etc.
   * @returns {*}
   */
  sort_data_by_columns(data, sort_columns) {

    // Seems redundant but I couldn't get it working with an anonymous function
    // Doesn't matter, it's all sorted
    const sortedData = data.sort(this.fieldSorter(sort_columns));
    // let sortedData = data.sort(this.fieldSorter({
    //   'medication_treatments.medication_agreements.dosage_instructions.start_datetime': 'DESC',
    // }));
    return sortedData;
  },
  /**
   * Sorts input data based on the field names.
   *
   * @param sort_columns
   * @returns {function(*, *): *}
   */
  fieldSorter(sort_columns) {
    // BUT FIRST! If it's nested (with . notation, we need to get the final value).
    function getValue(model, column_name) {
      const column_names = column_name.split('.');
      const valueDepth = column_names.length;
      let index = 0;
      let value = model;
      while (index < valueDepth) {
        // Make sure we take arrays into account - if they're there, just use the first element.
        // There really is no other way without looping through those and then determining the highest or lowes
        // But that could just be distracting / unobvious to the user
        if (Array.isArray(value)) {
          value = value[0][column_names[index]];
        } else {
          value = value[column_names[index]];
        }
        index++;
      }

      if (typeof value === 'undefined' || value === null) {
        // Need to return the 0 string to be compatible with numbers and strings
        return '0';
      }
      return value;
    }

    return (a, b) => sort_columns.map(sort_column => {

      // First we determine the direction
      // Reverse order for DESC, default is ASC
      let direction = 1;
      if (sort_column.direction === 'DESC') {
        direction = -1;
      }
      // Set the column name
      const column_name = sort_column.column_name;


      // Get the (nested) values we need to compare to determine the sorting order:
      const aValue = getValue(a, column_name).toString();
      const bValue = getValue(b, column_name).toString();

      // And then do the sorting magic
      return aValue > bValue ? direction : aValue < bValue ? -(direction) : 0;
    }).reduce((p, n) => p ? p : n, 0);
  },
  /**
   * Check if an element is visible inside the browser window
   *
   * @param element The DOM element
   * @param offset Used to offset the check, to make sure it goes "out of view" on the last pixel instead of the first.
   * @param mode Set to 'above' or 'below' to check where on the page it is
   * @returns {boolean}
   */
  element_is_visible_on_page(element, offset, mode) {
    offset = offset || 0;
    mode = mode || 'visible';

    const elementRect = element.getBoundingClientRect();
    const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
    const above = elementRect.bottom - offset < 0;
    const below = elementRect.top - viewHeight + offset >= 0;

    return mode === 'above' ? above : (mode === 'below' ? below : !above && !below);
  },
  /**
   * Check if an element is visible inside another element
   *
   * @param element The DOM element
   * @param parent The parent DOM element
   * @param mode Set to 'bottom' or 'top' to offset just the top or bottom with the offset
   * @param offset Used to offset the check, to make sure it goes "out of view" on the last pixel instead of the first.
   * @returns {boolean}
   */
  element_is_visible_inside_another_element(element, parent, mode, offset) {
    mode = mode || 'both';

    let offsetTop, offsetBottom;

    // Depending on the mode, we can have 1 or 2 offsets
    if (mode === 'bottom') {
      offsetTop = 0;
      offsetBottom = offset;
    }
    else if (mode === 'top') {
      offsetTop = offset;
      offsetBottom = 0;
    }
    else  {
      offsetTop = offset;
      offsetBottom = offset;
    }

    // After that it's a matter of comparing the bounding boxes to see if one is outside the other
    const above = element.getBoundingClientRect().bottom - offsetTop < parent.getBoundingClientRect().top;
    const below = element.getBoundingClientRect().top + offsetBottom >= parent.getBoundingClientRect().bottom;

    return !above && !below;
  },
  capitalizeFirstLetter(string) {
    return this.ucfirst(string);
  },
  isInt(value) {
    return Number.isInteger(value);
  },
  isFloat(value) {
    return +value === value && (!isFinite(value) || !!(value % 1));
  },
  isArray(value) {
    return Array.isArray(value);
  },
  isObject(value) {
    return typeof value === 'object' && !this.isArray(value);
  },
  isString(value) {
    return (typeof value === 'string' || value instanceof String);
  },
  isBoolean(value) {
    return typeof variable === "boolean";
  },
  isUrl(value) {
    const regexp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    return regexp.test(value);
  },
  dutchDate(dateString, format = 'DD-MM-YYYY') {
    return moment(dateString).format(format);
  },
  dutchDateTime(dateString) {
    return moment(dateString).format('DD-MM-YYYY HH:mm');
  },
  dayOfWeek(dateString, format = 'YYYY-MM-DD') {
    return moment(dateString, format).format('dddd').substring(0, 2);
  },
  clientScreenIsLarge() {
    return window.innerWidth >= 1024 || window.innerHeight >= 1024;
  },
  // Returns a float as a string, shows 2.0 as 2 and 2.5 as 2,5
  floatToString(value) {
    const floatValue = parseFloat(value);
    if (typeof floatValue === 'number') {
      return floatValue.toString().replace('.', ',');
    } else {
      return value; // Return unchanged value;
    }
  },
  removeHtmlTags(str) {
    if ((str === null) || (str === '')) {
      return false;
    } else {
      str = str.toString();
    }
    return str.replace(/(<([^>]+)>)/ig, '');
  },
};
