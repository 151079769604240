export default {
  beforeMount(el, binding, vNode) {
    // Define Handler and cache it on the element
    const bubble = binding.modifiers.bubble;
    const handler = (e) => {
      if (bubble || (!el.contains(e.target) && el !== e.target)) {
        binding.value(e);
      }
    };
    el.__vueClickOutside__ = handler;

    // add Event Listeners
    document.addEventListener('mousedown', handler);
  },
  beforeUnmount(el, binding) {
    // Remove Event Listeners
    document.removeEventListener('mousedown', el.__vueClickOutside__);
    el.__vueClickOutside__ = null;

  }
};
