<template>
    <medimo-tr class="no-gutters">
        <medimo-td class="col-sm-3 col-12">
            {{ intolerance.title }}
        </medimo-td>
        <medimo-td class="col-sm-5 col-12">
            {{ intolerance.info }}
        </medimo-td>
        <medimo-td class="col-sm-4 col-12">
            {{ parsedRegisteredDate }}
        </medimo-td>
    </medimo-tr>
</template>

<script>
import Dates from '@/vue/utility/dates';
import MedimoTr from '@/vue/components/general/table/MedimoTr';
import MedimoTd from '@/vue/components/general/table/MedimoTd';

export default {
  components: {
    MedimoTr,
    MedimoTd,
  },

  props: [
    'intolerance',
  ],

  data: function () {
    return {
      'trigger': false,
    };
  },

  computed: {
    parsedRegisteredDate() {
      return Dates.setDate(this.intolerance.register_date).dutchDate();
    }
  },
};
</script>
