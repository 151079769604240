import {MedicationGuardSignal} from '@/domain/models/MedicationGuardSignal';
import {DisplayInfoObject} from '@/domain/models/DisplayInfoObject';

export class GeneratedMedicationGuardSignal { 
  public id: number;
  public type: string;
  public displayInfo: DisplayInfoObject;

  constructor(id: number, type: string, displayInfo: DisplayInfoObject) {
    this.id = id;
    this.type = type;
    this.displayInfo = displayInfo;
  }

  public static fromJson(json: unknown): MedicationGuardSignal {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new MedicationGuardSignal(
      parsedJson['id'] as number,
      parsedJson['type'] as string,
      DisplayInfoObject.fromJson(parsedJson['displayInfo']),
    );
  }

  public static multipleFromJson(json: unknown): MedicationGuardSignal[] {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    const items: MedicationGuardSignal[] = [];
    for (const item of parsedJson) {
      items.push(this.fromJson(item));
    }
    return items;
  }
}
