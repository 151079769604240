import moment from 'moment-mini';

export const getDefaultLocale = () => {
  if (typeof window === 'undefined') return null;

  const {userLanguage, language} = window.navigator;
  const locale = (userLanguage || language || 'en').substr(0, 2);
  moment.locale(locale);
  return locale;
};
