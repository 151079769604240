import BaseModule from '@/vue/store/modules/base_module';

const state = {
  ...BaseModule.state,
  search_endpoint: '/api/v1/authorize-provisional',
  quick_filters: {},
  sort_columns: [],
};

const getters = {...BaseModule.getters};
const actions = { ...BaseModule.actions };
const mutations = {
  ...BaseModule.mutations,
  init_quick_filters(state, quick_filter) {
    // Remove any old quick filters
    Object.keys(state.quick_filters).forEach(quick_filter => {
      delete state.quick_filters[quick_filter];
    });
    // Add the one dynamic quick filter
    state.quick_filters[quick_filter.name] = quick_filter.value;
    // Add the default quick filter
    const scope = quick_filter.name === 'emergency-requests' ? 'noodvoorraad' : 'patient_plural';
    state.quick_filters.scopeFilter = {
      mustBeActive: true,
      group: {
        'own': {label: 'eigen ' + scope, value: true, translate: true},
        'observation': {label: 'Waarneming', value: false},
      }
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
