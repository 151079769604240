<template>
    <nav id="breadcrumbs" aria-label="breadcrumbs">
        <ol class="breadcrumb">
            <li class="breadcrumb-item d-block d-md-none d-flex is-clickable">
                <medimo-link :to="previousBreadCrumb" :trigger="navigatePrevious" v-if="breadcrumbs.length">
                    <fa-icon icon="fa-solid fa-square-caret-left" class="text-primary" size="lg" />
                </medimo-link>
                <img v-else-if="! navSearchIsOpen" src="/assets/images/logos/medimo-logo.svg" alt="Medimo" style="height: 26px;">
                <img v-else src="/assets/images/logos/medimo-icon.svg" alt="Medimo" style="height: 26px;">
            </li>
            <li class="breadcrumb-item hidden-sm-down" v-if="currentRoute !== '/'">
                <medimo-link to="/" :trigger="navigateHome" id="breadcrumbs-home-link"  aria-label="home">
                    <fa-icon icon="fa-solid fa-house" class="clickable text-primary" size="lg" />
                </medimo-link>
            </li>
            <template v-for="(breadcrumb, breadcrumbIndex) in breadcrumbs" :key="breadcrumbIndex + breadcrumb.path">
                <li class="breadcrumb-item hidden-sm-down" v-show="! navSearchIsOpen || ! currentlyOn('sm')">
                    <fa-icon icon="fa-solid fa-caret-right" fixed-width size="lg" />
                </li>
                <transition name="fade">
                    <bread-crumb v-show="! navSearchIsOpen || ! currentlyOn('sm')"
                                 v-bind:class="{'hidden-sm-down': breadcrumbIndex !== breadcrumbs.length - 2}"
                                 :is_back_breadcrumb="breadcrumbIndex === breadcrumbs.length - 2"
                                 :breadcrumb="breadcrumb" :breadcrumb_index="breadcrumbIndex"></bread-crumb>
                </transition>
            </template>
        </ol>
    </nav>
</template>

<script>
import MedimoLink from '@/vue/components/general/MedimoLink';
import BreadCrumb from '@/vue/components/nav/BreadCrumb';
import UsesMediaQueries from '@/vue/mixins/UsesMediaQueries';

// Used to check if the page we're on is a Vue page, or a Laravel Blade page
import ChecksIfVuePage from '@/vue/mixins/navigation/ChecksIfVuePage';

export default {
  components: {
    BreadCrumb,
    MedimoLink
  },
  mixins: [
    ChecksIfVuePage,
    UsesMediaQueries
  ],
  data: function () {
    return {
      navigateHome: false,
      navigatePrevious: false,
    };
  },
  computed: {
    breadcrumbs() {
      return this.$store.state.settings.breadcrumbs.data;
    },
    currentlyOnVuePage() {
      // Uses the mixin to determine if the current page is part of the Vue routes
      return this.currentPageIsVuePage();
    },
    navSearchIsOpen() {
      return this.$store.state.settings.navigation.navSearchNavigation;
    },
    previousBreadCrumb() {
      return this.$store.getters['settings/breadcrumbs/previous_breadcrumb'];
    },
    currentRoute() {
      return this.$route.path;
    }
  },
  created() {
    // Update and initialize the Breadcrumbs so we can access them on both full vue, and non-vue pages
    this.initializeBreadCrumbs();
  },
  methods: {
    goToPrevious() {
      this.navigatePrevious = !this.navigatePrevious;
    },
    /**
     * This function is called on the initial load of Vue, and on the pageload of regular pages
     * It detects what type of page it is (Vue or Laravel Blade), and how to handle the adding of a new breadcrumb
     */
    initializeBreadCrumbs() {
      // If we're on the homepage with a page refresh (i.e. this puppy being created), clear the breadcrumbs
      // Same for hitting the login page.
      if (window.location.pathname === '/' || window.location.pathname === '/login') {
        this.$store.commit('settings/breadcrumbs/clear_breadcrumbs');
      }
      // If we're not on the homepage, determine what the page type is, and act accordingly.
      // The MasterLayout takes care of adding Vue pages to the breadcrumbs, so we need this bit to take care
      // of Laravel Blade pages being added.
      else if (typeof sessionStorage.breadcrumb !== 'undefined' && !this.currentlyOnVuePage) {
        // It's an "old" page, add a breadcrumb based on the session storage
        const oldBreadcrumbs = JSON.parse(sessionStorage.breadcrumb);
        const lastOldBreadcrumb = oldBreadcrumbs[oldBreadcrumbs.length - 1];

        let title = lastOldBreadcrumb.title;

        //V2 pages don't have a title set, we have to extract it via the querySelector
        if (title.length === 0) {
          title = document.querySelector('title').text.replace(/[\r\n]+/gm, " ").replace(/ {1,}/gm, " ").trim();
        }

        const breadCrumb = {
          title: title,
          path: lastOldBreadcrumb.url,
        };

        this.$store.dispatch('settings/breadcrumbs/add_breadcrumb_trail', breadCrumb);
        this.$store.dispatch('settings/breadcrumbs/add_breadcrumb', breadCrumb);
      }
    }
  }
};
</script>
