<template>
    <div style="display:inline-block;">

        <medimo-link :class="'status badge badge-sm ml-1 ' + (patient.independenceClassification ? 'badge-medimo-50' : 'badge-warning')"
                     :disabled="!canViewPrescriptions"
                     @click="openModal()"
                     :to="''">
          <medimo-tooltip
              v-if="patient.independenceClassification"
              :content="patientUcFirst + ' heeft BEM klasse ' + patient.independenceClassification"
              class="small">BEM {{ patient.independenceClassification }}
          </medimo-tooltip>
          <medimo-tooltip
              v-else
              content="Geen BEM Bekend"
              class="small">BEM
            </medimo-tooltip>
        </medimo-link>

        <edit-bem-modal
                v-if="show_modal_bem"
                :patient="patient"
                @closed="show_modal_bem = false"
        ></edit-bem-modal>

    </div>
</template>

<script>
import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import EditBemModal from '@/vue/components/patients/bem/EditBemModal';
import utility from '@/vue/utility/utility';

export default {
  components: {
    EditBemModal
  },

  props: [
    'language',
    'patient'
  ],

  mixins: [
    BaseComponentsMixin,
  ],

  data: function () {
    return {
      show_modal_bem: false,
    };
  },
  computed: {
    patientUcFirst() {
      return utility.ucfirst(this.$store.state.current_user.data.language.patient);
    },
    canViewPrescriptions() {
      return this.patient.authority.canViewPrescriptions;
    }
  },
  mounted() {
    const showBemModal = this.$route.query.showBemModal;
    if (showBemModal) {
      this.openModal();
      this.$store.dispatch('settings/navigation/clearQueryString', {
        query_string: '?showBemModal=' + showBemModal
      });
    }
  },
  methods: {
    openModal() {
      if (this.canViewPrescriptions) {
        this.show_modal_bem = true;
      }
    }
  }
};
</script>
