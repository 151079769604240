<template>
    <medi-modal
        :loading="loading"
        :title="modalTitle"
        size="medium"
        @closed="onClosed()"
    >
        <medimo-form>

          <medimo-form-row v-if="read_only">
              <medimo-form-column column_classes="col-12">
                  <medimo-notification type="danger">
                    <fa-icon icon="fa-solid fa-bell" class="bell" size="lg" /> Deze contra-indicatie is verlopen.
                  </medimo-notification>
              </medimo-form-column>
          </medimo-form-row>

          <medimo-form-row>
                <medimo-form-column column_classes="col-12">
                    <medimo-labeled-input
                        :disabled="true"
                        label_column_classes="col-12 col-sm-3"
                        label="Title"
                        name="title"
                        v-model="title"
                    ></medimo-labeled-input>
                </medimo-form-column>
            </medimo-form-row>

            <medimo-form-row>
                <medimo-form-column column_classes="col-12">
                    <medimo-validated-input
                        :disabled="read_only"
                        label_column_classes="col-12 col-sm-3"
                        label="Bijzonderheden"
                        name="info"
                        v-model="info"
                    ></medimo-validated-input>
                </medimo-form-column>
            </medimo-form-row>

            <medimo-form-row>
                <medimo-form-column column_classes="col-12">
                    <medimo-validated-date-picker
                        :disabled="read_only"
                        label_column_classes="col-12 col-sm-3"
                        label="Vaststellingsmoment"
                        name="register_date"
                        v-model="register_date"
                        help_text="Wanneer heeft u of een eerdere zorgverlener vastgesteld dat deze contraindicatie van toepassing is? M.a.w. vanaf wanneer moet deze contraindicatie van kracht zijn? Indien u dit niet weet, vul dan nu/vandaag in.">
                    </medimo-validated-date-picker>
                </medimo-form-column>
            </medimo-form-row>

            <medimo-form-row>
                <medimo-form-column column_classes="col-12">
                    <medimo-validated-date-picker
                        :disabled="read_only"
                        label_column_classes="col-12 col-sm-3"
                        label="Stopdatum"
                        placeholder="(Optioneel)"
                        name="stop_date"
                        v-model="stop_date">
                    </medimo-validated-date-picker>
                </medimo-form-column>
            </medimo-form-row>

        </medimo-form>

        <template v-slot:action-buttons v-if="!confirm_delete">
            <medimo-notification type="danger" class="mr-auto col-md-6" v-if="pre_validation_message !== null">
              <fa-icon icon="fa-solid fa-bell" class="bell" size="lg" /> {{ pre_validation_message }}
            </medimo-notification>

            <medimo-button v-if="!read_only && pre_validation_message === null" class="mr-auto" type="outline-primary" @click.stop="confirm_delete = true">
                Verwijderen
            </medimo-button>
            <medimo-button v-if="!read_only && pre_validation_message === null" :disabled="saveButtonDisabled" @click="validateInput()">
                Opslaan
            </medimo-button>
            <medimo-button @click="store" v-if="!read_only && pre_validation_message !== null">
              Ja, ik weet het zeker
            </medimo-button>
            <medimo-button v-if="!read_only" @click="close(false)" type="outline-primary">
                Annuleren
            </medimo-button>
            <medimo-button v-if="read_only" @click="close(false)" type="outline-primary">
                Sluiten
            </medimo-button>
        </template>

        <template v-slot:action-buttons v-else>
            <medimo-notification type="danger" class="mr-auto col-md-6">
              <fa-icon icon="fa-solid fa-bell" class="bell" size="lg" /> Weet je het zeker?
            </medimo-notification>
            <medimo-button @click="confirm_delete = false" type="outline-primary">
                Annuleren
            </medimo-button>
            <medimo-button @click.prevent="remove">
               Ja, ik weet het zeker
            </medimo-button>
        </template>

    </medi-modal>
</template>

<script>
import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import PatientInfoMediModal from '@/vue/components/modals/PatientInfoMediModal';
import ValidateStartStop from '../../../mixins/indications/ValidateStartStop';

export default {
  components: {
    PatientInfoMediModal,
  },
  emits: ['closed'],

  props: [
    'indication',
    'height',
    'patient_id',
  ],

  mixins: [
    BaseComponentsMixin,
    ValidateStartStop,
  ],

  data: function () {
    return {
      loading: false,
      title: '',
      info: '',
      register_date: '',
      stop_date: null,
      read_only: false,
      confirm_delete: false,
    };
  },

  computed: {
    saveButtonDisabled() {
      // start datum aangepast
      if (this.register_date && this.register_date !== this.indication.register_date) {
        return false;
      }
      // eind datum toegevoegd
      if (!this.indication.stop_date && this.stop_date) {
        return false;
      }
      // eind datum verwijderd
      if (this.indication.stop_date && !this.stop_date) {
        return false;
      }
      // eind datum gewijzigd
      if (this.indication.stop_date && this.stop_date && this.indication.stop_date !== this.stop_date) {
        return false;
      }
      // bijzonderheden gewijzigd
      if (this.indication.info !== this.info) {
        return false;
      }
      return true;
    },
    modalTitle() {
      if (this.confirm_delete === true) {
        return 'Verwijder contra-indicatie: ' + this.indication.title;
      } else {
        return 'Wijzig contra-indicatie: ' + this.indication.title;
      }
    },
  },

  created() {
  },

  mounted() {
    this.loadIndication();
  },

  methods: {
    close(refresh) {
      this.$emit('closed', refresh);
    },
    onClosed(event) {
      this.$emit('closed');
    },

    loadIndication() {
      this.title = this.indication.title;
      this.info = this.indication.info;
      this.register_date = this.indication.register_date;

      if (this.register_date === null) {
        this.register_date = '';
      }

      this.stop_date = this.indication.stop_date;
      if (this.stop_date === null) {
        this.stop_date = '';
      }

      if (this.indication.active === false) {
        this.read_only = true;
      } else {
        this.read_only = false;
      }
    },

    store() {
      this.loading = true;
      this.$store.dispatch('indications/update', {
        patient_id: this.indication.patient_id,
        indication_id: this.indication.id,
        data: {
          info: this.info,
          register_date: this.register_date !== '' ? this.register_date : null,
          stop_date: this.stop_date !== '' ? this.stop_date : null,
          updated_at: this.indication.updated_at
        }
      }).then(() => {
        this.loading = false;
        this.close(true);
      }).catch(() => {
        this.loading = false;
      });
    },

    remove() {
      this.loading = true;
      this.$store.dispatch('indications/delete', {
        patient_id: this.indication.patient_id,
        indication_id: this.indication.id,
      }).then(() => {
        this.loading = false;
        this.close(true);
      }).catch(() => {
        this.loading = false;
      });
    }
  },

  watch: {
    indication() {
      this.loadIndication();
    }
  }
};
</script>
