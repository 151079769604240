<template>
  <medi-modal class="report-generator-modal" :loading="loading" :trigger="true" @closed="$emit('closed')"
    size="small"
    :title="routeData.title"
  >
    <span v-if="typeof route.description !== 'undefined' && route.description.length">
      {{ routeData.description }}<br/><br/>
    </span>
    <em>{{ waiting_text }}</em>
  </medi-modal>
</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import MediModal from '@/vue/components/general/MediModal';
import WorksWithV3Reports from '@/vue/mixins/reports/WorksWithV3Reports';

export default {
  emits: ['closed'],
  components: {MediModal},

  props: {
    route: {required: true},
    waiting_text: {default: 'Een moment geduld a.u.b., we zijn de data aan het ophalen'},
  },

  mixins: [
    BaseComponentsMixin,
    WorksWithV3Reports
  ],

  data: function () {
    return {
      loading: false,
    };
  },

  computed: {
    routeData() {
      return this.$store.getters['routes/routes'](this.route);
    },
    validRoute() {
      return this.route.includes('/reports/v3');
    },
    hasErrors() {
      return this.$store.getters['api/hasOldStyleErrors'];
    }
  },

  created() {
    if (this.validRoute) {
      this.getReport();
    }
    else {
      this.$store.dispatch('notifications/addDangerAlert', {
        title: 'Ongeldige overzicht route ingesteld voor ' + this.route
      });
      this.$emit('closed');
    }
  },

  mounted() {
    //
  },

  methods: {
    getReport() {
      this.loading = true;
      this.$store.dispatch('reports/generate', {
        report_type: this.report_type,
        //report_options: this.report_options,
      }).then(report => {
        // Met replace() zorgen we er voor dat als iemand history
        // back gaat, hij op de pagina _hiervoor_ uit komt
        // en niet in een loop blijft hangen
        if (!this.hasErrors && report.generated_on !== null) {
          this.$router.push('/reports/' + report.id);
        }
        this.$emit('closed');
      }).catch(error => {
        this.loading = false;
      });
    }
  },

  watch: {
    //
  }
};
</script>
