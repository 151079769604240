<template>
  <medi-modal :loading="loading" title="Apotheek-import" size="medium" @closed="$emit('closed')">
    <medimo-form>
      <medimo-form-row>
        <medimo-form-column column_classes="col-12">
          <p v-if="message" v-html="message"></p>
          <medimo-notification class="mb-2" type='danger' :exclamation="true" v-if="patient.latestImport > patient.latestSuccessfulImport">
            De laatste apotheek-import van {{ patient.latestImport }} was niet succesvol
          </medimo-notification>
          <MedimoLabeledTextOnly label_column_classes="col-sm-7" :bold_text="true" label="De laatste succesvolle apotheek-import was op:" :text="patient.latestSuccessfulImport"></MedimoLabeledTextOnly>
          <MedimoLabeledTextOnly label_column_classes="col-sm-7" :bold_text="true" label="Medicatiegegevens beschikbaar tot en met:" :text="patient.latestProcessedUntil"></MedimoLabeledTextOnly>
        </medimo-form-column>
      </medimo-form-row>
    </medimo-form>

    <template v-slot:action-buttons>
      <medimo-button v-if="patient.location.moduleAIS !== 'N'" @click.native="newIntake">
        <span>Nieuw intakeformulier</span>
      </medimo-button>
      <medimo-button v-if="patient.aisPlanImportVisible" @click.native="aisImport">
        <span>AIS import inplannen</span>
      </medimo-button>
      <medimo-button class="mr-auto" type="outline-primary" @click.native="show_import_history_modal = true">
        Historie
      </medimo-button>
      <medimo-button @click.native="$emit('closed')">
        Sluiten
      </medimo-button>
    </template>

    <import-history-modal
        v-if="show_import_history_modal"
        :patient_id="patient.id"
        @closed="show_import_history_modal = false"
    ></import-history-modal>
  </medi-modal>
</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import ImportHistoryModal from "@/vue/components/patients/import/ImportHistoryModal.vue";
import MedimoLabeledTextOnly from "@/vue/components/general/form/base/MedimoLabeledTextOnly.vue";

export default {
  components: {MedimoLabeledTextOnly, ImportHistoryModal},
  mixins: [BaseComponentsMixin],
  props: ['patient'],
  data: function () {
    return {
      loading: false,
      show_import_history_modal: false,
      message: '',
    };
  },
  created() {
    //
  },
  computed: {},
  methods: {
    aisImport() {
      this.loading = true;
      this.$store.dispatch('api/getEndpoint', {
        endpoint: '/api/v1/patients/' + this.patient.id + '/plan-ais-import',
      }).then(result => {
        this.$store.dispatch(`notifications/addSuccessAlert`, {message: result.data.message});
      }).catch(() => {
        // empty catch to prevent uncaught error notification
      }).finally(() => {
        this.loading = false;
      });
    },
    newIntake() {
      this.loading = true;
      this.$store.dispatch('api/getEndpoint', {
        endpoint: '/api/v1/patients/' + this.patient.id + '/new-intake',
      }).then(result => {
        // Ververs de client, als er een nieuwe popup in zit, dan popt die vanzelf op
        this.$store.dispatch('patients/fetch_single', {id: this.patient.id});
        // Toon nog een notifcation of het gelukt is
        this.$store.dispatch('notifications/addSuccessAlert', {title: result.data.message});
      }).catch(() => {
        // empty catch to prevent uncaught error notification
      }).finally(() => {
        this.loading = false;
      });
    },
  }
};
</script>
