<template>
  <medimo-validated-vue-select2 v-if="vueSelect"
                                label_column_classes="col-md-3 pl-2"
                                class="mt-2"
                                :help_text="helpText"
                                label="Batchnummer"
                                placeholder="Verplicht"
                                :minimum_results_for_search="2"
                                :loading="loading"
                                :options="options"
                                v-model="localValue"
                                name="batch_number">
  </medimo-validated-vue-select2>
  <medimo-validated-input
      v-else
      label_column_classes="col-md-3 pl-2"
      class="mt-2"
      :label="'Batchnummer'"
      v-model="localValue"
      name="batch_number"
      placeholder="Verplicht"
  ></medimo-validated-input>
</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import MedimoValidatedVueSelect2 from '@/vue/components/general/form/base/vue-select2/MedimoValidatedVueSelect2';

export default {
  components: {MedimoValidatedVueSelect2},
  props: [
    'value',
    'vaccine_id',
    'type',
  ],
  emits: ['input','batchesUpdated'],
  mixins: [BaseComponentsMixin],
  data: function () {
    return {
      localValue: null,
      options: [],
      loading: false,
      vueSelect: true
    };
  },
  computed: {
    helpText() {
      return 'Het batchnummer zoals op de flacon vermeld staat. Dit is belangrijk voor een juiste registratie en veiligheidsbewaking achteraf. U krijgt alleen batchnummers van het geselecteerde geneesmiddel te zien.';
    },
    cluster_id() {
      return this.$store.state.current_user.data.cluster_id;
    }
  },
  created() {},
  mounted() {
    this.fetchBatchNumbers();
  },
  methods: {
    fetchBatchNumbers() {
      this.loading = true;
      this.$store.dispatch('api/getEndpoint', {
        endpoint: '/api/v1/clusters/' + this.cluster_id + '/vaccinations/' + this.type + '/batches?vaccine_id=' + this.vaccine_id,
      }).then(response => {
        // Hier zetten we de ResourceCollection om in een Select2 format
        this.options = response.data.data.map(batch => {
          return {value: batch.id.toString(), text: batch.number};
        });

        this.vueSelect = true;
        if (this.options.length === 1) {
          this.localValue = this.options[0].value;
          if (this.options[0].text === '') {
            this.localValue = '';
            this.vueSelect = false;
          }
        }

        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    }
  },
  watch: {
    value(value) {
      this.localValue = value;
    },
    localValue(value) {
      this.$emit('input', value);
    },
    vaccine_id() {
      this.fetchBatchNumbers();
    },
    options: {
      deep: true,
      handler(value) {
        this.$emit('batchesUpdated', value);
      }
    }
  }
};
</script>
