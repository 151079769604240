import BaseModule from '@/vue/store/modules/base_module';

const state = {
  ...BaseModule.state,
  search_endpoint: '/api/v1/logs/naw',
  quick_filters: {'Fouten': false},
  sort_columns: [
    {column_name: 'created_at', direction: 'DESC'}
  ],
};

const getters = {...BaseModule.getters};
const actions = {
  ...BaseModule.actions,
  // updateParams
};
const mutations = {...BaseModule.mutations};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
