import BaseStockRecordDetail from '@/vue/store/modules/stock/base_stock_record_detail';

const state = {
  ...BaseStockRecordDetail.state,
  quick_filters: {
    'Toon opiaten' : false,
    'reservation' : {
      value: true,
      visible: false,
    },
  },
};
const actions = {
  ...BaseStockRecordDetail.actions
};
const getters = {
  ...BaseStockRecordDetail.getters
};
const mutations = {
  ...BaseStockRecordDetail.mutations
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
