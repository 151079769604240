import BaseModule from '@/vue/store/modules/base_module';

const state = {
  ...BaseModule.state,
  history: {},
  checkBsnResult: {},
  checkWidResult: {},
  values: {}
};

const getters = {};

const actions = {

  index({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/patients/' + payload.patient_id + '/bsn/index';

    return dispatch('api/getEndpoint', {
      endpoint: endpoint,
      data: {} // Empty, since it's a GET
    }, {root: true}).then(response => {
      commit('add_to_values', {patient_id: payload.patient_id, bsn_values: response.data.data});
    });
  },

  performBsnCheck({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/patients/' + payload.patient_id + '/bsn/perform-bsn-check';

    return dispatch('api/getEndpoint', {
      endpoint: endpoint,
      data: {} // Empty, since it's a GET
    }, {root: true}).then(response => {
      commit('add_to_check_bsn_result', {patient_id: payload.patient_id, bsn_check_result: response.data.data});
    });
  },

  performWidCheck({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/patients/' + payload.patient_id + '/bsn/perform-wid-check';

    return dispatch('api/postEndpoint', {
      endpoint: endpoint,
      data: payload.data,
    }, {root: true}).then(response => {
      commit('add_to_check_wid_result', {patient_id: payload.patient_id, wid_check_result: response.data.data});
    });
  },

  fetchHistory({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/patients/' + payload.patient_id + '/bsn/history';

    return dispatch('api/getEndpoint', {
      endpoint: endpoint,
      data: {} // Empty, since it's a GET
    }, {root: true}).then(response => {
      commit('add_to_history', {patient_id: payload.patient_id, bsn_history: response.data.data});
    });
  },
};

const mutations = {
  ...BaseModule.mutations,

  add_to_values(state, payload) {
    state.values[payload.patient_id] = payload.bsn_values;
  },
  add_to_history(state, payload) {
    state.history[payload.patient_id] = payload.bsn_history;
  },
  clear_history(state, payload) {
    delete state.history[payload.patient_id];
  },
  add_to_check_bsn_result(state, payload) {
    state.checkBsnResult[payload.patient_id] = payload.bsn_check_result;
  },
  add_to_check_wid_result(state, payload) {
    state.checkWidResult[payload.patient_id] = payload.wid_check_result;
  },
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
