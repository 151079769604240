import BaseModule from '@/vue/store/modules/base_module';

// initial state
const state = {
  ...BaseModule.state,
  data: []
};

// getters
const getters = {
  ...BaseModule.getters,

  validPatientToken(state, getters, rootState) {
    return (patientId) => {
      // We verwachten 1 token die op dit moment geldig is
      const validTokens = state.data.filter((model) => {
        return model.valid &&
          parseInt(model.patient_id) === parseInt(patientId);
      });
      if (validTokens.length > 0) {
        return validTokens[0];
      }
    };
  },
};

// actions
const actions = {
  ...BaseModule.actions,

  fetch({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/lsp/token/' + payload.patientId;

    return dispatch('api/getEndpoint', {
      endpoint: endpoint,
      data: {}
    }, {root: true}).then(response => {
      commit('set_data', response.data.data);
    });
  },

  createToken({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/lsp/signtoken/' + payload.patientId;

    return dispatch('api/postEndpoint', {
      endpoint: endpoint,
      data: {}
    }, {root: true}).then(response => {
      dispatch('fetch', { patientId: payload.patientId});
    });
  },

  invalidateToken({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/lsp/token/'+ payload.patientId +'/invalidate';

    return dispatch('api/postEndpoint', {
      endpoint: endpoint,
      data: payload
    }, {root: true}).then(response => {
      dispatch('fetch', { patientId: payload.patientId});
    });
  },
};

// mutations
const mutations = {
  ...BaseModule.mutations
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
