<template>
  <div>
    <div class="filter-group" v-for="(group, gIndex) in tree.groups">
      <div class="filter-items">
        <div class="filter-item" v-for="(item, iIndex) in group.items">
          <div v-if="iIndex === group.items.length-1" class="add-item-button" @click="addItem(group)"><fa-icon icon="fa-solid fa-plus" /></div>
          <div v-else class="add-item-spacer"></div>
          <filter-item-select2 :ajax_route="filter_route" :item="item" :value="item.value" :options="[{id: item.value, text: item.text}]" />
          <div class="delete-item-button" @click="deleteItem(group, item)"><fa-icon icon="fa-solid fa-times" /></div>
          <div v-if="iIndex !== group.items.length - 1" v-on:click.prevent="toggleLogic(group)" class="button group-logic">{{ prettyLogic(group.logic) }}</div>
        </div>
      </div>
      <div v-if="gIndex !== tree.groups.length - 1" v-on:click.prevent="toggleLogic(tree)" class="button tree-logic">{{ prettyLogic(tree.logic) }}</div>
    </div>
    <div class="button group" v-on:click.prevent="addGroup"><fa-icon icon="fa-solid fa-plus" /> groep</div>
  </div>
</template>

<script>
import FilterItemSelect2 from "@/vue/components/protocol/FilterItemSelect2.vue";

export default {
  components: {FilterItemSelect2},
  props: {
    tree: {required: true},
    filter_route: {required: true},
  },
  data: function () {
    return {
      name: '0',
      uniqueId: 'treeFilterId',
      disabled: false,
    };
  },
  methods: {
    prettyLogic: function (logic) {
      return logic === 'a' ? 'en' : 'of';
    },
    toggleLogic(group) {
      group.logic = group.logic === 'a' ? 'o' : 'a';
    },
    getLastId(subset) {
      let lastId = 0;
      subset.forEach(function (single) {
        const parts = single.name.split('-');
        const id = parseInt(parts[parts.length - 1]);
        if (id > lastId) {
          lastId = id;
        }
      });
      return lastId;
    },
    addGroup: function () {
      const lastId = this.getLastId(this.tree.groups);
      const newGroup = {t: 'g', name: '0-g-' + (lastId + 1), logic: 'a', items: []};
      this.addItem(newGroup);
      this.tree.groups.push(newGroup);
    },
    addItem(group) {
      const lastId = this.getLastId(group.items);
      group.items.push({t: 'i', name: group.name + '-i-' + (lastId + 1), value: '', text: ''});
    },
    deleteItem(group, item) {
      const items = group.items;

      //if the last item of the group is being deleted (and there are no groups), delete the group
      if (items.length === 1) {
        this.deleteGroup(group);
      } else {
        //otherwise delete the item
        items.splice(items.indexOf(item), 1);
      }
    },
    deleteGroup(group) {
      const groups = this.tree.groups;
      groups.splice(groups.indexOf(group), 1);
    },
  }
};
</script>

<style scoped lang="scss">
.button {
  color: #444;
  background-color: #F3F3F3;
  padding: 8px;
  height: 32px !important;
  &.group {
    margin: 10px;
  }
  &.group-logic, &.tree-logic {
    width: 32px;
    text-align: center;
  }
  &.tree-logic {
    margin: 5px;
    vertical-align: bottom;
  }
}
.filter-items {
  border: 1px solid #E3E3E3;
  margin: 5px 5px 5px 0;
  padding: 5px 5px 10px;
  min-width: 365px;
  display: inline-block;
  .filter-item {
    margin-bottom: 5px;
    .add-item-button, .add-item-spacer, .delete-item-button {
      padding: 8px;
      height: 32px;
      width: 32px;
      vertical-align: middle;
      display: inline-block;
      &.add-item-button, &.delete-item-button {
        color: #757575;
        cursor: pointer;
      }
    }
  }
}
</style>
