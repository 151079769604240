<template>
  <div class="root" v-if="isValidJson">
    <json-tree-branch
        :jsonKey="''"
        :jsonValue="jsonData"
        :layer="1"
        :query-value="query"
        :onlyShowMatches="onlyShowMatches"
        :fadeMatchContent="fadeMatchContent"
        :show-match-content="showMatchContent"
        :auto-collapse-after="autoCollapseAfter"
        :properties-to-show="propertiesToShow"
    ></json-tree-branch>
  </div>
  <div class="row" v-else>
    <div class="col col-sm-6">
      <medimo-notification type="danger">Invalide JSON</medimo-notification>
    </div>
  </div>
</template>

<script>
import JsonTreeBranch from './JsonTreeBranch';
import MedimoNotification from '@/vue/components/general/MedimoNotification';

export default {
  name: "JsonTree",
  components: {MedimoNotification, JsonTreeBranch},
  props: {
    rawData: String,
    propertiesToShow: {default: []},
    autoCollapseAfter: {default: 3}, // Collapsed de regels als ze 3 niveaus + diep zijn
    query: {default: ''},
    onlyShowMatches: {default: true},
    fadeMatchContent: {default: true},
    showMatchContent: {default: true},
  },
  data() {
    return {
      isValidJson: null,
    };
  },
  computed: {
    jsonData() {
      if (this.isValidJson) {
        return JSON.parse(this.rawData);
      }
      return [];
    }
  },
  created() {
    // We willen een error kunnen tonen als de Json invalid is zonder dat de hele app direct krak zegt
    this.isValidJson = true;

    try {
      JSON.parse(this.rawData);
    } catch (e) {
      this.isValidJson = false;
    }
  },
};
</script>
