<template>
    <page-block v-bind="$attrs">
        <slot></slot>
    </page-block>
</template>

<script>

import PageBlock from './PageBlock';

export default {
  components: {
    PageBlock
  },

  props: [],

  mixins: [],

  data: function () {
    return {
      //
    };
  },

  computed: {
    //
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    //
  },

  watch: {
    //
  }
};
</script>
