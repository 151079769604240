<template>
  <medi-modal id="advanced-filters" title="Overzicht instellingen" :trigger="true" @closed="$emit('closed')" :loading="loading">
    <medimo-form>
      <div class="m-0 p-0" v-for="(filter, filterName) in report.filters" :key="filterName">

        <!-- location_selector -->
        <medimo-validated-vue-select2 v-if="filter.type === 'location_selector'" class="mt-2" :label="filter.label" label_column_classes="col-12 col-sm-4" :name="filterName">
          <template v-slot:custom>
            <location-select2 :fetch_on_load="!hasWardSelecter" :multiple="filter.multiple" :cluster_id="null" :help_text="filter.help_text" :placeholder="filter.placeholder" @changed="updateFilterValue(filterName, $event)"></location-select2>
          </template>
        </medimo-validated-vue-select2>

        <!-- ward_selector -->
        <medimo-validated-vue-select2 v-if="filter.type === 'ward_selector'" class="mt-2" :label="filter.label" label_column_classes="col-12 col-sm-4" :name="filterName">
          <template v-slot:custom>
            <ward-select2 :fetch_on_load="true" :multiple="filter.multiple" :cluster_id="null" :location_id="null" :help_text="filter.help_text" :placeholder="filter.placeholder" @changed="updateFilterValue(filterName, $event)"></ward-select2>
          </template>
        </medimo-validated-vue-select2>

        <!-- patient_selector -->
        <medimo-validated-vue-select2 v-if="filter.type === 'patient_selector'" class="mt-2" :label="filter.label" label_column_classes="col-12 col-sm-4" :name="filterName">
          <template v-slot:custom>
            <patient-select2 :multiple="filter.multiple" :cluster_id="null" :help_text="filter.help_text" :placeholder="filter.placeholder" @changed="updateFilterValue(filterName, $event)"></patient-select2>
          </template>
        </medimo-validated-vue-select2>

        <!-- atc_selector -->
        <medimo-validated-vue-select2 v-if="filter.type === 'atc_selector'" class="mt-2" :label="filter.label" label_column_classes="col-12 col-sm-4" :name="filterName">
          <template v-slot:custom>
            <atc-select2 :multiple="filter.multiple" :help_text="filter.help_text" :placeholder="filter.placeholder" @changed="updateFilterValue(filterName, $event)"></atc-select2>
          </template>
        </medimo-validated-vue-select2>

        <!-- drug_id_selector -->
        <medimo-validated-vue-select2 v-if="filter.type === 'drug_id_selector'" class="mt-2" :label="filter.label" label_column_classes="col-12 col-sm-4" :name="filterName">
          <drug-id-select2 :help_text="filter.help_text" :placeholder="filter.placeholder" @changed="updateFilterValue(filterName, $event)"></drug-id-select2>
        </medimo-validated-vue-select2>

        <!-- date_picker -->
        <template v-if="filter.type === 'date_picker'">
          <medimo-validated-date-picker
              :name="filterName"
              placeholder="Selecteer datum"
              :class="'mt-2 date-picker ' + filterName"
              :maxDate="filter.maxDate.length ? filter.maxDate : '-'"
              :minDate="filter.minDate.length ? filter.minDate : '-'"
              label_column_classes="col-12 col-sm-4"
              :label="filter.label"

              :modelValue="filterValues[filterName]"
              @update:modelValue="updateFilterValue(filterName, $event)"

              :help_text="filter.help_text"
              :close_on_date_change="true"
          >
          </medimo-validated-date-picker>
        </template>


        <!-- date_time_picker -->
        <template v-if="filter.type === 'date_time_picker'">
          <medimo-validated-date-time-picker
              :name="filterName"
              placeholder="Selecteer datum/tijd"
              :class="'mt-2 date-time-picker ' + filterName"
              label_column_classes="col-12 col-sm-4"
              :label="filter.label"

              :modelValue="filterValues[filterName]"
              @update:modelValue="updateFilterValue(filterName, $event)"

              :help_text="filter.help_text"
              :close_on_time_change="true"
          >
          </medimo-validated-date-time-picker>
        </template>

        <!-- input_text -->
        <template v-if="filter.type === 'input_text'">
          <medimo-validated-input
              :name="filterName"
              :placeholder="filter.placeholder"
              label_column_classes="col-12 col-sm-4"
              :label="filter.label"

              :modelValue="filterValues[filterName]"
              @update:modelValue="updateFilterValue(filterName, $event)"

              :help_text="filter.help_text"
          >
          </medimo-validated-input>
        </template>
      </div>
    </medimo-form>

    <template v-slot:action-buttons>
      <medimo-button type="button" class="send-button btn btn-primary ml-auto" @click="generateReport()">
        Nieuw
      </medimo-button>
      <medimo-button type="button" class="btn-outline-primary" @click="$emit('closed')">
        Annuleren
      </medimo-button>
    </template>

  </medi-modal>
</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import WardSelect2 from '@/vue/components/selectors/WardSelect2';
import LocationSelect2 from '@/vue/components/selectors/LocationSelect2';
import PatientSelect2 from "@/vue/components/selectors/PatientSelect2.vue";
import AtcSelect2 from "@/vue/components/selectors/AtcSelect2.vue";
import DrugIdSelect2 from "@/vue/components/selectors/DrugIdSelect2.vue";

export default {
  emits: ['closed'],
  components: {PatientSelect2, LocationSelect2, WardSelect2, AtcSelect2, DrugIdSelect2},

  props: ['report'],

  mixins: [BaseComponentsMixin],

  data: function () {
    return {
      showModal: false,
      loading: false,
      wards: [],

      filterValues: {},
    };
  },

  computed: {
    hasWardSelecter() {
      let hasSelector = false;
      Object.keys(this.report.filters).forEach(filterName => {
        const filter = this.report.filters[filterName];
        if (filter.type === 'ward_selector') {
          hasSelector = true;
        }
      });
      return hasSelector;
    }
  },

  created() {
    // Even alle defaults setten:
    Object.keys(this.report.filters).forEach(filterName => {
      const filter = this.report.filters[filterName];
      if (['location_selector', 'ward_selector'].includes(filter.type)) {
        this.filterValues[filterName] = (filter.multiple ? [] : '');
      } else if (['date_picker', 'date_time_picker'].includes(filter.type)) {
        this.filterValues[filterName] = null;
      } else {
        this.filterValues[filterName] = '';
      }
      if (filterName === 'date_from') {
        this.filterValues[filterName] = this.report.default_from_datetime;
      } else if (filterName === 'date_to') {
        this.filterValues[filterName] = this.report.default_to_datetime;
      }
    });
  },

  mounted() {
    //
  },

  methods: {
    updateFilterValue(filterName, value) {
      this.filterValues[filterName] = value;
      // Dit moet hier omdat hij anders de interne waarde niet reactive update en de display in de DateTimePicker dan inc hours is.
      this.$forceUpdate();
    },
    generateReport() {
      this.loading = true;

      // Als het een bestaand report is, updaten we 'm
      let requestType = 'post';
      let endpoint = '/api/v1/reports/' + this.report.id;
      // Als het een nieuw report is, createn we 'm met de filters in het request
      if (typeof this.report.id === 'undefined' || this.report.id === null) {
        requestType = 'get';
        endpoint = '/api/v1/reports/type/' + this.report.report_class;

        let concatChar = '?';
        Object.keys(this.filterValues).forEach(property => {
          if (Array.isArray(this.filterValues[property]) && this.filterValues[property].length) {
            this.filterValues[property].forEach(filterValue => {
              endpoint += concatChar + property + '[]=' + filterValue;
              concatChar = '&';
            });
          } else {
            endpoint += concatChar + property + '=' + this.filterValues[property];
          }

          concatChar = '&';
        });
      }


      this.$store.dispatch('api/' + requestType + 'Endpoint', {
        endpoint: endpoint,
        data: this.filterValues
      }).then(response => {
        const newReport = response.data.data;
        // Loading kan blijven omdat we toch gaan navigeren

        if (newReport.status === 3) {
          // Hier moet een harde navigatie zitten om zo een page refreshen te enforcen
          // anders kunnen er problemen ontstaan met het tonen van de verkeerde kolommen
          window.location.href = '/reports/' + newReport.id;
        } else {
          this.$router.push('/reports/list');
        }

      }).catch(error => {
        this.loading = false;
      });
    }
  },

  watch: {
    //
  }
};
</script>
