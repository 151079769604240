import Times from '@/vue/utility/times';
import Quantity from '@/vue/utility/quantity';
import Utility from '@/vue/utility/utility';
import PrescriptionTypes from '@/vue/constants/prescription_types';

export default {
  getMedicationGuardValues(dosingInstruction, encodedQuantity) {

    let quantity = encodedQuantity;

    let usageType = dosingInstruction.usage_type;

    if (dosingInstruction.prescription_type === PrescriptionTypes.MANUAL_DOSING_SCHEMA) {
      usageType = 'S';
      quantity = '';
    }

    let statusId = false;
    if (!Utility.is_new_id(dosingInstruction.id)) {
      statusId = dosingInstruction.id;
    }

    let firstStatusId = false;
    if (!Utility.is_new_id(dosingInstruction.first_dosing_instruction_id)) {
      firstStatusId = dosingInstruction.first_dosing_instruction_id;
    }

    let stopOrMutate = dosingInstruction.stop_datetime;
    if (dosingInstruction.mutation_datetime) {
      stopOrMutate = dosingInstruction.mutation_datetime;
    }

    return {
      'usageType': usageType,
      'id': dosingInstruction.id,
      'gnmid': dosingInstruction.drug_id,
      'gnm': dosingInstruction.drug_name,
      'manualdosing-enabled': dosingInstruction.usage_type === 'S',
      'quantity': quantity,
      'administrationRoute': dosingInstruction.administration_route,
      'episodes': dosingInstruction.episodes,
      'amount': dosingInstruction.amount,
      'dosingUnits': dosingInstruction.dosing_unit,
      'deenh': dosingInstruction.dosing_unit,
      'dosageInterval': dosingInstruction.dosage_interval_id,
      'dinterval': dosingInstruction.dosage_interval_id,
      'firstStatusId': firstStatusId,
      'statusId': statusId,
      'startDateTime': dosingInstruction.start_datetime,
      'mutationDateTime': null,
      'startMomentDateTime': dosingInstruction.start_moment_datetime,
      'stopDateTime': stopOrMutate,
      'relatedStatusIdToBeStopped': false,
      'rawResponsePlease': true,
      'wcia': dosingInstruction.wcia,
    };
  },
  getHumanTimeValues(timeValues, standardTimes) {

    const times = timeValues.map(function (time) {
      return Times.encodeTime(time.time);
    });

    const notStandardTimes = times.filter( (time) => {
      return time !== "" && !standardTimes.includes(time);
    });

    if (notStandardTimes.length === 0) {
      const nonEmptyStandardTimes = standardTimes.filter(function (time) {
        return time !== "";
      });

      const values = nonEmptyStandardTimes.map(function (time) {
        const entry = Utility.find_model_by_property(timeValues, 'time', time);
        const value = entry ? entry.value : 0;
        return value;
      });
      return values.join(' - ');
    }
    else {
      const nonEmpty = timeValues.filter(function (time) {
        return time.time !== "" && time.value !== "" && (time.value === 'x' || parseFloat(time.value) !== 0) ;
      });
      const values = nonEmpty.map(function(entry) {
        return Times.decodeTime(entry.time) + ' - ' + entry.value;
      });
      return values.join(', ');
    }
  },
  getState(dosageInstruction, allDosageInstructions) {
    if (allDosageInstructions[0].id === dosageInstruction.id) {
      return 'START';
    } else {
      return 'WIJZIGING';
    }
  },
};
