import {MedicationVerification} from '@/domain/models/MedicationVerification';
import moment from 'moment';

export class GeneratedMedicationVerification { 
  public id: number;
  public patientId: number;
  public createdByUserName: string;
  public createdAt: moment.Moment;
  public completedByUserName: string | null;
  public completedAt: moment.Moment | null;
  public informant: string;
  public informantContactPerson: string | null;

  constructor(id: number, patientId: number, createdByUserName: string, createdAt: moment.Moment, completedByUserName: string | null = null, completedAt: moment.Moment | null = null, informant: string, informantContactPerson: string | null = null) {
    this.id = id;
    this.patientId = patientId;
    this.createdByUserName = createdByUserName;
    this.createdAt = createdAt;
    this.completedByUserName = completedByUserName;
    this.completedAt = completedAt;
    this.informant = informant;
    this.informantContactPerson = informantContactPerson;
  }

  public static fromJson(json: unknown): MedicationVerification {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new MedicationVerification(
      parsedJson['id'] as number,
      parsedJson['patientId'] as number,
      parsedJson['createdByUserName'] as string,
      moment.parseZone(parsedJson['createdAt']),
      parsedJson['completedByUserName'] as string,
      parsedJson['completedAt'] ? moment.parseZone(parsedJson['completedAt']) : null,
      parsedJson['informant'] as string,
      parsedJson['informantContactPerson'] as string,
    );
  }

  public static multipleFromJson(json: unknown): MedicationVerification[] {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    const items: MedicationVerification[] = [];
    for (const item of parsedJson) {
      items.push(this.fromJson(item));
    }
    return items;
  }
}
