<template>
  <div :class="'value clickable ' + colorClass" @click="copyValueToClipboard" ref="jsonValue">
    <a v-if="isUrl" :href="jsonValue">
      {{ jsonValue }}
    </a>
    <span v-else>
      {{ jsonValue === null ? 'null' : jsonValue }}
    </span>
  </div>
</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import copy from '@/vue/utility/copy';

export default {
  components: {},

  props: ['jsonValue'],

  mixins: [BaseComponentsMixin],

  data: function () {
    return {
      //
    };
  },

  computed: {
    colorClass() {
      if (this.isString) {
        return 'text-medimo';
      }
      else if (this.isBoolean) {
        return 'text-bright-blue';
      }
      else if (this.isInt) {
        return 'text-bright-blue';
      }
      else if (this.isNull) {
        return 'text-muted';
      }
    },
    // Type checkers
    isNull() {
      return this.jsonValue === null;
    },
    isInt() {
      return Number.isInteger(this.jsonValue);
    },
    isFloat() {
      return +this.jsonValue === this.jsonValue && (!isFinite(this.jsonValue) || !!(this.jsonValue % 1));
    },
    isArray() {
      return Array.isArray(this.jsonValue);
    },
    isObject() {
      return typeof this.jsonValue === 'object' && !this.isArray;
    },
    isString() {
      return (typeof this.jsonValue === 'string' || this.jsonValue instanceof String);
    },
    isBoolean() {
      return typeof variable === "boolean";
    },
    isUrl() {
      const regexp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
      return regexp.test(this.jsonValue);
    },
  },
  methods: {
    copyValueToClipboard() {
      copy.selectAndCopyText(this.$refs.jsonValue);
      this.$store.dispatch('notifications/addSuccessAlert', {message: 'Gekopieerd naar het klembord'});
    }
  },
};
</script>
