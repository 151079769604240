<template>
    <!-- A Timepicker to add a new time, in the last column -->
    <medimo-date-time-picker :only_time="true" @update:modelValue="addTime($event)" aria-hidden="true">

      <template v-slot:custom>
        <slot v-if="!!this.$slots.custom"></slot>
        <fa-icon v-else icon="fa-solid fa-plus" class="insert-new-time-th clickable" title="extra tijd" size="lg" />
      </template>

    </medimo-date-time-picker>
</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';

export default {
  emits: ['add_time'],
  components: {},

  props: [
    'use_standard_times',
  ],

  mixins: [BaseComponentsMixin],

  data: function () {
    return {
      //
    };
  },

  computed: {
    //
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    addTime(event) {
      this.$emit('add_time', event);
    }
  },

  watch: {
    //
  }
};
</script>
