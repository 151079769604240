<template>
    <nav>
        <ul class="pagination">
            <li class="page-item">
                <a class="page-link" href="#" @click.prevent="goToPage(first_page)">
                    <span class="fa fa-angle-double-left"></span> First
                </a>
            </li>
            <li class="page-item">
                <a class="page-link" href="#" @click.prevent="goToPage(current_page - 1)" >
                    <span class="fa fa-angle-left"></span> Previous
                </a>
            </li>
            <li class="page-item" v-for="page_number in page_numbers" v-bind:class="{active: isActivePage(page_number)}">
                <a class="page-link" href="#" @click.prevent="goToPage(page_number)">
                    {{ page_number }}
                </a>
            </li>
            <li class="page-item">
                <a class="page-link" href="#" v-if="(current_page + 1) <= last_page" @click.prevent="goToPage(current_page + 1)">
                    Next <span class="fa fa-angle-right"></span>
                </a>
            </li>
            <li class="page-item">
                <a class="page-link" href="#" @click.prevent="goToPage(last_page)">
                    Last <span class="fa fa-angle-double-right"></span>
                </a>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
  components: {},
  emits: ['on_loading_trigger'],
  props: [
    'on_loading_trigger', // The function to call when the navigation is changing; use to change a loading variable
    'go_to_page_action',
    'meta' // The META data from Laravel, needed to get everything working
  ],

  mixins: [],

  data: function () {
    return {
      //
    };
  },

  computed: {
    current_page() {
      return this.meta.current_page;
    },
    first_page() {
      return 1;
    },
    last_page() {
      return this.meta.last_page;
    },
    all_pages() {
      let current_page = 1;
      const page_numbers = [];

      while (current_page <= this.last_page()) {
        page_numbers.push(current_page);
        current_page++;
      }

      return page_numbers;
    },
    page_numbers() {
      const page_numbers = [];

      // Generate a smaller set if we have less than 500 results
      if (this.last_page < 10) {
        let page = 1;
        while (page <= this.last_page) {
          page_numbers.push(page);
          page++;
        }
      }
      // Generate the moving window when beyond page 7
      // For the first last - 4 pages
      else if (this.current_page >= 7 && this.current_page < (this.last_page - 4)) {

        page_numbers.push(this.current_page - 5);
        page_numbers.push(this.current_page - 4);
        page_numbers.push(this.current_page - 3);
        page_numbers.push(this.current_page - 2);
        page_numbers.push(this.current_page - 1);
        page_numbers.push(this.current_page);
        page_numbers.push(this.current_page + 1);
        page_numbers.push(this.current_page + 2);
        page_numbers.push(this.current_page + 3);
        page_numbers.push(this.current_page + 4);

      }
      // For the last 4 pages
      else if (this.current_page >= 7 && this.current_page <= this.last_page) {
        page_numbers.push(this.last_page - 9);
        page_numbers.push(this.last_page - 8);
        page_numbers.push(this.last_page - 7);
        page_numbers.push(this.last_page - 6);
        page_numbers.push(this.last_page - 5);
        page_numbers.push(this.last_page - 4);
        page_numbers.push(this.last_page - 3);
        page_numbers.push(this.last_page - 2);
        page_numbers.push(this.last_page - 1);
        page_numbers.push(this.last_page);
      }
      else {
        // The first 10 if not.
        // Someone could probably code this a lot cleaner but hey, it gets the job done atm!
        page_numbers.push(1);
        page_numbers.push(2);
        page_numbers.push(3);
        page_numbers.push(4);
        page_numbers.push(5);
        page_numbers.push(6);
        page_numbers.push(7);
        page_numbers.push(8);
        page_numbers.push(9);
        page_numbers.push(10);

      }

      return page_numbers;
    }
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    goToPage(page) {
      this.$emit('on_loading_trigger', true);
      this.$store.dispatch(this.go_to_page_action, page).finally(() => {
        this.$emit('on_loading_trigger', false);
      });
    },
    isActivePage(page_number) {
      return this.current_page === page_number;
    },
  },

  watch: {
    //
  }
};
</script>
