import {ProviderSpecificationObject} from '@/domain/models/ProviderSpecificationObject';
import {CodeObject} from '@/domain/models/CodeObject';
import {AddressObject} from '@/domain/models/AddressObject';
import {IdentifierObject} from '@/domain/models/IdentifierObject';

export class GeneratedProviderSpecificationObject { 
  public organizationName: string;
  public organizationType: CodeObject | null;
  public addresses: AddressObject[] | null;
  public identifiers: IdentifierObject[] | null;

  constructor(organizationName: string, organizationType: CodeObject | null = null, addresses: AddressObject[] | null = null, identifiers: IdentifierObject[] | null = null) {
    this.organizationName = organizationName;
    this.organizationType = organizationType;
    this.addresses = addresses;
    this.identifiers = identifiers;
  }

  public static fromJson(json: unknown): ProviderSpecificationObject {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new ProviderSpecificationObject(
      parsedJson['organizationName'] as string,
      parsedJson['organizationType'] ? CodeObject.fromJson(parsedJson['organizationType']) : null,
      parsedJson['addresses'] ? parsedJson['addresses'].map((e: unknown) => AddressObject.fromJson(e)) : null,
      parsedJson['identifiers'] ? parsedJson['identifiers'].map((e: unknown) => IdentifierObject.fromJson(e)) : null,
    );
  }

  public static multipleFromJson(json: unknown): ProviderSpecificationObject[] {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    const items: ProviderSpecificationObject[] = [];
    for (const item of parsedJson) {
      items.push(this.fromJson(item));
    }
    return items;
  }
}
