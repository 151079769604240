import BaseModule from '@/vue/store/modules/base_module';

const state = {
  ...BaseModule.state,
  search_endpoint: '/api/v1/readings/unread',
  quick_filters: {
    scopeFilter: {
      mustBeActive: true,
      group: {
        'own': {label: 'eigen patient_plural', value: true, translate: true},
        'observation': {label: 'Waarneming', value: false},
      }
    }
  },
  sort_columns: [],
};

const getters = { ...BaseModule.getters };
const actions = { ...BaseModule.actions };
const mutations = { ...BaseModule.mutations };

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
