<template>
  <medimo-tr @triggered="$emit('selected')">

    <medimo-td column_classes="col col-sm-3">
      <span>{{ episode.code }}</span>
    </medimo-td>

    <medimo-td column_classes="col col-sm-9">
      <span>{{ episode.text }}</span>
    </medimo-td>

  </medimo-tr>
</template>

<script>

import MedimoTd from '@/vue/components/general/table/MedimoTd';
import MedimoTr from '@/vue/components/general/table/MedimoTr';

export default {
  components: {
    MedimoTd,
    MedimoTr,
  },
  emits: ['selected'],

  props: [
    'episode',
  ],

  mixins: [],

  data: function () {
    return {
    };
  },

  computed: {
  },

  created() {
  },

  mounted() {
  },

  methods: {
  },

  watch: {
  }
};
</script>
