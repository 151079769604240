import BaseModule from '@/vue/store/modules/base_module';

// initial state
const state = {
  ...BaseModule.state,

  data: [],

  quick_filters: {

  },

  toggleQuickFilters: true,
};

// getters
const getters = {
  ...BaseModule.getters
};

// actions
const actions = {
  ...BaseModule.actions,
};

// mutations
const mutations = {
  ...BaseModule.mutations,
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
