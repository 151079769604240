<template>
  <medi-modal
      :id="'find-zindex-intolerance-table'"
      :class="'find-zindex-intolerance-table'"
      :title="title"
      :loading="loading"
      size="large"
      :min_height="0"
      @closed="$emit('closed');"
  >
    <medimo-external-data-table
        :store_name="store_name"
        :number_of_rows="models.length"
        :refresh_table="refresh_table"
        :minimum_height="true"
        :name="'patient-zindex-intolerances-' + patient.id"
        :privacy_filter="false"
    >

      <template v-slot:extra_buttons>
        <medimo-form-column>
          <medimo-button-link :outline="true" :icon="icon_geneesmiddel" :transparent="!btn_geneesmiddel_enabled" @click="setFilterGeneesmiddel"
                              :padding_class="'py-0 pr-2 pl-2 text-small'" class="quick-filter-button">
            Geneesmiddel
          </medimo-button-link>
        </medimo-form-column>

        <medimo-form-column>
          <medimo-button-link :outline="true" :icon="icon_groep" :transparent="!btn_groep_enabled" @click="setFilterGroep"
                              :padding_class="'py-0 pr-2 pl-2 text-small'" class="quick-filter-button">
            Groep
          </medimo-button-link>
        </medimo-form-column>

        <medimo-form-column>
          <medimo-button-link :outline="true" :icon="icon_stofnaam" :transparent="!btn_stofnaam_enabled" @click="setFilterStofnaam"
                              :padding_class="'py-0 pr-2 pl-2 text-small'" class="quick-filter-button">
            Stofnaam
          </medimo-button-link>
        </medimo-form-column>
      </template>

      <medimo-thead>
        <medimo-tr>
          <medimo-th column_classes="col col-sm-12" :store_name="store_name" sort_column_1="text">Naam</medimo-th>
        </medimo-tr>
      </medimo-thead>
      <medimo-tbody>
        <template v-for="model in models">
          <find-zindex-intolerance-data-table-row
              :intolerance="model"
              @selected="zindexIntoleranceSelected(model)"
          ></find-zindex-intolerance-data-table-row>
        </template>
      </medimo-tbody>
    </medimo-external-data-table>

    <template v-slot:action-buttons>
      <medimo-button @click="close">
        Sluiten
      </medimo-button>
    </template>

  </medi-modal>
</template>

<script>


import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import FindZindexIntoleranceDataTableRow from './FindZindexIntoleranceDataTableRow';

export default {
  emits: ['closed','zindexIntoleranceSelected'],
  components: {
    FindZindexIntoleranceDataTableRow
  },

  props: {
    'patient': {required: true},
  },

  mixins: [BaseComponentsMixin],

  data: function () {
    return {
      store_name: 'intolerances_zindex',
      title: '',
      refresh_table: 0,
      loading: false,
      btn_geneesmiddel_enabled: true,
      btn_groep_enabled: false,
      btn_stofnaam_enabled: false,
    };
  },

  computed: {
    models() {
      return this.$store.getters[this.store_name + '/search_results'];
    },
    icon_geneesmiddel() {
      return this.btn_geneesmiddel_enabled ? 'fa-solid fa-square-check' : 'fa-regular fa-square';
    },
    icon_groep() {
      return this.btn_groep_enabled ? 'fa-solid fa-square-check' : 'fa-regular fa-square';
    },
    icon_stofnaam() {
      return this.btn_stofnaam_enabled ? 'fa-solid fa-square-check' : 'fa-regular fa-square';
    }
  },

  created() {

  },

  mounted() {
    // When ready, we set the search endpoint
    this.setFilterGeneesmiddel();
  },

  unmounted() {

  },

  methods: {
    close() {
      this.$emit('closed');
    },
    setFilterGeneesmiddel() {
      this.$store.commit(this.store_name + '/set_search_endpoint', '/api/v1/patients/' + this.patient.id + '/intolerances/zindex-search/geneesmiddel');
      this.$store.commit(this.store_name + '/sort', {column_name: 'text', direction: 'ASC'});
      this.title = 'Zoek op geneesmiddel';
      this.refresh_table++;
      this.btn_geneesmiddel_enabled = true;
      this.btn_groep_enabled = false;
      this.btn_stofnaam_enabled = false;
    },
    setFilterGroep() {
      this.$store.commit(this.store_name + '/set_search_endpoint', '/api/v1/patients/' + this.patient.id + '/intolerances/zindex-search/groep');
      this.title = 'Zoek op groep';
      this.refresh_table++;
      this.btn_geneesmiddel_enabled = false;
      this.btn_groep_enabled = true;
      this.btn_stofnaam_enabled = false;
    },
    setFilterStofnaam() {
      this.$store.commit(this.store_name + '/set_search_endpoint', '/api/v1/patients/' + this.patient.id + '/intolerances/zindex-search/stofnaam');
      this.title = 'Zoek op stofnaam';
      this.refresh_table++;
      this.btn_geneesmiddel_enabled = false;
      this.btn_groep_enabled = false;
      this.btn_stofnaam_enabled = true;
    },
    zindexIntoleranceSelected(model) {
      this.loading = true;
      this.$store.dispatch('intolerances_zindex/fetchOptions', {
        patient_id: this.patient.id,
        type: model.type,
        id: model.id
      }).then(() => {
        this.loading = false;
        this.$emit('zindexIntoleranceSelected', model);
      }).catch(() => {
        this.loading = false;
      });
    }
  },

  watch: {
  }
};
</script>
