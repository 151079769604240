import {HealthcareProfessional} from '@/domain/models/HealthcareProfessional';
import {HealthcareProfessionalObject} from '@/domain/models/HealthcareProfessionalObject';

export class GeneratedHealthcareProfessional { 
  public id: number;
  public professionalObject: HealthcareProfessionalObject;

  constructor(id: number, professionalObject: HealthcareProfessionalObject) {
    this.id = id;
    this.professionalObject = professionalObject;
  }

  public static fromJson(json: unknown): HealthcareProfessional {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new HealthcareProfessional(
      parsedJson['id'] as number,
      HealthcareProfessionalObject.fromJson(parsedJson['professionalObject']),
    );
  }

  public static multipleFromJson(json: unknown): HealthcareProfessional[] {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    const items: HealthcareProfessional[] = [];
    for (const item of parsedJson) {
      items.push(this.fromJson(item));
    }
    return items;
  }
}
