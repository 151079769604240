<template>
    <div ref="protocolVisualiser" class="mfb-protocol-viewer" style="overflow-x: auto;">
      <medimo-loader-overlay :loading="!loaded"></medimo-loader-overlay>
      <div :style="{width:(142*ncolsVisible)+2+'px', maxHeight: '585px'}" v-if="loaded">
        <div class="row" style="max-width:none; margin-left:0px;" v-for="i in nrows">
          <div style="float: left; width: 142px;" v-for="j in ncolsVisible">
            <mfbprotocol-viewer-item
                :data="get_data(i,j)"
                :visible="is_visible(i,j)"
                :all_data="data"
                :row_index="i"
                :col_index="j"
                :trace_questions="traceQuestions"
                :toggled="toggled"
                @toggle="toggleNode"
              >
              </mfbprotocol-viewer-item>
            </div>
          </div>
        </div>
    </div>
</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import MfbprotocolViewerItem from './MfbprotocolViewerItem';
import MedimoLoaderOverlay from '@/vue/components/general/MedimoLoaderOverlay';

export default {
  components: {MedimoLoaderOverlay, MfbprotocolViewerItem},

  props: {
    'json_tree': {default: false},
    'protocol_id': {required: true},
    'release_id': {required: true},
    'trace': {default: false},
  },

  emits: ['update_size'],

  mixins: [BaseComponentsMixin],

  data: function () {
    return {
      nrows: 0,
      ncols: 0,
      data: {},
      loaded: false,
      toggled: {},
      nodeIndexes: {},
    };
  },
  computed: {
    ncolsVisible() {
      let minCol = 0;
      for (let c=this.ncols; c>0; c--) {
        for (let r=0; r<this.nrows; r++) {
          if (c > minCol && this.is_visible(r,c, true)) {
            minCol = c;
          }
        }
      }
      return minCol;
    },
    traceQuestions() {
      if (!this.trace) {
        return false;
      }
      if (this.trace.questions) {
        const questions = {};
        for (let i=0; i<this.trace.questions.length; i++) {
          questions[this.trace.questions[i].nodeId] = this.trace.questions[i];
        }
        return questions;
      }
    },
  },
  created() {
    const this2 = this;
    if (this.json_tree) {
      //Used in admin views
      this.tree = JSON.parse(this.json_tree);
      this.add_tree(this.tree[0], 1, 1);
      this2.loaded = true;
    }
    else {
      //Used in frontend views

      //1. Load the protocol tree
      const endpoint = '/api/v1/mfb-protocol/' + this.protocol_id + '/' + this.release_id;
      this.$store.dispatch('api/getEndpoint', {
        endpoint: endpoint
      }, {root: true})
        .then(data => {
          //2a. Add the tree
          this2.add_tree(data.data[0], 1, 1);
          this2.loaded = true;
          //2b. Trace path is highlighed via data attribute traceQuestions
          //2c. Collapse everything not in trace path
          this2.collapseQuestionsNotInTrace();
        })
        .catch(() => {});
    }
  },
  methods: {
    collapseQuestionsNotInTrace() {
      const keys = Object.keys(this.traceQuestions);
      for (let i=0; i< keys.length; i++) {
        const answer = this.traceQuestions[keys[i]].answer;
        if (!answer) {
          const nodeIndex = this.nodeIndexes[keys[i]];
          this.toggleNode(nodeIndex[0], nodeIndex[1], keys[i]);
        }
      }
    },
    scrollTo(left, top) {
      this.$refs.protocolVisualiser.scrollLeft = left;
      this.$refs.protocolVisualiser.scrollTop = top;
    },
    toggleNode(rowIndex, colIndex, nodeId) {
      const toggled = this.toggled;

      colIndex = colIndex + 1;
      const node = this.get_data(rowIndex, colIndex);

      if (toggled[nodeId]) {
        this.toggled[nodeId] = false;
        toggled[nodeId] = false;
      }
      else {
        this.toggled[nodeId] = {
          start_row: rowIndex,
          end_row: rowIndex + node.values.depth,
          start_col: colIndex
        };
      }
    },
    is_visible(row_index, col_index, onNColsVisible) {

      // When determining the number of columns visible, we can ignore empty boxes
      if (onNColsVisible && this.get_data(row_index, col_index) === '') {
        return false;
      }

      const toggledIds = Object.keys(this.toggled);

      for (let i=0; i<toggledIds.length; i++) {
        if (this.toggled[toggledIds[i]] !== false) {
          const v = this.toggled[toggledIds[i]];

          if (v.start_row === row_index) {
            return v.start_col > col_index;
          }
          else if (row_index > v.start_row && row_index < v.end_row) {
            return false;
          }
        }
      }
      return true;
    },
    add_tree(tree, rowIndex, colIndex) {
      if (!this.data[rowIndex]) {
        this.data[rowIndex] = {};
      }

      if (tree.node) {
        const yes = tree.yes;
        const no = tree.no;
        this.set_data(rowIndex, colIndex, tree.node.question, true, no !== false, tree);
        yes.parent = [rowIndex, colIndex, tree.node.id];
        this.add_tree(yes, rowIndex, colIndex+1);
        if (no) {
          const depth = yes.depth ? yes.depth: 1;
          this.add_arrow(rowIndex+1, rowIndex + depth, colIndex);
          no.parent = [rowIndex, colIndex, tree.node.id];
          this.add_tree(no, rowIndex + depth, colIndex);
        }
      }
      else {
        this.set_data(rowIndex, colIndex, tree.title, false, false, tree);
      }
    },
    add_arrow(rowIndexStart, rowIndexEnd, colIndex) {
      for (let i = rowIndexStart; i< rowIndexEnd; i++) {
        this.set_data(i, colIndex, '|');
      }
    },
    set_data(rowIndex,colIndex, value, arrows, no_arrow, values) {
      if (!this.data[rowIndex]) {
        this.data[rowIndex] = {};
      }
      let questionId = false;
      if (values && values.values && values.values.MFBVNR) {
        questionId = values.values.MFBVNR;
      }
      this.data[rowIndex][colIndex] = {value: value, no_arrow: no_arrow, arrows: arrows, values: values, questionId: questionId};
      if (values && values.node) {
        this.nodeIndexes[values.node.id] = [rowIndex, colIndex];
      }
      this.nrows = Math.max(this.nrows, rowIndex);
      this.ncols = Math.max(this.ncols, colIndex);
    },
    get_data(i,j) {
      if (this.data[i] && this.data[i][j] && this.data[i][j]) {
        return this.data[i][j];
      }
      else {
        return '';
      }
    }
  },
  watch: {
    'ncolsVisible' (newVal) {
      if (newVal >= 6) {
        this.$emit('update_size', 'extra-large');
      }
      else if (newVal < 4) {
        this.$emit('update_size', 'medium');
      }
      else {
        this.$emit('update_size', 'large');
      }
    }
  }
};
</script>
